import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { AlertDialogTitle } from '@tectonic/uikit';

import type { FC } from 'react';
import type { ConfirmationDialogHeaderProps } from './ConfirmationDialog.types';

const ConfirmationDialogHeader: FC<ConfirmationDialogHeaderProps> = ({
  config,
  data,
}) => {
  const { container, title } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});
  const titleContainerStyleConfig = useStyleConfig(title?.container ?? {});

  return (
    <div {...containerStyleConfig}>
      <AlertDialogTitle asChild>
        <div {...titleContainerStyleConfig}>
          <Text config={title?.text} data={data.title.text} />
        </div>
      </AlertDialogTitle>
    </div>
  );
};

ConfirmationDialogHeader.displayName = 'ConfirmationDialogHeader';

export { ConfirmationDialogHeader };
