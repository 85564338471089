import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { memo, type FC } from 'react';
import { useFilePreview } from './hooks';

import type { FilePreviewWidgetProps } from './FilePreview.types';

const FilePreviewWidget: FC<FilePreviewWidgetProps> = ({ widget }) => {
  const { isImage, isVideo, url } = useFilePreview(widget);
  const wConfig = widget.config!;

  const [style, className] = useStyleConfigV2(wConfig?.container);
  // eslint-disable-next-line no-nested-ternary
  const content = isVideo ? (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      className="h-full w-full object-cover"
      width="100%"
      height="100%"
      src={url}
    />
  ) : isImage ? (
    <img
      src={url}
      alt="file preview"
      className="h-full w-full object-cover"
      width="100%"
      height="100%"
    />
  ) : null;

  return (
    <div style={style} className={className}>
      {content}
    </div>
  );
};

FilePreviewWidget.displayName = 'FilePreviewWidget';

const MemoizedFilePreviewWidget = memo(FilePreviewWidget);

export { MemoizedFilePreviewWidget as FilePreviewWidget };
