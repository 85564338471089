import { FormProvider, useForm } from '@tectonic/uikit';
import clsx from 'clsx';
import { keyBy } from 'lodash-es';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { FacetSection } from './FacetSection';
import { FacetValue } from './FacetValue';
import { useDefaultFacetFormValues, useNonEmptyFacets } from './hooks';
import { toSearchFilters } from './utils';

import type { SearchFilter } from '@tectonic/types';
import type { ForwardRefRenderFunction } from 'react';
import type {
  SearchFilterFormProps,
  SearchFilterFormRef,
} from './SearchFilterForm.types';

const SearchFilterForm: ForwardRefRenderFunction<
  SearchFilterFormRef,
  SearchFilterFormProps
> = (props, formRef) => {
  const {
    config,
    facets,
    initialFilters,
    onFacetToggle,
    activeFacet,
    className,
  } = props;

  const filtersMap = useMemo(() => {
    const filters = initialFilters ?? [];
    return keyBy<SearchFilter>(filters, 'field');
  }, [initialFilters]);

  const filteredFacets = useNonEmptyFacets(facets);

  const defaultValues = useDefaultFacetFormValues(filtersMap, filteredFacets);

  const formMethods = useForm({ defaultValues });

  const { getValues } = formMethods;

  const currentFacet = filteredFacets.find(
    (facet) => facet.name === activeFacet
  );

  useImperativeHandle(
    formRef,
    () => {
      const getSearchFilters = () =>
        toSearchFilters({
          filters: filtersMap,
          facets: filteredFacets,
          formValues: getValues(),
        });

      return { getSearchFilters };
    },
    [getValues, filtersMap, filteredFacets]
  );

  return (
    <div className={clsx('flex h-full', className)}>
      {/* Facet sections should not have grow. */}
      <FacetSection
        className="h-full shrink-0 overflow-auto"
        activeFacet={activeFacet}
        config={config}
        facets={filteredFacets}
        onFacetToggle={onFacetToggle}
      />
      <FormProvider {...formMethods}>
        <div className="'h-full grow overflow-auto">
          {currentFacet ? (
            <FacetValue config={config?.facetValue} facet={currentFacet} />
          ) : null}
        </div>
      </FormProvider>
    </div>
  );
};

SearchFilterForm.displayName = 'ElemasonSearchFilterForm';

const ExoticSearchFilterForm = forwardRef(SearchFilterForm);

export { ExoticSearchFilterForm as SearchFilterForm };
