import { isEmpty } from 'lodash-es';
import { type FC } from 'react';
import { AddressForm, RenderBoundary } from '../../../components';
import { useAddressForm } from './hooks';

import type { AddressFormWidgetProps } from './AddressForm.types';

const AddressFormWidget: FC<AddressFormWidgetProps> = ({ widget }) => {
  const {
    onSubmit,
    isSubmitting,
    isLoading,
    hasLoadError,
    address,
    countries,
    provinces,
    onChange,
  } = useAddressForm(widget);

  const hasCountries = !isEmpty(countries);

  return (
    <RenderBoundary isLoading={isLoading} hasError={hasLoadError}>
      {hasCountries ? (
        <AddressForm
          provinces={provinces}
          countries={countries!}
          address={address}
          isSubmitting={isSubmitting}
          data={widget.data!}
          config={widget.config}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      ) : null}
    </RenderBoundary>
  );
};

AddressFormWidget.displayName = 'AddressFormWidget';

export { AddressFormWidget };
