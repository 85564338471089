import { usePageFragment } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonRepeaterWidget } from '@tectonic/types';

const defaultList: unknown[] = [];

const useRepeater = (widget: ElemasonRepeaterWidget) => {
  const wData = widget.data;
  const result = useHaloScript<unknown[]>(wData?.list);
  const list = result ?? defaultList;
  const fragment = usePageFragment(wData?.fragment);
  return { list, fragment };
};

export { useRepeater };
