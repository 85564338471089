import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Logger } from '@tectonic/logger';
import { Button } from '../../../components';
import { useHaloScript } from '../../../hooks/useHaloScript';

import type { ElemasonProductShareWidget } from '@tectonic/types';
import type { FC } from 'react';
import { useActionDispatch } from '../../../hooks';

interface ElemasonProductShareWidgetProps {
  widget: ElemasonProductShareWidget;
}

const ProductShareWidget: FC<ElemasonProductShareWidgetProps> = ({
  widget: { config, data, actions },
}) => {
  const dispatch = useActionDispatch();
  const product = useHaloScript(data?.product);
  const [style, className] = useStyleConfigV2(config?.container);

  const onClick = async () => {
    actions?.onClick.forEach((action) => dispatch(action, undefined));
    if (!product) return;

    const payload = {
      title: product.title,
      text: product.description,
      url: window.location.href,
    };
    try {
      await navigator.share(payload);
    } catch (error) {
      Logger.error(`Couldn't share product.`, error);
    }
  };

  return (
    <div style={style} className={className}>
      <Button onClick={onClick} data={data?.button} config={config?.button} />
    </div>
  );
};

export { ProductShareWidget };
