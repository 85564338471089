import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { CheckboxIndicator, CheckboxRoot, Icon } from '@tectonic/uikit';
import clsx from 'clsx';

import type { FC } from 'react';
import type { CheckboxFacetAttributeProps } from './CheckboxFacet.types';

const CheckoutFacetAttribute: FC<CheckboxFacetAttributeProps> = ({
  checked,
  facetValue,
  onCheckedChange,
  config,
}) => {
  const onChange = (value: boolean) => {
    onCheckedChange(facetValue.value, value);
  };

  const checkboxConfig = checked ? config?.checked : config?.unchecked;

  const { text } = checkboxConfig ?? {};

  const containerStyleConfig = useStyleConfig(checkboxConfig?.container ?? {});

  return (
    <div
      style={containerStyleConfig.style}
      className={clsx(
        'flex w-full items-center',
        containerStyleConfig.className
      )}
    >
      <CheckboxRoot
        checked={checked}
        id={facetValue.value}
        className="group shrink-0"
        onCheckedChange={onChange}
      >
        <Icon
          className="group-radix-state-checked:hidden"
          symbol="outline-checkbox-unchecked"
        />
        <CheckboxIndicator>
          <Icon symbol="solid-checkbox-checked" />
        </CheckboxIndicator>
      </CheckboxRoot>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={facetValue.value}
        className={clsx(
          'cursor-pointer',
          'ml-2 space-x-2.5',
          'flex grow items-center',
          'w-0'
        )}
      >
        <Text
          config={text}
          data={facetValue.value as string}
          className={clsx('shrink-1 min-w-0 grow truncate')}
        />
        <Text
          config={text}
          data="({count})"
          className="shrink-0"
          placeholders={{ count: `${facetValue.count}` }}
        />
      </label>
    </div>
  );
};

export { CheckoutFacetAttribute };
