import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Input, InputField, InputIcon, InputSlot } from '@tectonic/uikit';

import type { FC } from 'react';
import type { TextFieldProps } from './TextField.types';

const TextField: FC<TextFieldProps> = ({
  data,
  config,
  onEndIconClick,
  onStartIconClick,
  disabled,
  ...inputFieldProps
}) => {
  const { startIcon, endIcon } = data;
  const {
    endIcon: endIconConfig,
    startIcon: startIconConfig,
    borderRadius: rounded,
    input,
    ...inputConfig
  } = config ?? {};

  const [inputStyle, inputClass] = useStyleConfigV2(input);

  return (
    <Input rounded={rounded} disabled={disabled} {...inputConfig}>
      {startIcon ? (
        <InputSlot onClick={onStartIconClick}>
          <InputIcon symbol={startIcon} {...startIconConfig} />
        </InputSlot>
      ) : null}
      <InputField
        autoFocus={data.autoFocus}
        placeholder={data.placeholder}
        {...inputFieldProps}
        style={inputStyle}
        className={inputClass}
      />
      {endIcon ? (
        <InputSlot onClick={onEndIconClick}>
          <InputIcon symbol={endIcon} {...endIconConfig} />
        </InputSlot>
      ) : null}
    </Input>
  );
};

TextField.displayName = 'ElemasonTextField';

export { TextField };
