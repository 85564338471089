import { toTailwindUtility } from '@tectonic/utils';
import { compact } from 'lodash-es';

import type { GapConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useGapConfig = (config: GapConfig) => {
  const { gap, gapX, gapY } = config;

  const gapClasses = [];

  const gapStyles: CSSProperties = {};

  if (typeof gap === 'number') {
    gapStyles.gap = gap;
  } else {
    gapClasses.push(toTailwindUtility('gap', gap));
  }

  if (typeof gapX === 'number') {
    gapStyles.columnGap = gapX;
  } else {
    gapClasses.push(toTailwindUtility('gap-x', gapX));
  }

  if (typeof gapY === 'number') {
    gapStyles.rowGap = gapY;
  } else {
    gapClasses.push(toTailwindUtility('gap-y', gapY));
  }

  return [gapStyles, compact(gapClasses)];
};

export { useGapConfig };
