import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Input, InputField } from '@tectonic/uikit';
import { useEffect, type ChangeEvent, type FC } from 'react';
import { useActionDispatch, useSharedLocalState } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonInputWidget } from '@tectonic/types';

interface InputWidgetProps {
  widget: ElemasonInputWidget;
}

const InputWidget: FC<InputWidgetProps> = ({
  widget: { config, data, actions },
}) => {
  const stateKey = useHaloScript(data?.stateKey!)!;
  const name = useHaloScript(data?.name);
  const defaultValue = useHaloScript(data?.defaultValue!);
  const { sharedState, setSharedState } = useSharedLocalState(
    stateKey,
    defaultValue
  );
  const dispatch = useActionDispatch();

  const { rounded, size } = config ?? {};

  const [style, className] = useStyleConfigV2(config?.container);

  useEffect(() => {
    actions?.onChange?.forEach((action) => dispatch(action));
  }, [actions, sharedState]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSharedState(event.target.value);
  };

  return (
    <Input
      disabled={data?.disabled}
      rounded={rounded}
      size={size}
      style={style}
      className={className}
    >
      <InputField
        name={name}
        className="inline-block"
        size="md"
        max={config?.input?.max}
        min={config?.input?.min}
        type={config?.input?.type}
        placeholder={data?.placeholder}
        pattern={config?.input?.pattern}
        required={config?.input?.required}
        minLength={config?.input?.minLength}
        maxLength={config?.input?.maxLength}
        autoFocus={config?.input?.autoFocus}
        value={sharedState ?? defaultValue ?? ''}
        onChange={onChange}
      />
    </Input>
  );
};

export { InputWidget };
