import clsx from 'clsx';
import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { useColors } from '../../context';

import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import type { VariantProps } from 'tailwind-variants';
import type { Color } from '../../types';

const typographyVariants = tv({
  slots: {
    base: '',
  },
  variants: {
    variant: {
      headline1: '',
      headline2: '',
      headline3: '',
      headline4: '',
      headline5: '',
      headline6: '',
      headline7: 'headline7',
      headline8: 'headline8',
      headline9: 'headline9',
      body1: 'body1',
      body2: 'body2',
      body3: 'body3',
      body4: 'body4',
      body5: 'body5',
      body6: 'body6',
      body7: 'body7',
      body8: 'body8',
      body9: 'body9',
      body10: 'body10',
      subtext1: 'subtext1',
      subtext2: 'subtext2',
      subtext3: 'subtext3',
      subtext4: 'subtext4',
      subtext5: 'subtext5',
      subtext6: 'subtext6',
    },
  },
});

type Props = ComponentProps<'p'> & VariantProps<typeof typographyVariants>;

const componentMapping: Record<
  Exclude<Props['variant'], undefined>,
  keyof JSX.IntrinsicElements
> = {
  headline1: 'h1',
  headline2: 'h2',
  headline3: 'h3',
  headline4: 'h4',
  headline5: 'h5',
  headline6: 'h6',
  headline7: 'p',
  headline8: 'p',
  headline9: 'p',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'p',
  body5: 'p',
  body6: 'p',
  body7: 'p',
  body8: 'p',
  body9: 'p',
  body10: 'p',
  subtext1: 'p',
  subtext2: 'p',
  subtext3: 'p',
  subtext4: 'p',
  subtext5: 'p',
  subtext6: 'p',
};

const Typography: ForwardRefRenderFunction<HTMLElement, Props> = (
  { variant, className, children, color, style, ...typographyProps },
  ref
) => {
  const colors = useColors();
  const { base } = typographyVariants({ variant });
  const Component = componentMapping[variant!] ?? 'p';

  // TODO: fix type
  return (
    // @ts-ignore
    <Component
      // @ts-ignore
      ref={ref}
      className={clsx(base(), className)}
      style={{ color: colors[color as Color] ?? color, ...style }}
      {...typographyProps}
    >
      {children}
    </Component>
  );
};

Typography.displayName = 'Typography';

const ExoticTypography = forwardRef(Typography);

export { ExoticTypography as Typography };

export type { Props as TypographyProps };
