import { createContext } from '@tectonic/utils';

import type { Color } from '../types';

interface ThemeContextType {
  icon: {
    sprite: string;
    spinner: string;
  };
  colors: Record<Color, string>;
}

const name = 'ThemeContext';
const errorMessage =
  'useThemeContext must be inside a ThemeProvider with a value';

const [ThemeProvider, useThemeContext, ThemeContext] =
  createContext<ThemeContextType>({ errorMessage, name });

const useColors = () => {
  const { colors } = useThemeContext();
  return colors;
};

export { ThemeContext, ThemeProvider, useColors, useThemeContext };

export type { ThemeContextType };
