import { createContext } from '@tectonic/utils';

import type { ElemasonAnalyticsContextType } from '@tectonic/analytics';

const errorMessage =
  'useElemasonAnalyticsContext must be inside a ElemasonAnalyticsProvider with a value';

const name = 'ElemasonAnalyticsContext';

// TODO clean this up today the actions are defined inside the component,
// which donot have information about the widget and since our analytics events
// depends on the widget information we are using this hack to make the data available in the component,
// once we move the actions to be passed as a prop to the component we should not use this and clean up the existing places
const [ElemasonAnalyticsProvider, useElemasonAnalyticsContext] =
  createContext<ElemasonAnalyticsContextType>({
    name,
    errorMessage,
    strict: false,
  });

export { ElemasonAnalyticsProvider, useElemasonAnalyticsContext };
export type { ElemasonAnalyticsContextType };

