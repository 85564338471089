import axios from 'axios';

import type { SearchTrendsApiRouteLoaderResponse } from '@tectonic/types';

const getTrendingSearches =
  async (): Promise<SearchTrendsApiRouteLoaderResponse> => {
    const response = await axios.get(`/api/search/trends`);
    return response.data;
  };

export { getTrendingSearches };
