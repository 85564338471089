import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { memo, type FC } from 'react';
import { useHaloScript } from '../../../hooks/useHaloScript';

import type { ElemasonRichTextWidgetProps } from '@tectonic/types';

const RichTextWidget: FC<ElemasonRichTextWidgetProps> = ({ widget }) => {
  const wData = widget.data!;

  const text = useHaloScript<string>(wData.text);
  const className = useHaloScript<string>(wData.className);

  const content = text ?? '';

  const containerStyleConfig = useStyleConfig(widget.config?.container ?? {});

  return (
    <div
      suppressHydrationWarning
      style={containerStyleConfig.style}
      className={clsx(
        '[&_strong]:font-semibold',
        '[&_img]:!w-full',
        '[&_img]:!h-auto',
        containerStyleConfig.className,
        className
      )}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

RichTextWidget.displayName = 'RichTextWidget';

const MemoizedRichTextWidget = memo(RichTextWidget);

export { MemoizedRichTextWidget as RichTextWidget };
