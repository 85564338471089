import { useEffect, useRef } from 'react';

const usePrevious = <T = null>(value: T | null) => {
  const ref = useRef<T | null>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export { usePrevious };
