import { useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonWidgetType } from '@tectonic/types';
import clsx from 'clsx';
import { useState, type FC } from 'react';
import { Button } from '../../components';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { ProductListTabContent } from './ProductListTabContent';

import type { ElemasonProductListTabsWidget } from '@tectonic/types';

interface ElemasonProductListTabsWidgetProps {
  widget: ElemasonProductListTabsWidget;
}

const ProductListTabsWidget: FC<ElemasonProductListTabsWidgetProps> = ({
  widget,
}) => {
  const { id, data, config: productListTabsConfig } = widget;
  const [selectedTab, setSelectedTab] = useState(0);
  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductListTabs,
    productListTabsConfig
  );

  const { style, className } = useStyleConfig(config?.container ?? {});
  const { style: tabStyle, className: tabClassName } = useStyleConfig(
    config?.tab ?? {}
  );
  const { style: tabsStyle, className: tabsClassName } = useStyleConfig(
    config?.tabs ?? {}
  );
  const { style: selectedTabStyle, className: selectedTabClassName } =
    useStyleConfig(config?.selectedTab ?? {});

  return (
    <div style={style} className={clsx('flex w-full flex-col', className)}>
      <div style={tabsStyle} className={clsx('flex flex-row', tabsClassName)}>
        {data?.tabs?.map((tab, index) => {
          const isSelected = selectedTab === index;

          return (
            <Button
              key={index}
              data={tab.title}
              onClick={() => setSelectedTab(index)}
              style={isSelected ? selectedTabStyle : tabStyle}
              config={isSelected ? config?.selectedTab : config.tab}
              className={clsx(
                'shrink-0',
                isSelected ? selectedTabClassName : tabClassName
              )}
            />
          );
        })}
      </div>
      <div className="flex w-full flex-row">
        <ProductListTabContent
          id={id}
          config={config}
          // @ts-expect-error fix type
          data={{
            ...data?.tabs?.[selectedTab],
            sourceCard: data?.sourceCard,
            productFragment: data?.productFragment,
          }}
        />
      </div>
    </div>
  );
};

export { ProductListTabsWidget };
