import { useAnalyticsStore } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { useEffect, useRef, type FC } from 'react';
import { useElemasonPageContext } from '../../../contexts';
import { Block } from '../../Block';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { useNavbars } from '../hooks/useNavbars';

import type { Direction, ElemasonPage } from '@tectonic/types';

interface DrawerPageProps {
  slug: string;
  exit?: Direction;
  entry?: Direction;
  exiting?: boolean;
  page: ElemasonPage;
  animationType?: string;
}

const DrawerPage: FC<DrawerPageProps> = ({
  slug,
  page,
  exit,
  entry,
  exiting,
  animationType,
}) => {
  const pushRef = useRef(false);
  const { blocks, config } = page;
  const { style, className } = useStyleConfig(config?.theme ?? {});
  const { header, footer } = useNavbars({}, page);
  const { addDrawerFragments, removeDrawerFragments } =
    useElemasonPageContext();

  useEffect(() => {
    addDrawerFragments(slug, page.fragments ?? []);
    return () => removeDrawerFragments(slug);
  }, [page]);

  const { pushDrawer, popDrawer } = useAnalyticsStore((state) => ({
    popDrawer: state.popDrawer,
    pushDrawer: state.pushDrawer,
  }));

  useEffect(() => {
    if (!pushRef.current) {
      pushDrawer({
        pageId: page.id,
        pageSlug: page.slug,
        screenName: page.info?.title,
      });
      pushRef.current = true;
    }
  }, [page, pushDrawer, popDrawer]);

  return (
    <div
      style={style}
      className={clsx(
        'flex grow flex-col',
        'repeat-1 duration-300',
        (entry || exit) && (exiting ? 'animate-out' : 'animate-in'),
        ...(animationType === 'fade'
          ? [exiting ? 'fade-out' : 'fade-in']
          : [
              !exiting && entry === 'top' && 'slide-in-from-top',
              !exiting && entry === 'left' && 'slide-in-from-left',
              !exiting && entry === 'right' && 'slide-in-from-right',
              !exiting && entry === 'bottom' && 'slide-in-from-bottom',
              exiting && exit === 'top' && 'slide-out-to-top',
              exiting && exit === 'left' && 'slide-out-to-left',
              exiting && exit === 'right' && 'slide-out-to-right',
              exiting && exit === 'bottom' && 'slide-out-to-bottom',
            ]),
        className
      )}
    >
      {header && <Header header={header} />}
      {blocks.map((block, index) => (
        <Block key={block.id} block={block} index={index} />
      ))}
      {footer && <Footer footer={footer} />}
    </div>
  );
};

export { DrawerPage };
