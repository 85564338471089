import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { ElemasonWidgetActionType, LocalStateKeys } from '@tectonic/types';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef } from 'react';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../../contexts';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { ActionDispatchExporter } from '../../../core';
import { Cell } from '../../../core/Cell';
import { usePageFragment, useSharedLocalState } from '../../../hooks';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { queryKeys } from '../../../queryKeys';

import type { Cart, ElemasonCartWidget } from '@tectonic/types';
import type { FC } from 'react';
import type { DispatchExporterHandle } from '../../../core';

const STATE_KEY = LocalStateKeys.CART;
const FREE_GIFTS_STATE_KEY = LocalStateKeys.CART_FREE_GIFTS;

interface ElemasonCartWidgetProps {
  widget: ElemasonCartWidget;
}

// TODO: come up with a standardized way to fetch data
// TODO: Move the event tracking depending on above
const CartWidget: FC<ElemasonCartWidgetProps> = ({ widget }) => {
  const queryClient = useQueryClient();
  const analyticsContext = useElemasonAnalyticsContext();
  const fragment = usePageFragment(widget.data?.fragment);

  const { data: cart } = useQuery({
    queryKey: queryKeys.cart(),
    queryFn: () => remixApi.getCart(),
  });

  const { data: cartFreeGifts } = useQuery({
    queryKey: queryKeys.cartFreeGifts(),
    queryFn: () => remixApi.getCartFreeGifts(),
  });

  const refetch = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
  };

  useSharedLocalState<Cart>(STATE_KEY, cart);
  useSharedLocalState<unknown>(FREE_GIFTS_STATE_KEY, cartFreeGifts);

  const isTrackedRef = useRef<boolean>(false);
  const dispatchRef = useRef<DispatchExporterHandle>(null);

  useEffect(() => {
    const actionDispatch = dispatchRef.current?.dispatch;

    if (actionDispatch && !isEmpty(cart) && !isTrackedRef.current) {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.CART_VIEW,
        },
      });
      isTrackedRef.current = true;
    }
  }, [isTrackedRef, cart, dispatchRef]); // TODO [ANALYTICS] should this event be sent on every cart refresh

  const fragmentValue = useFragmentValue(fragment);

  if (!fragment) return null;

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: { ...analyticsContext.entities, cart },
      }}
    >
      <ActionDispatchExporter ref={dispatchRef} />
      <ElemasonFragmentProvider value={fragmentValue({ cart, refetch })}>
        {fragment.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </ElemasonAnalyticsProvider>
  );
};

export { CartWidget };
