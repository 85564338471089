/* eslint-disable no-nested-ternary */
import { AssetExtension, AssetScale } from '@tectonic/types';
import { isGif, isSVG } from '@tectonic/utils';
import { useMemo } from 'react';
import { useElemasonContext } from '../contexts';

import type { Asset } from '@tectonic/types';

const getImageSource = (asset: Asset, scale: AssetScale): string => {
  const { damId } = asset;
  const path = `${isSVG(asset.mimeType) ? 'vector-graphics' : 'images'}/${
    isGif(asset.mimeType) || isSVG(asset.mimeType) ? AssetScale.ORIGINAL : scale
  }/${damId}`;
  const extension = isGif(asset.mimeType)
    ? AssetExtension.GIF
    : isSVG(asset.mimeType)
      ? AssetExtension.SVG
      : AssetExtension.WEBP;
  return `${path}.${extension}`;
};

const useImageSource = (asset?: Asset, scale?: AssetScale) => {
  const { env } = useElemasonContext();
  const damUrl = env.DAM_URL;

  return useMemo(() => {
    // TODO: handle the empty state properly with an image wrapper component
    // to avoid unintended behavior.

    if (!asset) {
      // add x1 when needed
      return {
        x1: '',
        x2: '',
        x3: '',
        x4: '',
        original: '',
      };
    }

    if (!asset.damId) {
      return {
        x1: asset.originalUrl,
        x2: asset.originalUrl,
        x3: asset.originalUrl,
        x4: asset.originalUrl,
        original: asset.originalUrl,
      };
    }

    // fixed scale
    if (scale) {
      const source = getImageSource(asset, scale);
      const url = `${damUrl}/${source}`;
      return {
        x1: url,
        x2: url,
        x3: url,
        x4: url,
        original: asset.originalUrl,
      };
    }

    const x1 = getImageSource(asset, AssetScale.X_1);
    const x2 = getImageSource(asset, AssetScale.X_2);
    const x3 = getImageSource(asset, AssetScale.X_3);
    const x4 = getImageSource(asset, AssetScale.X_4);
    return {
      // add x1 when needed
      x1: `${damUrl}/${x1}`,
      x2: `${damUrl}/${x2}`,
      x3: `${damUrl}/${x3}`,
      x4: `${damUrl}/${x4}`,
    };
  }, [damUrl, asset, scale]);
};

export default useImageSource;
