import { isRangeControl } from '@tectonic/utils';
import { useMemo } from 'react';

import type {
  SearchFacetConfigWithValues,
  SearchFilter,
  SearchFilterRangeValue,
} from '@tectonic/types';
import type { SearchFilterFormValue } from './SearchFilterForm.types';

// TODO: Fine tune it with the controls being used in the app.
const getValueForRangeControl = (
  facet: SearchFacetConfigWithValues,
  filter?: SearchFilter
) => {
  // TODO: Confirm this behavior with product team. If we don't apply the value,
  // controls like slider will look empty.
  if (!filter) {
    // We treat range values as an array of [min, max]. Underlying range slider
    // library handles ranges in this format.
    return [facet.minValue, facet.maxValue];
  }
  const value = filter.value as SearchFilterRangeValue;
  return [value.min, value.max];
};

const getDefaultValueForStaticFacet = (facet: SearchFacetConfigWithValues) => {
  if (isRangeControl(facet)) {
    return getValueForRangeControl(facet);
  }
  return null;
};

const getValueForStaticFacet = (
  facet: SearchFacetConfigWithValues,
  filters: Record<string, SearchFilter>
) => {
  const facetName = facet.name;
  const filter = filters[facetName];

  if (!filter) {
    return getDefaultValueForStaticFacet(facet);
  }

  if (isRangeControl(facet)) {
    return getValueForRangeControl(facet, filter);
  }

  return filter.value;
};

const getDefaultValues = (
  filters: Record<string, SearchFilter>,
  facets: SearchFacetConfigWithValues[]
) => {
  const defaultValues: SearchFilterFormValue = {};

  for (const facet of facets) {
    const facetName = facet.name;
    if (facet.static) {
      defaultValues[facetName] = getValueForStaticFacet(facet, filters);
      continue;
    }

    const filter = filters[facetName];
    if (isRangeControl(facet)) {
      defaultValues[facetName] = getValueForRangeControl(facet, filter);
      continue;
    }
    defaultValues[facetName] = filter ? filter.value : null;
  }

  return defaultValues;
};

const useNonEmptyFacets = (facets: SearchFacetConfigWithValues[]) =>
  useMemo(
    // drop non-static facets with no counts.
    () => facets.filter((facet) => facet.static || !!facet.values.length),
    [facets]
  );

const useDefaultFacetFormValues = (
  filters: Record<string, SearchFilter>,
  facets: SearchFacetConfigWithValues[]
) => useMemo(() => getDefaultValues(filters, facets), [filters, facets]);

export { useDefaultFacetFormValues, useNonEmptyFacets };
