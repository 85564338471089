import { memo, type FC } from 'react';
import { ProductPricing } from '../../components';
import { useHaloScript } from '../../hooks/useHaloScript';
import { isOutOfStock } from '../../utils';

import type { ElemasonProductPricingWidget } from '@tectonic/types';

interface ElemasonProductPricingWidgetProps {
  widget: ElemasonProductPricingWidget;
}

const ProductPricingWidget: FC<ElemasonProductPricingWidgetProps> = ({
  widget: { data, config },
}) => {
  let variant = useHaloScript(data?.variant);
  const product = useHaloScript(data?.product);
  const quantity = useHaloScript(data?.quantity) ?? 1;

  if (product && !variant) {
    variant =
      product.variants?.find((v) => !isOutOfStock(v.stockStatus)) ??
      product.variants?.[0];
  }

  if (!variant) return null;

  return (
    <ProductPricing config={config} variant={variant} quantity={quantity} />
  );
};

const MemoizedProductPricingWidget = memo(ProductPricingWidget);

export { MemoizedProductPricingWidget as ProductPricingWidget };
