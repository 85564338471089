/* eslint-disable no-nested-ternary */
import cronParser from 'cron-parser';
import { useEffect, useRef, useState } from 'react';

import type { CountdownData } from '@tectonic/types';

const getCountdownValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const normalize = (n: number, divider: number) => n - (n % divider);

const useCountdown = (target?: CountdownData, updateInterval = 1000) => {
  const [countDown, setCountDown] = useState(
    target?.type === 'epoch'
      ? target.epoch - Date.now()
      : target?.type === 'duration'
        ? target?.duration ?? 0
        : cronParser.parseExpression(target!.cron).next().getTime()
  );

  const ref = useRef(countDown);
  const intervalRef = useRef<NodeJS.Timer>();
  const countDownDateRef = useRef(
    target?.type === 'duration'
      ? new Date(Date.now() + target.duration).getTime()
      : target?.type === 'epoch'
        ? target?.epoch ?? Date.now()
        : cronParser.parseExpression(target!.cron).next().getTime()
  );

  useEffect(() => {
    if (countDown > 0) {
      intervalRef.current = setInterval(() => {
        const newCountDown = normalize(
          countDownDateRef.current - Date.now(),
          updateInterval
        );

        if (newCountDown !== ref.current) {
          if (newCountDown > 0) {
            setCountDown(newCountDown);
            ref.current = newCountDown;
          } else {
            setCountDown(0);
            ref.current = 0;
            clearInterval(intervalRef.current);
          }
        }
      }, updateInterval / 10);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return getCountdownValues(Math.max(0, countDown));
};

export { getCountdownValues, useCountdown };
