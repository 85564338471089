/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { useActionDispatch } from '../../../hooks';
import { AttributeProvider } from './context/AttributeContext';
import { useVariantSelector } from './hooks/useVariantSelector';

import type { ElemasonVariantSelectorWidget } from '@tectonic/types';
import type { FC, MouseEventHandler } from 'react';

interface VariantSelectorProps {
  widget: ElemasonVariantSelectorWidget;
}

const VariantSelectorWidget: FC<VariantSelectorProps> = ({ widget }) => {
  const {
    product,
    selectedVariant,
    variantAttributes,
    attributeFragment,
    setSelectedAttribute,
    attributeFragmentValue,
  } = useVariantSelector(widget);

  const actionDispatch = useActionDispatch();
  const [style, className] = useStyleConfigV2(widget.config?.container);

  const handleOnClick: MouseEventHandler = (e) => {
    if (widget.actions?.onClick && widget.actions?.onClick?.length > 0) {
      e.stopPropagation();
      widget.actions?.onClick?.forEach((action) => actionDispatch(action));
    }
  };

  return (
    <div
      style={style}
      onClick={handleOnClick}
      className={clsx(
        'flex',
        widget.config?.container?.direction === 'row' ? 'flex-row' : 'flex-col',
        className
      )}
    >
      {Object.entries(variantAttributes).map(([attr, value]) => (
        <AttributeProvider
          key={attr}
          value={{
            // @ts-ignore
            attr,
            // @ts-ignore
            onSelect: (v: any) => setSelectedAttribute(attr, v),
          }}
        >
          <ElemasonFragmentProvider
            key={attr}
            value={attributeFragmentValue({ product, value, selectedVariant })}
          >
            {attributeFragment?.cells.map((cell) => (
              <Cell
                key={cell.id}
                cell={cell}
                style={{
                  pointerEvents:
                    widget.actions?.onClick && widget.actions.onClick.length > 0
                      ? 'none'
                      : 'initial',
                }}
              />
            ))}
          </ElemasonFragmentProvider>
        </AttributeProvider>
      ))}
    </div>
  );
};

export { VariantSelectorWidget };
