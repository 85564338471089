import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { ElemasonComponentType } from '@tectonic/types';
import { Button, Toast as UiToast, useThemeContext } from '@tectonic/uikit';
import clsx from 'clsx';
import { useRef } from 'react';
import { useOnClickOutside } from '../../../hooks/dom';
import { useElemasonComponentConfig } from '../../../hooks/useElemasonConfig';
import { useToast } from './store';

import type { ToastConfig } from '@tectonic/types';

const Toast = () => {
  const { toast, close } = useToast();
  const { colors } = useThemeContext();
  const ref = useRef<HTMLLIElement>(null);

  const toastConfig = useElemasonComponentConfig<ToastConfig>(
    ElemasonComponentType.Toast
  );
  const [style, className] = useStyleConfigV2(toastConfig.container);

  const onClickOutside = () => {
    close?.();
  };

  useOnClickOutside(ref, onClickOutside);

  return (
    <UiToast.Provider duration={toast?.duration ?? 3000}>
      <UiToast.Root
        ref={ref}
        style={{
          ...style,
          backgroundColor:
            colors[toast?.backgroundColor!] ?? style.backgroundColor,
        }}
        open={!!toast}
        onOpenChange={() => close()}
        className={clsx(className, 'flex flex-col items-center justify-center')}
      >
        <UiToast.Title className="flex items-center justify-between">
          <Text data={toast?.title} config={toastConfig.title} />

          {!!toast?.action && (
            <Button
              variant="ghost"
              size="2xs"
              className="ml-10 text-white"
              label={toast.action.label}
              onClick={() => {
                toast.action!.onClick();
                close();
              }}
            />
          )}
        </UiToast.Title>
        {!!toast?.description && (
          <UiToast.Description>
            <Text data={toast?.description} config={toastConfig.description} />
          </UiToast.Description>
        )}
      </UiToast.Root>
      <UiToast.Viewport
        className={clsx(
          'fixed bottom-0 left-1/2 z-30 flex w-full max-w-[90%] -translate-x-1/2 flex-col items-center justify-center',
          { 'pointer-events-none': !toast?.action }
        )}
      />
    </UiToast.Provider>
  );
};

export { Toast };
