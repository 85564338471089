import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsButtonEventNames } from '@tectonic/analytics';
import { updateCollectionProductSearchFacets } from '@tectonic/remix-client-network';
import {
  ElemasonWidgetActionType,
  SearchOperator,
  type ElemasonModifyFilterValueWidget,
} from '@tectonic/types';
import { toUrlSearchParams } from '@tectonic/utils';
import { omit } from 'lodash-es';
import { type FC, type MouseEvent } from 'react';
import { Button } from '../../components';
import { useElemasonContext } from '../../contexts';
import { useActionDispatch, useHaloScript } from '../../hooks';
import { updateSearchFacets } from '../../hooks/network';
import { queryKeys } from '../../queryKeys';

import type { AxiosError } from 'axios';

interface ElemasonModifyFilterValueWidgetProps {
  widget: ElemasonModifyFilterValueWidget;
}

const ModifyFilterValueWidget: FC<ElemasonModifyFilterValueWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const queryClient = useQueryClient();
  const dispatch = useActionDispatch();

  const mode = useHaloScript(data?.mode);
  const filter = useHaloScript(data?.filter);
  const filters = useHaloScript(data?.filters);
  const { searchParams } = useElemasonContext();
  const filterValue = useHaloScript(data?.filterValue);
  const searchConfig = useHaloScript(data?.searchConfig);
  const placeholders = useHaloScript(data?.placeholders);
  const collectionSlug = useHaloScript(data?.collectionSlug);

  const removeMutation = useMutation({
    mutationFn: async () => {
      const updatedFilters = filters!
        .map((f) => {
          if (f.field === filter?.field) {
            if (f.operator === 'IN' && Array.isArray(f.value)) {
              return { ...f, value: f.value.filter((v) => v !== filterValue) };
            }

            if (f.operator === 'IS' && f.value === filterValue) {
              return { ...f, value: undefined };
            }
          }
          return f;
        })
        .filter((f) => {
          if (f.operator === 'IN' && Array.isArray(f.value)) {
            return f.value.length > 0;
          }

          if (f.operator === 'IS') {
            return f.value !== undefined;
          }

          return true;
        });

      const { filterBy, ...params } = searchParams;
      const resp = await updateSearchFacets(
        // @ts-ignore
        { intent: 'apply', filters: updatedFilters },
        params,
        searchConfig!
      );

      if (!resp.data) return;

      const nSearchParams = toUrlSearchParams({
        ...omit(searchParams, ['page']),
        ...omit(resp.data.executedQuery as object, ['page']),
      });

      window.location.search = nSearchParams;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (resp) => {
      actions?.onSuccess?.forEach((action) => dispatch(action, resp));
    },
    onError: (error: AxiosError) => {
      actions?.onError?.forEach((action) => dispatch(action, error));
    },
  });

  const addMutation = useMutation({
    mutationFn: async () => {
      const currentFilter = filters!.find((f) => f.field === filter?.field);
      let updatedFilters = currentFilter
        ? filters
        : [
            ...filters!,
            {
              field: filter?.field,
              operator: 'IN',
              value: [] as string[],
            },
          ];

      if (
        updatedFilters!.some(
          (f) =>
            f.field === filter?.field &&
            (f.value as string[]).includes(filter?.value as string)
        )
      ) {
        return;
      }

      updatedFilters = updatedFilters!.map((f) => {
        if (f.field === filter?.field) {
          if (f.operator === 'IN' && Array.isArray(f.value)) {
            return { ...f, value: [...f.value, filterValue!] };
          }
        }
        return f;
      });

      const { filterBy, ...params } = searchParams;
      const resp = await updateSearchFacets(
        // @ts-ignore
        { intent: 'apply', filters: updatedFilters },
        params,
        searchConfig!
      );

      if (!resp.data) return;

      const nSearchParams = toUrlSearchParams({
        ...omit(searchParams, ['page']),
        ...omit(resp.data.executedQuery as object, ['page']),
      });

      window.location.search = nSearchParams;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (resp) => {
      actions?.onSuccess?.forEach((action) => dispatch(action, resp));
    },
    onError: (error: AxiosError) => {
      actions?.onError?.forEach((action) => dispatch(action, error));
    },
  });

  const collectionRemoveMutation = useMutation({
    mutationFn: async () => {
      const updatedFilters = filters!
        .map((f) => {
          if (f.field === filter?.field) {
            if (f.operator === 'IN' && Array.isArray(f.value)) {
              return { ...f, value: f.value.filter((v) => v !== filterValue) };
            }

            if (f.operator === 'IS' && f.value === filterValue) {
              return { ...f, value: undefined };
            }
          }
          return f;
        })
        .filter((f) => {
          if (f.operator === 'IN' && Array.isArray(f.value)) {
            return f.value.length > 0;
          }

          if (f.operator === 'IS') {
            return f.value !== undefined;
          }

          return true;
        });

      const resp = await updateCollectionProductSearchFacets(
        collectionSlug!,
        {
          intent: 'apply',
          // @ts-ignore
          filters: updatedFilters,
          excludeImplicitFilters: config?.excludeImplicitFilters,
        },
        searchConfig!
      );

      if (!resp.data) return;

      const nSearchParams = toUrlSearchParams({
        ...omit(searchParams, ['page']),
        ...omit(resp.data.executedQuery as object, ['page']),
      });

      window.location.search = nSearchParams;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (resp) => {
      actions?.onSuccess?.forEach((action) => dispatch(action, resp));
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsButtonEventNames.BUTTON_CLICK,
          data: {
            text: data?.placeholders,
          },
        },
      });
    },
    onError: (error: AxiosError) => {
      actions?.onError?.forEach((action) => dispatch(action, error));
    },
  });

  const collectionAddMutation = useMutation({
    mutationFn: async () => {
      const currentFilter = filters!.find((f) => f.field === filter?.field);
      let updatedFilters = currentFilter
        ? filters
        : [
            ...filters!,
            {
              field: filter?.field,
              operator: 'IN',
              value: [] as string[],
            },
          ];

      if (
        updatedFilters!.some(
          (f) =>
            f.field === filter?.field &&
            ((f.value as string[]).includes(filter?.value as string) ||
              f.value === filter?.value)
        )
      ) {
        return;
      }

      updatedFilters = updatedFilters!.map((f) => {
        if (f.field === filter?.field) {
          if (f.operator === 'IN' && Array.isArray(f.value)) {
            return { ...f, value: [...f.value, filterValue!] };
          }
          if (f.operator === 'IS') {
            return {
              ...f,
              operator: SearchOperator.IN,
              value: [filterValue!],
            };
          }
        }
        return f;
      });

      const resp = await updateCollectionProductSearchFacets(
        collectionSlug!,
        {
          intent: 'apply',
          // @ts-ignore
          filters: updatedFilters,
          excludeImplicitFilters: config?.excludeImplicitFilters,
        },
        searchConfig!
      );

      if (!resp.data) return;

      const nSearchParams = toUrlSearchParams({
        ...omit(searchParams, ['page']),
        ...omit(resp.data.executedQuery as object, ['page']),
      });

      window.location.search = nSearchParams;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (resp) => {
      actions?.onSuccess?.forEach((action) => dispatch(action, resp));
    },
    onError: (error: AxiosError) => {
      actions?.onError?.forEach((action) => dispatch(action, error));
    },
  });

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (mode === 'remove') {
      if (collectionSlug) {
        collectionRemoveMutation.mutate();
      } else {
        removeMutation.mutate();
      }
    } else if (collectionSlug) {
      collectionAddMutation.mutate();
    } else {
      addMutation.mutate();
    }
  };

  return (
    <Button
      data={data}
      onClick={onClick}
      config={config?.button}
      size={config?.button?.size}
      placeholders={placeholders}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      style={{
        color: config?.button?.text?.color,
        textWrap: config?.button?.text?.textWrap,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
      isLoading={addMutation.isPending || removeMutation.isPending}
    />
  );
};

export { ModifyFilterValueWidget };
