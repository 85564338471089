import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';

import type { FC } from 'react';
import type { ProductDiscountProps } from './ProductPricing.types';

// TODO: Move it to constants.
const DEFAULT_TEMPLATE = '{discount}% off';

const ProductDiscount: FC<ProductDiscountProps> = ({ discount, config }) => {
  // Discount can we represented in formats such as %, absolute values, etc
  // To avoid hard coding values, we rely on the template. Therefore, any formatting
  // on the discount must be done based on the template.
  const template = config?.template ?? DEFAULT_TEMPLATE;

  const [cStyle, cClassName] = useStyleConfigV2(config?.container ?? {});

  if (!config?.visible) return null;

  return (
    <div style={cStyle} className={cClassName}>
      <Text
        data={template}
        config={config?.text}
        placeholders={{ discount: `${discount ?? ''}` }}
      />
    </div>
  );
};

ProductDiscount.displayName = 'ElemasonProductDiscount';

export { ProductDiscount };
