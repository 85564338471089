import { Icon as UiIcon } from '@tectonic/uikit';

import type { IconConfig } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface IconProps
  extends Omit<ComponentProps<typeof UiIcon>, 'size' | 'color'> {
  config?: IconConfig;
}

const Icon: FC<IconProps> = ({ config, style, ...others }) => (
  <UiIcon style={style} size={config?.size} color={config?.color} {...others} />
);

export { Icon };
