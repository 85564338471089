import { create } from 'zustand';

import type { AuthStore } from './auth.types';

const initialState: Omit<AuthStore, 'flush' | 'setStep' | 'setData'> = {
  currentStep: null,
  currentMode: null,
  credentials: { phoneNumber: null, email: null },
  profileUpdates: null,
  user: null,
  emailLinking: { required: false, phoneVerificationCode: null },
};

const useAuthStore = create<AuthStore>((setState) => ({
  ...initialState,
  flush: () => {
    setState(initialState);
  },
  setStep: (step, payload) => {
    setState((state) => {
      const { credentials } = state;
      const nCredentials = {
        ...credentials,
        phoneNumber: payload?.phoneNumber ?? credentials?.phoneNumber,
        email: payload?.email ?? credentials?.email,
      };
      const emailLinking = {
        ...state.emailLinking,
        ...payload?.emailLinking,
      };
      return {
        emailLinking,
        currentStep: step,
        currentMode: payload?.mode ?? state.currentMode,
        user: payload?.user ?? state.user,
        credentials: nCredentials,
        profileUpdates: payload?.profileUpdates ?? state.profileUpdates,
      };
    });
  },
  setData: (payload) => {
    setState(payload);
  },
}));

export { useAuthStore };
