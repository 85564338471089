import { useStyleConfig } from '@tectonic/elemason-components';
import { isVisible } from '../../../utils';
import { ContentShimmer } from '../../Shimmer';

import type { FC } from 'react';
import type { SearchTrendsSkeletonProps } from './SearchTrends.types';

const SearchTrendsSkeleton: FC<SearchTrendsSkeletonProps> = ({ config }) => {
  const { header, container } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  const headerStyleConfig = useStyleConfig(header?.container ?? {});
  const queryListStyleConfig = useStyleConfig(
    config?.queryList?.container ?? {}
  );

  const queryContainerProps = useStyleConfig(config?.query?.container ?? {});

  const isHeaderVisible = isVisible(header);

  return (
    <div {...containerStyleConfig}>
      {isHeaderVisible ? (
        <div {...headerStyleConfig}>
          <ContentShimmer>Loading....</ContentShimmer>
        </div>
      ) : null}
      <div {...queryListStyleConfig}>
        {Array.from({ length: 4 }, (_item, index) => (
          <div key={index} {...queryContainerProps}>
            <ContentShimmer key={index}>Loading...</ContentShimmer>
          </div>
        ))}
      </div>
    </div>
  );
};

SearchTrendsSkeleton.displayName = 'SearchTrendsSkeleton';

export { SearchTrendsSkeleton };
