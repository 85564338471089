import { useStyleConfigV2, useTextConfig } from '@tectonic/elemason-components';
import { Button as UiButton, useColors } from '@tectonic/uikit';
import { populate } from '@tectonic/utils';
import clsx from 'clsx';
import { isUndefined, omitBy } from 'lodash-es';
import {
  forwardRef,
  type ComponentProps,
  type ForwardRefRenderFunction,
} from 'react';

import type {
  Color,
  ElemasonButtonConfig,
  ElemasonButtonData,
} from '@tectonic/types';

interface ButtonProps
  extends Omit<
    ComponentProps<typeof UiButton>,
    'label' | 'endIcon' | 'startIcon'
  > {
  data?: ElemasonButtonData;
  config?: ElemasonButtonConfig;
  placeholders?: Record<string, string | number>;
}

export const useButtonConfig = (config: ElemasonButtonConfig) => {
  const colors = useColors();
  const [bStyle, bClassName] = useStyleConfigV2(config ?? {});
  const [textStyle, textClassName] = useTextConfig(config?.text ?? {});

  const buttonProps = {
    size: config?.size,
    variant: config?.variant,
    disabled: config?.disabled,
    modifier: config?.modifier || 'primary',
  };

  return {
    style: {
      ...bStyle,
      ...textStyle,
      color: colors[config?.text?.color as Color] ?? config?.text?.color,
    },
    buttonProps,
    className: clsx(bClassName, textClassName),
  };
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { config, data, style, placeholders, className, ...props },
  ref
) => {
  const {
    buttonProps,
    style: bStyle,
    className: bClassName,
  } = useButtonConfig(config ?? {});

  const finalClassName = clsx(className, bClassName);
  const finalText = populate(data?.text ?? '', placeholders ?? {});

  return (
    <UiButton
      ref={ref}
      label={finalText}
      endIcon={data?.endIcon}
      startIcon={data?.startIcon}
      className={finalClassName}
      style={{
        ...style,
        ...bStyle,
      }}
      {...omitBy(buttonProps, isUndefined)}
      {...omitBy(props, isUndefined)}
    />
  );
};

const ExoticButton = forwardRef(Button);

export { ExoticButton as Button };
