import type { HaloScript } from '@tectonic/types';

const isHaloScript = (obj: any): obj is HaloScript => {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return false;
  }
  return 'type' in obj && obj.type === 'Script';
};

export { isHaloScript };
