import { getCookie } from '../helpers';

enum ShopifyIds {
    USER_TOKEN = '_shopify_y',
    SESSION_TOKEN = '_shopify_s',
}

const getShopifyIds = () => ({
    ttShopifySessionId: getCookie(ShopifyIds.SESSION_TOKEN),
    ttShopifyUserId: getCookie(ShopifyIds.USER_TOKEN),
});


export { getShopifyIds };
