import type {
  ApplyExpression,
  ArrayExpression,
  AssignmentExpression,
  ConditionalExpression,
  Expression,
  Identifier,
  LambdaExpression,
  Literal,
  LoopExpression,
  MemberExpression,
  ObjectExpression,
  Operators,
  Script,
  UnaryOperators,
} from './types';

const literal = (
  value: string | number | boolean | null | undefined
): Literal => ({
  type: 'Literal',
  value,
});

const identifier = (name: string | Expression): Identifier => ({
  type: 'Identifier',
  name,
});

const member = (
  object: Expression,
  property: Expression
): MemberExpression => ({ type: 'MemberExpression', object, property });

const assignment = (
  left: MemberExpression | Identifier,
  right: Expression
): AssignmentExpression => ({ type: 'AssignmentExpression', left, right });

const unaryExpression = (
  operator: UnaryOperators,
  argument: Expression
): Expression => ({ type: 'UnaryExpression', operator, argument });

const binaryExpression = (
  left: Expression,
  operator: Operators,
  right: Expression
): Expression => ({ type: 'BinaryExpression', left, operator, right });

const accessor = ([
  object,
  property,
  ...rest
]: Expression[]): MemberExpression => {
  if (rest.length === 0) {
    return member(object, property);
  }

  return accessor([member(object, property), ...rest]);
};

const lambda = (args: string[], body: Expression[]): LambdaExpression => ({
  type: 'LambdaExpression',
  arguments: args,
  body,
});

const apply = (
  fn: Expression,
  args: Expression[],
  thisArg?: any
): ApplyExpression => ({
  type: 'ApplyExpression',
  function: fn,
  arguments: args,
  thisArg,
});

const conditional = (
  test: Expression,
  consequent: Expression[],
  alternate?: Expression[]
): ConditionalExpression => ({
  type: 'ConditionalExpression',
  test,
  consequent,
  alternate,
});

const loop = (
  init: Expression[],
  test: Expression,
  body: Expression[],
  update: Expression[]
): LoopExpression => ({
  type: 'LoopExpression',
  init,
  test,
  body,
  update,
});

const array = (...items: Expression[]): ArrayExpression => ({
  type: 'ArrayExpression',
  items,
});

const object = (items: Record<string, Expression>): ObjectExpression => ({
  type: 'ObjectExpression',
  entries: Object.entries(items).map(([key, value]) => ({ key, value })),
});

const script = <T = unknown>(body: Expression[]): Script<T> => ({
  type: 'Script',
  body,
});

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const namespace = '___halo___';

const withNamespace = (key: string): string => `${namespace}.${key}`;

export {
  accessor,
  apply,
  array,
  assignment,
  binaryExpression,
  canUseDOM,
  conditional,
  identifier,
  lambda,
  literal,
  loop,
  member,
  object,
  script,
  unaryExpression,
  withNamespace,
};
