import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { memo, useEffect, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import {
  useActionDispatch,
  usePageFragment,
  useSharedLocalState,
} from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonDataSwitchWidget } from '@tectonic/types';

interface ElemasonDataSwitchWidgetProps {
  widget: ElemasonDataSwitchWidget;
}

const DataSwitchWidget: FC<ElemasonDataSwitchWidgetProps> = ({
  widget: { config, data, actions },
}) => {
  const value = useHaloScript(data?.value);
  const stateKey = useHaloScript(data?.stateKey);
  const clearable = useHaloScript(data?.clearable);
  const isDefault = useHaloScript(data?.isDefault);
  const fragment = usePageFragment(data?.fragment);
  const [style, className] = useStyleConfigV2(config?.container);
  const dispatch = useActionDispatch();

  const { sharedState, setSharedState } = useSharedLocalState(
    stateKey!,
    isDefault ? value : undefined
  );
  const [activeStyle, activeClassName] = useStyleConfigV2(config?.activeConfig);
  const [inActiveStyle, inActiveClassName] = useStyleConfigV2(
    config?.inactiveConfig
  );

  const isActive = isEqual(sharedState, value);

  useEffect(() => () => setSharedState(undefined), [setSharedState]);

  const handleOnClick = () => {
    if (clearable && isEqual(sharedState, value)) {
      setSharedState(null);
    } else {
      setSharedState(value);
    }
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  const fragmentValue = useFragmentValue(fragment);

  if (!fragment) return null;

  return (
    <Clickable
      onClick={handleOnClick}
      style={{ ...style, ...(isActive ? activeStyle : inActiveStyle) }}
      className={clsx(
        className,
        isActive ? activeClassName : inActiveClassName
      )}
    >
      <ElemasonFragmentProvider
        value={fragmentValue({
          switchValue: value,
          switchStateKey: stateKey,
          isActiveSwitch: isActive,
        })}
      >
        {fragment.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </Clickable>
  );
};

const MemoizedDataSwitchWidget = memo(DataSwitchWidget);

export { MemoizedDataSwitchWidget as DataSwitchWidget };
