import { evaluate } from '@tectonic/halo-script';
import { Logger } from '@tectonic/logger';
import { useCallback, useMemo } from 'react';
import {
  useElemasonBlockContext,
  useElemasonCellContext,
  useElemasonContext,
} from '../contexts';
import { useElemasonActionContext } from '../contexts/ElemasonActionContext';
import { useElemasonFragmentContext } from '../contexts/ElemasonFragmentContext';
import { useElemasonPageContext } from '../contexts/ElemasonPageContext';

import type { HaloScript } from '@tectonic/types';

const evaluateWithContext = (
  cell: any,
  block: any,
  params: any,
  data: any,
  scope: any,
  fragment: any,
  action: any,
  other?: any,
  script?: HaloScript,
  dispatch?: any,
  actions?: any
) => {
  if (script) {
    const context = {
      cell,
      block,
      params,
      fragment,
      page: data,
      action,
      other,
      local: scope,
      dispatch,
      actions,
    };

    const value = evaluate(script, context);

    if (script.debug) {
      Logger.debug(script.debug, value, { script, context });
    }

    return value;
  }

  return undefined;
};

const useHaloScript = <T = unknown>(script?: HaloScript<T>): T | undefined => {
  const cell = useElemasonCellContext();
  const block = useElemasonBlockContext();
  const actions = useElemasonActionContext();
  const { dispatch } = useElemasonPageContext();
  const { data, scope } = useElemasonPageContext();
  const fragmentContext = useElemasonFragmentContext();
  const { routeParams, searchParams } = useElemasonContext();

  return useMemo(
    () =>
      evaluateWithContext(
        cell,
        block,
        {
          ...routeParams,
          ...searchParams,
          __tt__internal_params: { routeParams, searchParams },
        },
        data,
        scope,
        fragmentContext,
        undefined,
        undefined,
        script,
        dispatch,
        actions
      ),
    [cell, script, routeParams, searchParams, data, scope, fragmentContext]
  );
};

const useHaloScriptEvaluator = () => {
  const cell = useElemasonCellContext();
  const block = useElemasonBlockContext();
  const actions = useElemasonActionContext();
  const { dispatch } = useElemasonPageContext();
  const { data, scope } = useElemasonPageContext();
  const fragmentContext = useElemasonFragmentContext();
  const { routeParams, searchParams, pathname } = useElemasonContext();

  const callback = useCallback(
    (script: HaloScript, other?: any) =>
      evaluateWithContext(
        cell,
        block,
        {
          ...routeParams,
          ...searchParams,
          pathname,
          __tt__internal_params: { routeParams, searchParams },
        },
        data,
        scope,
        fragmentContext,
        undefined,
        other,
        script,
        dispatch,
        actions
      ),
    [
      cell,
      block,
      actions,
      dispatch,
      data,
      fragmentContext,
      routeParams,
      searchParams,
      scope,
      pathname,
    ]
  );

  return callback;
};

export { evaluateWithContext, useHaloScript, useHaloScriptEvaluator };
