import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { useElemasonPageContext } from '../../../contexts';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { useVariantSelect } from './hooks/useVariantSelect';

import type {
  ElemasonVariantSelectWidget,
  ProductVariant,
} from '@tectonic/types';
import type { FC, MouseEvent } from 'react';

interface VariantSelectProps {
  widget: ElemasonVariantSelectWidget;
}

const VariantSelectWidget: FC<VariantSelectProps> = ({ widget }) => {
  const { dispatch } = useElemasonPageContext();
  const {
    product,
    fragment,
    selectedVariant,
    setSelectedVariant,
    fragmentValue,
  } = useVariantSelect(widget);

  const [style, className] = useStyleConfigV2(widget.config?.container);

  const onVariantSelect = ({
    variant,
    userProfileData,
  }: {
    variant: ProductVariant;
    userProfileData?: any;
  }) => {
    setSelectedVariant({ variant, userProfileData });
  };

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();

    dispatch({
      type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
      payload: {
        slug: widget.data?.drawerSlug ?? 'variant-selector',
        data: {
          product,
          selectedVariant,
          onVariantSelect,
        },
      },
    });
  };

  return (
    <Clickable
      style={style}
      onClick={onClick}
      className={clsx('flex flex-col', className)}
    >
      <ElemasonFragmentProvider
        value={fragmentValue({ product, selectedVariant })}
      >
        {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    </Clickable>
  );
};

export { VariantSelectWidget };
