import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { Clickable, IconSymbol } from '@tectonic/uikit';

import type { FC } from 'react';
import type { SearchSortOptionProps } from './SearchSortFacet.types';
import { Icon } from '../../Icon';

const defaultTemplate = '{displayName}';

const SearchSortOption: FC<SearchSortOptionProps> = ({
  isApplied,
  option,
  data,
  config,
  onSelect,
}) => {
  const { default: defaultConfig, applied: appliedConfig } = config ?? {};

  const fConfig = isApplied ? appliedConfig : defaultConfig;
  const containerProps = useStyleConfig(fConfig?.container ?? {});
  const fIcon = isApplied ? data?.selectedIcon : data?.unSelectedIcon;

  const text = data?.text ?? defaultTemplate;

  return (
    <Clickable onClick={() => onSelect(option)} {...containerProps}>
      {fIcon && <Icon symbol={fIcon as IconSymbol} config={fConfig?.icon} />}
      <Text
        data={text}
        config={fConfig?.text}
        placeholders={{ displayName: option.displayName }}
      />
    </Clickable>
  );
};

SearchSortOption.displayName = 'SearchSortOption';

export { SearchSortOption };
