import { useInfiniteQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useFragmentValue, useHaloScript, usePageFragment } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type {
  CursorPaginationOptions,
  ElemasonOrdersWidget,
  PaginatedOrders,
} from '@tectonic/types';

const getOrders = async (
  params: CursorPaginationOptions
): Promise<PaginatedOrders> => {
  const response = await remixApi.getOrders(params);
  if (isEmpty(response.data) || response.error) {
    throw response.error;
  }
  return response.data;
};

const useOrders = (widget: ElemasonOrdersWidget) => {
  const wData = widget.data!;

  const pagination = useHaloScript<Pick<CursorPaginationOptions, 'first'>>(
    wData.pagination
  );
  const { first } = pagination ?? {};

  const {
    data: response,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage: onLoadMore,
  } = useInfiniteQuery({
    initialPageParam: { first, after: null } as CursorPaginationOptions,
    queryFn: async ({ pageParam }) => getOrders(pageParam),
    queryKey: [queryKeys.orders()],
    getNextPageParam: (lastPage) => {
      if (lastPage.pageInfo.hasNextPage) {
        return { after: lastPage.pageInfo.endCursor, first };
      }
      return null;
    },
  });

  const orders = useMemo(() => {
    if (isEmpty(response)) {
      return [];
    }

    return response.pages.flatMap((page) =>
      page.edges.map((edge) => edge.node)
    );
  }, [response]);

  const onEndReached = useCallback(
    (inView: boolean) => {
      if (!hasNextPage || !inView) {
        return;
      }
      onLoadMore();
    },
    [hasNextPage, onLoadMore]
  );

  const [inViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: onEndReached,
  });

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    orders,
    isError,
    hasNextPage,
    onLoadMore,
    isLoading: isLoading || isFetchingNextPage,
  });

  return { fragmentData, fragment, inViewRef };
};

export { useOrders };
