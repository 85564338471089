import { isEqual } from 'lodash-es';
import { memo, useMemo, useRef, type FC } from 'react';
import { useHaloScript } from '../../hooks/useHaloScript';
import { GenericCarousel } from '../containers/GenericCarousel';

import type { ElemasonGenericCarouselWidget } from '@tectonic/types';

interface GenericCarouselProps {
  widget: ElemasonGenericCarouselWidget;
}

const GenericCarouselWidget: FC<GenericCarouselProps> = ({
  widget: { config, data },
}) => {
  const list = useHaloScript(data?.list) as Array<unknown>;
  const startIndex = useHaloScript(data?.startIndex);
  const listRef = useRef(list);

  const mList = useMemo(() => {
    if (isEqual(listRef.current, list)) {
      return listRef.current;
    }
    listRef.current = list;
    return listRef.current;
  }, [listRef, list]);

  return (
    <GenericCarousel
      slides={mList}
      fragment={data?.fragment}
      config={config?.carousel}
      startIndex={startIndex}
    />
  );
};

const MemoizedGenericCarouselWidget = memo(GenericCarouselWidget);

export { MemoizedGenericCarouselWidget as GenericCarouselWidget };
