import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { sum } from 'lodash-es';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import { useFragmentValue, useHaloScript, usePageFragment } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type { ElemasonReviewsWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ReviewCoreProps {
  sortBy?: string;
  widget: ElemasonReviewsWidget;
}

const ReviewCore: FC<ReviewCoreProps> = ({
  widget: { data, config },
  sortBy,
}) => {
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const productSlug = useHaloScript(data?.productSlug);
  const perPage = useHaloScript(config?.reviewsPerPage);
  const filterExpressions = useHaloScript(data?.filterExpressions);

  const {
    data: searchConfig,
    isError: isSearchConfigError,
    isLoading: isSearchConfigLoading,
  } = useQuery({
    queryKey: queryKeys.reviewsSearchConfig(),
    queryFn: () => remixApi.searchConfig({ entity: 'REVIEW' }),
  });

  const {
    isError,
    isLoading,
    hasNextPage,
    data: reviewsData,
    isFetchingNextPage,
    fetchNextPage: fetchNextReviews,
  } = useInfiniteQuery({
    queryKey: queryKeys.reviewsSearchForProduct(
      productSlug,
      sortBy ?? 'defaultRating:desc',
      filterExpressions
    ),
    initialPageParam: 1,
    queryFn: async ({ pageParam }) =>
      remixApi.searchReviewsForProduct(productSlug!, {
        q: '*',
        perPage,
        page: pageParam,
        filterExpressions,
        sortBy: sortBy ?? 'defaultRating:desc',
      }),
    getNextPageParam: (lastPage, allPages, _lastPageParam) => {
      const allPagesCount = sum(
        allPages.map((pageData) => pageData.hits.length)
      );

      if (allPagesCount >= lastPage.found) {
        return undefined;
      }

      return lastPage.page + 1;
    },
  });

  const fragmentData = fragmentValue({
    isError,
    isLoading,
    hasNextPage,
    reviewsData,
    fetchNextReviews,
    isFetchingNextPage,
    isSearchConfigError,
    isSearchConfigLoading,
    sortConfig: searchConfig?.data?.config?.sortConfig,
  });

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell, idx) => (
        <Cell key={cell.id ?? idx} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  );
};

export { ReviewCore };
