import { ElemasonFragmentProvider } from '../../../contexts';
import { Cell } from '../../../core';
import { useSearchTopLineFilter } from './hooks';

import type { FC } from 'react';
import type { SearchTopLineFilterWidgetProps } from './SearchTopLineFilter.types';

const SearchTopLineFilterWidget: FC<SearchTopLineFilterWidgetProps> = ({
  widget,
}) => {
  const { fragment, fragmentData } = useSearchTopLineFilter(widget);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

SearchTopLineFilterWidget.displayName = 'SearchTopLineFilterWidget';

export { SearchTopLineFilterWidget };
