import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { isNil } from 'lodash-es';
import { useElemasonContext } from '../contexts';
import { useHaloScript } from '../hooks';
import { SeoLink } from './SeoLink';

import type {
  ElemasonCell,
  NavigationActionPayload,
  StyleConfig,
} from '@tectonic/types';
import type { FC } from 'react';

/**
 * For better seo, navigation between pages should be done using anchor tags.
 * Elemason doesn't have link based navigation. We have action based navigation.
 * Because of that we need to resolve the actions that are used as navigation.
 */
const Seo: FC<Pick<ElemasonCell, 'seo'>> = ({ seo }) => {
  const sMetadata = useHaloScript<{
    payload: NavigationActionPayload;
    text: string;
    style: StyleConfig;
  }>(seo);

  const [style, className] = useStyleConfigV2(sMetadata?.style);
  const { navigationRegistry } = useElemasonContext();

  if (isNil(sMetadata)) {
    return null;
  }

  const { payload, text = null } = sMetadata;

  const href = navigationRegistry.getHref(payload);

  return (
    <SeoLink href={href} style={style} className={className}>
      {text}
    </SeoLink>
  );
};

Seo.displayName = 'Seo';

export { Seo };
