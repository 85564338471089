import { Logger } from '@tectonic/logger';
import invariant from 'invariant';
import { includes } from 'lodash-es';
import {
  AnalyticsAuthEventNames,
  AnalyticsCartEventNames,
  AnalyticsProductEventNames,
  AnalyticsSearchEventNames,
} from '../constants';
import { trackAuthEvent } from './auth';
import { trackCartEvent, trackCartProductEvent } from './cart';
import { trackEvent } from './event';
import { trackProductEvent } from './product';
import { trackSearchEvent } from './search';

import type {
  ElemasonAnalyticsActionPayload,
  ElemasonAnalyticsContextType,
} from '../types';

const generatePageInfoFromContext = (
  analyticsContext: ElemasonAnalyticsContextType
) => {
  const { previousPage, currentPage, widget, metadata } = analyticsContext;
  return {
    metadata,
    pageId: currentPage?.pageId,
    pageSlug: currentPage?.pageSlug,
    screenName: currentPage?.screenName,
    pageVersion: currentPage?.pageVersion,
    widgetId: widget.id,
    widgetVersion: widget.version,
    widgetType: widget.type,
    previousPageVersion: previousPage?.pageVersion,
    previousPageSlug: previousPage?.pageSlug,
    previousPageId: previousPage?.pageId,
  };
};

const AnalyticsProductEventNamesList = (<any>Object).values(
  AnalyticsProductEventNames
);

const AnalyticsCartEventNamesList = (<any>Object).values(
  AnalyticsCartEventNames
);

const AnalyticsSearchEventNamesList = (<any>Object).values(
  AnalyticsSearchEventNames
);

const AnalyticsAuthEventNamesList = (<any>Object).values(
  AnalyticsAuthEventNames
);

const trackElemasonAction = (payload: ElemasonAnalyticsActionPayload) => {
  try {
    const { event: eventName, data, analyticsContext } = payload;
    invariant(analyticsContext, 'Analytics context is required');

    // if (isInDebugMode()) {
    //     Logger.info(
    //         "====[ELEMASON_ANALYTICS_ACTION_TRACK]====",
    //         "\n event", eventName,
    //         "\n data", data,
    //         "\n analyticsContext", analyticsContext
    //     )
    // }

    const { entities: contextEntities } = analyticsContext;
    const { entities: overrideEntities, error, ...rest } = data ?? {};

    const pageInfo = generatePageInfoFromContext(analyticsContext);
    const finalEntities = { ...contextEntities, ...overrideEntities };

    if (AnalyticsProductEventNamesList.includes(eventName)) {
      trackProductEvent(eventName, {
        ...pageInfo,
        ...rest,
        product: finalEntities.product!,
        variant: finalEntities.productVariant,
        error,
      });
    } else if (AnalyticsCartEventNamesList.includes(eventName)) {
      const { quantity, ...restCartPayload } = rest;

      // TODO Hack find better way to do this
      if (includes(eventName.toLowerCase(), 'product')) {
        trackCartProductEvent(eventName, {
          ...pageInfo,
          cart: finalEntities.cart,
          product: finalEntities.product,
          variant: finalEntities.productVariant,
          quantity: quantity as number,
          ...restCartPayload,
          error,
        });
      } else {
        trackCartEvent(eventName, {
          ...pageInfo,
          cart: finalEntities.cart,
          product: finalEntities.product,
          variant: finalEntities.productVariant,
          quantity: quantity as number,
          ...restCartPayload,
          error,
        });
      }
    } else if (AnalyticsSearchEventNamesList.includes(eventName)) {
      trackSearchEvent(eventName, { ...pageInfo, ...rest, error });
    } else if (AnalyticsAuthEventNamesList.includes(eventName)) {
      trackAuthEvent(eventName, { ...pageInfo, ...rest, error });
    } else {
      trackEvent(eventName, { ...pageInfo, ...rest, error });
    }
  } catch (error) {
    Logger.error('Error in trackElemasonAction', payload, error);
  }
};

export { trackElemasonAction };
