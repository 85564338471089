import { useMutation } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { useState, type FC } from 'react';
import { Button } from '../../components';
import { useToast } from '../../core';
import { useActionDispatch, useHaloScript } from '../../hooks';

import type { ElemasonSetPreferredPincodeWidget } from '@tectonic/types';

interface SetPreferredPincodeProps {
  widget: ElemasonSetPreferredPincodeWidget;
}

const SetPreferredPincodeWidget: FC<SetPreferredPincodeProps> = ({
  widget: { config, data, actions },
}) => {
  const { showToast } = useToast();
  const dispatch = useActionDispatch();
  const pincode = useHaloScript(data?.pincode);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: () => remixApi.setPreferredPincode({ zip: pincode! }),
    onSuccess: ({ zip }) =>
      actions?.onSuccess?.forEach((action) => dispatch(action, { zip })),
    onError: (error) => {
      actions?.onError?.forEach((action) => dispatch(action, { error }));
      const title = getErrorMessage(error);
      showToast({ title, duration: 2000 });
    },
    onSettled: () => setIsLoading(false),
  });

  const handleOnClick = () => {
    mutate();
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return (
    <Button
      data={data?.button}
      onClick={handleOnClick}
      config={config?.button}
      disabled={isLoading || isPending}
      isLoading={isLoading || isPending}
    />
  );
};

export { SetPreferredPincodeWidget };
