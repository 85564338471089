import { useMutation } from '@tanstack/react-query';
import { Logger } from '@tectonic/logger';
import { toggleCollectionTopLineFilter } from '@tectonic/remix-client-network';
import { toUrlSearchParams } from '@tectonic/utils';
import { useElemasonContext } from '../../../contexts';
import {
  useActionDispatch,
  useFragmentValue,
  useHaloScript,
  usePageFragment,
} from '../../../hooks';

import type {
  ElemasonSearchTopLineFilterWidget,
  SearchConfigParams,
  SearchFilter,
  SearchTopLineFilter,
} from '@tectonic/types';

const toggleTopLineFilter = async ({
  searchParams,
  topLineFilter,
  appliedFilters,
  searchConfigParams,
}: {
  topLineFilter: SearchTopLineFilter;
  appliedFilters: SearchFilter[];
  searchConfigParams?: SearchConfigParams;
  searchParams: Record<string, string>;
}) => {
  if (searchConfigParams?.entity === 'COLLECTION') {
    return toggleCollectionTopLineFilter(
      searchConfigParams.entityIdentifier!,
      { appliedFilters, topLineFilter },
      searchParams
    );
  }
  // TODO: implement top line filter for plp.
  throw 'Not implemented';
};

const useSearchTopLineFilter = (widget: ElemasonSearchTopLineFilterWidget) => {
  const wData = widget.data!;
  const wActions = widget.actions;
  const fragment = usePageFragment(wData.fragment);
  const topLineFilter = useHaloScript(wData.topLineFilter)!;
  const appliedFilters = useHaloScript(wData.appliedFilters)!;
  const searchConfigParams = useHaloScript(wData.searchConfigParams);
  const { searchParams } = useElemasonContext();
  const actionDispatch = useActionDispatch();

  const { mutate: onToggle, isPending: isApplying } = useMutation({
    mutationFn: async () => {
      wActions?.onToggle?.forEach((action) => actionDispatch(action));
      const response = await toggleTopLineFilter({
        appliedFilters,
        topLineFilter,
        searchConfigParams,
        searchParams,
      });

      if (!response.data) {
        throw response.error;
      }

      return response.data;
    },
    onSuccess: (response) => {
      const nSearchParams = toUrlSearchParams({
        ...searchParams,
        ...(response.executedQuery as object),
      });
      wActions?.onSuccess?.forEach((action) => actionDispatch(action));
      window.location.search = nSearchParams;
    },
    onError: (error) => {
      wActions?.onError?.forEach((action) => actionDispatch(action));
      Logger.error('Unable to toggle top line filter', error);
    },
  });

  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    onToggle,
    topLineFilter,
    isApplying,
  });

  return { fragment, fragmentData };
};

export { useSearchTopLineFilter };
