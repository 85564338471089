/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import { script } from '@tectonic/halo-script';
import { ElemasonWidgetActionType, LocalStateKeys } from '@tectonic/types';
import { access } from '@tectonic/utils';
import clsx from 'clsx';
import { type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { useToast } from '../../core';
import { Cell } from '../../core/Cell';
import { useActionDispatch, useHaloScript, usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { queryKeys } from '../../queryKeys';
import { AttributeProvider } from '../Product/VariantSelector/context/AttributeContext';
import { useSelectedAttributes } from '../Product/VariantSelector/hooks/useSelectedAttributes';
import { useVariantAttributes } from '../Product/VariantSelector/hooks/useVariantAttributes';

import type {
  Cart,
  CartLineProductVariant,
  CartUpdateLinePayload,
  ElemasonCartLineVariantSelectorWidget,
  ProductVariant,
} from '@tectonic/types';

type CartLineVariantSelectorWidgetProps = {
  widget: ElemasonCartLineVariantSelectorWidget;
};

const useCartLineVariantSelector = ({
  data,
}: ElemasonCartLineVariantSelectorWidget) => {
  const cartLine = useHaloScript(data?.cartLine);

  const product = cartLine?.productVariant.product;
  const variant = cartLine?.productVariant;
  const attributeFragment = usePageFragment(data?.attributeFragment);
  const attributeFragmentValue = useFragmentValue(attributeFragment);
  const { selectedAttributes } = useSelectedAttributes(
    variant as unknown as ProductVariant
  );

  const variantAttributes = useVariantAttributes(
    product?.variants ?? [],
    selectedAttributes
  );

  return {
    product,
    cartLine,
    attributeFragment,
    variantAttributes,
    attributeFragmentValue,
    selectedVariant: variant,
  };
};

const STATE_KEY = LocalStateKeys.CART;
const SCRIPT_ACCESSOR = script([access(['local', STATE_KEY])]);

const CartLineVariantSelectorWidget: FC<CartLineVariantSelectorWidgetProps> = ({
  widget,
}) => {
  const {
    product,
    cartLine,
    selectedVariant,
    variantAttributes,
    attributeFragment,
    attributeFragmentValue,
  } = useCartLineVariantSelector(widget);
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const actionDispatch = useActionDispatch();
  const cart = useHaloScript<Cart>(SCRIPT_ACCESSOR);
  const [style, className] = useStyleConfigV2(widget.config?.container);
  const queryCache = queryClient.getQueryCache();
  const cartQueryKeys = queryCache
    .getAll()
    .map((cache) => cache.queryKey)
    .filter((key) => key[0] === 'cart' && key.length === 1);

  const updateCartMutation = useMutation({
    mutationFn: (lines: CartUpdateLinePayload[]) => remixApi.updateCart(lines),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart: Cart) => {
      cartQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (_queryData: Cart) => updatedCart);
      });

      showToast({ title: 'Variant updated' });
    },
    onError: (e) => {
      const title = getErrorMessage(e, {}, 'Failed to update variant');
      showToast({ title });
    },
  });

  const onVariantSelect = (data: {
    variant: CartLineProductVariant;
    userProfileData?: any;
  }) => {
    const lines = cart!.lines.edges.map((line) => ({
      ...line.node,
      id: line.node.id,
      quantity: line.node.quantity,
      userProfileData:
        line.node.productVariant.basisAttr1Value === 'Customize'
          ? data.userProfileData ?? null
          : null,
      variantId:
        line.node.productVariant.id === cartLine?.productVariant.id
          ? data.variant.id
          : line.node.productVariant.id,
      productVariant:
        line.node.productVariant.id === cartLine?.productVariant.id
          ? { ...data.variant, product: line.node.productVariant.product }
          : line.node.productVariant,
    }));

    cartQueryKeys.forEach((key) => {
      queryClient.setQueryData(key, (queryData: Cart) => ({
        ...queryData,
        lines: {
          edges: lines.map((node) => ({ node })),
        },
      }));
    });

    updateCartMutation.mutate(
      lines.map(({ id, quantity, variantId, userProfileData }) => ({
        id,
        quantity,
        variantId,
        userProfileData,
      }))
    );
  };

  const onVariantSelectorClick = () => {
    actionDispatch({
      type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
      payload: {
        slug: 'variant-selector',
        data: {
          onVariantSelect,
          selectedVariant: cartLine!.productVariant,
          product: cartLine!.productVariant.product,
        },
        // exit: 'bottom',
        // entry: 'bottom',
      },
    });
  };

  return (
    <div
      style={style}
      onClick={onVariantSelectorClick}
      className={clsx(
        'flex',
        widget.config?.container?.direction === 'row' ? 'flex-row' : 'flex-col',
        className
      )}
    >
      {Object.entries(variantAttributes).map(([attr, value]) => (
        <AttributeProvider
          key={attr}
          // @ts-ignore
          value={{ attr }}
        >
          <ElemasonFragmentProvider
            key={attr}
            value={attributeFragmentValue({ product, value, selectedVariant })}
          >
            {attributeFragment?.cells.map((cell) => (
              <Cell
                cell={cell}
                key={cell.id}
                style={{ pointerEvents: 'none' }}
              />
            ))}
          </ElemasonFragmentProvider>
        </AttributeProvider>
      ))}
    </div>
  );
};

export { CartLineVariantSelectorWidget };
