import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { ProductVariants } from '../../../components/Product/ProductVariants';
import { useActionDispatch } from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';

import type { ElemasonProductVariantsWidget } from '@tectonic/types';
import type { FC, MouseEvent } from 'react';

interface ElemasonProductVariantsWidgetProps {
  widget: ElemasonProductVariantsWidget;
}

const ProductVariantsWidget: FC<ElemasonProductVariantsWidgetProps> = ({
  widget: { config: productVariantsConfig, data, actions },
}) => {
  const dispatch = useActionDispatch();
  const product = useHaloScript(data?.product);
  const stateKey = useHaloScript(data?.stateKey);
  const selectedVariant = useHaloScript(data?.selectedVariant);

  const onClick = (event: MouseEvent) => {
    if (!actions?.onClick.length) return;
    event.stopPropagation();

    dispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_VARIANTS_CLICK,
        data: {
          entities: { product, productVariant: selectedVariant },
        },
      },
    });

    actions?.onClick.forEach((action) => dispatch(action, { dispatch }));
  };

  if (!product) return null;

  return (
    <ProductVariants
      onClick={onClick}
      product={product}
      stateKey={stateKey}
      config={productVariantsConfig!}
      selectedVariant={selectedVariant}
    />
  );
};

export { ProductVariantsWidget };
