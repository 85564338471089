import { Text, useStyleConfig } from '@tectonic/elemason-components';
import {
  AssetScale,
  ElemasonComponentType,
  ElemasonWidgetActionType,
  OrderLineStatus,
  OrderStatus,
} from '@tectonic/types';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash-es';
import { Fragment, useMemo, type FC } from 'react';
import { useElemasonComponentConfig } from '../../hooks/useElemasonConfig';
import { isDefaultVariant } from '../../utils';
import { ImageAsset } from '../Asset';
import { Button } from '../Button';
// import { usePrices } from "../Product";
import { isVideo, toCurrency } from '@tectonic/utils';
import {
  useElemasonAnalyticsContext,
  useElemasonPageContext,
} from '../../contexts';
import { usePrices } from '../Product';
import { OrderHeader } from './OrderHeader';

import type {
  ImageConfig,
  Money,
  Order,
  OrderLine,
  ProductPricingConfig,
  StyleConfig,
} from '@tectonic/types';

interface OrderLineStatusProps {
  orderStatus: OrderStatus;
  lineItem: OrderLine;
  onTrackClick: (url: string, title: string) => void;
}

const useOrderLineStatusStrip = ({
  orderStatus,
  lineItem,
  onTrackClick,
}: OrderLineStatusProps) =>
  useMemo(() => {
    if (orderStatus === OrderStatus.CANCELLED) {
      return (
        <div
          className={clsx('flex flex-row rounded-md px-2 py-3')}
          style={{ backgroundColor: '#FEF2F2' }}
        >
          <Text
            data="Order Cancelled"
            config={{
              variant: 'body3',
              fontWeight: 'semibold',
              color: 'neutral-950',
            }}
          />
        </div>
      );
    }

    const {
      trackingUrl,
      trackingId,
      deliveryStatus,
      status: lineItemStatus,
    } = lineItem;

    if (
      lineItemStatus === OrderLineStatus.FULFILLED ||
      lineItemStatus === OrderLineStatus.PARTIALLY_FULFILLED
    ) {
      return (
        <div
          className={clsx('flex flex-row rounded-md p-3')}
          style={{ backgroundColor: '#ECFDF5' }}
        >
          <div className={clsx('flex flex-col gap-y-1')} style={{ flex: 1 }}>
            <Text
              data={`Order ${
                deliveryStatus === 'DELIVERED' ? 'Delivered' : 'Fulfilled'
              }`}
              config={{
                variant: 'body3',
                fontWeight: 'semibold',
                color: 'neutral-950',
              }}
            />
            {isNil(trackingId) ? null : (
              <Text
                data={`Tracking ID: ${trackingId}`}
                config={{
                  variant: 'body4',
                  fontWeight: 'normal',
                  color: 'neutral-950',
                }}
              />
            )}
          </div>
          {deliveryStatus !== 'DELIVERED' && (
            <div className={clsx('flex flex-col')} style={{ flex: 0 }}>
              <Button
                data={{ text: 'TRACK' }}
                config={{
                  variant: 'outline',
                  size: 'sm',
                  borderRadius: 'md',
                  borderColor: 'black',
                  text: {
                    variant: 'body4',
                    fontWeight: 'semibold',
                    color: 'black',
                  },
                }}
                disabled={isNil(trackingUrl)}
                onClick={() =>
                  onTrackClick(trackingUrl!, `Tracking ${trackingId}`)
                }
              />
            </div>
          )}
        </div>
      );
    }

    if (lineItemStatus === OrderLineStatus.UNFULFILLED) {
      return (
        <div
          className={clsx('flex flex-col rounded-md p-3')}
          style={{ backgroundColor: '#ECFDF5' }}
        >
          <div className={clsx('flex flex-col gap-y-1')}>
            <Text
              data="Order Successfully Placed"
              config={{
                variant: 'body3',
                fontWeight: 'semibold',
                color: 'neutral-950',
              }}
            />
            <Text
              data="You will get the tracking ID soon. "
              config={{
                variant: 'body4',
                fontWeight: 'normal',
                color: 'neutral-950',
              }}
            />
          </div>
        </div>
      );
    }

    return null;
  }, [orderStatus, lineItem, onTrackClick]);

interface OrderDetailsProps {
  order: Order;
  isLoading: boolean;
  isError: boolean;
  variantId: string;
  orderReturnCtaText?: string;
  isLoadingReturnExchangeUrl?: boolean;
  onReturnExchangeClick: () => unknown;
}

const OrderDetails: FC<OrderDetailsProps> = ({
  order,
  isError,
  isLoading,
  orderReturnCtaText,
  variantId,
  onReturnExchangeClick,
  isLoadingReturnExchangeUrl,
}) => {
  const currentVariantLine: OrderLine = order.lineItems.edges.filter(
    (edge) => edge.node.productVariant.id === variantId
  )[0]?.node;

  // const otherVariantLines: OrderLine[] = order.lineItems.edges
  //   .filter((edge) => edge.node.productVariant.id !== variantId)
  //   .map((edge) => edge.node);

  const mediaAssetConfig: ImageConfig = {
    assetScale: AssetScale.X_2,
  };

  const { style: mediaStyle, className: mediaClassName } = useStyleConfig({
    width: 80,
    height: 80,
    aspectRatio: 1,
    borderRadius: 'md',
  });

  const { style: mediaOverlayStyle, className: mediaOverlayClassName } =
    useStyleConfig({
      position: 'absolute',
      bottom: 0,
      borderRadius: 'full',
      borderColor: 'neutral-950',
      backgroundColor: 'white',
      border: '1',
      end: 0,
      paddingX: '2',
      paddingY: '0.5',
    });

  const { dispatch } = useElemasonPageContext();
  const analyticsContext = useElemasonAnalyticsContext();

  const onTrackClick = (url: string, title: string) => {
    dispatch(
      {
        type: ElemasonWidgetActionType.LINK_OPEN,
        payload: {
          url,
          target: '_blank',
          metadata: { title },
        },
      },
      analyticsContext
    );
  };

  const showVariant = !isDefaultVariant(currentVariantLine.productVariant);

  const productPricingConfig: ProductPricingConfig = useElemasonComponentConfig(
    ElemasonComponentType.ProductPricing,
    {}
  );

  const { style: pricingContStyle, className: pricingContClassName } =
    useStyleConfig(productPricingConfig?.container ?? {});

  const prices = usePrices({
    config: productPricingConfig,
    variant: { ...currentVariantLine.productVariant },
  });
  // const prices = []

  const orderHeaderConfig: StyleConfig = {
    padding: '4',
    backgroundColor: 'white',
    // marginTop: '2'
  };

  const lineStatusStrip = useOrderLineStatusStrip({
    orderStatus: order.status,
    lineItem: currentVariantLine,
    onTrackClick,
  });

  const addressItems = [
    order.shippingAddress?.address1,
    order.shippingAddress?.address2,
    order.shippingAddress?.province,
    order.shippingAddress?.city,
    order.shippingAddress?.zip,
    order.shippingAddress?.country,
  ];
  const saneAddress = addressItems.filter((item) => !isEmpty(item)).join(', ');

  const shippingText =
    order.totalShippingPrice.amount > 0
      ? `${toCurrency(
          order.totalShippingPrice.amount,
          order.totalShippingPrice.currencyCode
        )}`
      : 'Free';

  const taxText =
    order.totalTax.amount > 0
      ? `Inclusive of all taxes (${toCurrency(
          order.totalTax.amount,
          order.totalTax.currencyCode
        )})`
      : 'Inclusive of all taxes';

  const isSingleLineOrder = order.lineItems.edges.length === 1;

  const hasAmount = (amount: Money) => !isEmpty(amount) && amount.amount > 0;

  /*
    TODO
    other products
    move box shadow to tailwind
  */

  const lineItemImages =
    currentVariantLine.productVariant.product.assetMap.gallery.filter(
      (asset) => !isVideo(asset.mimeType ?? '')
    );
  const lineItemImage =
    lineItemImages.length > 0 ? lineItemImages[0] : undefined;

  return (
    <div className="flex flex-col">
      <OrderHeader order={order} config={orderHeaderConfig} />

      {/* Order product customer shipping info */}
      <div
        className={clsx('mt-2 flex flex-col gap-y-2 p-2')}
        style={{ backgroundColor: 'white' }}
      >
        {/* Status strip */}
        <div className="flex flex-col">{lineStatusStrip}</div>

        {/* Product card */}
        <div
          className={clsx('flex flex-row rounded-md p-2')}
          style={{ boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.10)' }}
        >
          <div
            className={clsx('flex flex-col')}
            style={{ flex: 0, position: 'relative' }}
          >
            <div className={clsx('flex flex-col')} style={{}}>
              {lineItemImage && (
                <ImageAsset
                  className={clsx(mediaClassName, 'object-contain')}
                  style={mediaStyle}
                  config={mediaAssetConfig}
                  asset={lineItemImage}
                />
              )}
            </div>
            {currentVariantLine.currentQuantity > 1 && (
              <div
                className={clsx('flex', mediaOverlayClassName)}
                style={mediaOverlayStyle}
              >
                <Text
                  data={currentVariantLine.currentQuantity.toString()}
                  config={{
                    variant: 'body3',
                    fontWeight: 'medium',
                    color: 'neutral-950',
                  }}
                />
              </div>
            )}
          </div>
          <div className={clsx('flex flex-col px-2')} style={{ flex: 1 }}>
            <div className={clsx('flex flex-col')}>
              <Text
                data={currentVariantLine.productVariant.product.title}
                config={{
                  variant: 'body4',
                  fontWeight: 'medium',
                  color: 'neutral-950',
                }}
              />
            </div>
            {showVariant ? (
              <div className={clsx('flex flex-col')}>
                <Text
                  data={currentVariantLine.productVariant.basisAttr1Value}
                  config={{
                    variant: 'body4',
                    fontWeight: 'medium',
                    color: 'neutral-500',
                  }}
                />
              </div>
            ) : null}
            <div className={clsx('flex flex-col')}>
              <div
                className={clsx('flex flex-row', pricingContClassName)}
                style={pricingContStyle}
              >
                {/* TODO use a central component to show pricing today the component takes only product variant type as input */}
                {prices.map((price, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>{price.content}</Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Line item details */}
        <div className={clsx('mt-2 flex flex-col gap-y-4 p-4')} style={{}}>
          {/* Payment details */}
          <div className="flex flex-col">
            {/* Quantity header */}
            <div className={clsx('flex flex-col pb-2')}>
              <Text
                data={`Payment Details (${currentVariantLine.quantity})`}
                config={{
                  variant: 'body2',
                  color: 'neutral-950',
                  fontWeight: 'semibold',
                }}
              />
            </div>
            {/* Price details */}
            <div className={clsx('flex flex-col gap-y-1')}>
              <div className="flex flex-row" style={{}}>
                <div className="flex flex-col" style={{ flex: 1 }}>
                  <Text
                    data="Price"
                    config={{
                      variant: 'body3',
                      fontWeight: 'normal',
                      color: 'neutral-950',
                    }}
                  />
                </div>
                <div className="flex flex-col" style={{ flex: 0 }}>
                  <Text
                    data={`${toCurrency(
                      currentVariantLine.totalPrice.amount,
                      currentVariantLine.totalPrice.currencyCode
                    )}`}
                    config={{
                      variant: 'body3',
                      fontWeight: 'normal',
                      color: 'neutral-950',
                    }}
                  />
                </div>
              </div>
              {hasAmount(currentVariantLine.discount) ? (
                <div className="flex flex-row" style={{}}>
                  <div className="flex flex-col" style={{ flex: 1 }}>
                    <Text
                      data="Discount"
                      config={{
                        variant: 'body3',
                        fontWeight: 'normal',
                        color: 'neutral-950',
                      }}
                    />
                  </div>
                  <div className="flex flex-col" style={{ flex: 0 }}>
                    <Text
                      data={`-${toCurrency(
                        currentVariantLine.discount.amount,
                        currentVariantLine.discount.currencyCode
                      )}`}
                      config={{
                        variant: 'body2',
                        fontWeight: 'normal',
                        color: 'success-600',
                        textWrap: 'nowrap',
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {hasAmount(currentVariantLine.couponDiscount) ? (
                <div className="flex flex-row" style={{}}>
                  <div className="flex flex-col" style={{ flex: 1 }}>
                    <Text
                      data="Coupon Discount"
                      config={{
                        variant: 'body3',
                        fontWeight: 'normal',
                        color: 'neutral-950',
                      }}
                    />
                  </div>
                  <div className="flex flex-col" style={{ flex: 0 }}>
                    <Text
                      data={`-${toCurrency(
                        currentVariantLine.couponDiscount.amount,
                        currentVariantLine.couponDiscount.currencyCode
                      )}`}
                      config={{
                        variant: 'body2',
                        fontWeight: 'normal',
                        color: 'success-600',
                        textWrap: 'nowrap',
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {isSingleLineOrder ? (
                <>
                  {hasAmount(order.coinDiscount) ? (
                    <div className="flex flex-row" style={{}}>
                      <div className="flex flex-col" style={{ flex: 1 }}>
                        <Text
                          data="Coin Discount"
                          config={{
                            variant: 'body3',
                            fontWeight: 'normal',
                            color: 'neutral-950',
                          }}
                        />
                      </div>
                      <div className="flex flex-col" style={{ flex: 0 }}>
                        <Text
                          data={`-${toCurrency(
                            order.coinDiscount.amount,
                            order.coinDiscount.currencyCode
                          )}`}
                          config={{
                            variant: 'body2',
                            fontWeight: 'normal',
                            color: 'success-600',
                            textWrap: 'nowrap',
                          }}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="flex flex-row" style={{}}>
                    <div className="flex flex-col" style={{ flex: 1 }}>
                      <Text
                        data="Shipping Charges"
                        config={{
                          variant: 'body3',
                          fontWeight: 'normal',
                          color: 'neutral-950',
                        }}
                      />
                    </div>
                    <div className="flex flex-col" style={{ flex: 0 }}>
                      <Text
                        data={shippingText}
                        config={{
                          variant: 'body2',
                          fontWeight: 'normal',
                          color: 'success-600',
                          textWrap: 'nowrap',
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {/* Payment and amount */}
            <div className={clsx('mt-3 flex flex-col gap-y-1')}>
              <div className="flex flex-row" style={{}}>
                <div className="flex flex-col" style={{ flex: 1 }}>
                  <Text
                    data="Amount"
                    config={{
                      variant: 'body3',
                      fontWeight: 'semibold',
                      color: 'neutral-950',
                    }}
                  />
                </div>
                <div className="flex flex-col" style={{ flex: 0 }}>
                  <Text
                    data={`${toCurrency(
                      currentVariantLine.totalAmount.amount,
                      currentVariantLine.totalAmount.currencyCode
                    )}`}
                    config={{
                      variant: 'body3',
                      fontWeight: 'semibold',
                      color: 'neutral-950',
                    }}
                  />
                </div>
              </div>
              {isSingleLineOrder ? (
                <div className="flex flex-col">
                  <Text
                    data={taxText}
                    config={{
                      color: 'neutral-500',
                      variant: 'body3',
                      fontWeight: 'normal',
                    }}
                  />
                </div>
              ) : null}
              {order.isCod ? (
                <div className="flex flex-col">
                  <Text
                    data="Pay Cash on Delivery"
                    config={{
                      variant: 'body3',
                      fontWeight: 'normal',
                      color: 'neutral-950',
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {/* Contact details */}
          <div className="flex flex-col">
            {/* Contact details header */}
            <div className={clsx('flex flex-col pb-3')}>
              <Text
                data="Contact Information"
                config={{
                  color: 'neutral-950',
                  variant: 'body2',
                  fontWeight: 'semibold',
                }}
              />
            </div>
            {/* Contact details */}
            <div className={clsx('flex flex-col gap-y-2')}>
              <Text
                data={`${order.shippingAddress?.name || order.customerName}`}
                config={{
                  variant: 'body3',
                  fontWeight: 'normal',
                  color: 'neutral-950',
                }}
              />
              <Text
                data={`${order.shippingAddress?.phone || order.phone}`}
                config={{
                  variant: 'body3',
                  fontWeight: 'normal',
                  color: 'neutral-950',
                }}
              />
              <Text
                data={`${order.email}`}
                config={{
                  variant: 'body3',
                  fontWeight: 'normal',
                  color: 'neutral-950',
                }}
              />
            </div>
          </div>

          {/* Shipping details */}
          <div className="flex flex-col">
            {/* Shipping details header */}
            <div className={clsx('flex flex-col pb-3')}>
              <Text
                data="Shipping Address"
                config={{
                  variant: 'body2',
                  color: 'neutral-950',
                  fontWeight: 'semibold',
                }}
              />
            </div>
            {/* Shipping address */}
            <div className={clsx('flex flex-col pb-3')}>
              <Text
                data={saneAddress}
                config={{
                  variant: 'body3',
                  fontWeight: 'normal',
                  color: 'neutral-950',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {currentVariantLine.eilgibleForReturn &&
      currentVariantLine.trackingUrl ? (
        <div className="my-4 grow flex-col bg-white px-8 py-4">
          <Button
            size="sm"
            variant="outline"
            modifier="danger"
            className="w-full"
            data={{ text: orderReturnCtaText ?? 'Return / Exchange' }}
            isLoading={isLoadingReturnExchangeUrl}
            onClick={onReturnExchangeClick}
          />
        </div>
      ) : null}

      {/* Full Order Info */}
      {!isSingleLineOrder ? (
        <div className="bg-primary-50 mt-4 flex flex-col">
          {/* Other products Header */}

          {/* {otherVariantLines.length > 0 ? (<div className={clsx('flex flex-col p-4')}>
          <Text
            data="Other Products in this Order"
            config={{
              variant: "body1",
              fontWeight: "bold",
              color: "neutral-950"
            }}
          />
        </div>) : null} */}

          {/* Order Total Info */}
          <div className={clsx('flex flex-col px-4 pt-4')}>
            {/* Order Total Header */}
            <div className="flex flex-col pb-4">
              <Text
                data="Order Total"
                config={{
                  variant: 'body1',
                  fontWeight: 'semibold',
                  color: 'neutral-950',
                }}
              />
            </div>

            {/* Breakdown */}
            <div className="flex flex-col gap-y-2">
              {/* Line items */}
              {order.lineItems.edges
                .map((edge) => edge.node)
                .map((otherVariantLine) => {
                  const otherLineItemVariant = otherVariantLine.productVariant;

                  const lineItemTitle =
                    otherVariantLine.quantity > 1
                      ? `${otherLineItemVariant.product.title} x${otherVariantLine.quantity}`
                      : otherLineItemVariant.product.title;

                  return (
                    <div
                      key={`order-line-${otherVariantLine.productVariant.id}`}
                      className="flex flex-row gap-x-12"
                    >
                      <div className="flex w-0 grow">
                        <Text
                          className="w-full truncate"
                          data={lineItemTitle}
                          config={{
                            color: 'neutral-950',
                            variant: 'body3',
                            fontWeight: 'medium',
                          }}
                        />
                      </div>
                      <div className="shrink-0">
                        <Text
                          data={`${toCurrency(
                            otherVariantLine.totalPrice.amount,
                            otherVariantLine.totalAmount.currencyCode
                          )}`}
                          config={{
                            color: 'neutral-950',
                            variant: 'body3',
                            fontWeight: 'medium',
                          }}
                        />
                      </div>
                    </div>
                  );
                })}

              {/* Discount */}
              {hasAmount(order.discount) ? (
                <div className="flex flex-row">
                  <div className="flex" style={{ flex: 1 }}>
                    <Text
                      data="Discount"
                      config={{
                        color: 'neutral-950',
                        variant: 'body3',
                        fontWeight: 'medium',
                      }}
                    />
                  </div>
                  <div className="flex" style={{ flex: 0 }}>
                    <Text
                      data={`-${toCurrency(
                        order.discount.amount,
                        order.discount.currencyCode
                      )}`}
                      config={{
                        color: 'success-600',
                        variant: 'body3',
                        fontWeight: 'medium',
                        textWrap: 'nowrap',
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {/* Coupon Discount */}
              {hasAmount(order.couponDiscount) ? (
                <div className="flex flex-row">
                  <div className="flex" style={{ flex: 1 }}>
                    <Text
                      data="Coupon Discount"
                      config={{
                        color: 'neutral-950',
                        variant: 'body3',
                        fontWeight: 'medium',
                      }}
                    />
                  </div>
                  <div className="flex" style={{ flex: 0 }}>
                    <Text
                      data={`-${toCurrency(
                        order.couponDiscount.amount,
                        order.couponDiscount.currencyCode
                      )}`}
                      config={{
                        color: 'success-600',
                        variant: 'body3',
                        fontWeight: 'medium',
                        textWrap: 'nowrap',
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {hasAmount(order.coinDiscount) ? (
                <div className="flex flex-row" style={{}}>
                  <div className="flex flex-col" style={{ flex: 1 }}>
                    <Text
                      data="Coin Discount"
                      config={{
                        variant: 'body3',
                        fontWeight: 'normal',
                        color: 'neutral-950',
                      }}
                    />
                  </div>
                  <div className="flex flex-col" style={{ flex: 0 }}>
                    <Text
                      data={`-${toCurrency(
                        order.coinDiscount.amount,
                        order.coinDiscount.currencyCode
                      )}`}
                      config={{
                        variant: 'body2',
                        fontWeight: 'normal',
                        color: 'success-600',
                        textWrap: 'nowrap',
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {/* Shipping fee */}
              <div className="flex flex-row">
                <div className="flex" style={{ flex: 1 }}>
                  <Text
                    data="Shipping charges"
                    config={{
                      color: 'neutral-950',
                      variant: 'body3',
                      fontWeight: 'medium',
                    }}
                  />
                </div>
                <div className="flex" style={{ flex: 0 }}>
                  <Text
                    data={shippingText}
                    config={{
                      color: 'success-600',
                      variant: 'body3',
                      fontWeight: 'medium',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Total */}
          <div className="flex flex-row px-4 pt-3">
            <div className="flex" style={{ flex: 1 }}>
              <Text
                data="Grand Total"
                config={{
                  color: 'neutral-950',
                  variant: 'body3',
                  fontWeight: 'semibold',
                }}
              />
            </div>
            <div className="flex" style={{ flex: 0 }}>
              <Text
                data={`${toCurrency(
                  order.totalPrice.amount,
                  order.totalPrice.currencyCode
                )}`}
                config={{
                  color: 'success-600',
                  variant: 'body3',
                  fontWeight: 'medium',
                }}
              />
            </div>
          </div>

          {/* Taxes */}
          <div className="flex flex-col px-4 pb-4 pt-3">
            <Text
              data={taxText}
              config={{
                color: 'neutral-500',
                variant: 'body3',
                fontWeight: 'semibold',
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const OrderDetailsWrapper: FC<OrderDetailsProps> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (isEmpty(props.order)) {
    return null;
  }

  return <OrderDetails {...props} />;
};

export { OrderDetailsWrapper as OrderDetails };
