import { useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  Typography,
  useForm,
} from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { PhoneNumberInput } from '../../PhoneNumberInput';
import { SelectMenu } from '../../Select';
import { TextField } from '../../TextField';

import type { Country } from '@tectonic/types';
import type { FormSubmitHandler } from '@tectonic/uikit';
import type { FC } from 'react';
import type { AddressFormProps, AddressFormValue } from './AddressForm.types';

const getCountry = (
  { countries }: Pick<AddressFormProps, 'countries'>,
  country: string
) => countries.find((c) => c.name === country) ?? countries[0];

const getDefaultAddress = ({
  countries,
}: Pick<AddressFormProps, 'countries'>): Partial<AddressFormValue> => {
  const country = getCountry({ countries }, countries[0].name);
  return {
    address1: '',
    address2: '',
    city: '',
    company: '',
    default: false,
    firstName: '',
    lastName: '',
    zip: '',
    phoneNumber: { country, phone: '' },
  };
};

const getErrorFields = (
  fValue: Partial<AddressFormValue>,
  data: AddressFormProps['data']
) => {
  const fields = [
    {
      key: 'firstName',
      value: fValue.firstName,
      message: data.firstNameInput.textField.required,
    },
    {
      key: 'lastName',
      value: fValue.lastName,
      message: data.lastNameInput.textField.required,
    },
    {
      key: 'company',
      value: fValue.company,
      message: data.companyInput.textField.required,
    },
    {
      key: 'phoneNumber',
      value: fValue.phoneNumber?.phone,
      message: data.phoneInput.textField.required,
    },
    {
      key: 'address1',
      value: fValue.address1,
      message: data.address1Input.textField.required,
    },
    {
      key: 'address2',
      value: fValue.address2,
      message: data.address2Input.textField.required,
    },
    {
      key: 'city',
      value: fValue.city,
      message: data.cityInput.textField.required,
    },
    {
      key: 'zip',
      value: fValue.zip,
      message: data.pincodeInput.textField.required,
    },
    {
      key: 'country',
      value: fValue.country?.name,
      message: data.countryInput.select.required,
    },
    {
      key: 'province',
      value: fValue.province?.name,
      message: data.provinceInput?.select.required,
    },
  ];
  return fields.filter(
    (field) => isBlank(field.value) && !isBlank(field.message)
  );
};

const useDefaultValues = ({
  countries,
  address,
}: Pick<AddressFormProps, 'countries' | 'address'>) =>
  useMemo(() => {
    if (!address) {
      return getDefaultAddress({ countries });
    }

    const phoneNumberCountry =
      countries.find(
        (c) => c.stdCodes[0] && address.phone?.startsWith(c.stdCodes[0])
      ) ?? countries[0]!;

    const formValue: Partial<AddressFormValue> = {
      firstName: address.firstName,
      lastName: address.lastName,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      zip: address.zip,
      company: address.company,
      default: address.isDefault,
      province: { name: address.province, displayName: address.province },
      country: { name: address.country, displayName: address.country },
      phoneNumber: {
        country: phoneNumberCountry!,
        phone: address.phone?.replace(phoneNumberCountry.stdCodes[0], ''),
      },
    };

    return formValue;
  }, []);

const AddressForm: FC<AddressFormProps> = ({
  address,
  countries,
  isSubmitting,
  data,
  config,
  onSubmit,
  onChange,
  provinces,
}) => {
  const defaultValues = useDefaultValues({ countries, address });
  const { control, formState, watch, setError, setValue } =
    useForm<AddressFormValue>({
      defaultValues,
      mode: 'onChange',
    });
  const { errors } = formState;
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  const submitContainerStyleConfig = useStyleConfig(
    config?.submitCta?.container ?? {}
  );

  const hasCountry = !!watch('country');

  const handleSubmit: FormSubmitHandler<AddressFormValue> = (formData) => {
    const value = formData.data;
    const fields = getErrorFields(value, data);

    if (isEmpty(fields)) {
      onSubmit(value);
      return;
    }

    for (const field of fields) {
      setError(field.key as keyof AddressFormValue, { message: field.message });
    }
  };

  useEffect(() => {
    const subscription = watch((fValue, { name }) => {
      if (name === 'country') {
        setValue('phoneNumber', {
          phone: fValue.phoneNumber?.phone!,
          country: fValue.phoneNumber?.country as Country,
        });
      }
      onChange(fValue as Partial<AddressFormValue>);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Form
      control={control}
      onSubmit={handleSubmit}
      className={clsx('flex flex-col', 'h-full')}
    >
      <div
        style={containerStyleConfig.style}
        className={clsx(
          'flex h-0 grow flex-col overflow-y-auto',
          containerStyleConfig.className
        )}
      >
        {/* First name and last name */}
        <div
          className={clsx('flex gap-4')}
          style={{ order: config?.firstNameInput?.order }}
        >
          <div className={clsx('flex w-0 grow flex-col gap-2')}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  data={data.firstNameInput.textField}
                  config={config?.firstNameInput?.textField}
                  value={field.value}
                  type="text"
                  disabled={isSubmitting}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
            <FormErrorMessage
              name="firstName"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body3"
            />
          </div>
          <div className={clsx('flex w-0 grow flex-col gap-2')}>
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  data={data.lastNameInput.textField}
                  config={config?.lastNameInput?.textField}
                  value={field.value}
                  type="text"
                  disabled={isSubmitting}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
            <FormErrorMessage
              name="firstName"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body3"
            />
          </div>
        </div>

        {/* Company */}
        {config?.companyInput?.textField?.visible !== false ? (
          <div
            className={clsx('flex flex-col gap-2')}
            style={{ order: config?.companyInput?.order }}
          >
            <Controller
              control={control}
              name="company"
              render={({ field }) => (
                <TextField
                  data={data.companyInput.textField}
                  config={config?.companyInput?.textField}
                  value={field.value}
                  type="text"
                  disabled={isSubmitting}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
            <FormErrorMessage
              name="company"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body3"
            />
          </div>
        ) : null}

        {/* Phone number input */}
        <div
          className="flex flex-col gap-2"
          style={{ order: config?.phoneInput?.order }}
        >
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneNumberInput
                value={field.value}
                countries={countries}
                countryCode={{
                  data: data.phoneInput.countryCode?.select,
                  config: config?.phoneInput?.countryCode?.select,
                }}
                onChange={(value) => field.onChange(value)}
                placeholder={data.phoneInput.textField.placeholder}
              />
            )}
          />
          <FormErrorMessage
            name="phoneNumber"
            errors={errors}
            as={Typography}
            className="text-danger-600"
            variant="body3"
          />
        </div>

        {/* Address 1 */}
        <div
          className={clsx('flex flex-col gap-2')}
          style={{ order: config?.address1Input?.order }}
        >
          <Controller
            control={control}
            name="address1"
            render={({ field }) => (
              <TextField
                data={data.address1Input.textField}
                config={config?.address1Input?.textField}
                value={field.value}
                type="text"
                disabled={isSubmitting}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
          <FormErrorMessage
            name="address1"
            errors={errors}
            as={Typography}
            className="text-danger-600"
            variant="body3"
          />
        </div>

        {/* Address 2 */}
        <div
          className={clsx('flex flex-col gap-2')}
          style={{ order: config?.address2Input?.order }}
        >
          <Controller
            control={control}
            name="address2"
            render={({ field }) => (
              <TextField
                data={data.address2Input.textField}
                config={config?.address2Input?.textField}
                value={field.value}
                type="text"
                disabled={isSubmitting}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
          <FormErrorMessage
            name="address2"
            errors={errors}
            as={Typography}
            className="text-danger-600"
            variant="body3"
          />
        </div>

        {/* City and zip code */}

        <div
          className={clsx('flex gap-4')}
          style={{ order: config?.pincodeInput?.order }}
        >
          <div className={clsx('flex w-0 grow flex-col gap-2')}>
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <TextField
                  data={data.cityInput.textField}
                  config={config?.cityInput?.textField}
                  value={field.value}
                  type="text"
                  disabled={isSubmitting}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
            <FormErrorMessage
              name="city"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body3"
            />
          </div>
          <div className={clsx('flex w-0 grow flex-col gap-2')}>
            <Controller
              control={control}
              name="zip"
              render={({ field }) => (
                <TextField
                  data={data.pincodeInput.textField}
                  config={config?.pincodeInput?.textField}
                  value={field.value}
                  type="text"
                  disabled={isSubmitting}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
            <FormErrorMessage
              name="zip"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body3"
            />
          </div>
        </div>

        <div
          className={clsx('flex flex-col gap-2')}
          style={{ order: config?.countryInput?.order }}
        >
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <SelectMenu
                disabled={isEmpty(countries)}
                data={data.countryInput.select}
                config={config?.countryInput?.select}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                options={countries.map((country) => ({
                  name: country.name,
                  displayName: country.name,
                }))}
              />
            )}
          />
          <FormErrorMessage
            name="country"
            errors={errors}
            as={Typography}
            className="text-danger-600"
            variant="body3"
          />
        </div>
        {/* We hide provinceInput if it's data is not present. Presently, we don
        have visibility on various form related stuff. We might have a better
        solution once we know enough about forms */}
        {hasCountry && data.provinceInput && provinces.length > 0 ? (
          <div
            className={clsx('flex flex-col gap-2')}
            style={{ order: config?.provinceInput?.order }}
          >
            <Controller
              control={control}
              name="province"
              render={({ field }) => (
                <SelectMenu
                  config={config?.provinceInput?.select}
                  data={data.provinceInput!.select}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  options={provinces.map((province) => ({
                    name: province.name,
                    displayName: province.name,
                  }))}
                />
              )}
            />
            <FormErrorMessage
              name="province"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body3"
            />
          </div>
        ) : null}

        <Controller
          control={control}
          name="default"
          render={({ field }) => (
            <Checkbox
              data={data.defaultNudge.checkbox}
              config={config?.defaultNudge?.checkbox}
              style={{ order: config?.defaultNudge?.order }}
              checked={field.value}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </div>

      <div
        className={clsx('flex shrink-0', submitContainerStyleConfig.className)}
        style={{
          ...submitContainerStyleConfig.style,
          order: config?.submitCta?.order,
        }}
      >
        <Button
          isLoading={isSubmitting}
          className="grow"
          type="submit"
          data={data.submitCta.button}
          config={config?.submitCta?.button}
        />
      </div>
    </Form>
  );
};

AddressForm.displayName = 'AddressForm';

export { AddressForm };
