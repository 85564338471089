import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { memo, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { VideoAsset } from '../../../components';
import { useActionDispatch } from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';

import type { Asset, Nil } from '@tectonic/types';
import type { VideoBannerWidgetProps } from './VideoBanner.types';

const VideoBannerWidget: FC<VideoBannerWidgetProps> = ({ widget }) => {
  const { config, actions } = widget;
  const wData = widget.data!;
  const dispatch = useActionDispatch();
  const [style, className] = useStyleConfigV2(config?.video || {});
  const asset: Nil<Asset> = useHaloScript(wData.asset);
  const [containerStyle, containerClassName] = useStyleConfigV2(
    config?.container
  );

  const onInViewChange = useHaloScript(wData.onInViewChange);

  const autoPlay = useHaloScript(wData.autoPlay);
  const muted = useHaloScript(wData?.muted);
  const loop = useHaloScript(wData?.loop);
  const paused = useHaloScript(wData?.paused);

  const { ref: inViewRef } = useInView({
    onChange: (inView: boolean) => onInViewChange?.(inView),
    // delay to avoid jittery scroll
    delay: 300,
    threshold: 0.7,
  });

  const onClick = () => {
    actions?.onClick.forEach((action) => dispatch(action, undefined));
  };

  if (!asset) {
    return null;
  }
  return (
    <div
      ref={inViewRef}
      style={containerStyle}
      className={clsx('relative', containerClassName)}
    >
      <VideoAsset
        style={style}
        className={className}
        onClick={onClick}
        asset={asset}
        autoPlay={autoPlay ?? true}
        muted={muted ?? true}
        loop={loop ?? true}
        paused={paused}
        config={config?.video}
      />
    </div>
  );
};

VideoBannerWidget.displayName = 'VideoBannerWidget';

const MemoizedVideoBannerWidget = memo(VideoBannerWidget);

export { MemoizedVideoBannerWidget as VideoBannerWidget };
