import { LocalStorage } from '@tectonic/storage';
import { isEmpty } from 'lodash-es';
import { eraseCookie, setCookie } from '../helpers';

import type { Maybe } from '@tectonic/types';

const VERSION = 'fb';
const SUBDOMAIN_INDEX = 1;

enum PixelIds {
  TT_FBP = '__tt_fbp',
  TT_FBC = '__tt_fbc',
  FBCLID_PARAM = 'fbclid',
  FBP = '_fbp',
  FBC = '_fbc',
}

const getRandomNumber = () => +Math.random().toString().replace('.', '');


const setPixelCookies = (fbp: string, fbc: Maybe<string>) => {
  try {
    if (!isEmpty(fbp)) {
      setCookie(PixelIds.FBP, fbp, 180);
    }
    if (!isEmpty(fbc)) {
      setCookie(PixelIds.FBC, fbc!, 90);
    }
  } catch (error) {
    // log errors to sentry in future
  }
}

// FBP id for pixel
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
const setFBP = () => {
  const creationTime = Date.now();
  const randomNumber = getRandomNumber();
  const fbp = [VERSION, SUBDOMAIN_INDEX, creationTime, randomNumber].join('.');
  LocalStorage.setItem(PixelIds.TT_FBP, fbp);
  return fbp;
};

const setFBC = (fbclid: string) => {
  const creationTime = Date.now();
  const fbc = [VERSION, SUBDOMAIN_INDEX, creationTime, fbclid].join('.');
  LocalStorage.setItem(PixelIds.TT_FBC, fbc);
  return fbc;
};

const getFBP = () => LocalStorage.getItem(PixelIds.TT_FBP);

const getFBC = () => LocalStorage.getItem(PixelIds.TT_FBC);

const initializePixel = () => {
  try {
    let fbp = getFBP();
    if (!fbp) {
      fbp = setFBP();
    }

    const searchParams = new URLSearchParams(globalThis.location.search);
    const fbclid = searchParams.get(PixelIds.FBCLID_PARAM);
    let fbc = getFBC();

    if (fbclid) {
      // Looks like we have got a new fbclid, regenerate fbc.
      fbc = setFBC(fbclid);
    }

    setPixelCookies(fbp, fbc)

    return { fbp, fbc };
  } catch (error) {
    //
  }
  return null;
};

const resetPixel = () => {
  LocalStorage.removeItem(PixelIds.TT_FBC);
  eraseCookie(PixelIds.FBC);
};

export { getFBC, getFBP, initializePixel, resetPixel, setFBC, setFBP };
