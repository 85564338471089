import { createContext } from '@tectonic/utils';

type ElemasonActionContextType = Record<string, (...args: any[]) => void>;

const errorMessage =
  'useElemasonActionContext must be inside a ElemasonActionProvider with a value';
const name = 'ElemasonActionContext';

const [ElemasonActionProvider, useElemasonActionContext] =
  createContext<ElemasonActionContextType>({
    errorMessage,
    name,
    strict: false,
  });

export { ElemasonActionProvider, useElemasonActionContext };
export type { ElemasonActionContextType };
