import { useMemo } from 'react';

import type { MarqueeProps } from './Marquee.types';
import type { CSSProperties } from 'react';

const isUp = (direction: MarqueeProps['direction']): direction is 'up' =>
  direction === 'up';

const isDown = (direction: MarqueeProps['direction']): direction is 'down' =>
  direction === 'down';

const useMarqueeChildStyle = ({
  direction,
}: Required<Pick<MarqueeProps, 'direction'>>) =>
  useMemo(() => {
    let transform = 'none';
    if (isUp(direction)) {
      transform = 'rotate(90deg)';
    } else if (isDown(direction)) {
      transform = 'rotate(-90deg)';
    }

    return { '--marquee-transform': transform } as CSSProperties;
  }, [direction]);

const useMarqueeStyle = ({
  direction,
  play,
  delay,
  loop,
  autoFill,
  duration,
}: Required<
  Pick<MarqueeProps, 'direction' | 'play' | 'delay' | 'loop' | 'autoFill'>
> & { duration: number }) =>
  useMemo(() => {
    const animationPlay = play ? 'running' : 'paused';
    const animationDirection = direction === 'left' ? 'normal' : 'reverse';
    const animationDuration = `${duration}s`;
    const animationDelay = `${delay}s`;
    const animationIteration = loop ? `${loop}` : 'infinite';
    const width = autoFill ? 'auto' : '100%';
    return {
      '--marquee-play': animationPlay,
      '--marquee-direction': animationDirection,
      '--marquee-duration': animationDuration,
      '--marquee-delay': animationDelay,
      '--marquee-iteration-count': animationIteration,
      '--marquee-min-width': width,
    } as CSSProperties;
  }, [play, direction, duration, delay, loop, autoFill]);

const useContainerStyle = ({
  style,
  play,
  pauseOnHover,
  pauseOnClick,
  direction,
}: Required<
  Pick<
    MarqueeProps,
    | 'direction'
    | 'play'
    | 'delay'
    | 'loop'
    | 'autoFill'
    | 'style'
    | 'pauseOnClick'
    | 'pauseOnHover'
  >
>) =>
  useMemo(() => {
    const animationPauseOnHover = !play || pauseOnHover ? 'paused' : 'running';
    const animationPauseOnClick =
      !play || (pauseOnHover && !pauseOnClick) || pauseOnClick
        ? 'paused'
        : 'running';
    const width = isUp(direction) || isDown(direction) ? '100vh' : '100%';
    let transform = 'none';
    if (isUp(direction)) {
      transform = 'rotate(-90deg)';
    } else if (isDown(direction)) {
      transform = 'rotate(90deg)';
    }
    return {
      ...style,
      '--marquee-pause-on-hover': animationPauseOnHover,
      '--marquee-pause-on-click': animationPauseOnClick,
      '--marquee-width': width,
      '--marquee-transform': transform,
    };
  }, [style, play, pauseOnHover, pauseOnClick, direction]);

const useGradientStyle = ({
  gradientColor,
  gradientWidth,
}: Required<Pick<MarqueeProps, 'gradientColor' | 'gradientWidth'>>) => {
  // Gradient color in an unfinished rgba format
  const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;

  return useMemo(() => {
    const width =
      typeof gradientWidth === 'number' ? `${gradientWidth}px` : gradientWidth;
    const color = `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`;
    return {
      '--marquee-gradient-color': color,
      '--marquee-gradient-width': width,
    } as CSSProperties;
  }, [rgbaGradientColor, gradientWidth]);
};

export {
  isDown,
  isUp,
  useContainerStyle,
  useGradientStyle,
  useMarqueeChildStyle,
  useMarqueeStyle,
};
