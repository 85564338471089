import { useColors } from '@tectonic/uikit';

import type { BackgroundConfig, Color } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useBackgroundConfig = (config: BackgroundConfig) => {
  const backgroundStyles: CSSProperties = {};
  const colors = useColors();

  if (config.filter) {
    backgroundStyles.filter = config.filter;
  }

  if (config.backgroundColor) {
    backgroundStyles.backgroundColor =
      colors[config.backgroundColor as Color] ?? config.backgroundColor;
  }

  if (config.backdropFilter) {
    backgroundStyles.backdropFilter = config.backdropFilter;
    // @ts-ignore
    backgroundStyles['-webkit-backdrop-filter'] = config.backdropFilter;
  }

  if (config.background) {
    backgroundStyles.background = config.background;
  }

  if (config.backgroundSize) {
    backgroundStyles.backgroundSize = config.backgroundSize;
  }

  return backgroundStyles;
};

export { useBackgroundConfig };
