import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { AlertDialogDescription,  } from '@tectonic/uikit';
import { Icon } from '.././../Icon';

import type { FC } from 'react';
import type { ConfirmationDialogContentProps } from './ConfirmationDialog.types';

const ConfirmationDialogContent: FC<ConfirmationDialogContentProps> = ({
  data,
  config,
  onClose,
}) => {
  const { container, message, closeIcon } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});
  const titleContainerStyleConfig = useStyleConfig(message?.container ?? {});

  return (
    <div {...containerStyleConfig}>
      <AlertDialogDescription asChild>
        <div {...titleContainerStyleConfig}>
          <Text config={message?.text} data={data.message.text} />
          {data?.closeIcon &&  <Icon symbol={data?.closeIcon}  config={closeIcon ?? {}} onClick={onClose}/>}
        </div>
      </AlertDialogDescription>
    </div>
  );
};

ConfirmationDialogContent.displayName = 'ConfirmationDialogContent';

export { ConfirmationDialogContent };
