import { SearchOperator } from '@tectonic/types';
import { isCheckboxControl, isRangeControl } from '@tectonic/utils';
import { isNull, keyBy, pickBy } from 'lodash-es';

import type {
  SearchFacetConfigWithValues,
  SearchFilter,
} from '@tectonic/types';
import type { SearchFilterFormValue } from './SearchFilterForm.types';

const toSearchFilters = ({
  facets,
  formValues,
  filters,
}: {
  formValues: SearchFilterFormValue;
  facets: SearchFacetConfigWithValues[];
  filters: Record<string, SearchFilter>;
}): SearchFilter[] => {
  const configMap = keyBy<SearchFacetConfigWithValues>(facets, 'name');

  // To convert the formValues to filters, we need to do consider following scenarios
  // 1. A filter values is removed
  // 2. A filter value is updated
  // 3. A filter value did not change.
  // 4. A filter is unknown to client. Maybe we didn't find it in config etc.

  // We ignore filters that are not present in config. Server takes care of these
  // filters.
  const nFilters: Record<string, SearchFilter> = pickBy(
    filters,
    (_, key) => !!configMap[key]
  );

  const nFormValues = { ...formValues };

  for (const [field, value] of Object.entries(nFormValues)) {
    const config = configMap[field];
    const filter = nFilters[field];

    if (isNull(value)) {
      // form value is null. It means two things. Either, we didn't find any config
      // for it. Or there was no value for it in `appliedFilters/initialFilters`.
      continue;
    }

    // We have the config of the filter. We transform its filter value before
    // setting it to filter. For example, a range filter in form stores values
    // as [min, max], we need to convert it into {min, max}
    if (isRangeControl(config)) {
      nFilters[field] = {
        ...filter,
        field,
        // Filter might not have been applied initially. In such cases, we apply
        // filter with corresponding default operator.
        operator: filter?.operator ?? SearchOperator.BETWEEN,
        value: { min: value[0], max: value[1] },
      };
      continue;
    }

    if (isCheckboxControl(config)) {
      nFilters[field] = {
        ...filter,
        field,
        value,
        // Filter might not have been applied initially.
        operator: filter?.operator ?? SearchOperator.IN,
      };
      continue;
    }

    nFilters[field] = {
      ...filter,
      field,
      value,
      operator: filter?.operator ?? SearchOperator.IS,
    };
  }

  return Object.values(nFilters);
};

export { toSearchFilters };
