import clsx from 'clsx';

import type { FC } from 'react';
import type { InputSlotProps } from './Input2.types';

const InputSlot2: FC<InputSlotProps> = ({
  size,
  className,
  children,
  ...restProps
}) => (
  <div className={clsx('center', className)} {...restProps}>
    {children}
  </div>
);

InputSlot2.displayName = 'InputSlot';

export { InputSlot2 };
