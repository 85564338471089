import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { isHaloScript } from '@tectonic/utils';
import clsx from 'clsx';
import deepmerge from 'deepmerge';
import { isEmpty } from 'lodash-es';
import { memo, useMemo } from 'react';
import { Seo } from '../../components/Seo';
import { useHaloScript } from '../../hooks/useHaloScript';
import { Widget } from '../Widget';

import type { ElemasonCell, ElemasonWidget } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface CellProps extends ComponentProps<'div'> {
  cell: ElemasonCell;
}

const useCellContent = (cell: ElemasonCell) =>
  useMemo(() => {
    const { widget, children, seo } = cell;

    const seoContent = seo ? <Seo seo={cell.seo} /> : null;

    if (!isEmpty<ElemasonWidget<unknown, unknown>>(widget)) {
      return (
        <>
          <Widget widget={widget} />
          {seoContent}
        </>
      );
    }

    return (
      <>
        {(children ?? []).map((child, index) => (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <Cell cell={child} key={child.id ?? index} />
        ))}
        {seoContent}
      </>
    );
  }, [cell]);

const Cell: FC<CellProps> = ({ cell, style, className }) => {
  const configOverrides = useHaloScript(cell.configOverrides);
  let domId = useHaloScript<string>(
    isHaloScript(cell.config?.domId) ? cell.config?.domId : undefined
  );
  domId = (domId ?? cell.config?.domId) as string;
  const cellWithResolvedConfig = useMemo(
    () => ({
      ...cell,
      config: configOverrides
        ? deepmerge(cell.config ?? {}, configOverrides)
        : cell.config,
    }),
    [cell, configOverrides]
  );

  const { span, direction, ...config } = cellWithResolvedConfig.config ?? {};
  const render =
    useHaloScript(cellWithResolvedConfig.shouldRender) ??
    !cellWithResolvedConfig.shouldRender;
  const [cellStyle, cellClassName] = useStyleConfigV2(config ?? {});

  const content = useCellContent(cellWithResolvedConfig);

  if (!render) {
    return null;
  }

  const flex = span ?? 1;

  return (
    <div
      id={domId}
      style={{
        flex: flex !== 'none' ? flex : undefined,
        ...style,
        ...cellStyle,
      }}
      className={clsx(
        'flex',
        className,
        cellClassName,
        config.domClass,
        direction === 'column' ? 'flex-col' : 'flex-row'
      )}
    >
      {content}
    </div>
  );
};

Cell.displayName = 'ElemasonCell';

const MemoizedCell = memo(Cell);

export { MemoizedCell as Cell };
