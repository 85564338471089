import { VideoSourceType } from '@tectonic/types';
import { useMemo } from 'react';

import type { Asset } from '@tectonic/types';

const getVideoSource = (asset: Asset) =>
  asset.streamUrl
    ? [VideoSourceType.STREAM, asset.streamUrl, asset.originalUrl]
    : [VideoSourceType.CONTAINER, asset.originalUrl, asset.originalUrl];

const useVideoSource = (asset?: Asset) =>
  useMemo(() => (asset ? getVideoSource(asset) : []), [asset]);

export default useVideoSource;
