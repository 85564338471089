import { useMutation, useQueryClient } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { useToast } from '../../../core';
import { useActionDispatch, useHaloScript } from '../../../hooks';
import { queryKeys } from '../../../queryKeys';

import type {
  ElemasonUserProfileCtaWidget,
  UserProfile,
  UserProfileDataUpdate,
} from '@tectonic/types';

const useProfile = (widget: ElemasonUserProfileCtaWidget) => {
  const wData = widget.data!;
  const { actions } = widget;

  const { showToast } = useToast();
  const profile = useHaloScript<Partial<UserProfile>>(wData.profile)!;
  const operation = useHaloScript<'save' | 'remove'>(wData.operation)!;
  const dispatch = useActionDispatch();

  const queryClient = useQueryClient();

  const { mutate: saveProfile, isPending: isSaving } = useMutation({
    mutationKey: [queryKeys.profileData()],
    onSuccess: () => {
      showToast({ title: wData.save?.successMessage });
      queryClient.invalidateQueries({ queryKey: queryKeys.profiles() });
      queryClient.invalidateQueries({ queryKey: queryKeys.profileData() });
      actions?.onSuccess?.forEach((action) => dispatch(action));
    },
    mutationFn: (nProfile: UserProfileDataUpdate) =>
      remixApi.updateUserProfileData(nProfile),
    onError: (error) => {
      showToast({ title: getErrorMessage(error, wData.save?.errorMessages) });
      actions?.onError?.forEach((action) => dispatch(action));
    },
  });

  const { mutate: deleteProfile } = useMutation({
    mutationKey: [queryKeys.profileData()],
    onSuccess: () => {
      actions?.onSuccess?.forEach((action) => dispatch(action));
      showToast({ title: wData.remove?.successMessage });
      queryClient.invalidateQueries({ queryKey: queryKeys.profiles() });
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileData(),
      });
    },
    onError: (error) => {
      showToast({
        title: getErrorMessage(error, wData.remove?.errorMessages),
      });
      actions?.onError?.forEach((action) => dispatch(action));
    },
    mutationFn: (slug: string) => remixApi.deleteUserProfileData(slug),
  });

  const isLoading = isSaving;

  const onSave = () => {
    if (!profile.profileData) {
      return;
    }
    saveProfile(profile as UserProfileDataUpdate);
  };

  const onRemove = () => {
    if (!profile.profileData) {
      return;
    }
    deleteProfile(profile.slug!);
  };

  const onClick = () => {
    if (operation === 'save') {
      onSave();
    } else if (operation === 'remove') {
      onRemove();
    }
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return { onClick, isLoading };
};

export { useProfile };
