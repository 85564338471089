import { Icon, useThemeContext } from '@tectonic/uikit';
import { forwardRef } from 'react';
import { Center } from '../Center';

import type { ComponentProps, ForwardRefRenderFunction } from 'react';

interface LoaderProps extends Omit<ComponentProps<typeof Center>, 'children'> {
  className?: string;
  iconProps?: ComponentProps<typeof Icon>;
}

const Loader: ForwardRefRenderFunction<HTMLDivElement, LoaderProps> = (
  { iconProps, ...props },
  ref
) => {
  const {
    icon: { spinner },
  } = useThemeContext();

  return (
    <Center ref={ref} {...props}>
      <Icon symbol={spinner} className="animate-spin" {...iconProps} />
    </Center>
  );
};

Loader.displayName = 'Loader';

const ExoticLoader = forwardRef(Loader);

export { ExoticLoader as Loader };
