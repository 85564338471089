import { TransformComponent, TransformWrapper } from '@tectonic/uikit';

import type { ReactZoomPanPinchHandlers } from '@tectonic/uikit';
import type { FC, ReactNode } from 'react';

interface PanZoomTransformProps {
  children: ReactNode;
  handler?: (utils: ReactZoomPanPinchHandlers) => ReactNode;
}

const PanZoomTransform: FC<PanZoomTransformProps> = ({ children, handler }) => {
  if (!children) {
    return null;
  }

  return (
    <TransformWrapper>
      {(utils) => (
        <div className="relative h-full w-full">
          {handler && handler(utils)}
          <TransformComponent
            wrapperStyle={{ height: '100%', width: '100%' }}
            contentStyle={{
              width: '100%',
              height: '100%',
              transformOrigin: '0px 0px',
            }}
          >
            {children}
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
};

export { PanZoomTransform };
