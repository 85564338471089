import { isEmpty } from 'lodash-es';
import { SearchTrends } from '../../../components/Search';
import { useSearchTrends } from './hooks';

import type { FC } from 'react';
import type { SearchTrendsWidgetProps } from './SearchTrends.types';

const SearchTrendsWidget: FC<SearchTrendsWidgetProps> = ({ widget }) => {
  const { isLoading, trends, onQueryClick } = useSearchTrends(widget);
  const data = widget.data!;

  // We don't display widget if it has no queries.
  const shouldHideWidget = !isLoading && isEmpty(trends);
  if (shouldHideWidget) {
    return null;
  }

  return (
    <SearchTrends
      data={data}
      config={widget.config}
      isLoading={isLoading}
      queries={trends}
      onQueryClick={onQueryClick}
    />
  );
};

SearchTrendsWidget.displayName = 'ElemasonSearchTrendsWidget';

export { SearchTrendsWidget };
