import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useTextConfig } from '@tectonic/elemason-components';
import { Input2, InputField2 } from '@tectonic/uikit';
import clsx from 'clsx';
import { useEffect, type ChangeEvent, type FC } from 'react';
import { useDebounce } from 'use-debounce';
import { useActionDispatch, useSharedLocalState } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';
import { queryKeys } from '../../queryKeys';

import type { ElemasonPincodeInputWidget } from '@tectonic/types';

interface PincodeInputWidgetProps {
  widget: ElemasonPincodeInputWidget;
}

const PincodeInputWidget: FC<PincodeInputWidgetProps> = ({
  widget: { config, data, actions },
}) => {
  const stateKey = useHaloScript(data?.stateKey!)!;
  const defaultValue = useHaloScript(data?.defaultValue!);
  const { sharedState, setSharedState } = useSharedLocalState(
    stateKey,
    defaultValue
  );
  const [debounced] = useDebounce(sharedState, 500);
  const dispatch = useActionDispatch();

  const [inputTextStyle, inputTextClasses] = useTextConfig(
    config?.input?.text ?? {}
  );

  const { data: isValidZip } = useQuery({
    enabled: !!debounced,
    queryKey: queryKeys.isValidZip(debounced ?? ''),
    queryFn: async () => remixApi.getIsValidZip(debounced ?? ''),
  });

  useEffect(() => {
    if (isValidZip?.valid) {
      actions?.onValidZip?.forEach((action) =>
        dispatch(action, { zip: debounced })
      );
    } else {
      actions?.onInvalidZip?.forEach((action) =>
        dispatch(action, { zip: debounced })
      );
    }
  }, [isValidZip, debounced, actions]);

  useEffect(() => {
    actions?.onChange?.forEach((action) => dispatch(action));
  }, [actions, sharedState]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSharedState(event.target.value);
  };

  return (
    <Input2
      disabled={data?.disabled}
      size={config?.input?.size}
      rounded={config?.input?.rounded}
      style={{ maxWidth: config?.input?.maxWidth, width: config?.input?.width }}
    >
      <InputField2
        className={clsx('w-full', inputTextClasses)}
        style={{ ...inputTextStyle, color: config?.input?.text?.color }}
        max={config?.input?.max}
        min={config?.input?.min}
        disabled={data?.disabled}
        type={config?.input?.type}
        placeholder={data?.placeholder}
        pattern={config?.input?.pattern}
        required={config?.input?.required}
        minLength={config?.input?.minLength}
        maxLength={config?.input?.maxLength}
        autoFocus={config?.input?.autoFocus}
        value={sharedState ?? defaultValue ?? ''}
        onChange={onChange}
      />
    </Input2>
  );
};

export { PincodeInputWidget };
