import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { useActionDispatch, usePageFragment } from '../../../hooks';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { useAttributeContext } from './context/AttributeContext';

import type { ElemasonAttributeSelectorWidget } from '@tectonic/types';
import type { FC } from 'react';

interface AttributeSelectorWidgetProps {
  widget: ElemasonAttributeSelectorWidget;
}

const AttributeSelectorWidget: FC<AttributeSelectorWidgetProps> = ({
  widget,
}) => {
  const { onSelect } = useAttributeContext();
  const attributeValue = useHaloScript(widget.data?.attributeValue);
  const [style, className] = useStyleConfigV2(widget.config?.container);
  const attributeValueFragment = usePageFragment(
    widget.data?.attributeValueFragment
  );
  const dispatch = useActionDispatch();

  const attributeValueFragmentValue = useFragmentValue(attributeValueFragment);

  return (
    <Clickable
      style={style}
      className={className}
      onClick={(event) => {
        event.stopPropagation();
        onSelect(attributeValue);
        widget.actions?.onClick?.forEach((action) => dispatch(action));
      }}
    >
      <ElemasonFragmentProvider value={attributeValueFragmentValue()}>
        {attributeValueFragment?.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </Clickable>
  );
};

export { AttributeSelectorWidget };
