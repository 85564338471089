import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { queryKeys } from '../../queryKeys';

const useRecentlyViewedProducts = (enabled = true) => {
  const { data, isLoading, ...rest } = useQuery({
    enabled,
    queryKey: queryKeys.recentlyViewedProducts(),
    queryFn: async () => remixApi.getRecentlyViewedProducts(),
  });
  return {
    ...data,
    ...rest,
    isLoading: isLoading || !enabled,
  };
};

export { useRecentlyViewedProducts };
