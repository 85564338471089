import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from '@tectonic/errors';
import { checkUserExists, requestOtp } from '@tectonic/remix-client-network';
import { ElemasonAuthMode, ElemasonAuthStep } from '@tectonic/types';
import { isEmpty } from 'lodash-es';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useHaloScript } from '../../../hooks';
import { useAuthStore } from '../../../store';

import type {
  ElemasonAuthPhoneNoOtpSignInFormWidget,
  UserExistsResponse,
} from '@tectonic/types';
import type { AuthPhoneNoOtpSignInFormValue } from '../../../components';

const getUserExistence = async (phone: string): Promise<UserExistsResponse> => {
  const response = await checkUserExists({ phone });
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }

  return response.data;
};

const sendOtp = async (phone: string) => {
  const otpResponse = await requestOtp({ phone });
  if (otpResponse.error) {
    throw otpResponse.error;
  }
};

const usePhoneOtpSignInForm = (
  widget: ElemasonAuthPhoneNoOtpSignInFormWidget
) => {
  const setStep = useAuthStore((state) => state.setStep);
  const wData = widget.data!;
  const { showToast } = useToast();
  const noAccountFoundStepPayload = useHaloScript<{
    step: ElemasonAuthStep;
    mode: ElemasonAuthMode;
  }>(wData.noAccountFoundStepPayload);

  const mutationFn = async (value: AuthPhoneNoOtpSignInFormValue) => {
    try {
      const { phoneNumber } = value;
      const [stdCode] = phoneNumber.country.stdCodes;
      const phone = `${stdCode}${phoneNumber.phone}`;
      const { exists: isExistingUser, signupRequired: isSignUpRequired } =
        await getUserExistence(phone);

      if (!isExistingUser) {
        const { step, mode } = noAccountFoundStepPayload ?? {};
        // User doesn't exist in our system. Let's redirect them to auth flow.
        setStep(step ?? ElemasonAuthStep.EMAIL_OTP_SIGN_UP, {
          phoneNumber,
          mode: mode ?? ElemasonAuthMode.SIGN_UP,
        });
        showToast({ title: wData.messages.noAccountFound });
        return;
      }

      // User exists. Let's send the OTP.
      await sendOtp(phone);

      setStep(ElemasonAuthStep.PHONE_NO_OTP_CONFIRM, {
        phoneNumber,
        emailLinking: {
          required: isSignUpRequired,
          phoneVerificationCode: null,
        },
      });
      showToast({ title: wData.messages.signInOtpSendSuccess });
    } catch (error) {
      showToast({ title: getErrorMessage(error, wData.errorMessages) });
    }
  };

  const { isPending: isLoading, mutate: onSubmit } = useMutation({
    mutationFn,
    mutationKey: [],
  });

  return { isLoading, onSubmit };
};

export { usePhoneOtpSignInForm };
