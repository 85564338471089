import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { toCurrency } from '@tectonic/utils';
import clsx from 'clsx';
import { Button } from '../../../../components';
import { isVisible } from '../../../../utils';
import { CartCouponApplicability } from '../CartCouponApplicability';

import type {
  ElemasonCartCouponsDialogContentCardConfig,
  ElemasonCartCouponsDialogContentCardData,
  Promotion,
} from '@tectonic/types';
import type { FC } from 'react';

interface DiscountCardProps {
  disabled?: boolean;
  isApplied?: boolean;
  discount?: Promotion;
  onApply?: () => void;
  data?: ElemasonCartCouponsDialogContentCardData;
  config?: ElemasonCartCouponsDialogContentCardConfig;
}

const DiscountCard: FC<DiscountCardProps> = ({
  data,
  config,
  onApply,
  discount,
  disabled,
  isApplied,
}) => {
  const showTitle = isVisible(config?.title);
  const showDescription = isVisible(config?.description);
  const showShortSummary = isVisible(config?.shortSummary);
  const [style, className] = useStyleConfigV2(config?.container ?? {});
  const [appliedStyle, appliedClassName] = useStyleConfigV2(
    config?.container?.applied ?? {}
  );
  const [titleStyle, titleClassName] = useStyleConfigV2(config?.title ?? {});
  const [applyButtonStyle, applyButtonClassName] = useStyleConfigV2(
    config?.applyButton ?? {}
  );
  const [appliedButtonStyle, appliedButtonClassName] = useStyleConfigV2(
    config?.appliedButton ?? {}
  );
  const [descriptionStyle, descriptionClassName] = useStyleConfigV2(
    config?.description ?? {}
  );
  const [shortSummaryStyle, shortSummaryClassName] = useStyleConfigV2(
    config?.shortSummary ?? {}
  );

  if (!discount) return null;

  return (
    <div
      style={{ ...style, ...(isApplied ? appliedStyle : {}) }}
      className={clsx(className, isApplied && appliedClassName)}
    >
      {showTitle && (
        <div style={titleStyle} className={clsx('flex', titleClassName)}>
          <Text data={discount.title} config={config?.title} />
          <Button
            onClick={onApply}
            disabled={disabled}
            style={isApplied ? appliedButtonStyle : applyButtonStyle}
            data={isApplied ? data?.appliedButton : data?.applyButton}
            config={isApplied ? config?.appliedButton : config?.applyButton}
            className={
              isApplied ? appliedButtonClassName : applyButtonClassName
            }
          />
        </div>
      )}
      {showShortSummary && (
        <div style={shortSummaryStyle} className={shortSummaryClassName}>
          <Text
            data={`Save: ${toCurrency(
              discount.calculatedDiscountValue.amount,
              discount.calculatedDiscountValue.currencyCode
            )}`}
            config={config?.shortSummary}
          />
        </div>
      )}
      {showDescription && (
        <div style={descriptionStyle} className={descriptionClassName}>
          <Text data={discount.description} config={config?.description} />
        </div>
      )}
      {/* TODO: add TNC */}
      <CartCouponApplicability
        discount={discount}
        data={data?.appplicability}
        config={config?.applicability}
        applicability={discount.currentApplicability}
        requirement={discount.discountMinRequirementConfig}
      />
    </div>
  );
};

export { DiscountCard };
