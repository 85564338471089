import { create } from 'zustand';

import type { LoginDrawerState } from './LoginDrawer.types';

const useLoginDrawer = create<LoginDrawerState>((setState) => ({
  isOpen: false,
  slug: null,
  open: (slug: string) => {
    setState({ slug, isOpen: true });
  },
  close: () => {
    setState({ slug: null, isOpen: false });
  },
}));

export { useLoginDrawer };
