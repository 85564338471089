import { useMutation, useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { Logger } from '@tectonic/logger';
import { getOrderReturnsUrl } from '@tectonic/remix-client-network';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { isEmpty, isNil } from 'lodash-es';
import { OrderDetails } from '../../components';
import { useActionDispatch } from '../../hooks';
import { useAccessorValue } from '../../hooks/useAccessorValue';
import { queryKeys } from '../../queryKeys';

import type { ElemasonOrderDetailsWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonOrderDetailsWidgetProps {
  widget: ElemasonOrderDetailsWidget;
}

const OrderDetailsWidget: FC<ElemasonOrderDetailsWidgetProps> = ({
  widget: { data },
}) => {
  const dispatch = useActionDispatch();
  const {
    orderId: orderIdAcc,
    variantId: variantIdAcc,
    orderReturnCtaText,
  } = data ?? {};

  const orderId = useAccessorValue(orderIdAcc!);
  const variantId = useAccessorValue(variantIdAcc!);

  const {
    data: orderDetails,
    isError,
    isLoading,
  } = useQuery({
    enabled: !!orderId,
    queryKey: queryKeys.order(orderId!),
    queryFn: async () => remixApi.getOrderDetails(orderId!),
  });

  const {
    isPending: isLoadingReturnExchangeUrl,
    mutate: onReturnExchangeClick,
  } = useMutation({
    mutationKey: [],
    mutationFn: async () => {
      const response = await getOrderReturnsUrl(
        encodeURIComponent(orderId!),
        encodeURIComponent(variantId!)
      );
      const { data: rData, error } = response;
      if (error || isEmpty(rData)) {
        throw error;
      }
      return rData!;
    },
    onError: (error) => {
      dispatch({
        type: ElemasonWidgetActionType.TOAST,
        payload: { title: 'Something went wrong', duration: 2000 },
      });
      Logger.error('Error fetching return/exchange url', error);
    },
    onSuccess: (url: string) => {
      dispatch({
        type: ElemasonWidgetActionType.LINK_OPEN,
        payload: {
          url,
          target: '_blank',
          metadata: {
            title: 'Return/Exchange',
          },
        },
      });
    },
  });

  if (isNil(orderId) || isNil(variantId)) {
    return null;
  }

  if (isEmpty(orderDetails) && !isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <OrderDetails
        isError={isError}
        order={orderDetails!}
        isLoading={isLoading}
        variantId={variantId!}
        orderReturnCtaText={orderReturnCtaText}
        onReturnExchangeClick={onReturnExchangeClick}
        isLoadingReturnExchangeUrl={isLoadingReturnExchangeUrl}
      />
    </div>
  );
};

export { OrderDetailsWidget };
