import { AnalyticsSearchEventNames } from '@tectonic/analytics';
import {
  ElemasonWidgetActionType,
  type ElemasonSearchSortFacetWidget,
  type SearchFacetOption,
} from '@tectonic/types';
import { getUrlSearchParams } from '@tectonic/utils';
import { omit } from 'lodash-es';
import { useActionDispatch } from '../../../hooks';
import { useSearchFacets } from '../../../hooks/network';

const useSortFacet = (widget: ElemasonSearchSortFacetWidget) => {
  const { isLoading, data } = useSearchFacets(widget.data?.searchConfig);

  const sortBy = data?.appliedSortBy;

  const sortFacetConfig = data?.searchConfig.config.sortConfig;

  const actionDispatch = useActionDispatch();

  const onSelect = (option: SearchFacetOption) => {
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsSearchEventNames.SEARCH_SORT_APPLY,
        data: {
          sortBy: option.value,
        },
      },
    });

    const searchParams = getUrlSearchParams(globalThis.location.href);
    const nSearchParams = new URLSearchParams({
      ...omit(searchParams, 'page'),
      sortBy: option.value,
    });
    window.location.search = nSearchParams.toString();
    // TODO: Dispatch action to close drawer here.
  };

  return { isLoading, sortFacetConfig, sortBy, onSelect };
};

export { useSortFacet };
