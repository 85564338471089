import { LocalStorage } from '@tectonic/storage';
import { canUseDOM, withNamespace } from '../helpers';

const getValue = (key: string): unknown => {
  if (!canUseDOM) {
    return null;
  }
  return LocalStorage.getItem(withNamespace(key));
};

const setValue = (key: string, value: unknown): unknown => {
  if (!canUseDOM) {
    return null;
  }
  return LocalStorage.setItem(withNamespace(key), value);
};

export { getValue, setValue };
