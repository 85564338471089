import { useMutation } from '@tanstack/react-query';
import { AnalyticsSearchEventNames } from '@tectonic/analytics';
import { createSearchHistory } from '@tectonic/remix-client-network';
import {
  ElemasonWidgetActionType,
  LocalStateKeys,
  NavigationActionType,
  SearchCollections,
} from '@tectonic/types';
import { Controller, Form, useForm } from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { TextField } from '../../../components';
import { useActionDispatch, useSharedLocalState } from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';

import type { UserSearchHistoryCreatePayload } from '@tectonic/types';
import type { FormSubmitHandler } from '@tectonic/uikit';
import type { FC } from 'react';
import type {
  SearchAutoSuggestFormData,
  SearchAutoSuggestInputWidgetProps,
} from './SearchAutoSuggestInput.types';

const defaultValues = { query: '' };

const mutationFn = (payload: UserSearchHistoryCreatePayload) =>
  createSearchHistory(payload);

const SearchAutoSuggestInputWidget: FC<SearchAutoSuggestInputWidgetProps> = ({
  widget,
}) => {
  const { config } = widget;
  const wData = widget.data!;
  const actionDispatch = useActionDispatch();
  const defaultQuery = useHaloScript(wData.defaultQuery);

  const { mutate: createHistory } = useMutation({
    mutationFn,
    mutationKey: ['searchHistory'],
  });

  const { control, watch, setValue } = useForm<SearchAutoSuggestFormData>({
    defaultValues: { ...defaultValues, query: defaultQuery ?? '' },
    mode: 'onChange',
  });

  const { setSharedState } = useSharedLocalState<string>(
    LocalStateKeys.AUTO_SUGGEST_SEARCH_INPUT_TEXT,
    ''
  );

  const query = watch('query');
  const [debouncedQuery] = useDebounce<string>(query!, 300);

  const endIcon = isBlank(query) ? wData.input.endIcon : wData.clearIcon;

  const onSubmit: FormSubmitHandler<SearchAutoSuggestFormData> = (formData) => {
    const rawQuery = formData.data.query ?? '';

    if (!rawQuery) {
      return;
    }

    // TODO: read this from NavigationRegistry.

    const params = { q: rawQuery, rawQuery };

    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsSearchEventNames.SEARCH_CLICK,
        data: {
          querySource: { query: params },
        },
      },
    });

    createHistory({
      collection: SearchCollections.PRODUCT,
      tsQuery: { q: rawQuery },
    });

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PATH,
        slug: 'plp',
        search: params,
      },
    });
  };

  const onClear = () => {
    setValue('query', '');
  };

  useEffect(() => {
    setSharedState(debouncedQuery);
  }, [debouncedQuery, setSharedState]);

  return (
    <Form control={control} onSubmit={onSubmit}>
      <Controller
        name="query"
        control={control}
        render={({ field }) => (
          <TextField
            value={field.value}
            onEndIconClick={onClear}
            config={config?.input}
            data={{ ...wData.input, endIcon }}
            onChange={(event) => field.onChange(event.target.value)}
          />
        )}
      />
    </Form>
  );
};

SearchAutoSuggestInputWidget.displayName =
  'ElemasonSearchAutoSuggestInputWidget';

export { SearchAutoSuggestInputWidget };
