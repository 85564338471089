import { useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { uniq } from 'lodash-es';
import { SizeDropDown } from './SizeDropdown';
import { SizePills } from './SizePills';

import type {
  ProductSizeVariantsContainerConfig,
  ProductVariant,
} from '@tectonic/types';
import type { FC, MouseEventHandler } from 'react';

interface ProductSizeVariantsProps {
  onClick?: MouseEventHandler;
  variants: ProductVariant[];
  selectedVariant?: ProductVariant;
  config: ProductSizeVariantsContainerConfig;
  attr: 'basisAttr1' | 'basisAttr2' | 'basisAttr3';
}

const ProductSizeVariants: FC<ProductSizeVariantsProps> = ({
  attr,
  config,
  onClick,
  variants,
  selectedVariant,
}) => {
  const selectedSize = selectedVariant?.[`${attr}Display`];
  const { style, className } = useStyleConfig(config.container ?? {});

  const sizes = variants
    ?.filter((v) => v.id !== selectedVariant?.id)
    .map((variant) => variant[`${attr}Display`]!);
  if (uniq(sizes).length <= 1) return null;
  if (config?.config.variant === 'dropdown') {
    return (
      <Clickable
        style={style}
        className={className}
        onClick={onClick}
        title="Product size variant dropdown"
      >
        <SizeDropDown
          sizes={sizes}
          config={config.config}
          selectedSize={selectedSize}
        />
      </Clickable>
    );
  }

  if (config?.config.variant === 'pills') {
    return (
      <Clickable
        style={style}
        className={clsx('flex', className)}
        onClick={onClick}
        title="Product size variant pills"
      >
        <SizePills
          sizes={sizes}
          config={config.config}
          selectedSize={selectedSize}
        />
      </Clickable>
    );
  }

  return null;
};

export { ProductSizeVariants };
