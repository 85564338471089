import { createContext } from '@tectonic/utils';

import type { CarouselContextType } from './Carousel.types';

const errorMessage =
  'useCarouselContext must be inside a CarouselProvider with a value';

const name = 'CarouselContext';

const [CarouselProvider, useCarouselContext, CarouselContext] =
  createContext<CarouselContextType>({ errorMessage, name });

export { CarouselContext, CarouselProvider, useCarouselContext };
