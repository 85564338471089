import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import { createReview } from '@tectonic/remix-client-network';
import { isBlank } from '@tectonic/utils';
import { compact, isEmpty, isNil } from 'lodash-es';
import { useState } from 'react';
import { useToast } from '../../../core';
import {
  useActionDispatch,
  useFragmentValue,
  useHaloScript,
  usePageFragment,
} from '../../../hooks';

import type {
  ElemasonProductAddReviewWidget,
  ProductReview,
  ProductReviewCreatePayload,
} from '@tectonic/types';

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

const mutationFn = async (
  slug: string,
  payload: ProductReviewCreatePayload
): Promise<ProductReview> => {
  const response = await createReview(slug, payload);
  if (response.error || isNil(response.data)) {
    throw response.error;
  }

  return response.data;
};

const validate = (
  payload: ProductReviewCreatePayload,
  errorMessages: NonNullable<
    ElemasonProductAddReviewWidget['data']
  >['errorMessages']
): Record<string, string> => {
  const errors: Record<string, string> = {};

  if (isBlank(payload.userDisplayName)) {
    errors.userDisplayName = errorMessages.userDisplayName.required;
  }

  if (isNil(payload.rating?.default?.score)) {
    errors.rating = errorMessages.rating.required;
  }

  if (isBlank(payload.body)) {
    errors.body = errorMessages.body.required;
  } else if (payload.body.length > 5000) {
    errors.body = errorMessages.body.maxLength;
  }

  if (isBlank(payload.userEmail)) {
    errors.userEmail = errorMessages.userEmail.required;
  } else if (EMAIL_REGEX.test(payload.userEmail!) === false) {
    errors.userEmail = errorMessages.userEmail.invalid;
  }

  return errors;
};

const useProductAddReview = (widget: ElemasonProductAddReviewWidget) => {
  const wData = widget.data!;
  const wActions = widget.actions!;
  const dispatch = useActionDispatch();
  const { showToast } = useToast();
  const productSlug = useHaloScript<string>(wData.productSlug)!;

  const { errorMessages } = wData;
  const [errors, setErrors] = useState<Record<string, string>>({});

  const {
    mutate: addReview,
    isPending: isCreating,
    isError: hasCreateError,
  } = useMutation({
    mutationFn: (payload: ProductReviewCreatePayload) =>
      mutationFn(productSlug, payload),
    onSuccess: () => {
      wActions.onSuccess?.forEach((action) => dispatch(action));
    },
    onError: (error) => {
      wActions.onError?.forEach((action) => dispatch(action));
      Logger.error('useProductAddReview:onSubmit', error);
      showToast({ title: getErrorMessage(error) });
    },
  });

  const onAddReview = (payload: ProductReviewCreatePayload) => {
    const cErrors = validate(payload, errorMessages);
    setErrors(cErrors);
    if (!isEmpty(cErrors)) {
      return;
    }
    addReview({
      ...payload,
      assets: compact(
        payload.assets ?? []
      ) as ProductReviewCreatePayload['assets'],
    });
  };

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    onAddReview,
    isCreating,
    hasCreateError,
    errors,
  });

  return { fragmentData, fragment, onAddReview };
};

export { useProductAddReview };
