import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { ContentShimmer } from '../../../components';
import { useElemasonAnalyticsContext } from '../../../contexts';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { usePageFragment } from '../../../hooks';
import { useCollections } from '../../../hooks/network';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { useImpressionLedger } from '../../../hooks/useImpressionLedger';
import { useCollectionMap } from '../../../utils/collectionList';

import type { Collection, ImpressionLedgerEntry } from '@tectonic/types';
import type { FC } from 'react';
import type { CollectionListWidgetProps } from './CollectionList.types';

const CollectionListWidget: FC<CollectionListWidgetProps> = ({ widget }) => {
  const wData = widget.data!;
  const { config: wConfig } = widget;
  const analyticsContext = useElemasonAnalyticsContext();

  const [cStyle, cClassName] = useStyleConfigV2(wConfig?.list);
  const [iStyle, iClassName] = useStyleConfigV2(wConfig?.item);

  const {
    collections,
    isFetching,
    isFetchingNextPage,
    hasMore,
    onLoadMore,
    perPage,
    isError,
  } = useCollections(wData.source);
  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);

  const isLoading = isFetching || isFetchingNextPage;

  const loadMoreHandleInViewChange = useCallback(
    (inView: boolean) => inView && hasMore && onLoadMore(),
    [hasMore, onLoadMore]
  );

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.COLLECTION_IMPRESSION,
    analyticsContext
  );

  const collectionMap = useCollectionMap(collections ?? [], collections.length);

  const onCollectionInViewChange = useCallback(
    (viewCollection: Collection, inView: boolean) => {
      const entry = {
        ...collectionMap.get(`${viewCollection.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewCollection.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, collectionMap]
  );

  const [inViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: loadMoreHandleInViewChange,
  });

  return (
    <ul style={cStyle} className={clsx('flex overflow-auto', cClassName)}>
      {collections.map((collection, index) => (
        <li
          key={collection.id}
          style={iStyle}
          className={clsx('flex', iClassName)}
        >
          <ElemasonFragmentProvider
            value={fragmentValue({
              collection,
              index,
              perPage,
              page: Math.ceil((index + 1) / perPage),
              onCollectionInViewChange,
            })}
          >
            {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
          </ElemasonFragmentProvider>
        </li>
      ))}
      {((hasMore && isLoading) ||
        (collections.length === 0 && (isLoading || isError))) &&
        [1, 2, 3].map((n, index) => (
          <li
            style={iStyle}
            className={clsx('flex', iClassName)}
            key={`${n}_${index}`}
          >
            <ContentShimmer>
              <ElemasonFragmentProvider
                value={fragmentValue({ collection: {}, index })}
              >
                {fragment?.cells.map((cell) => (
                  <Cell key={cell.id} cell={cell} />
                ))}
              </ElemasonFragmentProvider>
            </ContentShimmer>
          </li>
        ))}
      {hasMore && !isLoading && <li ref={inViewRef} className="flex" />}
    </ul>
  );
};

CollectionListWidget.displayName = 'CollectionListWidget';

export { CollectionListWidget };
