import { forwardRef, useId } from 'react';
import { textAreaTv } from './variant';

import type { ForwardRefRenderFunction } from 'react';
import type { TextAreaProps } from './TextArea.types';

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  { variant, size, rounded, disabled, className, _label, ...props },
  ref
) => {
  const { base, textArea } = textAreaTv({
    className,
    disabled,
    rounded,
    size,
    variant,
  });
  const htmlFor = useId();

  return (
    <label
      htmlFor={htmlFor}
      {..._label}
      className={base({ className: _label?.className })}
    >
      <textarea ref={ref} {...props} className={textArea({ className })} />
    </label>
  );
};
const ExoticTextArea = forwardRef(TextArea);

export { ExoticTextArea as TextArea };
