import { ImageAsset } from '../../components';

import type { ImageTickProps } from './Tick.types';
import type { FC } from 'react';

const ImageTick: FC<ImageTickProps> = ({
  tick,
  onClick,
  style,
  className,
  config,
}) => (
  <ImageAsset
    config={config}
    onClick={onClick}
    asset={tick.asset}
    className={className}
    style={{ ...style, objectFit: config?.resizeMode }}
  />
);

export { ImageTick };
