import { Logger } from '@tectonic/logger';
import { isNil } from 'lodash-es';
import { trackEvent } from './event';
import { toProductImpressionPayload } from './product';

import type {
  AnalyticsCartEventPayload,
  AnalyticsProductLite,
  AnalyticsProductVariantLite,
} from '../types';

const getCart = (cart: AnalyticsCartEventPayload['cart']) => {
  if (!cart) {
    return {};
  }

  return {
    cartId: cart.id,
    valueBeforePromotions: cart.cost.subtotalAmount.amount,
    value: cart.cost.totalAmount.amount,
    promotions: cart.discountCodes?.map(({ code }) => code),
    coins: cart.coins?.spendable.currentlyApplicable.coins,
    shipping: null,
    tax: cart.cost.totalTaxAmount,
    currency: cart.cost.totalAmount.currencyCode,
    items: cart.lines.edges.map((edge, index) => {
      const { productVariant, discountAllocations, quantity, cost } = edge.node;
      const { product } = productVariant;
      const productPayload = toProductImpressionPayload({
        product,
        variant: productVariant,
        index,
      });
      return {
        ...productPayload,
        quantity,
        valueBeforePromotions: cost.subtotalAmount.amount,
        promotions: discountAllocations.map(({ code, title }) => code ?? title),
        value: cost.totalAmount.amount,
        sellingPrice: cost.amountPerQuantity,
        mrp: cost.compareAtAmountPerQuantity.amount,
        discount: discountAllocations.reduce(
          (acc, { discountedAmount }) => acc + discountedAmount.amount,
          productPayload.discount ?? 0
        ),
      };
    }),
  };
};

const getAnalyticsPayloadForCart = (params: AnalyticsCartEventPayload) => {
  const { cart, ...rest } = params;
  const payload = { ...getCart(cart), ...rest };
  return payload;
};

const getItemPayloadFromCartProduct = (
  product?: AnalyticsProductLite,
  variant?: AnalyticsProductVariantLite,
  quantity?: number
) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchParams,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    index,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page,
    ...rest
  } = toProductImpressionPayload({ product: product!, variant });
  return isNil(quantity) ? { ...rest } : { ...rest, quantity };
};

const trackCartEvent = async (
  event: string,
  params: AnalyticsCartEventPayload,
  immediate?: boolean
) => {
  try {
    const payload = getAnalyticsPayloadForCart(params);
    trackEvent(event, payload, { send_immediately: immediate });
  } catch (error) {
    Logger.error('[trackCartEvent]: Unable to track cart event', error);
  }
};

// in case of add to cart or remove from cart, we need to send particular product
// as items instead of all the items.
const trackCartProductEvent = (
  event: string,
  params: AnalyticsCartEventPayload
) => {
  try {
    const { product, variant, quantity, ...rest } = params;
    const payload = {
      ...getAnalyticsPayloadForCart(rest),
      items: [getItemPayloadFromCartProduct(product!, variant!, quantity)],
    };
    trackEvent(event, payload);
  } catch (error) {
    Logger.error('[trackCartEvent]: Unable to track cart event', error);
  }
};

export { trackCartEvent, trackCartProductEvent };
