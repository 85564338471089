import { Root, Thumb } from '@radix-ui/react-switch';
import { tv } from 'tailwind-variants';

import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import type { VariantProps } from 'tailwind-variants';

const switchTailwind = tv({
  slots: {
    thumb: 'switch-thumb',
    base: 'switch relative',
  },
  variants: {
    disabled: {
      true: 'cursor-default opacity-50',
    },
    checked: {
      true: 'switch-checked',
    },
  },
  compoundSlots: [
    {
      checked: true,
      slots: ['thumb'],
      className: 'switch-thumb-checked',
    },
  ],
});

interface Props
  extends Omit<ComponentProps<typeof Root>, 'children' | 'color' | 'label'>,
    VariantProps<typeof switchTailwind> {
  label?: string | undefined;
}

const Switch: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { disabled, checked, ...props },
  ref
) => {
  const { base, thumb } = switchTailwind({ disabled, checked });

  return (
    <Root {...props} className={base()}>
      <Thumb className={thumb()} />
    </Root>
  );
};

export { Switch };
