import { Switch } from '@tectonic/uikit';
import { useActionDispatch, useHaloScript } from '../../hooks';

import type { ElemasonSwitchWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonSwitchWidgetProps {
  widget: ElemasonSwitchWidget;
}

const SwitchWidget: FC<ElemasonSwitchWidgetProps> = ({
  widget: { data, actions },
}) => {
  const dispatch = useActionDispatch();
  const title = useHaloScript(data?.title);
  const checked = useHaloScript(data?.checked);

  const onCheckedChange = (isChecked: boolean) => {
    actions?.onCheckedChange?.forEach((action) =>
      dispatch(action, { isChecked })
    );
  };

  return (
    <Switch title={title} checked={checked} onCheckedChange={onCheckedChange} />
  );
};

export { SwitchWidget };
