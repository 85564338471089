import { useMutation, useQueryClient } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { Button } from '../../components';
import { useToast } from '../../core';
import { useHaloScript } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type { ElemasonSwapFromCartWidget } from '@tectonic/types';
import type { FC, MouseEventHandler } from 'react';

type SwapFromCartWidgetProps = {
  widget: ElemasonSwapFromCartWidget;
};

const SwapFromCartWidget: FC<SwapFromCartWidgetProps> = ({
  widget: { data, config },
}) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const cartLine = useHaloScript(data?.cartLine);
  const productVariant = useHaloScript(data?.productVariant);

  const swapMutation = useMutation({
    mutationFn: () =>
      remixApi.updateCartLines([
        {
          id: cartLine!.id,
          quantity: cartLine!.quantity,
          variantId: productVariant!.id,
          userProfileData: cartLine!.userProfileData,
        },
      ]),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onError: (error) => {
      const title = getErrorMessage(
        error,
        {},
        'Failed to remove product from cart'
      );
      showToast({ title, duration: 5000 });
    },
  });

  const onDelete: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    swapMutation.mutate();
  };

  if (!cartLine || !productVariant) return null;

  return (
    <Button
      data={data}
      onClick={onDelete}
      config={config?.button}
      size={config?.button?.size}
      variant={config?.button?.variant}
      disabled={swapMutation.isPending}
      modifier={config?.button?.modifier}
      isLoading={swapMutation.isPending}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

export { SwapFromCartWidget };
