import {
  AnalyticsDrawerEventNames,
  trackElemasonAction,
  useAnalyticsStore,
} from '@tectonic/analytics';
import { Logger } from '@tectonic/logger';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import copy from 'copy-to-clipboard';
import invariant from 'invariant';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from 'react';
import { ElemasonPageProvider, useElemasonContext } from '../../contexts';
import { Page } from '../Page';
import { Drawers, useLoginDrawer } from './Drawers';
import { useDrawers } from './Drawers/store';
import { Footer } from './Footer';
import { Header } from './Header';
import { Toast, useToast } from './Toast';
import { useNavbars } from './hooks/useNavbars';

import type {
  ElemasonConfig,
  ElemasonFragment,
  ElemasonPage,
  ElemasonRoot,
  ElemasonWidgetAction,
} from '@tectonic/types';
import type { ElemasonAnalyticsContextType } from '../../contexts';

interface ElemasonEntryProps {
  data: any;
  root?: ElemasonRoot;
  page?: ElemasonPage;
  config: ElemasonConfig;
}

const addOriginIfMissing = (url: string) => {
  try {
    // Try to construct a URL object from the input
    const urlObj = new URL(url);
    // If the URL object is successfully created, the origin is already present
    return url;
  } catch (e) {
    const og = window.location.origin;
    // If an error is thrown, it means the URL is relative
    return `${og}${url.startsWith('/') ? url : `/${url}`}`;
  }
};

// TODO: add root error boundary
// TODO: break this down, move dispatch outside
const ElemasonEntry: FC<ElemasonEntryProps> = ({
  root,
  data: pageData,
  page,
  config,
}) => {
  invariant(root, 'Root is required');
  invariant(page, 'Page is required');

  const pushRef = useRef(false);
  const { showToast } = useToast();
  const { open: openLoginDrawer } = useLoginDrawer();
  const { header, footer } = useNavbars(root, page!);
  const {
    open: openDrawer,
    close: closeDrawer,
    closeAll: closeAllDrawers,
  } = useDrawers();
  const {
    currentUser,
    searchParams,
    routeParams,
    navigationRegistry,
    navigate,
  } = useElemasonContext();

  const { pushPage } = useAnalyticsStore((state) => ({
    pushPage: state.pushPage,
  }));

  // global scope
  const [scope, setScope] = useState<Record<string, any>>({});

  const [isReady, setIsReady] = useState<boolean>(false);

  const data = useMemo(
    () => ({
      ...pageData,
      currentUser,
      pageSlug: page.slug,
      params: {
        ...searchParams,
        ...routeParams,
        __tt__internal_params: { routeParams, searchParams },
      },
    }),
    [pageData, currentUser, searchParams, routeParams, page]
  );

  const dispatch = useCallback(
    (
      action: ElemasonWidgetAction,
      analyticsContext?: ElemasonAnalyticsContextType
    ) => {
      switch (action.type) {
        case ElemasonWidgetActionType.ANALYTICS:
          trackElemasonAction({
            ...action.payload,
            analyticsContext: analyticsContext!,
          });
          break;
        case ElemasonWidgetActionType.COPY_TO_CLIPBOARD:
          copy(action.payload);
          break;
        case ElemasonWidgetActionType.TOAST:
          showToast(action.payload);
          break;
        case ElemasonWidgetActionType.NAVIGATOR_SHARE: {
          const payload = {
            text: action.payload.text,
            title: action.payload.title,
            description: action.payload.description,
            url: navigationRegistry.getHref({
              type: NavigationActionType.PATH,
              ...action.payload.url,
            }),
          };

          try {
            navigator.share(payload);
          } catch (error) {
            Logger.error(`Couldn't share product.`, error, payload);
          }
          break;
        }
        case ElemasonWidgetActionType.NAVIGATE_TO: {
          const url = navigationRegistry.getHref(action.payload);
          if (action.payload.mode === 'hard') {
            window.location.href = url;
          } else {
            navigate(url);
          }
          break;
        }
        case ElemasonWidgetActionType.NAVIGATE_BACK:
          window.history.back();
          break;
        case ElemasonWidgetActionType.MAIL_TO:
          window.location.href = `mailto:${action.payload}`;
          break;
        case ElemasonWidgetActionType.TELEPHONE:
          window.location.href = `tel:${action.payload}`;
          break;
        case ElemasonWidgetActionType.LINK_OPEN: {
          const url = addOriginIfMissing(action.payload.url);
          window.open(url, action.payload.target);
          break;
        }
        case ElemasonWidgetActionType.RELOAD: {
          window.location.reload();
          break;
        }
        case ElemasonWidgetActionType.SCROLL_INTO_VIEW: {
          const { delay, ...rest } = action.payload.options ?? {};
          if (delay) {
            setTimeout(() => {
              document.getElementById(action.payload.id!)?.scrollIntoView(rest);
            }, delay);
          } else {
            document.getElementById(action.payload.id!)?.scrollIntoView(rest);
          }

          break;
        }
        case ElemasonWidgetActionType.SCROLL_TO:
          window.scrollTo(action.payload);
          break;
        case ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN:
          trackElemasonAction({
            event: AnalyticsDrawerEventNames.DRAWER_OPEN,
            data: {
              slug: action.payload.slug,
            },
            analyticsContext: analyticsContext!,
          });
          openDrawer(
            action.payload.slug,
            action.payload.data,
            action.payload.entry,
            action.payload.exit,
            action.payload.animationType
          );
          break;
        case ElemasonWidgetActionType.LOGIN_DRAWER_OPEN:
          trackElemasonAction({
            event: AnalyticsDrawerEventNames.DRAWER_OPEN,
            data: {
              slug: action.payload.slug,
            },
            analyticsContext: analyticsContext!,
          });
          openLoginDrawer(action.payload.slug);
          break;
        case ElemasonWidgetActionType.GLOBAL_DRAWER_CLOSE:
          trackElemasonAction({
            event: AnalyticsDrawerEventNames.DRAWER_CLOSE,
            data: {
              slug: action.payload.slug,
            },
            analyticsContext: analyticsContext!,
          });
          closeDrawer(action.payload.slug);
          break;
        case ElemasonWidgetActionType.LOGOUT:
          Logger.warn('Not implemented');
          break;
        case ElemasonWidgetActionType.UPDATE_LOCAL_STATE:
          setScope((localState) => ({
            ...localState,
            [action.payload.key]: action.payload.value,
          }));
          break;
        default:
          Logger.error(`Invalid action ${JSON.stringify(action)}`);
      }
    },
    [
      setScope,
      showToast,
      closeDrawer,
      closeAllDrawers,
      openLoginDrawer,
      openDrawer,
      navigate,
      navigationRegistry,
    ]
  );

  const [drawerFragments, setDrawerFragments] = useState<
    Record<string, ElemasonFragment[]>
  >({});

  const addDrawerFragments = useCallback(
    (slug: string, nFragments: ElemasonFragment[]) => {
      setDrawerFragments((fragments) => ({ ...fragments, [slug]: nFragments }));
    },
    [setDrawerFragments]
  );
  const removeDrawerFragments = useCallback(
    (slug: string) => {
      setDrawerFragments(({ [slug]: _, ...fragments }) => fragments);
    },
    [setDrawerFragments]
  );

  useEffect(() => {
    if (page && !pushRef.current) {
      pushPage({
        pageId: page.id,
        pageSlug: page.slug,
        screenName: page.info?.title,
      });

      pushRef.current = true;
    }

    setIsReady(true);
  }, [page, pushRef, pushPage, setIsReady]);

  const pageProviderValue = useMemo(
    () => ({
      data,
      scope,
      config,
      setScope,
      dispatch,
      addDrawerFragments,
      removeDrawerFragments,
      isReady,
      rootFragments: root?.fragments,
      pageFragments: page?.fragments,
      drawerFragments: Object.values(drawerFragments).flat(),
    }),
    [
      data,
      scope,
      config,
      setScope,
      dispatch,
      addDrawerFragments,
      removeDrawerFragments,
      isReady,
      root,
      page,
    ]
  );

  useEffect(() => {
    closeAllDrawers();
  }, [page]);

  return (
    <ElemasonPageProvider value={pageProviderValue}>
      {header && <Header header={header} />}
      {page && <Page page={page} />}
      {footer && <Footer footer={footer} />}
      <Drawers />
      <Toast />
    </ElemasonPageProvider>
  );
};

ElemasonEntry.displayName = 'ElemasonEntry';

export { ElemasonEntry };
