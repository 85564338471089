import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { isBlank } from '@tectonic/utils';
import clsx from 'clsx';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { usePageFragment } from '../../../hooks';
import { useAccessorValue } from '../../../hooks/useAccessorValue';
import { useFragmentValue } from '../../../hooks/useFragmentValue';

import type { FC } from 'react';
import type { SearchAutoSuggestContentWidgetProps } from './SearchAutoSuggestContent.types';

const SearchAutoSuggestContentWidget: FC<
  SearchAutoSuggestContentWidgetProps
> = ({ widget }) => {
  const data = widget.data!;
  const wContent = data.content;
  const config = widget.config!;
  const searchText = useAccessorValue<string>(data.searchText);
  const hasQuery = !isBlank(searchText);
  const fragment = usePageFragment(
    hasQuery ? wContent.query : wContent.default
  );
  const fragmentValue = useFragmentValue(fragment);

  const [style, className] = useStyleConfigV2(config?.container ?? {});

  return fragment ? (
    <div
      className={clsx('max-w-screen flex flex-col', className)}
      style={style}
    >
      <ElemasonFragmentProvider value={fragmentValue()}>
        {fragment?.cells.map((cell, index) => (
          <Cell key={cell.id ?? index} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </div>
  ) : null;
};

SearchAutoSuggestContentWidget.displayName =
  'ElemasonSearchAutoSuggestContentWidget';

export { SearchAutoSuggestContentWidget };
