import { useRef } from 'react';
import { OtpConfirmationForm } from '../../../components';
import { useAuthOtpConfirmationForm } from './hooks';

import type { FC } from 'react';
import type { OtpConfirmationFormRef } from '../../../components';
import type { AuthOtpConfirmationFormWidgetProps } from './AuthOtpConfirmationForm.types';

const AuthOtpConfirmationFormWidget: FC<AuthOtpConfirmationFormWidgetProps> = ({
  widget,
}) => {
  const formRef = useRef<OtpConfirmationFormRef>(null);

  const { isLoading, onResendOtp, onSubmit } = useAuthOtpConfirmationForm(
    widget,
    formRef
  );

  return (
    <OtpConfirmationForm
      ref={formRef}
      data={widget.data!}
      isSubmitting={isLoading}
      onResend={onResendOtp}
      onSubmit={onSubmit}
      config={widget.config}
    />
  );
};

AuthOtpConfirmationFormWidget.displayName = 'AuthOtpConfirmationFormWidget';

export { AuthOtpConfirmationFormWidget };
