import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonComponentType } from '@tectonic/types';
import clsx from 'clsx';
import { isEmpty, take } from 'lodash-es';
import { useElemasonComponentConfig } from '../../../hooks/useElemasonConfig';
import { isVisible } from '../../../utils';
import { Icon } from '../../Icon';

import type {
  ProductBadge,
  ProductCardBadgesConfig,
  TextFontFamilyConfig,
} from '@tectonic/types';
import type { FC } from 'react';

interface ProductBadgesProps {
  badges: ProductBadge[];
  config?: ProductCardBadgesConfig;
}

const ProductBadges: FC<ProductBadgesProps> = ({
  badges: productBadges,
  config: productBadgesConfig,
}) => {
  const config = useElemasonComponentConfig(
    ElemasonComponentType.ProductBadges,
    productBadgesConfig
  );

  const showBadges = isVisible(config ?? {}) && !isEmpty(productBadges);

  const { style: badgesStyle, className: badgesClassName } = useStyleConfig({
    ...(config?.container ?? {}),
  });

  const {
    style: { backgroundImage, ...badgesButtonStyle },
    className: badgesButtonClassName,
  } = useStyleConfig(config?.config ?? {});

  const badges = config?.config?.badgesToShow
    ? take(productBadges, config?.config?.badgesToShow)
    : productBadges;

  if (!showBadges) return null;

  return (
    <div style={badgesStyle} className={clsx('flex flex-col', badgesClassName)}>
      {badges.map((badge) => (
        <div
          key={badge.name}
          className={clsx('flex flex-row', badgesButtonClassName)}
          style={{
            ...badgesButtonStyle,
            color: badge.textColor,
            backgroundImage: badge.backgroundColor ? 'none' : backgroundImage,
            backgroundColor: badge.backgroundColor
              ? badge.backgroundColor
              : 'initial',
          }}
        >
          {badge.startIcon && (
            <Icon
              symbol={badge.startIcon}
              config={config.icon}
              className="mr-1"
            />
          )}
          <Text
            data={badge.name}
            config={{
              ...config.config,
              fontFamily:
                (badge.font as TextFontFamilyConfig) ??
                config.config?.fontFamily,
            }}
          />
          {badge.endIcon && (
            <Icon symbol={badge.endIcon} config={config.icon} />
          )}
        </div>
      ))}
    </div>
  );
};

export { ProductBadges };
