import {
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import clsx from 'clsx';
import { omit } from 'lodash-es';
import { isVisible } from '../../../utils';
import { ContentShimmer } from '../../Shimmer';

import type { FC } from 'react';
import type { SearchHistorySkeletonProps } from './SearchHistory.types';

const SearchHistorySkeleton: FC<SearchHistorySkeletonProps> = ({ config }) => {
  const { header, container } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  const headerStyleConfig = useStyleConfig(header?.container ?? {});
  const [style, className] = useStyleConfigV2(
    config?.queryList?.container ?? {}
  );

  const [queryContainerStyle, queryContainerClassName] = useStyleConfigV2(
    omit(
      config?.query?.container,
      'border',
      'borderColor',
      'borderRadius',
      'padding',
      'paddingX',
      'paddingY'
    ) ?? {}
  );

  const isHeaderVisible = isVisible(header);

  return (
    <div {...containerStyleConfig}>
      {isHeaderVisible ? (
        <div {...headerStyleConfig}>
          <ContentShimmer>Loading....</ContentShimmer>
        </div>
      ) : null}
      <div
        style={style}
        className={clsx(
          'flex',
          config?.queryList?.container?.direction === 'horizontal'
            ? 'flex-row'
            : 'flex-col',
          className
        )}
      >
        {Array.from({ length: 4 }, (_item, index) => (
          <div
            key={index}
            style={queryContainerStyle}
            className={clsx('flex', queryContainerClassName)}
          >
            <ContentShimmer key={index}>Loading...</ContentShimmer>
          </div>
        ))}
      </div>
    </div>
  );
};

SearchHistorySkeleton.displayName = 'SearchHistorySkeleton';

export { SearchHistorySkeleton };
