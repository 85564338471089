import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import {
  createAddress,
  getAddresses,
  getCountries,
  getCountryDetails,
  updateAddress,
} from '@tectonic/remix-client-network';
import {
  LocalStateKeys,
  type Address,
  type AddressCreatePayload,
  type AddressUpdatePayload,
  type Country,
  type CountryDetails,
  type CountryProvince,
  type ElemasonAddressFormWidget,
} from '@tectonic/types';
import { isEmpty } from 'lodash-es';
import { useDeferredValue, useEffect, useState } from 'react';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useActionDispatch, useSharedLocalState } from '../../../hooks';
import { useAccessorValue } from '../../../hooks/useAccessorValue';
import { queryKeys } from '../../../queryKeys';
import { toPhoneText } from '../../../utils';

import type { AddressFormValue } from '../../../components';

const toAddressPayload = (value: AddressFormValue): AddressCreatePayload => {
  const { country, province } = value;
  return {
    ...value,
    country: country.name,
    province: province?.name ?? '',
    phone: toPhoneText(value.phoneNumber) ?? '',
  };
};

const addressesQueryFn = async (): Promise<Address[]> => {
  const response = await getAddresses();
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data.edges.map(({ node }) => node);
};

const countriesQueryFn = async (): Promise<Country[]> => {
  const response = await getCountries();
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data!;
};

const countryDetailsQueryFn = async (code: string): Promise<CountryDetails> => {
  const response = await getCountryDetails(code);
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data!;
};

const addressCreateMutationFn = async (
  payload: AddressCreatePayload
): Promise<Address> => {
  const response = await createAddress(payload);
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data;
};

const addressUpdateMutationFn = async (payload: AddressUpdatePayload) => {
  const response = await updateAddress(payload);
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data;
};

const useAddressForm = (widget: ElemasonAddressFormWidget) => {
  const wData = widget.data!;
  const dispatch = useActionDispatch();
  const addressId = useAccessorValue(wData.addressId ?? {});
  const [countryCode, setCountryCode] = useState<string>('');
  const deferredCode = useDeferredValue(countryCode);
  const queryClient = useQueryClient();
  const { setSharedState } = useSharedLocalState(
    LocalStateKeys.SELECTED_ADDRESS_ID,
    addressId
  );

  const { showToast } = useToast();

  const {
    isLoading: isCountriesLoading,
    isError: hasLoadError,
    data: countries,
  } = useQuery({ queryKey: queryKeys.countries(), queryFn: countriesQueryFn });

  const { data: address, isLoading: isAddressLoading } = useQuery({
    queryKey: queryKeys.addresses(),
    enabled: !!addressId,
    queryFn: addressesQueryFn,
    select: (data) => data.find((a) => a.id === addressId),
  });

  const { data: countryDetails } = useQuery({
    queryKey: queryKeys.countryDetails(deferredCode),
    queryFn: () => countryDetailsQueryFn(deferredCode),
    enabled: !!deferredCode,
  });

  const { mutateAsync: handleCreateAddress, isPending: isCreatingAddress } =
    useMutation({
      mutationFn: addressCreateMutationFn,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.addresses() });
      },
      mutationKey: queryKeys.addresses(),
    });

  const { mutateAsync: handleUpdateAddress, isPending: isUpdatingAddress } =
    useMutation({
      mutationFn: addressUpdateMutationFn,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.addresses() });
      },
    });

  const onSubmit = async (value: AddressFormValue) => {
    const payload = toAddressPayload(value);
    try {
      const isExistingAddress = !!address?.id;
      if (isExistingAddress) {
        await handleUpdateAddress({ ...payload, addressId: address.id });
      } else {
        await handleCreateAddress(payload);
      }
      showToast({ title: wData.messages.saveSuccess });
      widget.actions?.onSubmit.forEach((action) => dispatch(action));
    } catch (error) {
      Logger.error('useAddressForm:onSubmit', error);
      showToast({ title: getErrorMessage(error, wData.errorMessages) });
    }
  };

  const onChange = (value: Partial<AddressFormValue>) => {
    const cCode = countries!.find((c) => c.name === value?.country?.name);
    setCountryCode(cCode?.code ?? '');
  };

  const provinces: CountryProvince[] = countryDetails?.provinces ?? [];
  const isSubmitting = isUpdatingAddress || isCreatingAddress;
  const isLoading = isAddressLoading || isCountriesLoading;

  useEffect(() => () => setSharedState(null), [setSharedState]);

  return {
    isSubmitting,
    isLoading,
    hasLoadError,
    onSubmit,
    address,
    countries,
    provinces,
    onChange,
  };
};

export { useAddressForm };
