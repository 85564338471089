import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { isVisible } from '../../../utils';
import { ProductListItemElementType } from './ProductList.types';
import { ProductListItem } from './ProductListItem';

import type {
  ElemasonProductListWidget,
  ElemasonProductListWidgetSourceCardData,
  Product,
  ProductListProductCardHandle,
  ProductVariant,
} from '@tectonic/types';
import type { ForwardRefRenderFunction } from 'react';
import type { ProductListItemType } from './ProductList.types';

type ProductListProps = {
  count?: number;
  isError: boolean;
  isLoading: boolean;
  products?: Product[];
  onSourceClick: () => void;
  onProductClick: (product: Product, index: number) => void;
  config?: ElemasonProductListWidget['config'];
  selectedVariants?: Record<string, ProductVariant>;
  onVariantSelect?: (product: Product, variant: ProductVariant) => void;
  onProductInViewChange?: (product: Product, inView: boolean) => void;
  data?: {
    productFragment?: string;
    sourceCardFragment?: string;
    sourceCard?: ElemasonProductListWidgetSourceCardData;
  };
};

interface ProductListHandle {
  productCardRefs: Record<string, ProductListProductCardHandle>;
}

const ProductList: ForwardRefRenderFunction<
  ProductListHandle,
  ProductListProps
> = (
  {
    count,
    products,
    data,
    config,
    isError,
    isLoading,
    onSourceClick,
    onProductClick,
    onVariantSelect,
    selectedVariants,
    onProductInViewChange,
  },
  ref
) => {
  const itemsRef = useRef<(ProductListProductCardHandle | null)[]>([]);
  const { style, className } = useStyleConfig(config?.list ?? {});
  const showSourceCard =
    (count ?? 0) > (products?.length ?? 0) &&
    isVisible(config?.sourceCard ?? {});

  const listItems = useMemo(() => {
    const items: ProductListItemType[] = (products ?? [])?.map((product) => ({
      type: ProductListItemElementType.Product,
      data: product,
    }));

    if (isError) {
      return [
        {
          type: ProductListItemElementType.Skeleton,
          data: null,
        },
      ];
    }

    if (isLoading) {
      const loaders = Array.from({ length: 4 }, () => ({
        type: ProductListItemElementType.Skeleton,
        data: null,
      }));

      items.push(...loaders);
    }

    if (showSourceCard) {
      items.push({
        type: ProductListItemElementType.Source,
        data: null,
      });
    }

    return items;
  }, [products, isLoading, isError]);

  useImperativeHandle(ref, () => ({
    productCardRefs: itemsRef.current.reduce(
      (acc, itemRef) => {
        if (itemRef?.productSlug) {
          acc[itemRef?.productSlug] = itemRef;
        }

        return acc;
      },
      {} as Record<string, ProductListProductCardHandle>
    ),
  }));

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, listItems.length);
  }, [listItems]);

  if (listItems.length === 0) {
    return null;
  }

  return (
    <div
      style={style}
      className={clsx(
        className,
        'w-full flex-row overflow-auto',
        config?.list?.mode === 'grid'
          ? `grid grid-cols-${config?.list?.cols ?? 2}`
          : 'flex'
      )}
    >
      {listItems.map((item, index) => (
        <ProductListItem
          item={item}
          data={data}
          count={count}
          index={index}
          config={config}
          key={item.data?.id ?? index}
          onSourceClick={onSourceClick}
          onProductClick={(product: Product) => onProductClick(product, index)}
          onVariantSelect={onVariantSelect}
          selectedVariants={selectedVariants}
          ref={(el) => {
            itemsRef.current[index] = el;
          }}
          onProductInViewChange={onProductInViewChange}
        />
      ))}
    </div>
  );
};

const ExoticProductList = forwardRef(ProductList);

export { ExoticProductList as ProductList };
