import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { memo, useCallback, type FC } from 'react';
import { useElemasonAnalyticsContext } from '../../contexts';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useHaloScriptEvaluator } from '../../hooks/useHaloScript';
import { useImpressionLedger } from '../../hooks/useImpressionLedger';
import { useRepeaterMap } from '../../utils/repeaterItemList';
import { useRepeater } from './useRepeater';

import type {
  ElemasonFragment,
  ElemasonRepeaterWidget,
  ImpressionLedgerEntry,
} from '@tectonic/types';

interface RepeaterItemProps {
  index: number;
  list: unknown[];
  fragment: ElemasonFragment;
  onRepeaterInViewChange: (
    index: number,
    repeater: any,
    inView: boolean
  ) => void;
}

interface ElemasonRepeaterWidgetProps {
  widget: ElemasonRepeaterWidget;
}

const RepeaterItem: FC<RepeaterItemProps> = ({
  fragment,
  index,
  list,
  onRepeaterInViewChange,
}) => {
  const fragmentValue = useFragmentValue(fragment);

  return (
    <ElemasonFragmentProvider
      value={fragmentValue({
        repeaterList: list,
        repeaterIndex: index,
        repeaterItem: list[index],
        onRepeaterInViewChange: (inView: boolean) =>
          onRepeaterInViewChange(index, list[index], inView),
      })}
    >
      {fragment.cells.map((cell) => (
        <Cell key={cell.id} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  );
};

const RepeaterWidget: FC<ElemasonRepeaterWidgetProps> = ({ widget }) => {
  const evaluator = useHaloScriptEvaluator();
  const { list, fragment } = useRepeater(widget);
  const analyticsContext = useElemasonAnalyticsContext();

  const [style, className] = useStyleConfigV2(widget.config?.container);
  const [itemContainerStyle, itemContainerClassName] = useStyleConfigV2(
    widget.config?.itemContainer
  );

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.REPEATER_ITEM_IMPRESSION,
    analyticsContext
  );

  const repeaterMap = useRepeaterMap(list, list.length);

  const onRepeaterInViewChange = useCallback(
    (index: number, viewRepeater: any, inView: boolean) => {
      const entry = {
        ...repeaterMap.get(`${index}`),
        inView,
      };
      impressionLedger.setEntry(`${index}`, entry as ImpressionLedgerEntry);
    },
    [impressionLedger, repeaterMap]
  );

  if (!fragment) return null;

  return (
    <div
      style={style}
      className={clsx(
        'flex',
        widget.config?.direction === 'horizontal' ? 'flex-row' : 'flex-col',
        className
      )}
    >
      {list.map((_item, index) => (
        <div
          key={index}
          style={{
            ...itemContainerStyle,
            ...(widget.config?.dynamicItemContainer
              ? evaluator(widget.config.dynamicItemContainer, { index })
              : {}),
          }}
          className={clsx(
            'flex',
            widget.config?.itemContainer?.direction === 'horizontal'
              ? 'flex-row'
              : 'flex-col',
            itemContainerClassName
          )}
        >
          <RepeaterItem
            key={index}
            list={list}
            index={index}
            fragment={fragment}
            onRepeaterInViewChange={onRepeaterInViewChange}
          />
        </div>
      ))}
    </div>
  );
};

RepeaterWidget.displayName = 'RepeaterWidget';

const MemoisedRepeaterWidget = memo(RepeaterWidget);

export { MemoisedRepeaterWidget as RepeaterWidget };
