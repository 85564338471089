import { toTailwindUtility } from '@tectonic/utils';
import { compact } from 'lodash-es';

import type { PaddingConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const usePaddingConfig = (config: PaddingConfig) => {
  const {
    padding,
    paddingTop,
    paddingEnd,
    paddingBottom,
    paddingStart,
    paddingX,
    paddingY,
  } = config;

  const paddingStyles: CSSProperties = {};

  const paddingClasses = [
    // toTailwindUtility('p', padding),
    // toTailwindUtility('pt', paddingTop),
    // toTailwindUtility('pb', paddingBottom),
    // toTailwindUtility('pl', paddingStart),
    // toTailwindUtility('pr', paddingEnd),
    // toTailwindUtility('px', paddingX),
    // toTailwindUtility('py', paddingY),
  ];

  if (typeof padding === 'number') {
    paddingStyles.padding = padding;
  } else {
    paddingClasses.push(toTailwindUtility('p', padding));
  }

  if (typeof paddingTop === 'number') {
    paddingStyles.paddingTop = paddingTop;
  } else {
    paddingClasses.push(toTailwindUtility('pt', paddingTop));
  }

  if (typeof paddingBottom === 'number') {
    paddingStyles.paddingBottom = paddingBottom;
  } else {
    paddingClasses.push(toTailwindUtility('pb', paddingBottom));
  }

  if (typeof paddingStart === 'number') {
    paddingStyles.paddingLeft = paddingStart;
  } else {
    paddingClasses.push(toTailwindUtility('pl', paddingStart));
  }

  if (typeof paddingEnd === 'number') {
    paddingStyles.paddingRight = paddingEnd;
  } else {
    paddingClasses.push(toTailwindUtility('pr', paddingEnd));
  }

  if (typeof paddingX === 'number') {
    paddingStyles.paddingLeft = paddingX;
    paddingStyles.paddingRight = paddingX;
  } else {
    paddingClasses.push(toTailwindUtility('px', paddingX));
  }

  if (typeof paddingY === 'number') {
    paddingStyles.paddingTop = paddingY;
    paddingStyles.paddingBottom = paddingY;
  } else {
    paddingClasses.push(toTailwindUtility('py', paddingY));
  }

  return [paddingStyles, compact(paddingClasses)];
};

export { usePaddingConfig };
