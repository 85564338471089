import { useMutation, useQueryClient } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfig } from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import { script } from '@tectonic/halo-script';
import { LocalStateKeys } from '@tectonic/types';
import { access } from '@tectonic/utils';
import clsx from 'clsx';
import { CartProduct } from '../../components/Cart';
import { useToast } from '../../core/ElemasonEntry/Toast';
import { useHaloScript } from '../../hooks/useHaloScript';
import { queryKeys } from '../../queryKeys';

import type {
  Cart,
  CartLineProductVariant,
  CartUpdateLinePayload,
  ElemasonCartLinesWidget,
} from '@tectonic/types';
import type { FC } from 'react';

const STATE_KEY = LocalStateKeys.CART;
const SCRIPT_ACCESSOR = script([access(['local', STATE_KEY])]);

interface ElemasonCartLinesWidgetProps {
  widget: ElemasonCartLinesWidget;
}

const CartLinesWidget: FC<ElemasonCartLinesWidgetProps> = ({ widget }) => {
  const { config } = widget;
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const cart = useHaloScript<Cart>(SCRIPT_ACCESSOR);
  const queryCache = queryClient.getQueryCache();
  const cartQueryKeys = queryCache
    .getAll()
    .map((cache) => cache.queryKey)
    .filter((key) => key[0] === 'cart' && key.length === 1);

  const updateCartMutation = useMutation({
    mutationFn: (lines: CartUpdateLinePayload[]) => remixApi.updateCart(lines),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart: Cart) => {
      cartQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (_queryData: Cart) => updatedCart);
      });

      showToast({ title: 'Variant updated' });
    },
    onError: (e) => {
      const title = getErrorMessage(e, {}, 'Failed to update variant');
      showToast({ title });
    },
  });

  const { style, className } = useStyleConfig(config?.container ?? {});

  if (!cart || cart.lines.edges.length === 0) return null;

  const onVariantSelect = (
    index: number,
    data: {
      variant: CartLineProductVariant;
      userProfileData?: any;
    }
  ) => {
    const lines = cart.lines.edges.map((line, i) => ({
      ...line.node,
      id: line.node.id,
      quantity: line.node.quantity,
      userProfileData:
        line.node.productVariant.basisAttr1Value === 'Customize'
          ? data.userProfileData ?? null
          : null,
      variantId: index === i ? data.variant.id : line.node.productVariant.id,
      productVariant:
        index === i
          ? { ...data.variant, product: line.node.productVariant.product }
          : line.node.productVariant,
    }));

    cartQueryKeys.forEach((key) => {
      queryClient.setQueryData(key, (queryData: Cart) => ({
        ...queryData,
        lines: {
          edges: lines.map((node) => ({ node })),
        },
      }));
    });

    updateCartMutation.mutate(
      lines.map(({ id, quantity, variantId, userProfileData }) => ({
        id,
        quantity,
        variantId,
        userProfileData,
      }))
    );
  };

  return (
    <div
      style={style}
      className={clsx('flex h-full flex-col overflow-auto', className)}
    >
      {cart.lines.edges.map((line, index) => (
        <CartProduct
          line={line.node}
          id={line.node.id}
          key={line.node.id}
          isLoading={updateCartMutation.isPending}
          onVariantSelect={(data: {
            variant: CartLineProductVariant;
            userProfileData?: any;
          }) => onVariantSelect(index, data)}
          config={config?.card}
        />
      ))}
    </div>
  );
};

export { CartLinesWidget };
