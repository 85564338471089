import {
  setGlobalProperties,
  setUser,
  setUserAlias,
} from '@tectonic/analytics';

import type { ElemasonWidget, User } from '@tectonic/types';

// We have custom implementation of tracking. Therefore, we need to set, reset
// and alias user as per our backend implementation.
// 1. `ttUserId`
//    - We add `ttUserId` as current user id to identity a user for an event.
//    - It is always in sync with the user available in context.
// 2. `identify`
//    - We identify user only when the user signs in or signs up.
// 3. `aliases`
//    - Users are aliased when they login. We don't aliases a user when they sign
//      up cause the user id doesn't change.
const setupAnalyticsUser = (nUser: User, currentUser: User) => {
  // In case of sign up, id doesn't change. We need to alias the user only
  // in case of sign in.
  const prevId = currentUser.externalUserId;
  const userId = nUser.externalUserId;
  const { simpleId, isAnonymous } = nUser;
  const isSignIn = prevId !== userId;
  if (isSignIn) {
    // https://stackoverflow.com/a/69701902/2879146
    // oldId, newId
    setUserAlias(prevId, userId);
  }
  setUser(userId);
  setGlobalProperties({ ttEventSource: 'WEBSITE', ttUserId: userId, ttSourceUserId: simpleId, isAnonymous });
};

const enrichAnalyticsPayloadWithWidgetData = <T>(
  payload: T,
  widget: ElemasonWidget<unknown, unknown, unknown>
): T => {
  const { id: widgetId, version: widgetVersion, type: widgetType } = widget;
  return { ...payload, widgetId, widgetVersion, widgetType };
};

export { enrichAnalyticsPayloadWithWidgetData, setupAnalyticsUser };
