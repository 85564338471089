import { ElemasonWidgetType } from '@tectonic/types';
import { memo, type FC } from 'react';
import { ProductBadges } from '../../../components/Product/ProductBadges';
import { useElemasonWidgetConfig } from '../../../hooks/useElemasonConfig';
import { useHaloScript } from '../../../hooks/useHaloScript';

import type { ElemasonProductBadgesWidget } from '@tectonic/types';

interface ElemasonProductBadgesWidgetProps {
  widget: ElemasonProductBadgesWidget;
}

const ProductBadgesWidget: FC<ElemasonProductBadgesWidgetProps> = ({
  widget: { config: productBadgesConfig, data },
}) => {
  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductBadges,
    productBadgesConfig
  );

  const badges = useHaloScript(data?.badges);

  return <ProductBadges badges={badges ?? []} config={config} />;
};

const MemoizedProductBadgesWidget = memo(ProductBadgesWidget);

export { MemoizedProductBadgesWidget as ProductBadgesWidget };
