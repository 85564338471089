import { isVideo } from '@tectonic/utils';
import { memo, type ComponentProps, type FC } from 'react';
import { ImageAsset } from './Image';
import { VideoAsset } from './Video';

import type { Asset, ImageConfig, VideoConfig } from '@tectonic/types';

interface MediaAssetProps extends ComponentProps<any> {
  asset?: Asset;
  config?: ImageConfig | VideoConfig;
}

const MediaAsset: FC<MediaAssetProps> = ({ asset, config, ...otherProps }) => {
  const video = isVideo(asset?.mimeType ?? '');

  if (video) {
    return (
      <VideoAsset
        asset={asset}
        config={config as VideoConfig}
        {...otherProps}
      />
    );
  }

  return (
    <ImageAsset asset={asset} config={config as ImageConfig} {...otherProps} />
  );
};

const MemoizedMediaAsset = memo(MediaAsset);

export { MemoizedMediaAsset as MediaAsset };
