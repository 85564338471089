import axios from 'axios';

import type {
  CountryApiRouteLoaderResponse,
  CountryDetailsApiRouteLoaderResponse,
} from '@tectonic/types';

const getCountries = async (): Promise<CountryApiRouteLoaderResponse> => {
  const response = await axios.get(`/api/country`);
  return response.data;
};

const getCountryDetails = async (
  code: string
): Promise<CountryDetailsApiRouteLoaderResponse> => {
  const response = await axios.get(`/api/country/${code}`);
  return response.data;
};

export { getCountries, getCountryDetails };
