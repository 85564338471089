import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import {
  ElemasonWidgetActionType,
  type CartAddLinePayload,
  type ElemasonFragment,
  type ElemasonProductFBTWidget,
  type Product,
  type ProductVariant,
} from '@tectonic/types';
import {
  CheckboxIndicator,
  CheckboxRoot,
  Icon as UikitIcon,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { isEmpty, uniq } from 'lodash-es';
import { useMemo, useState } from 'react';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useActionDispatch, usePageFragment } from '../../../hooks';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { queryKeys } from '../../../queryKeys';
import { isOutOfStock } from '../../../utils';
import { Button } from '../../Button';

import type { FC } from 'react';

interface ProductFBTProps {
  products?: Product[];
  config: ElemasonProductFBTWidget['config'];
  wActions?: ElemasonProductFBTWidget['actions'];
  selectIcon?: string;
  unselectIcon?: string;
  rootProduct?: Product;
  fragment: ElemasonFragment;
  atcFragment: string;
  highlightText?: string;
  ctaText?: string;
  highlightProduct: boolean;
  isLoading: boolean;
  isError: boolean;
}

const ProductFBT: FC<ProductFBTProps> = ({
  products,
  config,
  selectIcon,
  unselectIcon,
  rootProduct,
  fragment,
  ctaText,
  atcFragment: atcFragmentSlug,
  highlightText,
  highlightProduct,
  isLoading,
  isError,
  wActions,
}) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const atcFragment = usePageFragment(atcFragmentSlug);
  const atcFragmentValue = useFragmentValue(atcFragment);

  const [fbtSelectedProducts, setFbtSelectedProducts] = useState<string[]>(
    (products || []).map((product) => product.slug)
  );

  const productsMap: Map<string, Product> = useMemo(
    () =>
      new Map<string, Product>(
        products?.map((product) => [product.slug, product])
      ),
    [products]
  );

  const [productsActiveVariants, setProductsActiveVariants] = useState<
    Record<string, ProductVariant | undefined>
  >(
    Object.fromEntries(
      products!.map((product) => [
        product.slug,
        product.variants?.find((v) => !isOutOfStock(v.stockStatus)),
      ])
    )
  );

  const totalPrice = Object.entries(productsActiveVariants).reduce(
    (acc, [key, value]) => {
      const price = value?.price?.amount ?? 0;
      const stockStatus = value?.stockStatus;
      if (stockStatus === 'IN_STOCK' && fbtSelectedProducts.includes(key)) {
        return acc + price;
      }
      return acc;
    },
    0
  );

  const totalMrp = Object.entries(productsActiveVariants).reduce(
    (acc, [key, value]) => {
      const mrp = value?.mrp?.amount ?? 0;
      const stockStatus = value?.stockStatus;
      if (stockStatus === 'IN_STOCK') {
        return acc + mrp;
      }
      return acc;
    },
    0
  );

  const onProductVariantChange = (
    productSlug: string,
    variant: ProductVariant
  ) => {
    setProductsActiveVariants({
      ...productsActiveVariants,
      [productSlug]: variant,
    });
  };

  const productsNum = config?.maxProductsToDisplay ?? 5;

  const toggleProductSelection = (productSlug: string, value: boolean) => {
    if (value) {
      setFbtSelectedProducts(uniq([...fbtSelectedProducts, productSlug]));
    } else {
      setFbtSelectedProducts(
        fbtSelectedProducts.filter((product) => product !== productSlug)
      );
    }
  };

  const actionDispatch = useActionDispatch();

  // TODO should we send individual events with context / should we have like an fbt event
  const mutation = useMutation({
    mutationFn: (lines: CartAddLinePayload[]) => {
      fbtSelectedProducts.forEach((productSlug) =>
        actionDispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_REQUEST,
            data: {
              quantity: 1,
              entities: {
                productVariant: productsActiveVariants[productSlug],
                product: productsMap.get(productSlug),
              },
            },
          },
        })
      );
      return remixApi.addToCart(lines);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      fbtSelectedProducts.forEach((productSlug) =>
        actionDispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_SUCCESS,
            data: {
              quantity: 1,
              entities: {
                productVariant: productsActiveVariants[productSlug],
                product: productsMap.get(productSlug),
                cart: updatedCart,
              },
            },
          },
        })
      );
      showToast({
        title: `${fbtSelectedProducts.length} products added to cart`,
      });
      wActions?.onSuccess?.forEach((action) => actionDispatch(action));
    },
    onError: (error) => {
      const title = getErrorMessage(error, {}, 'Failed to add product to cart');

      fbtSelectedProducts.forEach((productSlug) =>
        actionDispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_REQUEST,
            data: {
              quantity: 1,
              entities: {
                productVariant: productsActiveVariants[productSlug],
                product: productsMap.get(productSlug),
              },
              error,
            },
          },
        })
      );
      showToast({ title });
      wActions?.onSuccess?.forEach((action) => actionDispatch(action));
    },
  });

  const onClickAddToBag = () => {
    if (isEmpty(fbtSelectedProducts)) {
      return;
    }
    const addToCartLines = fbtSelectedProducts.map((productSlug) => ({
      variantId: productsActiveVariants[productSlug]?.id!,
      quantity: 1,
    }));
    mutation.mutate(addToCartLines);
  };

  const { style, className } = useStyleConfig(config?.container ?? {});

  const { style: headerContStyle, className: headerContClassName } =
    useStyleConfig(config?.header?.container ?? {});

  const { style: atcContStyle, className: atcContClassName } = useStyleConfig(
    config?.atcAction?.container ?? {}
  );

  const { style: cardListStyle, className: cardListClassName } = useStyleConfig(
    config?.list ?? {}
  );

  const { style: itemStyle, className: itemClassName } = useStyleConfig(
    config?.item?.container ?? {}
  );

  const { style: selectCtaContStyle, className: selectCtaContClassName } =
    useStyleConfig(config?.item?.selectCta?.container ?? {});

  const {
    style: productCalloutContStyle,
    className: productCalloutContClassName,
  } = useStyleConfig(config?.item?.productCallOut?.container ?? {});

  let actionText = ctaText ?? `ADD TO BAG`;

  if (fbtSelectedProducts.length >= 0) {
    actionText = `${actionText} (${fbtSelectedProducts.length})`;
  }

  const fragmentValue = useFragmentValue(fragment);

  // TODO handle loading / error states

  if (isEmpty(products)) {
    return null;
  }

  // TODO add stock status check
  // TODO use hook to use selected variant from product card

  return (
    <div className={clsx('flex flex-col', className)} style={style}>
      <div className={clsx('flex flex-col')}>
        {config?.header?.visible !== false && (
          <div
            className={clsx('flex flex-col', headerContClassName)}
            style={headerContStyle}
          >
            <Text
              data="Frequently Bought Together"
              config={config?.header?.text}
            />
          </div>
        )}
        <div
          className={clsx('flex flex-col', cardListClassName)}
          style={cardListStyle}
        >
          {products?.slice(0, productsNum).map((product) => {
            const key = `fbt-product-${product.slug}`;
            return (
              <div
                key={key}
                className={clsx('flex flex-col', itemClassName)}
                style={itemStyle}
              >
                {highlightProduct && rootProduct?.slug === product.slug && (
                  <div
                    className={clsx(
                      'flex flex-col',
                      productCalloutContClassName
                    )}
                    style={productCalloutContStyle}
                  >
                    <Text
                      data={highlightText}
                      config={config?.item?.productCallOut?.text}
                    />
                  </div>
                )}
                <div className="flex flex-row">
                  <div className="flex flex-col" style={{ flex: 1 }}>
                    <ElemasonFragmentProvider
                      value={fragmentValue({
                        product,
                        onVariantSelect: ({
                          variant,
                        }: {
                          variant: ProductVariant;
                        }) => {
                          onProductVariantChange(product.slug, variant);
                        },
                        selectedVariant: productsActiveVariants[product.slug],
                      })}
                    >
                      {fragment?.cells.map((cell, index) => (
                        <Cell key={cell.id ?? index} cell={cell} />
                      ))}
                    </ElemasonFragmentProvider>
                  </div>
                  <div
                    className={clsx('flex flex-col', selectCtaContClassName)}
                    style={{ ...selectCtaContStyle, flex: 0 }}
                  >
                    <CheckboxRoot
                      id={product.slug}
                      className="group shrink-0"
                      title={`Select ${product.slug}`}
                      checked={fbtSelectedProducts.includes(product.slug)}
                      onCheckedChange={(value: boolean) =>
                        toggleProductSelection(product.slug, value)
                      }
                    >
                      <UikitIcon
                        className="group-radix-state-checked:hidden"
                        symbol={unselectIcon || 'outline-checkbox-unchecked'}
                      />
                      <CheckboxIndicator>
                        <UikitIcon
                          symbol={selectIcon || 'solid-checkbox-checked'}
                        />
                      </CheckboxIndicator>
                    </CheckboxRoot>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {atcFragment ? (
        <ElemasonFragmentProvider
          value={atcFragmentValue({
            fbtSelectedProducts,
            onClickAddToBag,
            totalPrice,
            totalMrp,
            isAddingToCart: mutation.isPending,
          })}
        >
          {atcFragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
        </ElemasonFragmentProvider>
      ) : (
        <div
          className={clsx('flex flex-col', atcContClassName)}
          style={atcContStyle}
        >
          {fbtSelectedProducts.length === 0 ? (
            <Button
              disabled
              config={config?.atcAction?.disabledButton}
              data={{
                text: actionText,
              }}
            />
          ) : (
            <Button
              isLoading={mutation.isPending}
              config={config?.atcAction?.button}
              data={{
                text: actionText,
              }}
              onClick={onClickAddToBag}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { ProductFBT };
