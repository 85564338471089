import {
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import { Carousel as UiCarousel } from '@tectonic/uikit';
import clsx from 'clsx';
import { forwardRef, memo, useRef } from 'react';
import { MediaAsset } from '../Asset';
import { useButtonConfig } from '../Button';
import { CarouselSlide } from './CarouselSlide';

import type {
  Asset,
  CarouselBannerAsset,
  CarouselConfig,
  ElemasonWidgetAction,
} from '@tectonic/types';
import type { CarouselHandle } from '@tectonic/uikit/src/components/Carousel/Carousel.types';
import type { ComponentProps, ForwardRefRenderFunction } from 'react';

interface CarouselProps
  extends Omit<ComponentProps<typeof UiCarousel>, 'children'> {
  selectedIndex?: number;
  config?: CarouselConfig;
  isMuted?: boolean;
  slides?: CarouselBannerAsset[];
  onSlideClick?: (asset: Asset, action?: ElemasonWidgetAction) => void;
}

const Carousel: ForwardRefRenderFunction<CarouselHandle, CarouselProps> = (
  {
    slides,
    config,
    isMuted,
    startIndex,
    selectedIndex,
    style: cStyle,
    className: cClassName,
    onSlideClick,
    ...others
  },
  ref
) => {
  const startIndexRef = useRef(startIndex);
  const { style, className } = useStyleConfig(config?.dimension ?? {});
  const { style: paginationStyle, className: paginationClassName } =
    useStyleConfig(config?.pagination ?? {});
  const [selectedStyle, selectedClassName] = useStyleConfigV2(
    config?.selectedSlide
  );
  const [unselectedStyle, unselectedClassName] = useStyleConfigV2(
    config?.unselectedSlide
  );
  const [scrollNextStyle, scrollNextClassName] = useStyleConfigV2(
    config?.arrows?.next?.container
  );
  const [scrollPrevStyle, scrollPrevClassName] = useStyleConfigV2(
    config?.arrows?.prev?.container
  );

  const prevButtonConfig = useButtonConfig(config?.arrows?.prev?.button ?? {});
  const nextButtonConfig = useButtonConfig(config?.arrows?.next?.button ?? {});

  return (
    <UiCarousel
      ref={ref}
      axis={config?.axis}
      loop={config?.loop}
      align={config?.align}
      dragFree={config?.dragFree}
      style={{ ...style, ...cStyle }}
      startIndex={startIndexRef.current}
      containScroll={config?.containScroll}
      slidesToScroll={config?.slidesToScroll}
      className={clsx('relative w-full', className, cClassName)}
      autoplay={{ enable: config?.autoPlay, delay: config?.autoPlayInterval }}
      {...others}
    >
      <UiCarousel.Slides>
        {(slides ?? [])?.map((asset, index) => (
          <CarouselSlide
            key={index}
            action={asset.action}
            config={config?.slide}
            onClick={(action) => onSlideClick?.(asset, action)}
            className={clsx(
              className,
              'relative h-fit',
              config?.slidesToScroll ? '' : 'w-full',
              index === selectedIndex ? selectedClassName : unselectedClassName
            )}
            style={index === selectedIndex ? selectedStyle : unselectedStyle}
          >
            <MediaAsset
              muted={isMuted}
              asset={asset}
              style={style}
              className={className}
              config={{
                ...config?.slide!,
                loading: index === 0 ? 'eager' : 'lazy',
              }}
            />
          </CarouselSlide>
        ))}
      </UiCarousel.Slides>
      <UiCarousel.ScrollPrev
        style={prevButtonConfig.style}
        containerStyle={scrollPrevStyle}
        isVisible={config?.arrows?.visible}
        className={prevButtonConfig.className}
        containerClassName={scrollPrevClassName}
        startIcon={config?.arrows?.prev?.startIcon}
        {...prevButtonConfig.buttonProps}
      />
      <UiCarousel.ScrollNext
        style={nextButtonConfig.style}
        containerStyle={scrollNextStyle}
        isVisible={config?.arrows?.visible}
        className={nextButtonConfig.className}
        containerClassName={scrollNextClassName}
        startIcon={config?.arrows?.next?.startIcon}
        {...nextButtonConfig.buttonProps}
      />
      <UiCarousel.Dots
        style={paginationStyle}
        className={clsx(
          'flex',
          paginationClassName,
          config?.pagination?.overlay ? 'absolute' : ''
        )}
      >
        <UiCarousel.Dot
          className="transition-all"
          // @ts-expect-error TODO: fix types
          getStyles={({ isActive }: { isActive: boolean }) =>
            isActive
              ? config?.pagination?.activeDot
              : config?.pagination?.inactiveDot
          }
        />
      </UiCarousel.Dots>
    </UiCarousel>
  );
};

const ExoticCarousel = memo(forwardRef(Carousel));

export { ExoticCarousel as Carousel };
