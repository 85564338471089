import { isEmpty } from 'lodash-es';
import { SearchHistory } from '../../../components/Search';
import { useSearchHistory } from './hooks';

import type { FC } from 'react';
import type { SearchHistoryWidgetProps } from './SearchHistory.types';

const SearchHistoryWidget: FC<SearchHistoryWidgetProps> = ({ widget }) => {
  const { config } = widget;
  const data = widget.data!;

  const { isLoading, queries, onClear, onQueryClick } =
    useSearchHistory(widget);

  // We don't display widget if it has no queries.
  const shouldHideWidget = !isLoading && isEmpty(queries);
  if (shouldHideWidget) {
    return null;
  }

  return (
    <SearchHistory
      data={data}
      queries={queries}
      config={config}
      isLoading={isLoading}
      onClear={onClear}
      onQueryClick={onQueryClick}
    />
  );
};

SearchHistoryWidget.displayName = 'ElemasonSearchHistoryWidget';

export { SearchHistoryWidget };
