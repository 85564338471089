const removeKeysFromObject = (obj: any, keys: string[]): any => {
  if (Array.isArray(obj)) return obj.map(item =>
    removeKeysFromObject(item, keys)
  );

  if (typeof obj === 'object' && obj !== null) {
    return Object
      .keys(obj)
      .reduce(
        (previousValue, key) =>
          keys.includes(key.toLowerCase()) ? previousValue : {
            ...previousValue,
            [key]: removeKeysFromObject(obj[key], keys)
          },
        {}
      );
  }
  return obj;
}

export { removeKeysFromObject };
