import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { Button } from '../../components';
import { useToast } from '../../core';
import { useActionDispatch, useHaloScript } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type { ElemasonIncrementCartLineQuantityWidget } from '@tectonic/types';
import type { FC } from 'react';

type IncrementCartLineQuantityWidgetProps = {
  widget: ElemasonIncrementCartLineQuantityWidget;
};

const IncrementCartLineQuantityWidget: FC<
  IncrementCartLineQuantityWidgetProps
> = ({ widget: { data, config } }) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const actionDispatch = useActionDispatch();
  const cartLine = useHaloScript(data?.cartLine);

  const addItemMutation = useMutation({
    mutationFn: () => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_ADD_REQUEST,
        },
      });
      return remixApi.addToCart([
        { quantity: 1, variantId: cartLine!.productVariant!.id },
      ]);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_ADD_SUCCESS,
          data: {
            entities: {
              cart: updatedCart,
            },
          },
        },
      });
      showToast({ title: 'Product added to cart' });
    },
    onError: (error) => {
      const title = getErrorMessage(error, {}, 'Failed to add product to cart');

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_ADD_ERROR,
          data: {
            error,
          },
        },
      });

      showToast({ title });
    },
  });

  const onDelete = () => {
    addItemMutation.mutate();
  };

  if (!cartLine) return null;

  return (
    <Button
      data={data}
      onClick={onDelete}
      config={config?.button}
      size={config?.button?.size}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      disabled={addItemMutation.isPending}
      isLoading={addItemMutation.isPending}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

export { IncrementCartLineQuantityWidget };
