import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonWidgetType } from '@tectonic/types';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, type FC } from 'react';
import { ProductPromotionList } from '../../components';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import {
  useHaloScript,
  usePageFragment,
  useSharedLocalState,
} from '../../hooks';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { queryKeys } from '../../queryKeys';

import type { ElemasonProductPromotionListWidget } from '@tectonic/types';

interface ElemasonProductPromotionListWidgetProps {
  widget: ElemasonProductPromotionListWidget;
}

const ProductPromotionListWidget: FC<
  ElemasonProductPromotionListWidgetProps
> = ({ widget: { data, config: productPromotionListConfig } }) => {
  const {
    slug: slugAccessor,
    variant: variantAccessor,
    topK,
    stateKey: stateKeyKey,
    sortedStateKey: sortedStateKeyKey,
    fragment: fragmentSlug,
  } = data || {};

  const stateKey = useHaloScript(stateKeyKey)!;
  const sortedStateKey = useHaloScript(sortedStateKeyKey)!;
  const fragment = usePageFragment(fragmentSlug);
  const fragmentValue = useFragmentValue(fragment);

  const slug = useHaloScript(slugAccessor!);
  const variant = useHaloScript(variantAccessor!);
  const variantId = variant?.globalId;
  const numResults = topK || 5;

  const params = {
    topK: numResults.toString(),
    variantId: variantId?.toString() ?? '',
  };

  // TODO should we fail spectacularly or just drop the widget

  // TODO need a better way to handle it rather than at api level since useQuery will fail if we send nulls, if we exit before useQuery react is complaining that more hooks are rendered than previous render
  const {
    data: promotionsData,
    isError,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: queryKeys.productPromotionsForVariant(slug, variantId),
    queryFn: async () => {
      if (isNil(variantId) || isNil(slug)) {
        return null;
      }
      return remixApi.getPromotionsForProductVariant(
        slug ?? '',
        new URLSearchParams(params)
      );
    },
  });

  const state = useMemo(
    () => ({
      data: promotionsData,
      isError,
      isLoading,
      isFetching,
    }),
    [isLoading, promotionsData, isError, isFetching]
  );

  const sortedState = useMemo(
    () => ({
      data: {
        ...promotionsData,
        hits: [...(promotionsData?.hits || [])].sort(
          (a, b) =>
            b.calculatedDiscountValue?.amount -
            a.calculatedDiscountValue?.amount
        ),
      },
      isError,
      isLoading,
      isFetching,
    }),
    [isLoading, promotionsData, isError, isFetching]
  );

  const { setSharedState } = useSharedLocalState(stateKey, state);

  const { setSharedState: setSharedSortedState } = useSharedLocalState(
    sortedStateKey,
    sortedState
  );

  useEffect(() => setSharedState(state), [state, setSharedState]);
  useEffect(
    () => setSharedSortedState(sortedState),
    [sortedState, setSharedSortedState]
  );

  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductPromotionList,
    productPromotionListConfig
  );

  const { style, className } = useStyleConfig(config?.container ?? {});

  if (
    (!promotionsData?.hits || promotionsData?.hits.length === 0) &&
    !(isLoading || isFetching)
  ) {
    return null;
  }

  return (
    <div style={style} className={clsx('flex w-full flex-col', className)}>
      {fragment ? (
        <ElemasonFragmentProvider
          value={fragmentValue({
            isLoading: isLoading || isFetching,
            promotions: promotionsData?.hits || [],
          })}
        >
          {fragment.cells.map((cell) => (
            <Cell key={cell.id} cell={cell} />
          ))}
        </ElemasonFragmentProvider>
      ) : (
        <ProductPromotionList
          config={config}
          data={promotionsData?.hits || []}
          isLoading={isLoading || isFetching}
          isError={isError}
        />
      )}
    </div>
  );
};

export { ProductPromotionListWidget };
