import { ElemasonComponentType } from '@tectonic/types';
import { useElemasonComponentConfig } from '../../../hooks/useElemasonConfig';
import { getVariantTypes } from '../../../utils';
import { BundleVariantSelector } from './BundleVariantSelector';
import { ColorSelector } from './ColorSelector';
import { SizeSelector } from './SizeSelector';

import type {
  Product,
  ProductVariant,
  VariantSelectorConfig,
} from '@tectonic/types';
import type { FC } from 'react';

interface ProductVariantSelectorProps {
  product: Pick<
    Product,
    'variants' | 'hasVariantsWithComponents' | 'productComponents'
  >;
  config?: VariantSelectorConfig;
  selectedVariant?: ProductVariant;
  onSelect: (variant?: ProductVariant) => void;
}

const ProductVariantSelector: FC<ProductVariantSelectorProps> = ({
  product,
  onSelect,
  selectedVariant,
  config: variantSelectorConfig,
}) => {
  const config = useElemasonComponentConfig(
    ElemasonComponentType.VariantSelector,
    variantSelectorConfig
  );

  const variantTypes = product.variants
    ? getVariantTypes(product.variants[0])
    : [];

  if (product.hasVariantsWithComponents) {
    return (
      <BundleVariantSelector
        config={config}
        product={product}
        onSelect={onSelect}
        selectedVariant={selectedVariant}
      />
    );
  }

  return (
    <div>
      {variantTypes
        .filter(({ value }) => value && value !== 'title')
        .map(({ attr, value }) =>
          value === 'color' ? (
            <ColorSelector
              key={attr}
              attribute={attr}
              onSelect={onSelect}
              config={config.color}
              variants={product.variants ?? []}
              selectedVariant={selectedVariant!}
            />
          ) : (
            <SizeSelector
              key={attr}
              attribute={attr}
              onSelect={onSelect}
              config={config.size}
              selectedVariant={selectedVariant!}
              variants={product.variants ?? []}
            />
          )
        )}
    </div>
  );
};

export { ProductVariantSelector };
