import { Button } from '../../../components';
import { useActionDispatch } from '../../../hooks';

import type { FC } from 'react';
import type { SearchSortControlWidgetProps } from './SearchSortControl.types';

const SearchSortControlWidget: FC<SearchSortControlWidgetProps> = ({
  widget,
}) => {
  const wData = widget.data!;
  const dispatch = useActionDispatch();

  const onClick = () => {
    widget.actions?.onClick.forEach((action) => dispatch(action, undefined));
  };

  return (
    <Button
      onClick={onClick}
      data={wData.button}
      config={widget.config?.control?.button}
    />
  );
};

SearchSortControlWidget.displayName = 'SearchSortControlWidget';

export { SearchSortControlWidget };
