import { SearchFacetControlType } from '@tectonic/types';
import { useFormContext } from '@tectonic/uikit';
import { useMemo } from 'react';
import { CheckboxFacet } from './CheckboxFacet';
import { RadioFacet } from './RadioFacet';
import { RangeFacet } from './RangeFacet';

import type { FC } from 'react';
import type { FacetValueProps } from './FacetValue.types';

const getControl = (controlType?: SearchFacetControlType) => {
  switch (controlType) {
    case SearchFacetControlType.RANGE:
      return RangeFacet;
    case SearchFacetControlType.RADIO:
      return RadioFacet;
    case SearchFacetControlType.CHECKBOX:
      return CheckboxFacet;
    default:
      return null;
  }
};

const useFacetControl = (controlType: SearchFacetControlType) =>
  useMemo(() => getControl(controlType), [controlType]);

const FacetValue: FC<FacetValueProps> = ({ facet, config }) => {
  const { control } = useFormContext();
  const Control = useFacetControl(facet.controlType);
  const facetName = facet.name;
  return Control ? (
    <Control
      key={facetName}
      control={control}
      name={facetName}
      facet={facet}
      config={config}
    />
  ) : null;
};

FacetValue.displayName = 'FacetValue';

export { FacetValue };
