import { useHaloScript } from '../../hooks';

import type { ElemasonFilePreviewWidget } from '@tectonic/types';

const useFilePreview = (widget: ElemasonFilePreviewWidget) => {
  const wData = widget.data!;

  const file = useHaloScript<File>(wData.file)!;

  const isVideo = file.type.startsWith('video/');
  const isImage = file.type.startsWith('image/');
  const url = URL.createObjectURL(file);

  return { isVideo, isImage, url };
};

export { useFilePreview };
