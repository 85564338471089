import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { CarouselProvider } from './CarouselContext';
import Dot from './Dot';
import Dots from './Dots';
import ScrollNext from './ScrollNext';
import ScrollPrev from './ScrollPrev';
import Slide from './Slide';
import Slides from './Slides';
import Stage from './Stage';
import { useCarousel, useCarouselContextValue } from './hooks';

import type { ForwardRefRenderFunction } from 'react';
import type { CarouselHandle, CarouselProps } from './Carousel.types';

// Carousel is a wrapper around Embla Carousel. Refer official docs for styling
// related conventions https://www.embla-carousel.com/.

const CarouselComponent: ForwardRefRenderFunction<
  CarouselHandle,
  CarouselProps
> = (props, ref) => {
  const { axis = 'x', className, children, onSelect, ...restProps } = props;
  const [emblaRef, emblaApi] = useCarousel({ ...props, axis });

  const contextValue = useCarouselContextValue(emblaApi, {
    axis,
    startIndex: restProps.startIndex,
  });
  const selectCbRef = useRef<typeof onSelect>(onSelect);
  selectCbRef.current = onSelect;
  const { activeSnap } = contextValue;

  useImperativeHandle(ref, () => ({ emblaApi }), [emblaApi]);

  useEffect(() => {
    selectCbRef.current?.(activeSnap);
  }, [activeSnap]);

  return (
    <CarouselProvider value={contextValue}>
      {/* @ts-ignore */}
      <Stage className={className} ref={emblaRef} {...restProps}>
        {children}
      </Stage>
    </CarouselProvider>
  );
};

const ExoticCarousel = forwardRef(CarouselComponent);

ExoticCarousel.displayName = 'Carousel';

const Carousel = Object.assign(ExoticCarousel, {
  Slides,
  Slide,
  ScrollNext,
  ScrollPrev,
  Dot,
  Dots,
});

export default Carousel;

export type { CarouselOptions } from './Carousel.types';
