import { useMutation } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { useState, type FC } from 'react';
import { Button } from '../../components';
import { useToast } from '../../core';
import { useActionDispatch } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type { ElemasonLocateMeWidget } from '@tectonic/types';

interface LocateMeProps {
  widget: ElemasonLocateMeWidget;
}

const LocateMeWidget: FC<LocateMeProps> = ({
  widget: { config, data, actions },
}) => {
  const { showToast } = useToast();
  const dispatch = useActionDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate, isPending } = useMutation({
    mutationKey: queryKeys.zipByCoords(),
    mutationFn: (coords: GeolocationCoordinates) =>
      remixApi.getZipCodeByCoordinates(coords!.latitude, coords!.longitude),
    onSuccess: ({ zip }) =>
      actions?.onSuccess?.forEach((action) => dispatch(action, { zip })),
    onError: (error) => {
      actions?.onError?.forEach((action) => dispatch(action, { error }));
      const title = getErrorMessage(error);
      showToast({ title, duration: 2000 });
    },
    onSettled: () => setIsLoading(false),
  });

  const handlePermission = () => {
    if (!navigator.permissions) {
      return;
    }
    setIsLoading(true);
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (['granted', 'prompt'].includes(result.state)) {
        navigator.geolocation.getCurrentPosition(
          (positions) => mutate(positions.coords),
          (error) => {
            actions?.onError?.forEach((action) => dispatch(action, { error }));
            showToast({
              title: 'Location access denied',
              duration: 2000,
            });
            setIsLoading(false);
          }
        );
      } else if (result.state === 'denied') {
        setIsLoading(false);
        actions?.onError?.forEach((action) => dispatch(action));
        showToast({
          title: 'Location access denied',
          duration: 2000,
        });
      }
    });
  };

  const handleOnClick = () => {
    handlePermission();
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return (
    <Button
      data={data?.button}
      onClick={handleOnClick}
      config={config?.button}
      isLoading={isLoading || isPending}
    />
  );
};

export { LocateMeWidget };
