import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import {
  Text,
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { toCurrency } from '@tectonic/utils';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { type FC, type MouseEventHandler } from 'react';
import { useToast } from '../../core/ElemasonEntry/Toast';
import { useActionDispatch } from '../../hooks';
import { queryKeys } from '../../queryKeys';
import { isDefaultVariant, isVisible } from '../../utils';
import { Button } from '../Button';
import { ProductPricing } from '../Product';
import { ProductVariants } from '../Product/ProductVariants';

import type {
  CartLineAttribute,
  CartLineProductVariant,
  CartProductCardDetailsConfig,
} from '@tectonic/types';

interface CartProductCardDetailsProps {
  id: string;
  quantity: number;
  isFreebie?: boolean;
  isLoading?: boolean;
  variant: CartLineProductVariant;
  attributes?: CartLineAttribute[];
  config?: CartProductCardDetailsConfig;
  onVariantSelectorClick?: MouseEventHandler;
}

const CartProductCardDetails: FC<CartProductCardDetailsProps> = ({
  id,
  config,
  variant,
  quantity,
  isLoading,
  isFreebie,
  attributes,
  onVariantSelectorClick,
}) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { style, className } = useStyleConfig(config?.container ?? {});

  const actionDispatch = useActionDispatch();

  const addItemMutation = useMutation({
    mutationFn: () => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_ADD_REQUEST,
        },
      });
      return remixApi.addToCart([{ quantity: 1, variantId: variant!.id }]);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_ADD_SUCCESS,
          data: {
            entities: {
              cart: updatedCart,
            },
          },
        },
      });
      showToast({ title: 'Product added to cart' });
    },
    onError: (error) => {
      const title = getErrorMessage(error, {}, 'Failed to add product to cart');

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_ADD_ERROR,
          data: {
            error,
          },
        },
      });

      showToast({ title });
    },
    // TODO: Handle optimisitc updates using onMutate
  });

  const removeFromCartMutation = useMutation({
    mutationFn: () => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_REMOVE_REQUEST,
          data: {
            quantity: quantity - 1,
          },
        },
      });
      return remixApi.updateCart([
        { id, quantity: quantity - 1, variantId: variant!.id },
      ]);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_REMOVE_SUCCESS,
          data: {
            entities: {
              cart: updatedCart,
            },
            quantity: 1,
          },
        },
      });
      showToast({ title: 'Product removed from cart' });
    },
    onError: (error) => {
      const title = getErrorMessage(
        error,
        {},
        'Failed to remove product from cart'
      );

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PRODUCT_REMOVE_ERROR,
          data: {
            error,
            quantity: 1,
          },
        },
      });
      showToast({ title });
    },
  });

  const isBrandVisible = isVisible(config?.brand);
  const isTitleVisible = isVisible(config?.title);
  const isQuantityVisible = isVisible(config?.quantity);
  const showAttributes = isVisible(config?.attributes, false);
  const showVariant =
    isVisible(config?.variant ?? {}) &&
    !isDefaultVariant(variant) &&
    !variant.product.hasVariantsWithComponents;

  const brandProps = useStyleConfig(config?.brand ?? {});
  const titleProps = useStyleConfig(config?.title ?? {});
  const variantProps = useStyleConfig(config?.variant ?? {});
  const variantContainerProps = useStyleConfig(config?.variantContainer ?? {});
  const attributesHeadingProps = useStyleConfig(
    config?.attributes?.heading ?? {}
  );
  const attributesTextContainerProps = useStyleConfig(
    config?.attributes?.textContainer ?? {}
  );
  const [attributesStyles, attributesClassNames] = useStyleConfigV2(
    config?.attributes?.container
  );
  const { style: qStyle, className: qClassName } = useStyleConfig(
    config?.quantity ?? {}
  );

  const [freebieStyle, freebieClassNames] = useStyleConfigV2(
    config?.freebie ?? {}
  );

  const [freeGiftStyle, freeGiftClassNames] = useStyleConfigV2(
    config?.freebie?.freeGift ?? {}
  );

  const addToCart = () => {
    addItemMutation.mutate();
  };

  const removeFromCart = () => {
    removeFromCartMutation.mutate();
  };

  const { data: product } = useQuery({
    queryKey: queryKeys.product(variant.product.slug),
    queryFn: () => remixApi.getProduct(variant.product.slug),
  });

  return (
    <div className={clsx('flex flex-col', className)} style={style}>
      {isBrandVisible && (
        <Text
          data={variant.product.brand}
          config={config?.brand}
          {...brandProps}
        />
      )}
      {isTitleVisible && (
        <Text
          data={variant.product.title}
          config={config?.title}
          {...titleProps}
        />
      )}
      {showVariant && (
        <div {...variantContainerProps}>
          {product ? (
            <ProductVariants
              product={product}
              config={config!.productVariant}
              onClick={onVariantSelectorClick}
              selectedVariant={product.variants?.find(
                (v) => v.id === variant.id
              )}
            />
          ) : (
            <Text
              data={variant.basisAttr1Display}
              config={config?.variant}
              {...variantProps}
            />
          )}
        </div>
      )}
      {showAttributes && !isEmpty(attributes) && (
        <div
          style={attributesStyles}
          className={clsx('flex flex-col', attributesClassNames)}
        >
          <div {...attributesHeadingProps}>
            <Text data="Measurements:" config={config?.attributes?.heading} />
          </div>
          <div {...attributesTextContainerProps}>
            <Text
              config={config?.attributes?.text}
              data={attributes
                ?.map((attr) => `${attr.key}: ${attr.value}`)
                .join(', ')}
            />
          </div>
        </div>
      )}
      {isQuantityVisible && (
        <div style={qStyle} className={clsx('flex', qClassName)}>
          <Button
            onClick={removeFromCart}
            disabled={quantity === 1 || isLoading}
            config={config?.quantity?.remove}
            isLoading={removeFromCartMutation.isPending}
            data={{ startIcon: config?.quantity?.removeIcon }}
          />
          <Text config={config?.quantity} data={quantity.toString()} />
          <Button
            onClick={addToCart}
            disabled={isLoading}
            config={config?.quantity?.add}
            isLoading={addItemMutation.isPending}
            data={{ startIcon: config?.quantity?.addIcon }}
          />
        </div>
      )}
      {isFreebie ? (
        <>
          <div style={freebieStyle} className={clsx('flex', freebieClassNames)}>
            <Text
              config={config?.freebie?.priceText}
              data={toCurrency(0, variant.mrp.currencyCode)}
            />
            <ProductPricing
              variant={variant}
              config={config?.freebie?.pricing}
            />
            <Text data="Free" config={config?.freebie?.freeText} />
          </div>
          <div
            style={freeGiftStyle}
            className={clsx('flex', freeGiftClassNames)}
          >
            <Text data="Free Gift" config={config?.freebie?.freeGift?.text} />
          </div>
        </>
      ) : (
        <ProductPricing variant={variant} config={config?.pricing} />
      )}
    </div>
  );
};

CartProductCardDetails.displayName = 'ElemasonCartProductCardDetails';

export { CartProductCardDetails };
