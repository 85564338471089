import { forwardRef, useImperativeHandle } from "react";
import { useActionDispatch } from "../../hooks";

import type { ForwardRefRenderFunction } from "react";
import type { ActionDispatchActionInputType } from "../../hooks";

interface DispatchExporterHandle {
    dispatch: (
        actionScript: ActionDispatchActionInputType,
        actionContext?: any
    ) => void;
}

// We are using this function to wrap latest context in non broken down widgets
// as for these big widget the action dispatch happens before we set the latest 
// entities via the analytics provider, so we pass the ref to this empty component 
// use the internal dispatch to actually dispatch the event. We need to be careful 
// and start removing this from the big widgets as we start breaking them down.
const DispatchExporter: ForwardRefRenderFunction<DispatchExporterHandle, {}> = (
    _props,
    ref
) => {
    const dispatch = useActionDispatch();
    useImperativeHandle(ref, () => ({ dispatch }));
    return null;
};

const ExoticDispatchExporter = forwardRef(DispatchExporter);

export { ExoticDispatchExporter as ActionDispatchExporter };
export type { DispatchExporterHandle };

