import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Swatch } from './Swatch';

import type { ProductVariantsColorSwatchConfig } from '@tectonic/types';
import type { FC } from 'react';

interface Color {
  name: string;
  code: string;
}

interface ColorSwatchProps {
  colors: Color[];
  config?: ProductVariantsColorSwatchConfig;
}

const defaultMaxColors = 5;

const ColorSwatch: FC<ColorSwatchProps> = ({ colors, config }) => {
  const colorsToShow = config?.maxColors ?? defaultMaxColors;
  const remainingColors = colors.length - colorsToShow;
  const { style, className } = useStyleConfig(config?.container ?? {});
  const { style: tCStyle, className: tCClassName } = useStyleConfig(
    config?.textContainer ?? {}
  );

  return (
    <div style={style} className={clsx('flex', className)}>
      {colors.slice(0, colorsToShow).map((color, index) => (
        <Swatch
          key={index}
          color={color}
          style={{
            marginLeft:
              index !== 0 ? config?.itemContainer?.offsetLeft : undefined,
          }}
          config={{ item: config?.item, itemContainer: config?.itemContainer }}
        />
      ))}
      {remainingColors > 0 && (
        <div style={tCStyle} className={clsx('flex', tCClassName)}>
          <Text config={config?.text} data={`+${remainingColors}`} />
        </div>
      )}
    </div>
  );
};

export { ColorSwatch };
