import { useMemo } from 'react';

import type { Collection, ImpressionLedgerEntry } from '@tectonic/types';

const useCollectionMap = (collections: Collection[], totalFound: number) =>
  useMemo(() => {
    const map = new Map<string, Partial<ImpressionLedgerEntry>>();
    collections.forEach((collection, index) => {
      const entry = {
        index,
        page: 1,
        payload: collection,
      };

      map.set(`${collection.id}`, entry);
    });
    return map;
  }, [collections, totalFound]);

export { useCollectionMap };
