const withTryCatch =
  <T extends (...args: any) => any>(operation: T) =>
  (...args: Parameters<T>) => {
    try {
      return operation(...args);
    } catch (error) {
      //
    }
    return undefined;
  };

export { withTryCatch };
