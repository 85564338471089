import { ServerErrorCodes } from '@tectonic/types';
import { ClientErrorCodes } from './ClientErrorCodes';

// Default error messages. They are only displayed when client doesn't have any
// clue about error code and there interpretation. It should be used as last resort.
const ErrorMessages = {
  [ServerErrorCodes.UNRECOVERABLE]:
    'Oops! Something went wrong. Please try again later',
  [ClientErrorCodes.UNKNOWN]:
    'Oops! Something went wrong. Please try again later',
};

export { ErrorMessages };
