const withTimeout = <T = unknown>(
  promise: Promise<T>,
  { message, duration = 500 }: { message: string; duration?: number }
) => {
  // TODO: Use delay function from utils.
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error(message)), duration);
  });

  return Promise.race([promise, timeoutPromise]) as T;
};

export { withTimeout };
