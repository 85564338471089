import { isUndefined } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useElemasonPageContext } from '../contexts/ElemasonPageContext';

import type { Maybe } from '@tectonic/types';

const useSharedLocalState = <T = unknown>(key: string, initialState?: T) => {
  const { scope, setScope } = useElemasonPageContext();

  const setSharedState = useCallback(
    (nState?: T) => {
      // @ts-ignore
      setScope((state: any) => ({ ...state, [key]: nState }));
    },
    [setScope, key]
  );

  const clearSharedState = useCallback(() => {
    setSharedState(undefined);
  }, [key, setSharedState]);

  useEffect(() => {
    if (!isUndefined(initialState)) {
      setSharedState(initialState);
    }
  }, [setSharedState, initialState]);

  const sharedState = (scope[key] ?? null) as Maybe<T>;

  return { sharedState, setSharedState, clearSharedState } as const;
};

export { useSharedLocalState };
