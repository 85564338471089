import { Blocks } from '../Blocks';

import type { ElemasonNavbar } from '@tectonic/types';
import type { FC } from 'react';

interface FooterProps {
  footer: ElemasonNavbar;
}

const Footer: FC<FooterProps> = ({ footer: { blocks } }) => (
  <Blocks blocks={blocks} />
);

Footer.displayName = 'ElemasonFooter';

export { Footer };
