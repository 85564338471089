import { getSearchPageUrl } from './utils';

import type {
  MetaFunctionArgs,
  MetaFunctionUrls,
  SeoTemplates,
} from './meta.types';

const getOgTags = (seoTemplates: SeoTemplates, urls: MetaFunctionUrls) => [
  { tagName: 'link', rel: 'canonical', href: getSearchPageUrl(urls) },
];

const searchMetaFunction = (
  args: MetaFunctionArgs,
  seoTemplates: SeoTemplates
) => {
  const { data, matches } = args;
  const { dehydratedState, urls } = data!;

  const parentMeta = matches.flatMap((match) => match.meta) as any[];

  if (!dehydratedState) {
    return parentMeta;
  }

  const ogTags = getOgTags(seoTemplates, urls);
  return [...parentMeta.filter((tag) => tag.rel !== 'canonical'), ...ogTags];
};

export { searchMetaFunction };
