import { useQuery } from '@tanstack/react-query';
import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { searchProducts } from '@tectonic/remix-client-network';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useActionDispatch } from '../../../hooks';
import { useProductRecommendations } from '../../../hooks/network';
import { useAccessorValue } from '../../../hooks/useAccessorValue';
import { queryKeys } from '../../../queryKeys';

import type {
  ElemasonSearchProductSuggestionWidget,
  Product,
  ProductsSearchApiRouteActionResponse,
} from '@tectonic/types';

const useProductSuggestions = (
  widget: ElemasonSearchProductSuggestionWidget
) => {
  const wData = widget.data!;
  const searchText = useAccessorValue<string>(wData.searchText);

  const {
    isError,
    isLoading,
    data: response,
    refetch,
  } = useQuery<ProductsSearchApiRouteActionResponse>({
    queryKey: queryKeys.productSuggestions(),
    queryFn: () => searchProducts({ q: searchText, perPage: 10, page: 1 }),
  });

  const actionDispatch = useActionDispatch();

  // TODO: Pass recommendation payload if needed.
  const { products: recommendations, count: rCount } =
    useProductRecommendations(null);

  const { found: mCount = 0, hits: matchingProducts = [] } =
    response?.data ?? {};

  const shouldUseResult = !isEmpty(matchingProducts);
  const products = shouldUseResult ? matchingProducts : recommendations;
  const count = shouldUseResult ? mCount : rCount;

  const onSourceClick = () => {
    // TODO: Add stub
  };

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PDP,
        route: {
          slug: product.slug,
        },
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  return {
    products,
    isLoading,
    shouldUseResult,
    count,
    isError,
    onRetry: refetch,
    onSourceClick,
    onProductClick,
  };
};

export { useProductSuggestions };
