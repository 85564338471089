import { useMemo } from 'react';

import type { ImpressionLedgerEntry } from '@tectonic/types';

const useRepeaterMap = (repeaterItem: any[], totalFound: number) =>
  useMemo(() => {
    const map = new Map<string, Partial<ImpressionLedgerEntry>>();
    repeaterItem.forEach((item, index) => {
      const entry = {
        index,
        page: 1,
        payload: item,
      };

      map.set(`${index}`, entry);
    });
    return map;
  }, [repeaterItem, totalFound]);

export { useRepeaterMap };
