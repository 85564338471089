import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Logger } from '@tectonic/logger';
import {
  AutoCompleteTargetType,
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { range, take } from 'lodash-es';
import { memo, type FC } from 'react';
import { useElemasonPageContext } from '../../../contexts';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { flattenSource } from '../../../utils/flattenSource';
import { useSearchAutoComplete } from './useSearchAutoComplete';

import type {
  AutoCompleteHit,
  AutoCompleteResponse,
  CollectionHitParams,
  ElemasonSearchAutoCompleteWidget,
  ProductSearchParams,
} from '@tectonic/types';

interface SearchAutoCompleteProps {
  widget: ElemasonSearchAutoCompleteWidget;
}

const SearchAutoCompleteWidget: FC<SearchAutoCompleteProps> = ({ widget }) => {
  const { showToast } = useToast();
  const { dispatch } = useElemasonPageContext();
  const {
    isError,
    response,
    fragment,
    searchText,
    loaderFragment,
    noResultsFragment,
  } = useSearchAutoComplete(widget);

  const fragmentValue = useFragmentValue(fragment);
  const loaderFragmentValue = useFragmentValue(loaderFragment);
  const noResultsFragmentValue = useFragmentValue(noResultsFragment);
  const [style, className] = useStyleConfigV2(widget.config?.container);

  type Hit<T> = Omit<AutoCompleteResponse, 'hits'> & AutoCompleteHit<T>;

  const hits: Hit<unknown>[] =
    response?.data?.reduce(
      (acc, cur) => [
        ...acc,
        ...cur.hits.map((x) => ({
          ...x,
          name: cur.name,
          entity: cur.entity,
          target: cur.target,
        })),
      ],
      [] as Hit<unknown>[]
    ) ?? [];

  const onClick = (hit: Hit<unknown>) => {
    switch (hit.target) {
      case AutoCompleteTargetType.PRODUCT_SEARCH:
        dispatch({
          type: ElemasonWidgetActionType.NAVIGATE_TO,
          payload: {
            type: NavigationActionType.PATH,
            slug: 'plp',
            search: {
              rawQuery: (hit as Hit<ProductSearchParams>).productSearchParams
                .name,
              ...flattenSource(
                (hit as Hit<ProductSearchParams>).productSearchParams
              ),
            },
          },
        });
        break;
      case AutoCompleteTargetType.COLLECTION:
        dispatch({
          type: ElemasonWidgetActionType.NAVIGATE_TO,
          payload: {
            type: NavigationActionType.COLLECTION,
            route: {
              slug: (hit as Hit<CollectionHitParams>).collectionParams.slug,
            },
          },
        });
        break;
      default:
        showToast({ title: 'Something unexpected happened' });
        Logger.error(`Unhandled target type: ${JSON.stringify(hit, null, 2)}`);
        break;
    }
  };

  if (
    response &&
    hits.length === 0 &&
    noResultsFragment &&
    !!searchText &&
    !isError
  ) {
    return (
      <ElemasonFragmentProvider value={noResultsFragmentValue()}>
        {noResultsFragment.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    );
  }

  if (!response && searchText && !isError && loaderFragment) {
    return (
      <div style={style} className={clsx('flex flex-col', className)}>
        {range(widget.config?.maxResults ?? 10).map((hit, index) => (
          <ElemasonFragmentProvider key={index} value={loaderFragmentValue()}>
            <Clickable>
              {loaderFragment.cells.map((cell) => (
                <Cell key={cell.id} cell={cell} />
              ))}
            </Clickable>
          </ElemasonFragmentProvider>
        ))}
      </div>
    );
  }

  if ((!response && isError) || !fragment) return null;

  return (
    <div style={style} className={clsx('flex flex-col', className)}>
      {take(hits, widget.config?.maxResults ?? 10).map((hit, index) => (
        <ElemasonFragmentProvider
          key={index}
          value={fragmentValue({ ...hit, index })}
        >
          <Clickable onClick={() => onClick(hit)}>
            {fragment.cells.map((cell) => (
              <Cell key={cell.id} cell={cell} />
            ))}
          </Clickable>
        </ElemasonFragmentProvider>
      ))}
    </div>
  );
};

const MemoizedSearchAutoCompleteWidget = memo(SearchAutoCompleteWidget);

export { MemoizedSearchAutoCompleteWidget as SearchAutoCompleteWidget };
