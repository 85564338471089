import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
  type Product,
  type ProductComponent,
  type ProductVariant,
  type VariantSelectorConfig,
} from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import deepmerge from 'deepmerge';
import { useState, type FC } from 'react';
import {
  useElemasonAnalyticsContext,
  useElemasonPageContext,
} from '../../../contexts';
import { ImageAsset } from '../../Asset';
import { ProductVariantSelector } from './ProductVariantSelector';

interface BundleSelectorProps {
  product: Pick<
    Product,
    'hasVariantsWithComponents' | 'productComponents' | 'variants'
  >;
  config?: VariantSelectorConfig;
  selectedVariant?: ProductVariant;
  onSelect: (variant?: ProductVariant) => void;
}

const getSelectedVariant = (
  component: ProductComponent,
  selectedVariant?: ProductVariant
) => {
  const ids = selectedVariant?.productVariantComponents!.map((c) => c.id) ?? [];
  return component.variants.find((v) => ids.includes(v.id));
};

const getVariant = (
  variants: ProductVariant[],
  selectedComponents: (ProductVariant | undefined)[]
) => {
  const ids = selectedComponents.map((c) => c?.id);
  return variants.find((v) =>
    ids.every((id) =>
      v.productVariantComponents!.map((c) => c.id)!.includes(id!)
    )
  );
};

const BundleVariantSelector: FC<BundleSelectorProps> = ({
  config,
  product,
  onSelect,
  selectedVariant,
}) => {
  const { dispatch } = useElemasonPageContext();
  const analyticsContext = useElemasonAnalyticsContext();
  const [selectedVariants, setSelectedVariants] = useState(
    product.productComponents
      ?.sort((x, y) => parseInt(x.id, 10) - parseInt(y.id, 10))
      .map((component) => getSelectedVariant(component, selectedVariant))
  );
  const [style, className] = useStyleConfigV2(config?.bundle?.container);
  const [componentStyle, componentClassName] = useStyleConfigV2(
    config?.bundle?.component
  );
  const [detailsStyle, detailsClassName] = useStyleConfigV2(
    config?.bundle?.details
  );
  const [textDetailsStyle, textDetailsClassName] = useStyleConfigV2(
    config?.bundle?.textDetails
  );
  const [imageStyle, imageClassName] = useStyleConfigV2(config?.bundle?.image);

  const handleSelect = (index: number, variant?: ProductVariant) => {
    const variants = [...(selectedVariants ?? [])];
    variants[index] = variant;
    setSelectedVariants(variants);
    const newVariant = getVariant(product.variants!, variants);
    onSelect(newVariant);
  };

  const handleOnClick = (component: ProductComponent) => {
    dispatch(
      {
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PDP,
          route: {
            slug: component.slug,
          },
        },
      },
      analyticsContext
    );
  };

  return (
    <div style={style} className={clsx('flex flex-col', className)}>
      {product.productComponents?.map((component, index) => (
        <div
          key={component.id}
          style={componentStyle}
          className={clsx('flex flex-col', componentClassName)}
        >
          <Clickable
            style={detailsStyle}
            title="Bundle Variant Selector Item"
            className={clsx('flex', detailsClassName)}
            onClick={() => handleOnClick(component)}
          >
            <div>
              <ImageAsset
                style={imageStyle}
                className={imageClassName}
                config={config?.bundle?.image}
                asset={component.assetMap.gallery[0]}
              />
            </div>
            <div
              style={textDetailsStyle}
              className={clsx('flex flex-col', textDetailsClassName)}
            >
              <Text data={component.brand} config={config?.bundle?.brand} />
              <Text data={component.title} config={config?.bundle?.title} />
            </div>
          </Clickable>
          <ProductVariantSelector
            config={{
              ...config,
              size: deepmerge(config?.size ?? {}, config?.bundle?.size ?? {}),
              color: deepmerge(
                config?.color ?? {},
                config?.bundle?.color ?? {}
              ),
            }}
            selectedVariant={selectedVariants?.[index]}
            onSelect={(variant) => handleSelect(index, variant)}
            product={{ ...component, hasVariantsWithComponents: false }}
          />
        </div>
      ))}
    </div>
  );
};

export { BundleVariantSelector };
