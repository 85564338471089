import { memo, type FC } from 'react';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../contexts';
import { Block } from '../Block';

import type { ElemasonBlock } from '@tectonic/types';

interface BlocksProps {
  blocks: ElemasonBlock[];
}

// TODO: implement virtualization
const Blocks: FC<BlocksProps> = ({ blocks }) => {
  const analyticsContext = useElemasonAnalyticsContext();

  return (
    <>
      {blocks.map((block, index) => (
        <ElemasonAnalyticsProvider
          key={block.id}
          value={{ ...analyticsContext, blockIndex: index }}
        >
          <Block index={index} block={block} />
        </ElemasonAnalyticsProvider>
      ))}
    </>
  );
};

const MemoizedBlocks = memo(Blocks);

Blocks.displayName = 'ElemasonBlocks';

export { MemoizedBlocks as Blocks };
