import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Blocks } from '../Blocks';

import type { ElemasonPage } from '@tectonic/types';
import type { FC } from 'react';

interface PageProps {
  page: ElemasonPage;
}

const Page: FC<PageProps> = ({ page }) => {
  const [style, className] = useStyleConfigV2(page.config?.theme ?? {});

  return (
    <div style={style} className={clsx('flex grow flex-col', className)}>
      <Blocks blocks={page.blocks} />
    </div>
  );
};

Page.displayName = 'ElemasonPage';

export { Page };
