import clsx from 'clsx';
import { noop } from 'lodash-es';
import {
  Children,
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  isDown,
  isUp,
  useContainerStyle,
  useGradientStyle,
  useMarqueeChildStyle,
  useMarqueeStyle,
} from './hooks';

import type { ForwardRefRenderFunction, MutableRefObject } from 'react';
import type { MarqueeProps } from './Marquee.types';

const Marquee: ForwardRefRenderFunction<HTMLDivElement, MarqueeProps> = (
  props,
  ref
) => {
  const {
    style = {},
    className,
    autoFill = false,
    play = true,
    pauseOnHover = false,
    pauseOnClick = false,
    direction = 'left',
    speed = 50,
    delay = 0,
    loop = 0,
    gradient = false,
    gradientColor = [255, 255, 255],
    gradientWidth = 200,
    onFinish,
    onCycleComplete,
    children,
  } = props;

  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const rootRef = useRef<HTMLDivElement>(null);
  const containerRef = (ref as MutableRefObject<HTMLDivElement>) ?? rootRef;
  const marqueeRef = useRef<HTMLDivElement>(null);

  // Calculate width of container and marquee and set multiplier
  const calculateWidth = useCallback(() => {
    if (!marqueeRef.current || !containerRef.current) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const marqueeRect = marqueeRef.current.getBoundingClientRect();

    // Swap width and height if direction is vertical
    const isVertical = isUp(direction) || isDown(direction);
    const cWidth = isVertical ? containerRect.height : containerRect.width;
    const mWidth = isVertical ? marqueeRect.height : marqueeRect.width;

    if (autoFill && cWidth && mWidth) {
      setMultiplier(mWidth < cWidth ? Math.ceil(cWidth / mWidth) : 1);
    } else {
      setMultiplier(1);
    }

    setContainerWidth(cWidth);
    setMarqueeWidth(mWidth);
  }, [autoFill, containerRef, direction]);

  // Calculate width and multiplier on mount and on window resize
  useEffect(() => {
    calculateWidth();

    if (!marqueeRef.current || !containerRef.current) {
      return noop;
    }

    const resizeObserver = new ResizeObserver(() => calculateWidth());
    resizeObserver.observe(containerRef.current);
    resizeObserver.observe(marqueeRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateWidth, containerRef]);

  // Recalculate width when children change
  useEffect(() => {
    calculateWidth();
  }, [calculateWidth, children]);

  // Animation duration
  const duration = useMemo(() => {
    if (autoFill) {
      return (marqueeWidth * multiplier) / speed;
    }
    return marqueeWidth < containerWidth
      ? containerWidth / speed
      : marqueeWidth / speed;
  }, [autoFill, containerWidth, marqueeWidth, multiplier, speed]);

  const containerStyle = useContainerStyle({
    autoFill,
    delay,
    direction,
    loop,
    pauseOnClick,
    pauseOnHover,
    play,
    style,
  });

  const gradientStyle = useGradientStyle({ gradientColor, gradientWidth });

  const marqueeStyle = useMarqueeStyle({
    direction,
    duration,
    autoFill,
    delay,
    loop,
    play,
  });

  const childStyle = useMarqueeChildStyle({ direction });

  // Repeat children
  const multiplyChildren = useCallback(
    (length: number) =>
      Array.from(
        { length: Number.isFinite(length) ? length : 0 },
        (_, index) => (
          <Fragment key={index}>
            {Children.map(children, (child) => (
              <div style={childStyle} className="marquee-child">
                {child}
              </div>
            ))}
          </Fragment>
        )
      ),
    [childStyle, children]
  );

  return (
    <div
      ref={containerRef}
      style={containerStyle}
      className={clsx('marquee-container', className)}
    >
      {gradient && <div style={gradientStyle} className="marquee-overlay" />}
      <div
        className="marquee-track"
        style={marqueeStyle}
        onAnimationIteration={onCycleComplete}
        onAnimationEnd={onFinish}
      >
        <div className="marquee-child-container" ref={marqueeRef}>
          {Children.map(children, (child) => (
            <div style={childStyle} className="marquee-child">
              {child}
            </div>
          ))}
        </div>
        {multiplyChildren(multiplier - 1)}
      </div>
      <div className="marquee-track" style={marqueeStyle}>
        {multiplyChildren(multiplier)}
      </div>
    </div>
  );
};

const ExoticMarquee = forwardRef(Marquee);

export { ExoticMarquee as Marquee };
