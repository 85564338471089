import { useQuery } from '@tanstack/react-query';
import { AnalyticsButtonEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { useColors } from '@tectonic/uikit';
import clsx from 'clsx';
import { Button } from '../../components';
import { useActionDispatch } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type { Color, ElemasonCartButtonWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonCartButtonWidgetProps {
  widget: ElemasonCartButtonWidget;
}

const CartButtonWidget: FC<ElemasonCartButtonWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const colors = useColors();
  const [style, className] = useStyleConfigV2(config?.container);
  const dispatch = useActionDispatch();
  const [countContainerStyle, countContainerClassName] = useStyleConfigV2(
    config?.countContainer
  );

  const { data: cartQuantity, isSuccess } = useQuery({
    queryKey: queryKeys.cartQuantity(),
    queryFn: () => remixApi.getCartQuantity(),
  });

  const quantity = Math.min(cartQuantity?.totalQuantity ?? 0, 9);

  const onClick = () => {
    dispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsButtonEventNames.BUTTON_CLICK,
        data: {
          text: data?.button.text,
          startIcon: data?.button.startIcon,
          endIcon: data?.button.endIcon,
        },
      },
    });
    actions?.onClick.forEach((action) => dispatch(action, undefined));
  };

  // Sending config to pass border radius
  return (
    <div style={style} className={clsx('flex', className)}>
      <Button
        onClick={onClick}
        data={data?.button}
        config={config?.button}
        size={config?.button?.size}
        variant={config?.button?.variant}
        modifier={config?.button?.modifier}
        style={{
          borderColor: config?.button?.borderColor,
          borderRadius: config?.button?.borderRadius,
          backgroundColor: config?.button?.backgroundColor,
          color:
            colors[config?.button?.text?.color as Color] ??
            config?.button?.text?.color,
        }}
      />
      {(quantity > 0 || config?.showZeroCount) && isSuccess && (
        <div
          style={countContainerStyle}
          className={clsx('flex', countContainerClassName)}
        >
          <Text
            config={config?.countText}
            data={`${quantity}${cartQuantity?.totalQuantity > 9 ? '+' : ''}`}
          />
        </div>
      )}
    </div>
  );
};

CartButtonWidget.displayName = 'ElemasonCartButtonWidget';

export { CartButtonWidget };
