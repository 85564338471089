import { isHaloScript } from '@tectonic/utils';
import { memo, type FC } from 'react';
import { Money } from '../../components';
import { useAccessorValue } from '../../hooks/useAccessorValue';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonMoneyWidget, Money as IMoney } from '@tectonic/types';

interface ElemasonMoneyWidgetProps {
  widget: ElemasonMoneyWidget;
}

const useMoney = (widget: ElemasonMoneyWidget) => {
  const dMoney = widget.data?.money;
  const isScript = isHaloScript(dMoney ?? {});
  const aMoney = useAccessorValue((isScript ? {} : dMoney) as any);
  const sMoney = useHaloScript((isScript ? dMoney : null) as any);
  return (isScript ? sMoney : aMoney) as IMoney;
};

const MoneyWidget: FC<ElemasonMoneyWidgetProps> = ({ widget }) => {
  const money = useMoney(widget);
  const text = useHaloScript(
    typeof widget.data?.text !== 'string' ? widget.data?.text : undefined
  );
  const placeholders = useHaloScript(widget.data?.placeholders);

  return (
    <Money
      money={money}
      placeholders={placeholders}
      config={widget.config?.text}
      text={typeof widget.data?.text === 'string' ? widget.data?.text : text}
    />
  );
};

const MemoizedMoneyWidget = memo(MoneyWidget);

export { MemoizedMoneyWidget as MoneyWidget };
