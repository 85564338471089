import { isAxiosError, type AxiosError } from 'axios';
import { get, isEmpty, isNil } from 'lodash-es';
import { ClientErrorCodes, ErrorMessages } from './constants';

import type { ServerError } from '@tectonic/types';
import type { ParsedError, ParsedErrorReason } from './typings';

const DEFAULT_MESSAGE = ErrorMessages[ClientErrorCodes.UNKNOWN];

const getReasons = (error: unknown): ParsedErrorReason[] =>
  get(error, 'response.data.error.reasons', []);

const isParsedError = (error: unknown): error is ParsedError => {
  const reasons = getReasons(error);
  return !isEmpty(reasons);
};

const getMessageFromParsedError = (
  error: ParsedError,
  messages?: Record<string, string>,
  defaultMessage?: string
): string => {
  const [{ code, cause }] = getReasons(error);
  if ((cause as any)?.formattedMessage) {
    return (cause as any).formattedMessage;
  }
  return messages?.[code] ?? defaultMessage ?? DEFAULT_MESSAGE;
};

const getMessageFromAxiosError = (
  error: AxiosError,
  messages?: Record<string, string>,
  defaultMessage?: string
) => {
  if (isParsedError(error)) {
    return getMessageFromParsedError(error, messages);
  }

  return error.code
    ? messages?.[error.code] ?? defaultMessage ?? DEFAULT_MESSAGE
    : defaultMessage ?? DEFAULT_MESSAGE;
};

const getErrorMessage = (
  error: unknown,
  messages?: Record<string, string>,
  defaultMessage?: string
) => {
  if (isAxiosError(error)) {
    return getMessageFromAxiosError(error, messages, defaultMessage);
  }

  if (isParsedError(error)) {
    return getMessageFromParsedError(error, messages, defaultMessage);
  }

  // TODO: Check for other types of error here.
  return DEFAULT_MESSAGE;
};

const isServerError = (
  error: AxiosError
): error is AxiosError<ServerError[]> => {
  // As per out contract with backend, the error response will contain an array
  // of ServerError. To conclude if an error is a server error, we rely on
  // error.response.data
  const data = error.response?.data ?? null;
  return !isNil(data) && Array.isArray(data);
};

export { getErrorMessage, isServerError };
