import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { isEmpty } from 'lodash-es';
import { type FC } from 'react';
import {
  AddressList,
  Center,
  ConfirmationDialog,
  Loader,
  RenderBoundary,
} from '../../../components';
import { useAddressList } from './hooks';

import type { AddressListWidgetProps } from './AddressList.types';

const AddressListWidget: FC<AddressListWidgetProps> = ({ widget }) => {
  const wData = widget.data!;
  const { noAddresses } = widget.config ?? {};

  const {
    isLoading,
    isError,
    addresses,
    isDialogOpen,
    onAddressEdit,
    onDialogClose,
    onAddressDelete,
    isDeleting,
    onConfirm,
  } = useAddressList(widget);

  const hasNoAddress = isEmpty(addresses) && !isLoading && !isError;

  const textContainerStyleConfig = useStyleConfig(noAddresses?.container ?? {});

  if (hasNoAddress) {
    return (
      <div {...textContainerStyleConfig}>
        <Text config={noAddresses?.text} data={wData.noAddresses.text} />
      </div>
    );
  }

  return (
    <>
      <RenderBoundary
        isLoading={isLoading}
        hasError={isError}
        loader={
          <Center className="py-20">
            <Loader />
          </Center>
        }
      >
        <AddressList
          addresses={addresses}
          data={widget.data!}
          config={widget.config}
          onAddressDelete={onAddressDelete}
          onAddressEdit={onAddressEdit}
        />
      </RenderBoundary>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        isSubmitting={isDeleting}
        onConfirm={onConfirm}
        data={wData.deleteAlertDialog}
        config={widget.config?.deleteAlertDialog}
      />
    </>
  );
};

AddressListWidget.displayName = 'AddressListWidget';

export { AddressListWidget };
