import { createContext } from '@tectonic/utils';

import type { BasisAttrs } from '../types';

interface AttributeContextType {
  attr: BasisAttrs;
  onSelect: (value: any) => void;
}

const errorMessage =
  'useAttributeContext must be inside a AttributeProvider with a value';

const name = 'AttributeContext';

const [AttributeProvider, useAttributeContext, AttributeContext] =
  createContext<AttributeContextType>({ errorMessage, name });

export { AttributeContext, AttributeProvider, useAttributeContext };
