import { useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  Typography,
  useForm,
} from '@tectonic/uikit';
import { populate } from '@tectonic/utils';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useInterval } from '../../../hooks/timers';
import { Button } from '../../Button';
import { TextField } from '../../TextField';

import type { ForwardRefRenderFunction } from 'react';
import type {
  OtpConfirmationFormProps,
  OtpConfirmationFormRef,
  OtpConfirmationFormValue,
} from './OtpConfirmationForm.types';

const getWaitingPeriod = (time: number) => Math.ceil(time / 1000);

// TODO: Use useReducer if it grows wild.
const useResendCta = (data: OtpConfirmationFormProps['data']) => {
  const { resendCta, otpConfig } = data;
  const [isOver, setIsOver] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(() =>
    getWaitingPeriod(otpConfig.resendOtpTime)
  );
  const text = isOver
    ? resendCta.text
    : populate(`${resendCta.countdownText}`, { countdown });

  const { clear, reset: resetTimer } = useInterval(() => {
    setCountdown(countdown - 1);
  }, 1000);

  const reset = useCallback(() => {
    resetTimer();
    setCountdown(getWaitingPeriod(otpConfig.resendOtpTime));
    setIsOver(false);
  }, [resetTimer, otpConfig]);

  useEffect(() => {
    if (countdown <= 0) {
      clear();
      setIsOver(true);
    }
  }, [clear, countdown, setIsOver]);

  return [text, isOver, reset] as const;
};

const OtpConfirmationForm: ForwardRefRenderFunction<
  OtpConfirmationFormRef,
  OtpConfirmationFormProps
> = (props, formRef) => {
  const { data, isSubmitting, onSubmit, onResend, config } = props;
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  const { control, formState, setError, getValues } =
    useForm<OtpConfirmationFormValue>({
      defaultValues: { code: '' },
      mode: 'onChange',
    });

  const { errors } = formState;

  const [resendCtaText, isComplete, resetTimer] = useResendCta(data);

  const onChange = () => {
    const { code } = getValues();
    const { length } = data.otpConfig;
    if (code?.length !== length) {
      return;
    }
    onSubmit(code);
  };

  useImperativeHandle(formRef, () => ({ setError, resetTimer }), [
    setError,
    resetTimer,
  ]);

  return (
    <Form
      control={control}
      style={containerStyleConfig.style}
      className={clsx('flex flex-col', containerStyleConfig.className)}
      onChange={onChange}
    >
      <div
        className={clsx('flex flex-col gap-2', containerStyleConfig.className)}
      >
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <TextField
              data={data.otpInput.textField}
              config={config?.otpInput?.textField}
              value={field.value}
              type="number"
              className="text-center"
              disabled={isSubmitting}
              onChange={(event) => {
                const { value } = event.target;
                field.onChange(value);
              }}
            />
          )}
        />
        <FormErrorMessage
          name="code"
          errors={errors}
          as={Typography}
          className="text-danger-600"
          variant="body3"
        />
      </div>
      <div className="flex">
        <Button
          isLoading={isSubmitting}
          className="grow"
          type="button"
          onClick={onResend}
          disabled={!isComplete}
          data={{ text: resendCtaText }}
          config={config?.resendCta?.button}
        />
      </div>
    </Form>
  );
};

OtpConfirmationForm.displayName = 'OtpConfirmationForm';
const ExoticForm = forwardRef(OtpConfirmationForm);

export { ExoticForm as OtpConfirmationForm };
