import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Button } from '../../Button';
import { Icon } from '../../Icon';

import type { FC } from 'react';
import type { SearchHistoryHeaderProps } from './SearchHistory.types';

const SearchHistoryHeader: FC<SearchHistoryHeaderProps> = ({
  config,
  data,
  onClear,
}) => {
  const { clearCta, container, title, icon } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  return (
    <div
      style={containerStyleConfig.style}
      className={clsx('flex items-center', containerStyleConfig.className)}
    >
      {data.icon && <Icon config={icon} symbol={data.icon.symbol} />}
      <div className="grow">
        <Text config={title} data={data.title} />
      </div>
      {data.clearCta && (
        <Button
          onClick={() => onClear()}
          config={clearCta?.button}
          data={data.clearCta.button}
        />
      )}
    </div>
  );
};

SearchHistoryHeader.displayName = 'SearchHistoryHeader';

export { SearchHistoryHeader };
