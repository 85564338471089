import { createContext } from '@tectonic/utils';

import type {
  ElemasonWidget,
  NavigationActionPayload,
  User,
} from '@tectonic/types';
import type { ComponentType } from 'react';

interface Path {
  pathname: string;
  search?: string;
  hash?: string;
}

interface NavigateFunction {
  (to: string | Partial<Path>): void;
  (delta: number): void;
}

interface NavigationRegistry {
  getHref: (payload: NavigationActionPayload) => string;
}

interface ElemasonContextType {
  currentUser: User;
  experiments?: {
    athenaVariantInfo?: {
      expVariant?: string;
      defaultVariant?: string;
    };
    athenaRootVariantInfo?: {
      expVariant?: string;
      defaultVariant?: string;
    };
  };
  navigate: NavigateFunction;
  env: Record<string, string>;
  routeParams: Record<string, string>;
  searchParams: Record<string, string>;
  pathname: string;
  navigationRegistry: NavigationRegistry;
  widgets?: Record<
    string,
    ComponentType<{ widget: ElemasonWidget<unknown, unknown, unknown> }>
  >;
}

const errorMessage =
  'useElemasonContext must be inside a ElemasonProvider with a value';

const name = 'ElemasonContext';

const [ElemasonProvider, useElemasonContext] =
  createContext<ElemasonContextType>({
    errorMessage,
    name,
  });

export { ElemasonProvider, useElemasonContext };
