import { createContext } from '@tectonic/utils';

interface ElemasonDrawerContextType {
  slug: string;
  data: unknown;
  close: () => void;
}

const errorMessage =
  'useElemasonDrawerContext must be inside a ElemasonDrawerProvider with a value';
const name = 'ElemasonDrawerContext';

const [ElemasonDrawerProvider, useElemasonDrawerContext] =
  createContext<ElemasonDrawerContextType>({ errorMessage, name });

export { ElemasonDrawerProvider, useElemasonDrawerContext };
