import { useHydrated } from '@tectonic/utils';
import { Drawer } from './Drawer';
import { LoginDrawer } from './LoginDrawer';
import { useDrawers } from './store';

import type { FC } from 'react';

const Drawers: FC = () => {
  const hydrated = useHydrated();
  const { drawers } = useDrawers();

  if (!hydrated) {
    return null;
  }

  return (
    <>
      {drawers.map((drawer, index) => (
        <Drawer
          animationType={drawer.animationType}
          key={drawer.slug}
          slug={drawer.slug}
          data={drawer.data}
          exit={drawer.exit}
          entry={drawer.entry}
          exiting={drawer.exiting}
          isActive={index === drawers.length - 1}
        />
      ))}
      <LoginDrawer />
    </>
  );
};

export { Drawers };
