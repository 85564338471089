import { canUseDOM, withNamespace } from '../helpers';

// TODO: Move cookies related functionality to common package @tectonic/cookies and use it across
// the framework.
const getCookie = (name: string) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(';');

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName.trim() === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null; // Cookie not found
};

const setCookie = (
  name: string,
  value: unknown,
  options: Record<string, string> = {}
) => {
  const { maxAge, path = '/', domain, secure, sameSite = 'Lax' } = options;
  let cookie = `${name}=${encodeURIComponent(JSON.stringify(value))}`;

  if (maxAge) {
    cookie += `;expires=${maxAge}`;
  }

  if (path) {
    cookie += `;path=${path}`;
  }
  if (domain) {
    cookie += `;domain=${domain}`;
  }
  if (secure) {
    cookie += `;secure`;
  }
  if (sameSite) {
    cookie += `;samesite=${sameSite}`;
  }

  document.cookie = cookie;
};

const getValue = (key: string): unknown => {
  if (!canUseDOM) {
    return null;
  }
  try {
    const value = getCookie(withNamespace(key));
    if (typeof value !== 'string') {
      return null;
    }
    return JSON.parse(value);
  } catch (error) {
    //
  }
  return null;
};

const setValue = (
  key: string,
  value: unknown,
  options: Record<string, string> = {}
): unknown => {
  if (!canUseDOM) {
    return null;
  }
  setCookie(withNamespace(key), value, options);
  return null;
};

export { getValue, setValue };
