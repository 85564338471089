import { AnalyticsSearchEventNames } from '@tectonic/analytics';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { ElemasonWidgetActionType } from '@tectonic/types';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from '@tectonic/uikit';
import { toUrlSearchParams } from '@tectonic/utils';
import clsx from 'clsx';
import { omit } from 'lodash-es';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from 'react';
import {
  Loader,
  RenderBoundary,
  SearchFilterForm,
} from '../../../../components';
import { useElemasonContext } from '../../../../contexts';
import { useActionDispatch, useHaloScript } from '../../../../hooks';
import { SearchFilterDrawerFooter } from './SearchFilterDrawerFooter';
import { SearchFilterDrawerHeader } from './SearchFilterDrawerHeader';
import { useSearchFilterDrawer } from './hooks';

import type { SearchFilterFormRef } from '../../../../components';
import type { SearchFilterDrawerProps } from './SearchFilterDrawer.types';

const SearchFilterDrawer: FC<SearchFilterDrawerProps> = ({
  widget,
  isOpen,
  onClose,
}) => {
  const {
    isLoading,
    hasError,
    onApplyFacets,
    onResetFacets,
    facets,
    appliedFilters,
    onPreviewFacets,
  } = useSearchFilterDrawer(widget);
  const wData = widget.data!;
  const widgetConfig = widget.config ?? {};
  const { searchParams } = useElemasonContext();
  const defaultSection = useHaloScript(wData.defaultSection);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [activeFacet, setActiveFacet] = useState(defaultSection ?? '');

  const [overlayStyle, overlayClassName] = useStyleConfigV2(
    widget.config?.content?.overlay ?? {}
  );

  const [contentStyle, contentClassName] = useStyleConfigV2(
    widget.config?.content?.content ?? {}
  );

  const initialFilters = useMemo(() => appliedFilters ?? [], [appliedFilters]);

  const actionDispatch = useActionDispatch();

  const formRef = useRef<SearchFilterFormRef>(null);

  const onFacetToggle = useCallback(
    (key: string) => {
      const filters = formRef.current!.getSearchFilters();
      setActiveFacet(key);
      onPreviewFacets(filters);
    },
    [setActiveFacet, formRef, facets, onPreviewFacets]
  );

  const handleApply = useCallback(async () => {
    const filters = formRef.current!.getSearchFilters();

    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsSearchEventNames.SEARCH_FILTER_APPLY,
        data: {
          filters,
        },
      },
    });

    setIsSearchLoading(true);

    const response = await onApplyFacets(filters);

    if (!response?.executedQuery) {
      return;
    }
    const nSearchParams = toUrlSearchParams({
      ...omit(searchParams, ['page']),
      ...(response.executedQuery as object),
    });

    onClose();
    window.location.search = nSearchParams;
  }, [onApplyFacets, actionDispatch, onClose]);

  const handleReset = useCallback(() => {
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsSearchEventNames.SEARCH_FILTER_RESET,
      },
    });

    onResetFacets();
  }, [onResetFacets, actionDispatch]);

  useEffect(() => {
    setActiveFacet((prevFacet) => {
      const shouldSkip =
        prevFacet && facets.find((facet) => facet.name === prevFacet);
      if (shouldSkip || !facets.length) {
        return prevFacet;
      }
      const auxFacet = facets.find(
        (facet) => facet.static || !!facet.values.length
      );
      return auxFacet?.name ?? '';
    });
  }, [facets, setActiveFacet]);

  return (
    <DialogRoot open={isOpen} onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay
          style={overlayStyle}
          className={clsx(overlayClassName, 'fixed inset-0 z-10')}
        />
        <DialogContent
          style={contentStyle}
          // TODO: take these as config.
          className={clsx(
            contentClassName,
            'bg-white',
            'flex flex-col',
            'h-full w-full',
            'fixed inset-0 z-10'
          )}
        >
          <SearchFilterDrawerHeader
            config={widgetConfig.header}
            data={wData.header}
          />
          <div className="h-0 grow">
            <RenderBoundary
              hasError={hasError}
              isLoading={isLoading || isSearchLoading}
              // TODO: Take loader as config when needed.
              loader={
                <div className="flex h-full items-center">
                  <Loader />
                </div>
              }
            >
              <SearchFilterForm
                ref={formRef}
                config={widgetConfig.content}
                facets={facets}
                initialFilters={initialFilters}
                onFacetToggle={onFacetToggle}
                activeFacet={activeFacet}
              />
            </RenderBoundary>
          </div>
          <SearchFilterDrawerFooter
            data={wData.footer}
            onApply={handleApply}
            onReset={handleReset}
            config={widgetConfig.footer}
            disableControls={hasError || isLoading || isSearchLoading}
          />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
};

SearchFilterDrawer.displayName = 'SearchFilterDrawer';

export { SearchFilterDrawer };
