import { useTimeout } from '@tectonic/utils';
import { memo, type FC } from 'react';
import { useActionDispatch } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { TimeoutWidgetProps } from './Timeout.types';

const TimeoutWidget: FC<TimeoutWidgetProps> = ({ widget }) => {
  const wData = widget.data!;

  const delay = useHaloScript<number>(wData.delay)!;
  const dispatch = useActionDispatch();

  useTimeout(() => {
    widget.actions?.onTimeout?.forEach((action) => dispatch(action));
  }, delay);

  return null;
};

TimeoutWidget.displayName = 'TimeoutWidget';

const MemoizedTimeoutWidget = memo(TimeoutWidget);

export { MemoizedTimeoutWidget as TimeoutWidget };
