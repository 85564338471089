import { useEffect, useState } from 'react';

import type { ProductVariant } from '@tectonic/types';
import type { BasisAttrs } from '../types';

const useSelectedAttributes = (defaultVariant?: ProductVariant) => {
  const [selectedAttributes, setSelectedAttributes] = useState<
    Record<BasisAttrs, any>
  >({
    basisAttr1: defaultVariant?.basisAttr1Value,
    basisAttr2: defaultVariant?.basisAttr2Value,
    basisAttr3: defaultVariant?.basisAttr3Value,
  });

  const setSelectedAttribute = (attr: BasisAttrs, value: any) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [attr]: value,
    }));
  };

  useEffect(() => {
    setSelectedAttributes({
      basisAttr1: defaultVariant?.basisAttr1Value,
      basisAttr2: defaultVariant?.basisAttr2Value,
      basisAttr3: defaultVariant?.basisAttr3Value,
    });
  }, [defaultVariant]);

  return { selectedAttributes, setSelectedAttribute };
};

export { useSelectedAttributes };
