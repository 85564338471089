import { Button } from '../Button';
import { useCarouselContext } from './CarouselContext';

import type { FC } from 'react';
import type { CarouselScrollPrevProps } from './Carousel.types';

const ScrollPrev: FC<CarouselScrollPrevProps> = ({
  isVisible,
  containerStyle,
  containerClassName,
  ...restProps
}) => {
  const { scrollPrev } = useCarouselContext();

  const onClick = () => scrollPrev();

  if (!isVisible) return null;

  return (
    <div style={containerStyle} className={containerClassName}>
      <Button type="button" onClick={onClick} {...restProps} />
    </div>
  );
};

ScrollPrev.displayName = 'CarouselScrollPrev';

export default ScrollPrev;
