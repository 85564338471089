import { useSearchParams } from '@remix-run/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  AnalyticsProductEventNames,
  AnalyticsSearchEventNames,
} from '@tectonic/analytics';
import {
  searchCollectionProducts,
  searchProducts,
} from '@tectonic/remix-client-network';
import { ElemasonWidgetActionType, LocalStateKeys } from '@tectonic/types';
import { useOnWindowScroll, withAdditionalFilters } from '@tectonic/utils';
import { chunk, isEmpty, isNil, keyBy, noop, omit } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useElemasonAnalyticsContext,
  useElemasonContext,
} from '../../../contexts';
import {
  useActionDispatch,
  usePageFragments,
  useSharedLocalState,
} from '../../../hooks';
import {
  useHaloScript,
  useHaloScriptEvaluator,
} from '../../../hooks/useHaloScript';
import {
  useImpressionLedger,
  useProductImpressionPayloadMap,
} from '../../../hooks/useImpressionLedger';
import { queryKeys } from '../../../queryKeys';

import type {
  ElemasonBlock,
  ElemasonCell,
  ElemasonFragment,
  ElemasonProductGridWidget,
  HaloScript,
  Nil,
  Product,
  ProductSearchResponse,
  SearchConfigParams,
  SearchFilter,
  SearchParamsWithExpressions,
} from '@tectonic/types';
import type {
  ImpressionLedger,
  ProductImpressionPayloadMapType,
} from '../../../hooks/useImpressionLedger';

// Take this as widget config.
const PER_PAGE = 20;
const ENTITY_TYPE_PLP = 'PLP';

interface GetTransformedBlocksFunctionArgs {
  widget: ElemasonProductGridWidget;
  products: Product[];
  isLoading: boolean;
  currentView: string;
  impressionLedger?: ImpressionLedger;
  haloScriptEvaluator: ReturnType<typeof useHaloScriptEvaluator>;
  productImpressionPayloadMap?: ProductImpressionPayloadMapType;
  fragments: {
    previousPagination?: ElemasonFragment;
    loader: ElemasonFragment;
    // Record<viewId, ElemasonFragment>
    views: Record<string, ElemasonFragment>;
    others: { shouldRender: HaloScript<boolean>; fragment: ElemasonFragment }[];
  };

  hasPrevious: boolean;
  isFetchingPreviousPage: boolean;
  onLoadPrevious: () => void;
}

const getTransformedBlocks = ({
  products,
  widget,
  isLoading,
  currentView,
  impressionLedger,
  haloScriptEvaluator,
  productImpressionPayloadMap,
  fragments,
  hasPrevious,
  isFetchingPreviousPage,
  onLoadPrevious,
}: GetTransformedBlocksFunctionArgs): ElemasonBlock[] => {
  const wData = widget.data!;
  const gridView =
    wData.views.find((view) => view.viewId === currentView) ?? null;
  if (isNil(gridView)) {
    // looks like data is malformed.
    return [];
  }

  // create chunks based on the number of columns.
  const productChunks = chunk(products, gridView.columns);

  const result: ElemasonBlock[] = [];
  const otherFragmentCounts: Record<string, number> = {};

  let blockIndex = 0;

  if (hasPrevious && fragments.previousPagination) {
    result.push({
      id: 'previous-pagination',
      cells: fragments.previousPagination.cells.map((cell) => ({
        ...cell,
        metadata: {
          ...cell.metadata,
          isLoading: isFetchingPreviousPage,
          onLoadPrevious,
        },
      })),
      metadata: { blockIndex },
    });
    blockIndex += 1;
  }

  productChunks.forEach((pChunk, index) => {
    const cells = pChunk.map<ElemasonCell>((product, pIdIndex) => {
      const fragment = fragments.views[gridView.viewId];
      const cIndex = pIdIndex % fragment.cells.length;
      const cell = fragment.cells[cIndex];
      return {
        ...cell,
        id: product.id,
        metadata: { product, impressionLedger, productImpressionPayloadMap },
      };
    });

    // We need to populated the cells with empty cells in case the data is
    // not enough to fill the number of columns so that rendering of cells
    // doesn't break the overall layout of block. eg. when there are 11 product
    // in a 2 column grid the last row will not be rendered as expected if
    // we don't add empty cells for it.
    while (cells.length < gridView.columns) {
      const fragment = fragments.views[gridView.viewId];
      const cell = fragment.cells[cells.length - 1];
      // TODO: use better predicatable id
      cells.push({
        id: cells.length,
        children: [],
        config: cell?.config ?? { direction: 'column' },
      });
    }

    // Temporary solution to handle the custom fragments. We will have to
    // refactor this to use multi-dimensional cursor based approach.
    const fragmentCells: ElemasonCell[] = [];

    fragments.others.forEach(({ shouldRender, fragment }) => {
      const selfIndex =
        otherFragmentCounts[fragment.slug] !== undefined
          ? otherFragmentCounts[fragment.slug] + 1
          : 0;

      if (
        haloScriptEvaluator(shouldRender, {
          selfIndex,
          blockIndex,
          chunkIndex: index,
          gridSize: gridView.columns,
          remainingChunks: productChunks.length - index,
        })
      ) {
        otherFragmentCounts[fragment.slug] = selfIndex;
        fragment.cells.forEach((cell) => {
          fragmentCells.push({ ...cell, metadata: { selfIndex } });
        });
      }
    });

    if (fragmentCells.length > 0) {
      result.push({
        id: blockIndex,
        cells: fragmentCells,
        metadata: {
          blockIndex,
          chunkIndex: index,
          gridSize: gridView.columns,
        },
      });

      blockIndex += 1;
    }

    result.push({
      // TODO: use better predictable id
      cells,
      id: blockIndex,
      // TODO: Add config for block when we have dedicate page
      // config: gridBlock.config,
      metadata: { blockIndex, layoutType: gridView.viewId },
    });

    blockIndex += 1;
  });

  // TODO: Add promotional banners and widget here.

  const hasLoader = isLoading && !isEmpty(wData.loader);

  const loaderBlocks = hasLoader
    ? [
        {
          ...fragments.loader,
          // TODO: Add config for block when we have dedicate page
          // config: gridBlock.config,
          //   config: gridBlock.config,
        },
      ]
    : [];

  return [...result, ...loaderBlocks];
};

const useListItemJsonLD = ({
  result,
}: {
  result: ProductSearchResponse | null;
}) => {
  const isGeneratedRef = useRef<boolean>(false);

  useEffect(() => {
    if (isGeneratedRef.current || isEmpty(result?.hits)) {
      return noop;
    }
    const products = result!.hits;
    const itemListElement = products.map((product, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: product.title,
      url: `${globalThis.location.origin}/products/${product.slug}`,
    }));
    const ldJson = {
      '@context': 'https://schema.org/',
      '@type': 'ItemList',
      itemListElement,
    };
    // create ldJson tag in document head.
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(ldJson);
    document.head.appendChild(script);
    isGeneratedRef.current = true;
    return () => {
      script.remove();
    };
  }, [result]);
};

const useGridFragments = (
  widget: ElemasonProductGridWidget
): GetTransformedBlocksFunctionArgs['fragments'] => {
  const wData = widget.data!;
  const allFragments = usePageFragments();
  return useMemo(() => {
    const fMap = keyBy(allFragments, 'slug');
    const views: Record<string, ElemasonFragment> = {};
    const others: {
      shouldRender: HaloScript<boolean>;
      fragment: ElemasonFragment;
    }[] = [];
    // Access fragments for views and loader.
    wData.views.forEach((view) => {
      views[view.viewId] = fMap[view.row.fragment];
    });
    wData.customFragments?.forEach(({ fragment, shouldRender }) => {
      others.push({ shouldRender, fragment: fMap[fragment] });
    });
    return {
      loader: fMap[wData.loader.fragment],
      views,
      others,
      previousPagination: wData.previousPagination
        ? fMap[wData.previousPagination.fragment]
        : undefined,
    };
  }, [allFragments]);
};

const useTransformedBlocks = ({
  widget,
  products,
  isLoading,
  currentView,
  productImpressionPayloadMap,
  impressionLedger,
  hasPrevious,
  isFetchingPreviousPage,
  onLoadPrevious,
}: Omit<
  GetTransformedBlocksFunctionArgs,
  'fragments' | 'haloScriptEvaluator'
>) => {
  const fragments = useGridFragments(widget);
  const haloScriptEvaluator = useHaloScriptEvaluator();

  return useMemo(
    () =>
      getTransformedBlocks({
        products,
        widget,
        isLoading,
        currentView,
        fragments,
        haloScriptEvaluator,
        productImpressionPayloadMap,
        impressionLedger,
        isFetchingPreviousPage,
        hasPrevious,
        onLoadPrevious,
      }),
    [
      products,
      widget,
      isLoading,
      currentView,
      fragments,
      isFetchingPreviousPage,
      hasPrevious,
      onLoadPrevious,
    ]
  );
};

const getNextPageParam = (response: ProductSearchResponse): Nil<number> => {
  const { page, found } = response;
  const count = page * PER_PAGE;
  return count < found ? page + 1 : undefined;
};

const getPreviousPageParam = (
  firstPage: ProductSearchResponse
): Nil<number> => {
  const { page } = firstPage;
  return page > 1 ? page - 1 : undefined;
};

const getProducts = (
  params: Partial<SearchParamsWithExpressions>,
  searchConfig?: SearchConfigParams
) => {
  const { entity, entityIdentifier } = searchConfig ?? {};
  if (entity === 'COLLECTION' && entityIdentifier) {
    return searchCollectionProducts(entityIdentifier, params);
  }
  return searchProducts(params);
};

const searchQueryFn = async (
  params: Partial<SearchParamsWithExpressions>,
  entity: SearchConfigParams['entity'],
  entityIdentifier?: string,
  implicitFilters: SearchFilter[] = []
): Promise<ProductSearchResponse> => {
  // TODO: Leverage widget config and make search specific.
  const response = await getProducts(
    {
      ...params,
      filterExpressions: withAdditionalFilters(
        params.filterExpressions ?? [],
        implicitFilters ?? []
      ),
    },
    { entity, entityIdentifier }
  );
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data;
};

const useProductGrid = (
  widget: ElemasonProductGridWidget,
  productSearchResponse?: ProductSearchResponse,
  implicitFilters?: SearchFilter[]
) => {
  const wData = widget.data!;
  const currentView = useHaloScript(wData.currentView);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsRef = useRef(Object.fromEntries(searchParams));
  const [currentPageNumber, setCurrentPageNumber] = useState<number>();
  const { routeParams } = useElemasonContext();
  let isInfiniteScrollEnabled = useHaloScript(wData.isInfiniteScrollEnabled);
  isInfiniteScrollEnabled = isInfiniteScrollEnabled ?? true;
  const initialPageNumberRef = useRef<number>(+(searchParams.get('page') ?? 1));

  const actionDispatch = useActionDispatch();

  const entity = wData.searchConfig?.entity ?? ENTITY_TYPE_PLP;
  const entityIdentifier = useHaloScript(wData.searchConfig?.entityIdentifier);
  const ref = useRef<HTMLDivElement>(null);

  const {
    data,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    hasNextPage: hasMore,
    hasPreviousPage: hasPrevious,
    fetchNextPage: onLoadMore,
    fetchPreviousPage: onLoadPrevious,
  } = useInfiniteQuery<ProductSearchResponse>({
    getNextPageParam,
    getPreviousPageParam,
    initialData: () => {
      if (
        productSearchResponse &&
        productSearchResponse.page === initialPageNumberRef.current
      ) {
        return {
          pageParams: [productSearchResponse.page],
          pages: [productSearchResponse],
        };
      }
      return undefined;
    },
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    // queryKey should be same when doing back navigation
    // queryKey should be different when coming from other pages
    queryKey: queryKeys.productInfiniteSearch(
      routeParams,
      omit(searchParamsRef.current, ['page'])
    ),
    queryFn: ({ pageParam }) => {
      const fPageParam = pageParam ?? searchParams.get('pageParam') ?? 1;
      const payload = {
        perPage: PER_PAGE,
        ...Object.fromEntries(searchParams),
        page: +fPageParam,
      };
      setCurrentPageNumber(+fPageParam);
      return searchQueryFn(payload, entity, entityIdentifier, implicitFilters);
    },
    initialPageParam: initialPageNumberRef.current,
  });

  // console.log(
  //   data,
  //   productSearchResponse?.page,
  //   queryKeys.productInfiniteSearch(
  //     routeParams,
  //     omit(searchParamsRef.current, ['page'])
  //   )
  // );

  const [firstPage] = data?.pages ?? [];
  const totalProducts = firstPage?.found ?? 0;

  const isLoading = isFetching || isFetchingNextPage;

  const products = useMemo(() => {
    const { pages = [] } = data ?? {};
    return pages.flatMap((page) => page?.hits);
  }, [data?.pages]);

  const analyticsContext = useElemasonAnalyticsContext();

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );

  const productImpressionPayloadMap = useProductImpressionPayloadMap(
    products,
    {
      ...Object.fromEntries(searchParams),
      totalProducts: totalProducts.toString(),
    },
    PER_PAGE
  );

  const blocks = useTransformedBlocks({
    widget,
    products,
    isLoading,
    currentView: currentView!,
    productImpressionPayloadMap,
    impressionLedger,
    hasPrevious,
    isFetchingPreviousPage,
    onLoadPrevious,
  });

  const { setSharedState } = useSharedLocalState<number>(
    LocalStateKeys.TOTAL_PRODUCTS,
    totalProducts
  );

  useOnWindowScroll(
    useCallback(() => {
      if (!ref.current || !isInfiniteScrollEnabled) {
        return;
      }
      const rect = ref.current.getBoundingClientRect();
      const delta = Math.abs(rect.bottom - window.innerHeight);
      if (!(delta < 3000) || isLoading || !hasMore) {
        return;
      }

      onLoadMore();
    }, [onLoadMore, isLoading, hasMore, ref, isInfiniteScrollEnabled]),
    100
  );

  useEffect(() => {
    setSharedState(totalProducts);
  }, [totalProducts, setSharedState]);

  const isPageTrackedRef = useRef<number>(-1);

  useEffect(() => {
    if (
      !(
        isNil(currentPageNumber) ||
        isNil(totalProducts) ||
        entity !== ENTITY_TYPE_PLP ||
        isPageTrackedRef.current === currentPageNumber
      )
    ) {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsSearchEventNames.SEARCH_RESULTS_VIEW,
          data: {
            page: currentPageNumber,
            totalResultCount: totalProducts,
            querySource: {
              query: Object.fromEntries(searchParams),
            },
          },
        },
      });
      isPageTrackedRef.current = currentPageNumber;
    }
  }, [
    currentPageNumber,
    totalProducts,
    entity,
    actionDispatch,
    isPageTrackedRef,
  ]);

  useEffect(() => {
    if (!currentPageNumber) return;
    if (searchParams.get('page') === currentPageNumber.toString()) return;

    const newParams = new URLSearchParams(Object.fromEntries(searchParams));
    newParams.set('page', `${currentPageNumber}`);
    setSearchParams(newParams, {
      replace: true,
      preventScrollReset: true,
    });
  }, [currentPageNumber]);

  useListItemJsonLD({ result: firstPage });

  return { blocks, isLoading, ref };
};

export { useProductGrid };
