import { useMutation } from '@tanstack/react-query';
import {
  AnalyticsCartEventNames,
  DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT,
  getAnalyticsEventAttributes,
  trackCartEvent,
} from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { script } from '@tectonic/halo-script';
import { Logger } from '@tectonic/logger';
import { access } from '@tectonic/utils';
import { Button } from '../../../components';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { enrichAnalyticsPayloadWithWidgetData } from '../../../utils';

import type { AnalyticsCartEventPayload } from '@tectonic/analytics';
import type {
  Cart,
  CartAnalyticsAttribute,
  ElemasonCheckoutWidget,
} from '@tectonic/types';
import type { FC } from 'react';

const IS_ANONYMOUS_ACCESSOR = script([
  access(['page', 'currentUser', 'isAnonymous']),
]);

interface ElemasonCheckoutWidgetProps {
  widget: ElemasonCheckoutWidget;
}

const CheckoutWidget: FC<ElemasonCheckoutWidgetProps> = ({ widget }) => {
  const { data, config } = widget;
  const { showToast } = useToast();
  const isAnonymous = useHaloScript<boolean>(IS_ANONYMOUS_ACCESSOR);
  const cart = useHaloScript<Cart>(data!.cart);
  const addressId = useHaloScript<number>(data?.addressId);

  const mutation = useMutation({
    mutationFn: async () => {
      let attributes: CartAnalyticsAttribute[] = [];
      try {
        attributes = await getAnalyticsEventAttributes(
          DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT
        );
      } catch (error) {
        Logger.error('Error in fetching cart attributes ', error);
      }
      // TODO we should ideally get this from some config or encoded in widget itself
      attributes.push({
        key: 'checkoutPartner',
        value: 'SHOPIFY',
      } as CartAnalyticsAttribute);
      // Logger.info("Finalise client attributes", attributes)
      return remixApi.finalizeCart({ attributes, addressId });
    },
    onSuccess: (nCart: Cart) => {
      trackCartEvent(
        AnalyticsCartEventNames.CHECKOUT_BEGIN,
        enrichAnalyticsPayloadWithWidgetData<AnalyticsCartEventPayload>(
          { cart: nCart, isGuest: isAnonymous, isExpress: false },
          widget
        ),
        true
      );
      window.open(nCart.checkoutUrl, '_self');
    },
    onError: (error) => {
      const title = getErrorMessage(error, {}, 'Unable to proceed to buy');
      Logger.error('Unable to proceed to buy', error);
      showToast({ title });
    },
  });

  const onClick = () => {
    mutation.mutate();
    trackCartEvent(
      AnalyticsCartEventNames.CHECKOUT_BEGIN_BUTTON_CLICK,
      enrichAnalyticsPayloadWithWidgetData<AnalyticsCartEventPayload>(
        {
          cart,
          isGuest: isAnonymous,
          isExpress: false,
          text: data?.button.text,
        },
        widget
      ),
      true
    );
  };

  return (
    <Button
      data={data?.button}
      onClick={onClick}
      config={config?.button}
      size={config?.button?.size}
      isLoading={mutation.isPending}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

export { CheckoutWidget };
