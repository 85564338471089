import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Button } from '../../Button';

import type { ForwardRefRenderFunction } from 'react';
import type { SelectMenuHeaderProps } from './SelectMenu.types';

const SelectMenuHeader: ForwardRefRenderFunction<
  HTMLDivElement,
  SelectMenuHeaderProps
> = ({ className, config, data, onClose }, ref) => {
  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  return (
    <div
      ref={ref}
      style={containerStyleConfig.style}
      className={clsx(
        'flex items-center justify-between',
        containerStyleConfig.className,
        className
      )}
    >
      <Text data={data.title} config={config?.title?.text} />
      <Button
        config={config?.closeCta?.button}
        data={data.closeCta.button}
        onClick={onClose}
      />
    </div>
  );
};

const ExoticSelectMenuHeader = forwardRef(SelectMenuHeader);

SelectMenuHeader.displayName = 'SelectMenuHeader';

export { ExoticSelectMenuHeader as SelectMenuHeader };
