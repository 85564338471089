import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as FingerprintJSPro from '@fingerprintjs/fingerprintjs-pro';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import { LocalStorage } from '@tectonic/storage';
import { isNil } from 'lodash-es';
import { withTimeout } from '../analytics/utils';
import { setCookie } from '../helpers';

const initializeFingerprintV3 = () => FingerprintJS.load({ monitoring: false });

const initializeFingerprintPro = (key: string, region: string) =>
  FingerprintJSPro.load({
    apiKey: key,
    region: region as FingerprintJSPro.Region
  });

const initializeFingerprintProProxy = (key: string, region: string) =>
  FingerprintJSPro.load({
    apiKey: key,
    region: region as FingerprintJSPro.Region,
    endpoint: [
      "https://sauron.tectonic.so",
      FingerprintJSPro.defaultEndpoint
    ],
    scriptUrlPattern: [
      "https://sauron.tectonic.so/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
      FingerprintJSPro.defaultScriptUrlPattern
    ],
  });

const generateV0Fp = async () => {
  const fp = await getCurrentBrowserFingerPrint();
  return !isNil(fp) ? String(fp) : fp;
};

const getFingerPrintV0 = async () => {
  try {
    let fp = LocalStorage.getItem('ttClientFpV0');
    if (isNil(fp)) {
      fp = await generateV0Fp();
    }
    return { ttClientFpV0: fp };
  } catch (err) {
    // log to sentry
  }
  return {};
};

const getFingerPrintV3 = async () => {
  try {
    const result: any = LocalStorage.getItem('fingerPrintV3') ?? {};
    const fp = result.visitorId;
    setCookie('__tt_client_fp', fp, 30);
    return { ttClientFp: fp };
  } catch (err) {
    // log to sentry
  }
  return {};
};

const getFingerPrintPro = async () => {
  try {
    const result: any = LocalStorage.getItem("fingerPrintPro") ?? {};
    const fp = result.visitorId
    return { "ttClientFpPro": fp, "ttClientFpProInfo": result ?? {} }
  } catch (err) {
    // log to sentry
  }
  return {}
}

const getBrowserFingerPrint = async () => {
  const data = await Promise.allSettled([
    withTimeout<{}>(getFingerPrintV0(), {
      message: 'getFingerPrintV0 timeout',
      duration: 500,
    }),
    withTimeout<{}>(getFingerPrintV3(), {
      message: 'getFingerPrintV3 timeout',
      duration: 500,
    }),
    withTimeout<{}>(
      getFingerPrintPro(), {
      message: 'getFingerPrintPro timeout',
      duration: 500,
    }),
  ]);

  return Object.assign(
    {},
    ...data.map((prom) => (prom.status === 'fulfilled' ? prom.value : {}))
  );
};

export {
  generateV0Fp,
  getBrowserFingerPrint,
  initializeFingerprintPro,
  initializeFingerprintProProxy,
  initializeFingerprintV3
};

