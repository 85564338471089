import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { useMemo, type FC } from 'react';
import { Button } from '../../Button';

import type { Address } from '@tectonic/types';
import type { AddressCardProps } from './AddressCard.types';

const usePlaceholders = (address: Address) =>
  useMemo(() => {
    // const placeholders = address as unknown as Record<string, string>;

    const keys: (keyof Address)[] = [
      'name',
      'phone',
      'address1',
      'address2',
      'city',
      'province',
      'zip',
    ];

    const placeholders: Record<string, string> = {};

    keys.forEach((key) => {
      const value = `${(address[key] as string) ?? ''}`.trim();
      placeholders[key] = value;
    });

    return placeholders;
  }, [address]);

const AddressCard: FC<AddressCardProps> = ({
  address,
  data,
  onAddressDelete,
  onAddressEdit,
  config,
}) => {
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  const ctaContainerStyleConfig = useStyleConfig(config?.ctaContainer ?? {});
  const placeholders = usePlaceholders(address);

  return (
    <div
      style={containerStyleConfig.style}
      className={clsx('flex flex-col', containerStyleConfig.className)}
    >
      <Text
        data={data.title.text}
        placeholders={placeholders}
        config={config?.title?.text}
      />
      <Text
        data={data.subtitle.text}
        placeholders={placeholders}
        config={config?.subtitle?.text}
      />
      <Text
        data={data.summary.text}
        placeholders={placeholders}
        config={config?.summary?.text}
      />
      <div
        className={clsx(
          'flex justify-between',
          ctaContainerStyleConfig.className
        )}
        style={ctaContainerStyleConfig.style}
      >
        <Button
          onClick={() => onAddressEdit(address)}
          config={config?.editCta?.button}
          data={data.editCta.button}
        />
        <Button
          onClick={() => onAddressDelete(address)}
          config={config?.deleteCta?.button}
          data={data.deleteCta.button}
        />
      </div>
    </div>
  );
};

AddressCard.displayName = 'AddressCard';

export { AddressCard };
