import { isEmpty, isNil } from 'lodash-es';
import { useEffect, useRef } from 'react';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
  useElemasonPageContext,
} from '../../contexts';
import { ActionDispatchExporter } from '../../core';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonProductViewWidget, Product } from '@tectonic/types';
import type { FC } from 'react';
import type { DispatchExporterHandle } from '../../core';

interface ElemasonProductViewWidgetProps {
  widget: ElemasonProductViewWidget;
}

const ProductViewWidget: FC<ElemasonProductViewWidgetProps> = ({
  widget: { data, actions },
}) => {
  const product = useHaloScript<Product>(data?.product);
  const analyticsContext = useElemasonAnalyticsContext();
  const actionDispatchRef = useRef<DispatchExporterHandle>(null);
  const isTrackedRef = useRef<boolean>(false);
  const { isReady } = useElemasonPageContext();

  useEffect(() => {
    const actionDispatch = actionDispatchRef.current?.dispatch;
    if (
      isEmpty(actions?.onMount) ||
      isNil(actionDispatch) ||
      isTrackedRef.current ||
      !isReady
    ) {
      return;
    }
    actions?.onMount.forEach((action) => {
      actionDispatch(action);
      isTrackedRef.current = true;
    });
  }, [actions, isReady, actionDispatchRef.current, isTrackedRef.current]); // For some reason needed the current

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: {
          ...analyticsContext.entities,
          product: product ?? undefined,
        },
      }}
    >
      <ActionDispatchExporter ref={actionDispatchRef} />
    </ElemasonAnalyticsProvider>
  );
};

export { ProductViewWidget };
