import { useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonComponentType } from '@tectonic/types';
import clsx from 'clsx';
import { merge, sortBy } from 'lodash-es';
import { Fragment, useMemo } from 'react';
import { useElemasonComponentConfig } from '../../../hooks/useElemasonConfig';
import {
  getDefaultDiscountConfig,
  getDefaultMrpConfig,
  getDefaultPriceConfig,
  getDiscount,
} from '../../../utils/product';
import { ProductDiscount } from './ProductDiscount';
import { ProductMrp } from './ProductMrp';
import { ProductPrice } from './ProductPrice';

import type { FC } from 'react';
import type { ProductPricingProps } from './ProductPricing.types';

const usePrices = ({ variant, config, quantity }: ProductPricingProps) =>
  useMemo(() => {
    const discount = getDiscount(variant, config?.discount, quantity);
    const hasDiscount = !!discount;
    const priceConfig = merge(
      getDefaultPriceConfig(hasDiscount),
      config?.price
    );
    const mrpConfig = merge(getDefaultMrpConfig(hasDiscount), config?.mrp);
    const discountConfig = merge(getDefaultDiscountConfig(), config?.discount);
    const prices = [
      {
        displaySequenceIndex: priceConfig.displaySequenceIndex,
        content: (
          <ProductPrice
            quantity={quantity}
            config={priceConfig}
            price={variant.price}
          />
        ),
      },
    ];

    if (hasDiscount) {
      prices.push({
        displaySequenceIndex: mrpConfig.displaySequenceIndex,
        content: (
          <ProductMrp
            mrp={variant.mrp}
            config={mrpConfig}
            quantity={quantity}
          />
        ),
      });

      prices.push({
        displaySequenceIndex: discountConfig.displaySequenceIndex,
        content: (
          <ProductDiscount
            discount={discount}
            quantity={quantity}
            config={discountConfig}
          />
        ),
      });
    }
    return sortBy(prices, (value) => value.displaySequenceIndex);
  }, [variant, config]);

const ProductPricing: FC<ProductPricingProps> = ({
  config,
  variant,
  quantity,
}) => {
  const productPricingConfig = useElemasonComponentConfig(
    ElemasonComponentType.ProductPricing,
    config
  );
  const { style, className } = useStyleConfig(
    productPricingConfig?.container ?? {}
  );
  const prices = usePrices({ config: productPricingConfig, variant, quantity });

  return (
    <div className={clsx('flex flex-row', className)} style={style}>
      {prices.map((price, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>{price.content}</Fragment>
      ))}
    </div>
  );
};

ProductPricing.displayName = 'ElemasonProductPricing';

export { ProductPricing, usePrices };
