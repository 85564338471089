enum AnalyticsAuthEventNames {
  SIGN_IN_REQUEST = 'Sign In Request',
  SIGN_IN_SUCCESS = 'Sign In Success',
  SIGN_IN_ERROR = 'Sign In Error',
  SIGN_UP_REQUEST = 'Sign Up Request',
  SIGN_UP_SUCCESS = 'Sign Up Success',
  SIGN_UP_ERROR = 'Sign Up Error',
  SIGN_OUT = 'Sign Out Request',

  SIGN_IN_SEND_OTP_REQUEST = 'Sign In Send OTP Request',
  SIGN_IN_SEND_OTP_SUCCESS = 'Sign In Send OTP Success',
  SIGN_IN_SEND_OTP_ERROR = 'Sign In Send OTP Error',

  SIGN_UP_SEND_OTP_REQUEST = 'Sign Up Send OTP Request',
  SIGN_UP_SEND_OTP_SUCCESS = 'Sign Up Send OTP Success',
  SIGN_UP_SEND_OTP_ERROR = 'Sign Up Send OTP Error',

  OTP_RESEND_REQUEST = 'OTP Resend Request',
  // TODO: Fix typo.
  OTP_RESEND_SUCESS = 'OTP Resend Success',
  OTP_RESEND_ERROR = 'OTP Resend Error',

  OTP_VERIFY_REQUEST = 'OTP Verify Request',
  OTP_VERIFY_SUCCESS = 'OTP Verify Success',
  OTP_VERIFY_ERROR = 'OTP Verify Error',
}

enum AnalyticsAuthMode {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL_OTP = 'emailOtp',
}

enum AnalyticAuthType {
  SIGN_IN = 'signIn',
  SIGN_UP = 'singUp',
}

export { AnalyticAuthType, AnalyticsAuthEventNames, AnalyticsAuthMode };
