import { AnalyticsButtonEventNames } from '@tectonic/analytics';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { useCallback, useState } from 'react';
import { Button } from '../../../components';
import { useActionDispatch, useHaloScript } from '../../../hooks';
import { SearchFilterDrawer } from './SearchFilterDrawer';

import type { FC } from 'react';
import type { SearchFilterWidgetProps } from './SearchFilter.types';

const SearchFilterWidget: FC<SearchFilterWidgetProps> = ({ widget }) => {
  const wData = widget.data!;

  const dispatch = useActionDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const placeholders = useHaloScript(wData.trigger.button.placeholders);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onOpen = () => {
    setIsOpen(true);
    dispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsButtonEventNames.BUTTON_CLICK,
        data: {
          text: wData.trigger.button.text,
          placeholder: wData.trigger.button.placeholders,
        },
      },
    });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        data={wData.trigger.button}
        placeholders={placeholders}
        config={widget.config?.trigger?.button}
      />
      {isOpen && (
        <SearchFilterDrawer isOpen={isOpen} onClose={onClose} widget={widget} />
      )}
    </>
  );
};

SearchFilterWidget.displayName = 'SearchFilterWidget';

export { SearchFilterWidget };
