import { useStyleConfig } from '@tectonic/elemason-components';
import { AddressCard } from '../AddressCard';

import type { FC } from 'react';
import type { AddressListProps } from './AddressList.types';

const AddressList: FC<AddressListProps> = ({
  addresses,
  data,
  onAddressDelete,
  onAddressEdit,
  config,
}) => {
  const containerConfig = useStyleConfig(config?.container ?? {});

  return (
    <ul {...containerConfig}>
      {addresses.map((address) => (
        <li key={address.id}>
          <AddressCard
            address={address}
            data={data.addressCard}
            config={config?.addressCard}
            onAddressDelete={onAddressDelete}
            onAddressEdit={onAddressEdit}
          />
        </li>
      ))}
    </ul>
  );
};

AddressList.displayName = 'AddressList';

export { AddressList };
