import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import { ElemasonWidgetActionType, LocalStateKeys } from '@tectonic/types';
import clsx from 'clsx';
import { useEffect, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button, Loader } from '../../components';
import { useElemasonPageContext } from '../../contexts';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { useToast } from '../../core/ElemasonEntry/Toast';
import {
  useActionDispatch,
  usePageFragment,
  useSharedLocalState,
} from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useRemoveProductFromWishlistMutation } from '../../hooks/wishlist';
import { queryKeys } from '../../queryKeys';
import { isDefaultVariant } from '../../utils';

import type { InfiniteData } from '@tanstack/react-query';
import type {
  Cart,
  ElemasonWishlistItemsWidget,
  Product,
  ProductSearchResponse,
  ProductVariant,
} from '@tectonic/types';

const PAGE_SIZE = 10;
const STATE_KEY = LocalStateKeys.WISHLIST;

interface CallbackResponse {
  success?: Cart;
  error?: Error;
}

interface ElemasonWishlistItemsWidgetProps {
  widget: ElemasonWishlistItemsWidget;
}

const WishlistItemsWidget: FC<ElemasonWishlistItemsWidgetProps> = ({
  widget,
}) => {
  const { data, config } = widget;
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const {
    // @ts-ignore
    data: { wishlist: initialData },
  } = useElemasonPageContext();
  const actionDispatch = useActionDispatch();
  const fragment = usePageFragment(data?.productFragment);
  const [style, className] = useStyleConfigV2(config?.container);
  const [itemStyle, itemClassName] = useStyleConfigV2(config?.items);
  const [
    removeFromWishlistContainerStyle,
    removeFromWishlistContainerClassName,
  ] = useStyleConfigV2(config?.removeFromWishlistContainer);

  const {
    isFetching,
    data: wishlist,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: queryKeys.wishlist(),
    initialData: {
      pages: [initialData as ProductSearchResponse],
      pageParams: [1],
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam }) =>
      remixApi.getWishlistedProducts(pageParam, 10),
    getNextPageParam: (lastPage, allPages, _lastPageParam) => {
      if (allPages.length * PAGE_SIZE >= lastPage.found) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  const removeProductFromoWishlistMutation =
    useRemoveProductFromWishlistMutation();

  const addToCartMutation = useMutation({
    mutationFn: ({
      product: _product,
      variant,
    }: {
      product: Product;
      variant: ProductVariant;
    }) => remixApi.addToCart([{ quantity: 1, variantId: variant.id }]),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (_cart, { product }) => {
      removeProductFromoWishlistMutation.mutate({ product });
      showToast({ title: 'Product added to cart' });
    },
    onError: (e) => {
      const title = getErrorMessage(e, {}, 'Failed to add product to cart');
      Logger.error(e);
      showToast({ title });
    },
  });

  const { setSharedState } = useSharedLocalState<
    InfiniteData<ProductSearchResponse>
  >(STATE_KEY, wishlist);

  useEffect(() => {
    setSharedState(wishlist);
    return () => setSharedState(undefined);
  }, [wishlist, setSharedState]);

  const handleVariantSelect = (variant: ProductVariant) => {
    const product = wishlist?.pages
      ?.reduce((acc, curr) => [...acc, ...(curr?.hits ?? [])], [] as Product[])
      ?.find((p) => p.variants?.some((v) => v.id === variant.id));
    addToCartMutation.mutate({ product: product!, variant });
  };

  const callback = (
    product: Product,
    _variant: ProductVariant,
    response: CallbackResponse
  ) => {
    if (response.success) {
      removeProductFromoWishlistMutation.mutate({ product });
      actionDispatch({
        type: ElemasonWidgetActionType.GLOBAL_DRAWER_CLOSE,
        payload: {
          slug: 'wishlist-variant-selector',
        },
      });
    }
  };

  const onClickAddToBag = (product: Product) => {
    if (product.variants && isDefaultVariant(product.variants?.[0])) {
      handleVariantSelect(product.variants?.[0]);
    } else {
      actionDispatch({
        type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
        payload: {
          slug: 'wishlist-variant-selector',
          data: {
            product,
            preSelectedVariant:
              product.variants?.find(
                (variant) => variant.isCurrentlySelected
              ) ?? null,
            // TODO refactor using a better approach
            // Ideas:
            // 1. use a global state to store the variant (How do we handle multiple products?)
            // 2. use a dedicated widget for wishlist control
            callback,
          },
          // entry: 'bottom',
          // exit: 'bottom',
        },
      });
    }
  };

  const onClickRemoveFromWishlist = (product: Product) => {
    removeProductFromoWishlistMutation.mutate({ product });
  };

  const handleInViewChange = (inView: boolean) =>
    inView && hasNextPage && fetchNextPage();

  const [inViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: handleInViewChange,
  });

  const wishlistItems = wishlist?.pages?.reduce(
    (acc, curr) => [...acc, ...curr.hits],
    [] as Product[]
  );

  const fragmentValue = useFragmentValue(fragment);

  if (!fragment) return null;

  return (
    <>
      <div
        style={{
          ...style,
          gridTemplateColumns: `repeat(${config?.gridColumns ?? 2}, 1fr)`,
        }}
        className={clsx(
          className,
          'w-full flex-row overflow-auto',
          config?.list?.mode === 'grid'
            ? `grid grid-cols-${config?.list?.cols ?? 2}`
            : 'flex'
        )}
      >
        {wishlistItems?.map((product) => (
          <div
            style={itemStyle}
            key={product.id}
            className={clsx(itemClassName, 'relative flex flex-col')}
          >
            <ElemasonFragmentProvider value={fragmentValue({ product })}>
              {fragment?.cells.map((cell, index) => (
                <Cell key={cell.id ?? index} cell={cell} />
              ))}
            </ElemasonFragmentProvider>
            <Button
              data={data?.addToCartButton}
              config={config?.addToCartButton}
              isLoading={
                addToCartMutation.variables
                  ? product.variants
                      ?.map((v) => v.id)
                      .includes(addToCartMutation.variables?.variant.id)
                  : false
              }
              onClick={() => onClickAddToBag(product)}
            />
            <div
              style={removeFromWishlistContainerStyle}
              className={removeFromWishlistContainerClassName}
            >
              <Button
                data={data?.removeFromWishlistButton}
                config={config?.removeFromWishlistButton}
                onClick={() => onClickRemoveFromWishlist(product)}
                isLoading={
                  removeProductFromoWishlistMutation.variables?.product.id ===
                  product.id
                }
              />
            </div>
          </div>
        ))}
        <div ref={inViewRef} />
      </div>
      {isFetching && <Loader className="p-4" />}
    </>
  );
};

export { WishlistItemsWidget };
