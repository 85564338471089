import { useStyleConfig } from '@tectonic/elemason-components';
import { useController } from '@tectonic/uikit';
import { useMemo, type FC } from 'react';
import { CheckoutFacetAttribute } from './CheckboxFacetAttribute';

import type { CheckboxFacetProps } from './CheckboxFacet.types';

const defaultValue: string[] = [];

const CheckboxFacet: FC<CheckboxFacetProps> = ({
  facet,
  config,
  ...restProps
}) => {
  const { field } = useController(restProps);
  const fValue = field.value ?? defaultValue;
  const vSet = useMemo(
    () => new Set(Array.isArray(fValue) ? fValue : [fValue]),
    [fValue]
  );

  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  const onCheckedChange = (name: string, checked: boolean) => {
    if (checked) {
      vSet.add(name);
    } else {
      vSet.delete(name);
    }
    field.onChange(Array.from(vSet));
  };

  return (
    <ul {...containerStyleConfig}>
      {facet.values.map((facetValue, index) => {
        const isChecked = vSet.has(facetValue.value);
        return (
          <CheckoutFacetAttribute
            // eslint-disable-next-line react/no-array-index-key
            key={`${facetValue.value}_${index}`}
            config={config?.checkbox}
            facetValue={facetValue}
            checked={isChecked}
            onCheckedChange={onCheckedChange}
          />
        );
      })}
    </ul>
  );
};

CheckboxFacet.displayName = 'CheckboxFacet';

export { CheckboxFacet };
