import { Button } from '../../../components';
import { useProfile } from './hooks';

import type { FC } from 'react';
import type { UserProfileCtaWidgetProps } from './UserProfileCta.types';

const UserProfileCtaWidget: FC<UserProfileCtaWidgetProps> = ({ widget }) => {
  const wData = widget.data!;

  const { onClick, isLoading } = useProfile(widget);

  return (
    <Button
      isLoading={isLoading}
      config={widget.config?.button}
      data={wData.button}
      onClick={onClick}
    />
  );
};

UserProfileCtaWidget.displayName = 'UserProfileCtaWidget';

export { UserProfileCtaWidget };
