import axios from 'axios';

import type {
  ProductReviewCreatePayload,
  ProductsSearchApiRouteActionResponse,
  SearchParamsWithExpressions,
} from '@tectonic/types';

const searchProducts = async (
  params: Partial<SearchParamsWithExpressions>
): Promise<ProductsSearchApiRouteActionResponse> => {
  const response = await axios.post('/api/products/search', params, {});
  return response.data;
};

const createReview = async (
  slug: string,
  payload: ProductReviewCreatePayload
) => {
  const response = await axios.post(`/api/products/${slug}/reviews`, payload);
  return response.data;
};

export { createReview, searchProducts };
