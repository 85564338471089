import { isNil } from 'lodash-es';

const toTailwindUtility = (
  utility: string,
  value?: string | number
): string => {
  if (value?.toString().startsWith('-')) {
    return `-${utility}-${value.toString().replace('-', '')}`;
  }
  return value ? `${utility}${isNil(value) ? '' : '-'}${value}` : '';
};

export { toTailwindUtility };
