import { useMemo } from 'react';
import { useElemasonPageContext } from '../../contexts/ElemasonPageContext';

import type { ElemasonFragment, Nil } from '@tectonic/types';

const usePageFragment = (slug: Nil<string>): Nil<ElemasonFragment> => {
  const { pageFragments, drawerFragments, rootFragments } =
    useElemasonPageContext();

  return useMemo(() => {
    if (!slug) {
      return null;
    }

    const dFragment = drawerFragments?.find(
      (fragment) => fragment.slug === slug
    );

    if (dFragment) {
      return dFragment;
    }

    const pFragments =
      pageFragments?.find((fragment) => fragment.slug === slug) ?? null;

    if (pFragments) {
      return pFragments;
    }
    return rootFragments?.find((fragment) => fragment.slug === slug) ?? null;
  }, [slug, pageFragments, drawerFragments, rootFragments]);
};

export { usePageFragment };
