import { LocalStateKeys } from '@tectonic/types';
import { useEffect } from 'react';
import { usePageFragment, useSharedLocalState } from '../../../hooks';
import { useAuthStore, useShallow } from '../../../store';

import type {
  ElemasonAuthMode,
  ElemasonAuthStep,
  ElemasonAuthWidget,
  Nil,
  User,
} from '@tectonic/types';
import type { AuthStore } from '../../../store';

type LocalAuthStore = {
  phone?: Nil<string>;
  email?: Nil<string>;
  code?: Nil<string>;
  currentStep?: Nil<ElemasonAuthStep>;
  user?: Nil<User>;
  currentMode?: Nil<ElemasonAuthMode>;
};

const initialState: LocalAuthStore = {};

const authSelector = (state: AuthStore) => ({
  setup: state.setData,
  credentials: state.credentials,
  user: state.user,
  currentStep: state.currentStep,
  profileUpdates: state.profileUpdates,
  currentMode: state.currentMode,
});

const useAuth = (widget: ElemasonAuthWidget) => {
  const wData = widget.data!;
  const { fragments } = wData;

  const { setSharedState } = useSharedLocalState<LocalAuthStore>(
    LocalStateKeys.AUTH_STORE,
    initialState
  );

  const { setup, credentials, currentStep, user, profileUpdates, currentMode } =
    useAuthStore(useShallow(authSelector));

  const slug = currentStep ? fragments[currentStep as ElemasonAuthStep] : null;
  const fragment = usePageFragment(slug);

  useEffect(() => {
    const { defaultMode, defaultStep } = wData;
    setup({ currentStep: defaultStep, currentMode: defaultMode });
  }, [wData]);

  useEffect(() => {
    const { email } = credentials ?? {};
    const phoneNumber = profileUpdates?.phoneNumber ?? credentials.phoneNumber;
    const code = phoneNumber ? phoneNumber.country.stdCodes[0] : null;
    const phone = phoneNumber ? phoneNumber.phone : null;
    setSharedState({ phone, code, email, currentStep, user, currentMode });
  }, [credentials, currentStep, user, profileUpdates, currentMode]);

  return { fragment };
};

export { useAuth };
