import { useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  Typography,
  useForm,
} from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import clsx from 'clsx';
import {
  forwardRef,
  useImperativeHandle,
  type ForwardRefRenderFunction,
} from 'react';
import { Button } from '../../Button';
import { TextField } from '../../TextField';

import type { FormSubmitHandler } from '@tectonic/uikit';
import type {
  EmailInputFormProps,
  EmailInputFormRef,
  EmailInputFormValue,
} from './EmailInputForm.types';

const defaultValues: EmailInputFormValue = { email: '' };

const EmailInputForm: ForwardRefRenderFunction<
  EmailInputFormRef,
  EmailInputFormProps
> = (props, formRef) => {
  const { data, isSubmitting, onSubmit, config } = props;
  const { control, setError, formState } = useForm<EmailInputFormValue>({
    defaultValues,
    mode: 'onChange',
  });
  const { errors } = formState;

  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  const handleSubmit: FormSubmitHandler<EmailInputFormValue> = (formData) => {
    const value = formData.data;
    if (isBlank(value.email)) {
      setError('email', {
        type: 'custom',
        message: data.emailInput.textField.required!,
      });
      return;
    }
    onSubmit(value);
  };

  useImperativeHandle(formRef, () => ({ setError }), [setError]);

  return (
    <Form
      control={control}
      onSubmit={handleSubmit}
      style={containerStyleConfig.style}
      className={clsx('flex flex-col', containerStyleConfig.className)}
    >
      <div className={clsx('flex flex-col gap-2')}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              data={data.emailInput.textField}
              config={config?.emailInput?.textField}
              value={field.value}
              type="email"
              disabled={isSubmitting}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        <FormErrorMessage
          name="email"
          errors={errors}
          as={Typography}
          className="text-danger-600"
          variant="body3"
        />
      </div>
      <div className="flex">
        <Button
          isLoading={isSubmitting}
          className="grow"
          type="submit"
          data={data.submitCta.button}
          config={config?.submitCta?.button}
        />
      </div>
    </Form>
  );
};

EmailInputForm.displayName = 'EmailInputForm';

const ExoticForm = forwardRef(EmailInputForm);

export { ExoticForm as EmailInputForm };
