import clsx from 'clsx';
import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { useThemeContext } from '../../context/ThemeContext';
import { Icon } from '../Icon/Icon';

import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import type { VariantProps } from 'tailwind-variants';
import type { IconSymbol } from '../Icon/Icon.types';

const buttonTailwind = tv({
  slots: {
    label: 'btn-label',
    endIcon: 'btn-icon',
    startIcon: 'btn-icon',
    base: 'btn relative flex',
    loader: 'btn-loader absolute top-0 left-0 w-full h-full',
  },
  variants: {
    variant: {
      solid: 'btn-solid',
      ghost: 'btn-ghost',
      outline: 'btn-outline',
    },
    size: {
      lg: 'btn-lg',
      md: 'btn-md',
      sm: 'btn-sm',
      xs: 'btn-xs',
      '2xs': 'btn-2xs',
      '3xs': 'btn-3xs',
    },
    modifier: {
      info: '',
      black: '',
      accent: '',
      danger: '',
      warning: '',
      primary: '',
      success: '',
      secondary: '',
    },
    disabled: {
      true: 'cursor-default opacity-50',
    },
    isLoading: {
      true: '',
    },
  },
  compoundSlots: [
    // Solid
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      // className: 'btn-solid-primary',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'info',
      className: 'btn-solid-info',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'black',
      className: 'btn-solid-black',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'danger',
      className: 'btn-solid-danger',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'warning',
      className: 'btn-solid-warning',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'primary',
      className: 'btn-solid-primary',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'secondary',
      className: 'btn-solid-secondary',
    },
    {
      slots: ['loader', 'base'],
      variant: 'solid',
      modifier: 'accent',
      className: 'btn-solid-accent',
    },
    // Outline
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      // className: ['btn-outline-primary'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'info',
      className: ['btn-outline-info'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'black',
      className: ['btn-outline-black'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'accent',
      className: ['btn-outline-accent'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'danger',
      className: ['btn-outline-danger'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'warning',
      className: ['btn-outline-warning'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'primary',
      className: ['btn-outline-primary'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'success',
      className: ['btn-outline-success'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'outline',
      modifier: 'secondary',
      className: ['btn-outline-secondary'],
    },
    // Ghost
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      // className: ['btn-ghost-primary'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'info',
      className: ['btn-ghost-info'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'black',
      className: ['btn-ghost-black'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'accent',
      className: ['btn-ghost-accent'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'danger',
      className: ['btn-ghost-danger'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'warning',
      className: ['btn-ghost-warning'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'primary',
      className: ['btn-ghost-primary'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'success',
      className: ['btn-ghost-success'],
    },
    {
      slots: ['loader', 'base'],
      variant: 'ghost',
      modifier: 'secondary',
      className: ['btn-ghost-secondary'],
    },
    // Hide content for outline and ghost while loading
    {
      slots: ['label', 'endIcon', 'startIcon'],
      variant: 'ghost',
      isLoading: true,
      className: 'invisible',
    },
    {
      slots: ['label', 'endIcon', 'startIcon'],
      variant: 'outline',
      isLoading: true,
      className: 'invisible',
    },
  ],
  defaultVariants: {
    size: 'md',
    variant: 'solid',
  },
});

interface Props
  extends Omit<ComponentProps<'button'>, 'children' | 'color'>,
    VariantProps<typeof buttonTailwind> {
  label?: string | undefined;
  endIcon?: IconSymbol | undefined;
  startIcon?: IconSymbol | undefined;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    size,
    style,
    label,
    endIcon,
    variant,
    modifier,
    disabled,
    isLoading,
    startIcon,
    className,
    ...buttonProps
  },
  ref
) => {
  const { icon } = useThemeContext();

  const {
    base,
    loader,
    label: labelCls,
    endIcon: endIconCls,
    startIcon: startIconCls,
  } = buttonTailwind({
    size,
    variant,
    modifier,
    isLoading,
    disabled: isLoading || disabled,
  });

  return (
    <button
      ref={ref}
      title="label"
      type="button"
      style={style}
      disabled={isLoading || disabled}
      className={clsx(base(), className)}
      {...buttonProps}
    >
      {startIcon && (
        <div className={startIconCls()}>
          <Icon symbol={startIcon} size={size} />
        </div>
      )}
      {label && <div className={labelCls()}>{label}</div>}
      {isLoading && (
        <div className={clsx(loader(), className)} style={style}>
          <Icon size={size} symbol={icon.spinner} className="animate-spin" />
        </div>
      )}
      {endIcon && (
        <div className={endIconCls()}>
          <Icon symbol={endIcon} size={size} />
        </div>
      )}
    </button>
  );
};

Button.displayName = 'Button';

const ExoticButton = forwardRef(Button);

export { ExoticButton as Button };

export type { Props as ButtonProps };
