import {
  useContext as rcUseContext,
  createContext as rcCreateContext,
} from 'react';
import { isNil } from 'lodash-es';

import type { Context, Provider } from 'react';

interface CreateContextOptions {
  /**
   * If `true`, React will throw if context is `null` or `undefined`
   * In some cases, you might want to support nested context, so you can set it to `false`
   */
  strict?: boolean;
  /**
   * Error message to throw if the context is `undefined`
   */
  errorMessage?: string;
  /**
   * The display name of the context
   */
  name?: string;
}

type CreateContextReturn<T> = [Provider<T>, () => T, Context<T>];

const CONTEXT_ERROR_MESSAGE =
  'useContext must be inside a Provider with a value';

const createContext = <ContextType>(options: CreateContextOptions = {}) => {
  const { strict = true, errorMessage = CONTEXT_ERROR_MESSAGE, name } = options;

  const Context = rcCreateContext<ContextType | undefined>(undefined);

  Context.displayName = name;

  const useContext = () => {
    const context = rcUseContext(Context);
    if (strict && isNil(context)) {
      throw new Error(errorMessage);
    }
    return context;
  };

  return [
    Context.Provider,
    useContext,
    Context,
  ] as CreateContextReturn<ContextType>;
};

export { createContext };

export type { CreateContextOptions, CreateContextReturn };
