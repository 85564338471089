import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { OTPInput } from 'input-otp';
import { OtpInputSlot } from './OtpInputSlot';
import { useOtpInput } from './hooks';

import type { FC } from 'react';
import type { OtpInputWidgetProps } from './OtpInput.types';

const OtpInputWidget: FC<OtpInputWidgetProps> = ({ widget }) => {
  const wConfig = widget.config;
  const { onChange, maxLength } = useOtpInput(widget);
  const [cStyle, cClass] = useStyleConfigV2(wConfig?.container);

  return (
    <OTPInput
      containerClassName={clsx('flex', cClass)}
      // TODO: Fix this the otpInput doesn't take styles. It only takes className
      // as containerClass
      // style={cStyle}
      maxLength={maxLength}
      onChange={onChange}
      render={({ slots }) => (
        <>
          {slots.map((slot, index) => (
            <OtpInputSlot
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              slot={slot}
              widget={widget}
              index={index}
            />
          ))}
        </>
      )}
    />
  );
};

OtpInputWidget.displayName = 'OtpInputWidget';

export { OtpInputWidget };
