import { useCarouselContext } from './CarouselContext';
import { Children, cloneElement, type FC } from 'react';

import type { CarouselSlidesProps } from './Carousel.types';

const Slides: FC<CarouselSlidesProps> = ({ children }) => {
  const { activeSnap } = useCarouselContext();
  return Children.map(children, (child, index) => {
    const isActive = activeSnap === index;
    return cloneElement(child, { isActive, ...child.props });
  });
};

Slides.displayName = 'CarouselSlides';

export default Slides;
