import { useStyleConfig } from '@tectonic/elemason-components';
import { script } from '@tectonic/halo-script';
import { LocalStateKeys } from '@tectonic/types';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from '@tectonic/uikit';
import { access } from '@tectonic/utils';
import clsx from 'clsx';
import { useEffect, type FC } from 'react';
import { Loader } from '../../../components';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { CouponDialogContent } from './CouponDialogContent';

import type {
  Cart,
  ElemasonCartCouponsDialogConfig,
  ElemasonCartCouponsDialogData,
  Promotion,
} from '@tectonic/types';

interface CouponDialogProps {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  onClose: () => void;
  data?: ElemasonCartCouponsDialogData;
  onApply: (discount: Promotion) => void;
  config?: ElemasonCartCouponsDialogConfig;
  currentlyApplicableDiscounts?: Promotion[];
  potentiallyApplicableDiscounts?: Promotion[];
}

const STATE_KEY = LocalStateKeys.CART;

const CouponDialog: FC<CouponDialogProps> = ({
  data,
  config,
  onClose,
  onApply,
  isError,
  isLoading,
  isSuccess,
  currentlyApplicableDiscounts,
  potentiallyApplicableDiscounts,
}) => {
  const { showToast } = useToast();

  const cart = useHaloScript<Cart>(script([access(['local', STATE_KEY])]));

  useEffect(() => {
    if (isError) {
      showToast({ title: 'There was an error fetching coupons' });
    }
  });

  const { style, className } = useStyleConfig(config?.container ?? {});

  return (
    <DialogRoot open modal onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay className="fixed inset-0 z-20 bg-black/70" />
        <DialogContent
          style={style}
          className={clsx(className, 'pointer-events-auto')}
        >
          {isLoading && (
            <Loader className="-transform-x-1/2 -transform-y-1/2 fixed left-1/2 top-1/2 z-20 text-white" />
          )}
          {isSuccess && (
            <CouponDialogContent
              onApply={onApply}
              data={data?.content}
              config={config?.content}
              appliedCoupons={cart?.discountCodes ?? []}
              currentlyApplicableDiscounts={currentlyApplicableDiscounts}
              potentiallyApplicableDiscounts={potentiallyApplicableDiscounts}
            />
          )}
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
};

export { CouponDialog };
