import { interpret } from './interpreter';
import { parse } from './parser';
import { Array, Cookies, Enum, Math, Number, Storage } from './stdlib';

import type { Script } from './types';

const evaluate = (script: Script, context: any = {}, skipParse?: boolean) => {
  const canUseDom =
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement;

  const environment = {
    ...context,
    Enum,
    Math,
    Storage,
    Array,
    Number,
    Cookies,
    globalThis: canUseDom ? globalThis : null,
  };

  if (process.env.NODE_ENV === 'development' && !skipParse) {
    const ast = parse(script);
    return interpret(ast, environment);
  }
  return interpret(script, environment);
};

export * from './helpers';
export type * from './types';
export { evaluate };
