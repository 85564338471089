import { useMutation } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { type FC } from 'react';
import { Button } from '../../components';
import { useToast } from '../../core/ElemasonEntry/Toast';
import { useActionDispatch } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonRegisterInterestWidget } from '@tectonic/types';

interface RegisterInterestProps {
  widget: ElemasonRegisterInterestWidget;
}

const RegisterInterestWidget: FC<RegisterInterestProps> = ({
  widget: { config, data, actions },
}) => {
  const { showToast } = useToast();
  const dispatch = useActionDispatch();
  const mode = useHaloScript(data?.mode);
  const value = useHaloScript(data?.value);
  const variant = useHaloScript(data?.variant);

  const { mutate } = useMutation({
    mutationFn: () =>
      remixApi.registerInterest({
        email: mode === 'email' ? value : undefined,
        phone: mode === 'phone' ? value : undefined,
        variantGlobalId: variant?.globalId!,
      }),
    onSuccess: () => {
      showToast({
        title: 'We will notify you when the product is back in stock',
      });
      actions?.onSuccess?.forEach((action) => dispatch(action));
    },
    onError: () => {
      showToast({ title: 'Error registering request' });
      actions?.onError?.forEach((action) => dispatch(action));
    },
  });

  const handleOnClick = () => {
    mutate();
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return (
    <Button
      data={data?.button}
      onClick={handleOnClick}
      config={config?.button}
    />
  );
};

export { RegisterInterestWidget };
