/* eslint-disable no-param-reassign */
const generateTWColors = (
  colorObject: object = {},
  output: Record<string, string> = {},
  prefix = ''
) => {
  Object.entries(colorObject).forEach(([key, value]) => {
    if (typeof value === 'string') {
      output[prefix ? `${prefix}-${key}` : `${key}`] = value;
    } else if (typeof value === 'object') {
      generateTWColors(value, output, prefix ? `${prefix}-${key}` : `${key}`);
    }
  });
  return output;
};

export { generateTWColors };
