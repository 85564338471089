import { Button } from '../components';
import { useElemasonDrawerContext } from '../contexts/ElemasonDrawerContext';
import { useActionDispatch } from '../hooks';
import { useHaloScript } from '../hooks/useHaloScript';

import type { ElemasonDrawerCloseWidget } from '@tectonic/types';
import type { FC, MouseEvent } from 'react';

interface ElemasonDrawerCloseWidgetProps {
  widget: ElemasonDrawerCloseWidget;
}

const DrawerCloseWidget: FC<ElemasonDrawerCloseWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const dispatch = useActionDispatch();
  const { close } = useElemasonDrawerContext();
  const callback = useHaloScript(data?.callback);
  const callbackData = useHaloScript(data?.data);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    actions?.onClick.forEach((action) => dispatch(action, undefined));
    callback?.(callbackData);
    close();
  };

  return (
    <Button
      data={data}
      onClick={onClick}
      config={config?.button}
      size={config?.button?.size}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

DrawerCloseWidget.displayName = 'ElemasonDrawerCloseWidget';

export { DrawerCloseWidget };
