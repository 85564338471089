import { LocalStorage } from '@tectonic/storage';

enum SnapIds {
  SCC = '__tt_scc',
  SCCLID = 'ScCid',
}

const setSCC = (scclid: string) => {
  // TODO not clear if this needs to be session or local storage
  LocalStorage.setItem(SnapIds.SCC, scclid);
  return scclid;
};

const getSCC = () => LocalStorage.getItem(SnapIds.SCC);

const initializeSnap = () => {
  try {
    const searchParams = new URLSearchParams(globalThis.location.search);
    const scclid = searchParams.get(SnapIds.SCCLID);
    let scc = getSCC();

    if (scclid) {
      // Looks like we have got a new scclid, set scc.
      scc = setSCC(scclid);
    }

    return { scc };
  } catch (error) {
    //
  }
  return null;
};

const resetSnap = () => {
  LocalStorage.removeItem(SnapIds.SCC);
};

export { getSCC, initializeSnap, resetSnap, setSCC };
