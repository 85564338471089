import { Slot } from '@radix-ui/react-slot';
import { Children, cloneElement } from 'react';
import { useCarouselContext } from './CarouselContext';

import type { FC, ReactElement } from 'react';
import type { CarouselDotsProps } from './Carousel.types';

const MAX_NODES = 7;

const Dots: FC<CarouselDotsProps> = ({ children, asChild, ...restProps }) => {
  const child = Children.only(children) && (children as ReactElement);

  const { snaps, activeSnap } = useCarouselContext();

  const Comp = asChild ? Slot : 'div';

  // Calculate start and end indices to slice snaps array
  let start = Math.max(0, activeSnap - Math.floor(MAX_NODES / 2));
  let end = start + MAX_NODES;

  // Adjust if we're near the end of the snaps array
  if (end > snaps.length) {
    end = snaps.length;
    start = Math.max(0, end - MAX_NODES);
  }

  const finalSnaps = snaps.slice(start, end);
  const snapNodes = finalSnaps.map((snap, index) =>
    cloneElement(child, {
      snap: index + start, // Adjust index to match original snaps array
      key: `${snap}_${index + start}`, // Adjust key to match original snaps array
      ...child.props,
      style: {
        ...child.props.style,
        zoom:
          // eslint-disable-next-line no-nested-ternary
          finalSnaps.length < snaps.length
            ? // eslint-disable-next-line no-nested-ternary
              index === 0 || index === finalSnaps.length - 1
              ? 0.5
              : index === 1 || index === finalSnaps.length - 2
                ? 0.7
                : 0
            : 1,
      },
    })
  );

  return <Comp {...restProps}>{snapNodes}</Comp>;
};

Dots.displayName = 'CarouselDots';

export default Dots;
