import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { DialogClose, Input, InputField, InputSlot } from '@tectonic/uikit';
import clsx from 'clsx';
import { useState, type FC } from 'react';
import { Button } from '../../../components';
import { isVisible } from '../../../utils';
import { DiscountCard } from './DiscountCard';

import type {
  DiscountCode,
  ElemasonCartCouponsDialogContentConfig,
  ElemasonCartCouponsDialogContentData,
  Promotion,
} from '@tectonic/types';

interface CouponDialogContentProps {
  appliedCoupons: DiscountCode[];
  onApply: (discount: Promotion) => void;
  data?: ElemasonCartCouponsDialogContentData;
  currentlyApplicableDiscounts?: Promotion[];
  potentiallyApplicableDiscounts?: Promotion[];
  config?: ElemasonCartCouponsDialogContentConfig;
}

const CouponDialogContent: FC<CouponDialogContentProps> = ({
  data,
  config,
  onApply,
  appliedCoupons,
  currentlyApplicableDiscounts,
  potentiallyApplicableDiscounts,
}) => {
  const [customDiscount, setCustomDiscount] = useState('');
  const [style, className] = useStyleConfigV2(config?.container ?? {});
  const [headerStyle, headerClassName] = useStyleConfigV2(
    config?.header?.container ?? {}
  );
  const [couponsStyle, couponsClassName] = useStyleConfigV2(
    config?.coupons?.container ?? {}
  );
  const showPotentiallyApplicableDiscountsText =
    isVisible(config?.coupons?.potentiallyApplicableOffersText) &&
    (potentiallyApplicableDiscounts?.length ?? 0) > 0;
  const [
    potentiallyApplicableDiscountsTextContainerStyle,
    potentiallyApplicableDiscountsTextContainerClassName,
  ] = useStyleConfigV2(config?.coupons?.potentiallyApplicableOffersText ?? {});

  const appliedCouponCodes = appliedCoupons.map((coupon) => coupon.code);

  return (
    <div style={style} className={clsx('flex flex-col', className)}>
      <div style={headerStyle} className={clsx('z-10 flex', headerClassName)}>
        <DialogClose asChild>
          <Button data={data?.header?.close} config={config?.header?.close} />
        </DialogClose>
        <Text data={data?.header?.title ?? ''} config={config?.header?.title} />
      </div>
      {/* TODO: make it configurable */}
      <div className="p-4">
        <Input>
          <InputField
            size="md"
            onChange={(e) => setCustomDiscount(e.target.value)}
            placeholder={data?.couponEntry?.placeholder ?? 'Enter coupon code'}
          />
          <InputSlot
            onClick={() => onApply({ codes: [customDiscount] } as Promotion)}
          >
            <Button
              data={{ text: data?.couponEntry?.applyCta ?? 'Apply' }}
              config={{
                size: 'sm',
                variant: 'ghost',
              }}
            />
          </InputSlot>
        </Input>
      </div>
      <div style={couponsStyle} className={couponsClassName}>
        {currentlyApplicableDiscounts?.map((discount) => (
          <DiscountCard
            key={discount.id}
            discount={discount}
            data={data?.coupons?.card}
            config={config?.coupons?.card}
            isApplied={discount.codes.some((code) =>
              appliedCouponCodes.includes(code)
            )}
            onApply={() => onApply(discount)}
          />
        ))}
      </div>
      {showPotentiallyApplicableDiscountsText && (
        <div
          style={potentiallyApplicableDiscountsTextContainerStyle}
          className={potentiallyApplicableDiscountsTextContainerClassName}
        >
          <Text
            data={data?.coupons?.potentiallyApplicableOffersText ?? ''}
            config={config?.coupons?.potentiallyApplicableOffersText}
          />
        </div>
      )}
      <div>
        {potentiallyApplicableDiscounts?.map((discount) => (
          <DiscountCard
            disabled
            key={discount.id}
            discount={discount}
            data={data?.coupons?.card}
            config={config?.coupons?.card}
          />
        ))}
      </div>
    </div>
  );
};

export { CouponDialogContent };
