import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import {
  ElemasonWidgetActionType,
  type ElemasonCartPromotionWidget,
} from '@tectonic/types';
import {
  useActionDispatch,
  useFragmentValue,
  usePageFragment,
} from '../../../hooks';
import { queryKeys } from '../../../queryKeys';

import type { AxiosError } from 'axios';

const useCartCoupon = (widget: ElemasonCartPromotionWidget) => {
  const wData = widget.data!;
  const { actions } = widget;
  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);

  const queryClient = useQueryClient();
  const dispatch = useActionDispatch();

  const {
    mutate: onApply,
    isPending: isApplying,
    isSuccess: isApplied,
    isError: hasApplyError,
    error: couponApplyError,
  } = useMutation({
    mutationFn: async (code: string) => {
      actions?.onApplyRequest?.forEach((action) => dispatch(action));
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_ADD_REQUEST,
          data: { promotionName: code },
        },
      });
      const response = await remixApi.overrideCartDiscounts({
        discountCodes: [code],
      });
      return response;
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (cart, code) => {
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_ADD_SUCCESS,
          data: { entities: { cart }, promotionName: code },
        },
      });
      actions?.onApplySuccess?.forEach((action) => dispatch(action, cart));
    },
    onError: (error: AxiosError, code: string) => {
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_ADD_ERROR,
          data: { promotionName: code, error },
        },
      });
      actions?.onApplyError?.forEach((action) => dispatch(action, error));
    },
  });

  const {
    mutate: onRemove,
    isPending: isRemoving,
    isSuccess: isRemoved,
    isError: hasRemoveError,
    error: couponRemoveError,
  } = useMutation({
    mutationFn: (codes: string[]) => {
      actions?.onRemoveRequest?.forEach((action) => dispatch(action));
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_REMOVE_REQUEST,
          data: { promotionName: codes[0] },
        },
      });
      return remixApi.removeCartDiscounts();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart, codes) => {
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_REMOVE_SUCCESS,
          data: {
            entities: { cart: updatedCart },
            promotionName: codes[0],
          },
        },
      });
      actions?.onRemoveSuccess?.forEach((action) =>
        dispatch(action, updatedCart)
      );
    },
    onError: (error, codes) => {
      dispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_REMOVE_ERROR,
          data: { error, promotionName: codes[0] },
        },
      });
      actions?.onRemoveError?.forEach((action) => dispatch(action, error));
    },
  });

  const fragmentData = fragmentValue({
    isApplied,
    isApplying,
    hasApplyError,
    couponApplyErrorMessage: couponApplyError
      ? getErrorMessage(couponApplyError)
      : null,
    onApply,
    isRemoved,
    isRemoving,
    hasRemoveError,
    onRemove,
    couponRemoveErrorMessage: couponRemoveError
      ? getErrorMessage(couponRemoveError)
      : null,
  });

  return { fragment, fragmentData };
};

export { useCartCoupon };
