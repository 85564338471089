/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import {
  accessor,
  apply,
  array,
  assignment,
  binaryExpression,
  conditional,
  identifier,
  lambda,
  literal,
  loop,
} from '../helpers';

const find = lambda(
  ['__halo__find__enumerable', '__halo__find__condition'],
  [
    assignment(identifier('__halo__find__result'), literal(undefined)),
    loop(
      [assignment(identifier('__halo__find__i'), literal(0))],
      binaryExpression(
        identifier('__halo__find__i'),
        '<',
        accessor([identifier('__halo__find__enumerable'), literal('length')])
      ),
      [
        conditional(
          binaryExpression(
            apply(identifier('__halo__find__condition'), [
              accessor([
                identifier('__halo__find__enumerable'),
                identifier('__halo__find__i'),
              ]),
            ]),
            '&&',
            binaryExpression(
              identifier('__halo__find__result'),
              '==',
              literal(undefined)
            )
          ),
          [
            assignment(
              identifier('__halo__find__result'),
              accessor([
                identifier('__halo__find__enumerable'),
                identifier('__halo__find__i'),
              ])
            ),
          ]
        ),
        identifier('__halo__find__result'),
      ],
      [
        assignment(
          identifier('__halo__find__i'),
          binaryExpression(identifier('__halo__find__i'), '+', literal(1))
        ),
      ]
    ),
  ]
);

const filter = lambda(
  ['__halo__filter__enumerable', '__halo__filter__condition'],
  [
    loop(
      [
        assignment(identifier('__halo__filter__i'), literal(0)),
        assignment(identifier('__halo__filter__ri'), literal(0)),
        assignment(identifier('__halo__filter__result'), array()),
      ],
      binaryExpression(
        identifier('__halo__filter__i'),
        '<',
        accessor([identifier('__halo__filter__enumerable'), literal('length')])
      ),
      [
        conditional(
          apply(identifier('__halo__filter__condition'), [
            accessor([
              identifier('__halo__filter__enumerable'),
              identifier('__halo__filter__i'),
            ]),
          ]),
          [
            assignment(
              accessor([
                identifier('__halo__filter__result'),
                identifier('__halo__filter__ri'),
              ]),
              accessor([
                identifier('__halo__filter__enumerable'),
                identifier('__halo__filter__i'),
              ])
            ),
            assignment(
              identifier('__halo__filter__ri'),
              binaryExpression(
                identifier('__halo__filter__ri'),
                '+',
                literal(1)
              )
            ),
          ]
        ),
        identifier('__halo__filter__result'),
      ],
      [
        assignment(
          identifier('__halo__filter__i'),
          binaryExpression(identifier('__halo__filter__i'), '+', literal(1))
        ),
      ]
    ),
  ]
);

const map = lambda(
  ['__halo__map__enumerable', '__halo__map__lambda', 'prepend'],
  [
    loop(
      [
        assignment(
          identifier(
            binaryExpression(
              binaryExpression(identifier('prepend'), '??', literal('')),
              '+',
              literal('__halo__map__i')
            )
          ),
          literal(0)
        ),
        assignment(identifier('__halo__map__result'), array()),
      ],
      binaryExpression(
        identifier(
          binaryExpression(
            binaryExpression(identifier('prepend'), '??', literal('')),
            '+',
            literal('__halo__map__i')
          )
        ),
        '<',
        accessor([identifier('__halo__map__enumerable'), literal('length')])
      ),
      [
        assignment(
          accessor([
            identifier('__halo__map__result'),
            identifier(
              binaryExpression(
                binaryExpression(identifier('prepend'), '??', literal('')),
                '+',
                literal('__halo__map__i')
              )
            ),
          ]),
          apply(identifier('__halo__map__lambda'), [
            accessor([
              identifier('__halo__map__enumerable'),
              identifier(
                binaryExpression(
                  binaryExpression(identifier('prepend'), '??', literal('')),
                  '+',
                  literal('__halo__map__i')
                )
              ),
            ]),
          ])
        ),
        identifier('__halo__map__result'),
      ],
      [
        assignment(
          identifier(
            binaryExpression(
              binaryExpression(identifier('prepend'), '??', literal('')),
              '+',
              literal('__halo__map__i')
            )
          ),
          binaryExpression(
            identifier(
              binaryExpression(
                binaryExpression(identifier('prepend'), '??', literal('')),
                '+',
                literal('__halo__map__i')
              )
            ),
            '+',
            literal(1)
          )
        ),
      ]
    ),
  ]
);

const join = lambda(
  ['__halo__join__enumerable', '__halo__join__separator', 'prepend'],
  [
    assignment(identifier('__halo__join__result'), literal('')),
    loop(
      [
        assignment(
          identifier(
            binaryExpression(
              binaryExpression(identifier('prepend'), '??', literal('')),
              '+',
              literal('__halo__join__i')
            )
          ),
          literal(0)
        ),
      ],
      binaryExpression(
        identifier(
          binaryExpression(
            binaryExpression(identifier('prepend'), '??', literal('')),
            '+',
            literal('__halo__join__i')
          )
        ),
        '<',
        accessor([identifier('__halo__join__enumerable'), literal('length')])
      ),
      [
        assignment(
          identifier('__halo__join__result'),
          binaryExpression(
            identifier('__halo__join__result'),
            '+',
            binaryExpression(
              accessor([
                identifier('__halo__join__enumerable'),
                identifier(
                  binaryExpression(
                    binaryExpression(identifier('prepend'), '??', literal('')),
                    '+',
                    literal('__halo__join__i')
                  )
                ),
              ]),
              '+',
              conditional(
                binaryExpression(
                  identifier(
                    binaryExpression(
                      binaryExpression(
                        identifier('prepend'),
                        '??',
                        literal('')
                      ),
                      '+',
                      literal('__halo__join__i')
                    )
                  ),
                  '<',
                  binaryExpression(
                    accessor([
                      identifier('__halo__join__enumerable'),
                      literal('length'),
                    ]),
                    '-',
                    literal(1)
                  )
                ),
                [identifier('__halo__join__separator')],
                [literal('')]
              )
            )
          )
        ),
      ],
      [
        assignment(
          identifier(
            binaryExpression(
              binaryExpression(identifier('prepend'), '??', literal('')),
              '+',
              literal('__halo__join__i')
            )
          ),
          binaryExpression(
            identifier(
              binaryExpression(
                binaryExpression(identifier('prepend'), '??', literal('')),
                '+',
                literal('__halo__join__i')
              )
            ),
            '+',
            literal(1)
          )
        ),
      ]
    ),
    identifier('__halo__join__result'),
  ]
);

const concat = lambda(
  ['__halo__concat__array1', '__halo__concat__array2'],
  [
    loop(
      [
        assignment(identifier('__halo__concat__i'), literal(0)),
        assignment(identifier('__halo__concat__result'), array()),
      ],
      binaryExpression(
        identifier('__halo__concat__i'),
        '<',
        accessor([identifier('__halo__concat__array1'), literal('length')])
      ),
      [
        assignment(
          accessor([
            identifier('__halo__concat__result'),
            identifier('__halo__concat__i'),
          ]),
          accessor([
            identifier('__halo__concat__array1'),
            identifier('__halo__concat__i'),
          ])
        ),
      ],
      [
        assignment(
          identifier('__halo__concat__i'),
          binaryExpression(identifier('__halo__concat__i'), '+', literal(1))
        ),
      ]
    ),
    loop(
      [assignment(identifier('__halo__concat__j'), literal(0))],
      binaryExpression(
        identifier('__halo__concat__j'),
        '<',
        accessor([identifier('__halo__concat__array2'), literal('length')])
      ),
      [
        assignment(
          accessor([
            identifier('__halo__concat__result'),
            binaryExpression(
              identifier('__halo__concat__i'),
              '+',
              identifier('__halo__concat__j')
            ),
          ]),
          accessor([
            identifier('__halo__concat__array2'),
            identifier('__halo__concat__j'),
          ])
        ),
      ],
      [
        assignment(
          identifier('__halo__concat__j'),
          binaryExpression(identifier('__halo__concat__j'), '+', literal(1))
        ),
      ]
    ),
    identifier('__halo__concat__result'),
  ]
);

const uniq = lambda(
  ['__halo__uniq__enumerable'],
  [
    assignment(identifier('__halo__uniq__result'), array()),
    assignment(identifier('__halo__uniq__j'), literal(0)),
    loop(
      [assignment(identifier('__halo__uniq__i'), literal(0))],
      binaryExpression(
        identifier('__halo__uniq__i'),
        '<',
        accessor([identifier('__halo__uniq__enumerable'), literal('length')])
      ),
      [
        conditional(
          binaryExpression(
            apply(accessor([identifier('Enum'), literal('find')]), [
              identifier('__halo__uniq__result'),
              lambda(
                ['__halo__uniq__x'],
                [
                  binaryExpression(
                    identifier('__halo__uniq__x'),
                    '==',
                    accessor([
                      identifier('__halo__uniq__enumerable'),
                      identifier('__halo__uniq__i'),
                    ])
                  ),
                ]
              ),
            ]),
            '==',
            literal(undefined)
          ),
          [
            assignment(
              accessor([
                identifier('__halo__uniq__result'),
                identifier('__halo__uniq__j'),
              ]),
              accessor([
                identifier('__halo__uniq__enumerable'),
                identifier('__halo__uniq__i'),
              ])
            ),
            assignment(
              identifier('__halo__uniq__j'),
              binaryExpression(identifier('__halo__uniq__j'), '+', literal(1))
            ),
          ]
        ),
      ],
      [
        assignment(
          identifier('__halo__uniq__i'),
          binaryExpression(identifier('__halo__uniq__i'), '+', literal(1))
        ),
      ]
    ),
    identifier('__halo__uniq__result'),
  ]
);

const pick = lambda(
  ['__halo__pick__enumerable', '__halo__pick__n'],
  [
    assignment(identifier('__halo__pick__result'), array()),
    loop(
      [assignment(identifier('__halo__pick__i'), literal(0))],
      binaryExpression(
        binaryExpression(
          identifier('__halo__pick__i'),
          '<',
          identifier('__halo__pick__n')
        ),
        '&&',
        binaryExpression(
          identifier('__halo__pick__i'),
          '<',
          accessor([identifier('__halo__pick__enumerable'), literal('length')])
        )
      ),
      [
        assignment(
          accessor([
            identifier('__halo__pick__result'),
            identifier('__halo__pick__i'),
          ]),
          accessor([
            identifier('__halo__pick__enumerable'),
            identifier('__halo__pick__i'),
          ])
        ),
      ],
      [
        assignment(
          identifier('__halo__pick__i'),
          binaryExpression(identifier('__halo__pick__i'), '+', literal(1))
        ),
      ]
    ),
    identifier('__halo__pick__result'),
  ]
);

const pickLast = lambda(
  ['__halo__pickLast__enumerable', '__halo__pickLast__n'],
  [
    assignment(identifier('__halo__pickLast__result'), array()),
    assignment(
      identifier('__halo__pickLast__start'),
      binaryExpression(
        accessor([
          identifier('__halo__pickLast__enumerable'),
          literal('length'),
        ]),
        '-',
        identifier('__halo__pickLast__n')
      )
    ),
    loop(
      [
        assignment(
          identifier('__halo__pickLast__i'),
          identifier('__halo__pickLast__start')
        ),
      ],
      binaryExpression(
        identifier('__halo__pickLast__i'),
        '<',
        accessor([
          identifier('__halo__pickLast__enumerable'),
          literal('length'),
        ])
      ),
      [
        assignment(
          accessor([
            identifier('__halo__pickLast__result'),
            binaryExpression(
              identifier('__halo__pickLast__i'),
              '-',
              identifier('__halo__pickLast__start')
            ),
          ]),
          accessor([
            identifier('__halo__pickLast__enumerable'),
            identifier('__halo__pickLast__i'),
          ])
        ),
      ],
      [
        assignment(
          identifier('__halo__pickLast__i'),
          binaryExpression(identifier('__halo__pickLast__i'), '+', literal(1))
        ),
      ]
    ),
    identifier('__halo__pickLast__result'),
  ]
);

const reverse = lambda(
  ['__halo__reverse__enumerable'],
  [
    assignment(identifier('__halo__reverse__result'), array()),
    loop(
      [assignment(identifier('__halo__reverse__i'), literal(0))],
      binaryExpression(
        identifier('__halo__reverse__i'),
        '<',
        accessor([identifier('__halo__reverse__enumerable'), literal('length')])
      ),
      [
        assignment(
          accessor([
            identifier('__halo__reverse__result'),
            binaryExpression(
              accessor([
                identifier('__halo__reverse__enumerable'),
                literal('length'),
              ]),
              '-',
              binaryExpression(
                identifier('__halo__reverse__i'),
                '+',
                literal(1)
              )
            ),
          ]),
          accessor([
            identifier('__halo__reverse__enumerable'),
            identifier('__halo__reverse__i'),
          ])
        ),
      ],
      [
        assignment(
          identifier('__halo__reverse__i'),
          binaryExpression(identifier('__halo__reverse__i'), '+', literal(1))
        ),
      ]
    ),
    identifier('__halo__reverse__result'),
  ]
);

export { concat, filter, find, join, map, pick, pickLast, reverse, uniq };
