import { useColors } from '@tectonic/uikit';
import { toTailwindUtility } from '@tectonic/utils';
import { compact } from 'lodash-es';

import type { BorderConfig, Color } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useBorderConfig = (config: Partial<BorderConfig>) => {
  const {
    border,
    borderTop,
    borderBottom,
    borderStart,
    borderEnd,
    borderStyle,
    borderRadius,
    borderTopRadius,
    borderBottomRadius,
    borderStartRadius,
    borderEndRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = config;

  const colors = useColors();
  const borderStyles: CSSProperties = {};

  const borderClasses = [
    border === '1' ? 'border' : null,
    borderTop === '1' ? 'border-t' : null,
    borderEnd === '1' ? 'border-r' : null,
    borderStart === '1' ? 'border-l' : null,
    borderBottom === '1' ? 'border-b' : null,
    toTailwindUtility('border', borderStyle),
    toTailwindUtility('border-t', borderTop),
    toTailwindUtility('border', border === '1' ? '' : border),
    toTailwindUtility('border-r', borderEnd === '1' ? '' : borderEnd),
    toTailwindUtility('border-l', borderStart === '1' ? '' : borderStart),
    toTailwindUtility('border-b', borderBottom === '1' ? '' : borderBottom),
  ];

  if (typeof borderRadius === 'number') {
    borderStyles.borderRadius = borderRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded', borderRadius));
  }

  if (typeof borderTopRadius === 'number') {
    borderStyles.borderTopLeftRadius = borderTopRadius;
    borderStyles.borderTopRightRadius = borderTopRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-t', borderTopRadius));
  }

  if (typeof borderBottomRadius === 'number') {
    borderStyles.borderBottomLeftRadius = borderBottomRadius;
    borderStyles.borderBottomRightRadius = borderBottomRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-b', borderBottomRadius));
  }

  if (typeof borderStartRadius === 'number') {
    borderStyles.borderTopLeftRadius = borderStartRadius;
    borderStyles.borderBottomLeftRadius = borderStartRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-s', borderStartRadius));
  }

  if (typeof borderEndRadius === 'number') {
    borderStyles.borderTopRightRadius = borderEndRadius;
    borderStyles.borderBottomRightRadius = borderEndRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-e', borderEndRadius));
  }

  if (typeof borderBottomLeftRadius === 'number') {
    borderStyles.borderBottomLeftRadius = borderBottomLeftRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-bl', borderBottomLeftRadius));
  }

  if (typeof borderBottomRightRadius === 'number') {
    borderStyles.borderBottomRightRadius = borderBottomRightRadius;
  } else {
    borderClasses.push(
      toTailwindUtility('rounded-br', borderBottomRightRadius)
    );
  }

  if (typeof borderTopLeftRadius === 'number') {
    borderStyles.borderTopLeftRadius = borderTopLeftRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-tl', borderTopLeftRadius));
  }

  if (typeof borderTopRightRadius === 'number') {
    borderStyles.borderTopRightRadius = borderTopRightRadius;
  } else {
    borderClasses.push(toTailwindUtility('rounded-tr', borderTopRightRadius));
  }

  if (config.borderColor) {
    borderStyles.borderColor =
      colors[config.borderColor as Color] ?? config.borderColor;
  }

  return [borderStyles, compact(borderClasses)] as [CSSProperties, string[]];
};

export { useBorderConfig };
