import clsx from 'clsx';
import { forwardRef } from 'react';

import type {
  ComponentProps,
  ForwardRefRenderFunction,
  ReactNode,
} from 'react';

interface CenterProps extends ComponentProps<'div'> {
  children: ReactNode;
  className?: string;
}

const Center: ForwardRefRenderFunction<HTMLDivElement, CenterProps> = (
  { children, className, ...props },
  ref
) => (
  <div
    ref={ref}
    className={clsx('flex grow items-center justify-center', className)}
    {...props}
  >
    {children}
  </div>
);

Center.displayName = 'Center';

const ExoticCenter = forwardRef(Center);

export { ExoticCenter as Center };
