import { useMutation, useQueryClient } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { populate } from '@tectonic/utils';
import { memo, useMemo, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import {
  useActionDispatch,
  useFragmentValue,
  useHaloScript,
  usePageFragment,
} from '../../hooks';

import type { ElemasonResourceMutationWidget } from '@tectonic/types';

interface ResourceMutationWidgetProps {
  widget: ElemasonResourceMutationWidget;
}

const ResourceMutationWidget: FC<ResourceMutationWidgetProps> = ({
  widget: { type, data, id: widgetId, actions },
}) => {
  const endpoint = useHaloScript(data?.endpoint);
  const payload = useHaloScript(data?.payload);
  const placeholders = useHaloScript<Record<string, string>>(
    data?.placeholders
  );

  const queryClient = useQueryClient();

  const invalidateQueryKeys = useHaloScript<string[][]>(
    data?.invalidateQueryKeys
  );

  const resourceKey = useHaloScript(data?.resourceKey);
  const dispatch = useActionDispatch();

  if (endpoint?.url && !endpoint.url.startsWith('/')) {
    throw new Error('External urls are not supported.');
  }

  const url = populate(endpoint?.url!, placeholders ?? {});

  const method = data?.method ?? 'POST';

  const {
    data: response,
    mutate,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationKey: resourceKey ?? ['resourceMutation', widgetId, url],
    mutationFn: async () => {
      actions?.onSubmit?.forEach((action) => dispatch(action, undefined));
      if (method === 'PUT') {
        const result = await remixApi.getInstance().put(url, payload);
        return result.data;
      }
      const result = await remixApi.getInstance().post(url, payload);
      return result.data;
    },
    onSuccess: (result) => {
      actions?.onSuccess?.forEach((action) => dispatch(action, result));
      invalidateQueryKeys?.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey });
      });
    },
    onError: (result) => {
      actions?.onError?.forEach((action) => dispatch(action, result));
      invalidateQueryKeys?.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey });
      });
    },
  });

  const value = useMemo(
    () => ({
      response,
      isError,
      isSuccess,
      onMutate: mutate,
      isLoading: isPending,
    }),
    [response, isError, isSuccess, isPending]
  );

  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue(value);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

ResourceMutationWidget.displayName = 'ResourceMutationWidget';

const MemoizedResourceMutationWidget = memo(ResourceMutationWidget);

export { MemoizedResourceMutationWidget as ResourceMutationWidget };
