import { useInfiniteQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash-es';
import { Button, OrderList } from '../../components';
import { queryKeys } from '../../queryKeys';

import type { ElemasonOrderListWidget, OrderLite } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonOrderListWidgetProps {
  widget: ElemasonOrderListWidget;
}

const OrderListWidget: FC<ElemasonOrderListWidgetProps> = ({
  widget: { id, config },
}) => {
  const { style: listContStyle, className: listContClassName } = useStyleConfig(
    config?.listContainer ?? {}
  );

  const pp = 5;

  // TODO written amaeturish code + brain fog need to polish this better and handle errors, loading states
  // TODO check if we need to set the pagination params in page url

  const {
    data: ordersCusorResp,
    isLoading,
    isError,
    fetchNextPage: fetchNextOrders,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: queryKeys.orders(),
    initialPageParam: '',
    // eslint-disable-next-line max-len
    queryFn: async ({ pageParam }) => {
      const response = await remixApi.getOrders(
        isEmpty(pageParam) ? { first: pp } : { first: pp, after: pageParam }
      );
      if (isNil(response.data) || response.error) {
        throw response.error;
      }
      return response.data;
    },
    getNextPageParam: (lastPage, _allPages, _lastPageParam) => {
      if (!lastPage.pageInfo.hasNextPage) {
        return undefined;
      }
      return lastPage.pageInfo.endCursor;
    },
  });

  const orders: OrderLite[] = [];

  ordersCusorResp?.pages.forEach((pageData) => {
    pageData.edges.forEach((edge: { node: OrderLite }) => {
      orders.push(edge.node);
    });
  });

  const { style: paginationActionStyle, className: paginationActionClass } =
    useStyleConfig({
      paddingY: '3',
      paddingX: '4',
      marginTop: '2',
      justifyContent: 'center',
      backgroundColor: 'white',
    });

  const isLoadingData = isLoading || isFetching || isFetchingNextPage;
  const hasOrders = !isLoadingData && orders.length > 0;

  if (
    isEmpty(ordersCusorResp) &&
    !isLoading &&
    !isFetchingNextPage &&
    !isFetching
  ) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div
        className={clsx('flex flex-col', listContClassName)}
        style={listContStyle}
      >
        <div className="flex flex-col">
          <OrderList
            config={config}
            orders={orders}
            isLoading={isLoadingData}
            isError={isError}
            hasOrders={hasOrders}
          />
        </div>
      </div>
      {hasOrders ? (
        <div
          className={clsx('flex', paginationActionClass)}
          style={{ ...paginationActionStyle }}
        >
          {hasNextPage ? (
            <Button
              config={{
                size: 'sm',
                variant: 'outline',
                borderColor: 'neutral-950',
                text: {
                  fontWeight: 'bold',
                  color: 'neutral-950',
                },
              }}
              data={{
                text: 'VIEW OLDER ORDERS',
              }}
              onClick={() => fetchNextOrders()}
            />
          ) : (
            <Text
              data="No more orders to show"
              config={{ variant: 'body3', color: 'neutral-950' }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export { OrderListWidget };
