import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  AnalyticsCartEventNames,
  AnalyticsProductEventNames,
} from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfig } from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { useCallback, useRef, type FC } from 'react';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../contexts/ElemasonAnalyticsContext';
import {
  ActionDispatchExporter,
  type DispatchExporterHandle,
} from '../../core';
import { useToast } from '../../core/ElemasonEntry/Toast';
import { queryKeys } from '../../queryKeys';
import { Button } from '../Button';
import { ProductGallery } from '../Product/ProductGallery';
import { CartBundleInfo } from './CartBundleInfo';
import { CartProductCardDetails } from './CartProductDetails';

import type {
  CartLine,
  CartLineProductVariant,
  ElemasonCartLineCardConfig,
  Product,
  ProductVariant,
} from '@tectonic/types';

interface CartLineProductProps {
  id: string;
  line: CartLine;
  isLoading?: boolean;
  config?: ElemasonCartLineCardConfig;
  onVariantSelect: (data: {
    variant: CartLineProductVariant;
    userProfileData?: any;
  }) => void;
}

const CartProduct: FC<CartLineProductProps> = ({
  id,
  line,
  config,
  isLoading,
  onVariantSelect,
}) => {
  const { showToast } = useToast();
  const analyticsContext = useElemasonAnalyticsContext();
  const queryClient = useQueryClient();

  const { style, className } = useStyleConfig(config ?? {});

  const { style: containerStyle, className: containerClassName } =
    useStyleConfig(config?.container ?? {});

  const { style: mediaStyle, className: mediaClassName } = useStyleConfig(
    config?.media ?? {}
  );
  const { style: deleteStyle, className: deleteClassName } = useStyleConfig(
    config?.delete ?? {}
  );

  const dispatchRef = useRef<DispatchExporterHandle>(null);

  const dispatchCartLineEvent = useCallback(
    (eventName: AnalyticsCartEventNames, data?: Record<string, any>) => {
      const actionDispatch = dispatchRef.current?.dispatch!;
      if (isNil(actionDispatch)) {
        return;
      }
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: eventName,
          data,
        },
      });
    },
    [dispatchRef.current]
  );

  const deleteMutation = useMutation({
    mutationFn: () => {
      dispatchCartLineEvent(AnalyticsCartEventNames.PRODUCT_REMOVE_REQUEST, {
        quantity: line.quantity,
      });
      return remixApi.deleteCartLines([line]);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      dispatchCartLineEvent(AnalyticsCartEventNames.PRODUCT_REMOVE_SUCCESS, {
        entities: { cart: updatedCart },
        quantity: line.quantity,
      });
      showToast({ title: 'Product removed from cart' });
    },
    onError: (error) => {
      const title = getErrorMessage(
        error,
        {},
        'Failed to remove product from cart'
      );

      dispatchCartLineEvent(AnalyticsCartEventNames.PRODUCT_REMOVE_ERROR, {
        error,
      });
      showToast({ title, duration: 5000 });
    },
  });

  const onDelete = () => {
    deleteMutation.mutate();
  };

  const onProductClick = useCallback(
    (product: Product) => {
      // Logger.info("Cart onProductClick happened", product)
      const actionDispatch = dispatchRef.current?.dispatch!;

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_CLICK,
        },
      });

      actionDispatch({
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PDP,
          route: {
            slug: product.slug,
          },
        },
      });
    },
    [dispatchRef.current]
  );

  const onVariantSelectorClick = useCallback(() => {
    if (dispatchRef.current?.dispatch) {
      dispatchRef.current?.dispatch({
        type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
        payload: {
          slug: 'variant-selector',
          data: {
            selectedVariant: line.productVariant,
            product: line.productVariant.product,
            onVariantSelect,
          },
          entry: 'bottom',
          exit: 'bottom',
        },
      });
    }
  }, [dispatchRef.current]);

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: {
          ...(analyticsContext?.entities ?? {}),
          cartLine: line,
          product: line.productVariant.product as Product,
          productVariant: line.productVariant as unknown as ProductVariant,
        },
      }}
    >
      <ActionDispatchExporter ref={dispatchRef} />
      <div style={style} className={clsx('flex flex-col', className)}>
        <div
          style={containerStyle}
          className={clsx('flex', containerClassName)}
        >
          <ProductGallery
            style={mediaStyle}
            className={mediaClassName}
            onProductClick={onProductClick}
            product={line.productVariant.product as Product}
          />
          <CartProductCardDetails
            id={id}
            isLoading={isLoading}
            quantity={line.quantity}
            config={config?.details}
            isFreebie={line.isFreebie}
            attributes={line.attributes}
            variant={line.productVariant}
            onVariantSelectorClick={onVariantSelectorClick}
          />
          <div style={deleteStyle} className={deleteClassName}>
            <Button
              onClick={onDelete}
              config={config?.delete}
              disabled={isLoading}
              isLoading={deleteMutation.isPending}
              data={{ startIcon: config?.deleteIcon }}
            />
          </div>
        </div>
        {line.productVariant.product.hasVariantsWithComponents && (
          <CartBundleInfo
            config={config?.bundle}
            variant={line.productVariant}
            onEditBundle={onVariantSelectorClick}
          />
        )}
      </div>
    </ElemasonAnalyticsProvider>
  );
};

export { CartProduct };
