import { useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { noop } from 'lodash-es';
import { memo, type ComponentProps, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { useActionDispatch, usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { PressableWidgetProps } from './Pressable.types';

const PressableWidget: FC<PressableWidgetProps> = ({ widget }) => {
  const wData = widget.data!;

  const { actions, config } = widget;

  const dispatch = useActionDispatch();
  const data = useHaloScript(wData.data);
  const fragment = usePageFragment(wData.fragment);
  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  const fragmentValue = useFragmentValue(fragment);

  const onClick: ComponentProps<typeof Clickable>['onClick'] = actions?.onClick
    ? (event) => {
        event.stopPropagation();
        console.log('----------', event.ctrlKey, event.metaKey);
        if (!event.ctrlKey && !event.metaKey) {
          console.log('ctrl was not held down during the click');
          //ctrl was held down during the click
          actions?.onClick.forEach((action) => dispatch(action, undefined));
        }
      }
    : noop;

  return fragment ? (
    <Clickable
      onClick={onClick}
      style={containerStyleConfig.style}
      className={clsx('flex', containerStyleConfig.className)}
    >
      <ElemasonFragmentProvider value={fragmentValue(data)}>
        {fragment?.cells.map((cell, index) => (
          <Cell key={cell.id ?? index} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </Clickable>
  ) : null;
};

PressableWidget.displayName = 'PressableWidget';

const MemoizedPressableWidget = memo(PressableWidget);

export { MemoizedPressableWidget as PressableWidget };
