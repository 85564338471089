import {
  AnalyticsButtonEventNames,
  AnalyticsProductEventNames,
} from '@tectonic/analytics';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { script } from '@tectonic/halo-script';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import { access } from '@tectonic/utils';
import { memo, type FC, type MouseEventHandler } from 'react';
import { Button } from '../../../components';
import { useActionDispatch } from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';
import {
  useAddProductToWishlistMutation,
  useRemoveProductFromWishlistMutation,
} from '../../../hooks/wishlist';

import type { ElemasonProductWishlistWidget, Product } from '@tectonic/types';

const IS_ANONYMOUS_ACCESSOR = script([
  access(['page', 'currentUser', 'isAnonymous']),
]);

interface ElemasonProductWishlistWidgetProps {
  widget: ElemasonProductWishlistWidget;
}

const ProductWishlistWidget: FC<ElemasonProductWishlistWidgetProps> = ({
  widget: { config, data },
}) => {
  const actionDispatch = useActionDispatch();
  const product = useHaloScript<Product>(data?.product);
  const [style, className] = useStyleConfigV2(config?.container);
  const isAnonymous = useHaloScript<boolean>(IS_ANONYMOUS_ACCESSOR);

  const addProductToWishlistMutation = useAddProductToWishlistMutation();

  const removeProductToWishlistMutation =
    useRemoveProductFromWishlistMutation();

  const toggleWishlist: MouseEventHandler = (e) => {
    e.stopPropagation();

    if (isAnonymous) {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsButtonEventNames.BUTTON_CLICK,
          data: {
            text: 'Wishlist Button Login Drawer',
          },
        },
      });
      actionDispatch({
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PATH,
          slug: 'login',
        },
      });
      return;
    }

    if (product!.inDefaultWishlist) {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_DEWISHLIST_REQUEST,
          data: { entities: { product } },
        },
      });
      removeProductToWishlistMutation.mutate({ product: product! });
    } else {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_WISHLIST_REQUEST,
          data: { entities: { product } },
        },
      });
      addProductToWishlistMutation.mutate({ product: product! });
    }
  };

  return (
    <div style={style} className={className}>
      <Button
        onClick={toggleWishlist}
        isLoading={
          addProductToWishlistMutation.isPending ||
          removeProductToWishlistMutation.isPending
        }
        data={
          product?.inDefaultWishlist
            ? data?.wishlistedButton
            : data?.wishlistButton
        }
        config={
          product?.inDefaultWishlist
            ? config?.wishlistedButton
            : config?.wishlistButton
        }
      />
    </div>
  );
};

const MemoizedProductWishlistWidget = memo(ProductWishlistWidget);

export { MemoizedProductWishlistWidget as ProductWishlistWidget };
