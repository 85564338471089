import {
  Text,
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  Input,
  InputField,
  InputSlot,
  useForm,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Button } from '../../Button';
import { PhoneNumberInput } from '../../PhoneNumberInput';
import { TextField } from '../../TextField';

import type { PhoneNumber } from '@tectonic/types';
import type { FormSubmitHandler } from '@tectonic/uikit';
import type { FC } from 'react';
import type {
  ProfileEditFormProps,
  ProfileEditFormValue,
} from './ProfileEditForm.types';

const useDefaultValues = ({
  user,
  countries,
  defaultCountry,
}: Pick<
  ProfileEditFormProps,
  'user' | 'countries' | 'defaultCountry'
>): ProfileEditFormValue =>
  useMemo(() => {
    const country =
      countries.find(
        (c) => c.stdCodes[0] && user.phone?.startsWith(c.stdCodes[0])
      )! ?? defaultCountry;

    const phone = user.phone?.replace(country?.stdCodes[0] ?? '', '') ?? '';
    const phoneNumber: PhoneNumber = { country, phone };

    return {
      phoneNumber,
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email,
      dateOfBirth: user.dateOfBirth,
    };
  }, [user, countries]);

const ProfileEditForm: FC<ProfileEditFormProps> = ({
  data,
  user,
  config,
  onSubmit,
  countries,
  isSubmitting,
  defaultCountry,
  onApplyReferrerCode,
  defaultReferrerCode,
  hasApplyReferrerCodeError,
  isApplyingReferrerCodeSuccess,
}) => {
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  const [referrerCodeStyles, referrerCodeClassName] = useStyleConfigV2(
    config?.referrerCodeInput?.container
  );
  const defaultValues = useDefaultValues({ countries, user, defaultCountry });
  const [referrerCode, setReferrerCode] = useState(defaultReferrerCode ?? '');

  const { control } = useForm<ProfileEditFormValue>({
    defaultValues,
    mode: 'onChange',
  });

  const handleSubmit: FormSubmitHandler<ProfileEditFormValue> = ({
    data: value,
  }) => {
    onSubmit(
      { ...value, dateOfBirth: value.dateOfBirth || null },
      referrerCode
    );
  };

  const handleApply = () => {
    onApplyReferrerCode?.(referrerCode);
  };

  return (
    <Form
      control={control}
      onSubmit={handleSubmit}
      style={containerStyleConfig.style}
      className={clsx('flex flex-col', containerStyleConfig.className)}
    >
      <div className={clsx('flex gap-4')}>
        <div className="w-0 grow">
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <TextField
                data={data.firstNameInput.textField}
                config={config?.firstNameInput?.textField}
                value={field.value}
                disabled={isSubmitting}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
        </div>
        <div className="w-0 grow">
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <TextField
                data={data.lastNameInput.textField}
                config={config?.lastNameInput?.textField}
                value={field.value}
                disabled={isSubmitting}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
        </div>
      </div>
      {data.dateOfBirth && (
        <div className="grow">
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field }) => (
              <TextField
                type="date"
                disabled={isSubmitting}
                value={field.value ?? ''}
                data={data.dateOfBirth!.textField}
                config={config?.dateOfBirth?.textField}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
        </div>
      )}
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <TextField
            data={data.firstNameInput.textField}
            config={config?.firstNameInput?.textField}
            value={field.value}
            disabled
            onChange={(event) => {
              field.onChange(event.target.value);
            }}
          />
        )}
      />
      {!user.referrerCode &&
        onApplyReferrerCode &&
        !isApplyingReferrerCodeSuccess &&
        config?.referrerCodeInput?.isVisible && (
          <div style={referrerCodeStyles} className={referrerCodeClassName}>
            <Text
              data="Have a referral code?"
              className="body3 pb-2 pl-1.5 text-neutral-500"
            />
            <Input>
              <InputField
                value={referrerCode}
                placeholder="Referrer Code"
                size={config?.referrerCodeInput?.size ?? 'md'}
                onChange={(e) => setReferrerCode(e.target.value)}
              />
              <InputSlot onClick={handleApply}>
                <Button
                  data={{ text: 'Apply' }}
                  config={{
                    size: 'sm',
                    variant: 'ghost',
                    text: {
                      textTransform: 'uppercase',
                    },
                  }}
                />
              </InputSlot>
            </Input>
            {hasApplyReferrerCodeError && (
              <Text
                data="Invalid Referral Code"
                className="body3 text-danger-600 pt-2"
              />
            )}
          </div>
        )}
      {(user.referrerCode || isApplyingReferrerCodeSuccess) &&
        config?.referrerCodeInput?.isVisible && (
          <div className="text-success-600 bg-success-50 border-success-600 rounded-md border p-3">
            <Text
              className="body3 font-semibold"
              data="Referral Successfully Applied"
            />
            <Text
              className="body4 font-medium"
              data="You got coins worth ৳50 as reward"
            />
          </div>
        )}
      <Controller
        control={control}
        name="phoneNumber"
        render={({ field }) => (
          <PhoneNumberInput
            value={field.value}
            countries={countries}
            countryCode={{
              data: data.phoneNumberInput.countryCode?.select!,
              config: config?.phoneInput?.countryCode?.select!,
            }}
            onChange={(value) => field.onChange(value)}
            placeholder={data.phoneNumberInput.textField.placeholder}
          />
        )}
      />
      <div className={clsx('flex')}>
        <Button
          type="submit"
          className="w-0 grow"
          isLoading={isSubmitting}
          data={data.submitCta.button}
          config={config?.submitCta?.button}
        />
      </div>
    </Form>
  );
};

ProfileEditForm.displayName = 'ProfileEditForm';

export { ProfileEditForm };
