import { get, isNil, isString } from 'lodash-es';
/* eslint-disable no-restricted-syntax */
import { DataAccessorType } from '@tectonic/types';
import { useMemo } from 'react';
import { useElemasonContext } from '../contexts';
import { useElemasonCellContext } from '../contexts/ElemasonCellContext';
import { useElemasonPageContext } from '../contexts/ElemasonPageContext';

import type { DataAccessor, Maybe } from '@tectonic/types';

const getDataAccessorType = (value: unknown): Maybe<DataAccessorType> => {
  if (!isString(value)) {
    return null;
  }

  if (value.startsWith(DataAccessorType.Global)) {
    return DataAccessorType.Global;
  }

  if (value.startsWith(DataAccessorType.Local)) {
    return DataAccessorType.Local;
  }

  if (value.startsWith(DataAccessorType.This)) {
    return DataAccessorType.This;
  }

  if (value.startsWith(DataAccessorType.Route)) {
    return DataAccessorType.Route;
  }

  return null;
};

const getValueByAccessor = ({
  accessor,
  data,
  accessorType,
}: {
  accessor: string;
  accessorType: DataAccessorType;
  data: object;
}) => {
  // the format is `$$globalState.product` or  '$$scope.selectedVariant'
  // or $$this.productId
  const path = accessor.replace(`${accessorType}.`, '');
  const value = get(data, path, null) ?? null;
  return value;
};

const useAccessorValue = <T = unknown>({ data, accessor }: DataAccessor<T>) => {
  const { searchParams, routeParams } = useElemasonContext();
  const cellContext = useElemasonCellContext();
  const { data: pageData, scope } = useElemasonPageContext();
  return useMemo(() => {
    if (isNil(accessor)) {
      return data;
    }

    const accessorType = getDataAccessorType(accessor);

    let target;

    switch (accessorType) {
      case DataAccessorType.Global:
        target = pageData;
        break;
      case DataAccessorType.Local:
        target = scope;
        break;
      case DataAccessorType.This:
        target = cellContext;
        break;
      case DataAccessorType.Route:
        target = {
          ...routeParams,
          ...searchParams,
          __tt__internal_params: { routeParams, searchParams },
        };
        break;
      default:
        target = null;
        break;
    }

    if (!isNil(accessorType) && !isNil(target)) {
      return (
        getValueByAccessor({
          accessor,
          data: target as object,
          accessorType,
        }) ?? data
      );
    }

    return data;
  }, [accessor, pageData, data, scope, cellContext, routeParams, searchParams]);
};

export { useAccessorValue };
