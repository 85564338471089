import { useCallback, useEffect, useRef, useState } from 'react';

type SetIntervalReturnType = ReturnType<typeof setInterval>;

const isDelayValid = (delay: number | null) => {
  // Note: 0 is a valid value for delay.
  if (!delay && delay !== 0) {
    return false;
  }
  return true;
};

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  const timeout = useRef<SetIntervalReturnType | null>(null);
  const [isCleared, setIsCleared] = useState<boolean>(false);

  const clear = useCallback(() => {
    if (timeout.current) {
      setIsCleared(true);
      clearInterval(timeout.current);
    }
  }, [timeout, setIsCleared]);

  const reset = useCallback(() => {
    if (!isDelayValid(delay)) {
      return;
    }
    // Clear existing interval.
    clear();
    timeout.current = setInterval(() => savedCallback.current(), <number>delay);
    setIsCleared(false);
  }, [delay, clear, setIsCleared]);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!isDelayValid(delay)) {
      return clear;
    }

    timeout.current = setInterval(() => savedCallback.current(), <number>delay);
    return clear;
  }, [delay]);

  return { clear, reset, isCleared };
};

export { useInterval };
