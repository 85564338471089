const PLACE_HOLDER_REGEX = /\{(\w+?)}/g;

const populate = (
  template: string,
  placeholders: Record<string, unknown>
): string =>
  template.replaceAll(
    PLACE_HOLDER_REGEX,
    (match, name) => (placeholders[name] as string) ?? match
  );

const populateWithEncode = (
  template: string,
  placeholders: Record<string, unknown>
): string =>
  template.replaceAll(PLACE_HOLDER_REGEX, (match, name) =>
    encodeURIComponent((placeholders[name] as string) ?? match)
  );

const isBlank = (text?: string | null): boolean => (text ? !text.trim() : true);

export { isBlank, populate, populateWithEncode };
