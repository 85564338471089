import type { ProductVariant } from '@tectonic/types';
import type { BasisAttrs } from '../types';

const basisAttributes: BasisAttrs[] = [
  'basisAttr1',
  'basisAttr2',
  'basisAttr3',
];

const findCurrentVariant = (
  variants: ProductVariant[],
  currentAttr: BasisAttrs,
  currentValue: any,
  currentVariant: ProductVariant
) => {
  const otherAttributes = basisAttributes.filter(
    (attr) => attr !== currentAttr
  );

  return variants.find((v) =>
    otherAttributes.every(
      (attr) =>
        v[`${attr}Value`] === currentVariant[`${attr}Value`] &&
        v[`${currentAttr}Value`] === currentValue
    )
  );
};

const useVariantAttributes = (
  variants: ProductVariant[],
  selectedAttributes: Record<BasisAttrs, any>,
  currentVariant?: ProductVariant
) => {
  const attributes: Record<string, any> = {};

  variants.forEach((variant) => {
    basisAttributes.forEach((attr) => {
      if (variant[`${attr}Name`] !== undefined) {
        if (!attributes[attr]) {
          attributes[attr] = {
            options: [],
            selected: undefined,
            name: variant[`${attr}Name`],
            display: variant[`${attr}Display`],
            displayConf: variant[`${attr}DisplayConf`],
          };
        }

        if (
          !attributes[attr].options.some(
            (o: any) => o.value === variant[`${attr}Value`]
          )
        ) {
          const isSelected =
            selectedAttributes[attr] === variant[`${attr}Value`];

          const relevantVariant =
            findCurrentVariant(
              variants,
              attr,
              variant[`${attr}Value`],
              currentVariant ?? variant
            ) ?? variant;

          const option = {
            isSelected,
            value: relevantVariant[`${attr}Value`],
            display: relevantVariant[`${attr}Display`],
            displayConf: relevantVariant[`${attr}DisplayConf`],
            isOOS: relevantVariant?.stockStatus === 'OUT_OF_STOCK',
            variant: relevantVariant,
          };

          if (isSelected) {
            attributes[attr].selected = option;
          }

          attributes[attr].options.push(option);
        }
      }
    });
  });

  return attributes;
};

export { useVariantAttributes };
