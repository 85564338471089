import { Text } from '@tectonic/elemason-components';
import { toCurrency } from '@tectonic/utils';

import type { FC } from 'react';
import type { ProductMrpProps } from './ProductPricing.types';

const ProductMrp: FC<ProductMrpProps> = ({
  mrp,
  config,
  quantity = 1,
  ...props
}) => {
  const text = toCurrency(
    mrp.amount * quantity,
    mrp.currencyCode,
    config?.numberFormatOptions
  );

  if (!config?.visible) return null;

  return (
    <Text
      config={config?.text}
      placeholders={{ mrp: text }}
      data={config?.template ?? text}
      {...props}
    />
  );
};

ProductMrp.displayName = 'ElemasonProductMrp';

export { ProductMrp };
