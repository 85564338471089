import { isEqual, keyBy } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { useHaloScript } from '../../../../hooks';
import { useSearchFacets } from '../../../../hooks/network';

import type {
  ElemasonSearchFilterWidget,
  Nil,
  ProductsSearchFacetsApiRouteData,
  SearchFacetConfigWithValues,
  SearchFacetResult,
  SearchFacetValue,
  SearchFilter,
} from '@tectonic/types';

const useFacets = (
  data?: Nil<ProductsSearchFacetsApiRouteData>
): SearchFacetConfigWithValues[] =>
  useMemo(() => {
    if (!data) {
      return [];
    }

    const { searchConfig, facets } = data;
    const configs = [...searchConfig.config.facetConfig.options].sort(
      (facetA, facetB) =>
        facetA.displaySequenceIndex - facetB.displaySequenceIndex
    );

    const results = keyBy<SearchFacetResult>(facets, 'fieldName');

    return configs.map((config) => {
      const values: SearchFacetValue[] = results[config.name]?.values ?? [];
      return { ...config, values };
    });
  }, [data]);

const useSearchFilterDrawer = (widget: ElemasonSearchFilterWidget) => {
  const implicitFilters = useHaloScript(widget.data?.implicitFilters);
  const {
    isError: hasError,
    data,
    isLoading,
    onApplyFacets,
    onPreviewFacets: onPreviewSearchFacets,
    onResetFacets: onResetSearchFacets,
  } = useSearchFacets(widget.data?.searchConfig, implicitFilters);

  const { appliedFilters } = data ?? {};

  const facets = useFacets(data);

  // We refresh the preview only when the refinements have changed for better
  // experience. There for we store previous refinements in ref.
  const prevAppliedFiltersRef = useRef<Nil<SearchFilter[]>>(null);

  const onPreviewFacets = useCallback(
    (filters: SearchFilter[]) => {
      const prevFilters = prevAppliedFiltersRef.current;
      if (!isEqual(filters, prevFilters)) {
        onPreviewSearchFacets(filters);
      }
      prevAppliedFiltersRef.current = filters;
    },
    [facets, prevAppliedFiltersRef, onPreviewSearchFacets]
  );

  const onResetFacets = useCallback(() => {
    onResetSearchFacets();
    prevAppliedFiltersRef.current = null;
  }, [onResetSearchFacets]);

  return {
    isLoading,
    hasError,
    facets,
    appliedFilters,
    onApplyFacets,
    onResetFacets,
    onPreviewFacets,
  };
};

export { useSearchFilterDrawer };
