import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  ElemasonWidgetType,
  NavigationActionType,
} from '@tectonic/types';
import { populate } from '@tectonic/utils';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { memo, useCallback, type FC } from 'react';
import { ProductList as ProductListContent } from '../../components';
import { useElemasonAnalyticsContext } from '../../contexts';
import { useActionDispatch } from '../../hooks';
import { useProductSearch } from '../../hooks/network';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { useHaloScript } from '../../hooks/useHaloScript';
import { useImpressionLedger } from '../../hooks/useImpressionLedger';
import { flattenSource } from '../../utils/flattenSource';
import { useProductMap } from '../../utils/productList';
import { ProductListHeader } from './ProductListHeader';

import type {
  ElemasonProductListWidgetHeaderData,
  ElemasonTemplatedProductListWidget,
  ImpressionLedgerEntry,
  Product,
  SearchQuerySource,
} from '@tectonic/types';

const populateQuerySource = (
  querySource: SearchQuerySource,
  placeholders: Record<string, string>
) => {
  if (isEmpty(placeholders)) {
    return querySource;
  }
  return JSON.parse(populate(JSON.stringify(querySource), placeholders));
};

interface ElemasonTemplatedProductListWidgetProps {
  widget: ElemasonTemplatedProductListWidget;
}

const TemplatedProductListWidget: FC<
  ElemasonTemplatedProductListWidgetProps
> = ({ widget }) => {
  const { id: widgetId, data, config: productListConfig } = widget;
  const analyticsContext = useElemasonAnalyticsContext();
  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    productListConfig
  );

  const actionDispatch = useActionDispatch();

  const titlePlaceholders =
    useHaloScript<Record<string, string>>(data?.header?.title?.placeholders) ??
    {};
  const sourcePlaceholders =
    useHaloScript<Record<string, string>>(data?.header?.source?.placeholders) ??
    {};
  const subtitlePlaceholders =
    useHaloScript<Record<string, string>>(
      data?.header?.subtitle?.placeholders
    ) ?? {};
  const querySourcePlaceholders =
    useHaloScript<Record<string, string>>(data?.sourcePlaceholders) ?? {};

  const headerData: ElemasonProductListWidgetHeaderData = {
    title: data?.header?.title?.text
      ? populate(data?.header?.title?.text || '', titlePlaceholders)
      : undefined,
    subtitle: data?.header?.subtitle?.text
      ? populate(data?.header?.subtitle?.text || '', subtitlePlaceholders)
      : undefined,
    source: data?.header?.source?.text
      ? populate(data?.header?.source?.text || '', sourcePlaceholders)
      : undefined,
  };

  const enrichedSource =
    !data?.source || isEmpty(querySourcePlaceholders)
      ? data?.source
      : populateQuerySource(data.source, querySourcePlaceholders);

  const { style, className } = useStyleConfig(config?.container ?? {});

  const { hits, found, isError, isLoading } = useProductSearch(
    widgetId,
    enrichedSource,
    config?.maxProductToDisplay
  );

  const onSourceClick = () => {
    const fSource = flattenSource(enrichedSource);
    actionDispatch(
      {
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PATH,
          slug: 'plp',
          search: fSource,
        },
      },
      analyticsContext
    );
  };

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });

    // trackProductEvent(
    //   ProductEventNames.PRODUCT_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsProductEventPayload>(
    //     {
    //       index,
    //       product,
    //     },
    //     widget
    //   )
    // );

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PDP,
        route: {
          slug: product.slug,
        },
      },
    });
  };

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );
  const productMap = useProductMap(hits ?? [], found ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  const onInViewChange = (viewProduct: Product, inView: boolean) => {
    onProductInViewChange(viewProduct, inView);
  };

  if (!isLoading && isEmpty(hits)) {
    return null;
  }

  return (
    <div style={style} className={clsx('flex w-full flex-col', className)}>
      <ProductListHeader
        count={found}
        isError={isError}
        data={headerData}
        isLoading={isLoading}
        config={config?.header}
        onSourceClick={onSourceClick}
      />
      <ProductListContent
        data={data}
        count={found}
        config={config}
        products={hits}
        isError={isError}
        isLoading={isLoading}
        onSourceClick={onSourceClick}
        onProductClick={onProductClick}
        onProductInViewChange={onInViewChange}
      />
    </div>
  );
};

const MemoizedTemplatedProductListWidget = memo(TemplatedProductListWidget);

export { MemoizedTemplatedProductListWidget as TemplatedProductListWidget };
