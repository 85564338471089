import { Cell, ElemasonFragmentProvider } from '@tectonic/elemason';
import { useAuthOtpConfirmation } from './hooks';

import type { FC } from 'react';
import type { AuthOtpConfirmationWidgetProps } from './AuthOtpConfirmation.types';

const AuthOtpConfirmationWidget: FC<AuthOtpConfirmationWidgetProps> = ({
  widget,
}) => {
  const { fragment, fragmentData } = useAuthOtpConfirmation(widget);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

AuthOtpConfirmationWidget.displayName = 'AuthOtpConfirmationWidget';

export { AuthOtpConfirmationWidget };
