import {
  NavigationActionType,
  type Product,
  type ProductListProductCardHandle,
  type ProductVariant,
} from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useElemasonContext } from '../../../../contexts';
import { ElemasonFragmentProvider } from '../../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../../core/Cell';
import { usePageFragment } from '../../../../hooks';
import { useFragmentValue } from '../../../../hooks/useFragmentValue';
import { isOutOfStock } from '../../../../utils';
import { SeoLink } from '../../../SeoLink';

import type { ForwardRefRenderFunction } from 'react';

interface ProductListProductCardProps {
  index: number;
  count: number;
  product: Product;
  productFragment?: string;
  selectedVariant?: ProductVariant;
  onClick?: (product: Product) => void;
  onVariantSelect?: (variant: ProductVariant) => void;
  onInViewChange?: (product: Product, inView: boolean) => void;
}

const ProductListProductCard: ForwardRefRenderFunction<
  ProductListProductCardHandle,
  ProductListProductCardProps
> = (
  {
    index,
    count,
    product,
    onClick,
    selectedVariant: parentVariant,
    onVariantSelect,
    onInViewChange,
    productFragment,
  },
  ref
) => {
  const fragment = usePageFragment(productFragment);
  const productCardRef = useRef<HTMLDivElement>(null);
  const [activeVariant, setActiveVariant] = useState<ProductVariant>(
    parentVariant ??
      product.variants?.find((v) => !isOutOfStock(v.stockStatus)) ??
      product.variants!?.[0]
  );

  const selectedVariant = parentVariant ?? activeVariant;
  // Default variant: variant selected from backend.
  const preselectedVariant = useMemo(() => {
    if (!product) {
      return null;
    }
    return (
      product.variants?.find((variant) => variant.isCurrentlySelected) ?? null
    );
  }, [product]);

  useImperativeHandle(
    ref,
    () => ({ productCardRef, productSlug: product.slug }),
    [productCardRef, product]
  );

  const { ref: inViewRef } = useInView({
    onChange: (inView: boolean) => onInViewChange?.(product, inView),
    // delay to avoid jittery scroll
    delay: 300,
    threshold: 0.7,
  });

  const handleVariantSelect = ({ variant }: { variant: ProductVariant }) => {
    setActiveVariant(variant);
    onVariantSelect?.(variant);
  };

  const { navigationRegistry } = useElemasonContext();
  const href = navigationRegistry.getHref({
    type: NavigationActionType.PDP,
    route: { slug: product.slug },
  });

  const fragmentValue = useFragmentValue(fragment);

  if (!fragment) return null;

  return (
    <Clickable
      ref={(el) => {
        inViewRef(el);
        // @ts-ignore
        productCardRef.current = el;
      }}
      className=""
      onClick={() => onClick?.(product)}
    >
      <SeoLink href={href}>{product.title}</SeoLink>
      <ElemasonFragmentProvider
        value={fragmentValue({
          index,
          count,
          product,
          selectedVariant,
          preselectedVariant,
          onVariantSelect: handleVariantSelect,
        })}
      >
        {fragment?.cells.map((cell, idx) => (
          <Cell key={cell.id ?? idx} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </Clickable>
  );
};

const ExoticProductListProductCard = forwardRef(ProductListProductCard);

ProductListProductCard.displayName = 'ElemasonProductListProductCard';

export { ExoticProductListProductCard as ProductListProductCard };
