import { useStyleConfig } from '@tectonic/elemason-components';
import { Carousel, Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { useHaloScript } from '../../hooks/useHaloScript';

import type {
  ElemasonCarouselBannerWidgetConfig,
  ElemasonWidgetAction,
  HaloScript,
} from '@tectonic/types';
import type { ComponentProps, FC, MouseEvent } from 'react';

interface CarouselSlideProps
  extends Omit<ComponentProps<typeof Carousel.Slide>, 'onClick'> {
  config?: ElemasonCarouselBannerWidgetConfig['carousel']['slide'];
  action?: HaloScript<ElemasonWidgetAction>;
  onClick?: (action?: ElemasonWidgetAction) => void;
}

const CarouselSlide: FC<CarouselSlideProps> = ({
  children,
  config,
  style,
  onClick,
  action: sAction,
  className,
  ...props
}) => {
  const action = useHaloScript(sAction);
  const { style: slideStyle, className: slideClassName } = useStyleConfig(
    config ?? {}
  );

  const handleOnClick = (e: MouseEvent) => {
    if (action) {
      e.preventDefault();
      e.stopPropagation();
      onClick?.(action);
    } else {
      onClick?.();
    }
  };

  return (
    <Carousel.Slide
      style={{ ...slideStyle, ...style }}
      className={clsx(slideClassName, className)}
      {...props}
    >
      {onClick ? (
        <Clickable onClick={handleOnClick}>{children}</Clickable>
      ) : (
        children
      )}
    </Carousel.Slide>
  );
};

export { CarouselSlide };
