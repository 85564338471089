import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { memo, useRef, type FC, type MouseEvent } from 'react';
import { Button } from '../../../components';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../../contexts';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { ActionDispatchExporter } from '../../../core';
import { Cell } from '../../../core/Cell';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useActionDispatch, usePageFragment } from '../../../hooks';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { queryKeys } from '../../../queryKeys';
import { isOutOfStock } from '../../../utils';

import type { Cart, ElemasonAddToCartWidget } from '@tectonic/types';
import type { AxiosError } from 'axios';
import type { DispatchExporterHandle } from '../../../core';

interface ElemasonAddToCartWidgetProps {
  widget: ElemasonAddToCartWidget;
}

const AddToCartWidget: FC<ElemasonAddToCartWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const dispatch = useActionDispatch();
  const items = useHaloScript(data?.items);
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const toastCtaLabel = useHaloScript(data?.toastCtaLabel);

  let variant = useHaloScript(data?.variant);
  const product = useHaloScript(data?.product);
  const callback = useHaloScript(data?.callback);
  const quantity = useHaloScript(data?.quantity);
  const discountId = useHaloScript(data?.discountId);
  const userProfileData = useHaloScript(data?.userProfileData);

  if (variant === undefined && !items) {
    variant = product?.variants?.find((v) => !isOutOfStock(v.stockStatus));
  }

  const variants =
    items && items.length > 0
      ? items
      : [{ variant, quantity: quantity ?? 1, userProfileData, discountId }];

  const analyticsContext = useElemasonAnalyticsContext();

  const dispatchRef = useRef<DispatchExporterHandle>(null);

  const mutation = useMutation({
    mutationFn: () => {
      if (variant) {
        dispatchRef.current?.dispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_REQUEST,
            data: {
              quantity: quantity ?? 1,
            },
          },
        });
      }

      return remixApi.addToCart(
        variants
          .filter((item) => !isOutOfStock(item.variant!.stockStatus))
          .map((item) => ({
            quantity: item.quantity!,
            variantId: item.variant!.id,
            discountId: item.discountId,
            userProfileData: item.userProfileData,
          }))
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      if (variant) {
        dispatchRef.current?.dispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_SUCCESS,
            data: {
              quantity: quantity ?? 1,
              entities: {
                cart: updatedCart,
              },
            },
          },
        });
      }

      const queryCache = queryClient.getQueryCache();
      const activeCartQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'cart' && key.length === 1);

      activeCartQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (_cart: Cart) => updatedCart);
      });

      const toastAction = toastCtaLabel
        ? {
            label: toastCtaLabel,
            onClick: () => {
              actions?.onToastClick?.forEach((action) => dispatch(action));
            },
          }
        : undefined;

      showToast({
        title: 'Product added to cart',
        duration: toastAction ? 2000 : 2000,
        action: toastAction,
      });
      callback?.(product!, variant!, { success: updatedCart });
      actions?.onSuccess?.forEach((action) =>
        dispatch(action, undefined, true)
      );
    },
    onError: (error: AxiosError) => {
      const title = getErrorMessage(error, {}, 'Failed to add product to cart');

      if (variant) {
        dispatchRef.current?.dispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_ERROR,
            data: {
              quantity: quantity ?? 1,
              error,
            },
          },
        });
      }

      showToast({ title, duration: 5000 });
      callback?.(product!, variant!, { error });
      actions?.onError?.forEach((action) => dispatch(action, undefined));
    },
  });

  const onClick = (e?: MouseEvent) => {
    e?.stopPropagation();
    mutation.mutate();
  };

  if (!variant && !(items && items.length > 0)) return null;

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: {
          ...(analyticsContext.entities ?? {}),
          product,
          productVariant: variant,
        },
      }}
    >
      <ActionDispatchExporter ref={dispatchRef} />
      {fragment ? (
        <ElemasonFragmentProvider
          value={fragmentValue({
            items,
            product,
            onClick,
            isLoading: mutation.isPending,
            disabled: variant ? isOutOfStock(variant.stockStatus) : false,
          })}
        >
          {fragment?.cells.map((cell, idx) => (
            <Cell key={cell.id ?? idx} cell={cell} />
          ))}
        </ElemasonFragmentProvider>
      ) : (
        <Button
          data={data}
          onClick={onClick}
          config={config?.button}
          size={config?.button?.size}
          isLoading={mutation.isPending}
          variant={config?.button?.variant}
          modifier={config?.button?.modifier}
          disabled={variant ? isOutOfStock(variant.stockStatus) : false}
          style={{
            color: config?.button?.text?.color,
            borderColor: config?.button?.borderColor,
            borderRadius: config?.button?.borderRadius,
            backgroundColor: config?.button?.backgroundColor,
          }}
        />
      )}
    </ElemasonAnalyticsProvider>
  );
};

const MemoizedAddToCartWidget = memo(AddToCartWidget);

export { MemoizedAddToCartWidget as AddToCartWidget };
