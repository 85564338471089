import { useBorderConfig, useTextConfig } from '@tectonic/elemason-components';
import { Input2, InputField2 } from '@tectonic/uikit';
import clsx from 'clsx';
import { useSharedLocalState } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonInputWidget2 } from '@tectonic/types';
import type { ChangeEvent, FC } from 'react';

interface InputWidget2Props {
  widget: ElemasonInputWidget2;
}

const InputWidget2: FC<InputWidget2Props> = ({ widget: { config, data } }) => {
  const stateKey = useHaloScript(data?.stateKey!)!;
  const defaultValue = useHaloScript(data?.defaultValue!);
  const { sharedState, setSharedState } = useSharedLocalState(
    stateKey,
    defaultValue ?? ''
  );

  const [inputTextStyle, inputTextClasses] = useTextConfig(
    config?.input?.text ?? {}
  );

  const [borderStyles, borderClassName] = useBorderConfig(
    config?.input?.border ?? {}
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (config?.input?.maxLength) {
      setSharedState(value.slice(0, config?.input?.maxLength));
    } else {
      setSharedState(e.target.value);
    }
  };

  return (
    <Input2
      style={borderStyles}
      size={config?.input?.size}
      rounded={config?.input?.rounded}
      className={clsx(borderClassName)}
    >
      <InputField2
        className={clsx('w-full', inputTextClasses)}
        style={{ ...inputTextStyle, color: config?.input?.text?.color }}
        max={config?.input?.max}
        min={config?.input?.min}
        disabled={data?.disabled}
        type={config?.input?.type}
        placeholder={data?.placeholder}
        pattern={config?.input?.pattern}
        required={config?.input?.required}
        minLength={config?.input?.minLength}
        maxLength={config?.input?.maxLength}
        autoFocus={config?.input?.autoFocus}
        value={sharedState ?? defaultValue ?? ''}
        onChange={onChange}
      />
    </Input2>
  );
};

export { InputWidget2 };
