type EnvRecord = Record<string, unknown>;

const envs: EnvRecord = {};
const browserEnvs: EnvRecord = {};

const setEnv = (key: string, value: unknown) => {
  envs[key] = value;
};

const setBrowserEnv = (key: string, value: unknown) => {
  browserEnvs[key] = value;
};

const populateEnvs = (env: EnvRecord) => {
  Object.entries(env)
    .filter(([key]) => key.startsWith('REMIX_'))
    .map(
      ([key, value]) => [key.replace('REMIX_', ''), value] as [string, unknown]
    )
    .map(
      ([key, value]) => [key.replace('PUBLIC_', ''), value] as [string, unknown]
    )
    .forEach(([key, value]) => setEnv(key, value));

  Object.entries(env)
    .filter(([key]) => key.startsWith('REMIX_PUBLIC_'))
    .map(
      ([key, value]) =>
        [key.replace('REMIX_PUBLIC_', ''), value] as [string, unknown]
    )
    .forEach(([key, value]) => setBrowserEnv(key, value));

  setEnv('SENTRY_DSN', env.SENTRY_DSN);
  setBrowserEnv('SENTRY_DSN', env.SENTRY_DSN);
};

const getEnvs = () => envs;
const getBrowserEnvs = () => browserEnvs;

export { getBrowserEnvs, getEnvs, populateEnvs };
