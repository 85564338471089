import { useStyleConfig } from '@tectonic/elemason-components';
import { useCarousel } from '@tectonic/uikit';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFragmentValue, useHaloScript, usePageFragment } from '../../hooks';

import type { ElemasonHeadlessCarouselWidget } from '@tectonic/types';

type UseCarouselArgs = Parameters<typeof useCarousel>[0];

const useCarouselStyleConfig = (widget: ElemasonHeadlessCarouselWidget) => {
  const wConfig = widget.config ?? {};
  const { thumbnails } = wConfig;

  return {
    main: {
      viewport: useStyleConfig(wConfig?.viewport ?? {}),
      container: useStyleConfig(wConfig?.container ?? {}),
      slide: useStyleConfig(wConfig?.slide ?? {}),
    },

    thumb: {
      viewport: useStyleConfig(thumbnails?.viewport ?? {}),
      container: useStyleConfig(thumbnails?.container ?? {}),
      slide: useStyleConfig(thumbnails?.slide ?? {}),
    },
  };
};

const useList = (widget: ElemasonHeadlessCarouselWidget) => {
  const wData = widget.data!;
  const list = useHaloScript(wData.list) as Array<unknown>;
  const listRef = useRef(list);

  const mList = useMemo(() => {
    if (isEqual(listRef.current, list)) {
      return listRef.current;
    }
    listRef.current = list;
    return listRef.current;
  }, [listRef, list]);

  return mList;
};

const useHeadlessCarousel = (widget: ElemasonHeadlessCarouselWidget) => {
  const wData = widget.data!;
  const wConfig = widget.config;
  const list = useList(widget);
  const {
    thumbnails,
    viewport: mViewport,
    container: mContainer,
    ...mainCarouselConfig
  } = wConfig ?? {};

  const styleConfig = useCarouselStyleConfig(widget);

  const {
    viewport: tViewport,
    container: tContainer,
    ...thumbCarouselConfig
  } = thumbnails ?? {};

  let startIndex = useHaloScript<number>(wData.startIndex);
  startIndex = +(startIndex ?? 0);

  const hasThumbnails = !!thumbnails?.enable;

  const [selectedSlideIndex, setSelectedSlideIndex] = useState(startIndex);

  const [mainCarouselRef, mainCarouselApi] = useCarousel({
    ...(mainCarouselConfig as UseCarouselArgs),
  });

  //   TODO: Initialize thumbnail carousel only if it is enabled.
  const [thumbCarouselRef, thumbCarouselApi] = useCarousel({
    ...(thumbCarouselConfig as UseCarouselArgs),
  });

  const onThumbClick = useCallback(
    (index: number) => {
      if (!mainCarouselApi || !thumbCarouselApi) {
        return;
      }
      mainCarouselApi.scrollTo(index);
    },
    [mainCarouselApi, thumbCarouselApi]
  );

  const onSelect = useCallback(() => {
    if (!mainCarouselApi || !thumbCarouselApi) {
      return;
    }
    setSelectedSlideIndex(mainCarouselApi.selectedScrollSnap());
    thumbCarouselApi.scrollTo(mainCarouselApi.selectedScrollSnap());
  }, [mainCarouselApi, thumbCarouselApi, setSelectedSlideIndex]);

  const onScrollPrev = useCallback(() => {
    if (!mainCarouselApi || !thumbCarouselApi) {
      return;
    }
    mainCarouselApi.scrollPrev();
  }, [mainCarouselApi, thumbCarouselApi]);

  const onScrollNext = useCallback(() => {
    if (!mainCarouselApi || !thumbCarouselApi) {
      return;
    }
    mainCarouselApi.scrollNext();
  }, [mainCarouselApi, thumbCarouselApi]);

  const mainSlideFragment = usePageFragment(wData.slideFragment);
  const mainSlideFragmentValue = useFragmentValue(mainSlideFragment);
  const thumbSlideFragment = usePageFragment(wData?.thumbnailFragment);
  const thumbSlideFragmentValue = useFragmentValue(thumbSlideFragment);

  useEffect(() => {
    if (!mainCarouselApi) {
      return () => {};
    }
    onSelect();

    mainCarouselApi.on('select', onSelect);
    mainCarouselApi.on('reInit', onSelect);
    return () => {
      mainCarouselApi.off('reInit', onSelect);
      mainCarouselApi.off('select', onSelect);
    };
  }, [mainCarouselApi, onSelect]);

  // TODO: Uncomment this to navigate to selected index.
  useEffect(() => {
    if (!mainCarouselApi) {
      return;
    }
    setTimeout(() => {
      mainCarouselApi?.scrollTo(startIndex);
    }, 100);
  }, [startIndex, mainCarouselApi]);

  return {
    styleConfig,
    list,
    hasThumbnails,
    mainSlideFragment,
    thumbSlideFragment,
    thumbSlideFragmentValue,
    mainSlideFragmentValue,
    mainCarouselRef,
    thumbCarouselRef,
    onThumbClick,
    onScrollNext,
    onScrollPrev,
    selectedSlideIndex,
  };
};

export { useHeadlessCarousel };
