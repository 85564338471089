import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { useColors } from '@tectonic/uikit';
import clsx from 'clsx';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonColorWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonColorWidgetProps {
  widget: ElemasonColorWidget;
}

const ColorWidget: FC<ElemasonColorWidgetProps> = ({
  widget: { data, config },
}) => {
  const colors = useColors();
  const color = useHaloScript(data?.color);
  const [style, className] = useStyleConfigV2(config?.container);

  return (
    <div
      className={clsx('flex', className)}
      style={{ ...style, background: colors[color!] ?? color }}
    />
  );
};

export { ColorWidget };
