import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Button } from '../../../../components';

import type { FC } from 'react';
import type { SearchFilterDrawerFooterProps } from './SearchFilterDrawer.types';

const SearchFilterDrawerFooter: FC<SearchFilterDrawerFooterProps> = ({
  disableControls,
  onApply,
  onReset,
  data,
  config,
}) => {
  const { container, resetCta, submitCta } = config ?? {};
  const containerStyleConfig = useStyleConfig(container ?? {});

  return (
    <div
      style={containerStyleConfig.style}
      className={clsx(
        'flex items-center bg-white',
        containerStyleConfig.className
      )}
    >
      <div className="flex grow justify-center">
        <Button
          onClick={onReset}
          disabled={disableControls}
          config={resetCta?.button}
          data={data.resetCta}
        />
      </div>

      <div className="flex grow justify-center">
        <Button
          onClick={onApply}
          disabled={disableControls}
          config={submitCta?.button}
          data={data.submitCta}
        />
      </div>
    </div>
  );
};

SearchFilterDrawerFooter.displayName = 'SearchFilterDrawerFooter';

export { SearchFilterDrawerFooter };
