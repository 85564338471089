enum AnalyticsProductEventNames {
  PRODUCT_VIEW = 'Product View',
  PRODUCT_CLICK = 'Product Click',
  PRODUCT_IMPRESSION = 'Product Impression',
  COLLECTION_IMPRESSION = 'Collection Impression',
  REPEATER_ITEM_IMPRESSION = 'Repeater Item Impression',

  PRODUCT_VARIANTS_CLICK = 'Product Variants Click',

  PRODUCT_WISHLIST_REQUEST = 'Product Wishlist Request',
  PRODUCT_WISHLIST_SUCCESS = 'Product Wishlist Success',
  PRODUCT_WISHLIST_ERROR = 'Product Wishlist Error',
  PRODUCT_DEWISHLIST_REQUEST = 'Product De-wishlist Request',
  PRODUCT_DEWISHLIST_SUCCESS = 'Product De-wishlist Success',
  PRODUCT_DEWISHLIST_ERROR = 'Product De-wishlist Error',
}

export { AnalyticsProductEventNames };
