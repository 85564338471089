import { createContext } from '@tectonic/utils';

interface CountdownContextType {
  days: string;
  hours: string;
  ended: boolean;
  minutes: string;
  seconds: string;
}

const errorMessage =
  'useCountdownContext must be inside a CountdownProvider with a value';

const name = 'CountdownContext';

const [CountdownProvider, useCountdownContext, CountdownContext] =
  createContext<CountdownContextType>({ errorMessage, name });

export { CountdownContext, CountdownProvider, useCountdownContext };
