import { useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  Typography,
  useForm,
} from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import clsx from 'clsx';
import { Button } from '../../Button';
import { TextField } from '../../TextField';

import type { FormSubmitHandler } from '@tectonic/uikit';
import type { FC } from 'react';
import type {
  AuthPasswordRecoveryFormProps,
  AuthPasswordRecoveryFormValue,
} from './AuthPasswordRecoveryForm.types';

const defaultValues: AuthPasswordRecoveryFormValue = {
  email: '',
};

// TODO: Delete this and use `EmailInputForm`
const AuthPasswordRecoveryForm: FC<AuthPasswordRecoveryFormProps> = ({
  isLoading,
  data,
  onSubmit,
  config,
}) => {
  const { control, setError, formState } =
    useForm<AuthPasswordRecoveryFormValue>({
      defaultValues,
      mode: 'onChange',
    });
  const { errors } = formState;

  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  const handleSubmit: FormSubmitHandler<AuthPasswordRecoveryFormValue> = (
    formData
  ) => {
    const value = formData.data;
    if (isBlank(value.email)) {
      setError('email', {
        type: 'custom',
        message: data.emailInput.textField.required!,
      });
      return;
    }
    onSubmit(value);
  };

  return (
    <Form
      control={control}
      onSubmit={handleSubmit}
      style={containerStyleConfig.style}
      className={clsx('flex flex-col', containerStyleConfig.className)}
    >
      <div className={clsx('flex flex-col gap-2')}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              data={data.emailInput.textField}
              config={config?.emailInput?.textField}
              value={field.value}
              type="email"
              disabled={isLoading}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        <FormErrorMessage
          name="email"
          errors={errors}
          as={Typography}
          className="text-danger-600"
          variant="body3"
        />
      </div>
      <div className="flex">
        <Button
          isLoading={isLoading}
          className="grow"
          type="submit"
          data={data.submitCta.button}
          config={config?.submitCta?.button}
        />
      </div>
    </Form>
  );
};

AuthPasswordRecoveryForm.displayName = 'AuthPasswordRecoveryForm';

export { AuthPasswordRecoveryForm };
