import { toTailwindUtility } from '@tectonic/utils';
import clsx from 'clsx';
import { merge } from 'lodash-es';
import { useBackgroundConfig } from './useBackgroundConfig';
import { useBackgroundImageConfig } from './useBackgroundImageConfig';
import { useBorderConfig } from './useBorderConfig';
import { useDimensionConfig } from './useDimensionConfig';
import { useGapConfig } from './useGapConfig';
import { useMarginConfig } from './useMarginConfig';
import { useOpacityConfig } from './useOpacityConfig';
import { useOverflowConfig } from './useOverflowConfig';
import { usePaddingConfig } from './usePaddingConfig';
import { usePositionConfig } from './usePositionConfig';
import { useShadowConfig } from './useShadowConfig';

import type { StyleConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useTransitionConfig = (config: StyleConfig) => {
  const {
    transitionDuration,
    transitionTimingFunction,
    transitionProperty,
    transition,
    animation,
    animationTimeline,
    animationDelay,
  } = config;

  const style: CSSProperties = {
    transitionDuration,
    transitionTimingFunction,
    transitionProperty,
    animation,
    animationDelay,
    animationTimeline,
  };

  return [style, toTailwindUtility('transition', transition)];
};

const useStyleConfig = (config: StyleConfig) => {
  const shadowClasses = useShadowConfig(config);
  const opacityStyles = useOpacityConfig(config);
  const overflowClasses = useOverflowConfig(config);
  const dimensionStyles = useDimensionConfig(config);
  const [gapStyles, gapClasses] = useGapConfig(config);
  const backgroundStyles = useBackgroundConfig(config);
  const [borderStyles, borderClasses] = useBorderConfig(config);
  const [marginStyles, marginClasses] = useMarginConfig(config);
  const backgroundImageStyles = useBackgroundImageConfig(config);
  const [paddingStyles, paddingClasses] = usePaddingConfig(config);
  const [positionStyles, positionClasses] = usePositionConfig(config);
  const [transitionStyles, transitionClasses] = useTransitionConfig(config);

  const className = clsx(
    gapClasses,
    borderClasses,
    marginClasses,
    shadowClasses,
    paddingClasses,
    positionClasses,
    overflowClasses,
    transitionClasses
  );

  const style = merge(
    gapStyles,
    marginStyles,
    borderStyles,
    opacityStyles,
    paddingStyles,
    positionStyles,
    dimensionStyles,
    backgroundStyles,
    transitionStyles,
    backgroundImageStyles
  );

  return { className, style };
};

const useStyleConfigV2 = (config?: StyleConfig): [CSSProperties, string] => {
  const { style, className } = useStyleConfig(config ?? {});
  return [style, className];
};

export { useStyleConfig, useStyleConfigV2 };
