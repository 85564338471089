import { Logger } from '@tectonic/logger';
import { canUseDOM } from '@tectonic/utils';
import { trackEvent } from './event';

import type { SearchQuerySource } from '@tectonic/types';
import type { AnalyticsSearchEventPayload } from '../types';

const getPayloadFromQuerySource = (searchSource?: SearchQuerySource) => {
  if (!searchSource) {
    return {};
  }

  const url = canUseDOM ? new URL(window.location.href) : null;
  const { q, filterBy = '' } = (searchSource.query as any) ?? {};
  const searchTerm = url?.searchParams.get('rawQuery') ?? q;
  const payload = { searchTerm, filters: JSON.stringify(filterBy) };
  return payload;
};

const trackSearchEvent = (
  event: string,
  payload: AnalyticsSearchEventPayload
) => {
  try {
    const { querySource, ...rest } = payload;
    const qPayload = getPayloadFromQuerySource(querySource);
    const finalPayload = { ...qPayload, ...rest };
    trackEvent(event, finalPayload);
  } catch (error) {
    Logger.error('[trackSearchEvent]: Unable to track search event', error);
  }
};

export { trackSearchEvent };
