import { Logger } from '@tectonic/logger';

const isGtagAvailable = () => typeof globalThis.gtag === 'function';

const getValue = (key: string) =>
  new Promise((resolve) => {
    const isAvailable = isGtagAvailable();
    if (!isAvailable) {
      resolve(null);
      return;
    }

    globalThis.gtag('get', globalThis.env.GTAG_ID, key, resolve);
  });

const getClientId = async () => {
  try {
    const value = await getValue('client_id');
    return value;
  } catch (error) {
    Logger.error(error);
  }
  return null;
};

const getSessionId = async () => {
  try {
    const value = await getValue('session_id');
    return value;
  } catch (error) {
    Logger.error(error);
  }
  return null;
};

export { getClientId, getSessionId };
