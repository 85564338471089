import { toTailwindUtility } from '@tectonic/utils';
import { compact, isNil } from 'lodash-es';

import type { MarginConfig, RealSpaceScaleConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const isRealSpaceScale = (keyInput: string): keyInput is RealSpaceScaleConfig =>
  [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '0.5',
    '1.5',
    '2.5',
    '3.5',
    '4.5',
    '5.5',
    '-0',
    '-1',
    '-2',
    '-3',
    '-4',
    '-5',
    '-6',
    '-7',
    '-8',
    '-9',
    '-10',
    '-11',
    '-12',
    '-13',
    '-14',
    '-15',
    '-16',
    '-17',
    '-18',
    '-19',
    '-20',
    '-21',
    '-22',
    '-23',
    '-24',
    '-25',
    '-0.5',
    '-1.5',
    '-2.5',
    '-3.5',
    '-4.5',
    '-5.5',
    'auto',
  ].includes(keyInput);

const useMarginConfig = (config: MarginConfig) => {
  const {
    margin,
    marginTop,
    marginEnd,
    marginBottom,
    marginStart,
    marginX,
    marginY,
  } = config;

  const marginStyles: CSSProperties = {};

  if (!isNil(margin) && !isRealSpaceScale(margin)) {
    marginStyles.margin = margin;
  }

  if (!isNil(marginTop) && !isRealSpaceScale(marginTop)) {
    marginStyles.marginTop = marginTop;
  }

  if (!isNil(marginEnd) && !isRealSpaceScale(marginEnd)) {
    marginStyles.marginRight = marginEnd;
  }

  if (!isNil(marginBottom) && !isRealSpaceScale(marginBottom)) {
    marginStyles.marginBottom = marginBottom;
  }

  if (!isNil(marginStart) && !isRealSpaceScale(marginStart)) {
    marginStyles.marginLeft = marginStart;
  }

  if (!isNil(marginX) && !isRealSpaceScale(marginX)) {
    marginStyles.marginLeft = marginX;
    marginStyles.marginRight = marginX;
  }

  if (!isNil(marginY) && !isRealSpaceScale(marginY)) {
    marginStyles.marginTop = marginY;
    marginStyles.marginBottom = marginY;
  }

  const marginClasses = [
    toTailwindUtility('m', margin),
    toTailwindUtility('mt', marginTop),
    toTailwindUtility('mb', marginBottom),
    toTailwindUtility('ml', marginStart),
    toTailwindUtility('mr', marginEnd),
    toTailwindUtility('mx', marginX),
    toTailwindUtility('my', marginY),
  ];

  return [marginStyles, compact(marginClasses)];
};

export { isRealSpaceScale, useMarginConfig };
