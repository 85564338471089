import { useStyleConfig } from '@tectonic/elemason-components';
import { SearchSortFacetSkeleton } from './SearchSortFacetSkeleton';
import { SearchSortOption } from './SearchSortOption';

import type { FC } from 'react';
import type { SearchSortFacetProps } from './SearchSortFacet.types';

const SearchSortFacet: FC<SearchSortFacetProps> = ({
  config,
  data,
  onSelect,
  isLoading,
  sortBy,
  sortFacet,
}) => {
  const { facet, facetList } = config ?? {};
  const listContainerStyleConfig = useStyleConfig(facetList?.container ?? {});
  const options = sortFacet?.options ?? [];

  if (isLoading || !sortFacet) {
    return <SearchSortFacetSkeleton config={config} />;
  }

  return (
    <div {...listContainerStyleConfig}>
      {options.map((option) => {
        const isApplied = option.value === sortBy;
        return (
          <SearchSortOption
            isApplied={isApplied}
            key={option.value}
            option={option}
            onSelect={onSelect}
            config={facet}
            data={data?.facet}
          />
        );
      })}
    </div>
  );
};

SearchSortFacet.displayName = 'ElemasonSearchSortFacet';

export { SearchSortFacet };
