import { Text } from '@tectonic/elemason-components';
import { toCurrency } from '@tectonic/utils';

import type { FC } from 'react';
import type { ProductPriceProps } from './ProductPricing.types';

const ProductPrice: FC<ProductPriceProps> = ({
  price,
  config,
  quantity = 1,
  ...props
}) => {
  const text = toCurrency(
    price.amount * quantity,
    price.currencyCode,
    config?.numberFormatOptions
  );

  if (!config?.visible) return null;

  return (
    <Text
      data={text}
      config={config?.text}
      style={{ order: config.index, ...props }}
      {...props}
    />
  );
};

ProductPrice.displayName = 'ElemasonProductPrice';

export { ProductPrice };
