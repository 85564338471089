import { Typography } from '@tectonic/uikit';
import { populate } from '@tectonic/utils';
import clsx from 'clsx';
import Mdx from 'markdown-to-jsx';
import { useTextConfig } from './hooks';

import type { TextConfig, TextData } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface TextProps
  extends Omit<
    ComponentProps<typeof Typography>,
    'children' | 'color' | 'variant'
  > {
  data?: TextData;
  config?: TextConfig;
  placeholders?: Record<string, string | number | boolean>;
}

const Text: FC<TextProps> = ({
  data,
  style,
  config,
  className,
  placeholders,
  ...props
}) => {
  const [textStyle, textClassName] = useTextConfig(config ?? {});

  if (!data) return null;

  const finalText = populate(
    typeof data === 'string' ? data : `${data}`,
    placeholders ?? {}
  );

  if (config?.markdown) {
    return (
      // @ts-ignore
      <div
        style={{ ...style, ...textStyle, color: config?.color }}
        className={clsx(className, textClassName, config.variant)}
        {...props}
      >
        <Mdx>{finalText}</Mdx>
      </div>
    );
  }

  return (
    <Typography
      color={config?.color}
      variant={config?.variant}
      style={{ ...style, ...textStyle }}
      className={clsx(className, textClassName)}
      {...props}
    >
      {finalText}
    </Typography>
  );
};

export { Text };
