/* eslint-disable max-len */
import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash-es';
import { memo, useCallback, useEffect, useMemo, useRef, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
  useElemasonCellContext,
} from '../../contexts';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import {
  ActionDispatchExporter,
  type DispatchExporterHandle,
} from '../../core';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useHaloScript } from '../../hooks/useHaloScript';
import { queryKeys } from '../../queryKeys';

import type {
  ElemasonProductWidget,
  ImpressionLedgerEntry,
  Nil,
  Product,
} from '@tectonic/types';
import type {
  ImpressionLedger,
  ProductImpressionPayloadMapType,
} from '../../hooks/useImpressionLedger';

interface ElemasonProductWidgetProps {
  widget: ElemasonProductWidget;
}

const ProductWidget: FC<ElemasonProductWidgetProps> = ({
  widget: { data, actions, config },
}) => {
  const id = useHaloScript(data?.id);
  const initial = useHaloScript(data?.product);
  const cellContext = useElemasonCellContext();
  const collection = useHaloScript(data?.collection);
  const productSlug = useHaloScript(data?.productSlug);
  const analyticsContext = useElemasonAnalyticsContext();
  const fragment = usePageFragment(data?.productFragment);
  const [style, className] = useStyleConfigV2(config?.container);

  const onExternalInViewChange = useHaloScript(data?.onInViewChange);

  const { impressionLedger, productImpressionPayloadMap } = cellContext;

  // TODO Move the impression to action dispatcher framework as well,
  // if & when breaking down the list/grid components
  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      if (isEmpty(productImpressionPayloadMap) || isEmpty(impressionLedger)) {
        return;
      }

      const entry = {
        ...(
          productImpressionPayloadMap as unknown as ProductImpressionPayloadMapType
        ).get(`${viewProduct.id}`),
        inView,
      };
      (impressionLedger as unknown as ImpressionLedger).setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productImpressionPayloadMap]
  );

  const onInViewChange = (viewProduct: Nil<Product>, inView: boolean) => {
    if (isEmpty(viewProduct)) {
      return;
    }
    onExternalInViewChange?.(inView);
    onProductInViewChange(viewProduct, inView);
  };

  const slug = productSlug ?? initial?.slug ?? '';

  const { data: product } = useQuery({
    enabled: !initial,
    initialData: initial,
    refetchOnMount: !initial,
    queryFn: () => (slug ? remixApi.getProduct(slug) : null),
    queryKey: queryKeys.product(productSlug ?? initial?.slug ?? ''),
  });

  const { ref: inViewRef } = useInView({
    onChange: (inView: boolean) => onInViewChange?.(product, inView),
    // delay to avoid jittery scroll
    delay: 300,
    threshold: 0.7,
  });

  const actionDispatchRef = useRef<DispatchExporterHandle>(null);
  const isTrackedRef = useRef<boolean>(false);

  // Default variant: variant selected from backend.
  const preselectedVariant = useMemo(() => {
    if (!product) {
      return null;
    }
    return (
      product.variants?.find((variant) => variant.isCurrentlySelected) ?? null
    );
  }, [product]);

  useEffect(() => {
    const actionDispatch = actionDispatchRef.current?.dispatch;
    if (
      isEmpty(actions?.onMount) ||
      isNil(actionDispatch) ||
      isTrackedRef.current
    ) {
      return;
    }
    actions?.onMount.forEach((action) => {
      isTrackedRef.current = true;
      actionDispatch(action);
    });
  }, [actions, actionDispatchRef.current, isTrackedRef.current]); // For some reason needed the current

  const fragmentValue = useFragmentValue(fragment);

  if (!fragment) return null;

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: {
          ...analyticsContext.entities,
          product: product ?? undefined,
        },
      }}
    >
      <ActionDispatchExporter ref={actionDispatchRef} />
      <ElemasonFragmentProvider
        value={fragmentValue({ product, preselectedVariant, collection })}
      >
        <div
          id={id}
          style={style}
          ref={inViewRef}
          className={clsx('flex flex-row', className)}
        >
          {fragment?.cells.map((cell, index) => (
            <Cell key={cell.id ?? index} cell={cell} />
          ))}
        </div>
      </ElemasonFragmentProvider>
    </ElemasonAnalyticsProvider>
  );
};

const MemoizedProductWidget = memo(ProductWidget);

export { MemoizedProductWidget as ProductWidget };
