import type { OpacityConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useOpacityConfig = (config: OpacityConfig): CSSProperties => {
  const { opacity } = config;
  if (opacity === undefined) return {};
  return { opacity };
};

export { useOpacityConfig };
