import { Logger } from '@tectonic/logger';
import {
  getBlogUrl,
  removeParentBreadcrumb,
  sanitizeParentMeta,
} from './utils';

import type { DehydratedState } from '@tanstack/react-query';
import type { Blog } from '@tectonic/types';
import type {
  MetaFunctionArgs,
  MetaFunctionUrls,
  SeoTemplates,
} from './meta.types';

const getMetadata = (dehydratedState: DehydratedState) => {
  // temp changes. solve it properly
  const queries = dehydratedState?.queries ?? [];
  const [aux, blogQuery] = queries;
  const blog = (blogQuery?.state?.data as any)?.data?.blog;
  return { blog };
};

const getBreadcrumbJsonLd = (
  blog: Blog,
  seoTemplates: SeoTemplates,
  urls: MetaFunctionUrls
) => {
  const { name } = blog;
  const blogUrl = getBlogUrl(blog, urls);

  const breadcrumb = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: new URL(urls.requestUrl).origin,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name,
        item: blogUrl,
      },
    ],
  };

  return [breadcrumb];
};

const getOgTags = (
  blog: Blog,
  seoTemplates: SeoTemplates,
  urls: MetaFunctionUrls
) => {
  try {
    const { name } = blog;
    const ogTags = [
      {
        tagName: 'link',
        rel: 'canonical',
        href: getBlogUrl(blog, urls),
      },
      // TODO: Use proper description fields when we implement SEO.
      {
        property: 'keywords',
        content: `${name}, Vaaree, vaaree.com`, // TODO solve this via seoTemplates
      },
      {
        property: 'og:url',
        content: getBlogUrl(blog, urls),
      },
      {
        property: 'og:title',
        content: name,
      },
    ];

    return ogTags;
  } catch (e) {
    Logger.error(e, 'Error in generating og tags');
    return [];
  }
};

const getJsonLdTags = ({
  blog,
  urls,
  seoTemplates,
}: {
  blog: Blog;
  urls: MetaFunctionUrls;
  seoTemplates: SeoTemplates;
}) => {
  const breadcrumbs = getBreadcrumbJsonLd(blog, seoTemplates, urls);

  return [...breadcrumbs].map((ld) => ({ 'script:ld+json': ld }));
};

const blogMetaFunction = (
  args: MetaFunctionArgs,
  seoTemplates: SeoTemplates
) => {
  const { data, matches } = args;
  const { dehydratedState, urls } = data!;
  if (!dehydratedState) {
    return matches.flatMap((match) => match.meta);
  }
  let parentMeta = sanitizeParentMeta(matches).filter(
    (tag) => tag.rel !== 'canonical'
  );
  parentMeta = removeParentBreadcrumb(parentMeta);
  const { blog } = getMetadata(dehydratedState as DehydratedState);

  if (!blog) {
    return matches.flatMap((match) => match.meta);
  }
  // console.log("blog", blog);
  const ogTags = getOgTags(blog!, seoTemplates, urls);
  const jsonLdTags = getJsonLdTags({ blog, seoTemplates, urls });
  return [...ogTags, ...parentMeta, ...jsonLdTags];
};

export { blogMetaFunction };
