import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { memo, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonFragmentWidget } from '@tectonic/types';

interface ElemasonFragmentWidgetProps {
  widget: ElemasonFragmentWidget;
}

const FragmentWidget: FC<ElemasonFragmentWidgetProps> = ({ widget }) => {
  const data = useHaloScript(widget.data?.data);
  const fragment = usePageFragment(widget.data?.fragment);
  const fragmentValue = useFragmentValue(fragment);

  const { direction, ...restConfig } = widget.config?.container ?? {};

  const [style, className] = useStyleConfigV2(restConfig);

  return (
    <div
      style={style}
      className={clsx(
        'flex',
        className,
        direction === 'col' ? 'flex-col' : 'flex-row'
      )}
    >
      <ElemasonFragmentProvider value={fragmentValue(data)}>
        {fragment?.cells?.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    </div>
  );
};

FragmentWidget.displayName = 'FragmentWidget';

const MemoizedFragmentWidget = memo(FragmentWidget);

export { MemoizedFragmentWidget as FragmentWidget };
