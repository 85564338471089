import { createContext } from '@tectonic/utils';

import type {
  ElemasonConfig,
  ElemasonFragment,
  ElemasonWidgetAction,
} from '@tectonic/types';
import type { Dispatch } from 'react';
import type { ElemasonAnalyticsContextType } from './ElemasonAnalyticsContext';

interface ElemasonPageContextType {
  data: unknown;
  config: ElemasonConfig;
  scope: Record<string, unknown>;
  rootFragments?: ElemasonFragment[];
  pageFragments?: ElemasonFragment[];
  drawerFragments: ElemasonFragment[];
  isReady?: boolean;
  setScope: Dispatch<Record<string, unknown>>;
  removeDrawerFragments: (slug: string) => void;
  addDrawerFragments: (slug: string, fragments: ElemasonFragment[]) => void;
  dispatch: (
    action: ElemasonWidgetAction,
    analyticsContext?: ElemasonAnalyticsContextType
  ) => void;
}

const errorMessage =
  'useElemasonPageContext must be inside a ElemasonPageProvider with a value';
const name = 'ElemasonPageContext';

const [ElemasonPageProvider, useElemasonPageContext] =
  createContext<ElemasonPageContextType>({ errorMessage, name });

// Elemason page context is page level context. It provides page level info
export { ElemasonPageProvider, useElemasonPageContext };
