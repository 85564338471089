/* eslint-disable no-nested-ternary */
import { Text } from '@tectonic/elemason-components';
import { useThemeContext } from '@tectonic/uikit';
import {
  addDays,
  addSeconds,
  format,
  formatDistance,
  isAfter,
  isSameDay,
} from 'date-fns';
import { isNumber } from 'lodash-es';
import { memo, useMemo, type FC } from 'react';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonDateWidget } from '@tectonic/types';
import type { Color } from '@tectonic/uikit';

type CutOff = {
  timestamp: number;
  timestampUnit: 'seconds' | 'minutes' | 'hours';
  delta?: number;
};
interface ElemasonDateWidgetProps {
  widget: ElemasonDateWidget;
}

function isNDaysFromToday(n: number, givenDate: Date) {
  const today = new Date();
  const targetDate = addDays(today, n);
  return isSameDay(givenDate, targetDate);
}

const adjustDate = (date: Date, cutoff?: CutOff) => {
  if (!cutoff) {
    return date;
  }
  const nDate = new Date(date);
  const { timestamp, timestampUnit, delta = 1 } = cutoff;
  let cutoffTimeInMilliseconds = 0;

  // Convert the cutoff timestamp into milliseconds based on the provided unit
  switch (timestampUnit) {
    case 'seconds':
      cutoffTimeInMilliseconds = timestamp * 1000;
      break;
    case 'minutes':
      cutoffTimeInMilliseconds = timestamp * 60 * 1000;
      break;
    case 'hours':
      cutoffTimeInMilliseconds = timestamp * 60 * 60 * 1000;
      break;
    default:
      break;
  }
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  // Calculate the exact cutoff date and time by adding the cutoff time to the start of the day
  const cutoffDateTime = new Date(
    startOfDay.getTime() + cutoffTimeInMilliseconds
  );

  // If the current date time is past the cutoff, add the delta in days to the new date
  if (nDate > cutoffDateTime) {
    nDate.setDate(nDate.getDate() + (delta ?? 1));
  }

  return nDate;
};

const NAN_REGEX = /\bNaN\b/g;

const useDate = ({
  dateStr,
  cutoff,
  offsetSeconds,
  offsetDays,
}: {
  dateStr: string;
  offsetSeconds?: number;
  offsetDays?: number;
  cutoff?: CutOff;
}) =>
  useMemo(() => {
    let auxDate = dateStr === 'now' ? new Date() : new Date(dateStr);
    auxDate = cutoff ? adjustDate(auxDate, cutoff) : auxDate;
    return offsetSeconds
      ? addSeconds(auxDate, Math.floor(parseInt(`${offsetSeconds}`, 10)) || 0)
      : offsetDays
        ? addDays(auxDate, Math.floor(parseInt(`${offsetDays}`, 10)) || 0)
        : auxDate;
  }, [dateStr]);

const useMaxDate = (widget: ElemasonDateWidget) => {
  const maxOffsetDays = useHaloScript(widget.data?.maxOffsetDays);
  return useMemo(() => {
    if (!isNumber(maxOffsetDays)) {
      return null;
    }
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + maxOffsetDays);
    maxDate.setHours(0, 0, 0, 0);

    return maxDate;
  }, [maxOffsetDays]);
};

const useFormattedDate = ({
  date,
  formatStr,
  mode,
}: {
  mode?: 'distance' | 'normal';
  date: Date;
  formatStr: string;
}) =>
  useMemo(() => {
    try {
      switch (mode) {
        case 'distance':
          return formatDistance(date, Date.now());
        default:
          return format(date, formatStr);
      }
    } catch (err) {
      // couldn't formate date.
    }
    return null;
  }, [date, formatStr, mode]);

const DateWidget: FC<ElemasonDateWidgetProps> = ({ widget }) => {
  const { data, config } = widget;
  const { colors } = useThemeContext();
  const text = useHaloScript(data?.text);
  const dateStr = useHaloScript(data?.date) ?? 'now';
  const nthDayTexts = useHaloScript(data?.nthDayTexts);
  const formatStr = useHaloScript(config?.format) ?? '';
  const placeholders = useHaloScript(data?.placeholders);
  const offsetDays = useHaloScript(data?.offsetDays) ?? 0;
  const offsetSeconds = useHaloScript(data?.offsetSeconds) ?? 0;
  const cutoff = useHaloScript(data?.cutoff);

  let overrideText;

  const maxDate = useMaxDate(widget);
  const dateObj = useDate({ dateStr, cutoff, offsetDays, offsetSeconds });

  if (maxDate && isAfter(dateObj, maxDate)) {
    dateObj.setDate(maxDate.getDate());
  }

  const date = useFormattedDate({
    date: dateObj,
    formatStr,
    mode: config?.mode,
  });

  if (!date || NAN_REGEX.test(date)) {
    return '';
  }

  if (nthDayTexts) {
    for (const nthDay of Object.keys(nthDayTexts)) {
      if (isNDaysFromToday(parseInt(nthDay, 10), dateObj)) {
        overrideText = nthDayTexts[parseInt(nthDay, 10)];
        break;
      }
    }
  }

  return (
    <Text
      data={overrideText ?? text}
      config={{
        ...config?.text,
        color: colors[config?.text?.color as Color] ?? config?.text?.color,
      }}
      placeholders={{ ...placeholders, date }}
    />
  );
};

const MemoizedDateWidget = memo(DateWidget);

export { MemoizedDateWidget as DateWidget };
