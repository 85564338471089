import { useStyleConfigV2 } from '@tectonic/elemason-components';
import {
  HoverCardContent,
  HoverCardPortal,
  HoverCardRoot,
  HoverCardTrigger,
} from '@tectonic/uikit';
import clsx from 'clsx';
import deepmerge from 'deepmerge';
import { useState, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';

import type { ElemasonMenuWidget } from '@tectonic/types';

interface ElemasonMenuWidgetProps {
  widget: ElemasonMenuWidget;
}

const MenuWidget: FC<ElemasonMenuWidgetProps> = ({ widget }) => {
  const { config } = widget;
  const wData = widget.data!;
  const trigger = usePageFragment(wData.trigger);
  const triggerValue = useFragmentValue(trigger);
  const content = usePageFragment(wData.content);
  const contentValue = useFragmentValue(trigger);

  const { openDelay, closeDelay } = wData;

  const [isOpen, setIsOpen] = useState(false);

  const triggerConfig = deepmerge(
    config?.trigger?.container ?? {},
    (isOpen ? config?.trigger?.open : ({} as any)) ?? {}
  );
  const [tStyle, tClassName] = useStyleConfigV2(triggerConfig);

  const [cStyle, cClassName] = useStyleConfigV2(
    config?.content?.container ?? {}
  );

  return (
    <HoverCardRoot
      open={isOpen}
      onOpenChange={(value) => setIsOpen(value)}
      openDelay={openDelay}
      closeDelay={closeDelay}
    >
      <HoverCardTrigger
        asChild
        style={tStyle}
        className={clsx('cursor-pointer', tClassName)}
      >
        <div>
          <ElemasonFragmentProvider value={triggerValue({ isOpen })}>
            {trigger?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
          </ElemasonFragmentProvider>
        </div>
      </HoverCardTrigger>

      <HoverCardPortal>
        <HoverCardContent
          style={cStyle}
          className={clsx('cursor-pointer', cClassName)}
        >
          <ElemasonFragmentProvider value={contentValue({ isOpen })}>
            {content?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
          </ElemasonFragmentProvider>
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCardRoot>
  );
};

export { MenuWidget };
