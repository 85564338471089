import { Logger } from '@tectonic/logger';
import { get, isEmpty, isNil, isObject, isString, pick } from 'lodash-es';
import { getProperties, track } from '../analytics';
import { useAnalyticsStore } from '../hooks';

import type { Nil } from '@tectonic/types';
import type {
  AnalyticsEvenOptions,
  AnalyticsEventParamsWithData,
  AnalyticsEventPayload,
} from '../types';

const toErrorMessage = (error?: Nil<unknown>): Nil<string> => {
  if (isNil(error)) {
    return null;
  }
  if (isString(error)) {
    return error;
  }

  const response = get(error, 'response.data.error.reasons', null);
  if (response) {
    return JSON.stringify(response);
  }

  return JSON.stringify(pick(error, ['code', 'message']));
};

// Analytics event params represents the common event params to be send with every
// event whenever available. We send common params as `params` and event data as `data`
// https://www.notion.so/tectonic-technologies/Hermes-Client-Payload-22521accf8484a97b5efc6fb19fa33d8?pvs=4
const toAnalyticsEventPayload = (
  eventData: Partial<AnalyticsEventPayload>
): AnalyticsEventParamsWithData => {
  const state = useAnalyticsStore.getState();
  const {
    screenName,
    pageId,
    pageVersion,
    pageSlug,
    source,
    widgetId,
    widgetVersion,
    // TODO: remove widget type, post integration with page service. WidgetId
    // should be sufficient.
    widgetType,
    previousScreenName,
    previousPageSlug,
    previousPageId,
    previousUrl,
    previousPageVersion,
    // error message.
    error,
    ...data
  } = eventData;

  const message = toErrorMessage(error);

  if (message) {
    data.message = message;
  }

  const { currentPage, previousPage } = state;

  const params = {
    pageId: pageId ?? currentPage?.pageId,
    pageVersion: pageVersion ?? currentPage?.pageVersion,
    pageSlug: pageSlug ?? currentPage?.pageSlug,
    screenName: screenName ?? currentPage?.screenName,
    source,
    widgetId,
    widgetVersion,
    widgetType,
    previousPageId: previousPageId ?? previousPage?.pageId,
    previousUrl: previousUrl ?? state.previousUrl,
    previousPageSlug: previousPageSlug ?? previousPage?.pageSlug,
    previousScreenName: previousScreenName ?? previousPage?.screenName,
    previousPageVersion: previousPageVersion ?? previousPage?.pageVersion,
    userAgent: navigator.userAgent,
  };

  return { data, params };
};

const santizePropertyValue = (value: unknown) => {
  try {
    if (isObject(value)) {
      return isEmpty(value) ? "" : `${JSON.stringify(value)}`
    }
    return `${value}`
  } catch (err) {
    Logger.warn("Error in sanitizing analytics prop value")
    return ""
  }
}

const getAnalyticsEventAttributes = async (timeout: number) => {
  const properties = await getProperties(timeout);
  return Object.entries(properties)
    .filter(([_, value]) => !isNil(value))
    .map(([key, value]) => ({ key, value: santizePropertyValue(value) }))
    .filter(({ value }) => !isEmpty(value));
};

// Generic event tracker. In most cases, it should be enough. If you need a special
// event tracker, feel free to create one. eg. trackSearchEvent, trackCartEvent
// where search and cart event requires some data to be extracted from the payload
// and formatted in a particular way.
// DO NOT BLOAT `trackEvent` FUNCTION.
const trackEvent = (
  event: string,
  data: Partial<AnalyticsEventPayload>,
  options?: AnalyticsEvenOptions
) => {
  // TODO: Discuss this with backend. Client should not care about events
  // that should be disabled/filtered. Backend can always chose to ignore those
  // events if they are not needed.
  // if (globalThis.env.DISABLED_EVENTS.includes(event)) return;
  const payload = toAnalyticsEventPayload(data);
  return track(event, payload, options);
};

export { getAnalyticsEventAttributes, toAnalyticsEventPayload, trackEvent };
