import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import {
  ElemasonWidgetActionType,
  type ElemasonPage,
  type Look,
  type Product,
  type ProductSearchResponse,
} from '@tectonic/types';
import { useToast } from '../core/ElemasonEntry/Toast';
import { queryKeys } from '../queryKeys';
import { useActionDispatch } from './useActionDispatch';

import type { InfiniteData } from '@tanstack/react-query';

const useAddProductToWishlistMutation = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const actionDispatch = useActionDispatch();
  const mutation = useMutation({
    mutationFn: async ({ product }: { product: Product }) => {
      const data = await remixApi.addProductToWishlist(product.id);
      return data;
    },

    onSuccess: (_, { product }) => {
      const queryCache = queryClient.getQueryCache();

      const activeLookQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'look');

      const pdpQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'page' && key[1] === 'pdp');
      const collectionPdpQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'page' && key[1] === 'collection-pdp');
      const activeProductQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'product');

      const activeProductSlugQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'product-' && key[1] === product!.slug);

      const activeProductInfiniteSearchQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'search' && key[1] === 'product');

      const collectionProductSearchKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'collection-search');

      const autosuggestProductSearchKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'product' && key[1] === 'suggestions');

      autosuggestProductSearchKeys.forEach((key) => {
        queryClient.setQueryData(key, (data: any) => ({
          ...data,
          data: {
            ...data.data,
            hits: data.data?.hits?.map((hit) => ({
              ...hit,
              inDefaultWishlist:
                hit.id === product.id ? true : hit.inDefaultWishlist,
            })),
          }
        }));
      });

      activeLookQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (data: Look) => ({
          ...data,
          products: data.products.map((p) => ({
            ...p,
            product: {
              ...p.product,
              inDefaultWishlist:
                p.product.id === product.id
                  ? true
                  : p.product.inDefaultWishlist,
            },
          })),
        }));
      });

      pdpQueryKeys.forEach((key) => {
        queryClient.setQueryData(
          key,
          (data: { page: ElemasonPage; data: { product: Product } }) => {
            if (data.data.product.id !== product!.id) return data;
            return {
              ...data,
              data: {
                ...data.data,
                product: {
                  ...data.data.product,
                  inDefaultWishlist: true,
                },
              },
            };
          }
        );
      });
      collectionPdpQueryKeys.forEach((key) => {
        queryClient.setQueryData(
          key,
          (data: { page: ElemasonPage; data: { product: Product } }) => {
            if (data.data.product.id !== product!.id) return data;
            return {
              ...data,
              data: {
                ...data.data,
                product: {
                  ...data.data.product,
                  inDefaultWishlist: true,
                },
              },
            };
          }
        );
      });

      activeProductInfiniteSearchQueryKeys.forEach((key) => {
        queryClient.setQueryData(
          key,
          (data: InfiniteData<ProductSearchResponse>) => ({
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              hits: page?.hits?.map((hit) => ({
                ...hit,
                inDefaultWishlist:
                  hit.id === product.id ? true : hit.inDefaultWishlist,
              })),
            })),
          })
        );
      });

      activeProductQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (data: ProductSearchResponse) => ({
          ...data,
          hits: data?.hits?.map((hit) => ({
            ...hit,
            inDefaultWishlist:
              hit.id === product.id ? true : hit.inDefaultWishlist,
          })),
        }));
      });

      activeProductSlugQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (queryData: Product) => ({
          ...queryData,
          inDefaultWishlist: true,
        }));
      });

      collectionProductSearchKeys.forEach((key) => {
        queryClient.setQueryData(key, (data: ProductSearchResponse) => ({
          ...data,
          hits: data?.hits?.map((hit) => ({
            ...hit,
            inDefaultWishlist:
              hit.id === product.id ? true : hit.inDefaultWishlist,
          })),
        }));
      });

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_WISHLIST_SUCCESS,
          data: { entities: { product } },
        },
      });
    },
    onError: (e, { product }) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_WISHLIST_ERROR,
          data: { entities: { product } },
        },
      });
      const title = getErrorMessage(e, {}, 'Failed to add product to wishlist');
      Logger.error(e);
      showToast({ title });
    },
  });

  return mutation;
};

const useRemoveProductFromWishlistMutation = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const actionDispatch = useActionDispatch();

  const mutation = useMutation({
    mutationFn: ({ product }: { product: Product }) =>
      remixApi.removeProductFromWishlist(product!.id),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.wishlist() });
    },
    onSuccess: (_, { product }) => {
      const queryCache = queryClient.getQueryCache();

      const activeProductSlugQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'product-' && key[1] === product!.slug);

      const pdpQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'page' && key[1] === 'pdp');
      const collectionPdpQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'page' && key[1] === 'collection-pdp');

      const activeLookQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'look');

      const activeProductQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'product');

      const activeProductInfiniteSearchQueryKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'search' && key[1] === 'product');

      const collectionProductSearchKeys = queryCache
        .getAll()
        .map((cache) => cache.queryKey)
        .filter((key) => key[0] === 'collection-search');

        const autosuggestProductSearchKeys = queryCache
          .getAll()
          .map((cache) => cache.queryKey)
          .filter((key) => key[0] === 'product' && key[1] === 'suggestions');

        autosuggestProductSearchKeys.forEach((key) => {
          queryClient.setQueryData(key, (data: any) => ({
            ...data,
            data: {
              ...data.data,
              hits: data.data?.hits?.map((hit) => ({
                ...hit,
                inDefaultWishlist:
                  hit.id === product.id ? false : hit.inDefaultWishlist,
              })),
            },
          }));
        });

      activeProductSlugQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (queryData: Product) => ({
          ...queryData,
          inDefaultWishlist: false,
        }));
      });

      pdpQueryKeys.forEach((key) => {
        queryClient.setQueryData(
          key,
          (data: { page: ElemasonPage; data: { product: Product } }) => {
            if (data.data.product.id !== product!.id) return data;

            return {
              ...data,
              data: {
                ...data.data,
                product: {
                  ...data.data.product,
                  inDefaultWishlist: false,
                },
              },
            };
          }
        );
      });
      collectionPdpQueryKeys.forEach((key) => {
        queryClient.setQueryData(
          key,
          (data: { page: ElemasonPage; data: { product: Product } }) => {
            if (data.data.product.id !== product!.id) return data;

            return {
              ...data,
              data: {
                ...data.data,
                product: {
                  ...data.data.product,
                  inDefaultWishlist: false,
                },
              },
            };
          }
        );
      });

      activeLookQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (queryData: Look) => ({
          ...queryData,
          products: queryData.products.map((p) => ({
            ...p,
            product: {
              ...p.product,
              inDefaultWishlist:
                p.product.id === product!.id
                  ? false
                  : p.product.inDefaultWishlist,
            },
          })),
        }));
      });

      activeProductInfiniteSearchQueryKeys.forEach((key) => {
        queryClient.setQueryData(
          key,
          (queryData: InfiniteData<ProductSearchResponse>) => ({
            ...queryData,
            pages: queryData.pages.map((page) => ({
              ...page,
              hits: page?.hits?.map((hit) => ({
                ...hit,
                inDefaultWishlist:
                  hit.id === product!.id ? false : hit.inDefaultWishlist,
              })),
            })),
          })
        );
      });

      activeProductQueryKeys.forEach((key) => {
        queryClient.setQueryData(key, (queryData: ProductSearchResponse) => ({
          ...queryData,
          hits: queryData?.hits?.map((hit) => ({
            ...hit,
            inDefaultWishlist:
              hit.id === product!.id ? false : hit.inDefaultWishlist,
          })),
        }));
      });

      collectionProductSearchKeys.forEach((key) => {
        queryClient.setQueryData(key, (queryData: ProductSearchResponse) => ({
          ...queryData,
          hits: queryData?.hits?.map((hit) => ({
            ...hit,
            inDefaultWishlist:
              hit.id === product!.id ? false : hit.inDefaultWishlist,
          })),
        }));
      });

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_DEWISHLIST_SUCCESS,
          data: { entities: { product } },
        },
      });
    },
    onError: (e, { product }) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsProductEventNames.PRODUCT_DEWISHLIST_ERROR,
          data: { entities: { product } },
        },
      });
      const title = getErrorMessage(e, {}, 'Failed to add product to cart');
      Logger.error(e);
      showToast({ title });
    },
  });
  
  return mutation;
};

export {
  useAddProductToWishlistMutation,
  useRemoveProductFromWishlistMutation,
};
