import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { ElemasonAuthStep } from '@tectonic/types';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from '@tectonic/uikit';
import { Button, Loader, RenderBoundary } from '../../../../components';
import { ElemasonDrawerProvider } from '../../../../contexts';
import {
  ElemasonPageProvider,
  useElemasonPageContext,
} from '../../../../contexts/ElemasonPageContext';
import { queryKeys } from '../../../../queryKeys';
import { useAuthStore } from '../../../../store';
import { DrawerPage } from '../DrawerPage';
import { useLoginDrawer } from './hooks';

import type { FC } from 'react';
import type { LoginDrawerProps } from './LoginDrawer.types';

// We need to reload the page on close when a user is successfully authenticated
// and decided to close the popup without updating profile details.
const profileDetailsSteps = new Set<ElemasonAuthStep>([
  ElemasonAuthStep.PROFILE_DETAILS_OTP_CONFIRMATION,
  ElemasonAuthStep.PROFILE_DETAILS_OTP_CONFIRMATION,
]);

const LoginDrawer: FC<LoginDrawerProps> = () => {
  const { isOpen, close: onClose, slug } = useLoginDrawer();

  const currentStep = useAuthStore((state) => state.currentStep);

  const { data, isError, isLoading } = useQuery({
    queryKey: queryKeys.drawer(slug!),
    // TODO: Use function from remix-client-network module
    queryFn: () => remixApi.getPage(slug!),
    enabled: !!slug && isOpen,
  });
  const pageContextValue = useElemasonPageContext();
  const drawerPageContext = {
    ...pageContextValue,
    pageFragments: data?.page?.fragments,
  };

  const handleClose = () => {
    if (profileDetailsSteps.has(currentStep!)) {
      globalThis.location.reload();
    }
    onClose();
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={onClose} modal>
      <DialogPortal>
        <DialogOverlay className="pointer-events-auto fixed inset-0 z-20 bg-black/70" />
        <DialogContent className="relative">
          <ElemasonPageProvider value={drawerPageContext}>
            <ElemasonDrawerProvider
              value={{ slug: slug!, data, close: () => onClose() }}
            >
              <RenderBoundary
                isLoading={isLoading}
                loader={
                  <Loader className="-transform-x-1/2 -transform-y-1/2 fixed left-1/2 top-1/2 z-20" />
                }
                hasError={isError}
              >
                {data ? (
                  <DrawerPage
                    slug={data.page.slug ?? 'login-drawer'}
                    page={data.page}
                  />
                ) : null}
              </RenderBoundary>
              <div className="absolute end-0 top-1 z-20">
                <Button
                  onClick={handleClose}
                  config={{ size: 'md', variant: 'ghost' }}
                  data={{ startIcon: 'outline-close' }}
                />
              </div>
            </ElemasonDrawerProvider>
          </ElemasonPageProvider>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
};

LoginDrawer.displayName = 'LoginDrawer';

export { LoginDrawer };
