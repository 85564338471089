import {
  Text,
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import {
  type ElemasonProductListWidgetHeaderConfig,
  type ElemasonProductListWidgetHeaderData,
} from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { Icon, Shimmer } from '../../components';
import { isVisible } from '../../utils';

import type { FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import { useFragmentValue, usePageFragment } from '../../hooks';

type ProductListHeaderProps = {
  count?: number;
  isError: boolean;
  isLoading: boolean;
  data?: ElemasonProductListWidgetHeaderData;
  config?: ElemasonProductListWidgetHeaderConfig;
  onSourceClick?: () => void;
};

const isHeaderVisible = (
  config?: ElemasonProductListWidgetHeaderConfig,
  data?: ElemasonProductListWidgetHeaderData
) => {
  const { title, subtitle, source, headerFragment } = data ?? {};
  // Header is displayed only when it has something to show.
  return (
    (isVisible(config ?? {}) &&
      (Boolean(title) || Boolean(subtitle) || Boolean(source))) ||
    Boolean(headerFragment)
  );
};

const ProductListHeader: FC<ProductListHeaderProps> = ({
  data,
  count,
  config,
  isError,
  isLoading,
  onSourceClick,
}) => {
  const { title, source, subtitle, icon, headerFragment } = data ?? {};

  const showIcon = isVisible(config?.icon ?? {});
  const showTitle = isVisible(config?.title ?? {});
  const showHeader = isHeaderVisible(config, data);
  const showSource = isVisible(config?.source ?? {}) && !isError && !isLoading;
  const showSubtitle = isVisible(config?.subtitle ?? {});

  const containerProps = useStyleConfig(config?.container ?? {});
  const [headerStyles, headerClassName] = useStyleConfigV2(config);

  const headerFragments = usePageFragment(data?.headerFragment);
  const headerFragmentValue = useFragmentValue(headerFragments);

  if (!showHeader) {
    return null;
  }

  if (headerFragment) {
    return (
      <ElemasonFragmentProvider
        value={headerFragmentValue({ count, isLoading })}
      >
        {headerFragments?.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    );
  }

  if (isLoading) {
    return (
      <div {...containerProps}>
        <div className="flex flex-row items-center gap-2">
          <div className="grow">
            {showTitle && title && (
              <Shimmer>
                <Text
                  data={title}
                  config={config?.title}
                  className="invisible"
                />
              </Shimmer>
            )}
          </div>
          {showSource && source && (
            <Shimmer>
              <Clickable className="invisible">
                <Text
                  data={source}
                  config={config?.source}
                  placeholders={{ count: `${count ?? ''}` }}
                />
              </Clickable>
            </Shimmer>
          )}
        </div>
        {showSubtitle && subtitle && (
          <Shimmer>
            <Text
              data={subtitle}
              config={config?.subtitle}
              className="invisible"
            />
          </Shimmer>
        )}
      </div>
    );
  }

  return (
    <div {...containerProps}>
      <div className="flex flex-row items-center gap-2">
        <div className={clsx(headerClassName, 'grow')} style={headerStyles}>
          {showIcon && icon && <Icon symbol={icon} config={config?.icon} />}
          {showTitle && title && <Text data={title} config={config?.title} />}
        </div>
        {showSource && source && (
          <Clickable onClick={onSourceClick}>
            <Text
              data={source}
              config={config?.source}
              placeholders={{ count: `${count ?? ''}` }}
            />
          </Clickable>
        )}
      </div>
      {showSubtitle && subtitle && (
        <Text data={subtitle} config={config?.subtitle} />
      )}
    </div>
  );
};

ProductListHeader.displayName = 'ElemasonProductListHeader';

export { ProductListHeader };
