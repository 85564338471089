import { Link } from '@tectonic/elemason-components';
import clsx from 'clsx';

import type { ComponentProps } from 'react';

const SeoLink = ({
  className,
  children,
  ...rest
}: ComponentProps<typeof Link>) => (
  <Link
    className={clsx(
      'absolute start-0 top-0 -z-50 h-0 w-0 overflow-hidden',
      className
    )}
    {...rest}
  >
    {children}
  </Link>
);

export { SeoLink };
