import { forwardRef } from 'react';
import {
  ProductListItemElementType,
  type ProductListItemType,
} from './ProductList.types';
import { ProductListProductCard } from './ProductListCard/ProductListCard';
import { ProductListCardSkeleton } from './ProductListCard/ProductListCardSkeleton';
import { ProductListSourceCard } from './ProductListCard/ProductListSourceCard';

import type {
  ElemasonProductListWidget,
  ElemasonProductListWidgetSourceCardData,
  Product,
  ProductListProductCardHandle,
  ProductVariant,
} from '@tectonic/types';
import type { ForwardRefRenderFunction } from 'react';

type ProductListItemProps = {
  index: number;
  count?: number;
  item: ProductListItemType;
  onSourceClick: () => void;
  onProductClick: (product: Product) => void;
  config: ElemasonProductListWidget['config'];
  selectedVariants?: Record<string, ProductVariant>;
  onVariantSelect?: (product: Product, variant: ProductVariant) => void;
  onProductInViewChange?: (product: Product, inView: boolean) => void;
  data?: {
    productFragment?: string;
    sourceCardFragment?: string;
    sourceCard?: ElemasonProductListWidgetSourceCardData;
  };
};

const ProductListItem: ForwardRefRenderFunction<
  ProductListProductCardHandle,
  ProductListItemProps
> = (
  {
    item,
    index,
    config,
    count,
    data,
    onSourceClick,
    onProductClick,
    onVariantSelect,
    selectedVariants,
    onProductInViewChange,
  },
  ref
) => {
  if (item.type === ProductListItemElementType.Skeleton) {
    return <ProductListCardSkeleton config={config?.skeletonCard} />;
  }

  if (item.type === ProductListItemElementType.Source) {
    return (
      <ProductListSourceCard
        count={count}
        data={data?.sourceCard}
        config={config?.sourceCard}
        onSourceClick={onSourceClick}
        fragmentSlug={data?.sourceCardFragment}
      />
    );
  }

  return (
    <ProductListProductCard
      ref={ref}
      index={index}
      count={count!}
      product={item.data!}
      onClick={onProductClick}
      productFragment={data?.productFragment}
      selectedVariant={item.data ? selectedVariants?.[item.data.id] : undefined}
      onVariantSelect={
        onVariantSelect
          ? (variant) => item.data && onVariantSelect(item.data, variant)
          : undefined
      }
      onInViewChange={onProductInViewChange}
    />
  );
};

const ExoticProductListItem = forwardRef(ProductListItem);

export { ExoticProductListItem as ProductListItem };
