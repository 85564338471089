import {
  PortalRoot,
  SelectContent,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectViewport,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { useState } from 'react';
import { Button } from '../../Button';
import { SelectMenuHeader } from './SelectMenuHeader';
import { SelectMenuOptions } from './SelectMenuOptions';

import type { FC } from 'react';
import type { SelectMenuProps } from './SelectMenu.types';

const SelectMenu: FC<SelectMenuProps> = ({
  disabled,
  options,
  data,
  value,
  config,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { content: contentConfig } = config ?? {};

  const hasValue = !!value;

  const onValueChange = (name: string) => {
    const option = options.find((opt) => opt.name === name)!;
    onChange(option);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col">
      {isOpen ? (
        <PortalRoot asChild>
          <div className={clsx('fixed inset-0', 'z-20 ', 'bg-black/70')} />
        </PortalRoot>
      ) : null}
      <SelectRoot
        open={isOpen}
        disabled={disabled}
        value={value?.name}
        onOpenChange={setIsOpen}
        onValueChange={onValueChange}
      >
        <SelectTrigger asChild>
          {/* TODO: Create proper control for it instead of using button. */}
          <Button
            className="justify-between"
            config={{
              variant: 'outline',
              borderRadius: 'md',
              ...config?.trigger,
              text: { color: hasValue ? 'black' : 'neutral-400' },
            }}
            data={{
              endIcon: data.trigger.icon,
              text: value?.displayName ?? data.trigger.text,
            }}
          />
        </SelectTrigger>
        <SelectPortal>
          <SelectContent
            ref={(ref) =>
              ref?.addEventListener('touchend', (e) => e.preventDefault())
            }
            className={clsx('fixed inset-x-0 bottom-0 z-20 bg-white')}
          >
            <SelectViewport>
              <SelectMenuHeader
                ref={(ref) =>
                  ref?.addEventListener('touchend', () => onClose())
                }
                className="sticky top-0 bg-white"
                onClose={onClose}
                data={data.header}
                config={contentConfig?.header}
              />
              <SelectMenuOptions
                options={options}
                config={config?.content}
                value={value}
              />
            </SelectViewport>
          </SelectContent>
        </SelectPortal>
      </SelectRoot>
    </div>
  );
};

SelectMenu.displayName = 'SelectMenu';

export { SelectMenu };
