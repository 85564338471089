import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import { Logger } from '@tectonic/logger';
import {
  ElemasonWidgetActionType,
  ElemasonWidgetType,
  NavigationActionType,
} from '@tectonic/types';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { memo, useCallback, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductList as ProductListContent } from '../../components';
import { useElemasonAnalyticsContext } from '../../contexts';
import { useActionDispatch } from '../../hooks';
import { useRecentlyViewedProducts } from '../../hooks/network/useRecentlyViewed';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { useImpressionLedger } from '../../hooks/useImpressionLedger';
import { useProductMap } from '../../utils/productList';
import { ProductListHeader } from '../ProductList/ProductListHeader';

import type {
  ElemasonTemplatedProductListWidget,
  ImpressionLedgerEntry,
  Product,
} from '@tectonic/types';

interface ElemasonRecentlyViewedProductListWidgetProps {
  widget: ElemasonTemplatedProductListWidget;
}

const RecentlyViewedProductsWidget: FC<
  ElemasonRecentlyViewedProductListWidgetProps
> = ({ widget }) => {
  const { data, config: productListConfig } = widget;

  const analyticsContext = useElemasonAnalyticsContext();
  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    productListConfig
  );

  const { style, className } = useStyleConfig(config?.container ?? {});

  const { ref, inView: enabled } = useInView();

  const { hits, found, isError, isLoading } =
    useRecentlyViewedProducts(enabled);

  const onSourceClick = () => ({});

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );
  const productMap = useProductMap(hits ?? [], found ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  const onInViewChange = (viewProduct: Product, inView: boolean) => {
    onProductInViewChange(viewProduct, inView);
  };

  const actionDispatch = useActionDispatch();

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });

    // trackProductEvent(
    //   ProductEventNames.PRODUCT_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsProductEventPayload>(
    //     {
    //       index,
    //       product,
    //       widgetId,
    //       widgetVersion,
    //       widgetType,
    //     },
    //     widget
    //   )
    // );

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PDP,
        route: {
          slug: product.slug,
        },
      },
    });
  };

  if (isEmpty(hits) && (!isLoading || isError)) {
    Logger.debug('Empty result set for recently viewed');
    return null;
  }

  return (
    <div
      ref={ref}
      style={style}
      className={clsx('flex w-full flex-col', className)}
    >
      <ProductListHeader
        count={found}
        isError={isError}
        data={data?.header}
        isLoading={isLoading}
        config={config?.header}
      />
      <ProductListContent
        data={data}
        count={found}
        config={config}
        products={hits}
        isError={isError}
        isLoading={isLoading}
        onSourceClick={onSourceClick}
        onProductClick={onProductClick}
        onProductInViewChange={onInViewChange}
      />
    </div>
  );
};

const MemoRecentlyViewedProductsWidget = memo(RecentlyViewedProductsWidget);

export { MemoRecentlyViewedProductsWidget as RecentlyViewedProductsWidget };
