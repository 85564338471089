import { useQuery } from '@tanstack/react-query';
import {
  getCartRecommendations,
  getProductRecommendations,
} from '@tectonic/remix-client-network';
import { RecommendationEntity, RecommendationIntent } from '@tectonic/types';
import { queryKeys } from '../../queryKeys';

import type {
  Nil,
  RecommendationProductApiRouteLoaderResponse,
  RecommendationProductGetRecommendationPayload,
} from '@tectonic/types';

const useProductRecommendations = (
  payload?: Nil<RecommendationProductGetRecommendationPayload>,
  { enabled = true, queryKey }: { enabled?: boolean; queryKey?: string } = {}
) => {
  const intent = payload?.intent ?? RecommendationIntent.Related;
  const entity = payload?.entity ?? RecommendationEntity.Product;

  const qKeys = queryKeys.productRecommendations(payload?.identifier);
  const keys = queryKey ? [...qKeys, queryKey] : qKeys;

  const {
    isLoading,
    data: response,
    isError,
    refetch: onRetry,
  } = useQuery<RecommendationProductApiRouteLoaderResponse>({
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled,
    queryKey: keys,
    queryFn: () =>
      entity === RecommendationEntity.Cart
        ? getCartRecommendations({ ...payload, intent })
        : getProductRecommendations({ ...payload, intent }),
  });

  const { found: count = 0, hits: products = [] } = response ?? {};

  return {
    isLoading: isLoading || !enabled,
    count,
    products,
    isError,
    onRetry,
  };
};

export { useProductRecommendations };
