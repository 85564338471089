import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { Carousel as UiCarousel } from '@tectonic/uikit';
import clsx from 'clsx';
import { take } from 'lodash-es';
import { useCallback, useEffect, useRef, useState, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { ContentShimmer } from '../../../components';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { usePageFragment } from '../../../hooks';
import { useCollections } from '../../../hooks/network';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { CarouselSlide } from '../../CarouselBanner/CarouselSlide';

import type { CarouselHandle } from '@tectonic/uikit';
import type { CollectionBookWidgetProps } from './CollectionBook.types';

const CollectionBookWidget: FC<CollectionBookWidgetProps> = ({ widget }) => {
  const wData = widget.data!;
  const { config: wConfig } = widget;

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);

  const carouselFragment = usePageFragment(wData.carouselFragment);
  const carouselFragmentValue = useFragmentValue(carouselFragment);

  const carouselRef = useRef<CarouselHandle>(null);

  const [cStyle, cClassName] = useStyleConfigV2(
    wConfig?.carousel?.dimension ?? {}
  );

  const [paginationStyle, paginationClassName] = useStyleConfigV2(
    wConfig?.carousel?.pagination ?? {}
  );

  const pagination = useHaloScript(wData.pagination);
  const {
    collections,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    hasMore,
    hasPrevious,
    onLoadMore,
    onLoadPrevious,
  } = useCollections(wData.source, pagination);
  const isLoading = isFetching || isFetchingNextPage || isFetchingPreviousPage;

  const handleInViewChange = useCallback(
    (inView: boolean) => {
      if (inView && hasMore && !isLoading) {
        onLoadMore();
      }
    },
    [hasMore, onLoadMore, isLoading]
  );

  const handlePrevInViewChange = useCallback(
    (inView: boolean) => {
      if (inView && hasPrevious && !isLoading) {
        onLoadPrevious();
      }
    },
    [hasMore, onLoadPrevious, isLoading]
  );

  const [inViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: handleInViewChange,
  });

  const [inPrevViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: handlePrevInViewChange,
  });

  const iCollection = useHaloScript<string>(wData.initialCollection);
  let startIndex = iCollection
    ? collections.findIndex((c) => c.slug === iCollection)
    : 0;
  startIndex = Math.max(0, startIndex);

  const startIndexRef = useRef<number>(startIndex);
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  useEffect(() => {
    const onSelect = () => {
      const snap = carouselRef.current!.emblaApi?.selectedScrollSnap();
      setCurrentIndex(snap ?? 0);
    };

    // ugly hac to ensure that we have embla ref when effect runs.
    setTimeout(() => {
      carouselRef.current?.emblaApi?.on('select', onSelect);
    }, 1000);

    return () => {
      carouselRef.current?.emblaApi?.off('select', onSelect);
    };
  }, [carouselRef, setCurrentIndex]);

  useEffect(() => {
    setCurrentIndex(startIndex);
  }, [startIndex, setCurrentIndex]);

  if (wData?.carouselFragment) {
    return (
      <ElemasonFragmentProvider value={carouselFragmentValue({ collections })}>
        {carouselFragment?.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    );
  }

  return (
    <UiCarousel
      ref={carouselRef}
      style={cStyle}
      inViewThreshold={1}
      axis={wConfig?.carousel?.axis}
      loop={wConfig?.carousel?.loop}
      startIndex={startIndexRef.current}
      className={clsx('relative h-full w-full', cClassName)}
      autoplay={{
        enable: wConfig?.carousel?.autoPlay,
        delay: wConfig?.carousel?.autoPlayInterval,
      }}
    >
      <UiCarousel.Slides>
        <>
          {hasPrevious && !isLoading && (
            <div ref={inPrevViewRef} className="flex" />
          )}
          {(collections ?? [])?.map((collection, index) => (
            <CarouselSlide
              style={cStyle}
              key={collection.id}
              config={wConfig?.carousel?.slide}
              className={clsx('relative h-fit w-full', cClassName)}
            >
              <ElemasonFragmentProvider
                value={fragmentValue({ collection, index, currentIndex })}
              >
                {fragment?.cells.map((cell) => (
                  <Cell key={cell.id} cell={cell} />
                ))}
              </ElemasonFragmentProvider>
            </CarouselSlide>
          ))}
          {hasMore && isLoading && (
            <>
              {take(collections, 1).map((collection, index) => (
                <CarouselSlide
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${collection.id}_${index}`}
                  config={wConfig?.carousel?.slide}
                  className={clsx('relative h-fit w-full', cClassName)}
                >
                  <ContentShimmer>
                    <ElemasonFragmentProvider
                      value={fragmentValue({ collection, index, currentIndex })}
                    >
                      {fragment?.cells.map((cell) => (
                        <Cell key={cell.id} cell={cell} />
                      ))}
                    </ElemasonFragmentProvider>
                  </ContentShimmer>
                </CarouselSlide>
              ))}
            </>
          )}
          {hasMore && !isLoading && <div ref={inViewRef} className="flex" />}
        </>
      </UiCarousel.Slides>
      <UiCarousel.Dots
        style={paginationStyle}
        className={clsx(
          'flex',
          paginationClassName,
          wConfig?.carousel?.pagination?.overlay ? 'absolute' : ''
        )}
      >
        <UiCarousel.Dot
          className="transition-all"
          // @ts-expect-error TODO: fix types
          getStyles={({ isActive }: { isActive: boolean }) =>
            isActive
              ? wConfig?.carousel?.pagination?.activeDot
              : wConfig?.carousel?.pagination?.inactiveDot
          }
        />
      </UiCarousel.Dots>
    </UiCarousel>
  );
};

CollectionBookWidget.displayName = 'CollectionBookWidget';

export { CollectionBookWidget };
