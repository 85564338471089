import { useAnalyticsStore, type ElemasonAnalyticsContextType } from '@tectonic/analytics';
import { useEffect, useRef } from 'react';
import { ImpressionLedger } from './ImpressionLedger';

const useImpressionLedger = (
  eventName: string,
  source: ElemasonAnalyticsContextType
) => {

  const pageInfo = useAnalyticsStore((state) => ({
    previousPage: state.previousPage,
    currentPage: state.currentPage,
  }));

  const ledgerRef = useRef<ImpressionLedger>(
    new ImpressionLedger(eventName, source, pageInfo)
  );

  // mount ref to avoid re-initialization of ledger on first render.
  const isMountRef = useRef(true);

  useEffect(() => {
    const prevLedger = ledgerRef.current;
    if (!isMountRef.current) {
      ledgerRef.current = new ImpressionLedger(eventName, source, pageInfo);
    }
    isMountRef.current = false;

    return () => {
      // clearInterval(id);
      prevLedger?.flush();
    };
  }, [eventName, source, ledgerRef, isMountRef, pageInfo]);

  return ledgerRef.current;
};

export { useImpressionLedger };
