import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { SelectGroup, SelectItem, SelectItemText } from '@tectonic/uikit';
import clsx from 'clsx';

import type { FC } from 'react';
import type { SelectMenuOptionsProps } from './SelectMenu.types';

const SelectMenuOptions: FC<SelectMenuOptionsProps> = ({
  options,
  className,
  config,
  value,
}) => {
  const containerStyleConfig = useStyleConfig(config?.options?.container ?? {});

  const optionContainerStyleConfig = useStyleConfig(
    config?.option?.container ?? {}
  );

  return (
    <SelectGroup asChild>
      <ul
        style={containerStyleConfig.style}
        className={clsx(containerStyleConfig.className, className)}
      >
        {options.map((option) => (
          <SelectItem key={option.name} value={option.name} asChild>
            <li
              style={optionContainerStyleConfig.style}
              className={clsx(
                optionContainerStyleConfig.className,
                'radix-disabled:opacity-40'
              )}
            >
              <SelectItemText>
                <Text
                  className={clsx({
                    'font-semibold': value?.name === option.name,
                  })}
                  config={config?.option?.text}
                  data={option.displayName}
                />
              </SelectItemText>
            </li>
          </SelectItem>
        ))}
      </ul>
    </SelectGroup>
  );
};

SelectMenuOptions.displayName = 'SelectMenuOptions';

export { SelectMenuOptions };
