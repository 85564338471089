import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Shimmer } from '../../../Shimmer';

import type { ProductListSkeltonCardConfig } from '@tectonic/types';
import type { FC } from 'react';
import { useFragmentValue, usePageFragment } from '../../../../hooks';
import { ElemasonFragmentProvider } from '../../../../contexts';
import { Cell } from '../../../../core';

interface ProductListCardSkeletonProps {
  fragment?: string;
  config?: ProductListSkeltonCardConfig;
}

const ProductListCardSkeleton: FC<ProductListCardSkeletonProps> = ({
  config,
  fragment: fragmentSlug,
}) => {
  const fragment = usePageFragment(fragmentSlug);
  const fragmentValue = useFragmentValue(fragment);
  const [style, className] = useStyleConfigV2(config?.size);

  if (fragment) {
    return (
      <ElemasonFragmentProvider value={fragmentValue({})}>
        {fragment?.cells.map((cell, idx) => (
          <Cell key={cell.id ?? idx} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    );
  }

  return (
    <Shimmer className={clsx('border-1 shrink-0', className)} style={style} />
  );
};

export { ProductListCardSkeleton };
