import {
  useFragmentValue,
  useHaloScript,
  usePageFragment,
  useSharedLocalState,
} from '../../hooks';

import type { ElemasonOtpInputWidget } from '@tectonic/types';
import type { SlotProps } from 'input-otp';

const useOtpInput = (widget: ElemasonOtpInputWidget) => {
  const wData = widget.data!;
  const stateKey = useHaloScript<string>(wData.stateKey)!;

  const maxLength = useHaloScript<number>(wData.length)!;

  const { setSharedState } = useSharedLocalState(stateKey);

  const onChange = (value: string) => {
    setSharedState(value);
  };

  return { onChange, maxLength };
};

const useOtpInputSlot = ({
  widget,
  index,
  slot,
}: {
  widget: ElemasonOtpInputWidget;
  index: number;
  slot: SlotProps;
}) => {
  const wData = widget.data!;

  const fragment = usePageFragment(wData.slot);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    slotIndex: index,
    slotChar: slot.char,
    slotIsActive: slot.isActive,
    slotHasCaret: slot.hasFakeCaret,
  });

  return { fragment, fragmentData };
};

export { useOtpInput, useOtpInputSlot };
