import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { useFragmentValue } from '../../../hooks';
import { useAuth } from './hooks';

import type { FC } from 'react';
import type { AuthWidgetProps } from './Auth.types';

const AuthWidget: FC<AuthWidgetProps> = ({ widget }) => {
  const { fragment } = useAuth(widget);
  const fragmentValue = useFragmentValue(fragment);

  return fragment ? (
    <div className="flex flex-col">
      <ElemasonFragmentProvider value={fragmentValue()}>
        {fragment?.cells.map((cell, index) => (
          <Cell key={cell.id ?? index} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </div>
  ) : null;
};

AuthWidget.displayName = 'AuthWidget';

export { AuthWidget };
