import { useStyleConfig } from '@tectonic/elemason-components';
import { SearchQuery } from './SearchQuery';

import type { FC } from 'react';
import type { SearchQueriesProps } from './SearchTrends.types';

const SearchQueries: FC<SearchQueriesProps> = ({
  config,
  data,
  onQueryClick,
  queries,
}) => {
  const { queryList } = config ?? {};
  const containerStyleConfig = useStyleConfig(queryList?.container ?? {});
  return (
    <div {...containerStyleConfig}>
      {queries.map((query) => (
        <SearchQuery
          key={query.name}
          data={data}
          onQueryClick={onQueryClick}
          query={query}
          config={config?.query}
        />
      ))}
    </div>
  );
};

SearchQueries.displayName = 'SearchQueries';

export { SearchQueries };
