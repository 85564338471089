import axios from 'axios';

import type {
  ProductsSearchApiRouteActionResponse,
  SearchParamsWithExpressions,
} from '@tectonic/types';

const searchCollectionProducts = async (
  slug: string,
  payload: Partial<SearchParamsWithExpressions>
  // TODO: Use specific interface
): Promise<ProductsSearchApiRouteActionResponse> => {
  const response = await axios.post(
    `/api/collections/${slug}/product/search`,
    payload
  );
  return response.data;
};

export { searchCollectionProducts };
