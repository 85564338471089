import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { useMemo, type FC } from 'react';
import { ProductPromotionCard } from './ProductPromotionCard';
import { ProductPromotionSkeletonCard } from './ProductPromotionSkeletonCard';

import type {
  ElemasonProductPromotionListWidget,
  Maybe,
  Promotion,
} from '@tectonic/types';

type PromotionListItemType = 'LOADING' | 'ERROR' | 'DATA';

interface PromotionListItemInfo {
  data: Maybe<Promotion>;
  type: PromotionListItemType;
}

type ProductPromotionListProps = {
  isError?: boolean;
  isLoading?: boolean;
  config?: ElemasonProductPromotionListWidget['config'];
  data?: Promotion[];
};

const ProductPromotionList: FC<ProductPromotionListProps> = ({
  data,
  config,
  isError,
  isLoading,
}) => {
  const { style, className } = useStyleConfig(config?.list ?? {});

  const promotionItems: PromotionListItemInfo[] = useMemo(() => {
    const items: PromotionListItemInfo[] = (data ?? [])?.map((promotion) => ({
      type: 'DATA',
      data: promotion,
    }));

    if (isError) {
      return [
        {
          type: 'ERROR',
          data: null,
        },
      ];
    }

    if (isLoading) {
      const loaders: PromotionListItemInfo[] = Array.from(
        { length: 4 },
        () => ({
          type: 'LOADING',
          data: null,
        })
      );

      items.push(...loaders);
    }

    return items;
  }, [data, isLoading, isError]);

  const finalCardWidth =
    data?.length === 1 ? '100%' : config?.card?.width || '100%';

  const updatedConfig = {
    ...config,
    card: { ...config?.card, width: finalCardWidth },
  };

  if (isEmpty(data) && !isLoading) {
    return null;
  }

  return (
    <div
      style={style}
      className={clsx('flex w-full flex-row overflow-auto', className)}
    >
      {promotionItems.map((item, idx) => {
        if (item.type === 'LOADING') {
          // eslint-disable-next-line react/no-array-index-key
          return (
            <ProductPromotionSkeletonCard
              key={`pdp-promo-skel-${idx}`}
              config={config}
            />
          );
        }
        if (item.type === 'DATA') {
          return (
            <ProductPromotionCard
              key={`pdp-promo-${item.data?.id}`}
              config={updatedConfig}
              promotion={item.data!}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export { ProductPromotionList };
