import { Logger } from '@tectonic/logger';
import { trackEvent } from './event';

import type { ImpressionEntity } from '@tectonic/types';
import type { AnalyticsEventPayload } from '../types';

// Generic wrapper in future we could add transformations here based on the entity type
const trackImpressionEvent = (
  event: string,
  payload: AnalyticsEventPayload,
  _entity?: ImpressionEntity
) => {
  try {
    trackEvent(event, payload);
  } catch (error) {
    Logger.error(
      '[trackImpressionEvent]: Unable to track impression event',
      error
    );
  }
};

export { trackImpressionEvent };
