import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { memo, type FC } from 'react';
import { Shimmer } from '../../components';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';

import type { ShimmerWidgetProps } from './Shimmer.types';

const ShimmerWidget: FC<ShimmerWidgetProps> = ({ widget }) => {
  const wData = widget.data!;

  const { config } = widget;

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  return fragment ? (
    <Shimmer
      style={containerStyleConfig.style}
      className={clsx('flex', containerStyleConfig.className)}
    >
      <ElemasonFragmentProvider value={fragmentValue()}>
        {fragment?.cells.map((cell, index) => (
          <Cell className="invisible" key={cell.id ?? index} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </Shimmer>
  ) : null;
};

ShimmerWidget.displayName = 'ShimmerWidget';

const MemoizedShimmerWidget = memo(ShimmerWidget);

export { MemoizedShimmerWidget as ShimmerWidget };
