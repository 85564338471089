import clsx from 'clsx';
import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { useColors, useThemeContext } from '../../context';

import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import type { VariantProps } from 'tailwind-variants';
import type { Color } from '../../types';
import type { IconSymbol } from './Icon.types';

const iconTailwind = tv({
  slots: {
    base: '',
  },
  variants: {
    size: {
      '2xl': 'w-12 h-12',
      xl: 'w-10 h-10',
      lg: 'w-8 h-8',
      md: 'w-6 h-6',
      sm: 'w-5 h-5',
      xs: 'w-4 h-4',
      '2xs': 'w-3 h-3',
      '3xs': 'w-2 h-2',
    },
  },
  defaultVariants: { size: 'md' },
});

const useIconHref = (symbol: IconSymbol) => {
  const { icon } = useThemeContext();
  return `${icon.sprite}#${symbol}`;
};

type Props = Omit<ComponentProps<'svg'>, 'children'> &
  VariantProps<typeof iconTailwind> & { symbol: IconSymbol };

const Icon: ForwardRefRenderFunction<SVGSVGElement, Props> = (
  { symbol, size, className, color, style, ...svgProps },
  ref
) => {
  const colors = useColors();
  const href = useIconHref(symbol);
  const { base } = iconTailwind({ size });

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={clsx(base(), className)}
      style={{ color: colors[color as Color] ?? color, ...style }}
      {...svgProps}
    >
      <use xlinkHref={href} />
    </svg>
  );
};

Icon.displayName = 'Icon';

const ExoticIcon = forwardRef(Icon);

export { ExoticIcon as Icon };

export type { Props as IconProps };
