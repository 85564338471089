import type { ComponentProps, FC, MouseEvent, ReactNode } from 'react';

interface LinkProps extends ComponentProps<'a'> {
  href: string;
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const isModifiedEvent = (event: MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const shouldProcessLinkClick = (event: MouseEvent, target?: string) =>
  event.button === 0 && // Ignore everything but left clicks
  !isModifiedEvent(event) && // Ignore clicks with modifier keys
  (!target || target === '_self'); // Let browser handle "target=_blank" etc.

const Link: FC<LinkProps> = ({ href, children, target, onClick, ...props }) => {
  if (!href) return null;

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (shouldProcessLinkClick(event, target)) {
      event.preventDefault();
      onClick?.(event);
    }
  };

  return (
    <a href={href} onClick={handleClick} target={target} {...props}>
      {children}
    </a>
  );
};

export { Link };
