import { LocalStorage } from '@tectonic/storage';

enum PinterestIds {
  PTC = '__tt_ptclid',
  PTCLID = 'epik',
}

const setPTC = (pclid: string) => {
  // TODO not clear if this needs to be session or local storage
  LocalStorage.setItem(PinterestIds.PTC, pclid);
  return pclid;
};

const getPTC = () => LocalStorage.getItem(PinterestIds.PTC);

const initializePinterest = () => {
  try {
    const searchParams = new URLSearchParams(globalThis.location.search);
    const pclid = searchParams.get(PinterestIds.PTCLID);
    let ptc = getPTC();

    if (pclid) {
      // Looks like we have got a new epik, set ptc.
      ptc = setPTC(pclid);
    }

    return { ptc };
  } catch (error) {
    //
  }
  return null;
};

const resetPinterest = () => {
  LocalStorage.removeItem(PinterestIds.PTC);
};

export { getPTC, initializePinterest, resetPinterest, setPTC };
