import { Logger } from '@tectonic/logger';
import { create } from 'zustand';

import type { NonFunctionProperties } from '@tectonic/types';
import type { AnalyticsStore, AnalyticsStorePage } from '../types';

const debug = false

// For analytics, we need a few data with every event like screenName, pageId,
// etc. These params are typically available on page level. To avail this data
// to event trackers, we use a specific analytics store. This store will have
// all the critical data and keep them in sync with current ui.
const useAnalyticsStore = create<AnalyticsStore>((setState) => ({
  drawers: [],
  previousUrl: null,
  currentPage: null,
  previousPage: null,

  pushPage: (page: AnalyticsStorePage) => {
    setState((state) => {
      if (debug) {
        Logger.log('pushPage', {
          page,
          drawers: [],
          currentPage: page,
          previousPage: state.currentPage,
        })
      }

      return ({
        page,
        drawers: [],
        currentPage: page,
        previousPage: state.currentPage,
      })
    })
  },


  pushDrawer: (drawer: AnalyticsStorePage) => {
    setState((state) => {
      if (debug) {
        Logger.log('pushDrawer', {
          page: state.page,
          currentPage: drawer,
          previousPage: state.currentPage,
          drawers: [...state.drawers, drawer],
        })
      }

      return ({
        currentPage: drawer,
        previousPage: state.currentPage,
        drawers: [...state.drawers, drawer],
      })
    })
  },

  popDrawer: () => {
    setState((state) => {
      if (debug) {
        Logger.log('popDrawer', {
          page: state.page,
          previousPage: state.currentPage,
          drawers: state.drawers.slice(0, -1),
          currentPage: state.drawers[state.drawers.length - 2] ?? state.page,
        })
      }

      return ({
        previousPage: state.currentPage,
        drawers: state.drawers.slice(0, -1),
        currentPage: state.drawers[state.drawers.length - 2] ?? state.page,
      })
    })
  },

  setParams: (params: Partial<NonFunctionProperties<AnalyticsStore>>) => {
    if (debug) {
      Logger.log('params', params)
    }

    setState({ ...params })
  }
}));

export { useAnalyticsStore };
