import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';
import { useCarouselContext } from './CarouselContext';

import type { ForwardRefRenderFunction } from 'react';
import type { CarouselDotProps } from './Carousel.types';

const Dot: ForwardRefRenderFunction<HTMLButtonElement, CarouselDotProps> = (
  { snap, asChild, style, getStyles, ...restProps },
  ref
) => {
  const { scrollTo, activeSnap } = useCarouselContext();
  const onClick = () => scrollTo(snap!);

  const Comp = asChild ? Slot : 'button';
  return (
    <Comp
      ref={ref}
      type="button"
      onClick={onClick}
      title={`carousel-dot-${snap}`}
      data-carousel-slide-active={activeSnap === snap}
      style={{ ...style, ...getStyles?.({ isActive: activeSnap === snap }) }}
      {...restProps}
    />
  );
};

Dot.displayName = 'CarouselDot';

export default forwardRef(Dot);
