import { useStyleConfig } from '@tectonic/elemason-components';
import { isVisible } from '../../../utils';
import { SearchHistoryHeader } from './SearchHistoryHeader';
import { SearchHistorySkeleton } from './SearchHistorySkeleton';
import { SearchQueries } from './SearchQueries';

import type { FC } from 'react';
import type { SearchHistoryProps } from './SearchHistory.types';

const SearchHistory: FC<SearchHistoryProps> = ({
  data,
  isLoading,
  onClear,
  onQueryClick,
  queries,
  config,
}) => {
  const { header, container } = config ?? {};

  const isHeaderVisible = isVisible(header);

  const containerStyleConfig = useStyleConfig(container ?? {});

  if (isLoading) {
    return <SearchHistorySkeleton config={config} />;
  }

  return (
    <div {...containerStyleConfig}>
      {isHeaderVisible ? (
        <SearchHistoryHeader
          config={header}
          data={data.header!}
          onClear={onClear}
        />
      ) : null}
      <SearchQueries
        config={config}
        queries={queries}
        data={data.query}
        onClear={onClear}
        onQueryClick={onQueryClick}
      />
    </div>
  );
};

SearchHistory.displayName = 'SearchHistory';

export { SearchHistory };
