import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { ConfirmationDialogContent } from './ConfirmationDialogContent';
import { ConfirmationDialogFooter } from './ConfirmationDialogFooter';
import { ConfirmationDialogHeader } from './ConfirmationDialogHeader';

import { useStyleConfig } from '@tectonic/elemason-components';
import type { FC } from 'react';
import type { ConfirmationDialogProps } from './ConfirmationDialog.types';

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  data,
  isOpen,
  onClose,
  onConfirm,
  config,
  isSubmitting,
}) => {
  const alertDialogContentStyleConfig = useStyleConfig(
    config?.content?.dialogContainer ?? {}
  );
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogPortal>
        <AlertDialogOverlay
          className={clsx('fixed inset-0 z-20', 'bg-black/70')}
        />
        <AlertDialogContent
          forceMount
          style={alertDialogContentStyleConfig?.style}
          className={clsx(
            'fixed z-50',
            'bg-white',
            'w-11/12',
            'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
          )}
        >
          <ConfirmationDialogHeader
            data={data.header}
            config={config?.header}
          />
          <ConfirmationDialogContent
            data={data.content}
            config={config?.content}
            onClose={onClose}
          />
          <ConfirmationDialogFooter
            isSubmitting={isSubmitting}
            data={data.footer}
            config={config?.footer}
            onConfirm={onConfirm}
            onClose={onClose}
          />
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

ConfirmationDialog.displayName = 'ConfirmationDialog';

export { ConfirmationDialog };
