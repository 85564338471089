import { useStyleConfig } from '@tectonic/elemason-components';
import { ContentShimmer } from '../../Shimmer';

import type { FC } from 'react';
import type { SearchSortFacetSkeletonProps } from './SearchSortFacet.types';

const SearchSortFacetSkeleton: FC<SearchSortFacetSkeletonProps> = ({
  config,
}) => {
  const { facet, facetList } = config ?? {};

  const listContainerStyleConfig = useStyleConfig(facetList?.container ?? {});
  const facetContainerProps = useStyleConfig(facet?.default?.container ?? {});

  return (
    <div {...listContainerStyleConfig}>
      {Array.from({ length: 4 }, (_, index) => (
        <div key={index} {...facetContainerProps}>
          <ContentShimmer className="w-1/2">Loading...</ContentShimmer>
        </div>
      ))}
    </div>
  );
};

SearchSortFacetSkeleton.displayName = 'SearchSortFacetSkeleton';

export { SearchSortFacetSkeleton };
