import { AnalyticsButtonEventNames } from '@tectonic/analytics';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { useColors } from '@tectonic/uikit';
import { isHaloScript } from '@tectonic/utils';
import { memo, type FC, type MouseEvent } from 'react';
import { Button } from '../components';
import { useActionDispatch } from '../hooks';
import { useHaloScript } from '../hooks/useHaloScript';

import type { Color, ElemasonButtonWidget, HaloScript } from '@tectonic/types';

interface ElemasonButtonWidgetProps {
  widget: ElemasonButtonWidget;
}

const ButtonWidget: FC<ElemasonButtonWidgetProps> = ({ widget }) => {
  const { data, config, actions } = widget;
  const colors = useColors();
  const dispatch = useActionDispatch();
  const placeholders = useHaloScript(data?.placeholders);
  const isScript = isHaloScript(data?.isLoading);
  const lResult = useHaloScript(
    isScript ? (data?.isLoading as HaloScript<boolean>) : undefined
  );
  const isLoading = isScript ? lResult : (data?.isLoading as boolean);

  const onClick = (e: MouseEvent) => {
    if ((actions?.onClick.length ?? 0) > 0) {
      e.stopPropagation();
    }

    dispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsButtonEventNames.BUTTON_CLICK,
        data: {
          text: data?.text,
          startIcon: data?.startIcon,
          endIcon: data?.endIcon,
        },
      },
    });

    actions?.onClick.forEach((action) => dispatch(action));
  };

  // Sending config to pass border radius
  return (
    <Button
      config={config?.button}
      data={data}
      onClick={onClick}
      // @ts-ignore
      isLoading={!!isLoading}
      placeholders={placeholders}
      size={config?.button?.size}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      style={{
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
        textWrap: config?.button?.text?.textWrap,
        color:
          colors[config?.button?.text?.color as Color] ??
          config?.button?.text?.color,
      }}
    />
  );
};

ButtonWidget.displayName = 'ElemasonButtonWidget';

const MemoizedButtonWidget = memo(ButtonWidget);

export { MemoizedButtonWidget as ButtonWidget };
