import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { toProductSearchRequestPayload } from '@tectonic/utils';
import { queryKeys } from '../../queryKeys';

import type { SearchQuerySource } from '@tectonic/types';

const useCollectionProductSearchV2 = (
  widgetId: number | string,
  {
    slug,
    perPage,
    source,
    enabled = true,
  }: {
    slug?: string;
    perPage?: number;
    source?: SearchQuerySource;
    enabled?: boolean;
  }
) => {
  if (!slug) {
    throw new Error('useCollectionProductSearchV2: collection is required');
  }

  const pp = perPage ?? 10;

  const query = { perPage: pp };

  const {
    data,
    isLoading: isProductsLoading,
    ...rest
  } = useQuery({
    enabled,
    queryKey: queryKeys.collectionSearch(slug, widgetId),
    queryFn: async () =>
      remixApi.searchCollectionProducts(
        toProductSearchRequestPayload({
          ...(source?.query ?? {}),
          ...query,
        }),
        slug
      ),
  });

  return {
    perPage: pp,
    ...data,
    ...rest,
    isLoading: isProductsLoading || !enabled,
  };
};

export { useCollectionProductSearchV2 };
