import { ElemasonFragmentProvider } from '../../../contexts';
import { Cell } from '../../../core';
import { useCartCoupon } from './hooks';

import type { FC } from 'react';
import type { CartPromotionWidgetProps } from './CartPromotion.types';

const CartPromotionWidget: FC<CartPromotionWidgetProps> = ({ widget }) => {
  const { fragment, fragmentData } = useCartCoupon(widget);

  return fragment ? (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment.cells.map((cell) => (
        <Cell key={cell.id} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  ) : null;
};

CartPromotionWidget.displayName = 'CartPromotionWidget';

export { CartPromotionWidget };
