import { noop } from 'lodash-es';
import { useEffect, useRef } from 'react';

const useTimeout = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef(callback);

  if (savedCallback.current !== callback) {
    savedCallback.current = callback;
  }

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return noop;
    }

    const id = setTimeout(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearTimeout(id);
    };
  }, [delay]);
};

export { useTimeout };
