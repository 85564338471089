import { isNil } from 'lodash-es';

import type { VisibilityConfig } from '@tectonic/types';

// In a widget config `visible` flag tell whether something to be shown or not.
// We consider things to be visible by default unless explicitly stated.
// `isVisible` is utility to helps us determine this value without worrying
// much about default behavior. eg. in case of product card widget the cart cta
// is visible by default. Therefore, we can use it as:
// const showCart = isVisible(config?.cartCta?.visible)
// The wishlist cta for cart is hidden by default. We can use this utility as
// const showCart = isVisible(config?.cartCta?.visible, false)
const isVisible = ({ visible }: VisibilityConfig = {}, defaultValue = true) =>
  isNil(visible) ? defaultValue : visible;

export { isVisible };
