// @ts-nocheck
import { ElemasonWidgetType, type ElemasonWidget } from '@tectonic/types';
import { useMemo } from 'react';
import { useElemasonContext } from '../../../contexts';
import {
  AccordionWidget,
  AddToCartWidget,
  AddressFormWidget,
  AddressListWidget,
  AttributeSelectorWidget,
  AuthLogoutWidget,
  AuthOtpConfirmationFormWidget,
  AuthOtpConfirmationWidget,
  AuthPhoneNoOtpSignInFormWidget,
  AuthWidget,
  ButtonWidget,
  CarouselBannerWidget,
  CartButtonWidget,
  CartCouponsWidget,
  CartLinesWidget,
  CartPromotionWidget,
  CartWidget,
  CheckoutWidget,
  CollectionBookWidget,
  CollectionListWidget,
  ColorWidget,
  CountdownTextWidget,
  CountdownWidget,
  DataSwitchWidget,
  DrawerCloseWidget,
  EmailSubscriptionWidget,
  ExpressCheckoutWidget,
  FilePreviewWidget,
  FileUploadWidget,
  FragmentWidget,
  GenericCarouselWidget,
  HeadlessCarousel,
  IconWidget,
  ImageBannerWidget,
  InputWidget,
  InputWidget2,
  LookBookWidget,
  LooksWidget,
  MenuWidget,
  ModifyFilterValueWidget,
  MoneyWidget,
  OrderDetailsWidget,
  OrderListWidget,
  OrdersWidget,
  OtpInputWidget,
  PressableWidget,
  ProductAddReviewWidget,
  ProductBadgesWidget,
  ProductCarouselWidget,
  ProductFBTWidget,
  ProductGalleryCarouselWidget,
  ProductGridWidget,
  ProductListWidget,
  ProductPricingWidget,
  ProductPromotionListWidget,
  ProductRatingsWidget,
  ProductRecommendationsWidget,
  ProductShareWidget,
  ProductVariantSelectorWidget,
  ProductVariantsWidget,
  ProductViewWidget,
  ProductWidget,
  ProductWishlistWidget,
  RecentlyViewedProductsWidget,
  RegisterInterestWidget,
  RepeaterWidget,
  ResourceMutationWidget,
  ResourceWidget,
  RichTextWidget,
  SearchAutoCompleteWidget,
  SearchAutoSuggestContentWidget,
  SearchAutoSuggestInputWidget,
  SearchFilterWidget,
  SearchHistoryWidget,
  SearchProductSuggestionWidget,
  SearchSortControlWidget,
  SearchSortFacetWidget,
  SearchTopLineFilterWidget,
  SearchTrendsWidget,
  ServiceabilityV2Widget,
  ShimmerWidget,
  SwitchWidget,
  TemplatedProductListWidget,
  TextAreaWidget,
  TextWidget,
  TickerWidget,
  TimeoutWidget,
  UserProfileCtaWidget,
  VariantSelectWidget,
  VariantSelectorWidget,
  VideoBannerWidget,
  WishlistItemsWidget,
} from '../../../widgets';
import { CartLineProductWidget } from '../../../widgets/Cart/CartLineProduct';
import { CartLineVariantSelectorWidget } from '../../../widgets/Cart/CartLineVariantSelector';
import { DecrementCartLineQuantityWidget } from '../../../widgets/Cart/DecrementCartLineQuantity';
import { IncrementCartLineQuantityWidget } from '../../../widgets/Cart/IncrementCartLineQuantity';
import { RemoveFromCartWidget } from '../../../widgets/Cart/RemoveFromCart';
import { SwapFromCartWidget } from '../../../widgets/Cart/SwapFromCart';
import { CollectionProductListWidget } from '../../../widgets/CollectionProductList';
import { CollectionProductListV2Widget } from '../../../widgets/CollectionProductListV2';
import { DateWidget } from '../../../widgets/Date';
import { LocateMeWidget } from '../../../widgets/LocateMe';
import { PincodeInputWidget } from '../../../widgets/PincodeInput';
import { ReviewsWidget } from '../../../widgets/Reviews';
import { SetPreferredPincodeWidget } from '../../../widgets/SetPreferredPincode';
import { ShareWidget } from '../../../widgets/Share';

import type { ComponentType } from 'react';

const inBuiltWidgets: Record<
  ElemasonWidgetType | string,
  ComponentType<{ widget: ElemasonWidget<unknown, unknown, unknown> }>
> = {
  [ElemasonWidgetType.Auth]: AuthWidget,
  [ElemasonWidgetType.AuthLogout]: AuthLogoutWidget,
  [ElemasonWidgetType.CountdownText]: CountdownTextWidget,
  [ElemasonWidgetType.CollectionList]: CollectionListWidget,
  [ElemasonWidgetType.Date]: DateWidget,
  [ElemasonWidgetType.CollectionBook]: CollectionBookWidget,
  [ElemasonWidgetType.Product]: ProductWidget,
  [ElemasonWidgetType.ProductVariants]: ProductVariantsWidget,
  [ElemasonWidgetType.AuthOtpConfirmation]: AuthOtpConfirmationWidget,
  [ElemasonWidgetType.AuthOtpConfirmationForm]: AuthOtpConfirmationFormWidget,
  [ElemasonWidgetType.AuthPhoneNoOtpSignInForm]: AuthPhoneNoOtpSignInFormWidget,
  [ElemasonWidgetType.Icon]: IconWidget,
  [ElemasonWidgetType.Text]: TextWidget,
  [ElemasonWidgetType.SetPreferredPincode]: SetPreferredPincodeWidget,
  [ElemasonWidgetType.LocateMe]: LocateMeWidget,
  [ElemasonWidgetType.PincodeInput]: PincodeInputWidget,
  [ElemasonWidgetType.Countdown]: CountdownWidget,
  [ElemasonWidgetType.Pressable]: PressableWidget,
  [ElemasonWidgetType.SwapFromCart]: SwapFromCartWidget,
  [ElemasonWidgetType.Money]: MoneyWidget,
  [ElemasonWidgetType.Menu]: MenuWidget,
  [ElemasonWidgetType.Looks]: LooksWidget,
  [ElemasonWidgetType.Button]: ButtonWidget,
  [ElemasonWidgetType.Ticker]: TickerWidget,
  [ElemasonWidgetType.Cart]: CartWidget,
  [ElemasonWidgetType.Share]: ShareWidget,
  [ElemasonWidgetType.Checkout]: CheckoutWidget,
  [ElemasonWidgetType.CartLines]: CartLinesWidget,
  [ElemasonWidgetType.AddToCart]: AddToCartWidget,
  [ElemasonWidgetType.CartButton]: CartButtonWidget,
  [ElemasonWidgetType.ProductShare]: ProductShareWidget,
  [ElemasonWidgetType.Accordion]: AccordionWidget,
  [ElemasonWidgetType.CartPromotion]: CartPromotionWidget,
  [ElemasonWidgetType.CartCoupons]: CartCouponsWidget,
  [ElemasonWidgetType.DrawerClose]: DrawerCloseWidget,
  [ElemasonWidgetType.ProductList]: ProductListWidget,
  [ElemasonWidgetType.ImageBanner]: ImageBannerWidget,
  [ElemasonWidgetType.ProductBadges]: ProductBadgesWidget,
  [ElemasonWidgetType.CarouselBanner]: CarouselBannerWidget,
  [ElemasonWidgetType.ProductPricing]: ProductPricingWidget,
  [ElemasonWidgetType.ProductGalleryCarousel]: ProductGalleryCarouselWidget,
  [ElemasonWidgetType.ProductCarousel]: ProductCarouselWidget,
  [ElemasonWidgetType.ExpressCheckout]: ExpressCheckoutWidget,
  [ElemasonWidgetType.GenericCarousel]: GenericCarouselWidget,
  [ElemasonWidgetType.HeadlessCarousel]: HeadlessCarousel,

  [ElemasonWidgetType.RemoveFromCart]: RemoveFromCartWidget,
  [ElemasonWidgetType.CartLineProduct]: CartLineProductWidget,
  [ElemasonWidgetType.IncrementCartLineQuantity]:
    IncrementCartLineQuantityWidget,
  [ElemasonWidgetType.DecrementCartLineQuantity]:
    DecrementCartLineQuantityWidget,
  [ElemasonWidgetType.Switch]: SwitchWidget,
  [ElemasonWidgetType.Reviews]: ReviewsWidget,
  [ElemasonWidgetType.ModifyFilterValue]: ModifyFilterValueWidget,
  [ElemasonWidgetType.CartLineVariantSelector]: CartLineVariantSelectorWidget,
  [ElemasonWidgetType.SearchAutoComplete]: SearchAutoCompleteWidget,
  [ElemasonWidgetType.ProductPromotionList]: ProductPromotionListWidget,
  [ElemasonWidgetType.ProductRecommendations]: ProductRecommendationsWidget,
  [ElemasonWidgetType.ProductVariantSelector]: ProductVariantSelectorWidget,
  [ElemasonWidgetType.SearchAutoSuggestInput]: SearchAutoSuggestInputWidget,
  [ElemasonWidgetType.SearchAutoSuggestContent]: SearchAutoSuggestContentWidget,
  [ElemasonWidgetType.SearchHistory]: SearchHistoryWidget,
  [ElemasonWidgetType.SearchTrends]: SearchTrendsWidget,
  [ElemasonWidgetType.Repeater]: RepeaterWidget,
  [ElemasonWidgetType.DataSwitch]: DataSwitchWidget,
  [ElemasonWidgetType.SearchProductSuggestion]: SearchProductSuggestionWidget,
  [ElemasonWidgetType.RecentlyViewedProductList]: RecentlyViewedProductsWidget,
  [ElemasonWidgetType.TemplatedProductList]: TemplatedProductListWidget,
  [ElemasonWidgetType.ProductRatings]: ProductRatingsWidget,
  [ElemasonWidgetType.SearchSortControl]: SearchSortControlWidget,
  [ElemasonWidgetType.SearchFilter]: SearchFilterWidget,
  [ElemasonWidgetType.ProductGrid]: ProductGridWidget,
  [ElemasonWidgetType.SearchSortFacet]: SearchSortFacetWidget,
  [ElemasonWidgetType.BoughtTogetherProducts]: ProductFBTWidget,
  [ElemasonWidgetType.OrdersList]: OrderListWidget,
  [ElemasonWidgetType.OrderDetails]: OrderDetailsWidget,
  [ElemasonWidgetType.ProductWishlist]: ProductWishlistWidget,
  [ElemasonWidgetType.Shimmer]: ShimmerWidget,
  [ElemasonWidgetType.AddressForm]: AddressFormWidget,
  [ElemasonWidgetType.AddressList]: AddressListWidget,
  [ElemasonWidgetType.RichText]: RichTextWidget,
  [ElemasonWidgetType.WishlistItems]: WishlistItemsWidget,
  [ElemasonWidgetType.ProductView]: ProductViewWidget,
  [ElemasonWidgetType.VariantSelector]: VariantSelectorWidget,
  [ElemasonWidgetType.AttributeSelector]: AttributeSelectorWidget,
  [ElemasonWidgetType.Fragment]: FragmentWidget,
  [ElemasonWidgetType.CollectionProductList]: CollectionProductListWidget,
  [ElemasonWidgetType.CollectionProductListV2]: CollectionProductListV2Widget,
  [ElemasonWidgetType.VariantSelect]: VariantSelectWidget,
  [ElemasonWidgetType.Color]: ColorWidget,
  [ElemasonWidgetType.LookBook]: LookBookWidget,
  [ElemasonWidgetType.RegisterInterest]: RegisterInterestWidget,
  [ElemasonWidgetType.Input]: InputWidget,
  [ElemasonWidgetType.Input2]: InputWidget2,
  [ElemasonWidgetType.ServiceabilityV2]: ServiceabilityV2Widget,
  [ElemasonWidgetType.EmailSubscription]: EmailSubscriptionWidget,
  [ElemasonWidgetType.VideoBanner]: VideoBannerWidget,
  [ElemasonWidgetType.Timeout]: TimeoutWidget,
  [ElemasonWidgetType.UserProfileCta]: UserProfileCtaWidget,
  [ElemasonWidgetType.OtpInput]: OtpInputWidget,
  [ElemasonWidgetType.ProductAddReview]: ProductAddReviewWidget,
  [ElemasonWidgetType.TextArea]: TextAreaWidget,
  [ElemasonWidgetType.FileUpload]: FileUploadWidget,
  [ElemasonWidgetType.FilePreview]: FilePreviewWidget,
  [ElemasonWidgetType.Orders]: OrdersWidget,
  [ElemasonWidgetType.Resource]: ResourceWidget,
  [ElemasonWidgetType.ResourceMutation]: ResourceMutationWidget,
  [ElemasonWidgetType.SearchTopLineFilter]: SearchTopLineFilterWidget,
};

const useWidgetComponent = (widget: ElemasonWidget<unknown, unknown>) => {
  const { widgets: customWidgets } = useElemasonContext();

  return useMemo(() => {
    const widgets = { ...inBuiltWidgets, ...customWidgets };
    return widgets?.[widget.type];
  }, [widget]);
};
export { useWidgetComponent };
