import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { useHydrated } from '@tectonic/utils';
import clsx from 'clsx';
import { useEffect, type ComponentProps, type FC } from 'react';
import { useCountdown } from '../../hooks/useCountdown';

import type { CountdownConfig, CountdownData } from '@tectonic/types';

interface CountdownProps extends ComponentProps<'div'> {
  // The target date in milliseconds
  target?: CountdownData;
  config?: CountdownConfig;
  onCountdownEnd?: () => void;
}

const Countdown: FC<CountdownProps> = ({
  target,
  config,
  className,
  style,
  onCountdownEnd,
  ...props
}) => {
  const hydrated = useHydrated();
  const { days, hours, minutes, seconds } = useCountdown(target);

  const isSeparatorVisible = config?.separator?.visible ?? true;
  const { style: itemStyle, className: itemClassName } = useStyleConfig(
    config?.item ?? {}
  );
  const { style: containerStyle, className: containerClassName } =
    useStyleConfig(config?.container ?? {});

  const separatorText = isSeparatorVisible
    ? config?.separator?.value ?? ':'
    : '';

  useEffect(() => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      onCountdownEnd?.();
    }
  }, [days, hours, minutes, seconds]);

  // Will cause a hydration mismatch if rendered on the server
  if (!hydrated) return null;

  return (
    <div
      style={{ ...style, ...containerStyle }}
      className={clsx(className, containerClassName, 'flex flex-row')}
      {...props}
    >
      {days > 0 && (
        <div style={itemStyle} className={itemClassName}>
          <Text data={days.toString().padStart(2, '0')} config={config?.text} />
        </div>
      )}
      {days > 0 && isSeparatorVisible && (
        <div style={itemStyle} className={itemClassName}>
          <Text data={separatorText} config={config?.text} />
        </div>
      )}
      <div style={itemStyle} className={itemClassName}>
        <Text data={hours.toString().padStart(2, '0')} config={config?.text} />
      </div>
      {isSeparatorVisible && (
        <div style={itemStyle} className={itemClassName}>
          <Text data={separatorText} config={config?.text} />
        </div>
      )}
      <div style={itemStyle} className={itemClassName}>
        <Text
          data={minutes.toString().padStart(2, '0')}
          config={config?.text}
        />
      </div>
      {isSeparatorVisible && (
        <div style={itemStyle} className={itemClassName}>
          <Text data={separatorText} config={config?.text} />
        </div>
      )}
      <div style={itemStyle} className={itemClassName}>
        <Text
          data={seconds.toString().padStart(2, '0')}
          config={config?.text}
        />
      </div>
    </div>
  );
};

export { Countdown };
