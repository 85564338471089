import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import { deleteAddress, getAddresses } from '@tectonic/remix-client-network';
import { ElemasonWidgetActionType, LocalStateKeys } from '@tectonic/types';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useActionDispatch, useSharedLocalState } from '../../../hooks';
import { queryKeys } from '../../../queryKeys';

import type { Address, ElemasonAddressListWidget, Nil } from '@tectonic/types';

const getAddressesQueryFn = async (): Promise<Address[]> => {
  const response = await getAddresses();
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data.edges.map(({ node }) => node);
};

const deleteAddressMutationFn = async (addressId: string) => {
  const response = await deleteAddress(addressId);
  if (response.error) {
    throw response.error;
  }
};

const useAddressList = (widget: ElemasonAddressListWidget) => {
  const actionDispatch = useActionDispatch();
  const wData = widget.data!;
  const wActions = widget.actions;

  const [selectedAddress, setSelectedAddress] = useState<Nil<Address>>(null);
  const { showToast } = useToast();

  const queryClient = useQueryClient();

  const { setSharedState } = useSharedLocalState<Nil<string>>(
    LocalStateKeys.SELECTED_ADDRESS_ID,
    null
  );

  const { isPending: isDeleting, mutateAsync: handleDelete } = useMutation({
    mutationFn: deleteAddressMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.addresses() });
    },
  });

  const onAddressEdit = (address: Address) => {
    setSharedState(address.id);
    if (wActions?.onEdit) {
      wActions.onEdit.forEach((action) => {
        actionDispatch(action);
      });
      return;
    }

    if (!wData.addressDrawerSlug) {
      return;
    }

    actionDispatch({
      type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
      payload: {
        slug: wData.addressDrawerSlug,
        data: { selectedAddressId: address.id },
        entry: 'bottom',
        exit: 'bottom',
      },
    });
  };

  const onDialogClose = () => {
    setSelectedAddress(null);
  };

  const onAddressDelete = (address: Address) => {
    setSelectedAddress(address);
  };

  const onConfirm = async () => {
    try {
      await handleDelete(selectedAddress!.id);
      setSelectedAddress(null);
      showToast({ title: wData.messages.deleteSuccess });
    } catch (error) {
      Logger.error('useAddressList:onDelete', error);
      showToast({
        title: getErrorMessage(error, wData.errorMessages),
      });
    }
  };

  const {
    isError,
    isFetching: isLoading,
    data: addresses,
  } = useQuery({
    queryKey: queryKeys.addresses(),
    queryFn: getAddressesQueryFn,
    initialData: [],
  });

  return {
    isLoading,
    isError,
    isDeleting,
    onAddressEdit,
    addresses,
    onDialogClose,
    onAddressDelete,
    onConfirm,
    isDialogOpen: !!selectedAddress,
  };
};

export { useAddressList };
