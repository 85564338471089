import { toProductImpressionPayload } from '@tectonic/analytics';
import { useMemo } from 'react';

import type { ImpressionLedgerEntry, Product } from '@tectonic/types';

const useProductMap = (products: Product[], totalFound: number) =>
  useMemo(() => {
    const map = new Map<string, Partial<ImpressionLedgerEntry>>();
    products.forEach((product, index) => {
      const entry = {
        index,
        page: 1,
        payload: toProductImpressionPayload({ product, index, totalFound }),
      };
      map.set(`${product.id}`, entry);
    });
    return map;
  }, [products, totalFound]);

export { useProductMap };
