import pino from 'pino';

const logger = pino({
  level: 'debug',
});

logger.level = 'debug';

// Helper function to generate formatting for pino
const generateMessageFormattersForArgs = (args: unknown[]): string =>
  `[LOGGER] ${args
    .map((arg: any) => {
      if (typeof arg === 'string') {
        return '%s';
      }
      if (typeof arg === 'number') {
        return '%d';
      }
      return '%o';
    })
    .join(' ')}`;

// For now this function does nothing but in future it can parse/reformat tags
// eslint-disable-next-line max-len
const generateLogTags = (tags: unknown): {} | null =>
  !tags || Object.keys(tags).length === 0 ? null : tags;

export const Logger = {
  info: (tags = {}, ...args: unknown[]): void => {
    const logTags = generateLogTags(tags);
    logger.info(logTags, generateMessageFormattersForArgs(args), ...args);
  },
  warn: (tags = {}, ...args: unknown[]): void => {
    const logTags = generateLogTags(tags);
    logger.warn(logTags, generateMessageFormattersForArgs(args), ...args);
  },
  error: (...args: unknown[]): void => {
    // We extract the error object to send it as first arg to pino
    let errArg = null;
    const nonErrArgs: unknown[] = [];
    args.forEach((arg: unknown) => {
      if (arg instanceof Error) {
        errArg = arg;
      } else {
        nonErrArgs.push(arg);
      }
    });

    logger.error(
      errArg,
      generateMessageFormattersForArgs(nonErrArgs),
      ...nonErrArgs
    );
  },
  errorWithTags: (tags = {}, ...args: unknown[]): void => {
    // We extract the error object to send it as first arg to pino
    let errArg = null;
    const nonErrArgs: unknown[] = [];
    args.forEach((arg: unknown) => {
      if (arg instanceof Error) {
        errArg = arg;
      } else {
        nonErrArgs.push(arg);
      }
    });

    const logTags = generateLogTags(tags) || {};
    if (errArg) {
      // @ts-ignore
      logTags.err = errArg;
    }

    logger.error(
      logTags,
      generateMessageFormattersForArgs(nonErrArgs),
      ...nonErrArgs
    );
  },
  debug: (tags = {}, ...args: unknown[]): void => {
    const logTags = generateLogTags(tags);
    logger.debug(logTags, generateMessageFormattersForArgs(args), ...args);
  },
  log: (tags = {}, ...args: unknown[]): void => {
    const logTags = generateLogTags(tags);
    logger.info(logTags, generateMessageFormattersForArgs(args), ...args);
  },
};
