import { tv } from 'tailwind-variants';

const textAreaTv = tv({
  slots: {
    base: '',
    textArea: 'w-full input-field',
  },
  variants: {
    variant: {
      outline: 'border border-neutral-300 focus-within:border-black',
    },

    size: {
      lg: {
        base: 'py-3.5',
        textArea: 'input-field-lg',
      },
      md: {
        base: 'py-3',
        textArea: 'input-field-md',
      },
      sm: {
        base: 'py-2.5',
        textArea: 'input-field-sm',
      },
      xs: { base: 'py-2', textArea: 'input-field-xs' },
    },

    rounded: {
      md: { base: 'rounded' },
      lg: { base: 'rounded-lg' },
      xl: { base: 'rounded-xl' },
      none: { base: 'rounded-none' },
      full: { base: 'rounded-full' },
    },

    disabled: {
      true: {
        base: 'opacity-70 pointer-events-none',
      },
    },
  },
  defaultVariants: {
    variant: 'outline',
    size: 'md',
    rounded: 'md',
  },
});

export { textAreaTv };
