import { throttle } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';

const useOnWindowScroll = (callback: EventListener, debounceMs = 50) => {
  const cbRef = useRef<EventListener>(callback);

  useEffect(() => {
    cbRef.current = callback;
  }, [callback, cbRef]);

  useEffect(() => {
    const onScroll = throttle<EventListener>((...args) => {
      cbRef.current(...args);
    }, debounceMs);

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [debounceMs]);
};

const useWindowScrollY = (debounceMs = 200) => {
  const [scrollY, setScrollY] = useState(0);

  const onScroll: EventListener = useCallback(() => {
    setScrollY(window.scrollY);
  }, [setScrollY]);

  useOnWindowScroll(onScroll, debounceMs);
  return scrollY;
};

export { useOnWindowScroll, useWindowScrollY };
