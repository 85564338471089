import deepmerge from 'deepmerge';
import { useElemasonPageContext } from '../contexts/ElemasonPageContext';

import type {
  ElemasonComponentType,
  ElemasonConfig,
  ElemasonWidgetType,
} from '@tectonic/types';

const useElemasonConfig = (defaultConfig: ElemasonConfig) =>
  deepmerge(useElemasonPageContext().config ?? {}, defaultConfig ?? {});

const useElemasonWidgetConfig = <T>(
  widget: ElemasonWidgetType,
  defaultConfig?: T
): T =>
  deepmerge(
    // @ts-expect-error TODO: no error fix type issue
    useElemasonPageContext().config?.widgets?.[widget] ?? ({} as object),
    defaultConfig ?? {}
  ) as T;

const useElemasonComponentConfig = <T>(
  component: ElemasonComponentType,
  defaultConfig?: T
): T =>
  deepmerge(
    useElemasonPageContext().config?.components?.[component] ?? {},
    defaultConfig ?? {}
  ) as T;

export {
  useElemasonComponentConfig,
  useElemasonConfig,
  useElemasonWidgetConfig,
};
