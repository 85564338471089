import { toProductImpressionPayload } from '@tectonic/analytics';
import { ImpressionEntity } from '@tectonic/types';
import { useMemo } from 'react';

import type { ImpressionLedgerEntry, Product } from '@tectonic/types';

// eslint-disable-next-line max-len
type ProductImpressionPayloadMapType = Map<
  string,
  Partial<ImpressionLedgerEntry>
>;

const useProductImpressionPayloadMap = (
  products: Product[],
  searchParams?: Record<string, string>,
  perPage?: number,
  totalFound?: number
) =>
  useMemo(() => {
    // eslint-disable-next-line max-len
    const map: ProductImpressionPayloadMapType = new Map<
      string,
      Partial<ImpressionLedgerEntry>
    >();

    products.forEach((product, index) => {
      let page = Math.ceil(index / (perPage ?? Infinity));
      page = page !== 0 ? page : 1;
      const payload = toProductImpressionPayload({
        index,
        product,
        searchParams: { page, ...(searchParams || {}) },
        totalFound,
      });
      const entry = { index, page, payload, entity: ImpressionEntity.PRODUCT };
      map.set(`${product.id}`, entry);
    });

    return map;
  }, [products, searchParams, perPage]);

export { useProductImpressionPayloadMap };

export type { ProductImpressionPayloadMapType };
