/* eslint-disable max-len */
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect, useRef, type FC } from 'react';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../contexts';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import {
  ActionDispatchExporter,
  type DispatchExporterHandle,
} from '../../core';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { useHaloScript } from '../../hooks/useHaloScript';

import type {
  ElemasonCartLineProductWidget,
  Product,
  ProductVariant,
} from '@tectonic/types';

interface ElemasonCartLineProductWidgetProps {
  widget: ElemasonCartLineProductWidget;
}

const CartLineProductWidget: FC<ElemasonCartLineProductWidgetProps> = ({
  widget: { data, actions, config },
}) => {
  const id = useHaloScript(data?.id);
  const cartLine = useHaloScript(data?.cartLine);
  const analyticsContext = useElemasonAnalyticsContext();
  const fragment = usePageFragment(data?.productFragment);
  const [style, className] = useStyleConfigV2(config?.container);

  const isTrackedRef = useRef<boolean>(false);
  const actionDispatchRef = useRef<DispatchExporterHandle>(null);

  useEffect(() => {
    const actionDispatch = actionDispatchRef.current?.dispatch;
    if (
      isEmpty(actions?.onMount) ||
      isNil(actionDispatch) ||
      isTrackedRef.current
    ) {
      return;
    }
    actions?.onMount.forEach((action) => {
      isTrackedRef.current = true;
      actionDispatch(action);
    });
  }, [actions, actionDispatchRef.current, isTrackedRef.current]);

  const fragmentValue = useFragmentValue(fragment);

  if (!fragment) return null;

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: {
          ...analyticsContext.entities,
          cartLine,
          product: cartLine!.productVariant.product as Product,
          productVariant: cartLine!.productVariant as unknown as ProductVariant,
        },
      }}
    >
      <ActionDispatchExporter ref={actionDispatchRef} />
      <ElemasonFragmentProvider
        value={fragmentValue({
          product: cartLine?.productVariant.product,
          line: cartLine,
        })}
      >
        <div id={id} style={style} className={clsx('flex flex-row', className)}>
          {fragment?.cells.map((cell, index) => (
            <Cell key={cell.id ?? index} cell={cell} />
          ))}
        </div>
      </ElemasonFragmentProvider>
    </ElemasonAnalyticsProvider>
  );
};

export { CartLineProductWidget };
