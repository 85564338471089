import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { toCurrency } from '@tectonic/utils';
import clsx from 'clsx';
import { Button } from '../../../../components';
import { useActionDispatch } from '../../../../hooks';

import type {
  DiscountMinRequirementConfig,
  ElemasonCartCouponsDialogContentCardApplicabilityReasonConfig,
  ElemasonCartCouponsDialogContentCardApplicabilityReasonData,
  Promotion,
  Reason,
} from '@tectonic/types';
import type { FC } from 'react';

interface ApplicabilityReasonProps {
  reason: Reason;
  discount: Promotion;
  requirement: DiscountMinRequirementConfig;
  data?: ElemasonCartCouponsDialogContentCardApplicabilityReasonData;
  config?: ElemasonCartCouponsDialogContentCardApplicabilityReasonConfig;
}

const ApplicabilityReason: FC<ApplicabilityReasonProps> = ({
  data,
  reason,
  config,
  discount,
  requirement,
}) => {
  const actionDispatch = useActionDispatch();

  const [loginStyle, loginClassName] = useStyleConfigV2(
    config?.loginText ?? {}
  );

  const [buttonStyle, buttonClassName] = useStyleConfigV2(
    config?.loginButton ?? {}
  );

  const [quantityStyle, quantityClassName] = useStyleConfigV2(
    config?.quantityText ?? {}
  );

  const [totalAmountStyle, totalAmountClassName] = useStyleConfigV2(
    config?.totalAmountText ?? {}
  );

  const [applicableProductsStyle, applicableProductsClassName] =
    useStyleConfigV2(config?.applicableProductsText ?? {});

  const onViewApplicableProducts = () => {
    actionDispatch({
      type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
      payload: {
        slug: 'coupon-details',
        data: {
          discount,
        },
        entry: 'bottom',
        exit: 'bottom',
      },
    });
  };

  const onClickLogin = () =>
    actionDispatch({
      type: ElemasonWidgetActionType.LOGIN_DRAWER_OPEN,
      payload: { slug: 'login-drawer' },
    });

  if (reason.code === 'LOGIN_REQUIRED') {
    return (
      <div style={loginStyle} className={clsx('flex', loginClassName)}>
        <Text config={config?.loginText} data={data?.loginText} />
        <Button
          style={buttonStyle}
          onClick={onClickLogin}
          data={data?.loginButton}
          className={buttonClassName}
          config={config?.loginButton}
        />
      </div>
    );
  }

  if (reason.code === 'MIN_REQUIREMENTS_NOT_MET') {
    if (requirement.requirementType === 'QUANTITY') {
      return (
        <div
          style={quantityStyle}
          className={clsx('flex flex-col', quantityClassName)}
        >
          <Text
            data={data?.quantityText}
            config={config?.quantityText}
            placeholders={{
              requiredQuantity: `${requirement.quantity?.required}`,
              remainingQuantity: `${requirement.quantity?.remaining}`,
            }}
          />
          <div
            style={applicableProductsStyle}
            className={applicableProductsClassName}
          >
            <Text
              onClick={onViewApplicableProducts}
              data={data?.applicableProductsText}
              config={config?.applicableProductsText}
            />
          </div>
        </div>
      );
    }

    if (requirement.requirementType === 'PRICE') {
      return (
        <div
          style={totalAmountStyle}
          className={clsx('flex flex-col', totalAmountClassName)}
        >
          <Text
            config={config?.totalAmountText}
            data={data?.totalAmountText}
            placeholders={{
              remainingPrice: `${
                requirement.price
                  ? toCurrency(
                      requirement.price.remaining.amount,
                      requirement.price.remaining.currencyCode
                    )
                  : ''
              }`,
              requiredPrice: `${
                requirement.price
                  ? toCurrency(
                      requirement.price.required.amount,
                      requirement.price.required.currencyCode
                    )
                  : ''
              }`,
            }}
          />
          <div
            style={applicableProductsStyle}
            className={applicableProductsClassName}
          >
            <Text
              onClick={onViewApplicableProducts}
              data={data?.applicableProductsText}
              config={config?.applicableProductsText}
            />
          </div>
        </div>
      );
    }
  }

  return null;
};

export { ApplicabilityReason };
