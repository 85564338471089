import { ProductDiscountFormat, ProductStockStatus } from '@tectonic/types';
import { isNil, sortBy } from 'lodash-es';
import { percentage } from './math';

import type {
  ProductCoins,
  ProductDiscountConfig,
  ProductPriceConfig,
  ProductVariant,
} from '@tectonic/types';

const isOutOfStock = (stockStatus: ProductStockStatus): boolean =>
  stockStatus === ProductStockStatus.OUT_OF_STOCK;

const isProductOOS = (variants: ProductVariant[]): boolean =>
  variants?.every((variant) => isOutOfStock(variant.stockStatus));

const sortItems = <T extends object>(
  items: T[],
  key: keyof T = 'displaySequenceIndex' as any
): T[] => sortBy(items, (item) => item[key]);

// Temp hack to hide variant selection for a category that can have only
// only one variant. Presently, backend doesn't have any flag for it. We'll move
// away from it in future.
const isDefaultVariant = ({
  basisAttr1Name,
}: Pick<ProductVariant, 'basisAttr1Name'>): boolean =>
  basisAttr1Name.toLowerCase() === 'title';

const getDiscount = (
  variant: Pick<ProductVariant, 'mrp' | 'price' | 'discountPercentage'>,
  config?: ProductDiscountConfig,
  quantity = 1
): number => {
  const { mrp, discountPercentage } = variant;
  const isAmount = config?.format === ProductDiscountFormat.AMOUNT;
  const value = isAmount
    ? percentage(mrp.amount, discountPercentage) * quantity
    : discountPercentage;
  return Math.floor(value);
};

const hasSpendableCoins = (coins?: ProductCoins): coins is ProductCoins => {
  if (isNil(coins)) {
    return false;
  }
  const { spendable } = coins;
  return spendable.currentlyApplicable.coins >= 0;
};

const getDefaultPriceConfig = (hasDiscount: boolean): ProductPriceConfig => ({
  displaySequenceIndex: 0,
  text: { color: hasDiscount ? 'warning-700' : 'neutral-950' },
});

const getDefaultMrpConfig = (hasDiscount: boolean): ProductPriceConfig => ({
  displaySequenceIndex: 1,
  text: { textDecoration: hasDiscount ? 'line-through' : undefined },
});

const getDefaultDiscountConfig = (): ProductDiscountConfig => ({
  displaySequenceIndex: 2,
});

const getVariantType = (basisAttrName?: string) =>
  basisAttrName?.toLocaleLowerCase();

const getVariantTypes = (
  variant: ProductVariant
): {
  attr: 'basisAttr1' | 'basisAttr2' | 'basisAttr3';
  value: string | undefined;
}[] => {
  const { basisAttr1Name, basisAttr2Name, basisAttr3Name } = variant;

  return [
    { attr: 'basisAttr1', value: getVariantType(basisAttr1Name) },
    { attr: 'basisAttr2', value: getVariantType(basisAttr2Name) },
    { attr: 'basisAttr3', value: getVariantType(basisAttr3Name) },
  ];
};

export {
  getDefaultDiscountConfig,
  getDefaultMrpConfig,
  getDefaultPriceConfig,
  getDiscount,
  getVariantType,
  getVariantTypes,
  hasSpendableCoins,
  isDefaultVariant,
  isOutOfStock,
  isProductOOS,
  sortItems,
};
