import { Text, useStyleConfig } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  type ElemasonProductPromotionListWidget,
  type Promotion,
} from '@tectonic/types';
import { toCurrency } from '@tectonic/utils';
import clsx from 'clsx';
import { round } from 'lodash-es';
import { useElemasonPageContext } from '../../../contexts';

import type { FC } from 'react';

interface ProductPromotionCardProps {
  promotion: Promotion;
  config?: ElemasonProductPromotionListWidget['config'];
}

const ProductPromotionCard: FC<ProductPromotionCardProps> = ({
  config,
  promotion,
}) => {
  const { dispatch } = useElemasonPageContext();
  const { style, className } = useStyleConfig(config?.card ?? {});

  const { style: discountStyle, className: discountClassName } = useStyleConfig(
    config?.discount?.container ?? {}
  );

  const { style: promotionCodeStyle, className: promotionCodeClassName } =
    useStyleConfig(config?.code?.container ?? {});

  const { style: promotionDescStyle, className: promotionDescClassName } =
    useStyleConfig(config?.description?.container ?? {});

  const { style: targetProductStyle, className: targetProductClassName } =
    useStyleConfig(config?.targetProduct?.container ?? {});

  const onViewApplicableProducts = () => {
    dispatch({
      type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
      payload: {
        slug: 'coupon-details',
        data: {
          discount: promotion,
        },
      },
    });
  };

  // TODO Move static texts to templating, support for input is already there just need to implement the template substitution

  return (
    <div
      className={clsx('relative flex shrink-0 flex-col', className)}
      style={style}
    >
      <Text
        className={clsx('relative flex shrink-0 flex-col', discountClassName)}
        style={discountStyle}
        data={`Offer Price: ${toCurrency(
          round(promotion.bestPrice.postDiscountApplication.amount, 2),
          promotion.bestPrice.postDiscountApplication.currencyCode
        )}`}
        config={config?.discount?.text}
      />
      <Text
        className={promotionCodeClassName}
        style={promotionCodeStyle}
        data={`Use Coupon: ${promotion.codes[0]?.toUpperCase()}`}
        config={config?.code?.text}
      />
      <Text
        className={promotionDescClassName}
        style={promotionDescStyle}
        data={promotion.description}
        config={config?.description?.text}
      />
      {promotion.targetConfig.targetType !== 'ALL_PRODUCTS' && (
        <Text
          data="View Products"
          style={targetProductStyle}
          onClick={onViewApplicableProducts}
          className={targetProductClassName}
          config={config?.targetProduct?.text}
        />
      )}
    </div>
  );
};

ProductPromotionCard.displayName = 'ElemasonProductPromotionCard';

export { ProductPromotionCard };
