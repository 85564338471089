import {
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { memo, type FC } from 'react';
import { ImageAsset } from '../components';
import { Cell } from '../core/Cell';
import { useActionDispatch } from '../hooks';
import { useHaloScript } from '../hooks/useHaloScript';

import type { Asset, ElemasonImageBannerWidget, Nil } from '@tectonic/types';

interface ElemasonImageBannerWidgetProps {
  widget: ElemasonImageBannerWidget;
}

const ImageBannerWidget: FC<ElemasonImageBannerWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const dispatch = useActionDispatch();
  const { style, className } = useStyleConfig(config?.image || {});
  const asset: Nil<Asset> = useHaloScript(data?.asset);

  const [containerStyle, containerClassName] = useStyleConfigV2(
    config?.container
  );

  const onClick = () => {
    actions?.onClick.forEach((action) => dispatch(action, undefined));
  };

  if (!asset) return null;

  if (actions?.onClick) {
    return (
      <Clickable
        style={containerStyle}
        className={clsx('relative cursor-pointer', containerClassName)}
      >
        <ImageAsset
          asset={asset}
          style={style}
          onClick={onClick}
          className={className}
          config={config?.image}
        />
        {data?.overlay && <Cell cell={data.overlay} />}
      </Clickable>
    );
  }
  return (
    <div
      style={containerStyle}
      className={clsx('relative', containerClassName)}
    >
      <ImageAsset
        asset={asset}
        style={style}
        onClick={onClick}
        className={className}
        config={config?.image}
      />
      {data?.overlay && <Cell cell={data.overlay} />}
    </div>
  );
};

const MemoizedImageBannerWidget = memo(ImageBannerWidget);

export { MemoizedImageBannerWidget as ImageBannerWidget };
