import { Text, useStyleConfig } from '@tectonic/elemason-components';
import {
  AssetScale,
  ElemasonWidgetActionType,
  NavigationActionType,
  OrderLineStatus,
  OrderStatus,
} from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import { isVideo } from '@tectonic/utils';
import clsx from 'clsx';
import {
  useElemasonAnalyticsContext,
  useElemasonPageContext,
} from '../../contexts';
import { isDefaultVariant } from '../../utils';
import { ImageAsset } from '../Asset';
import { Button } from '../Button';
import { OrderHeader } from './OrderHeader';

import type {
  Color,
  ElemasonOrderListWidget,
  ImageConfig,
  OrderLite,
  StyleConfig,
} from '@tectonic/types';
import type { FC } from 'react';

interface OrderCardProps {
  config: ElemasonOrderListWidget['config'];
  order: OrderLite;
}

const statusMap: Record<OrderLineStatus, { text: string; color: Color }> = {
  [OrderLineStatus.FULFILLED]: {
    text: 'Order Fulfilled',
    color: 'neutral-950',
  },
  [OrderLineStatus.PARTIALLY_FULFILLED]: {
    text: 'Order Partially Fulfilled',
    color: 'neutral-950',
  },
  [OrderLineStatus.UNFULFILLED]: {
    text: 'Order Successfully Placed.',
    color: 'success-600',
  },
};

const OrderListCard: FC<OrderCardProps> = ({ config, order }) => {
  const { style: orderCardStyle, className: orderCardClassName } =
    useStyleConfig(config?.orderCard?.container ?? {});

  const {
    style: orderLineItemContStyle,
    className: orderLineItemContClassName,
  } = useStyleConfig({
    padding: '2',
    borderRadius: 'md',
    gapX: '2',
  });

  const {
    style: orderLineItemVariantCardStyle,
    className: orderLineItemVariantCardClassName,
  } = useStyleConfig({
    gapX: '2',
  });

  const {
    style: orderLineItemVariantTextContStyle,
    className: orderLineItemVariantTextContClassName,
  } = useStyleConfig({
    gapY: '2',
  });

  const mediaAssetConfig: ImageConfig = {
    assetScale: AssetScale.X_2,
  };

  const { dispatch } = useElemasonPageContext();
  const analyticsContext = useElemasonAnalyticsContext();
  const viewOrder = (orderId: string, variantId: string) => {
    dispatch(
      {
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.ORDER_ITEM,
          route: {
            orderId,
            variantId,
          },
        },
      },
      analyticsContext
    );
  };

  const { style: mediaStyle, className: mediaClassName } = useStyleConfig({
    width: 80,
    height: 80,
    aspectRatio: 1,
    borderRadius: 'md',
  });

  const { style: mediaOverlayStyle, className: mediaOverlayClassName } =
    useStyleConfig({
      position: 'absolute',
      bottom: 0,
      borderRadius: 'full',
      borderColor: 'neutral-950',
      backgroundColor: 'white',
      border: '1',
      end: 0,
      paddingX: '2',
      paddingY: '0.5',
    });

  const orderHeaderConfig: StyleConfig = {
    paddingX: '2',
    paddingBottom: '4',
    backgroundColor: 'white',
  };

  return (
    <div
      key={`order-${order.number}`}
      className={clsx('flex flex-col', orderCardClassName)}
      style={{
        ...orderCardStyle,
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.1)',
      }}
    >
      <div className="flex flex-col">
        <OrderHeader order={order} config={orderHeaderConfig} />
      </div>

      {order.lineItems.edges.map((orderLineItemEdge) => {
        const lineItem = orderLineItemEdge.node;

        const lineItemStatus = lineItem.status;

        let lineItemStatusText = '';
        let lineItemStatusTextColor: Color;

        if (order.status === OrderStatus.CANCELLED) {
          lineItemStatusText = 'Order Cancelled';
          lineItemStatusTextColor = 'danger-600';
        } else {
          const statusInfo = statusMap[lineItemStatus];

          lineItemStatusText = statusInfo.text;
          if (lineItem.deliveryStatus === 'DELIVERED') {
            lineItemStatusText = 'Order Delivered';
          }
          lineItemStatusTextColor = statusInfo.color;
        }

        const productVariant = lineItem.productVariant;
        const showVariant = productVariant && !isDefaultVariant(productVariant);

        const lineItemImages =
          productVariant?.product.assetMap.gallery.filter(
            (asset) => !isVideo(asset.mimeType ?? '')
          ) || [];
        const lineItemImage =
          lineItemImages.length > 0
            ? lineItemImages[0]
            : {
                damId: '',
                mimeType: 'image/webp',
                originalUrl: 'https://placehold.co/80x80',
              };

        return (
          <Clickable
            onClick={() =>
              productVariant && viewOrder(order.id, productVariant.id)
            }
            key={`order-${order.name}-li-${productVariant?.simpleId ?? ''}`}
            className={clsx('flex flex-row', orderLineItemContClassName)}
            style={{
              ...orderLineItemContStyle,
              boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.10)',
            }}
          >
            <div
              className={clsx(
                'flex flex-row',
                orderLineItemVariantCardClassName
              )}
              style={{ ...orderLineItemVariantCardStyle, flex: 1 }}
            >
              <div
                className={clsx('flex flex-col')}
                style={{ flex: 0, position: 'relative' }}
              >
                <div className={clsx('flex flex-col')} style={{}}>
                  <ImageAsset
                    className={clsx(mediaClassName, 'object-contain')}
                    style={mediaStyle}
                    config={mediaAssetConfig}
                    asset={lineItemImage}
                  />
                </div>
                {lineItem.currentQuantity > 1 && (
                  <div
                    className={clsx('flex', mediaOverlayClassName)}
                    style={mediaOverlayStyle}
                  >
                    <Text
                      data={lineItem.currentQuantity.toString()}
                      config={{
                        variant: 'body3',
                        fontWeight: 'medium',
                        color: 'neutral-950',
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={clsx(
                  'flex flex-col',
                  orderLineItemVariantTextContClassName
                )}
                style={{ ...orderLineItemVariantTextContStyle, flex: 1 }}
              >
                <div className={clsx('flex flex-col')}>
                  <Text
                    data={
                      productVariant?.product.title ??
                      orderLineItemEdge.node.title
                    }
                    config={{
                      variant: 'body4',
                      fontWeight: 'medium',
                      color: 'neutral-950',
                    }}
                  />
                </div>
                {showVariant ? (
                  <div className={clsx('flex flex-col')}>
                    <Text
                      data={productVariant.basisAttr1Value}
                      config={{
                        variant: 'body4',
                        fontWeight: 'medium',
                        color: 'neutral-500',
                      }}
                    />
                  </div>
                ) : null}
                <div className={clsx('flex flex-col')}>
                  <Text
                    data={lineItemStatusText}
                    config={{
                      variant: 'body3',
                      fontWeight: 'semibold',
                      color: lineItemStatusTextColor,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={clsx('flex flex-col')} style={{ flex: 0 }}>
              <Button
                config={{
                  variant: 'outline',
                  borderColor: 'white',
                  startIcon: {
                    size: 'md',
                  },
                }}
                data={{
                  startIcon: 'outline-chevron-right',
                }}
              />
            </div>
          </Clickable>
        );
      })}
    </div>
  );
};

export { OrderListCard };
