import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { useHydrated } from '@tectonic/utils';
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from 'react';
import { Carousel } from '../../components';
import { useActionDispatch, useSharedLocalState } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';
import { useDefaultVariant } from '../Product/VariantSelector/hooks/useDefaultVariant';
import { ZoomDialog } from './ZoomDialog';

import type {
  Asset,
  ElemasonProductGalleryCarouselWidget,
} from '@tectonic/types';
import type { CarouselHandle } from '@tectonic/uikit/src/components/Carousel/Carousel.types';

interface ProductGalleryCarouselProps {
  widget: ElemasonProductGalleryCarouselWidget;
}

const ProductGalleryCarouselWidget: FC<ProductGalleryCarouselProps> = ({
  widget: { config, data, actions: wActions },
}) => {
  const hydrated = useHydrated();
  const product = useHaloScript(data?.product);
  const isMuted = useHaloScript(data?.isMuted);
  const stateKey = useHaloScript(data?.stateKey);
  const [zoomView, setZoomView] = useState(false);
  const variant = useHaloScript(data?.selectedVariant);
  const mainCarouselRef = useRef<CarouselHandle>(null);
  const thumbnailCarouselRef = useRef<CarouselHandle>(null);
  const dVariant = useDefaultVariant(product?.variants ?? []);
  const disableAutoSelection = useHaloScript(data?.disableAutoSelection);
  const dispatch = useActionDispatch();

  const selectedVariant = variant ?? dVariant;

  const [selectedIndex, setSelectedIndex] = useState(
    disableAutoSelection
      ? 0
      : product?.assetMap.gallery?.findIndex((x) =>
          (selectedVariant?.assets?.map((asset) => asset.damId) ?? []).includes(
            x.damId
          )
        ) ?? 0
  );

  const [style, className] = useStyleConfigV2(config?.container ?? {});
  const [tStyle, tClassName] = useStyleConfigV2(
    config?.thumbnailCarousel?.container ?? {}
  );

  const sharedState = useMemo(() => ({ selectedIndex }), [selectedIndex]);

  const { setSharedState } = useSharedLocalState(stateKey!, sharedState);

  const assets = product ? product.assetMap.gallery : [];

  const onSlideClick = () => {
    if (config?.zoomDialog?.visible) {
      setZoomView(true);
    }

    wActions?.onSlideClick?.forEach((action) => {
      dispatch(action);
    });
  };

  const onClose = () => {
    setZoomView(false);
    mainCarouselRef.current?.emblaApi?.scrollTo(selectedIndex);
  };

  const onThumbnailSlideClick = (asset: Asset) => {
    const index = assets.findIndex((x) => x.damId === asset.damId);
    mainCarouselRef.current?.emblaApi?.scrollTo(index);
  };

  const onSelect = useCallback(() => {
    if (!mainCarouselRef.current?.emblaApi) return;
    setSelectedIndex(mainCarouselRef.current.emblaApi.selectedScrollSnap);
    if (!thumbnailCarouselRef.current?.emblaApi) return;
    thumbnailCarouselRef.current.emblaApi.scrollTo(
      mainCarouselRef.current.emblaApi.selectedScrollSnap()
    );
  }, [mainCarouselRef.current, thumbnailCarouselRef.current, setSelectedIndex]);

  useEffect(() => {
    if (!mainCarouselRef.current?.emblaApi) return;
    onSelect();
    mainCarouselRef.current.emblaApi.on('select', onSelect);
    mainCarouselRef.current.emblaApi.on('reInit', onSelect);
  }, [mainCarouselRef.current, onSelect]);

  useEffect(() => {
    const variantAssetDamIds =
      selectedVariant?.assets?.map((x) => x.damId) ?? [];

    const index = assets.findIndex((x) => variantAssetDamIds.includes(x.damId));

    if (index > -1) {
      mainCarouselRef.current?.emblaApi?.scrollTo(index);
    }
  }, [selectedVariant]);

  useEffect(() => {
    setSharedState(sharedState);
  }, [sharedState]);

  if (!product) {
    return null;
  }

  return (
    <div style={style} className={className}>
      <Carousel
        isMuted={isMuted}
        ref={mainCarouselRef}
        config={config?.carousel}
        onSlideClick={onSlideClick}
        key={hydrated ? 'hydrated' : 'loading'}
        startIndex={hydrated ? selectedIndex : 0}
        slides={
          // eslint-disable-next-line no-nested-ternary
          hydrated
            ? assets
            : selectedVariant && selectedVariant.assets
              ? selectedVariant.assets
              : assets
        }
      />
      {config?.showThumbnails && (
        <div style={tStyle} className={tClassName}>
          <Carousel
            dragFree
            slides={assets}
            containScroll="keepSnaps"
            ref={thumbnailCarouselRef}
            selectedIndex={selectedIndex}
            config={config?.thumbnailCarousel}
            onSlideClick={onThumbnailSlideClick}
          />
        </div>
      )}
      {zoomView && config?.zoomDialog?.visible && (
        <ZoomDialog
          assets={assets}
          onClose={onClose}
          data={data?.zoomDialog}
          config={config?.zoomDialog}
          selectedIndex={selectedIndex}
          onNext={() => setSelectedIndex((prev) => prev + 1)}
          onPrevious={() => setSelectedIndex((prev) => prev - 1)}
        />
      )}
    </div>
  );
};

const MemoisedProductGalleryCarouselWidget = memo(ProductGalleryCarouselWidget);

// eslint-disable-next-line max-len
export { MemoisedProductGalleryCarouselWidget as ProductGalleryCarouselWidget };
