import clsx from 'clsx';
import { inputFieldTv } from './variant';

import type { FC } from 'react';
import type { InputFieldProps } from './InputField.types';

const InputField: FC<InputFieldProps> = ({
  size,
  className,
  ...inputProps
}) => {
  const inputClass = inputFieldTv({ size, className });

  return (
    <input type="text" className={clsx('w-full', inputClass)} {...inputProps} />
  );
};

// TODO: Add support for ref when needed.

InputField.displayName = 'UiKitInputField';

export { InputField };
