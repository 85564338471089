import { AnalyticsProductEventNames } from '@tectonic/analytics';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import { useCallback, type FC } from 'react';
import { ProductList as ProductListContent } from '../../components';
import { useElemasonAnalyticsContext } from '../../contexts/ElemasonAnalyticsContext';
import { useActionDispatch } from '../../hooks';
import { useProductSearch } from '../../hooks/network';
import { useImpressionLedger } from '../../hooks/useImpressionLedger';
import { flattenSource } from '../../utils/flattenSource';
import { useProductMap } from '../../utils/productList';

import type {
  ElemasonProductListTabsWidget,
  ElemasonProductListWidgetSourceCardData,
  ElemasonProductListWidgetTabData,
  ImpressionLedgerEntry,
  Product,
} from '@tectonic/types';

interface ElemasonProductListTabsWidgetProps {
  widget: ElemasonProductListTabsWidget;
}

interface ProductListTabContentProps {
  id: number;
  data?: ElemasonProductListWidgetTabData & {
    productFragment?: string;
    sourceCard?: ElemasonProductListWidgetSourceCardData;
  };
  config?: ElemasonProductListTabsWidgetProps['widget']['config'];

  // TODO should this be taken from root widget to get widget info for analytics events
  // onProductClick?: (product: Product) => void;
  // onSourceClick?: () => void;
}

const ProductListTabContent: FC<ProductListTabContentProps> = ({
  config,
  data,
  id,
}) => {
  const analyticsContext = useElemasonAnalyticsContext();
  const actionDispatch = useActionDispatch();

  const { hits, found, isError, isLoading } = useProductSearch(
    id,
    data?.source,
    config?.maxProductToDisplay
  );

  const onSourceClick = () => {
    const params = data?.source ? flattenSource(data?.source) : {};

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PATH,
        slug: 'plp',
        search: params,
      },
    });
  };

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });

    // trackProductEvent(
    //   ProductEventNames.PRODUCT_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsProductEventPayload>(
    //     {
    //       index,
    //       product,
    //     },
    //     widget
    //   )
    // );

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PDP,
        route: {
          slug: product.slug,
        },
      },
    });
  };

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );
  const productMap = useProductMap(hits ?? [], found ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  return (
    <ProductListContent
      // @ts-ignore
      data={data}
      count={found}
      config={config}
      products={hits}
      isError={isError}
      isLoading={isLoading}
      onSourceClick={onSourceClick}
      onProductClick={onProductClick}
      onProductInViewChange={onProductInViewChange}
    />
  );
};

export { ProductListTabContent };
