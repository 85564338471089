import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { omit } from 'lodash-es';
import { ContentShimmer, Shimmer } from '../Shimmer';

import type { ElemasonOrderListWidget } from '@tectonic/types';
import type { FC } from 'react';

interface OrderListSkeletonCardProps {
  config: ElemasonOrderListWidget['config'];
}

const OrderListSkeletonCard: FC<OrderListSkeletonCardProps> = ({ config }) => {
  const { style: orderCardStyle, className: orderCardClassName } =
    useStyleConfig(config?.orderCard?.container ?? {});

  const { style: orderHeaderStyle, className: orderHeaderClassName } =
    useStyleConfig({
      paddingBottom: '4',
      paddingX: '2',
    });

  const {
    style: orderLineItemContStyle,
    className: orderLineItemContClassName,
  } = useStyleConfig({
    padding: '2',
    borderRadius: 'md',
    gapX: '2',
  });

  return (
    <Shimmer style={{ minHeight: '200px' }} className={clsx('flex flex-col')}>
      <div
        style={{
          ...omit(orderCardStyle, 'backgroundColor', 'backgroundImage'),
        }}
        className={clsx('flex h-full w-full flex-col', orderCardClassName)}
      >
        <div
          style={{
            ...omit(orderHeaderStyle, 'backgroundColor', 'backgroundImage'),
          }}
          className={clsx('flex flex-col', orderHeaderClassName)}
        >
          <ContentShimmer className="h-full w-full" />
        </div>
        <div
          style={{
            ...omit(
              orderLineItemContStyle,
              'backgroundColor',
              'backgroundImage'
            ),
            flex: 1,
          }}
          className={clsx('flex flex-col', orderLineItemContClassName)}
        >
          <ContentShimmer className="h-full w-full" />
        </div>
      </div>
    </Shimmer>
  );
};

export { OrderListSkeletonCard };
