import { isEmpty } from 'lodash-es';
import { getCookie, setCookie } from '../helpers';

enum HeimdallIds {
  REFERRER = '__tt_heimdall_referrer',
  CLICK_ID = 'tthclid',
}

/*

Check url and cookie data
1. If both are empty return
2. if url data is not empty
    1. set it to cookie data var 
*/

// Check url and cookie data

const getHeimdallTrackingInfo = () => {
  try {
    const ttHeimdallReferrerCookie = getCookie(HeimdallIds.CLICK_ID);

    const searchParams = new URLSearchParams(globalThis.location.search);
    const ttclid = searchParams.get(HeimdallIds.CLICK_ID);
    const urlHeimdallData = (ttclid && JSON.parse(atob(ttclid))) ?? {};
    let cookieHeimdallData = isEmpty(ttHeimdallReferrerCookie)
      ? {}
      : JSON.parse(ttHeimdallReferrerCookie!);

    if (isEmpty(urlHeimdallData) && isEmpty(cookieHeimdallData)) {
      return {};
    }

    cookieHeimdallData = isEmpty(urlHeimdallData)
      ? cookieHeimdallData
      : urlHeimdallData;

    if (!isEmpty(cookieHeimdallData)) {
      setCookie(
        HeimdallIds.CLICK_ID,
        JSON.stringify(cookieHeimdallData),
        1 / (24 * 12)
      );
    }

    const {
      referrer: ttHeimdallReferrer,
      session: ttHeimdallSession,
      user: ttHeimdallUser,
    } = cookieHeimdallData;

    return {
      ttHeimdallReferrer,
      ttHeimdallSession,
      ttHeimdallUser,
    };
  } catch (error) {
    return {};
  }
};

export { getHeimdallTrackingInfo };
