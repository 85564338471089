import { isImage } from '../../media';
import {
  getCollectionUrl,
  removeParentBreadcrumb,
  sanitizeParentMeta,
  toAssetUrl,
} from './utils';

import type { DehydratedState } from '@tanstack/react-query';
import type { Asset, Collection } from '@tectonic/types';
import type {
  MetaFunctionArgs,
  MetaFunctionUrls,
  SeoTemplates,
} from './meta.types';

const getMetadata = (dehydratedState: DehydratedState) => {
  // temp changes. solve it properly
  const queries = dehydratedState?.queries ?? [];
  const [aux, collectionQuery] = queries;
  const collection = (collectionQuery?.state?.data as any)?.data?.collection;

  return { collection };
};

const getOgTags = (
  collection: Collection,
  seoTemplates: SeoTemplates,
  urls: MetaFunctionUrls,
  queryParams: URLSearchParams
) => {
  const asset = collection.assetMap.gallery.find((x: Asset) =>
    isImage(x.mimeType)
  );
  const assetUrl = asset ? toAssetUrl(asset, urls.damUrl) : undefined;
  const collectionUrl = getCollectionUrl(collection, urls, queryParams);

  const imageTags = assetUrl
    ? [
        {
          property: 'og:image',
          itemProp: 'image',
          content: assetUrl,
        },
        {
          property: 'og:image:secure_url',
          itemProp: 'image',
          content: assetUrl,
        },
        {
          property: 'og:image:width',
          content: asset ? asset.dimensions?.width : undefined,
        },
        {
          property: 'og:image:height',
          content: asset ? asset.dimensions?.height : undefined,
        },
      ]
    : [];

  const title = collection.seoFields?.name ?? collection.name;

  const description =
    collection.seoFields?.description ?? collection.description;

  const titleTags = title ? [{ title }] : [];

  const descriptionTags = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:description',
      content: description,
    },
  ];

  const keywordsTag = [
    {
      name: 'keywords',
      content: `${collection.name}, Vaaree, vaaree.com`,
    },
  ];

  return [
    { tagName: 'link', rel: 'canonical', href: collectionUrl },

    ...titleTags,
    ...descriptionTags,
    ...keywordsTag,
    ...imageTags,
    { property: 'og:url', content: collectionUrl },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
  ];
};

const getBreadcrumbJsonLd = (
  collection: Collection,
  urls: MetaFunctionUrls,
  queryParams: URLSearchParams
) => {
  const breadcrumb = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: new URL(urls.requestUrl).origin,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: collection.name,
        item: getCollectionUrl(collection, urls, queryParams),
      },
    ],
  };
  return [breadcrumb];
};

const getJsonLdTags = ({
  collection,
  urls,
  queryParams,
}: {
  collection: Collection;
  urls: MetaFunctionUrls;
  queryParams: URLSearchParams;
}) => {
  const breadcrumbs = getBreadcrumbJsonLd(collection, urls, queryParams);
  return [...breadcrumbs].map((ld) => ({ 'script:ld+json': ld }));
};

const collectionMetaFunction = (
  args: MetaFunctionArgs,
  seoTemplates: SeoTemplates
) => {
  const { data, matches, location } = args;
  const { dehydratedState, urls } = data!;
  const queryParams = new URLSearchParams(location.search);

  if (!dehydratedState) {
    return matches.flatMap((match) => match.meta);
  }

  let parentMeta = sanitizeParentMeta(matches).filter(
    (tag) => tag.rel !== 'canonical'
  );
  // Remove parent Breadcrumb as its already getting added by getBreadcrumbJsonLd
  parentMeta = removeParentBreadcrumb(parentMeta);
  const { collection } = getMetadata(dehydratedState as DehydratedState);

  if (!collection) {
    return matches.flatMap((match) => match.meta);
  }

  const ogTags = getOgTags(collection!, seoTemplates, urls, queryParams);
  const jsonLdTags = getJsonLdTags({ collection, urls, queryParams });

  return [...ogTags, ...parentMeta, ...jsonLdTags];
};

export { collectionMetaFunction };
