// RecommendationProductGetRecommendationPayload

import axios from 'axios';

import type {
  RecommendationProductApiRouteLoaderResponse,
  RecommendationProductGetRecommendationPayload,
} from '@tectonic/types';

const getProductRecommendations = async (
  params: RecommendationProductGetRecommendationPayload
): Promise<RecommendationProductApiRouteLoaderResponse> => {
  const response = await axios.get('/api/recommendation/product', { params });
  return response.data;
};

const getCartRecommendations = async (
  params: RecommendationProductGetRecommendationPayload
): Promise<RecommendationProductApiRouteLoaderResponse> => {
  const response = await axios.get('/api/recommendation/cart', { params });
  return response.data;
};

export { getCartRecommendations, getProductRecommendations };
