import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AnalyticsSearchEventNames
} from '@tectonic/analytics';
import {
  deleteSearchHistory,
  getSearchHistory,
} from '@tectonic/remix-client-network';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
  type ElemasonSearchHistoryWidget,
  type SearchHistoryApiRouteLoaderResponse,
  type UserSearchHistory,
} from '@tectonic/types';
import { useActionDispatch } from '../../../hooks';
import { flattenSource } from '../../../utils/flattenSource';


const queryFn = () => getSearchHistory();
const queryKey = ['searchHistory'];
const mutationFn = (id?: string) => deleteSearchHistory(id);

const useSearchHistory = (widget: ElemasonSearchHistoryWidget) => {
  const queryClient = useQueryClient();
  const actionDispatch = useActionDispatch();
  const { data: response, isLoading } =
    useQuery<SearchHistoryApiRouteLoaderResponse>({ queryKey, queryFn });
  const queries = response?.data ? response.data.hits : [];

  const { mutate } = useMutation({
    mutationKey: queryKey,
    mutationFn,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const onClear = (id?: string) => {
    mutate(id);
  };

  const onQueryClick = (query: UserSearchHistory) => {
    const params = { rawQuery: query.slug, ...flattenSource(query.source) };

    // trackSearchEvent(
    //   AnalyticsSearchEventNames.SEARCH_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsSearchEventPayload>(
    //     {
    //       querySource: query.source,
    //     },
    //     widget
    //   )
    // );

    actionDispatch(
      {
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsSearchEventNames.SEARCH_CLICK,
          data: {
            querySource: query.source,
          },
        }
      }
    )

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PATH,
        slug: 'plp',
        search: params,
      },
    });
  };

  return { isLoading, queries, onQueryClick, onClear };
};

export { useSearchHistory };
