import { isNull, isObject, isUndefined } from 'lodash-es';

const getUrlSearchParams = <T = Record<string, string>>(url: string) =>
  Object.fromEntries(new URL(url).searchParams) as T;

const toUrlSearchParams = (params: object): string => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (isUndefined(value) || isNull(value)) {
      continue;
    }

    if (isObject(value)) {
      searchParams.append(key, JSON.stringify(value));
    } else {
      searchParams.append(key, value as string);
    }
  }

  return searchParams.toString();
};

const toUrlPath = (pathname: string, params: object): string => {
  const qs = toUrlSearchParams(params).trim();
  if (!qs) {
    return pathname;
  }
  const char = qs.startsWith('?') ? '' : '?';
  return [pathname, qs].join(char);
};

export { getUrlSearchParams, toUrlPath, toUrlSearchParams };
