import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { isOutOfStock } from '../../../utils';

import type {
  ProductVariant,
  VariantSizeSelectorConfig,
} from '@tectonic/types';
import type { FC } from 'react';

interface SizeSelectorProps {
  variants: ProductVariant[];
  config?: VariantSizeSelectorConfig;
  selectedVariant?: ProductVariant;
  onSelect: (variant: ProductVariant) => void;
  attribute: 'basisAttr1' | 'basisAttr2' | 'basisAttr3';
}

const SizeSelector: FC<SizeSelectorProps> = ({
  config,
  variants,
  onSelect,
  attribute,
  selectedVariant,
}) => {
  const { style, className } = useStyleConfig(config?.container ?? {});
  const { style: iStyle, className: iClassName } = useStyleConfig(
    config?.item ?? {}
  );
  const { style: sStyle, className: sClassName } = useStyleConfig(
    config?.selectedItem ?? {}
  );
  const { style: uStyle, className: uClassName } = useStyleConfig(
    config?.unavailableItem ?? {}
  );

  return (
    <div style={style} className={clsx('flex shrink-0', className)}>
      {variants?.map((variant) => {
        const isOOS = isOutOfStock(variant.stockStatus);
        let textConfig;
        if (isOOS) {
          textConfig = config?.unavailableText;
        } else if (variant.id === selectedVariant?.id) {
          textConfig = config?.selectedText;
        } else {
          textConfig = config?.text;
        }

        return (
          <Clickable
            title="Size Selector Item"
            style={{
              ...(variant.id === selectedVariant?.id ? sStyle : iStyle),
              ...(isOOS ? uStyle : {}),
            }}
            key={variant.id}
            onClick={() => onSelect(variant)}
            className={clsx(
              'flex',
              variant.id === selectedVariant?.id && !isOOS
                ? sClassName
                : iClassName,
              isOOS ? uClassName : ''
            )}
          >
            <Text data={variant[`${attribute}Display`]} config={textConfig} />
          </Clickable>
        );
      })}
    </div>
  );
};

export { SizeSelector };
