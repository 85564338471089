import { Typography } from '@tectonic/uikit';
import { isString } from 'lodash-es';
import { Component, isValidElement } from 'react';
import { Loader } from '../Loader';

import type { Maybe } from '@tectonic/types';
import type { ReactNode } from 'react';

interface RenderBoundaryProps {
  loader?: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
  hasError?: boolean;
  error?: Maybe<Error>;
  fallback?: ReactNode;
}

interface RenderBoundaryState {
  error: Maybe<Error>;
}

const getLoader = (loader?: ReactNode) => {
  // TODO: Make the typography configurable
  if (isString(loader)) return <Typography>{loader}</Typography>;
  if (isValidElement(loader)) return loader;

  return <Loader />;
};

const getFallback = (error?: Maybe<Error>, fallback?: ReactNode) => {
  // TODO: Make the typography configurable
  if (isString(fallback)) return <Typography>{fallback}</Typography>;
  if (isValidElement(fallback)) return fallback;

  const message = 'Oops! Something went wrong';

  return <Typography className="text-danger-700">{message}</Typography>;
};

class RenderBoundary extends Component<
  RenderBoundaryProps,
  RenderBoundaryState
> {
  constructor(props: RenderBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { children } = this.props;
    const { error: internalError } = this.state;
    const { error, fallback, isLoading, loader, hasError } = this.props;

    // isLoading takes precedence over error
    // error from props takes precedence over internalError
    if (isLoading) return getLoader(loader);
    if (internalError || error || hasError)
      return getFallback(error ?? internalError, fallback);

    return children;
  }
}

export { RenderBoundary };
