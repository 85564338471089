import { useAnalyticsStore } from '@tectonic/analytics';
import { create } from 'zustand';

import type { Direction } from '@tectonic/types';

interface DrawerState {
  slug: string;
  data: unknown;
  exit?: Direction;
  entry?: Direction;
  exiting?: boolean;
  animationType?: string;
}

interface DrawersState {
  drawers: DrawerState[];
  open: (
    slug: string,
    data?: unknown,
    entry?: Direction,
    exit?: Direction,
    animationType?: string
  ) => void;
  close: (slug: string) => void;
  closeAll: () => void;
}

const useDrawers = create<DrawersState>((setState) => ({
  drawers: [],
  open: (slug, data, entry, exit, animationType) =>
    setState((state) => ({
      drawers: [...state.drawers, { slug, data, entry, exit, animationType }],
    })),
  close: (slug) => {
    useAnalyticsStore.getState().popDrawer();
    setState((state) => ({
      drawers: state.drawers.filter((drawer) => drawer.slug !== slug),
    }));
  },
  closeAll() {
    setState(() => ({ drawers: [] }));
  },
}));

export { useDrawers };
