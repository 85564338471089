import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { Button } from '../../Button';
import { Icon } from '../../Icon';

import type { FC, MouseEvent } from 'react';
import type { SearchQueryProps } from './SearchHistory.types';

const DEFAULT_TEMPLATE = '{name}';

const SearchQuery: FC<SearchQueryProps> = ({
  config,
  data,
  onClear,
  onQueryClick,
  query,
}) => {
  const { container, startIcon, clearCta, text } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  const onClick = () => {
    onQueryClick(query);
  };

  const handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    onClear(query.id);
  };

  return (
    <Clickable
      onClick={onClick}
      style={containerStyleConfig.style}
      className={clsx('flex items-center', containerStyleConfig.className)}
    >
      <div className={clsx('flex grow items-center gap-2')}>
        {data.startIcon ? (
          <Icon config={startIcon} symbol={data.startIcon} />
        ) : null}
        <Text
          config={text}
          placeholders={{ name: query.name }}
          data={data.text ?? DEFAULT_TEMPLATE}
        />
      </div>

      <Button
        onClick={handleClear}
        config={clearCta?.button}
        data={data.clearCta.button}
      />
    </Clickable>
  );
};

SearchQuery.displayName = 'SearchQuery';

export { SearchQuery };
