import { SearchSortFacet } from '../../../components';
import { useSortFacet } from './hooks';

import type { FC } from 'react';
import type { SearchSortFacetWidgetProps } from './SearchSortFacet.types';

const SearchSortFacetWidget: FC<SearchSortFacetWidgetProps> = ({ widget }) => {
  const { isLoading, onSelect, sortBy, sortFacetConfig } = useSortFacet(widget);

  return (
    <SearchSortFacet
      config={widget.config}
      data={widget.data!}
      isLoading={isLoading}
      onSelect={onSelect}
      sortBy={sortBy}
      sortFacet={sortFacetConfig}
    />
  );
};

SearchSortFacetWidget.displayName = 'SearchSortFacetWidget';

export { SearchSortFacetWidget };
