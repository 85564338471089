import { Logger } from '@tectonic/logger';
import { ProductStockStatus } from '@tectonic/types';
import { trackEvent } from './event';

import type { ProductVariant } from '@tectonic/types';
import type {
  AnalyticsProductEventPayload,
  AnalyticsProductImpressionPayload,
} from '../types';

const toProductImpressionPayload = ({
  index,
  product,
  variant,
  searchParams,
  totalFound,
}: AnalyticsProductImpressionPayload) => {
  const auxVariant =
    variant ??
    product.variants?.find(
      (v: ProductVariant) => v.stockStatus === ProductStockStatus.OUT_OF_STOCK
    ) ??
    product.variants?.[0];
  return {
    index,
    productId: product.simpleId,
    productGroupId: null,
    productHandle: product.slug,
    itemId: auxVariant?.sku,
    variantId: auxVariant?.simpleId,
    currency: auxVariant?.price.currencyCode,
    discount: auxVariant
      ? auxVariant.mrp.amount - auxVariant.price.amount
      : undefined,
    itemBrand: product.brand,
    itemCategory: product.category,
    mrp: auxVariant?.mrp.amount,
    sellingPrice: auxVariant?.price.amount,
    productTitle: product.title,
    productVariant: auxVariant?.basisAttr1Display,
    itemVariant: auxVariant?.title,
    page: searchParams ? (searchParams.page as string) : undefined,
    searchParams,
    totalFound,
  };
};

const trackProductEvent = (
  event: string,
  payload: AnalyticsProductEventPayload
) => {
  try {
    const { product, variant, ...rest } = payload;
    trackEvent(event, {
      ...toProductImpressionPayload({ product, variant }),
      ...rest,
    });
  } catch (error) {
    Logger.error('[trackProductEvent]: Unable to track product event', error);
  }
};

export { toProductImpressionPayload, trackProductEvent };
