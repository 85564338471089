import { useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  Typography,
  useForm,
} from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import clsx from 'clsx';
import { Button } from '../../Button';
import { TextField } from '../../TextField';

import type { FormSubmitHandler } from '@tectonic/uikit';
import type { FC } from 'react';
import type {
  AuthEmailPasswordSignInFormProps,
  AuthEmailPasswordSignInFormValue,
} from './AuthEmailPasswordSignInForm.types';

const defaultValues: AuthEmailPasswordSignInFormValue = {
  email: '',
  password: '',
};

const AuthEmailPasswordSignInForm: FC<AuthEmailPasswordSignInFormProps> = ({
  data,
  isLoading,
  onResetPassword,
  onSubmit,
  config,
}) => {
  const { control, setError, formState } =
    useForm<AuthEmailPasswordSignInFormValue>({
      defaultValues,
      mode: 'onChange',
    });

  const { errors } = formState;

  const handleSubmit: FormSubmitHandler<AuthEmailPasswordSignInFormValue> = (
    formData
  ) => {
    const value = formData.data;
    const hasEmail = !isBlank(value.email);
    const hasPassword = !isBlank(value.password);

    if (!hasEmail) {
      setError('email', {
        type: 'custom',
        message: data.emailInput.textField.required!,
      });
    }
    if (!hasPassword) {
      setError('password', {
        type: 'custom',
        message: data.passwordInput.textField.required!,
      });
    }

    if (hasEmail && hasPassword) {
      onSubmit(value);
    }
  };

  const emailInputContainerStyleConfig = useStyleConfig(
    config?.emailInput?.container ?? {}
  );

  const passwordInputContainerStyleConfig = useStyleConfig(
    config?.passwordInput?.container ?? {}
  );

  const resetCtaContainerStyleConfig = useStyleConfig(
    config?.resetPasswordCta?.container ?? {}
  );

  const submitCtaContainerStyleConfig = useStyleConfig(
    config?.submitCta?.container ?? {}
  );

  return (
    <Form control={control} onSubmit={handleSubmit}>
      <div
        style={emailInputContainerStyleConfig.style}
        className={clsx(
          'flex flex-col gap-2',
          emailInputContainerStyleConfig.className
        )}
      >
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              data={data.emailInput.textField}
              config={config?.emailInput?.textField}
              value={field.value}
              type="email"
              disabled={isLoading}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        <FormErrorMessage
          name="email"
          errors={errors}
          as={Typography}
          className="text-danger-600"
          variant="body3"
        />
      </div>
      <div
        style={passwordInputContainerStyleConfig.style}
        className={clsx(
          'flex flex-col gap-2',
          passwordInputContainerStyleConfig.className
        )}
      >
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField
              data={data.passwordInput.textField}
              config={config?.passwordInput?.textField}
              value={field.value}
              type="password"
              disabled={isLoading}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
        <FormErrorMessage
          name="password"
          errors={errors}
          as={Typography}
          className="text-danger-600"
          variant="body4"
        />
      </div>
      <div
        style={resetCtaContainerStyleConfig.style}
        className={clsx('flex', resetCtaContainerStyleConfig.className)}
      >
        <Button
          disabled={isLoading}
          type="button"
          onClick={onResetPassword}
          config={config?.resetPasswordCta?.button}
          data={data.resetPasswordCta.button}
        />
      </div>
      <div
        style={submitCtaContainerStyleConfig.style}
        className={clsx(
          'flex flex-col',
          submitCtaContainerStyleConfig.className
        )}
      >
        <Button
          isLoading={isLoading}
          type="submit"
          config={config?.submitCta?.button}
          data={data.submitCta.button}
        />
      </div>
    </Form>
  );
};

AuthEmailPasswordSignInForm.displayName = 'AuthEmailPasswordSignInForm';

export { AuthEmailPasswordSignInForm };
