import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { TextArea } from '@tectonic/uikit';
import { useHaloScript, useSharedLocalState } from '../../hooks';

import type { ElemasonTextAreaData } from '@tectonic/types';
import type { ChangeEvent, FC } from 'react';
import type { TextAreaWidgetProps } from './TextArea.types';

const TextAreaWidget: FC<TextAreaWidgetProps> = ({ widget }) => {
  const wData = widget.data;
  const hResult = useHaloScript<
    {
      stateKey: string;
      defaultValue?: string;
    } & ElemasonTextAreaData
  >(wData);
  const {
    stateKey,
    defaultValue,
    placeholder,
    rows,
    cols,
    minLength,
    maxLength,
    disabled,
  } = hResult ?? {};

  const wConfig = widget.config;
  const [labelStyle, labelClass] = useStyleConfigV2(wConfig?.container);
  const { rounded, size } = wConfig ?? {};

  const { sharedState, setSharedState } = useSharedLocalState<string>(
    stateKey!,
    defaultValue ?? ''
  );

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSharedState(event.target.value);
  };

  return (
    <TextArea
      rounded={rounded}
      size={size}
      onChange={onChange}
      value={sharedState ?? ''}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      minLength={minLength}
      maxLength={maxLength}
      disabled={disabled}
      _label={{ style: labelStyle, className: labelClass }}
    />
  );
};

TextAreaWidget.displayName = 'TextAreaWidget';

export { TextAreaWidget };
