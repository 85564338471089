import { AnalyticsProductEventNames } from '@tectonic/analytics';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
} from '@tectonic/types';
import { useActionDispatch } from '../../../hooks';
import { useProductRecommendations } from '../../../hooks/network';
import { useAccessorValue } from '../../../hooks/useAccessorValue';

import type {
  ElemasonProductRecommendationsWidget,
  PaginationOptions,
  Product,
} from '@tectonic/types';

const useRecommendationWidget = (
  widget: ElemasonProductRecommendationsWidget,
  {
    perPage = 10,
    page = 1,
    enabled = true,
    queryKey,
  }: PaginationOptions & { enabled?: boolean; queryKey?: any } = {}
) => {
  const { slug, intent, entity } = widget.data ?? {};
  const identifier = useAccessorValue<string>(slug ?? {});
  const { isLoading, isError, onRetry, count, products } =
    useProductRecommendations(
      {
        perPage,
        page,
        identifier,
        intent,
        entity,
      },
      { enabled, queryKey }
    );

  const onSourceClick = () => {};

  const actionDispatch = useActionDispatch();

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });

    // trackProductEvent(
    //   ProductEventNames.PRODUCT_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsProductEventPayload>(
    //     {
    //       index,
    //       product,
    //     },
    //     widget
    //   )
    // );

    if (!widget.config?.preventPdpNavigation) {
      actionDispatch({
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PDP,
          route: {
            slug: product.slug,
          },
        },
      });
    }
  };

  return {
    isLoading,
    isError,
    onRetry,
    count,
    products,
    onProductClick,
    onSourceClick,
  };
};

export { useRecommendationWidget };
