import clsx from 'clsx';

import type { ComponentProps, FC } from 'react';

type ShimmerProps = ComponentProps<'div'>;

const Shimmer: FC<ShimmerProps> = ({ className, ...props }) => (
  <div className={clsx('animate-pulse bg-gray-300', className)} {...props} />
);

const ContentShimmer: FC<ShimmerProps> = ({
  className,
  children,
  ...props
}) => (
  <div className={clsx('relative', className)} {...props}>
    <div className="inline-flex animate-pulse bg-gray-300 text-transparent">
      <div className="invisible">{children}</div>
    </div>
  </div>
);

export { ContentShimmer, Shimmer };
