import { tv } from 'tailwind-variants';

const inputFieldTv = tv({
  base: 'input-field',
  variants: {
    size: {
      lg: 'input-field-lg',
      md: 'input-field-md',
      sm: 'input-field-sm',
      xs: 'input-field-xs',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export { inputFieldTv };
