import clsx from 'clsx';
import { useId } from 'react';

import type { ChangeEvent, FC } from 'react';
import type { FileInputProps } from './FileInput.types';

/**
 * Check if a mime type matches the set given in accept
 *
 * @param type the mime type to test, ex image/png
 * @param accept the mime types to accept, ex audio/*,video/*,image/png
 * @returns true if the mime is accepted, false otherwise
 */
const verifyAccept = (type: string, accept: string): boolean => {
  const allowed = accept.split(',').map((x) => x.trim());
  return allowed.includes(type) || allowed.includes(`${type.split('/')[0]}/*`);
};

const FileInput: FC<FileInputProps> = ({
  onChange,
  accept,
  multiple,
  inputRef,
}) => {
  const id = useId();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const files = fileList ? [...fileList] : [];
    const result = accept
      ? files.filter((file) => verifyAccept(file.type, accept))
      : files;
    onChange(result);

    // Clear the input value to allow selecting the same file again
    const input = event.target;
    input.value = '';
  };

  return (
    <input
      id={id}
      ref={inputRef}
      type="file"
      className={clsx('hidden h-0 w-0')}
      accept={accept}
      multiple={multiple}
      onChange={handleChange}
    />
  );
};

FileInput.displayName = 'FileInput';

export { FileInput };
