import { useMutation } from '@tanstack/react-query';
import { Logger } from '@tectonic/logger';
import { uploadFile } from '@tectonic/remix-client-network';
import { isNil, uniqueId } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useToast } from '../../core';
import {
  useFragmentValue,
  useHaloScript,
  usePageFragment,
  useSharedLocalState,
} from '../../hooks';

import type { Asset, ElemasonFileUploadWidget, Maybe } from '@tectonic/types';
import type { RefObject } from 'react';

interface MediaFile {
  id: string;
  file: File;
  asset?: Partial<Asset>;
  isUploading?: boolean;
  hasUploadError?: boolean;
}

const uploadAsset = async (mediaFile: MediaFile): Promise<Asset> => {
  const formData = new FormData();
  formData.append('file', mediaFile.file);
  const response = await uploadFile(formData, { usecase: 'REVIEW' });
  if (response.error || isNil(response.data)) {
    throw response.error;
  }
  return response.data;
};

const useFileUpload = (
  widget: ElemasonFileUploadWidget,
  inputRef: RefObject<HTMLInputElement>
) => {
  const wData = widget.data!;
  const { accept, multiple, maxFileSize, maxFiles, errorMessages } = wData;
  const { showToast } = useToast();
  const [statuses, setStatuses] = useState<
    Record<string, 'pending' | 'error' | 'success'>
  >({});

  const stateKey = useHaloScript<string>(wData.stateKey)!;
  const { setSharedState } = useSharedLocalState(stateKey);

  const [mediaFiles, setSelectedMediaFiles] = useState<MediaFile[]>([]);

  const { mutateAsync } = useMutation({
    mutationKey: [],
    mutationFn: uploadAsset,
  });

  const onUpload = async (media: MediaFile) => {
    setStatuses((prev) => ({ ...prev, [media.id]: 'pending' }));
    try {
      const asset = await mutateAsync(media);
      setSelectedMediaFiles((prev) =>
        prev.map((m) => (m.id === media.id ? { ...m, asset } : m))
      );
      setStatuses((prev) => ({ ...prev, [media.id]: 'success' }));
    } catch (error) {
      Logger.error('[useFileUpload]: Unable to upload asset', error);
      setStatuses((prev) => ({ ...prev, [media.id]: 'error' }));
    }
  };

  const onInputClick = () => {
    inputRef.current!.click();
  };

  const validateFiles = (files: File[]) => {
    let error: Maybe<string> = null;
    let result = files;

    if (!isNil(maxFileSize) && errorMessages?.maxFileSize) {
      const nResult = result.filter((file) => file.size <= maxFileSize);
      error =
        nResult.length !== result.length ? errorMessages.maxFileSize : error;
      result = result.filter((file) => file.size <= maxFileSize);
    }

    if (!isNil(maxFiles) && errorMessages?.maxFiles) {
      const count = mediaFiles.length + result.length;
      error = count > maxFiles ? errorMessages.maxFiles : error;
      const remaining = Math.max(maxFiles - mediaFiles.length, 0);
      result = result.slice(0, remaining);
    }

    return { error, result };
  };

  const onChange = async (files: File[]) => {
    validateFiles(files);
    const { result, error } = validateFiles(files);

    if (error) {
      showToast({ title: error });
    }

    if (result.length === 0) {
      return;
    }

    const sFiles = result.map((file) => ({ file, id: uniqueId() }));

    if (multiple) {
      setSelectedMediaFiles((prev) => [...prev, ...sFiles]);
    } else {
      setSelectedMediaFiles(sFiles);
    }
    sFiles.forEach((mFile) => onUpload(mFile));
  };

  const onRemove = (media: MediaFile) => {
    setSelectedMediaFiles((prev) => prev.filter((m) => m.id !== media.id));
    setStatuses((prev) => {
      const { [media.id]: status, ...rest } = prev;
      return rest;
    });
  };

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    files: mediaFiles.map((media) => ({
      ...media,
      isUploading: statuses[media.id] === 'pending',
      hasUploadError: statuses[media.id] === 'error',
    })),
    onInputClick,
    onRemove,
    onUpload,
  });

  useEffect(() => {
    setSharedState(mediaFiles);
  }, [setSharedState, mediaFiles]);

  return { fragment, fragmentData, onChange, multiple, accept };
};

export { useFileUpload };
