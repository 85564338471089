import { create } from 'zustand';

import type { Toast } from '@tectonic/types';

interface ToastState {
  toast?: Toast;
  close: () => void;
  showToast: (toast: Toast) => void;
}

const useToast = create<ToastState>((setState) => ({
  toast: undefined,
  close: () => setState({ toast: undefined }),
  showToast: (toast: Toast) => setState({ toast }),
}));

export { useToast };
export type { Toast };
