import { data, encodeId } from '@tectonic/utils';
import axios from 'axios';

import type {
  BoughtTogetherProducts,
  Cart,
  CartAddLinePayload,
  CartDiscountMode,
  CartExpressCheckoutPayload,
  CartFinalizePayload,
  CartLine,
  CartQuantityResponse,
  CartUpdateLinePayload,
  Collection,
  CursorPaginationOptions,
  DiscountCodesPayload,
  ElemasonFragment,
  ElemasonRoot,
  Look,
  Order,
  PaginatedResponse,
  Product,
  ProductRecommendations,
  ProductReview,
  ProductReviewsList,
  ProductSearchResponse,
  Promotion,
  PromotionResponse,
  RecentlyViewedProducts,
  RegisterInterestPayload,
  SearchAutoCompleteResponse,
  SearchConfig,
  SearchConfigParams,
  SearchParamsWithExpressions,
  SearchQuery,
  ServiceabilityResponse,
  User,
  UserOrderMinimalLoaderRouteResponse,
  UserOrdersLoaderRouteResponse,
  UserProfileData,
  UserProfileDataUpdate,
  UserProfileSchema,
  WidgetServicePageResponse,
} from '@tectonic/types';
import type { GenericAbortSignal } from 'axios';

const getInstance = () => {
  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use((config: any) => {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-Initiated-At'] = Date.now();
    // @ts-ignore
    const timeDrift = globalThis.timeDrift?.toString();
    if (timeDrift) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-Time-Drift'] = timeDrift;
    }
    return config;
  });
  return axiosInstance;
};

const getRoot = async () =>
  data(getInstance().get<ElemasonRoot>('/api/pages/root'));

const getLook = async (slug: string) =>
  data(getInstance().get<Look>(`/api/looks/${slug}`));

const getLooks = async (params: SearchQuery) =>
  data(getInstance().post<PaginatedResponse<Look>>('/api/looks', params));

const getFragment = async (id: string) =>
  data(getInstance().get<ElemasonFragment>(`/api/fragments/${id}`));

const getPage = async (slug: string) =>
  data(getInstance().get<WidgetServicePageResponse>(`/api/pages/${slug}`));

const getProduct = async (slug: string) =>
  data(getInstance().get<Product>(`/api/products/${slug}`));

const geCurrentUser = async () =>
  data(getInstance().get<User>('/api/users/me'));

const applyReferrerCode = async (payload: { referrerCode: string }) =>
  data(getInstance().patch('/api/users/referrer-code', payload));

const redeemReward = async (payload: { redeemId: string }) =>
  data(getInstance().post('/api/users/redeem-reward', payload));

const setPreferredPincode = async (payload: { zip: string }) =>
  data(getInstance().post('/api/users/preferences/zip', payload));

const triggerSocialEvent = async (payload: { event: string }) =>
  data(getInstance().post('/api/users/social-event', payload));

const getProfileData = async () =>
  data(
    getInstance().get<{ profileData: UserProfileData }>(
      `/api/users/profile-data`
    )
  );

const getCoinsLedger = async (page?: number, perPage?: number) =>
  data(
    getInstance().get('/api/users/coins/ledger', { params: { page, perPage } })
  );

const getProductProfileSchema = async (slug: string) =>
  data(
    getInstance().get<UserProfileSchema>(
      `/api/products/${slug}/user-profile-schema`
    )
  );

const getProductLooks = async (slug: string) =>
  data(
    getInstance().get<PaginatedResponse<Look>>(
      `/api/products/${slug}/looks?page=1&perPage=1`
    )
  );

const getCollection = async (slug: string) =>
  data(getInstance().get<Collection>(`/api/collections/${slug}`));

const searchProducts = async (params: Partial<SearchParamsWithExpressions>) =>
  data(getInstance().post<ProductSearchResponse>('/api/products', params));

const searchCollection = async (
  params: Partial<SearchParamsWithExpressions>,
  slug: string
) =>
  data(
    getInstance().post<ProductSearchResponse>(
      `/api/collections/${slug}/search`,
      params
    )
  );

const searchCollectionProducts = async (
  params: Partial<SearchParamsWithExpressions>,
  slug: string
) =>
  (
    await data(
      getInstance().post<ProductSearchResponse>(
        `/api/collections/${slug}/product/search`,
        params
      )
    )
  )?.data;

const getReviewsByTag = async (
  tag: string,
  page: number,
  perPage: number,
  signal?: GenericAbortSignal
) =>
  data(
    axios.get<PaginatedResponse<ProductReview>>('/api/reviews', {
      params: { tag, page, perPage },
      signal,
    })
  );

const getProductRecommendations = async (
  slug: string,
  params: URLSearchParams
) =>
  data(
    getInstance().get<ProductRecommendations>(
      `/api/products/${slug}/recommendations?${params}`
    )
  );
const getRecentlyViewedProducts = async () =>
  data(getInstance().get<RecentlyViewedProducts>('/api/users/products/viewed'));

const getRatingsForProduct = async (slug: string) =>
  data(getInstance().get<ProductReviewsList>(`/api/products/${slug}/ratings`));

const getReviewsForProduct = async (slug: string, params: URLSearchParams) =>
  data(
    getInstance().get<ProductReviewsList>(
      `/api/products/${slug}/reviews?${params}`
    )
  );

const searchReviewsForProduct = async (slug: string, params: SearchQuery) =>
  data(
    getInstance().post<ProductReviewsList>(
      `/api/products/${slug}/reviews/search`,
      params
    )
  );

const searchConfig = async (params: SearchConfigParams) =>
  data(
    getInstance().get<{ data: SearchConfig }>('/api/search/config', { params })
  );

const getPromotionsForProductVariant = async (
  slug: string,
  params: URLSearchParams
) =>
  data(
    getInstance().get<PromotionResponse>(
      `/api/products/${slug}/promotions?${params}`
    )
  );

const getBoughtTogetherProductsForProduct = async (
  slug: string,
  params: URLSearchParams
) =>
  data(
    getInstance().get<BoughtTogetherProducts>(
      `/api/products/${slug}/recommendations/bought-together?${params}`
    )
  );

const getCart = async () => data(getInstance().get<Cart>('/api/users/cart'));

const getCartFreeGifts = async () =>
  data(getInstance().get<Cart>('/api/users/cart/free-gifts'));

const addToCart = async (payload: CartAddLinePayload[]) =>
  data(getInstance().post<Cart>('/api/users/cart/lines', payload));

const updateCartLines = async (payload: CartUpdateLinePayload[]) =>
  data(getInstance().patch<Cart>('/api/users/cart/lines', payload));

const getCartQuantity = async () =>
  data(getInstance().get<CartQuantityResponse>('/api/users/cart/quantity'));

const updateCart = async (payload: CartUpdateLinePayload[]) =>
  data(getInstance().patch<Cart>('/api/users/cart/lines', payload));

const deleteCartLines = async (payload: CartLine[]) =>
  data(
    getInstance().delete<Cart>('/api/users/cart/lines', {
      params: { lineIds: payload.map((line) => line.id).join(',') },
    })
  );

const getCartDiscounts = async (mode: CartDiscountMode) =>
  data(
    getInstance().get<PaginatedResponse<Promotion>>(
      '/api/users/cart/discounts',
      {
        params: { mode },
      }
    )
  );

const removeCartDiscounts = async () =>
  data(getInstance().delete<Cart>('/api/users/cart/discounts'));

const overrideCartDiscounts = async (payload: DiscountCodesPayload) =>
  data(getInstance().post<Cart>('/api/users/cart/discounts', payload));

const expressCheckout = async (payload: CartExpressCheckoutPayload) =>
  data(getInstance().post<Cart>('/api/users/cart/express-checkout', payload));

const getOrders = async (
  params: CursorPaginationOptions & { provider?: string }
) =>
  data(
    getInstance().get<UserOrdersLoaderRouteResponse>(`/api/users/orders`, {
      params,
    })
  );

const getMinimalOrder = async (
  params: CursorPaginationOptions & { orderId: string }
) =>
  data(
    getInstance().get<UserOrderMinimalLoaderRouteResponse>(
      `/api/users/orders/minimal`,
      { params }
    )
  );

const getOrderDetails = async (orderId: string) =>
  data(getInstance().get<Order>(`/api/users/orders/${encodeId(orderId)}`));

const getClickPostReturnExchangeUrl = async (params: { trackingUrl: string }) =>
  data(
    getInstance().get<{ url: string }>('/api/click-post/return-exchange-url', {
      params,
    })
  );

const finalizeCart = async (payload: CartFinalizePayload) =>
  data(getInstance().put<Cart>('/api/users/cart/finalize', payload));

const updateCartNote = async (note: string) =>
  data(getInstance().put<Cart>('/api/users/cart/note', { note }));

const emailSubscription = async (email: string) =>
  data(getInstance().post('/api/users/subscribe', { email }));

const getServiceability = async (variantId: string, zipcode?: string) =>
  data(
    getInstance().get<ServiceabilityResponse>(
      `/api/variants/${variantId}/serviceability`,
      { params: { zipcode } }
    )
  );

const updateUserProfileData = async (payload: UserProfileDataUpdate) =>
  data(getInstance().put('/api/users/profile-data', payload));

const deleteUserProfileData = async (slug: string) =>
  data(getInstance().delete(`/api/users/profile-data`, { data: { slug } }));

const getWishlistedProducts = async (page: number, perPage: number) =>
  data(
    getInstance().get<ProductSearchResponse>('/api/wishlist', {
      params: { page, perPage },
    })
  );

const getSearchAutoComplete = async (searchText: string) =>
  data(
    getInstance().get<SearchAutoCompleteResponse>('/api/search/autocomplete', {
      params: { q: searchText },
    })
  );

const addProductToWishlist = async (productId: string) =>
  data(getInstance().post('/api/wishlist', productId));

const removeProductFromWishlist = async (productId: string) =>
  data(getInstance().delete(`/api/wishlist?productId=${productId}`));

const registerInterest = async (payload: RegisterInterestPayload) =>
  data(getInstance().post('/api/users/variant/register-interest', payload));

const getIsValidZip = async (zip: string | number) =>
  data(getInstance().get<{ valid: boolean }>(`/api/zip`, { params: { zip } }));

const getZipCodeByCoordinates = async (lat: number, long: number) =>
  data(
    getInstance().get<{ zip: string }>('/api/zip/by-coordinates', {
      params: { lat, long },
    })
  );

export {
  addProductToWishlist,
  addToCart,
  applyReferrerCode,
  deleteCartLines,
  deleteUserProfileData,
  emailSubscription,
  expressCheckout,
  finalizeCart,
  geCurrentUser,
  getBoughtTogetherProductsForProduct,
  getCart,
  getCartDiscounts,
  getCartFreeGifts,
  getCartQuantity,
  getClickPostReturnExchangeUrl,
  getCoinsLedger,
  getCollection,
  getFragment,
  getInstance,
  getIsValidZip,
  getLook,
  getLooks,
  getMinimalOrder,
  getOrderDetails,
  getOrders,
  getPage,
  getProduct,
  getProductLooks,
  getProductProfileSchema,
  getProductRecommendations,
  getProfileData,
  getPromotionsForProductVariant,
  getRatingsForProduct,
  getRecentlyViewedProducts,
  getReviewsByTag,
  getReviewsForProduct,
  getRoot,
  getSearchAutoComplete,
  getServiceability,
  getWishlistedProducts,
  getZipCodeByCoordinates,
  overrideCartDiscounts,
  redeemReward,
  registerInterest,
  removeCartDiscounts,
  removeProductFromWishlist,
  searchCollection,
  searchCollectionProducts,
  searchConfig,
  searchProducts,
  searchReviewsForProduct,
  setPreferredPincode,
  triggerSocialEvent,
  updateCart,
  updateCartLines,
  updateCartNote,
  updateUserProfileData,
};
