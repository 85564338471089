import { useMutation } from '@tanstack/react-query';
import { AnalyticsAuthEventNames, AnalyticsAuthMode, trackAuthEvent } from '@tectonic/analytics';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import {
  verifyOtp as confirmOtp,
  requestOtp,
  validateMobileOtp,
} from '@tectonic/remix-client-network';
import { ElemasonAuthMode, ElemasonAuthStep } from '@tectonic/types';
import { delay } from '@tectonic/utils';
import { isEmpty } from 'lodash-es';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useAccessorValue } from '../../../hooks/useAccessorValue';
import { useAuthStore, useShallow } from '../../../store';
import { setupAnalyticsUser } from '../../../utils';

import type {
  ElemasonAuthOtpConfirmationFormWidget,
  PhoneNumber,
  User,
  UserMobileOtpValidatePayload,
  UserOtpRequestPayload,
  UserVerifyOtpRouteActionPayload,
  UserWithAuthTokens
} from '@tectonic/types';
import type { RefObject } from 'react';
import type { OtpConfirmationFormRef } from '../../../components';
import type { AuthStore } from '../../../store';

const requestOtpMutationFn = async (payload: UserOtpRequestPayload) => {
  const response = await requestOtp(payload);
  if (response.error) {
    throw response.error;
  }
};

const verifyOtpMutationFn = async (
  payload: UserVerifyOtpRouteActionPayload,
  authMode?: ElemasonAuthMode
): Promise<UserWithAuthTokens> => {

  trackAuthEvent(AnalyticsAuthEventNames.OTP_VERIFY_REQUEST, {
    method: payload.intent === "phone" ? AnalyticsAuthMode.PHONE_NUMBER : AnalyticsAuthMode.EMAIL_OTP,
    authMode,
    user: {
      email: payload.email,
      phone: payload.phone,
    }
  })

  const response = await confirmOtp(payload);

  if (response.error || isEmpty(response.data)) {
    trackAuthEvent(AnalyticsAuthEventNames.OTP_VERIFY_ERROR, {
      method: payload.intent === "email" ? AnalyticsAuthMode.EMAIL_OTP : AnalyticsAuthMode.PHONE_NUMBER,
      authMode,
      user: {
        email: payload.email,
        phone: payload.phone
      },
      error: response.error
    })
    throw response.error;
  } else {
    trackAuthEvent(AnalyticsAuthEventNames.OTP_VERIFY_SUCCESS, {
      method: payload.intent === "phone" ? AnalyticsAuthMode.PHONE_NUMBER : AnalyticsAuthMode.EMAIL_OTP,
      authMode,
      user: response.data?.user,
    })
    if (
      authMode === ElemasonAuthMode.SIGN_UP ||
      authMode === ElemasonAuthMode.SIGN_IN
    ) {
      trackAuthEvent((
        authMode === ElemasonAuthMode.SIGN_UP ?
          AnalyticsAuthEventNames.SIGN_UP_SUCCESS :
          AnalyticsAuthEventNames.SIGN_IN_SUCCESS
      ), {
        mode: payload.intent === "phone" ? AnalyticsAuthMode.PHONE_NUMBER : AnalyticsAuthMode.EMAIL_OTP,
        user: response.data?.user,
      });
    }
  }
  return response.data;
};

const validateMobileOtpMutationFn = async (
  payload: UserMobileOtpValidatePayload
) => {
  const response = await validateMobileOtp(payload);
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }
  return response.data;
};

const toPhoneText = (phoneNumber: PhoneNumber): string =>
  `${phoneNumber.country.stdCodes[0]}${phoneNumber.phone}`;

const toVerifyPayload = (
  code: string,
  { credentials, currentStep }: Pick<AuthStore, 'credentials' | 'currentStep'>
): UserVerifyOtpRouteActionPayload => {
  const { phoneNumber, email } = credentials;
  const phone = phoneNumber ? toPhoneText(phoneNumber) : null;
  const intent =
    currentStep === ElemasonAuthStep.PHONE_NO_OTP_CONFIRM ? 'phone' : 'email';
  return { code, email, phone, intent };
};

const authSelector = (state: AuthStore) => ({
  credentials: state.credentials,
  currentMode: state.currentMode,
  currentStep: state.currentStep,
  emailLinking: state.emailLinking,
});

const useAuthOtpConfirmationForm = (
  widget: ElemasonAuthOtpConfirmationFormWidget,
  formRef: RefObject<OtpConfirmationFormRef>
) => {
  const wData = widget.data!;
  const setStep = useAuthStore((state) => state.setStep);

  const { showToast } = useToast();
  const { errorMessages, messages } = wData;
  const { credentials, currentMode, emailLinking, currentStep } = useAuthStore(
    useShallow(authSelector)
  );
  const currentUser = useAccessorValue<User>({
    accessor: '$$globalState.currentUser',
  })!;

  const { isPending: isSendingOtp, mutateAsync: resendOtp } = useMutation({
    mutationKey: [],
    mutationFn: requestOtpMutationFn,
  });

  const { mutateAsync: verifyOtp, isPending: isVerifying } = useMutation({
    mutationKey: [],
    mutationFn:
      ({ payload, authMode }:
        {
          payload: UserVerifyOtpRouteActionPayload,
          authMode?: ElemasonAuthMode
        }) =>
        verifyOtpMutationFn(payload, authMode),
  });

  const { mutateAsync: validateEmailLinking, isPending: isLinking } =
    useMutation({
      mutationKey: [],
      mutationFn: validateMobileOtpMutationFn,
    });

  const onSubmit = async (code: string) => {
    try {
      const isLinkingRequired = !!emailLinking.required;
      const payload = toVerifyPayload(code, { credentials, currentStep });

      if (isLinkingRequired) {
        await validateEmailLinking({ code, phone: payload.phone! });
        setStep(ElemasonAuthStep.EMAIL_LINK, {
          mode: ElemasonAuthMode.EMAIL_LINK,
          emailLinking: { phoneVerificationCode: code },
        });
        showToast({ title: wData.messages.emailLinkRequired });
        return;
      }

      const response = await verifyOtp({ payload, authMode: currentMode! });

      const { user } = response;

      const isSignUp = currentMode === ElemasonAuthMode.SIGN_UP;
      const title = isSignUp ? messages.signUpSuccess : messages.signInSuccess;

      setupAnalyticsUser(user, currentUser);

      if (isEmpty(user.phone) && isSignUp) {
        setStep(ElemasonAuthStep.PROFILE_DETAILS_UPDATE, { user });
        return;
      }

      showToast({ title });
      // We want user to see the toast. For now we are using delay. In future,
      // we might use flash message or a better solution for this.
      await delay(1000);
      // Successful login. Let's reload the page.
      globalThis.location.reload();
    } catch (error) {
      Logger.error('useAuthOtpConfirmationForm:onSubmit', error);
      showToast({ title: getErrorMessage(error, errorMessages) });
    }
  };

  const isLoading = isVerifying || isSendingOtp || isLinking;

  const onResendOtp = async () => {
    const { email, phoneNumber } = credentials;
    const phone = phoneNumber ? toPhoneText(phoneNumber) : null;
    try {

      trackAuthEvent(AnalyticsAuthEventNames.OTP_RESEND_REQUEST, {
        user: {
          email,
          phone
        }
      })

      await resendOtp({ email, phone });
      showToast({ title: wData.messages.otpResendSuccess });

      trackAuthEvent(AnalyticsAuthEventNames.OTP_RESEND_SUCESS, {
        user: {
          email,
          phone
        }
      })

      formRef.current?.resetTimer();
    } catch (error) {

      trackAuthEvent(AnalyticsAuthEventNames.OTP_RESEND_ERROR, {
        user: {
          email,
          phone
        },
        error,
      })

      Logger.error('useAuthOtpConfirmationForm:onResentOtp', error);
      showToast({ title: getErrorMessage(error, errorMessages) });
    }
  };

  return { isLoading, onSubmit, onResendOtp };
};

export { useAuthOtpConfirmationForm };
