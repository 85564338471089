import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useEffect, useMemo, type FC } from 'react';
import { Button } from '../../components';
import { useActionDispatch, useSharedLocalState } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';
import { queryKeys } from '../../queryKeys';

import type { ElemasonServiceabilityV2Widget } from '@tectonic/types';

interface ElemasonServiceabilityV2WidgetProps {
  widget: ElemasonServiceabilityV2Widget;
}

const ServiceabilityV2Widget: FC<ElemasonServiceabilityV2WidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const dispatch = useActionDispatch();
  const zipcode = useHaloScript(data?.zipcode);
  const stateKey = useHaloScript(data?.stateKey);
  const variantId = useHaloScript(data?.variantId);
  const autoFetch = useHaloScript(data?.autoFetch);

  const {
    refetch,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    data: serviceability,
  } = useQuery({
    enabled: Boolean(autoFetch && variantId && zipcode),
    queryKey: queryKeys.serviceability(variantId!, zipcode!),
    queryFn: () => remixApi.getServiceability(variantId!, zipcode),
  });

  const hasError = isError || serviceability?.status === 'FAILED';

  const stateContent = useMemo(
    () => ({
      isError: hasError,
      serviceability,
      isLoading: isLoading || isFetching,
    }),
    [hasError, isLoading, isFetching, serviceability]
  );

  const { setSharedState } = useSharedLocalState(stateKey!);

  useEffect(() => {
    setSharedState(stateContent);
  }, [stateContent]);

  useEffect(() => {
    if (hasError && !isFetching) {
      actions?.onError?.forEach((action) =>
        dispatch(action, { serviceability })
      );
    }
  }, [isFetching, hasError, serviceability]);

  useEffect(() => {
    if (isSuccess && !hasError) {
      actions?.onSuccess?.forEach((action) =>
        dispatch(action, { serviceability })
      );
    }
  }, [isSuccess, hasError, serviceability]);

  const onClick = () => {
    refetch();
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return (
    <Button
      onClick={onClick}
      data={data?.button}
      config={config?.button}
      size={config?.button?.size}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      disabled={!zipcode || !variantId}
      isLoading={isLoading || isFetching}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

export { ServiceabilityV2Widget };
