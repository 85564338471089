import { Text } from '@tectonic/elemason-components';
import { useThemeContext } from '@tectonic/uikit';
import { toCurrency } from '@tectonic/utils';

import type {
  Color,
  Money as MoneyType,
  TextConfig,
  TextData,
} from '@tectonic/types';
import type { FC } from 'react';

interface MoneyProps {
  text?: TextData;
  money?: MoneyType;
  config?: TextConfig;
  placeholders?: Record<string, string>;
}

const Money: FC<MoneyProps> = ({ money, text, config, placeholders }) => {
  const { colors } = useThemeContext();

  if (!money) return null;

  // TODO: remove once fixed by backend
  try {
    return (
      <Text
        data={text}
        config={{
          ...config,
          color: colors[config?.color as Color] ?? config?.color,
        }}
        placeholders={{
          ...placeholders,
          money: toCurrency(money?.amount, money?.currencyCode),
        }}
      />
    );
  } catch (error) {
    return null;
  }
};

export { Money };
