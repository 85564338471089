import { useQuery } from '@tanstack/react-query';
import {
  AnalyticsSearchEventNames
} from '@tectonic/analytics';
import { getTrendingSearches } from '@tectonic/remix-client-network';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
  type ElemasonSearchTrendsWidget,
  type SearchNamedQuerySource,
  type SearchTrendsApiRouteLoaderResponse,
} from '@tectonic/types';
import { useActionDispatch } from '../../../hooks';
import { flattenSource } from '../../../utils/flattenSource';


const queryKey = ['trends'];
const queryFn = () => getTrendingSearches();

const useSearchTrends = (widget: ElemasonSearchTrendsWidget) => {
  const actionDispatch = useActionDispatch();

  const { data: response, isLoading } =
    useQuery<SearchTrendsApiRouteLoaderResponse>({ queryKey, queryFn });
  const trends = response?.data ? response.data : [];

  const onQueryClick = (query: SearchNamedQuerySource) => {
    const params = { rawQuery: query.slug, ...flattenSource(query.source) };

    // trackSearchEvent(
    //   AnalyticsSearchEventNames.SEARCH_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsSearchEventPayload>(
    //     {
    //       querySource: query.source,
    //     },
    //     widget
    //   )
    // );

    actionDispatch(
      {
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsSearchEventNames.SEARCH_CLICK,
          data: {
            querySource: query.source,
          },
        }
      }
    )

    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.PATH,
        slug: 'plp',
        search: params,
      },
    });
  };

  return { onQueryClick, trends, isLoading };
};

export { useSearchTrends };
