import { LocalStateKeys } from '@tectonic/types';
import { isHaloScript } from '@tectonic/utils';
import { useEffect } from 'react';
import { usePageFragment, useSharedLocalState } from '../../../../hooks';
import { useFragmentValue } from '../../../../hooks/useFragmentValue';
import { useHaloScript } from '../../../../hooks/useHaloScript';
import { useDefaultVariant } from './useDefaultVariant';
import { useSelectedAttributes } from './useSelectedAttributes';
import { useVariantAttributes } from './useVariantAttributes';

import type {
  ElemasonVariantSelectorWidget,
  HaloScript,
  Nil,
  ProductVariant,
} from '@tectonic/types';
import type { BasisAttrs } from '../types';

const STATE_KEY = LocalStateKeys.SELECTED_VARIANT;

const useVariantSelector = ({
  data,
  config,
}: ElemasonVariantSelectorWidget) => {
  const product = useHaloScript(data?.product);
  const variant = useHaloScript(data?.defaultVariant);
  const dVariant = useDefaultVariant(product?.variants ?? []);
  const attributeFragment = usePageFragment(data?.attributeFragment);
  const attributeFragmentValue = useFragmentValue(attributeFragment);
  const isHaloKey = isHaloScript(data?.stateKey);
  const hStateKey = useHaloScript(
    isHaloKey ? (data?.stateKey as HaloScript<string>) : undefined
  );

  const stateKey = (isHaloKey ? hStateKey : data?.stateKey) as Nil<string>;

  const { sharedState: currentVariant, setSharedState } = useSharedLocalState<
    ProductVariant | undefined
  >(
    stateKey ?? STATE_KEY,
    variant ?? (config?.selectDefault === false ? undefined : dVariant)
  );

  const { selectedAttributes, setSelectedAttribute } = useSelectedAttributes(
    currentVariant ??
      variant ??
      (config?.selectDefault === false ? undefined : dVariant)
  );

  const selectedVariant = product?.variants?.find((v) =>
    Object.keys(selectedAttributes).every(
      (attr) =>
        v[`${attr as BasisAttrs}Value`] ===
        selectedAttributes[attr as BasisAttrs]
    )
  );

  const variantAttributes = useVariantAttributes(
    product?.variants ?? [],
    selectedAttributes,
    currentVariant ??
      variant ??
      (config?.selectDefault === false ? undefined : dVariant)
  );

  useEffect(() => {
    if (selectedVariant && currentVariant !== selectedVariant) {
      setSharedState(selectedVariant);
    }
  }, [selectedVariant, setSharedState]);

  useEffect(() => () => setSharedState(undefined), [setSharedState]);

  return {
    product,
    selectedVariant,
    attributeFragment,
    variantAttributes,
    setSelectedAttribute,
    attributeFragmentValue,
  };
};

export { useVariantSelector };
