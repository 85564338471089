import { useMutation } from '@tanstack/react-query';
import {
  AnalyticsAuthEventNames,
  AnalyticsAuthMode,
  trackAuthEvent
} from '@tectonic/analytics';
import {
  setupAnalyticsUser,
  toPhoneText,
  useActionDispatch,
  useAuthStore,
  useElemasonContext,
  useFragmentValue,
  useInterval,
  usePageFragment,
  useShallow,
  useToast,
} from '@tectonic/elemason';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import {
  requestOtp,
  requestOtpForSignUp,
  signInWithEmailOtp,
  signInWithPhoneOtp,
} from '@tectonic/remix-client-network';
import { ElemasonAuthMode, ElemasonAuthStep } from '@tectonic/types';
import { delay } from '@tectonic/utils';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import type { AuthStore } from '@tectonic/elemason';
import type {
  UserSignInWithEmailOtpRouteActionPayload,
  UserSignInWithPhoneOtpRouteActionPayload,
} from '@tectonic/types';
import type { ElemasonAuthOtpConfirmationWidget } from './AuthOtpConfirmation.types';

const authSelector = (state: AuthStore) => ({
  credentials: state.credentials,
  currentMode: state.currentMode,
  currentStep: state.currentStep,
});

const toVerifyPhoneOtpPayload = (
  code: string,
  { credentials }: Pick<AuthStore, 'credentials'>
): UserSignInWithPhoneOtpRouteActionPayload => {
  const { phoneNumber, email } = credentials;
  return { code, email, phone: toPhoneText(phoneNumber!)! };
};

const toVerifyEmailOtpPayload = (
  code: string,
  { credentials }: Pick<AuthStore, 'credentials'>
): UserSignInWithEmailOtpRouteActionPayload => {
  const { email } = credentials;
  return { code, email: email! };
};

const getWaitingPeriod = (time: number) => Math.ceil(time / 1000);

const useOtpTimer = (widget: ElemasonAuthOtpConfirmationWidget) => {
  const wData = widget.data!;
  const { otpConfig } = wData;
  const [countdown, setCountdown] = useState(() =>
    getWaitingPeriod(otpConfig.resendOtpTime)
  );

  const { clear, reset: resetTimer } = useInterval(() => {
    setCountdown(countdown - 1);
  }, 1000);

  const reset = useCallback(() => {
    resetTimer();
    setCountdown(getWaitingPeriod(otpConfig.resendOtpTime));
  }, [resetTimer, otpConfig]);

  useEffect(() => {
    if (countdown <= 0) {
      clear();
    }
  }, [clear, countdown]);

  return { countdown, reset };
};

const useAuthOtpConfirmation = (widget: ElemasonAuthOtpConfirmationWidget) => {
  const wData = widget.data!;
  const { currentUser } = useElemasonContext();
  const { showToast } = useToast();
  const { errorMessages, messages } = wData;
  const wActions = widget.actions;
  const dispatch = useActionDispatch();

  const { credentials, currentMode, currentStep } = useAuthStore(
    useShallow(authSelector)
  );
  const { countdown, reset: resetTimer } = useOtpTimer(widget);
  const isEmailAuth = currentStep === ElemasonAuthStep.EMAIL_OTP_CONFIRM;
  const isSignUp = currentMode === ElemasonAuthMode.SIGN_UP;

  const { isPending: isSendingOtp, mutateAsync: onResendOtp } = useMutation({
    mutationKey: [],
    mutationFn: async () => {
      const { email, phoneNumber } = credentials;
      const phone = phoneNumber ? toPhoneText(phoneNumber) : null;
      try {
        const otpResponse = isSignUp
          ? await requestOtpForSignUp(phone!)
          : await requestOtp({ phone, email });
        if (otpResponse.error) {
          throw otpResponse.error;
        }
        showToast({ title: wData.messages.otpResendSuccess });
        trackAuthEvent(AnalyticsAuthEventNames.OTP_RESEND_SUCESS, {
          user: {
            email,
            phone,
          }
        });

        resetTimer();
      } catch (error) {
        trackAuthEvent(AnalyticsAuthEventNames.OTP_RESEND_ERROR, {
          user: {
            email,
            phone,
          },
          error,
        });

        Logger.error(
          '[useAuthOtpConfirmationWidget]: Unable to resent otp',
          error
        );
        showToast({ title: getErrorMessage(error, errorMessages) });
      }
    },
  });

  const { mutateAsync: onVerify, isPending: isVerifying } = useMutation({
    mutationKey: [],
    mutationFn: async (code: string) => {
      // TODO: User proper types.
      const payload: any = isEmailAuth
        ? toVerifyEmailOtpPayload(code, { credentials })
        : toVerifyPhoneOtpPayload(code, { credentials });
      try {
        trackAuthEvent(AnalyticsAuthEventNames.OTP_VERIFY_REQUEST, {
          authMode: currentMode,
          method: isEmailAuth
            ? AnalyticsAuthMode.EMAIL_OTP
            : AnalyticsAuthMode.PHONE_NUMBER,
          user: {
            email: payload.email,
            phone: payload.phone,
          }
        });

        const response = isEmailAuth
          ? await signInWithEmailOtp(
            payload as UserSignInWithEmailOtpRouteActionPayload
          )
          : await signInWithPhoneOtp(
            payload as UserSignInWithPhoneOtpRouteActionPayload
          );

        if (response.error || isEmpty(response.data)) {
          throw response.error;
        }

        const { user } = response.data;

        trackAuthEvent(AnalyticsAuthEventNames.OTP_VERIFY_SUCCESS, {
          authMode: currentMode,
          method: isEmailAuth
            ? AnalyticsAuthMode.EMAIL_OTP
            : AnalyticsAuthMode.PHONE_NUMBER,
          user,
        });

        setupAnalyticsUser(user, currentUser);
        showToast({ title: messages.verifySuccess });

        if (
          currentMode === ElemasonAuthMode.SIGN_UP ||
          currentMode === ElemasonAuthMode.SIGN_IN
        ) {
          trackAuthEvent((
            isSignUp ?
              AnalyticsAuthEventNames.SIGN_UP_SUCCESS :
              AnalyticsAuthEventNames.SIGN_IN_SUCCESS
          ), {
            mode: isEmailAuth
              ? AnalyticsAuthMode.EMAIL_OTP
              : AnalyticsAuthMode.PHONE_NUMBER,
            user,
          });
        }

        if (wActions?.onVerifySuccess) {
          wActions.onVerifySuccess.forEach((action) => dispatch(action));
        } else {
          // We want user to see the toast. For now we are using delay. In future,
          // we might use flash message or a better solution for this.
          await delay(1000);
          // Successful login. Let's reload the page.
          globalThis.location.reload();
        }
      } catch (error) {
        Logger.error(
          '[useAuthOtpConfirmationWidget]: Unable to verify otp',
          error
        );
        showToast({ title: getErrorMessage(error, errorMessages) });
        trackAuthEvent(AnalyticsAuthEventNames.OTP_VERIFY_ERROR, {
          authMode: currentMode,
          method: isEmailAuth
            ? AnalyticsAuthMode.EMAIL_OTP
            : AnalyticsAuthMode.PHONE_NUMBER,
          user: {
            email: payload.email,
            phone: payload.phone,
          },
          error,
        });
      }
    },
  });

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    isSendingOtp,
    onResendOtp,
    isVerifying,
    onVerify,
    countdown,
  });

  return { fragmentData, fragment };
};

export { useAuthOtpConfirmation };
