import { Blocks } from '../Blocks';

import type { ElemasonNavbar } from '@tectonic/types';
import type { FC } from 'react';

interface HeaderProps {
  header: ElemasonNavbar;
}

const Header: FC<HeaderProps> = ({ header: { blocks } }) => (
  <Blocks blocks={blocks} />
);

Header.displayName = 'ElemasonHeader';

export { Header };
