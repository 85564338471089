import { Logger } from '@tectonic/logger';
import { isEmpty } from 'lodash-es';
import { trackEvent } from './event';
import { removeKeysFromObject } from './helpers';

import type { AnalyticsEventPayload } from '../types';

const maskEmail = (email: string): string => {
  const saneEmailChars: string[] = []
  try {
    const emailChars = email.split('')
    const len = emailChars.indexOf('@');
    emailChars.forEach((item, pos) => ((pos >= 2 && pos <= len - 2) || (len <= 4 && pos < len)) ? saneEmailChars.push('*') : saneEmailChars.push(item))
  } catch (error) {
    Logger.error("Error in sanitizing input", error)
  }
  return saneEmailChars.join("")
}

const maskPhoneNumber = (phoneNumber: string): string => {
  let sanePhoneNumber = ""
  try {
    const phoneNumberDigits = phoneNumber.slice(-4);
    sanePhoneNumber = phoneNumberDigits.padStart(phoneNumber.length, '*');
  } catch (error) {
    Logger.error("Error in sanitizing input", error)
  }
  return sanePhoneNumber
}

const sanitizeAuthEventPayload = (payload: AnalyticsEventPayload) => {
  const sanitizedPayload = removeKeysFromObject(payload,
    ['password', 'accesstoken', 'refreshtoken', 'idtoken', 'otp', 'code', 'token', 'firstname', 'lastname', 'name', 'fullname', 'preferredzip']
  );
  const { user, email, phoneNumber, phone, ...rest } = sanitizedPayload;

  const {
    email: userEmail,
    phone: userPhone,
    phoneNumber: userPhoneNumber,
    externalUserId: userId,
    simpleId: sourceUserId
  } = user;

  if (!isEmpty(userEmail)) {
    rest.email = maskEmail(userEmail as string);
  }
  if (!isEmpty(userPhone)) {
    rest.phoneNumber = maskPhoneNumber(userPhone as string);
  }
  if (!isEmpty(userPhoneNumber)) {
    rest.phoneNumber = maskPhoneNumber(userPhoneNumber as string);
  }
  if (!isEmpty(userId)) {
    rest.userId = userId;
  }
  if (!isEmpty(sourceUserId)) {
    rest.sourceUserId = sourceUserId;
  }

  return rest;
}


const trackAuthEvent = (event: string, payload: AnalyticsEventPayload) => {
  try {
    // Drop password, tokens, codes, etc
    const sanitizedPayload = sanitizeAuthEventPayload(payload);
    trackEvent(event, sanitizedPayload);
  } catch (error) {
    Logger.error("Error in sending auth event", error)
  }
};

export { maskEmail, maskPhoneNumber, trackAuthEvent };

