import { useRef, type FC } from 'react';
import { FileInput } from '../../components';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import { useFileUpload } from './hooks';

import type { FileUploadWidgetProps } from './FileUpload.types';

const FileUploadWidget: FC<FileUploadWidgetProps> = ({ widget }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fragment, fragmentData, accept, multiple, onChange } = useFileUpload(
    widget,
    inputRef
  );

  return (
    <>
      <FileInput
        inputRef={inputRef}
        onChange={onChange}
        accept={accept}
        multiple={multiple}
      />
      <ElemasonFragmentProvider value={fragmentData}>
        {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    </>
  );
};

FileUploadWidget.displayName = 'FileUploadWidget';

export { FileUploadWidget };
