import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { ProductRatings } from '../../components';
import { useAccessorValue } from '../../hooks/useAccessorValue';
import { queryKeys } from '../../queryKeys';

import type { ElemasonProductRatingsWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonProductRatingsWidgetProps {
  widget: ElemasonProductRatingsWidget;
}

const ProductRatingsWidget: FC<ElemasonProductRatingsWidgetProps> = ({
  widget: { data, config },
}) => {
  const slug = useAccessorValue(data?.slug || {});

  const { style, className } = useStyleConfig(config?.container ?? {});

  const { data: ratingsData } = useQuery({
    enabled: !!slug,
    queryKey: queryKeys.ratingsForProduct(slug),
    queryFn: async () => remixApi.getRatingsForProduct(slug!),
  });

  const ratingsDetails = ratingsData?.stats;

  if (!slug) {
    return null;
  }

  if (
    !ratingsDetails ||
    !ratingsDetails?.default ||
    ratingsDetails.default.count === 0
  ) {
    return null;
  }

  const saneIconData: string = data?.ratingIcon || 'solid-star';
  const partialSaneIconData: string =
    data?.partialRatingIcon || 'solid-half-star';

  // TODO remove this once the api returns sane values

  const saneRatingsDetails = {
    ...ratingsDetails.default,
    countByScore: {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      ...ratingsDetails.default.countByScore,
    },
  };

  return (
    <div style={style} className={clsx('flex flex-row', className)}>
      <ProductRatings
        config={config}
        data={saneRatingsDetails}
        icon={saneIconData}
        partialIcon={partialSaneIconData}
      />
    </div>
  );
};

export { ProductRatingsWidget };
