import { AppConfig } from '@tectonic/config';
import {
  SearchFacetControlType,
  SearchFacetSortBy,
  SearchFacetSortOrder,
  SearchFacetView,
  SearchFilterAction,
  SearchOperator,
} from '@tectonic/types';
import {
  difference,
  groupBy,
  isEmpty,
  isEqual,
  isString,
  keyBy,
  mapValues,
  uniq,
} from 'lodash-es';

import type {
  SearchConfig,
  SearchFacet,
  SearchFacetResult,
  SearchFacetValue,
  SearchFilter,
  SearchFilterExpression,
  SearchFilterMultiValue,
  SearchParamsWithExpressions,
  SearchTopLineFilter,
} from '@tectonic/types';

const facetValueDefaults = {
  sortOrder: SearchFacetSortOrder.ASC,
  sortBy: SearchFacetSortBy.VALUE,
};

const isRangeControl = (facet: SearchFacet) =>
  facet.controlType === SearchFacetControlType.RANGE;

const isCheckboxControl = (facet: SearchFacet) =>
  facet.controlType === SearchFacetControlType.CHECKBOX;

const toFacetValue = (value: string, count = 0): SearchFacetValue => ({
  value,
  highlighted: value,
  count,
});

const compareFacetValue = (
  countA: SearchFacetValue,
  countB: SearchFacetValue,
  facet: SearchFacet
): number => {
  const sortBy = facet.sortBy ?? facetValueDefaults.sortBy;

  const isCustomSort = sortBy === SearchFacetSortBy.CUSTOM_ORDER;
  const customSortOrder = facet.customSortOrderValues ?? [];

  const sortOrder = facet.sortOrder ?? facetValueDefaults.sortOrder;

  // Value from count to be compare
  const valueA = isCustomSort
    ? customSortOrder.findIndex((name) => countA.value === name)
    : countA[sortBy.toLowerCase() as keyof SearchFacetValue];
  const valueB = isCustomSort
    ? customSortOrder.findIndex((name) => countB.value === name)
    : countB[sortBy.toLowerCase() as keyof SearchFacetValue];

  if (valueA === valueB) {
    return 0;
  }

  const result = valueA > valueB ? 1 : -1;
  if (isCustomSort) {
    return result;
  }

  return sortOrder === SearchFacetSortOrder.ASC ? result : -result;
};

const sanitizeSearchParams = (
  params: Partial<SearchParamsWithExpressions>
): Partial<SearchParamsWithExpressions> =>
  mapValues(params, (value, key) => {
    try {
      // JSON.parse converts the value into number it is q is a number.
      // Backend throws 422 in such cases. So we skip parsing q.
      if (key === 'q') {
        return value;
      }
      const result = isString(value) ? JSON.parse(value) : value;
      return result;
    } catch (error) {
      return value;
    }
  });

const DEFAULT_PAGE_SIZE = 20;

const defaultSearchParams = {
  q: '*',
  perPage: DEFAULT_PAGE_SIZE,
};

// We need to add implicit filters to expressions when they are not present.
const withImplicitFilters = (
  expressions: SearchFilterExpression[]
): SearchFilterExpression[] => {
  const filters = new Map(
    Object.entries(
      AppConfig.getConfig<Record<string, any>>('implicitFilters') ?? {}
    )
  );

  // Remove filters that are already present in expressions
  const filteredExpressions = expressions.filter(
    (expression) => !filters.has(expression.field)
  );

  return [...filteredExpressions, ...filters.values()];
};

const withAdditionalFilters = (
  expressions: SearchFilterExpression[],
  additionalExpressions: SearchFilterExpression[]
): SearchFilterExpression[] => {
  const filters = new Map(
    additionalExpressions.map((expression) => [expression.field, expression])
  );

  // Remove filters that are already present in expressions
  const filteredExpressions = expressions.filter(
    (expression) => !filters.has(expression.field)
  );

  return [...filteredExpressions, ...filters.values()];
};

const toProductSearchRequestPayload = (
  searchParams: Partial<SearchParamsWithExpressions>,
  excludeImplicitFilters = false
): SearchParamsWithExpressions => {
  const { filterExpressions = [], ...params } =
    sanitizeSearchParams(searchParams);
  const result = {
    ...defaultSearchParams,
    ...params,
    perPage: params.perPage ?? defaultSearchParams.perPage,
    filterExpressions: excludeImplicitFilters
      ? filterExpressions
      : withImplicitFilters(filterExpressions),
  } as SearchParamsWithExpressions;
  return result;
};

/**
 * By default backend returns only those facets that have counts. Sometimes we
 * want to display selected facets that have zero count. We only need to
 * populated these values for facets who are multi-select/checkbox. Possible cases:
 * - Entire facet that is selected is missing from counts.
 * - Only some value in the entire facet has a count.
 */
const addMissingAppliedFacets = ({
  facets,
  filters,
  searchConfig,
}: {
  facets: SearchFacetResult[];
  filters: SearchFilter[];
  searchConfig: SearchConfig;
}): SearchFacetResult[] => {
  const enhancedFacets = [...facets];

  // convert arrays to dict for quick lookup.
  const facetMap = keyBy<SearchFacetResult>(enhancedFacets, 'fieldName');
  const configMap = keyBy<SearchFacet>(
    searchConfig.config.facetConfig.options,
    'name'
  );

  for (const filter of filters) {
    const { field } = filter;
    const config = configMap[field];
    if (!config || !isCheckboxControl(config)) {
      continue;
    }
    const facet = facetMap[field];
    // Value may or may not be an array. It depends on the SearchOperator. Since
    // checkbox controls are always array, we can covert value to the array
    const filterValues = [filter.value].flat() as SearchFilterMultiValue;

    if (isEmpty(facet?.values)) {
      // Looks like entire facet is missing.
      const values = filterValues.map((value) => toFacetValue(value));
      enhancedFacets.push({ ...facet, fieldName: field, values });
      continue;
    }

    for (const filterValue of filterValues) {
      const facetValue = facet.values.find(
        (item) => item.value === filterValue
      );
      if (facetValue) {
        // facet has value for applied filter value.
        continue;
      }
      facet.values = [...facet.values, toFacetValue(filterValue)];
    }
  }

  return enhancedFacets;
};

// We need to sort facet values based on the config.
const toSortedFacets = (
  searchConfig: SearchConfig,
  facets: SearchFacetResult[]
): SearchFacetResult[] => {
  const configMap = keyBy<SearchFacet>(
    searchConfig.config.facetConfig.options,
    'name'
  );

  // sort facet values based on the config.
  return facets.map((facet) => {
    const fConfig = configMap[facet.fieldName];
    if (!fConfig) {
      return facet;
    }
    const values: SearchFacetValue[] = [...facet.values];
    values.sort((itemA, itemB) => compareFacetValue(itemA, itemB, fConfig));
    return { ...facet, values };
  });
};

/**
 * Sometimes we need to display facets based on the facet view config. eg. in case
 * facet view config fixed, we don't care about other filters.
 * When we return the facets, we also need to display the facets that are already
 * selected/applied regardless of the value returned from typesense. When facet
 * count is zero typesense doesn't return facets because of that we have to
 * manually add those facets.
 */
const getRelevantFacets = (
  facets: SearchFacetResult[],
  filters: SearchFilter[],
  searchConfig: SearchConfig
): SearchFacetResult[] => {
  const configs = searchConfig.config.facetConfig.options;

  // Presently, we only handle facet views.
  const fixedFacets = configs
    .filter((facet) => facet.view === SearchFacetView.FIXED)
    .map((facet) => facet.name as string);
  const fixedFacetSet = new Set(fixedFacets);

  if (isEmpty(fixedFacetSet)) {
    return facets;
  }

  const configsMap = keyBy<SearchFacet>(configs, 'name');
  const filtersMap = keyBy<SearchFilter>(filters, 'field');

  const nFacets: SearchFacetResult[] = [];

  for (const facet of facets) {
    const key = facet.fieldName;
    const filter = filtersMap[key];
    const config = configsMap[key];

    const useAsIs =
      !config ||
      !isCheckboxControl(config) ||
      !fixedFacetSet.has(key) ||
      !filter;

    if (useAsIs) {
      nFacets.push(facet);
      continue;
    }

    // Value may or may not be an array. It depends on the SearchOperator. Since
    // checkbox controls are always array, we can covert value to the array
    const vArray = [filter.value].flat() as SearchFilterMultiValue;
    // pick only those counts that part of the filter.
    const fSet = new Set(vArray);
    const nValues = facet.values.filter((item) => fSet.has(item.value));
    nFacets.push({ ...facet, values: nValues });
  }

  return nFacets;
};

const toTopLineFiltersWithAction = (
  topLineFilters: SearchTopLineFilter[],
  appliedFilters: SearchFilter[]
) => {
  // A top line filter can be in two state applied or not applied. We need to
  // add appropriate action to the top line filter.

  const auxFilters = groupBy(appliedFilters, 'field');

  const result: SearchTopLineFilter[] = [];
  for (const topLineFilter of topLineFilters) {
    const aFilterGroup = auxFilters[topLineFilter.field];
    if (!aFilterGroup) {
      result.push({ ...topLineFilter, action: SearchFilterAction.APPLY });
      continue;
    }

    const aFilter = aFilterGroup.find(
      (filter) => filter.operator === topLineFilter.operator
    );

    if (!aFilter) {
      result.push({ ...topLineFilter, action: SearchFilterAction.APPLY });
      continue;
    }
    const isApplied =
      topLineFilter.operator === SearchOperator.IN
        ? !difference(
            topLineFilter.value as SearchFilterMultiValue,
            aFilter.value as SearchFilterMultiValue
          ).length
        : isEqual(aFilter.value, topLineFilter.value);

    result.push({
      ...topLineFilter,
      action: isApplied ? SearchFilterAction.DROP : SearchFilterAction.APPLY,
    });
  }

  return result;
};

const mergeTopLineFilterToFilterExpressions = (
  topLineFilter: SearchTopLineFilter,
  appliedFilters: SearchFilterExpression[]
): SearchFilterExpression[] => {
  const result: SearchFilterExpression[] = [];

  let isMerged = false;

  // TODO: modify the logic when topLineFilter kind is key.
  const tExpression: SearchFilterExpression = {
    field: topLineFilter.field,
    operator: topLineFilter.operator,
    value: topLineFilter.value,
    action: topLineFilter.action,
  };

  for (const aFilter of appliedFilters) {
    const isMatchingFilter =
      aFilter.field === topLineFilter.field &&
      aFilter.operator === topLineFilter.operator;
    if (isMerged || !isMatchingFilter) {
      result.push(aFilter);
      continue;
    }

    if (aFilter.operator === SearchOperator.IN) {
      const { action } = topLineFilter;
      const tValue = topLineFilter.value as SearchFilterMultiValue;
      const aValue = aFilter.value as SearchFilterMultiValue;
      const value =
        action === SearchFilterAction.APPLY
          ? uniq([...tValue, ...aValue])
          : difference(aValue, tValue);
      result.push({
        ...aFilter,
        value,
        action: !value.length ? action : undefined,
      });
    } else {
      result.push(tExpression);
    }

    isMerged = true;
  }

  if (!isMerged) {
    result.push(tExpression);
  }

  return result;
};

export {
  addMissingAppliedFacets,
  getRelevantFacets,
  isCheckboxControl,
  isRangeControl,
  mergeTopLineFilterToFilterExpressions,
  sanitizeSearchParams,
  toProductSearchRequestPayload,
  toSortedFacets,
  toTopLineFiltersWithAction,
  withAdditionalFilters,
};
