import { isEmpty } from "lodash-es";
import { AnalyticsCartEventNames, AnalyticsProductEventNames } from "../constants";
import { setCookie } from "../helpers";


const sendAffiliateEvent = (event: string, payload: Record<string, any>) => {

  try {
    const { affiliateGTMDataLayer } = globalThis;

    if (!affiliateGTMDataLayer) {
      // console.warn("sendAffiliateEventNoOp")
      return;
    }

    const {
      data,
      ttUserEmailSHA256,
      ttUserEmailMD5,
      ttSourceUserId,
      ttUserId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      $current_url_params
    } = payload

    setCookie('__tt_affiliate_visitor_id', ttUserId, 180)

    let finalPayload = {}

    if (event === AnalyticsProductEventNames.PRODUCT_VIEW) {
      const { item, productId, currency } = data;
      const { productId: itemProductId, currency: itemCurrency } = item ?? {};
      const finalProductId = productId ?? itemProductId;
      const finalCurrency = currency ?? itemCurrency;
      finalPayload = {
        'event': 'view_item',
        'ecommerce': {
          'items': [
            {
              'item_id': finalProductId,
            }
          ],
          'currency': finalCurrency
        }
      };
    } else if (event === AnalyticsCartEventNames.PRODUCT_ADD_SUCCESS) {
      const { items, currency } = data;

      if (isEmpty(items)) {
        return
      }

      const { productId, quantity, sellingPrice: initSellingPrice } = items[0];
      let finalSellingPrice = initSellingPrice
      if (typeof initSellingPrice === 'object' && !Array.isArray(initSellingPrice) && initSellingPrice !== null) {
        // @ts-ignore
        finalSellingPrice = initSellingPrice.amount
      }

      finalPayload = {
        'event': 'add_to_cart',
        'ecommerce': {
          'items': [
            {
              'item_id': productId,
              'price': finalSellingPrice,
              'quantity': quantity,
            }
          ],
          'currency': currency
        }
      };
    } else if (event === AnalyticsCartEventNames.CART_VIEW) {
      const { items, currency } = data;

      if (isEmpty(items)) {
        return
      }

      const itemsArray = items.map((item: Record<string, unknown>) => {
        const { productId, quantity, sellingPrice: initSellingPrice } = item;

        let finalSellingPrice = initSellingPrice
        if (typeof initSellingPrice === 'object' && !Array.isArray(initSellingPrice) && initSellingPrice !== null) {
          // @ts-ignore
          finalSellingPrice = initSellingPrice.amount
        }

        return {
          'item_id': productId,
          'price': finalSellingPrice,
          'quantity': quantity
        }
      })

      finalPayload = {
        'event': 'view_cart',
        'ecommerce': {
          'items': itemsArray,
          'currency': currency
        }
      };
    } else if (event === AnalyticsProductEventNames.PRODUCT_IMPRESSION) {

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { items } = data;
      const { q, rawQuery } = $current_url_params ?? {}

      if (isEmpty(items)) {
        return
      }

      const { productId, sellingPrice, currency } = items[0];
      const keywords = q ?? rawQuery

      finalPayload = {
        'event': 'view_item_list',
        'ecommerce': {
          'items': [{
            'item_id': productId,
            'price': sellingPrice
          }],
          'currency': currency
        }
      };

      if (!isEmpty(keywords)) {
        // @ts-ignore
        finalPayload.keywords = keywords
      }
    }

    if (isEmpty(finalPayload)) {
      return
    }


    if (!isEmpty(ttUserEmailSHA256)) {
      finalPayload = { ...finalPayload, 'user_email_sha256': ttUserEmailSHA256 }
    }

    if (!isEmpty(ttUserEmailMD5)) {
      finalPayload = { ...finalPayload, 'user_email_md5': ttUserEmailMD5 }
    }

    if (!isEmpty(ttSourceUserId)) {
      finalPayload = { ...finalPayload, 'customer_id': ttSourceUserId }
    }

    // console.log("sendAffiliateEventPayload", finalPayload)

    affiliateGTMDataLayer.push(finalPayload);

    // console.log("sendAffiliateEventFinalDataLayer", affiliateGTMDataLayer)
  } catch (error) {
    // console.error(error)
    // TODO Log these errors in future in sentry or something
  }
};

export { sendAffiliateEvent };
