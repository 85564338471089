import { useInfiniteQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfigV2 } from '@tectonic/elemason-components';
import {
  Clickable,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  Carousel as UiCarousel,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { take } from 'lodash-es';
import { useCallback, useMemo, useState, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button, ContentShimmer } from '../../components';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { queryKeys } from '../../queryKeys';
import { CarouselSlide } from '../CarouselBanner/CarouselSlide';

import type {
  ElemasonLookBookWidget,
  Look,
  Nil,
  PaginatedResponse,
  SearchResponse,
} from '@tectonic/types';

interface ElemasonLookBookWidgetProps {
  widget: ElemasonLookBookWidget;
}

const PER_PAGE = 10;

const getNextPageParam = (response: SearchResponse<unknown>): Nil<number> => {
  const { page, found } = response;
  const count = page * PER_PAGE;
  return count < found ? page + 1 : undefined;
};

const LookBookWidget: FC<ElemasonLookBookWidgetProps> = ({
  widget: { config, data },
}) => {
  const { query } = data ?? {};
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lookBookOpen, setLookBookOpen] = useState(false);
  const [style, className] = useStyleConfigV2(config?.container);
  const carouselFragment = usePageFragment(data?.carouselFragment);
  const carouselFragmentValue = useFragmentValue(carouselFragment);
  const [closeButtonStyle, closeButtonClassName] = useStyleConfigV2(
    config?.carouselCloseButton
  );

  const [cStyle, cClassName] = useStyleConfigV2(
    config?.carousel?.dimension ?? {}
  );
  const [paginationStyle, paginationClassName] = useStyleConfigV2(
    config?.carousel?.pagination ?? {}
  );

  const params = {
    ...query,
    page: 1,
    perPage: PER_PAGE,
  };

  const {
    isFetching,
    isFetchingNextPage,
    data: lookBookPages,
    hasNextPage: hasMore,
    fetchNextPage: onLoadMore,
  } = useInfiniteQuery<PaginatedResponse<Look>>({
    getNextPageParam,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    // TODO: update query if there will be multiple instances
    queryKey: queryKeys.looks(params),
    queryFn: ({ pageParam }) => {
      const payload = {
        ...params,
        perPage: PER_PAGE,
        page: (pageParam ?? 1) as number,
      };
      return remixApi.getLooks(payload);
    },
    initialPageParam: 1,
  });

  const handleOnClick = (index: number) => {
    setLookBookOpen(true);
    setSelectedIndex(index);
  };

  const onClose = () => {
    setLookBookOpen(false);
  };

  const looks = useMemo(
    () => lookBookPages?.pages.flatMap((page) => page?.hits),
    [lookBookPages?.pages]
  );

  const isLoading = isFetching || isFetchingNextPage;

  const handleInViewChange = useCallback(
    (inView: boolean) => inView && hasMore && onLoadMore(),
    [hasMore, onLoadMore]
  );

  const [inViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: handleInViewChange,
  });

  if (!looks || looks.length === 0) {
    return null;
  }

  return (
    <>
      <div style={style} className={clsx('flex', className)}>
        {looks.map((look, index) => (
          <Clickable key={look.id} onClick={() => handleOnClick(index)}>
            <ElemasonFragmentProvider value={fragmentValue({ look, index })}>
              {fragment?.cells.map((cell) => (
                <Cell key={cell.id} cell={cell} />
              ))}
            </ElemasonFragmentProvider>
          </Clickable>
        ))}
        {hasMore && isLoading && (
          <>
            {take(looks, 2).map((look, index) => (
              <ContentShimmer key={look.id}>
                <Clickable>
                  <ElemasonFragmentProvider
                    value={fragmentValue({ look, index })}
                  >
                    {fragment?.cells.map((cell) => (
                      <Cell key={cell.id} cell={cell} />
                    ))}
                  </ElemasonFragmentProvider>
                </Clickable>
              </ContentShimmer>
            ))}
          </>
        )}
        {hasMore && !isLoading && <div ref={inViewRef} className="flex" />}
      </div>
      {lookBookOpen && carouselFragment && (
        <DialogRoot open onOpenChange={onClose}>
          <DialogPortal>
            <DialogOverlay className="fixed inset-0 z-10 bg-black/70" />
            <DialogContent className="fixed inset-0 z-10 h-full w-full">
              <UiCarousel
                style={cStyle}
                inViewThreshold={1}
                startIndex={selectedIndex}
                axis={config?.carousel?.axis}
                loop={config?.carousel?.loop}
                className={clsx('relative h-full w-full', cClassName)}
                autoplay={{
                  enable: config?.carousel?.autoPlay,
                  delay: config?.carousel?.autoPlayInterval,
                }}
              >
                <UiCarousel.Slides>
                  <>
                    {(looks ?? [])?.map((item, index) => (
                      <CarouselSlide
                        key={index}
                        config={config?.carousel?.slide}
                        className={clsx('relative h-fit w-full', cClassName)}
                      >
                        <ElemasonFragmentProvider
                          value={carouselFragmentValue(item)}
                        >
                          {carouselFragment?.cells.map((cell) => (
                            <Cell key={cell.id} cell={cell} />
                          ))}
                        </ElemasonFragmentProvider>
                      </CarouselSlide>
                    ))}
                    {hasMore && isLoading && (
                      <>
                        {take(looks, 1).map((item, index) => (
                          <CarouselSlide
                            key={index}
                            config={config?.carousel?.slide}
                            className={clsx(
                              'relative h-full w-full',
                              cClassName
                            )}
                          >
                            <ContentShimmer key={item.id}>
                              <ElemasonFragmentProvider
                                value={carouselFragmentValue(item)}
                              >
                                {carouselFragment?.cells.map((cell) => (
                                  <Cell key={cell.id} cell={cell} />
                                ))}
                              </ElemasonFragmentProvider>
                            </ContentShimmer>
                          </CarouselSlide>
                        ))}
                      </>
                    )}
                    {hasMore && !isLoading && (
                      <div ref={inViewRef} className="flex" />
                    )}
                  </>
                </UiCarousel.Slides>
                <UiCarousel.Dots
                  style={paginationStyle}
                  className={clsx(
                    'flex',
                    paginationClassName,
                    config?.carousel?.pagination?.overlay ? 'absolute' : ''
                  )}
                >
                  <UiCarousel.Dot
                    className="transition-all"
                    // @ts-expect-error TODO: fix types
                    getStyles={({ isActive }: { isActive: boolean }) =>
                      isActive
                        ? config?.carousel?.pagination?.activeDot
                        : config?.carousel?.pagination?.inactiveDot
                    }
                  />
                </UiCarousel.Dots>
              </UiCarousel>
              <div style={closeButtonStyle} className={closeButtonClassName}>
                <Button
                  onClick={onClose}
                  data={data?.carouselCloseButton}
                  config={config?.carouselCloseButton?.button}
                />
              </div>
            </DialogContent>
          </DialogPortal>
        </DialogRoot>
      )}
    </>
  );
};

export { LookBookWidget };
