import axios from 'axios';

import type {
  AddressCreatePayload,
  AddressUpdatePayload,
  OrderReturnsUrlLoaderRouteResponse,
  PaginationOptions,
  RemixRouteResponse,
  SearchHistoryApiRouteLoaderResponse,
  UserAddressesActionRouteResponse,
  UserAddressesRouteLoaderResponse,
  UserAddressesV2RouteLoaderResponse,
  UserEligibleForDeleteApiRouteLoaderResponse,
  UserEmailLinkingVerifyPayload,
  UserEmailPasswordRecoveryPayload,
  UserEmailSignInWithEmailPasswordRouteActionResponse,
  UserExistsApiRouteLoaderResponse,
  UserExistsPayload,
  UserFileUploadActionRouteResponse,
  UserMobileOtpValidatePayload,
  UserOtpRequestPayload,
  UserProfileSchemaApiRouteLoaderResponse,
  UserProfilesApiRouteLoaderResponse,
  UserRecoverEmailPasswordRouteActionResponse,
  UserRequestOtpForSignUpRouteActionResponse,
  UserRequestOtpRouteActionResponse,
  UserSearchHistoryCreatePayload,
  UserSignInWithEmailOtpRouteActionPayload,
  UserSignInWithEmailOtpRouteActionResponse,
  UserSignInWithEmailPasswordPayload,
  UserSignInWithIdTokenPayload,
  UserSignInWithIdTokenRouteActionResponse,
  UserSignInWithPhoneOtpRouteActionPayload,
  UserSignInWithPhoneOtpRouteActionResponse,
  UserSignUpWithEmailPasswordPayload,
  UserUpdateOtpRequestPayload,
  UserUpdateRequestPayload,
  UserValidateMobileOtpRouteActionResponse,
  UserVerifyEmailLinkingRouteActionResponse,
  UserVerifyOtpRouteActionPayload,
  UserVerifyOtpRouteActionResponse
} from '@tectonic/types';

const getSearchHistory =
  async (): Promise<SearchHistoryApiRouteLoaderResponse> => {
    const response = await axios.get(`/api/search/history`);
    return response.data;
  };

const createSearchHistory = async (payload: UserSearchHistoryCreatePayload) => {
  const response = await axios.post(`/api/search/history`, payload);
  return response.data;
};

const deleteSearchHistory = async (id?: string) => {
  await axios.delete(`/api/search/history`, {
    params: { id },
  });
};

const checkUserExists = async (
  params?: UserExistsPayload
): Promise<UserExistsApiRouteLoaderResponse> => {
  const response = await axios.get(`/api/users/exists`, { params });
  return response.data;
};

const requestOtp = async (
  payload: UserOtpRequestPayload
): Promise<UserRequestOtpRouteActionResponse> => {
  const response = await axios.post(`/api/users/request-otp`, payload);
  return response.data;
};

const requestOtpForSignUp = async (
  phone: string
): Promise<UserRequestOtpForSignUpRouteActionResponse> => {
  const response = await axios.post(`/api/users/request-otp-for-sign-up`, {
    phone,
  });
  return response.data;
};

const verifyOtp = async (
  payload: UserVerifyOtpRouteActionPayload
): Promise<UserVerifyOtpRouteActionResponse> => {
  const response = await axios.post(`/api/users/verify-otp`, payload);
  return response.data;
};

const validateMobileOtp = async (
  payload: UserMobileOtpValidatePayload
): Promise<UserValidateMobileOtpRouteActionResponse> => {
  const response = await axios.post(`/api/users/validate-mobile-otp`, payload);
  return response.data;
};

const validateMobileOtpForPotentialLink = async (
  payload: UserMobileOtpValidatePayload
): Promise<RemixRouteResponse> => {
  const response = await axios.post(
    `/api/users/validate-phone-for-potential-link`,
    payload
  );
  return response.data;
};

const signInWithPhoneOtp = async (
  payload: UserSignInWithPhoneOtpRouteActionPayload
): Promise<UserSignInWithPhoneOtpRouteActionResponse> => {
  const response = await axios.post(
    `/api/users/sign-in-with-phone-otp`,
    payload
  );
  return response.data;
};

const signInWithEmailOtp = async (
  payload: UserSignInWithEmailOtpRouteActionPayload
): Promise<UserSignInWithEmailOtpRouteActionResponse> => {
  const response = await axios.post(
    `/api/users/sign-in-with-email-otp`,
    payload
  );
  return response.data;
};

const signInWithEmailPassword = async (
  payload: UserSignInWithEmailPasswordPayload
): Promise<UserEmailSignInWithEmailPasswordRouteActionResponse> => {
  const response = await axios.post(
    `/api/users/sign-in-with-email-password`,
    payload
  );
  return response.data;
};

const signInWithIdToken = async (
  payload: UserSignInWithIdTokenPayload
): Promise<UserSignInWithIdTokenRouteActionResponse> => {
  const response = await axios.post(
    `/api/users/sign-in-with-id-token`,
    payload
  );
  return response.data;
};

const signUpWithEmailPassword = async (
  payload: UserSignUpWithEmailPasswordPayload
): Promise<UserEmailSignInWithEmailPasswordRouteActionResponse> => {
  const response = await axios.post(
    `/api/users/sign-up-with-email-password`,
    payload
  );
  return response.data;
};

const recoverEmailPassword = async (
  payload: UserEmailPasswordRecoveryPayload
): Promise<UserRecoverEmailPasswordRouteActionResponse> => {
  const response = await axios.post(
    `/api/users/recover-email-password`,
    payload
  );
  return response.data;
};

const requestOtpForUserUpdate = async (
  payload: UserUpdateOtpRequestPayload
): Promise<RemixRouteResponse> => {
  const response = await axios.post(
    `/api/users/request-otp-for-user-update`,
    payload
  );
  return response.data;
};

const updateUser = async (
  payload: Partial<UserUpdateRequestPayload>
): Promise<RemixRouteResponse> => {
  const response = await axios.patch(`/api/users`, payload);
  return response.data;
};

const verifyEmailLinking = async (
  payload: UserEmailLinkingVerifyPayload
): Promise<UserVerifyEmailLinkingRouteActionResponse> => {
  const response = await axios.post(`/api/users/verify-email-linking`, payload);
  return response.data;
};

// TODO: Pagination if there are so many addresses
const getAddresses = async (): Promise<UserAddressesRouteLoaderResponse> => {
  const response = await axios.get(`/api/users/addresses`);
  return response.data;
};

const getAddressesV2 =
  async (): Promise<UserAddressesV2RouteLoaderResponse> => {
    const response = await axios.get(`/api/v2/user/address`);
    return response.data;
  };

const createAddress = async (
  payload: AddressCreatePayload
): Promise<UserAddressesActionRouteResponse> => {
  const response = await axios.post(`/api/users/addresses`, payload);
  return response.data;
};

const createAddressV2 = async (payload: AddressCreatePayload) => {
  const response = await axios.post(`/api/v2/user/address`, payload);
  return response.data;
};

const updateAddress = async (
  payload: AddressUpdatePayload
): Promise<UserAddressesActionRouteResponse> => {
  const response = await axios.patch(`/api/users/addresses`, payload);
  return response.data;
};

const updateAddressV2 = async (
  payload: AddressUpdatePayload
): Promise<UserAddressesActionRouteResponse> => {
  const response = await axios.patch(`/api/v2/user/address`, payload);
  return response.data;
};

const deleteAddress = async (
  addressId: string
): Promise<UserAddressesActionRouteResponse> => {
  const response = await axios.delete(`/api/users/addresses`, {
    data: { addressId },
  });
  return response.data;
};

const deleteAddressV2 = async (
  addressId: string
): Promise<UserAddressesActionRouteResponse> => {
  const response = await axios.delete(`/api/v2/user/address`, {
    data: { addressId },
  });
  return response.data;
};

const getProfileSchema =
  async (): Promise<UserProfileSchemaApiRouteLoaderResponse> => {
    const response = await axios.get(`/api/users/profile/schema`);
    return response.data;
  };

const getProfiles = async (
  params?: PaginationOptions
): Promise<UserProfilesApiRouteLoaderResponse> => {
  const response = await axios.get(`/api/users/profiles`, { params });
  return response.data;
};

const isEligibleForDelete =
  async (): Promise<UserEligibleForDeleteApiRouteLoaderResponse> => {
    const response = await axios.get(`/api/users/eligible-for-delete`);
    return response.data;
  };

const markUserForDelete = async (): Promise<RemixRouteResponse> => {
  const response = await axios.post(`/api/users/mark-for-delete`);
  return response.data;
};

const getOrderReturnsUrl = async (
  orderId: string,
  variantId: string
): Promise<OrderReturnsUrlLoaderRouteResponse> => {
  const response = await axios.get(
    `/api/users/orders/${orderId}/${variantId}/post-order/returns/url`
  );
  return response.data;
};

const uploadFile = async (
  formData: FormData,
  params?: { usecase?: 'REVIEW' }
): Promise<UserFileUploadActionRouteResponse> => {
  const response = await axios.post(`/api/users/file/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params,
  });
  return response.data;
};

export {
  checkUserExists,
  createAddress,
  createAddressV2,
  createSearchHistory,
  deleteAddress,
  deleteAddressV2,
  deleteSearchHistory,
  getAddresses,
  getAddressesV2,
  getOrderReturnsUrl,
  getProfileSchema,
  getProfiles,
  getSearchHistory,
  isEligibleForDelete,
  markUserForDelete,
  recoverEmailPassword,
  requestOtp,
  requestOtpForSignUp,
  requestOtpForUserUpdate,
  signInWithEmailOtp,
  signInWithEmailPassword,
  signInWithIdToken,
  signInWithPhoneOtp,
  signUpWithEmailPassword,
  updateAddress,
  updateAddressV2,
  updateUser,
  uploadFile,
  validateMobileOtp,
  validateMobileOtpForPotentialLink,
  verifyEmailLinking,
  verifyOtp
};
