import mixpanel from 'mixpanel-browser';

import type { RequestOptions } from 'mixpanel-browser';
import type { AnalyticsEventNames } from '../constants';
import type { AnalyticsEventPayload, MixpanelConfig } from '../types';

const initializeMixpanel = (mpConfig: MixpanelConfig) => {
  mixpanel.init(mpConfig.token, mpConfig.options);
};

const trackPageView = (props: AnalyticsEventPayload) => {
  mixpanel.track_pageview(props);
};

const timeEvent = (event: AnalyticsEventNames) => {
  mixpanel.time_event(event);
};

const track = (
  event: string,
  props: AnalyticsEventPayload,
  options?: RequestOptions
) => {
  try {
    mixpanel.track(event, props, options);
  } catch (error) {
    console.error('[mixpanelTrackError]', error);
  }
};

// const isDebugModeEnabled = () => mixpanel.get_config("debug");

const getDistinctId = () => mixpanel.get_distinct_id();

const getProperty = (key: string) => mixpanel.get_property(key);

const getProperties = () => mixpanel.get_properties();

const identifyUser = (userId: string) => {
  mixpanel.identify(userId);
};

const setPeopleProperties = (props: AnalyticsEventPayload) => {
  mixpanel.people.set(props);
};

const resetUser = () => {
  mixpanel.reset();
};

const setSuperProperties = (props: AnalyticsEventPayload) => {
  mixpanel.register(props);
};

const removeSuperProperty = (key: string) => {
  mixpanel.unregister(key);
};

const setUserAlias = (alias: string, original: string) => {
  mixpanel.alias(alias, original);
};

export {
  getDistinctId,
  getProperties,
  getProperty,
  identifyUser,
  initializeMixpanel,
  removeSuperProperty,
  resetUser,
  setPeopleProperties,
  setSuperProperties,
  setUserAlias,
  timeEvent,
  track,
  trackPageView,
};
