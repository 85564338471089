import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { getVariantType } from '../../utils';
import { ImageAsset } from '../Asset';
import { Button } from '../Button';
import { Icon } from '../Icon';

import type {
  CartBundleAccordionConfig,
  CartLineProductVariant,
  ProductComponent,
  ProductVariant,
} from '@tectonic/types';
import type { FC } from 'react';

interface CartBundleInfoProps {
  onEditBundle: () => void;
  variant: CartLineProductVariant;
  config?: CartBundleAccordionConfig;
}

const getVariantTypes = (
  variant?: ProductVariant
): {
  attr: 'basisAttr1' | 'basisAttr2' | 'basisAttr3';
  value: 'color' | 'size' | undefined;
}[] => {
  if (!variant) return [];

  const { basisAttr1Name, basisAttr2Name, basisAttr3Name } = variant;

  return [
    { attr: 'basisAttr1', value: getVariantType(basisAttr1Name) },
    { attr: 'basisAttr2', value: getVariantType(basisAttr2Name) },
    { attr: 'basisAttr3', value: getVariantType(basisAttr3Name) },
  ];
};

const getVariant = (
  productVariantComponents: ProductComponent[],
  productVariants: ProductVariant[]
) => {
  const ids = productVariantComponents.map((c) => c?.id);
  return productVariants.find((v) => ids.includes(v.id));
};

const CartBundleInfo: FC<CartBundleInfoProps> = ({
  variant,
  config,
  onEditBundle,
}) => {
  const [style, className] = useStyleConfigV2(config?.container);
  const [itemStyle, itemClassName] = useStyleConfigV2(config?.item);
  const [imageStyle, imageClassName] = useStyleConfigV2(config?.asset);
  const [contentStyle, contentClassName] = useStyleConfigV2(config?.content);
  const [itemDetailsStyle, itemDetailsClassName] = useStyleConfigV2(
    config?.itemDetails
  );

  return (
    <AccordionRoot type="multiple" style={style} className={className}>
      <AccordionItem value="1" className="gap-3">
        <AccordionHeader asChild>
          <AccordionTrigger asChild className="flex items-center">
            <div>
              <Text
                config={config?.title}
                data={`${variant.productVariantComponents?.length} Products in combo`}
              />
              <Icon
                className="ml-auto"
                config={{ size: 'sm' }}
                symbol="outline-chevron-down"
              />
            </div>
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent
          style={contentStyle}
          className={clsx('flex flex-col', contentClassName)}
        >
          {variant.product.productComponents?.map((c) => {
            const v = getVariant(
              variant.productVariantComponents ?? [],
              c.variants
            );

            const variantTypes = getVariantTypes(v);

            return (
              <div
                key={c.id}
                style={itemStyle}
                className={clsx('flex', itemClassName)}
              >
                <ImageAsset
                  style={imageStyle}
                  config={config?.asset}
                  className={imageClassName}
                  asset={c.assetMap.gallery[0]}
                />
                <div
                  style={itemDetailsStyle}
                  className={clsx('flex flex-col', itemDetailsClassName)}
                >
                  <Text data={c.title} config={config?.itemDetails?.title} />
                  <Text
                    config={config?.itemDetails?.variantInfo}
                    data={variantTypes
                      .filter((vt) => ['size', 'color'].includes(vt.value!))
                      .map((vt) => v?.[`${vt.attr}Display`])
                      .filter(Boolean)
                      .join(', ')}
                  />
                </div>
              </div>
            );
          })}
          <Button
            onClick={onEditBundle}
            config={config?.editButton}
            data={{ text: 'Edit combo' }}
          />
        </AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  );
};

export { CartBundleInfo };
