import { useStyleConfig } from '@tectonic/elemason-components';
import { LocalStateKeys } from '@tectonic/types';
import clsx from 'clsx';
import { useEffect, useMemo, type FC } from 'react';
import { ProductVariantSelector } from '../../../components/Product/ProductVariantSelector';
import { useSharedLocalState } from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { isDefaultVariant, isOutOfStock } from '../../../utils';

import type {
  ElemasonProductVariantSelectorWidget,
  ProductVariant,
} from '@tectonic/types';

const STATE_KEY = LocalStateKeys.SELECTED_VARIANT;

const useDefaultVariant = (variants: ProductVariant[]) =>
  useMemo(() => {
    // select first in-stock variant.
    const variant =
      variants?.find((v) => !isOutOfStock(v.stockStatus)) ?? variants[0];
    return variant;
  }, [variants]);

interface ElemasonProductVariantSelectorWidgetProps {
  widget: ElemasonProductVariantSelectorWidget;
}

const ProductVariantSelectorWidget: FC<
  ElemasonProductVariantSelectorWidgetProps
> = ({ widget: { config, data } }) => {
  const product = useHaloScript(data?.product);
  const variant = useHaloScript(data?.defaultVariant);
  const dVariant = useDefaultVariant(product?.variants ?? []);

  const updater = useHaloScript(data?.updater);

  const { sharedState: selectedVariant, setSharedState } =
    useSharedLocalState<ProductVariant>(
      data?.stateKey ?? STATE_KEY,
      variant ?? dVariant
    );

  const onVariantSelect = (nVariant?: ProductVariant) => {
    setSharedState(nVariant);
    if (nVariant && !isOutOfStock(nVariant.stockStatus)) updater?.(nVariant);
  };

  const { style: contStyle, className: contClassName } = useStyleConfig(
    config?.container ?? {}
  );

  useEffect(() => () => setSharedState(undefined), [setSharedState]);

  if (!product || !selectedVariant) return null;
  if (isDefaultVariant(selectedVariant) && !product.hasVariantsWithComponents)
    return null;

  return (
    <div className={clsx('flex flex-col', contClassName)} style={contStyle}>
      <ProductVariantSelector
        config={config}
        product={product}
        onSelect={onVariantSelect}
        selectedVariant={selectedVariant}
      />
    </div>
  );
};

export { ProductVariantSelectorWidget };
