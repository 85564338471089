import { percentage } from '../math';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

// https://javascript.info/size-and-scroll-window#width-height-of-the-document
const getScrollHeight = (): number =>
  Math.max(
    document.body?.scrollHeight,
    document.documentElement.scrollHeight,
    document.body?.offsetHeight,
    document.documentElement.offsetHeight,
    document.body?.clientHeight,
    document.documentElement.clientHeight
  );

const isWindowEndReached = (scrollY: number, threshold = 30): boolean => {
  const scrollHeight = getScrollHeight();
  const diff = scrollHeight - percentage(scrollHeight, threshold);
  return window.innerHeight + scrollY >= diff;
};

const isWindowEndReachedPx = (scrollY: number, threshold = 1000): boolean => {
  const scrollHeight = getScrollHeight();
  const diff = scrollHeight - threshold;
  return window.innerHeight + scrollY >= diff;
};

const loadScript = (
  src: string,
  attributes: Record<string, any> = { defer: true },
  el?: any
) => {
  const script = document.createElement('script');
  script.src = src;
  for (const [key, value] of Object.entries(attributes)) {
    script.setAttribute(key, value.toString());
  }
  (el ?? document.body)?.appendChild(script);
  return script;
};

export {
  canUseDOM,
  getScrollHeight,
  isWindowEndReached,
  isWindowEndReachedPx,
  loadScript,
};
