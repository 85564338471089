import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';

import type { ProductSizeVariantsPillsConfig } from '@tectonic/types';
import type { FC } from 'react';

interface SizePillsProps {
  sizes: string[];
  selectedSize?: string;
  config: ProductSizeVariantsPillsConfig;
}

const SizePills: FC<SizePillsProps> = ({ sizes, selectedSize, config }) => {
  const { style, className } = useStyleConfig(config.container ?? {});
  const { style: mCStyle, className: mCClassName } = useStyleConfig(
    config.moreContainer ?? {}
  );
  const { style: iStyle, className: iClassName } = useStyleConfig(
    config.text ?? {}
  );

  return (
    <>
      <div style={style} className={clsx('flex flex-row', className)}>
        <div style={iStyle} className={iClassName}>
          <Text data={selectedSize} config={config.text} />
        </div>
      </div>
      {sizes.length > 0 && (
        <div style={mCStyle} className={clsx('flex flex-row', mCClassName)}>
          <div style={iStyle} className={iClassName}>
            <Text
              data={`+${sizes.length} ${
                sizes.length === 1 ? 'other' : 'others'
              }`}
              config={config.text}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { SizePills };
