import { useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonComponentType } from '@tectonic/types';
import clsx from 'clsx';
import { useElemasonComponentConfig } from '../../hooks/useElemasonConfig';
import { Icon } from '../Icon';

import type { SwatchConfig } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface SwatchProps extends Omit<ComponentProps<'div'>, 'color'> {
  color: {
    name: string;
    code: string;
  };
  config?: SwatchConfig;
}

const Swatch: FC<SwatchProps> = ({
  color,
  config: swatchConfig,
  style,
  className,
}) => {
  const config = useElemasonComponentConfig(
    ElemasonComponentType.Swatch,
    swatchConfig
  );
  const { style: iStyle, className: iClassName } = useStyleConfig(
    config?.item ?? {}
  );

  const { style: cStyle, className: cClassName } = useStyleConfig(
    config?.itemContainer ?? {}
  );

  let content = (
    <div
      className={iClassName}
      style={{
        ...iStyle,
        background: color.code,
      }}
    />
  );

  if (!color || !color.code) {
    // todo make it configurable
    content = (
      <div className={clsx(iClassName)} style={iStyle}>
        <Icon symbol="outline-checkered" />
      </div>
    );
  }

  return (
    <div
      key={color.name}
      style={{ ...style, ...cStyle }}
      className={clsx(cClassName, className)}
    >
      {content}
    </div>
  );
};

export { Swatch };
