import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import { Logger } from '@tectonic/logger';
import { ElemasonWidgetType } from '@tectonic/types';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { memo, useCallback, useRef, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductList as ProductListContent } from '../../../components';
import { useElemasonAnalyticsContext } from '../../../contexts';
import { ElemasonActionProvider } from '../../../contexts/ElemasonActionContext';
import { useElemasonWidgetConfig } from '../../../hooks/useElemasonConfig';
import { useImpressionLedger } from '../../../hooks/useImpressionLedger';
import { useProductMap } from '../../../utils/productList';
import { ProductListHeader } from '../../ProductList/ProductListHeader';
import { useRecommendationWidget } from './hooks';

import type {
  ElemasonProductRecommendationsWidget,
  ImpressionLedgerEntry,
  Product,
} from '@tectonic/types';
import type { ProductRecommendationWidgetProps } from './ProductRecommendations.types';

const ProductRecommendationsWidget: FC<ProductRecommendationWidgetProps> = ({
  widget,
}) => {
  const { config: productListConfig, data } = widget;

  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    productListConfig
  );

  const { style, className } = useStyleConfig(config?.container ?? {});
  const { ref, inView: isListInView } = useInView();
  const isInViewRef = useRef<boolean>(isListInView);
  isInViewRef.current = isInViewRef.current || isListInView;

  const analyticsContext = useElemasonAnalyticsContext();

  const {
    isLoading,
    isError,
    count,
    products,
    onProductClick,
    onSourceClick,
    onRetry,
  } = useRecommendationWidget(widget as ElemasonProductRecommendationsWidget, {
    perPage: config?.maxProductToDisplay,
    enabled: isInViewRef.current,
    queryKey: widget.id,
  });

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );
  const productMap = useProductMap(products ?? [], count ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  const onInViewChange = (viewProduct: Product, inView: boolean) => {
    onProductInViewChange(viewProduct, inView);
  };

  if (isEmpty(products) && (!isLoading || isError)) {
    Logger.error('Empty result set for product recommendations');
    return null;
  }

  return (
    <ElemasonActionProvider value={{ refetchProductRecommendations: onRetry }}>
      <div
        ref={ref}
        style={style}
        className={clsx('flex w-full flex-col', className)}
      >
        <ProductListHeader
          count={count}
          isError={isError}
          data={data?.header}
          isLoading={isLoading}
          config={config?.header}
        />
        <ProductListContent
          data={data}
          count={count}
          config={config}
          products={products}
          isError={isError}
          isLoading={isLoading}
          onSourceClick={onSourceClick}
          onProductClick={onProductClick}
          onProductInViewChange={onInViewChange}
        />
      </div>
    </ElemasonActionProvider>
  );
};

const MemoizedProductRecommendationsWidget = memo(ProductRecommendationsWidget);

export { MemoizedProductRecommendationsWidget as ProductRecommendationsWidget };
