import { Logger } from '@tectonic/logger';

const getClevertapInfo = () => {
  let clevertapId = null;
  try {
    // @ts-ignore
    if (
      typeof globalThis !== 'undefined' &&
      typeof globalThis.getClevertapId !== 'undefined'
    ) {
      // @ts-ignore
      clevertapId = globalThis.getClevertapId();
    }
  } catch (error) {
    Logger.error(`[getClevertapInfoErr]`, error);
  }
  return { clevertapId };
};

export { getClevertapInfo };
