import { Clickable, type IconSymbol } from '@tectonic/uikit';
import clsx from 'clsx';
import { Icon } from '../Icon';

import type { IconConfig, LookAsset } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface LookLegendProps
  extends Omit<ComponentProps<'div'>, 'onClick' | 'ref'> {
  asset: LookAsset;
  config?: IconConfig;
  annotationIcon: IconSymbol;
  activeProductSlug?: string;
  inactiveAnnotationIcon: IconSymbol;
  onClick: (productSlug: string) => void;
}

const LookLegend: FC<LookLegendProps> = ({
  asset,
  style,
  config,
  className,
  onClick,
  annotationIcon,
  activeProductSlug,
  inactiveAnnotationIcon,
  ...props
}) =>
  asset?.config?.legends?.map((legend) => (
    <Clickable
      key={legend.productSlug}
      title={`${legend.productSlug} legend`}
      className={clsx(className, 'absolute cursor-pointer p-2')}
      onClick={() => onClick(legend.productSlug)}
      style={{
        top: `${legend.y * 100}%`,
        left: `${legend.x * 100}%`,
        transform: 'translate(-50%, -50%)',
        ...style,
      }}
      {...props}
    >
      <Icon
        symbol={
          activeProductSlug === legend.productSlug
            ? annotationIcon
            : inactiveAnnotationIcon
        }
        config={config}
      />
    </Clickable>
  ));

export { LookLegend };
