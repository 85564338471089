import { Logger } from '@tectonic/logger';

import type { ElemasonShareWidget } from '@tectonic/types';
import { populate } from '@tectonic/utils';
import type { FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import {
  useActionDispatch,
  useFragmentValue,
  useHaloScript,
  usePageFragment,
} from '../../hooks';

interface ElemasonShareWidgetProps {
  widget: ElemasonShareWidget;
}

const ShareWidget: FC<ElemasonShareWidgetProps> = ({
  widget: { data, actions },
}) => {
  const wData = data!;
  const dispatch = useActionDispatch();
  const template = useHaloScript(wData.template);
  const placeholders = useHaloScript(wData.placeholders);

  const onShare = async () => {
    try {
      actions?.onClick.forEach((action) => dispatch(action, undefined));
      await navigator.share({
        text: populate(template ?? '', placeholders ?? {}),
      });
    } catch (error) {
      Logger.error(`Couldn't share product.`, error);
    }
  };

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({ onShare });

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

export { ShareWidget };
