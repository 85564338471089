import { Text, useStyleConfig } from '@tectonic/elemason-components';

import type { FC } from 'react';
import type { SearchSuggestionsHeaderProps } from './SearchSuggestions.types';

const SearchSuggestionsHeader: FC<SearchSuggestionsHeaderProps> = ({
  config,
  data,
}) => {
  const { container, title } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  return (
    <div {...containerStyleConfig}>
      <Text config={title} data={data.title} />
    </div>
  );
};

SearchSuggestionsHeader.displayName = 'ElemasonSearchSuggestionsHeader';

export { SearchSuggestionsHeader };
