/* eslint-disable max-len */
import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  ElemasonWidgetType,
  NavigationActionType,
} from '@tectonic/types';
import clsx from 'clsx';
import { memo, useCallback, useRef, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductList as ProductListContent } from '../../components';
import { useElemasonAnalyticsContext } from '../../contexts';
import { useActionDispatch } from '../../hooks';
import { useCollectionProductSearchV2 } from '../../hooks/network/useCollectionProductSearchV2';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { useHaloScript } from '../../hooks/useHaloScript';
import { useImpressionLedger } from '../../hooks/useImpressionLedger';
import { useProductMap } from '../../utils/productList';
import { ProductListHeader } from '../ProductList/ProductListHeader';

import type {
  ElemasonCollectionProductListV2Widget,
  ImpressionLedgerEntry,
  Product,
  SearchQuerySource,
} from '@tectonic/types';

interface ElemasonCollectionProductListV2WidgetProps {
  widget: ElemasonCollectionProductListV2Widget;
}

const CollectionProductListV2Widget: FC<
  ElemasonCollectionProductListV2WidgetProps
> = ({ widget }) => {
  const { id: widgetId, data, config: productListConfig } = widget;

  const { ref, inView: isListInView } = useInView({ threshold: 0 });

  const isInViewRef = useRef<boolean>(isListInView);
  isInViewRef.current = isInViewRef.current || isListInView;

  const analyticsContext = useElemasonAnalyticsContext();
  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    productListConfig
  );

  const minProduct = productListConfig?.minProduct ?? -1;

  const actionDispatch = useActionDispatch();
  const collection = useHaloScript(data?.collection);
  const source = useHaloScript<SearchQuerySource>(data?.source);

  const { style, className } = useStyleConfig(config?.container ?? {});
  const { hits, found, isError, isLoading } = useCollectionProductSearchV2(
    widgetId,
    {
      slug: collection,
      source,
      perPage: config?.maxProductToDisplay,
      enabled: config.ignoreIntersectionObserver ?? isInViewRef.current,
    }
  );

  const onSourceClick = () => {
    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.COLLECTION,
        route: {
          slug: collection!,
        },
      },
    });
  };

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );

  const productMap = useProductMap(hits ?? [], found ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  const onInViewChange = (viewProduct: Product, inView: boolean) => {
    onProductInViewChange(viewProduct, inView);
  };

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: { product },
          index,
        },
      },
    });
    if (!config.preventPdpNavigation) {
      actionDispatch({
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PDP,
          route: {
            slug: product.slug,
          },
        },
      });
    }
  };

  if (found < minProduct && !(isLoading || isError)) {
    return null;
  }

  return (
    <div
      ref={ref}
      style={style}
      className={clsx('flex w-full flex-col', className)}
    >
      <ProductListHeader
        count={found}
        isError={isError}
        data={data?.header}
        isLoading={isLoading}
        config={config?.header}
      />

      <ProductListContent
        data={data}
        count={found}
        config={config}
        products={hits}
        isError={isError}
        isLoading={isLoading}
        onSourceClick={onSourceClick}
        onProductClick={onProductClick}
        onProductInViewChange={onInViewChange}
      />
    </div>
  );
};

const MemoizedCollectionProductListV2Widget = memo(
  CollectionProductListV2Widget
);

export { MemoizedCollectionProductListV2Widget as CollectionProductListV2Widget };
