import { AuthPhoneNoOtpSignInForm } from '../../../components';
import { usePhoneOtpSignInForm } from './hooks';

import type { FC } from 'react';
import type { AuthPhoneNoOtpSignInFormWidgetProps } from './AuthPhoneNoOtpSignInForm.types';

const AuthPhoneNoOtpSignInFormWidget: FC<
  AuthPhoneNoOtpSignInFormWidgetProps
> = ({ widget }) => {
  const wData = widget.data!;

  const { isLoading, onSubmit } = usePhoneOtpSignInForm(widget);

  return (
    <AuthPhoneNoOtpSignInForm
      isLoading={isLoading}
      data={wData}
      config={widget.config}
      onSubmit={onSubmit}
    />
  );
};

AuthPhoneNoOtpSignInFormWidget.displayName = 'AuthPhoneNoOtpSignInFormWidget';

export { AuthPhoneNoOtpSignInFormWidget };
