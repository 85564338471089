import { useCallback } from 'react';
import { Carousel } from '../../components';
import {
  useElemasonAnalyticsContext,
  useElemasonPageContext,
} from '../../contexts';
import { useHaloScript } from '../../hooks/useHaloScript';

import type {
  CarouselBannerAsset,
  ElemasonCarouselBannerWidget,
  ElemasonWidgetAction,
} from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonCarouselBannerWidgetProps {
  widget: ElemasonCarouselBannerWidget;
}

const CarouselBannerWidget: FC<ElemasonCarouselBannerWidgetProps> = ({
  widget,
}) => {
  const { config, data } = widget;
  const { assets: assetsAccessor } = data ?? {};
  const assets = useHaloScript(assetsAccessor);
  const { dispatch } = useElemasonPageContext();
  const analyticsContext = useElemasonAnalyticsContext();

  const onSlideClick = useCallback(
    (_asset: CarouselBannerAsset, action?: ElemasonWidgetAction) => {
      if (action) {
        dispatch(action, analyticsContext);
      }
    },
    [dispatch]
  );

  const { carousel: carouselConfig } = config || {};

  if (!assets) return null;

  return (
    <Carousel
      slides={assets}
      config={carouselConfig}
      onSlideClick={onSlideClick}
    />
  );
};

CarouselBannerWidget.displayName = 'ElemasonCarouselBannerWidget';

export { CarouselBannerWidget };
