import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import deepmerge from 'deepmerge';
import { memo, useMemo } from 'react';
import { ElemasonBlockProvider, ElemasonCellProvider } from '../../contexts';
import { useHaloScript } from '../../hooks/useHaloScript';
import { Cell } from '../Cell';

import type { ElemasonBlock } from '@tectonic/types';
import type { FC } from 'react';

interface BlockProps {
  index: number;
  block: ElemasonBlock;
}

const Block: FC<BlockProps> = ({ index, block }) => {
  const configOverrides = useHaloScript(block?.configOverrides);
  const blockWithResolvedConfig = useMemo(
    () => ({
      ...block,
      config: configOverrides
        ? deepmerge(block.config ?? {}, configOverrides)
        : block.config,
    }),
    [block, configOverrides]
  );

  const { config, cells, shouldRender, metadata } = blockWithResolvedConfig;

  const [style, className] = useStyleConfigV2(config ?? {});
  const toRender = useHaloScript(shouldRender) ?? !shouldRender;
  const context = useMemo(() => ({ index, metadata }), [index, metadata]);

  if (!toRender) return null;

  return (
    <ElemasonBlockProvider value={context}>
      <div
        className={clsx('flex flex-row', className)}
        style={style}
        id={config?.domId}
      >
        {cells.map((cell, idx) => (
          <ElemasonCellProvider
            key={cell.id ?? idx}
            value={{ index: idx, ...cell.metadata }}
          >
            <Cell cell={cell} />
          </ElemasonCellProvider>
        ))}
      </div>
    </ElemasonBlockProvider>
  );
};

Block.displayName = 'ElemasonBlock';

const MemoizedBlock = memo(Block);

export { MemoizedBlock as Block };
