import { Text, useStyleConfig } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  NavigationActionType,
  type ElemasonOrderListWidget,
  type Maybe,
  type OrderLite,
} from '@tectonic/types';
import clsx from 'clsx';
import { useMemo, type FC } from 'react';
import {
  useElemasonAnalyticsContext,
  useElemasonPageContext,
} from '../../contexts';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { OrderListCard } from './OrderListCard';
import { OrderListSkeletonCard } from './OrderListSkeletonCard';

interface OrderListProps {
  config: ElemasonOrderListWidget['config'];
  orders: OrderLite[];
  isLoading?: boolean;
  isError?: boolean;
  hasOrders?: boolean;
}

type OrderListItemType = 'LOADING' | 'ERROR' | 'ORDER';

interface OrderListItemInfo {
  data: Maybe<OrderLite>;
  type: OrderListItemType;
}

const OrderList: FC<OrderListProps> = ({
  config,
  orders,
  isLoading,
  hasOrders,
  isError,
}) => {
  const { style: listStyle, className: listClassName } = useStyleConfig(
    config?.list ?? {}
  );

  const { dispatch } = useElemasonPageContext();
  const analyticsContext = useElemasonAnalyticsContext();

  const onContinueShoppingClick = () => {
    dispatch(
      {
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PATH,
          slug: 'home',
        },
      },
      analyticsContext
    );
  };

  const orderItems: OrderListItemInfo[] = useMemo(() => {
    const items: OrderListItemInfo[] = (orders ?? [])?.map((order) => ({
      type: 'ORDER',
      data: order,
    }));

    if (isError) {
      return [
        {
          type: 'ERROR',
          data: null,
        },
      ];
    }

    if (isLoading) {
      const loaders: OrderListItemInfo[] = Array.from({ length: 4 }, () => ({
        type: 'LOADING',
        data: null,
      }));

      items.push(...loaders);
    }

    return items;
  }, [orders, isLoading, isError]);

  return (
    <div className={clsx('flex flex-col', listClassName)} style={listStyle}>
      {hasOrders || isLoading ? (
        orderItems.map((orderItem, idx) => {
          if (orderItem.type === 'LOADING') {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <OrderListSkeletonCard
                key={`order-skel-${idx}`}
                config={config}
              />
            );
          }
          if (orderItem.type === 'ORDER') {
            return (
              <OrderListCard
                config={config}
                order={orderItem.data!}
                key={`order-${orderItem.data?.name}`}
              />
            );
          }
          return null;
        })
      ) : (
        <div
          className="flex flex-col gap-y-8 px-8"
          style={{
            backgroundColor: '#FFFFFF',
            alignItems: 'center',
            paddingTop: '150px',
          }}
        >
          <div
            className="flex flex-col gap-y-4"
            style={{ alignItems: 'center' }}
          >
            <div className="flex">
              <Icon
                symbol="outline-empty-box"
                config={{ size: 'lg', color: 'black' }}
              />
            </div>
            <div className="flex">
              <Text
                data="No Orders Found"
                config={{
                  variant: 'body2',
                  fontWeight: 'semibold',
                  color: 'black',
                }}
              />
            </div>
            <div className="flex flex-col">
              <Text
                data="Looks like you haven’t placed any order yet."
                config={{
                  variant: 'body3',
                  fontWeight: 'normal',
                  color: 'black',
                }}
              />
              <Text
                data="Explore our latest arrivals on the homepage."
                config={{
                  variant: 'body3',
                  fontWeight: 'normal',
                  color: 'black',
                }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              config={{
                variant: 'solid',
                backgroundColor: 'primary-400',
                size: 'lg',
                text: {
                  color: 'white',
                  variant: 'body2',
                  fontWeight: 'semibold',
                },
                borderRadius: 'lg',
              }}
              data={{ text: 'CONTINUE SHOPPING' }}
              onClick={onContinueShoppingClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { OrderList };
