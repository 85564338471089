import { useStyleConfig } from '@tectonic/elemason-components';
import { ProductVariantsColorVariant } from '@tectonic/types';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { uniqBy } from 'lodash-es';
import { ColorSwatch } from '../../../ColorSwatch';
import { ColorDropdown } from '../../../ColorSwatch/ColorDropdown';

import type {
  ProductColorVariantsContainerConfig,
  ProductVariant,
} from '@tectonic/types';
import type { FC, MouseEventHandler } from 'react';

interface ProductColorVariantsProps {
  onClick?: MouseEventHandler;
  variants: ProductVariant[];
  selectedVariant: ProductVariant;
  config: ProductColorVariantsContainerConfig;
  attr: 'basisAttr1' | 'basisAttr2' | 'basisAttr3';
}

const ProductColorVariants: FC<ProductColorVariantsProps> = ({
  attr,
  config,
  onClick,
  variants,
  selectedVariant,
}) => {
  const { style, className } = useStyleConfig(config.container ?? {});

  const selectedColor = {
    name: selectedVariant?.[`${attr}Display`],
    code: selectedVariant?.[`${attr}DisplayConf`]?.color,
  };

  const colors = variants?.map((variant) => ({
    name: variant[`${attr}Display`]!,
    code: variant[`${attr}DisplayConf`]?.color,
  }));
  if (uniqBy(colors, 'name').length <= 1) return null;

  if (config?.config.variant === ProductVariantsColorVariant.SWATCH) {
    return (
      <Clickable
        style={style}
        title="Product Color Variants Swatch"
        onClick={onClick}
        className={clsx('flex flex-row', className)}
      >
        <ColorSwatch colors={[selectedColor]} config={config.config} />
        <ColorSwatch colors={colors} config={config.config} />
      </Clickable>
    );
  }

  if (config?.config.variant === ProductVariantsColorVariant.DROPDOWN) {
    return (
      <Clickable
        style={style}
        onClick={onClick}
        title="Product Color Variants Dropdown"
        className={clsx('flex flex-row', className)}
      >
        <ColorDropdown
          colors={colors}
          color={selectedColor}
          config={config.config}
        />
      </Clickable>
    );
  }

  return null;
};

export { ProductColorVariants };
