import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';

import type {
  ElemasonProductListWidgetSourceCardData,
  ProductListSourceCardConfig,
} from '@tectonic/types';
import type { FC } from 'react';
import { useFragmentValue, usePageFragment } from '../../../../hooks';
import { ElemasonFragmentProvider } from '../../../../contexts';
import { Cell } from '../../../../core';

interface ProductListSourceCardProps {
  count?: number;
  fragmentSlug?: string;
  onSourceClick: () => void;
  config?: ProductListSourceCardConfig;
  data?: ElemasonProductListWidgetSourceCardData;
}

const ProductListSourceCard: FC<ProductListSourceCardProps> = ({
  data,
  count,
  config,
  fragmentSlug,
  onSourceClick,
}) => {
  const { title, subtitle } = data ?? {};
  const fragment = usePageFragment(fragmentSlug);
  const fragmentValue = useFragmentValue(fragment);
  const [style, className] = useStyleConfigV2(config?.container ?? {});
  const [sizeStyle, sizeClassName] = useStyleConfigV2(config?.size ?? {});

  if (fragment) {
    return (
      <Clickable
        style={{ ...style, ...sizeStyle }}
        onClick={onSourceClick}
        className={clsx(
          'flex shrink-0 flex-col items-center justify-center',
          className,
          sizeClassName
        )}
      >
        <ElemasonFragmentProvider value={fragmentValue({})}>
          {fragment?.cells.map((cell, idx) => (
            <Cell key={cell.id ?? idx} cell={cell} />
          ))}
        </ElemasonFragmentProvider>
      </Clickable>
    );
  }

  return (
    <Clickable
      style={{ ...style, ...sizeStyle }}
      onClick={onSourceClick}
      className={clsx(
        'flex shrink-0 flex-col items-center justify-center',
        className,
        sizeClassName
      )}
    >
      <Text
        data={title}
        config={config?.title}
        placeholders={{ count: `${count ?? ''}` }}
      />
      <Text
        data={subtitle}
        config={config?.subtitle}
        placeholders={{ count: `${count ?? ''}` }}
      />
    </Clickable>
  );
};

export { ProductListSourceCard };
