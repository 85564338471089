import { useCallback, useEffect, useMemo } from 'react';
import { usePageFragment, useSharedLocalState } from '../../../../hooks';
import { useFragmentValue } from '../../../../hooks/useFragmentValue';
import { useHaloScript } from '../../../../hooks/useHaloScript';
import { useDefaultVariant } from '../../VariantSelector/hooks/useDefaultVariant';

import type {
  ElemasonVariantSelectWidget,
  ProductVariant,
} from '@tectonic/types';

const useVariantSelect = ({ data }: ElemasonVariantSelectWidget) => {
  const product = useHaloScript(data?.product);
  const stateKey = useHaloScript(data?.stateKey);
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const variant = useHaloScript(data?.defaultVariant);
  const dVariant = useDefaultVariant(product?.variants ?? []);

  const initialState = useMemo(
    () => ({ variant: variant ?? dVariant }),
    [variant, dVariant]
  );

  const { sharedState: currentVariant, setSharedState } = useSharedLocalState<{
    variant: ProductVariant;
    userProfileData?: any;
  }>(stateKey!, initialState);

  const setSelectedVariant = useCallback(
    ({
      variant: v,
      userProfileData,
    }: {
      variant: ProductVariant;
      userProfileData?: any;
    }) => {
      setSharedState({ variant: v, userProfileData });
    },
    [setSharedState]
  );

  useEffect(() => () => setSharedState(undefined), [setSharedState]);

  return {
    product,
    fragment,
    fragmentValue,
    setSelectedVariant,
    selectedVariant: currentVariant?.variant,
  };
};

export { useVariantSelect };
