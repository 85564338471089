import { useStyleConfig } from '@tectonic/elemason-components';
import { Carousel } from '@tectonic/uikit';
import clsx from 'clsx';

import type { ElemasonCarouselBannerWidgetConfig } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface CarouselSlideProps extends ComponentProps<typeof Carousel.Slide> {
  config?: ElemasonCarouselBannerWidgetConfig['carousel']['slide'];
}

const CarouselSlide: FC<CarouselSlideProps> = ({
  children,
  config,
  style,
  className,
  ...props
}) => {
  const { style: slideStyle, className: slideClassName } = useStyleConfig(
    config ?? {}
  );

  return (
    <Carousel.Slide
      style={{ ...slideStyle, ...style }}
      className={clsx(slideClassName, className)}
      {...props}
    >
      {children}
    </Carousel.Slide>
  );
};

export { CarouselSlide };
