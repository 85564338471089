import { useInfiniteQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { queryKeys } from '../../queryKeys';

import type {
  Collection,
  Nil,
  SearchQuerySource,
  SearchResponse,
} from '@tectonic/types';

const PER_PAGE = 10;

type CollectionSearchResponse = SearchResponse<{ document: Collection }>;

const useCollections = (
  source: SearchQuerySource,
  options: { page?: number; perPage?: number } = {}
) => {
  const perPage = options.perPage ?? PER_PAGE;
  const getNextPageParam = (
    response: CollectionSearchResponse
  ): Nil<number> => {
    const { page, found } = response;
    const count = page * perPage;
    return count < found ? page + 1 : undefined;
  };
  const getPreviousPageParam = (
    response: CollectionSearchResponse
  ): Nil<number> => {
    const { page } = response;
    return page <= 1 ? undefined : page - 1;
  };

  const params = { ...(source.query as object), page: 1, perPage: PER_PAGE };

  const {
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    data,
    isError,
    hasNextPage: hasMore,
    hasPreviousPage: hasPrevious,
    fetchNextPage: onLoadMore,
    fetchPreviousPage: onLoadPrevious,
  } = useInfiniteQuery<CollectionSearchResponse>({
    getNextPageParam,
    getPreviousPageParam,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,

    queryKey: queryKeys.collections(params),
    queryFn: ({ pageParam }) => {
      const payload = {
        ...params,
        perPage: PER_PAGE,
        page: (pageParam ?? 1) as number,
      };
      // TODO: Fix typings. remixApi.searchCollection should have return type
      // SearchResponse<T> instead of ProductSearchResponse.
      const response = remixApi.searchCollection(
        payload,
        source.collection ?? 'collections'
      );
      return response as unknown as CollectionSearchResponse;
    },
    initialPageParam: options?.page ?? 1,
  });

  const { pages } = data ?? {};

  const collections =
    pages?.flatMap((page) => page.hits.map(({ document }) => document)) ?? [];

  return {
    isError,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    hasMore,
    onLoadMore,
    collections,
    perPage,
    hasPrevious,
    onLoadPrevious,
  };
};

export { useCollections };
