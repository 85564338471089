import EmblaAutoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { isNil, noop, omitBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { EmblaCarouselType, EmblaPluginType } from 'embla-carousel-react';
import type {
  CarouselAutoplayOptions,
  CarouselContextType,
  CarouselProps,
  CarouselWheelGestureOptions,
  UseCarouselArgs,
} from './Carousel.types';

const getAutoplayPlugin = (options: CarouselAutoplayOptions) =>
  EmblaAutoplay({ playOnInit: true, ...options });

const getWheelGesturesPlugin = (options: CarouselWheelGestureOptions) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { enable, ...rest } = options;
  return WheelGesturesPlugin(rest);
};

const useEmblaPlugins = ({
  autoplay,
  wheelGestures,
}: Pick<CarouselProps, 'autoplay' | 'wheelGestures'>) =>
  useMemo(() => {
    const plugins: EmblaPluginType[] = [];
    if (autoplay?.enable) {
      const autoplayPlugin = getAutoplayPlugin(autoplay);
      plugins.push(autoplayPlugin);
    }
    if (wheelGestures?.enable) {
      const wGesturePlugin = getWheelGesturesPlugin(wheelGestures);
      plugins.push(wGesturePlugin);
    }
    return plugins;
  }, [autoplay]);

const useCarousel = (args: UseCarouselArgs) => {
  const {
    loop,
    inViewThreshold,
    autoplay,
    align,
    axis,
    containScroll,
    container,
    direction,
    dragFree,
    dragThreshold,
    duration,
    slidesToScroll,
    startIndex,
    watchResize,
    watchSlides,
    wheelGestures,
  } = args;
  const plugins = useEmblaPlugins({ autoplay, wheelGestures });
  const options = omitBy(
    {
      loop,
      inViewThreshold,
      align,
      containScroll,
      container,
      direction,
      dragFree,
      dragThreshold,
      duration,
      slidesToScroll,
      startIndex,
      watchResize,
      watchSlides,
      axis: axis!,
    },
    isNil
  );

  return useEmblaCarousel(options, plugins);
};

const useCarouselContextValue = (
  emblaApi: EmblaCarouselType | undefined,
  { axis, startIndex }: Pick<CarouselProps, 'axis' | 'startIndex'>
): CarouselContextType => {
  const [snaps, setSnaps] = useState<number[]>([]);
  const [activeSnap, setActiveSnap] = useState<number>(startIndex ?? 0);

  const onInit = useCallback(
    (carousel: EmblaCarouselType) => {
      setSnaps(carousel.scrollSnapList());
    },
    [setSnaps]
  );

  const scrollTo = useCallback(
    (index: number) => emblaApi?.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(
    (carousel: EmblaCarouselType) => {
      setActiveSnap(carousel.selectedScrollSnap());
    },
    [setActiveSnap]
  );

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  const context = useMemo(
    () => ({
      axis: axis!,
      scrollTo,
      scrollNext,
      scrollPrev,
      snaps,
      activeSnap,
    }),
    [axis, scrollTo, scrollNext, scrollPrev, snaps, activeSnap]
  );

  useEffect(() => {
    if (!emblaApi) {
      return noop;
    }
    onInit(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('select', onSelect);

    return () => {
      emblaApi.off('reInit', onInit);
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, onInit, onSelect]);

  return context;
};

export { useCarousel, useCarouselContextValue };
