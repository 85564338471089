import { create } from 'zustand';

import type { ElemasonUserProfileWidgetStep } from '@tectonic/types';
import type { UserProfileWidgetStore } from './user.types';

const useUserProfileWidgetStore = create<UserProfileWidgetStore>(
  (setState) => ({
    currentStep: null,
    details: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
    },
    setStep: (step: ElemasonUserProfileWidgetStep, payload) => {
      setState((state) => {
        const { details } = state;
        const nDetails = {
          firstName: payload?.firstName ?? details.firstName,
          lastName: payload?.lastName ?? details.lastName,
          phoneNumber: payload?.phoneNumber ?? details.phoneNumber,
        };
        return { currentStep: step, details: nDetails };
      });
    },
  })
);

export { useUserProfileWidgetStore };
