import { useStyleConfigV2 } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  type Product,
  type ProductVariant,
  type ProductVariantsConfig,
} from '@tectonic/types';
import clsx from 'clsx';
import { useEffect, type FC, type MouseEventHandler } from 'react';
import { useActionDispatch } from '../../../hooks';
import { getVariantTypes, isOutOfStock } from '../../../utils';
import { ProductBundleVariants } from './ProductBundleVariants';
import { ProductColorVariants } from './ProductColorVariants';
import { ProductSizeVariants } from './ProductSizeVariants';

interface ProductVariantsProps {
  product: Product;
  stateKey?: string;
  onClick?: MouseEventHandler;
  config: ProductVariantsConfig;
  selectedVariant?: ProductVariant;
}

const ProductVariants: FC<ProductVariantsProps> = ({
  config,
  onClick,
  product,
  stateKey,
  selectedVariant,
}) => {
  const dispatch = useActionDispatch();
  const variantTypes = product.variants
    ? getVariantTypes(product.variants[0])
    : [];

  const selected =
    selectedVariant ??
    product.variants?.find((v) => !isOutOfStock(v.stockStatus)) ??
    product.variants?.[0];

  const [style, className] = useStyleConfigV2(config.container ?? {});

  const currentVariant = selectedVariant ?? selected;

  useEffect(() => {
    if (!selectedVariant && stateKey) {
      dispatch({
        type: ElemasonWidgetActionType.UPDATE_LOCAL_STATE,
        payload: {
          key: stateKey,
          value: currentVariant,
        },
      });
    }
  });

  if (
    product.hasVariantsWithComponents &&
    product.productComponents?.length !== undefined &&
    product.productComponents?.length > 0
  ) {
    return (
      <div
        style={style}
        className={clsx(
          'flex',
          className,
          config.container?.direction === 'row' ? 'flex-row' : 'flex-col'
        )}
      >
        <ProductBundleVariants
          product={product}
          onClick={onClick}
          config={config.bundle}
        />
      </div>
    );
  }

  return (
    <div
      style={style}
      className={clsx(
        'flex',
        className,
        config.container?.direction === 'row' ? 'flex-row' : 'flex-col'
      )}
    >
      {variantTypes
        .filter(({ value }) => value && value !== 'title')
        .map(({ attr, value }) =>
          value === 'color' ? (
            <ProductColorVariants
              key={attr}
              attr={attr}
              onClick={onClick}
              config={config.color}
              variants={product.variants ?? []}
              selectedVariant={currentVariant!}
            />
          ) : (
            <ProductSizeVariants
              key={attr}
              attr={attr}
              onClick={onClick}
              config={config.size}
              variants={product.variants ?? []}
              selectedVariant={currentVariant}
            />
          )
        )}
    </div>
  );
};
export { ProductVariants };
