import { useQuery } from '@tanstack/react-query';
import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonWidgetActionType, ElemasonWidgetType } from '@tectonic/types';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useActionDispatch } from '../../hooks';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { queryKeys } from '../../queryKeys';
import { isOutOfStock } from '../../utils';
import { MediaAsset } from '../Asset';
import { ProductList } from '../Product';
import { LookLegend } from './Legend';

import type {
  ElemasonLooksWidgetConfig,
  LookTypesense,
  Product,
  ProductListHandle,
  ProductVariant,
} from '@tectonic/types';
import type { Dispatch, FC } from 'react';

interface LookProps {
  look: LookTypesense;
  mediaClassName?: string;
  productFragment?: string;
  mediaStyle?: React.CSSProperties;
  config?: ElemasonLooksWidgetConfig;
  selectedVariants: Record<string, ProductVariant>;
  setSelectedVariants: Dispatch<Record<string, ProductVariant>>;
  setProducts: Dispatch<Record<string, Product>>;
}

const Look: FC<LookProps> = ({
  look: typesenseLook,
  mediaClassName,
  productFragment,
  mediaStyle,
  config,
  selectedVariants,
  setSelectedVariants,
  setProducts,
}) => {
  const {
    data: look,
    error: lookError,
    isLoading: lookIsLoading,
  } = useQuery({
    queryKey: queryKeys.look(typesenseLook.slug),
    queryFn: () => remixApi.getLook(typesenseLook.slug),
  });
  const [activeProductSlug, setActiveProductSlug] = useState<
    string | undefined
  >(look?.products[0]?.product.slug ?? undefined);

  const ref = useRef<ProductListHandle>(null);
  const { style, className } = useStyleConfig(config?.mediaContainer ?? {});
  const productListConfig = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    config?.list
  );

  useEffect(() => {
    setSelectedVariants(
      Object.fromEntries(
        (
          look?.products.map((lookProduct) => [
            lookProduct.product.id,
            lookProduct.product.variants?.find(
              (v) => !isOutOfStock(v.stockStatus)
            ) ?? lookProduct.product.variants?.[0],
          ]) ?? []
        ).filter(([_a, variant]) => variant !== undefined)
      )
    );
    setProducts(
      Object.fromEntries(
        look?.products.map((lookProduct) => [
          lookProduct.product.id,
          lookProduct.product,
        ]) ?? []
      )
    );
  }, [look]);

  const onVariantSelect = (product: Product, variant: ProductVariant) => {
    setSelectedVariants({
      ...selectedVariants,
      [product.id]: variant,
    });
  };

  const actionDispatch = useActionDispatch();

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });

    // trackProductEvent(
    //   ProductEventNames.PRODUCT_CLICK,
    //   enrichAnalyticsPayloadWithWidgetData<AnalyticsProductEventPayload>(
    //     {
    //       index,
    //       product,
    //     },
    //     widget
    //   )
    // );

    actionDispatch({
      type: ElemasonWidgetActionType.GLOBAL_DRAWER_OPEN,
      payload: {
        slug: 'mini-pdp',
        data: {
          product,
        },
        entry: 'bottom',
        exit: 'bottom',
      },
    });
  };

  const handleLegendClick = (slug: string) => {
    setActiveProductSlug(slug);
    if (ref.current?.productCardRefs) {
      const productCard = ref.current.productCardRefs[slug];

      if (productCard) {
        productCard.productCardRef.current?.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (activeProductSlug === undefined && look?.products.length) {
      setActiveProductSlug(look.products[0]?.product.slug);
    }
  }, [look]);

  return (
    <>
      <div style={style} className={clsx(className, 'relative')}>
        <MediaAsset
          style={mediaStyle}
          className={mediaClassName}
          asset={typesenseLook.assetMap.gallery[0]}
        />
        <LookLegend
          onClick={handleLegendClick}
          config={config?.annotationIconConfig}
          activeProductSlug={activeProductSlug}
          asset={typesenseLook.assetMap.gallery[0]}
          annotationIcon={config!.annotationIcon!}
          inactiveAnnotationIcon={config!.inactiveAnnotationIcon!}
        />
      </div>
      <div className="flex w-full flex-row overflow-auto">
        <ProductList
          ref={ref}
          isError={!!lookError}
          onSourceClick={() => {}}
          data={{ productFragment }}
          config={productListConfig}
          isLoading={!!lookIsLoading}
          onProductClick={onProductClick}
          onVariantSelect={onVariantSelect}
          selectedVariants={selectedVariants}
          products={(look?.products ?? []).map(({ product }) => product)}
        />
      </div>
    </>
  );
};

export { Look };
