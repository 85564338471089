import { isEmpty } from 'lodash-es';
import { useEffect, useRef, type FC } from 'react';
import { Blocks } from '../../../core/Blocks';
import { useActionDispatch } from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { useProductGrid } from './hooks';

import type { ProductGridWidgetProps } from './ProductGrid.types';

// Implementation of ProductGrid widget differs significantly between app and
// web. On the app it has a dedicated ProductGridRoot. We need to create blocks
// on the fly for recycler list view. We need a dedicated root for it. On the
// web we don't have any of such restriction. Once we use virtualization on web
// we might need to take same approach for this as app. Also, it might mess
// block index currently cause we are nesting `Block` in block. Presently,
// we don't use `useElemasonBlockContext` on the web. So it should be fine. If there's
// any issue, we must create a separate Root for it.
const ProductGridWidget: FC<ProductGridWidgetProps> = ({ widget }) => {
  const initialData = useHaloScript(widget.data?.initialData);
  const implicitFilters = useHaloScript(widget.data?.implicitFilters);
  const { blocks, isLoading, ref } = useProductGrid(
    widget,
    initialData,
    implicitFilters
  );

  const actionDispatch = useActionDispatch();
  const isTrackedRef = useRef<boolean>(false);

  useEffect(() => {
    if (isLoading || isEmpty(widget.actions?.onMount) || isTrackedRef.current) {
      return;
    }
    // TODO Should we ensure all actions done once or figure out a way to filter out only analytics are tracked once.
    widget.actions?.onMount.forEach((action) => {
      isTrackedRef.current = true;
      actionDispatch(action);
    });
  }, [
    widget.actions?.onMount,
    actionDispatch,
    isTrackedRef.current,
    isLoading,
  ]);

  return (
    <div ref={ref}>
      <Blocks blocks={blocks} />
    </div>
  );
};

ProductGridWidget.displayName = 'ProductGridWidget';

export { ProductGridWidget };
