import { createContext } from '@tectonic/utils';

interface ElemasonCellContextType {
  // index of the cell
  index: number;

  // destructured cell metadata.
  [index: string]: unknown;
}

const errorMessage =
  'useElemasonCellContext must be inside a ElemasonCellProvider with a value';
const name = 'ElemasonCellContext';

// For a block, the root cells is are its immediate children.
const [ElemasonCellProvider, useElemasonCellContext] =
  createContext<ElemasonCellContextType>({
    errorMessage,
    name,
    // we can not be strict with elemason root cell cause it is recursive in nature
    // so the provider might have null values.
    strict: false,
  });

export { ElemasonCellProvider, useElemasonCellContext };
