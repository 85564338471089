import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { DialogClose } from '@tectonic/uikit';
import clsx from 'clsx';
import { Button } from '../../../../components';

import type { FC } from 'react';
import type { SearchFilterDrawerHeaderProps } from './SearchFilterDrawer.types';

const SearchFilterDrawerHeader: FC<SearchFilterDrawerHeaderProps> = ({
  data,
  config,
}) => {
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  return (
    <div
      style={containerStyleConfig.style}
      className={clsx('flex items-center', containerStyleConfig.className)}
    >
      <div className="grow">
        <Text data={data.title} config={config?.title} />
      </div>
      <DialogClose asChild>
        {/* TODO: Take this as config if needed */}
        <Button
          data={{ startIcon: 'outline-close' }}
          config={{ variant: 'ghost', modifier: 'black' }}
        />
      </DialogClose>
    </div>
  );
};

SearchFilterDrawerHeader.displayName = 'SearchFilterDrawerHeader';

export { SearchFilterDrawerHeader };
