import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { ApplicabilityReason } from './ApplicabilityReason';

import type {
  CurrentApplicability,
  DiscountMinRequirementConfig,
  ElemasonCartCouponsDialogContentCardApplicabilityConfig,
  ElemasonCartCouponsDialogContentCardApplicabilityData,
  Promotion,
} from '@tectonic/types';
import type { FC } from 'react';

interface CartCouponApplicabilityProps {
  discount: Promotion;
  applicability: CurrentApplicability;
  requirement: DiscountMinRequirementConfig;
  data?: ElemasonCartCouponsDialogContentCardApplicabilityData;
  config?: ElemasonCartCouponsDialogContentCardApplicabilityConfig;
}

const CartCouponApplicability: FC<CartCouponApplicabilityProps> = ({
  data,
  config,
  discount,
  requirement,
  applicability,
}) => {
  const [style, className] = useStyleConfigV2(config?.container ?? {});
  if (applicability.isApplicable) return null;

  return (
    <div style={style} className={clsx('flex flex-col', className)}>
      {applicability.reasons?.map((reason) => (
        <ApplicabilityReason
          reason={reason}
          key={reason.code}
          discount={discount}
          data={data?.reason}
          config={config?.reason}
          requirement={requirement}
        />
      ))}
    </div>
  );
};

export { CartCouponApplicability };
