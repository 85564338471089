import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import {
  ElemasonWidgetActionType,
  ElemasonWidgetType,
  NavigationActionType,
} from '@tectonic/types';
import clsx from 'clsx';
import { useCallback, useRef, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductList as ProductListContent } from '../../components';
import { useElemasonAnalyticsContext } from '../../contexts';
import { useActionDispatch } from '../../hooks';
import { useCollectionProductSearch } from '../../hooks/network';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { useHaloScript } from '../../hooks/useHaloScript';
import { useImpressionLedger } from '../../hooks/useImpressionLedger';
import { useProductMap } from '../../utils/productList';
import { ProductListHeader } from '../ProductList/ProductListHeader';

import type {
  ElemasonCollectionProductListWidget,
  ImpressionLedgerEntry,
  Product,
  SearchQuerySource,
} from '@tectonic/types';

interface ElemasonCollectionProductListWidgetProps {
  widget: ElemasonCollectionProductListWidget;
}

const CollectionProductListWidget: FC<
  ElemasonCollectionProductListWidgetProps
> = ({ widget }) => {
  const { id: widgetId, data, config: productListConfig } = widget;
  const analyticsContext = useElemasonAnalyticsContext();
  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    productListConfig
  );

  const actionDispatch = useActionDispatch();
  const collection = useHaloScript(data?.collection);
  const source = useHaloScript<SearchQuerySource>(data?.source);
  const { ref, inView: isListInView } = useInView();
  const isListInViewRef = useRef<boolean>(isListInView);
  isListInViewRef.current = isListInViewRef.current || isListInView;

  const { style, className } = useStyleConfig(config?.container ?? {});
  const { hits, found, isError, isLoading } = useCollectionProductSearch(
    widgetId,
    {
      slug: collection,
      source,
      perPage: config?.maxProductToDisplay,
      enabled: isListInViewRef.current,
    }
  );

  const onSourceClick = () => {
    actionDispatch({
      type: ElemasonWidgetActionType.NAVIGATE_TO,
      payload: {
        type: NavigationActionType.COLLECTION,
        route: {
          slug: collection!,
        },
      },
    });
  };

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );

  const productMap = useProductMap(hits ?? [], found ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  const onInViewChange = (viewProduct: Product, inView: boolean) => {
    onProductInViewChange(viewProduct, inView);
  };

  const onProductClick = (product: Product, index: number) => {
    // TODO Hack for now the problem is the product entity is there in product card
    // but we need to know from where product click happened if we put this in
    // product card then we will loose info about look/reco/etc widgets
    // once we come up with hierarchy info we can move this to appropriate place
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsProductEventNames.PRODUCT_CLICK,
        data: {
          entities: {
            product,
          },
          index,
        },
      },
    });
    if (!config.preventPdpNavigation) {
      actionDispatch({
        type: ElemasonWidgetActionType.NAVIGATE_TO,
        payload: {
          type: NavigationActionType.PDP,
          route: {
            slug: product.slug,
          },
        },
      });
    }
  };

  return (
    <div
      ref={ref}
      style={style}
      className={clsx('flex w-full flex-col', className)}
    >
      <ProductListHeader
        count={found}
        isError={isError}
        data={data?.header}
        isLoading={isLoading}
        config={config?.header}
      />

      <ProductListContent
        data={data}
        count={found}
        config={config}
        products={hits}
        isError={isError}
        isLoading={isLoading}
        onSourceClick={onSourceClick}
        onProductClick={onProductClick}
        onProductInViewChange={onInViewChange}
      />
    </div>
  );
};

export { CollectionProductListWidget };
