import { useCallback } from 'react';
import { useElemasonFragmentContext } from '../contexts/ElemasonFragmentContext';

import type { ElemasonFragment, Nil } from '@tectonic/types';

const useFragmentValue = (fragment?: Nil<ElemasonFragment>) => {
  const fragmentContext = useElemasonFragmentContext();

  const callback = useCallback(
    (data?: unknown) => {
      if (!fragment) return fragmentContext;

      const value = {
        data,
        id: fragment.id,
        slug: fragment.slug,
        stack: fragmentContext
          ? [
              {
                id: fragmentContext.id,
                slug: fragmentContext.slug,
                data: fragmentContext.data,
              },
              ...(fragmentContext.stack ?? []),
            ]
          : [],
      };

      return value;
    },
    [fragmentContext, fragment]
  );

  return callback;
};
export { useFragmentValue };
