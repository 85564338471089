import { useStyleConfig } from '@tectonic/elemason-components';
import { Marquee } from '@tectonic/uikit';
import { useActionDispatch } from '../../hooks';
import { Tick } from './Tick';

import type {
  ElemasonTickerWidget,
  ElemasonTickerWidgetTickData,
  TickerSpeedConfig,
} from '@tectonic/types';
import type { FC } from 'react';

const speeds: Record<TickerSpeedConfig, number> = {
  slow: 10,
  medium: 50,
  fast: 100,
};

interface ElemasonTickerWidgetProps {
  widget: ElemasonTickerWidget;
}

const TickerWidget: FC<ElemasonTickerWidgetProps> = ({
  widget: { config, data, actions },
}) => {
  const { ticks } = data ?? {};
  const dispatch = useActionDispatch();
  const { container, direction, speed = 'medium' } = config?.ticker ?? {};
  const { style, className } = useStyleConfig(container ?? {});

  const onTickPress = (tick: ElemasonTickerWidgetTickData) => {
    actions?.onTickClick.forEach((action) => dispatch(action, tick));
  };

  if (!ticks) return null;

  return (
    <Marquee
      style={style}
      speed={speeds[speed]}
      className={className}
      direction={direction}
    >
      {ticks.map((tick, index) => (
        <Tick
          key={index}
          tick={tick}
          config={config?.tick}
          onClick={onTickPress}
        />
      ))}
    </Marquee>
  );
};

export { TickerWidget };
