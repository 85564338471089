import { createContext } from '@tectonic/utils';

import type { ElemasonId } from '@tectonic/types';

interface ElemasonFragmentContextType {
  id: ElemasonId;
  slug: string;
  data?: unknown;
  stack?: ElemasonFragmentContextType[];
}

const errorMessage =
  'useElemasonFragmentContext must be inside a ElemasonFragmentProvider with a value';
const name = 'ElemasonFragmentContext';

const [ElemasonFragmentProvider, useElemasonFragmentContext] =
  createContext<ElemasonFragmentContextType>({
    errorMessage,
    name,
    strict: false,
  });

export { ElemasonFragmentProvider, useElemasonFragmentContext };
export type { ElemasonFragmentContextType };
