import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

const carouselSlideContainer = tv({
  slots: {
    base: 'flex',
  },
  variants: {
    // https://www.embla-carousel.com/api/options/#axis
    axis: {
      x: 'flex-row',
      y: 'flex-col',
    },
  },
  defaultVariants: {
    axis: 'x',
  },
});

type CarouselSlideContainerCvaProps = VariantProps<
  typeof carouselSlideContainer
>;

export { carouselSlideContainer };

export type { CarouselSlideContainerCvaProps };
