import { useStyleConfig } from '@tectonic/elemason-components';
import { AlertDialogCancel } from '@tectonic/uikit';
import clsx from 'clsx';
import { Button } from '../../Button';

import type { FC } from 'react';
import type { ConfirmationDialogFooterProps } from './ConfirmationDialog.types';

const ConfirmationDialogFooter: FC<ConfirmationDialogFooterProps> = ({
  data,
  config,
  onClose,
  onConfirm,
  isSubmitting,
}) => {
  const { container, noCta, yesCta } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  return (
    <div
      style={containerStyleConfig.style}
      className={clsx('flex justify-end', containerStyleConfig.className)}
    >
      <AlertDialogCancel asChild>
        <Button
          onClick={onClose}
          config={noCta?.button}
          data={data.noCta.button}
          disabled={isSubmitting}
        />
      </AlertDialogCancel>
      <Button
        onClick={onConfirm}
        config={yesCta?.button}
        data={data.yesCta.button}
        disabled={isSubmitting}
      />
    </div>
  );
};

ConfirmationDialogFooter.displayName = 'ConfirmationDialogFooter';

export { ConfirmationDialogFooter };
