import { trackEvent } from './event';

import type { Metric } from 'web-vitals';

const trackWebVitalMetric = (metric: Metric) => {
  const { name, value, delta, rating, id } = metric;
  trackEvent(`RUM ${name}`, { name, value, delta, rating, id });
};

export { trackWebVitalMetric };
