enum AnalyticsSearchEventNames {
  // Clicking on search icon in header.
  SEARCH_VIEW = 'Search View',

  // clicking on search item.
  SEARCH_CLICK = 'Search Click',

  // Search results view
  SEARCH_RESULTS_VIEW = 'Search Results View',

  SEARCH_FILTER_APPLY = 'Search Filter Apply',
  SEARCH_FILTER_RESET = 'Search Filter Reset',
  SEARCH_SORT_APPLY = 'Search Sort Apply'
}

enum AnalyticsSearchEventSource {
  PLAIN_TEXT = 'Search Plain Text',
  AUTOCOMPLETE = 'Search Suggestions',
  TRENDING = 'Search Trends',
  HISTORY = 'Search History',
}

export { AnalyticsSearchEventNames, AnalyticsSearchEventSource };
