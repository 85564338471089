import { Logger } from '@tectonic/logger';
import deepmerge from 'deepmerge';
import { useMemo, type FC } from 'react';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../contexts';
import { useHaloScript } from '../../hooks/useHaloScript';
import { useWidgetComponent } from './hooks/useWidgetComponent';

import type { ElemasonWidget } from '@tectonic/types';

interface WidgetProps {
  widget: ElemasonWidget<unknown, any, unknown>;
}

const Widget: FC<WidgetProps> = ({ widget }) => {
  const Component = useWidgetComponent(widget);
  const analyticsContext = useElemasonAnalyticsContext();
  const configOverrides = useHaloScript(widget.configOverrides);
  const widgetWithResolvedConfig = useMemo(
    () => ({
      ...widget,
      config: configOverrides
        ? deepmerge(widget.config ?? {}, configOverrides)
        : widget.config,
    }),
    [widget, configOverrides]
  );

  if (!Component) {
    Logger.warn(`Widget ${widget.type} not found`);
    return null;
  }

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        widget: widgetWithResolvedConfig,
        metadata: widget?.metadata ?? analyticsContext?.metadata,
      }}
    >
      <Component widget={widgetWithResolvedConfig} />
    </ElemasonAnalyticsProvider>
  );
};

export { Widget };
