import { toTailwindUtility } from '@tectonic/utils';
import { compact, isNil } from 'lodash-es';

import type { PositionConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const usePositionConfig = (config: PositionConfig) => {
  const {
    justifyContent,
    alignItems,
    position,
    offset,
    start,
    wrap,
    end,
    bottom,
    top,
    zIndex,
    order,
    pointerEvents,
    cursor,
    display,
    alignSelf,
    flexDirection,
  } = config;

  const positionStyles: CSSProperties = {
    transform: config.transform,
  };

  const positionClasses = [
    position,
    toTailwindUtility('flex', wrap),
    toTailwindUtility('offset', offset),
    toTailwindUtility('items', alignItems),
    toTailwindUtility('justify', justifyContent),
  ];

  if (!isNil(alignSelf)) {
    positionStyles.alignSelf = alignSelf;
  }

  if (!isNil(order)) {
    positionStyles.order = order;
  }

  if (!isNil(display)) {
    positionStyles.display = display;
  }

  if (!isNil(top)) {
    positionStyles.top = top;
  }

  if (!isNil(end)) {
    positionStyles.right = end;
  }

  if (!isNil(start)) {
    positionStyles.left = start;
  }

  if (!isNil(bottom)) {
    positionStyles.bottom = bottom;
  }

  if (!isNil(zIndex)) {
    positionStyles.zIndex = zIndex;
  }

  if (!isNil(pointerEvents)) {
    positionStyles.pointerEvents = pointerEvents;
  }

  if (!isNil(flexDirection)) {
    positionStyles.flexDirection = flexDirection;
  }

  if (!isNil(cursor)) {
    positionStyles.cursor = cursor;
  }

  return [positionStyles, compact(positionClasses)];
};

export { usePositionConfig };
