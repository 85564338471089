import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { queryKeys } from '../../queryKeys';

import type { ElemasonId, SearchQuerySource } from '@tectonic/types';

const useProductSearch = (
  widgetId: ElemasonId,
  source?: SearchQuerySource,
  perPage?: number
) => {
  if (!source) {
    throw new Error('useProductSearch: source is required');
  }

  const pp = perPage ?? 10;

  const query = { ...(source.query as object), perPage: pp };

  const { data, ...rest } = useQuery({
    queryKey: queryKeys.productSearch(source, widgetId),
    queryFn: async () => remixApi.searchProducts(query),
  });

  return {
    perPage: pp,
    ...data,
    ...rest,
  };
};

export { useProductSearch };
