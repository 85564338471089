import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import { useOrders } from './hooks';

import type { FC } from 'react';
import type { OrdersWidgetProps } from './Orders.types';

const OrdersWidget: FC<OrdersWidgetProps> = ({ widget }) => {
  const { fragment, fragmentData, inViewRef } = useOrders(widget);

  return (
    <>
      <ElemasonFragmentProvider value={fragmentData}>
        {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
      <div ref={inViewRef} />
    </>
  );
};

OrdersWidget.displayName = 'OrdersWidget';

export { OrdersWidget };
