import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { AssetScale } from '@tectonic/types';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { Button, MediaAsset, PanZoomTransform } from '../../components';

import type {
  Asset,
  ZoomConfig,
  ZoomData,
  ZoomDialogConfig,
  ZoomDialogData,
} from '@tectonic/types';
import type { ReactZoomPanPinchHandlers } from '@tectonic/uikit';
import type { FC } from 'react';

interface ZoomDialogProps {
  assets: Asset[];
  onNext: () => void;
  onClose: () => void;
  data?: ZoomDialogData;
  selectedIndex: number;
  onPrevious: () => void;
  config?: ZoomDialogConfig;
}

interface HandlerProps extends ReactZoomPanPinchHandlers {
  data?: ZoomData;
  config?: ZoomConfig;
}

const Handler: FC<HandlerProps> = ({
  data,
  zoomIn,
  zoomOut,
  config,
  resetTransform,
}) => {
  const [zoomInStyles, zoomInClassName] = useStyleConfigV2(
    config?.zoomIn?.container
  );

  const [zoomOutStyles, zoomOutClassName] = useStyleConfigV2(
    config?.zoomOut?.container
  );

  const [resetStyles, resetClassName] = useStyleConfigV2(
    config?.reset?.container
  );

  return (
    <div className="pointer-events-none fixed left-0 top-0 z-10 h-full w-full">
      {config?.zoomIn?.visible && (
        <div style={zoomInStyles} className={clsx('flex', zoomInClassName)}>
          <Button
            data={data?.zoomIn}
            onClick={() => zoomIn()}
            config={config?.zoomIn?.button}
            className="pointer-events-auto"
          />
        </div>
      )}
      {config?.zoomOut?.visible && (
        <div style={zoomOutStyles} className={clsx('flex', zoomOutClassName)}>
          <Button
            data={data?.zoomOut}
            onClick={() => zoomOut()}
            config={config.zoomOut.button}
            className="pointer-events-auto"
          />
        </div>
      )}
      {config?.reset?.visible && (
        <div style={resetStyles} className={clsx('flex', resetClassName)}>
          <Button
            data={data?.reset}
            config={config.reset.button}
            className="pointer-events-auto"
            onClick={() => resetTransform()}
          />
        </div>
      )}
    </div>
  );
};

const ZoomDialog: FC<ZoomDialogProps> = ({
  data,
  assets,
  onNext,
  config,
  onClose,
  onPrevious,
  selectedIndex,
}) => {
  const [nextStyles, nextClassName] = useStyleConfigV2(config?.next?.container);
  const [closeStyles, closeClassName] = useStyleConfigV2(
    config?.close?.container
  );
  const [previousStyles, previousClassName] = useStyleConfigV2(
    config?.previous?.container
  );
  const asset = assets[selectedIndex ?? 0];

  return (
    <DialogRoot open onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay className="fixed inset-0 z-10 bg-black/80" />
        <DialogContent className="fixed inset-0 z-10 h-full w-full">
          <PanZoomTransform
            handler={(props) =>
              Handler({ ...props, config: config?.zoom, data: data?.zoom })
            }
          >
            <MediaAsset
              asset={asset}
              // @ts-ignore
              config={{
                width: '100%',
                height: '100%',
                resizeMode: 'contain',
                assetScale: AssetScale.X_4,
                backgroundColor: 'transparent',
              }}
            />
          </PanZoomTransform>
          {config?.next?.visible && (
            <div style={nextStyles} className={clsx('flex', nextClassName)}>
              <Button
                onClick={onNext}
                data={data?.next}
                config={config?.next?.button}
                disabled={selectedIndex === assets.length - 1}
              />
            </div>
          )}
          {config?.previous?.visible && (
            <div
              style={previousStyles}
              className={clsx('flex', previousClassName)}
            >
              <Button
                onClick={onPrevious}
                data={data?.previous}
                disabled={selectedIndex === 0}
                config={config?.previous?.button}
              />
            </div>
          )}
          {config?.close?.visible && (
            <div style={closeStyles} className={clsx('flex', closeClassName)}>
              <Button
                onClick={onClose}
                data={data?.close}
                config={config?.close?.button}
              />
            </div>
          )}
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
};

export { ZoomDialog };
