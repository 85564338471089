import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import { useOtpInputSlot } from './hooks';

import type { FC } from 'react';
import type { OtpInputSlotProps } from './OtpInput.types';

const OtpInputSlot: FC<OtpInputSlotProps> = ({ widget, slot, index }) => {
  const { fragment, fragmentData } = useOtpInputSlot({ widget, slot, index });
  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell, cIndex) => (
        <Cell key={cell.id ?? cIndex} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  );
};

OtpInputSlot.displayName = 'OtpInputSlot';

export { OtpInputSlot };
