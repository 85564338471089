import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { isVisible } from '../../../utils';

import type { ElemasonPage, ElemasonRoot, Maybe } from '@tectonic/types';

const getHeader = (root: ElemasonRoot, page: Maybe<ElemasonPage>) => {
  const { header, rootNavbars } = page?.config ?? {};
  if (!isNil(header)) {
    return header;
  }
  if (rootNavbars?.header && !isVisible(rootNavbars.header ?? {})) {
    return null;
  }
  return root.header;
};

const getFooter = (root: ElemasonRoot, page: Maybe<ElemasonPage>) => {
  const { footer, rootNavbars } = page?.config ?? {};
  if (!isNil(footer)) {
    return footer;
  }
  if (rootNavbars?.footer && !isVisible(rootNavbars.footer ?? {})) {
    return null;
  }
  return root.footer;
};

const useNavbars = (root: ElemasonRoot, page: Maybe<ElemasonPage>) =>
  useMemo(
    () => ({
      header: getHeader(root, page),
      footer: getFooter(root, page),
    }),
    [root, page]
  );

export { useNavbars };
