import axios from 'axios';

import type {
  CollectionProductSearchFacetsApiRouteActionPayload,
  CollectionProductSearchFacetsApiRouteActionResponse,
  CollectionProductSearchFacetsApiRouteLoaderResponse,
  CollectionTopLineFilterApiRoutActionPayload,
  CollectionTopLineFilterApiRouteActionResponse,
  ProductsSearchFacetsApiRouteActionPayload,
  ProductsSearchFacetsApiRouteActionResponse,
  ProductsSearchFacetsApiRouteLoaderResponse,
  SearchConfigApiRouteLoaderResponse,
  SearchConfigParams,
  SearchParams,
  SearchSuggestionApiRouteActionResponse,
} from '@tectonic/types';

// TODO: Modify it accordingly when we have actual apis.
const getSearchSuggestions = async (
  query: string
): Promise<SearchSuggestionApiRouteActionResponse> => {
  const response = await axios.get(`/api/search/suggestions`);
  return response.data;
};

const getSearchConfig = async (
  params: SearchConfigParams
): Promise<SearchConfigApiRouteLoaderResponse> => {
  const response = await axios.get(`/api/search/config`, { params });
  return response.data;
};

const getProductSearchFacets = async (
  params: SearchParams
): Promise<ProductsSearchFacetsApiRouteLoaderResponse> => {
  const response = await axios.get(`/api/products/search/facets`, { params });
  return response.data;
};

const updateProductSearchFacets = async (
  payload: ProductsSearchFacetsApiRouteActionPayload,
  params: object
): Promise<ProductsSearchFacetsApiRouteActionResponse> => {
  const response = await axios.post(`/api/products/search/facets`, payload, {
    params,
  });
  return response.data;
};

const updateCollectionProductSearchFacets = async (
  slug: string,
  payload: CollectionProductSearchFacetsApiRouteActionPayload,
  params: object
): Promise<CollectionProductSearchFacetsApiRouteActionResponse> => {
  const response = await axios.post(
    `/api/collections/${slug}/product/search/facets`,
    payload,
    {
      params,
    }
  );
  return response.data;
};

const getCollectionProductSearchFacets = async (
  slug: string,
  params: SearchParams
  // TODO: Use collection route typings
): Promise<CollectionProductSearchFacetsApiRouteLoaderResponse> => {
  const response = await axios.get(
    `/api/collections/${slug}/product/search/facets`,
    { params }
  );
  return response.data;
};

const toggleCollectionTopLineFilter = async (
  slug: string,
  payload: CollectionTopLineFilterApiRoutActionPayload,
  params: object
): Promise<CollectionTopLineFilterApiRouteActionResponse> => {
  const response = await axios.post(
    `/api/collections/${slug}/product/search/top-line-filter`,
    payload,
    { params }
  );
  return response.data;
};

export {
  getCollectionProductSearchFacets,
  getProductSearchFacets,
  getSearchConfig,
  getSearchSuggestions,
  toggleCollectionTopLineFilter,
  updateCollectionProductSearchFacets,
  updateProductSearchFacets,
};
