import clsx from 'clsx';
import { forwardRef } from 'react';
import { useCarouselContext } from './CarouselContext';
import { carouselSlideContainer } from './variants';

import type { ForwardRefRenderFunction } from 'react';
import type { CarouselViewportProps } from './Carousel.types';

const Viewport: ForwardRefRenderFunction<
  HTMLDivElement,
  CarouselViewportProps
> = ({ children }, ref) => {
  const { axis } = useCarouselContext();
  const { base } = carouselSlideContainer({ axis });

  return (
    // Main embla carousel scroll container aka viewport.
    <div
      ref={ref}
      className={clsx('w-full overflow-hidden', axis === 'y' ? 'h-full' : '')}
    >
      {/* Slides */}
      <div className={clsx('h-full', base())}>{children}</div>
    </div>
  );
};

export default forwardRef(Viewport);
