import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { isBoolean } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import useVideoSource from '../../hooks/useVideoSource';

import type { Asset, VideoConfig } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface VideoAssetProps extends Omit<ComponentProps<'video'>, 'src'> {
  paused?: boolean;
  asset?: Asset;
  config?: VideoConfig;
}

const VideoAsset: FC<VideoAssetProps> = ({
  asset,
  config,
  loop = true,
  muted = true,
  autoPlay = true,
  playsInline = true,
  className,
  style,
  paused,
  ...otherProps
}) => {
  const innerRef = useRef<HTMLVideoElement>(null);
  const [isInitialized, setIsInitialized] = useState(
    !config?.showOnIntersection
  );
  const original = useVideoSource(asset)[2];

  const [cStyle, cClassName] = useStyleConfigV2(config ?? {});

  useEffect(() => {
    if (!isBoolean(paused)) {
      return;
    }

    const video = innerRef.current!;

    if (paused) {
      video.pause();
    } else {
      video.play();
    }
  }, [paused, innerRef]);

  const callback: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsInitialized(true);
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    const options = {
      root: document.querySelector('#scrollArea'),
      threshold: 0,
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(innerRef.current!);
  }, []);

  if (!asset) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      preload="none"
      loop={loop}
      muted={muted}
      ref={innerRef}
      autoPlay={autoPlay}
      playsInline={playsInline}
      style={{ ...style, ...cStyle, objectFit: config?.resizeMode }}
      className={clsx(className, cClassName)}
      poster={asset?.streamThumbnail}
      {...(config?.showOnIntersection && !isInitialized
        ? {}
        : { src: original })}
      {...otherProps}
    />
  );
};

export { VideoAsset };
