import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getCollectionProductSearchFacets,
  getProductSearchFacets,
  updateCollectionProductSearchFacets,
  updateProductSearchFacets,
} from '@tectonic/remix-client-network';
import { withAdditionalFilters } from '@tectonic/utils';
import { omit, uniqueId } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useElemasonContext } from '../../../contexts';
import { queryKeys } from '../../../queryKeys';
import { useAccessorValue } from '../../useAccessorValue';

import type {
  CollectionProductSearchFacetsApiRouteActionResponse,
  CollectionProductSearchFacetsApiRouteLoaderResponse,
  Nil,
  ProductsSearchFacetsApiRouteActionPayload,
  ProductsSearchFacetsApiRouteActionResponse,
  ProductsSearchFacetsApiRouteLoaderResponse,
  SearchConfigParams,
  SearchFilter,
  SearchParams,
} from '@tectonic/types';
import type { UseSearchFacetsArgs } from './search.types';

const defaultAccessor = {};

const isCollectionEntity = (
  searchConfig?: SearchConfigParams
): searchConfig is SearchConfigParams => {
  const { entity, entityIdentifier } = searchConfig ?? {};
  return entity === 'COLLECTION' && !!entityIdentifier;
};

const getSearchFacets = (
  searchParams: SearchParams,
  searchConfig?: SearchConfigParams
): Promise<
  | ProductsSearchFacetsApiRouteLoaderResponse
  | CollectionProductSearchFacetsApiRouteLoaderResponse
> => {
  if (isCollectionEntity(searchConfig)) {
    return getCollectionProductSearchFacets(
      searchConfig.entityIdentifier!,
      searchParams
    );
  }

  return getProductSearchFacets(searchParams as SearchParams);
};

const updateSearchFacets = (
  payload: ProductsSearchFacetsApiRouteActionPayload,
  params: object,
  searchConfig?: SearchConfigParams
): Promise<
  | ProductsSearchFacetsApiRouteActionResponse
  | CollectionProductSearchFacetsApiRouteActionResponse
> => {
  if (isCollectionEntity(searchConfig)) {
    return updateCollectionProductSearchFacets(
      searchConfig.entityIdentifier!,
      payload,
      params
    );
  }
  return updateProductSearchFacets(payload, params);
};

const useSearchFacets = (
  config?: UseSearchFacetsArgs,
  implicitFilters?: SearchFilter[]
) => {
  const entity = config?.entity ?? 'PLP';
  const { searchParams } = useElemasonContext();
  const entityIdentifier = useAccessorValue<string>(
    config?.entityIdentifier ?? defaultAccessor
  );

  // in generate new query key when component mount to avoid stale data.
  const cacheKeys = useMemo(() => [...queryKeys.facets(), uniqueId()], []);

  const queryFn = useCallback(async () => {
    const response = await getSearchFacets(
      {
        ...omit(searchParams, 'page'),
        filterExpressions: JSON.stringify(
          withAdditionalFilters(
            searchParams.filterExpressions
              ? JSON.parse(searchParams.filterExpressions) ?? []
              : [],
            implicitFilters ?? []
          )
        ),
      } as any,
      {
        entity,
        entityIdentifier,
      }
    );
    return response.data;
  }, [entity, entityIdentifier]);

  const {
    isLoading: isLoadingFacets,
    data: initialFacets,
    isError: isFacetsLoadError,
  } = useQuery({ queryFn, queryKey: cacheKeys });

  const executedQueryRef = useRef<SearchParams>({} as SearchParams);

  const mutationFn = useCallback(
    async (
      payload: ProductsSearchFacetsApiRouteActionPayload & {
        params?: Nil<object>;
      }
    ) => {
      const fParams = payload.params ?? executedQueryRef.current;
      const response = await updateSearchFacets(payload, fParams, {
        entity,
        entityIdentifier,
      });
      return response.data;
    },
    [entity, entityIdentifier]
  );

  const {
    mutate,
    mutateAsync,
    isPending: isUpdatingFacets,
    data: updatedFacets,
    isError: isFacetsUpdateError,
  } = useMutation({ mutationFn, mutationKey: cacheKeys });

  const onResetFacets = useCallback(() => {
    // We treat url as default state in case of plain plp.
    const params = isCollectionEntity({ entity, entityIdentifier })
      ? undefined
      : searchParams;
    mutate({ intent: 'reset', filters: [], params });
  }, [entity, entityIdentifier, searchParams]);

  const onPreviewFacets = useCallback(
    (filters: SearchFilter[]) => {
      const { filterBy, ...params } = searchParams;
      mutateAsync({ intent: 'preview', filters, params });
    },
    [mutationFn]
  );

  const onApplyFacets = useCallback(
    async (filters: SearchFilter[]) => {
      const { filterBy, ...params } = searchParams;
      return mutateAsync({ intent: 'apply', filters, params });
    },
    [mutationFn]
  );

  const isLoading = isLoadingFacets || isUpdatingFacets;

  const data = updatedFacets ?? initialFacets;

  const isError = isFacetsLoadError || isFacetsUpdateError;

  useEffect(() => {
    executedQueryRef.current = (updatedFacets?.executedQuery ??
      {}) as SearchParams;
  }, [executedQueryRef, updatedFacets]);

  return {
    isLoading,
    data,
    isError,
    onResetFacets,
    onPreviewFacets,
    onApplyFacets,
  };
};

export { updateSearchFacets, useSearchFacets };
