import { isNil } from "lodash-es";

// TODO check if any other usecase move it to global utils
const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return undefined;
};

const setCookie = (cname: string, cvalue: string, exdays?: number) => {
  let expires = '';
  if (!isNil(exdays)) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = `expires=${d.toUTCString()};`;
  }
  const domain = `domain=.${globalThis.env.ROOT_DOMAIN};`;
  document.cookie = `${cname}=${encodeURIComponent(cvalue)}; ${expires} ${domain} SameSite=None; Secure; path=/`;
};

function eraseCookie(cname: string) {
  const domain = `domain=.${globalThis.env.ROOT_DOMAIN};`;
  document.cookie = `${cname}=; ${domain} path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export { eraseCookie, getCookie, setCookie };
