import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';

import type {
  Product,
  ProductBundleVariantsContainerConfig,
} from '@tectonic/types';
import type { FC, MouseEventHandler } from 'react';

interface ProductBundleVariantsProps {
  product: Product;
  onClick?: MouseEventHandler;
  config?: ProductBundleVariantsContainerConfig;
}

const ProductBundleVariants: FC<ProductBundleVariantsProps> = ({
  product,
  config,
  onClick,
}) => {
  const [style, className] = useStyleConfigV2(config?.container);

  return (
    <Clickable
      style={style}
      onClick={onClick}
      className={clsx('flex', className)}
      title="Product bundle variants dropdown"
    >
      <Text
        config={config?.text}
        data={`${product.productComponents?.length} Products`}
      />
    </Clickable>
  );
};

export { ProductBundleVariants };
