import { isEmpty } from 'lodash-es';

import type { ParsedError } from '@tectonic/errors';
import type { Nil, PhoneNumber, User } from '@tectonic/types';

const toPhoneText = (phoneNumber: PhoneNumber): Nil<string> => {
  const { country } = phoneNumber;
  if (!country) {
    throw {
      response: {
        data: {
          error: {
            code: 'COUNTRY_REQUIRED',
            message: 'Country is required.',
            reasons: [
              {
                code: 'UNKNOWN',
                cause: { formattedMessage: 'Country is required.' },
              },
            ],
          } as ParsedError,
        },
      },
    };
  }
  const { stdCodes } = country;
  const phone = phoneNumber.phone?.trim() ?? '';
  const [stdCode] = stdCodes;
  return phone ? `${stdCode}${phone}` : null;
};

const isVerificationRequired = (phoneNumber: PhoneNumber, user: User) => {
  const phone = toPhoneText(phoneNumber);
  if (isEmpty(phone)) {
    return false;
  }
  return user.phone !== phone;
};

export { isVerificationRequired, toPhoneText };
