import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { isOutOfStock } from '../../../utils';
import { Swatch } from '../../ColorSwatch/Swatch';
import { Icon } from '../../Icon';

import type {
  ProductVariant,
  VariantColorSelectorConfig,
} from '@tectonic/types';
import type { FC } from 'react';

interface ColorSelectorProps {
  variants: ProductVariant[];
  config?: VariantColorSelectorConfig;
  selectedVariant?: ProductVariant;
  onSelect: (variant: ProductVariant) => void;
  attribute: 'basisAttr1' | 'basisAttr2' | 'basisAttr3';
}

const ColorSelector: FC<ColorSelectorProps> = ({
  config,
  variants,
  onSelect,
  attribute,
  selectedVariant,
}) => {
  const { style, className } = useStyleConfig(config?.container ?? {});
  const { style: iStyle, className: iClassName } = useStyleConfig(
    config?.item ?? {}
  );
  const { style: sStyle, className: sClassName } = useStyleConfig(
    config?.selectedItem ?? {}
  );
  const { style: siStyle, className: siClassName } = useStyleConfig(
    config?.swatch?.itemContainer ?? {}
  );

  return (
    <div style={style} className={clsx('flex', className)}>
      {variants?.map((variant) => (
        <Clickable
          title="Color Selector Item"
          style={variant.id === selectedVariant?.id ? sStyle : iStyle}
          key={variant.id}
          onClick={() => onSelect(variant)}
          className={clsx(
            'flex shrink-0',
            variant.id === selectedVariant?.id ? sClassName : iClassName
          )}
        >
          <div className="relative">
            <Swatch
              config={config?.swatch}
              color={{
                name: variant[`${attribute}Value`]!,
                code: variant[`${attribute}DisplayConf`]?.color,
              }}
            />
            {isOutOfStock(variant.stockStatus) && (
              <Icon
                style={siStyle}
                symbol={config?.unavailableIcon!}
                config={config?.unavailableIconConfig}
                className={clsx('absolute inset-0', siClassName)}
              />
            )}
          </div>
          {selectedVariant?.id === variant.id && (
            <Text
              config={config?.selectedItemText}
              data={variant[`${attribute}Display`]}
            />
          )}
        </Clickable>
      ))}
    </div>
  );
};

export { ColorSelector };
