import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { forwardRef } from 'react';

import type { UiSchema } from '@rjsf/utils';
import type { ComponentProps, ForwardRefRenderFunction } from 'react';

export { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
export type {
  RJSFSchema,
  RegistryWidgetsType,
  UiSchema,
  WidgetProps,
} from '@rjsf/utils';

export {
  Controller,
  Form,
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';

interface JSONSchemaFormProps
  extends Omit<ComponentProps<typeof Form>, 'validator' | 'uiSchema'> {
  // Currently, uiSchema doesn't have support for layout. It relies on the
  // properties of the schema to determine the layout. Since properties is an object,
  // there's no way to ensure the order in which the properties are rendered.
  // https://github.com/rjsf-team/react-jsonschema-form/issues/2174
  // As a work around, we are adding a custom field `ui:layout` to the uiSchema
  // to specify the order in which the fields should be rendered. It is a temporary
  // workaround until the issue is resolved.
  uiSchema?: UiSchema & { 'ui:layout'?: string[] };
}

const JSONSchemaForm: ForwardRefRenderFunction<any, JSONSchemaFormProps> = (
  props,
  ref
) => <Form ref={ref} validator={validator} {...props} />;

const ExoticJSONSchemaForm = forwardRef(JSONSchemaForm);

export { ExoticJSONSchemaForm as JSONSchemaForm };

export type { JSONSchemaFormProps };

export type {
  FormSubmitHandler,
  SubmitHandler,
  UseControllerProps,
  UseFormReturn,
  UseFormSetError,
} from 'react-hook-form';
