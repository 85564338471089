import { useStyleConfigV2 } from '@tectonic/elemason-components';
import { memo, type FC } from 'react';
import { Icon } from '../../components';
import { useActionDispatch } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonIconWidget } from '@tectonic/types';

interface ElemasonIconWidgetProps {
  widget: ElemasonIconWidget;
}

const IconWidget: FC<ElemasonIconWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const dispatch = useActionDispatch();
  const [style, className] = useStyleConfigV2(config);
  const symbol = useHaloScript(
    typeof data?.symbol === 'string' ? undefined : data?.symbol
  );

  const onClick = () => {
    if (actions?.onClick) {
      actions.onClick.forEach((action) => dispatch(action));
    }
  };

  return (
    <Icon
      onClick={onClick}
      style={style}
      className={className}
      config={config?.icon}
      symbol={(typeof data?.symbol === 'string' ? data.symbol : symbol)!}
    />
  );
};

const MemoizedIconWidget = memo(IconWidget);

export { MemoizedIconWidget as IconWidget };
