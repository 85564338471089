import clsx from 'clsx';
import { forwardRef } from 'react';

import type { ForwardRefRenderFunction } from 'react';
import type { ClickableProps } from './Clickable.types';

const Clickable: ForwardRefRenderFunction<HTMLDivElement, ClickableProps> = (
  { className, ...divProps },
  ref
) => (
  // TODO: Add other accessibility attributes.
  <div ref={ref} role="button" {...divProps} className={clsx(className)} />
);

Clickable.displayName = 'Clickable';

const ExoticClickable = forwardRef(Clickable);

export { ExoticClickable as Clickable };
