import { compact } from 'lodash-es';
import { Children, forwardRef, useMemo } from 'react';
import Slides from './Slides';
import Viewport from './Viewport';

import type { ForwardRefRenderFunction, ReactElement } from 'react';
import type { CarouselStageProps } from './Carousel.types';

const useChildren = (children: CarouselStageProps['children']) =>
  useMemo(() => {
    // TODO: Verify the filtering of children in prod build.
    const slides: ReactElement[] = [];
    const others: ReactElement[] = [];

    const compactChildren = Array.isArray(children)
      ? compact(children)
      : children;

    Children.forEach(compactChildren, (child) => {
      if (child.type === Slides) {
        slides.push(child);
      } else {
        others.push(child);
      }
    });
    return { slides, others };
  }, [children]);

// `CarouselStage` component acts as glue for carousel, dots, scroll buttons.
const CarouselStage: ForwardRefRenderFunction<
  HTMLDivElement,
  CarouselStageProps
> = ({ className, children, style }, ref) => {
  const { slides, others } = useChildren(children);
  return (
    <div className={className} style={style}>
      <Viewport ref={ref}>{slides}</Viewport>
      {others}
    </div>
  );
};

CarouselStage.displayName = 'CarouselStage';

export default forwardRef(CarouselStage);
