import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { isBlank, populate } from '@tectonic/utils';
import { memo, useMemo, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import {
  useActionDispatch,
  useFragmentValue,
  useHaloScript,
  usePageFragment,
  useSharedLocalState,
} from '../../hooks';

import type { ElemasonResourceWidget } from '@tectonic/types';

interface ResourceWidgetProps {
  widget: ElemasonResourceWidget;
}

const ResourceWidget: FC<ResourceWidgetProps> = ({
  widget: { data, id: widgetId, actions },
}) => {
  const resource = useHaloScript(data?.resource);
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const placeholders = useHaloScript<Record<string, string>>(
    data?.placeholders
  );
  let isEnabled = useHaloScript(data?.enabled);
  isEnabled = isEnabled ?? true;

  const resourceKey = useHaloScript(data?.resourceKey);
  const dispatch = useActionDispatch();
  const stateKey = useHaloScript(data?.stateKey);
  const { setSharedState } = useSharedLocalState(stateKey ?? '');

  if (resource?.url && !resource.url.startsWith('/')) {
    throw new Error('External urls are not supported.');
  }

  const url = populate(resource?.url!, placeholders ?? {});

  const {
    isError,
    isLoading,
    isPending,
    data: response,
  } = useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!url && !!isEnabled,

    queryKey: resourceKey ?? [
      'resource',
      widgetId,
      url,
      JSON.stringify(resource?.search),
    ],
    queryFn: async () => {
      actions?.onRequest?.forEach((action) => dispatch(action, undefined));
      const aResponse = await remixApi
        .getInstance()
        .get(url, { params: resource?.search });
      if (!isBlank(stateKey)) {
        setSharedState({ data: aResponse.data });
      }
      return aResponse;
    },
  });

  const value = useMemo(
    () => ({
      data: response?.data,
      isError,
      isLoading,
      isPending,
    }),
    [response, isError, isLoading, isPending]
  );

  const fragmentData = fragmentValue(value);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

ResourceWidget.displayName = 'ResourceWidget';

const MemoizedResourceWidget = memo(ResourceWidget);

export { MemoizedResourceWidget as ResourceWidget };
