import { toTailwindUtility } from '@tectonic/utils';
import { compact } from 'lodash-es';

import type { ShadowConfig } from '@tectonic/types';

const useShadowConfig = (config: ShadowConfig) => {
  const { shadowUp, shadowCenter, shadowDown } = config;

  // TODO: add shadow-up and shadow-down in tailwind.config.js
  const shadowClasses = [
    toTailwindUtility('shadow', shadowUp === 'base' ? '' : shadowUp),
    toTailwindUtility('shadow', shadowDown === 'base' ? '' : shadowDown),
    toTailwindUtility('shadow', shadowCenter === 'base' ? '' : shadowCenter),
  ];

  return compact(shadowClasses);
};

export { useShadowConfig };
