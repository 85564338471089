import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { useThemeContext } from '@tectonic/uikit';
import clsx from 'clsx';
import { keys } from 'lodash-es';
import { Icon } from '../Icon';

import type {
  ElemasonProductRatingsWidget,
  ProductReviewStats,
} from '@tectonic/types';
import type { FC } from 'react';

type ProductRatingsProps = {
  isError?: boolean;
  isLoading?: boolean;
  config?: ElemasonProductRatingsWidget['config'];
  data?: ProductReviewStats;
  icon: string;
  partialIcon: string;
};

const ProductRatings: FC<ProductRatingsProps> = ({
  data,
  config,
  icon,
  partialIcon,
  isError,
  isLoading,
}) => {
  const { colors } = useThemeContext();

  const { style, className } = useStyleConfig(config?.container ?? {});

  const { overallLevel: overallLevelConfig, ratingLevel: ratingLevelConfig } =
    config || {};

  const {
    style: overallRatingTextContStyle,
    className: overallRatingTextContClass,
  } = useStyleConfig(overallLevelConfig?.ratingText?.container ?? {});

  const {
    style: overallRatingIconContStyle,
    className: overallRatingIconContClass,
  } = useStyleConfig(overallLevelConfig?.ratingIcon?.container ?? {});

  const {
    style: overallRatingsCountTextContStyle,
    className: overallRatingsCountTextContClass,
  } = useStyleConfig(overallLevelConfig?.ratingCount?.container ?? {});

  const { style: overallStatsContStyle, className: overallStatsContClass } =
    useStyleConfig(config?.overallLevel?.container ?? {});

  const { style: ratingLevelContStyle, className: ratingLevelContClass } =
    useStyleConfig(config?.ratingLevel?.container ?? {});

  const {
    style: ratingLevelTextContStyle,
    className: ratingLevelTextContClass,
  } = useStyleConfig(config?.ratingLevel?.ratingText?.container ?? {});

  const {
    style: ratingLevelCountContStyle,
    className: ratingLevelCountContClass,
  } = useStyleConfig(config?.ratingLevel?.ratingCount?.container ?? {});

  const {
    style: ratingLevelIconContStyle,
    className: ratingLevelIconContClass,
  } = useStyleConfig(config?.ratingLevel?.ratingIcon?.container ?? {});

  if (!data) {
    return null;
  }

  const { average, count: totalCount, countByScore } = data || {};

  if (totalCount === 0) {
    return null;
  }

  const rating = Array.from({ length: Math.floor(average!) });

  // TODO move away from explicit w-full/justify/align

  return (
    <div style={style} className={clsx('flex w-full flex-row', className)}>
      <div
        className={clsx('flex w-full flex-col', overallStatsContClass)}
        style={overallStatsContStyle}
      >
        <div
          className={clsx('flex w-full flex-col', overallRatingTextContClass)}
          style={overallRatingTextContStyle}
        >
          <Text
            data={`${average}`}
            config={overallLevelConfig?.ratingText?.text}
          />
        </div>
        <div
          className={clsx('flex w-full flex-row', overallRatingIconContClass)}
          style={overallRatingIconContStyle}
        >
          {rating.map((_, idx) => (
            <Icon
              // eslint-disable-next-line react/no-array-index-key
              key={`overall-rating-star-${idx}`}
              symbol={icon}
              config={overallLevelConfig?.ratingIcon?.icon}
            />
          ))}
          {Number.isInteger(data.average) ? null : (
            <Icon
              key="overall-rating-star-partial"
              symbol={partialIcon}
              config={overallLevelConfig?.ratingIcon?.icon}
            />
          )}
        </div>
        <div
          className={clsx(
            'flex w-full flex-col',
            overallRatingsCountTextContClass
          )}
          style={overallRatingsCountTextContStyle}
        >
          <Text
            data={
              totalCount > 1 ? `${totalCount} Ratings` : `${totalCount} Rating`
            }
            config={overallLevelConfig?.ratingCount?.text}
          />
        </div>
      </div>
      <div
        className={clsx('flex w-full flex-col', ratingLevelContClass)}
        style={ratingLevelContStyle}
      >
        {keys(countByScore)
          .sort()
          .reverse()
          .map((ratingValue) => {
            const progressFullWidth = 130;
            const ratingCount = countByScore[ratingValue];
            const ratingProgress = Math.ceil(
              (ratingCount * progressFullWidth) / totalCount
            );
            return (
              <div
                className={clsx('flex w-full flex-col')}
                key={`rating-level-${ratingValue}`}
              >
                <div className={clsx('flex w-full flex-row items-center')}>
                  <div
                    className={clsx('flex flex-col', ratingLevelTextContClass)}
                    style={ratingLevelTextContStyle}
                  >
                    <Text
                      data={ratingValue}
                      config={ratingLevelConfig?.ratingText?.text}
                    />
                  </div>
                  <div
                    className={clsx('flex flex-col', ratingLevelIconContClass)}
                    style={ratingLevelIconContStyle}
                  >
                    <Icon
                      symbol={icon}
                      config={ratingLevelConfig?.ratingIcon?.icon}
                    />
                  </div>
                  <div
                    className={clsx('flex flex-col justify-center')}
                    style={{}}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="130"
                      height="6"
                      viewBox="0 0 130 6"
                      fill="none"
                    >
                      <rect
                        width={progressFullWidth}
                        height="6"
                        rx={config?.ratingLevel?.track?.rx ?? 3}
                        fill={
                          config?.ratingLevel?.backgroundFillColor
                            ? // @ts-ignore
                              colors[
                                config?.ratingLevel?.backgroundFillColor
                              ] ?? config?.ratingLevel?.backgroundFillColor
                            : '#E5E5E5'
                        }
                      />
                      <rect
                        width={ratingProgress}
                        height="6"
                        rx={config?.ratingLevel?.filledTrack?.rx ?? 3}
                        fill={
                          config?.ratingLevel?.fillColor
                            ? // @ts-ignore
                              colors[config?.ratingLevel?.fillColor] ??
                              config?.ratingLevel?.fillColor
                            : '#059669'
                        }
                      />
                    </svg>
                  </div>
                  <div
                    className={clsx('flex flex-col', ratingLevelCountContClass)}
                    style={ratingLevelCountContStyle}
                  >
                    <Text
                      data={`${ratingCount}`}
                      config={ratingLevelConfig?.ratingCount?.text}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { ProductRatings };
