import type { ElemasonId, SearchFilterExpression } from '@tectonic/types';

const queryKeys = {
  // Root
  root: () => ['root'],

  // Page
  page: (slug: string) => ['page', slug],

  // Looks
  look: (slug: string) => ['look', slug],
  looks: (params: Record<string, any>) => ['looks', params],
  reviews: (params: Record<string, any>) => ['reviews', params],
  collections: (params: Record<string, any>) => ['collections', params],

  // Product Looks
  productLooks: (slug: string) => ['productLooks', slug],

  // Cart
  cart: () => ['cart'],
  cartQuantity: () => ['cart', 'quantity'],
  cartFreeGifts: () => ['cart', 'freeGifts'],
  cartDiscounts: (type: string) => ['cart', 'discounts', type],

  // Drawers
  drawer: (slug: string) => ['drawer', slug],

  // Countries
  countries: () => ['countries'],
  countryDetails: (code: string) => ['country', code],

  // Addresses
  addresses: () => ['addresses'],

  // Search
  facets: () => ['searchFacets'],
  productInfiniteSearch: (
    routeParams: Record<string, unknown>,
    searchParams: Record<string, unknown>
  ) => [
    'search',
    'product',
    JSON.stringify(routeParams),
    JSON.stringify(searchParams),
  ],

  // Zip
  zipByCoords: () => ['zip-co'],
  isValidZip: (zip: string | number) => ['zip', zip],

  // Products
  product: (slug: string) => ['product-', slug],
  recentlyViewedProducts: () => ['product', 'recentlyViewed'],
  productSuggestions: () => ['product', 'suggestions'],
  frequentlyBoughtTogether: (slug: string) => [
    'product',
    'frequentlyBoughtTogether',
    slug,
  ],
  productRecommendations: (identifier?: string) => [
    'product',
    'recommendations',
    identifier,
  ],
  productSearch: (source: any, widgetId: ElemasonId) => [
    'product',
    'search',
    JSON.stringify(source),
    widgetId,
  ],
  collectionSearch: (collection: string, widgetId: ElemasonId) => [
    'collection-search',
    collection,
    widgetId,
  ],
  collection: (slug: string) => ['collection', slug],

  // Serviceability
  serviceability: (slug: string, zipcode: string) => [
    'serviceability',
    slug,
    zipcode,
  ],

  // ratings
  ratingsForProduct: (slug?: string) => ['ratings', slug],

  // reviews
  reviewsForProduct: (slug?: string) => ['reviews', slug],
  reviewsSearchForProduct: (
    slug?: string,
    sortBy?: string,
    filterExpressions?: SearchFilterExpression[]
  ) => ['reviewsSearch', slug, filterExpressions],
  reviewsSearchConfig: () => ['reviewsSearchConfig'],

  // Product Associations
  productPromotionsForVariant: (
    slug: string | undefined,
    variantId: string | undefined
  ) => ['promotions', slug, variantId],
  productProfileSchema: (slug: string) => ['productProfileSchema', slug],

  // Orders
  orders: () => ['orders'],
  order: (orderId: string) => ['order', orderId],

  // Tabs
  tab: (slug: string) => ['tab', slug],

  // Wishlist
  wishlist: () => ['wishlist'],

  // Profile Data
  profiles: () => ['profiles'],
  profileData: () => ['profileData'],

  // user
  user: () => ['user'],
  userProfileSchema: () => ['userProfileSchema'],
};

export { queryKeys };
