import { compact, pick, uniq } from 'lodash-es';
import { isImage } from '../../media';
import {
  getPdpUrl,
  removeParentBreadcrumb,
  sanitizeParentMeta,
  toAggregateRating,
  toAssetUrl,
  toAssetUrls,
  toProductAttributes,
  toProductAvailability,
} from './utils';

import type { DehydratedState } from '@tanstack/react-query';
import type { Asset, Collection, Product } from '@tectonic/types';
import type {
  MetaFunctionArgs,
  MetaFunctionUrls,
  SeoTemplates,
} from './meta.types';

const getMetadata = (dehydratedState: DehydratedState) => {
  // temp changes. solve it properly
  const queries = dehydratedState?.queries ?? [];
  const [aux, productQuery] = queries;
  const product = (productQuery?.state?.data as any)?.data?.product;
  const collection = (productQuery?.state?.data as any)?.data?.collection;
  return { product, collection };
};

const getOgTags = (
  product: Product,
  seoTemplates: SeoTemplates,
  urls: MetaFunctionUrls
) => {
  const asset = product.assetMap.gallery.find((x: Asset) =>
    isImage(x.mimeType)
  );
  const assetUrl = asset ? toAssetUrl(asset, urls.damUrl) : undefined;
  const pdpUrl = getPdpUrl(product, urls);

  const imageTags = assetUrl
    ? [
        {
          property: 'og:image:secure_url',
          itemProp: 'image',
          content: assetUrl,
        },
        {
          property: 'og:image',
          itemProp: 'image',
          content: assetUrl,
        },
      ]
    : [];

  const title = product.seoFields?.name ?? product.title;

  const description = product.seoFields?.description ?? product.description;

  const titleTags = title ? [{ title }] : [];

  const descriptionTags = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:description',
      content: description,
    },
  ];

  const keywordsTag = [
    {
      name: 'keywords',
      content: `${product.title}, Vaaree, vaaree.com`,
    },
  ];

  const priceTags = [
    {
      name: 'og:price:amount',
      content: `${product.price?.amount}`,
    },
    {
      name: 'og:price:currency',
      content: `${product.price?.currencyCode}`,
    },
  ];

  return [
    { tagName: 'link', rel: 'canonical', href: pdpUrl },
    ...titleTags,
    ...descriptionTags,
    ...keywordsTag,
    ...imageTags,
    ...priceTags,
    { property: 'og:url', content: pdpUrl },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'product' },
  ];
};

const getCommonProductJsonLd = (product: Product, urls: MetaFunctionUrls) => {
  const pdpUrl = getPdpUrl(product, urls);
  const productJsonLd = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    '@id': pdpUrl,
    name: product.title,
    url: pdpUrl,
    description: product.description,
    image: toAssetUrls(product.assetMap.gallery, urls.damUrl),
    brand: { '@type': 'Brand', name: product.brand },
  };

  return productJsonLd;
};

const getProductJsonLdForDefaultVariant = (
  product: Product,
  urls: MetaFunctionUrls
) => {
  const commonData = getCommonProductJsonLd(product, urls);
  const variant = product.variants?.[0];
  if (!variant) {
    return [commonData];
  }

  const aggregateRating = toAggregateRating(product);

  const productJsonLd: Record<string, unknown> = {
    ...commonData,
    sku: variant.sku,
    mpn: variant.sku,
    offers: {
      '@type': 'Offer',
      sku: variant.sku,
      priceCurrency: variant.price.currencyCode,
      price: variant.price.amount,
      itemCondition: 'https://schema.org/NewCondition',
      availability: toProductAvailability(variant),
      // TODO: Add support for this.
      // "shippingDetails": { "@id": "#shipping_policy" },
      // "hasMerchantReturnPolicy": { "@id": "#return_policy" }
      ...pick(variant.seoFields, ['priceValidUntil']),
    },
    ...pick(product.seoFields, ['releaseDate']),
  };

  if (aggregateRating) {
    productJsonLd.aggregateRating = aggregateRating;
  }

  return [productJsonLd];
};

const getProductJsonLdForVariants = (
  product: Product,
  urls: MetaFunctionUrls
) => {
  const commonData = getCommonProductJsonLd(product, urls);
  const variants = product.variants ?? [];
  if (variants.length === 0) {
    return [commonData];
  }

  const variesBy = uniq(
    variants.flatMap((variant) =>
      compact([
        variant.basisAttr1Name,
        variant.basisAttr2Name,
        variant.basisAttr3Name,
      ])
    )
  ).map((x) => `https://schema.org/${x.toLowerCase()}`);

  const hasVariant = variants.map((variant) => {
    const ld = {
      '@type': 'Product',
      sku: variant.sku,
      image: toAssetUrls(variant.assets ?? [], urls.damUrl),
      name: variant.title,
      aggregateRating: toAggregateRating(product),
      offers: {
        '@type': 'Offer',
        // TODO: this will change if we have separate page for a variant.
        url: commonData.url,
        priceCurrency: variant.price.currencyCode,
        price: variant.price.amount,
        itemCondition: 'https://schema.org/NewCondition',
        availability: toProductAvailability(variant),
        // TODO: Add this when we have it.
        // shippingDetails: { '@id': '#shipping_policy' },
        // hasMerchantReturnPolicy: { '@id': '#return_policy' },
        ...pick(variant.seoFields, ['priceValidUntil']),
      },
      ...toProductAttributes(variant),
    };

    return ld;
  });

  const productJsonLd = {
    ...commonData,
    ...pick(product.seoFields, ['releaseDate']),
    '@type': 'ProductGroup',
    productGroupId: product.id,
    variesBy,
    hasVariant,
  };
  return [productJsonLd];
};

const getBreadcrumbJsonLd = (
  product: Product,
  collection: Collection,
  urls: MetaFunctionUrls,
  seoTemplates: SeoTemplates
) => {
  const collectionName = collection?.name || 'All Products';
  const collectionUrl = collection?.slug
    ? `${new URL(urls.requestUrl).origin}/collections/${collection.slug}`
    : 'https://store.vaaree.com/collections/explore-all-products';

  const breadcrumb = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: new URL(urls.requestUrl).origin,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: collectionName,
        item: collectionUrl,
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: product.title,
        item: getPdpUrl(product, urls),
      },
    ],
  };

  return [breadcrumb];
};

const getJsonLdTags = ({
  product,
  collection,
  urls,
  seoTemplates,
}: {
  product: Product;
  collection: Collection | undefined;
  urls: MetaFunctionUrls;
  seoTemplates: SeoTemplates;
}) => {
  const breadcrumbs = getBreadcrumbJsonLd(
    product,
    collection!,
    urls,
    seoTemplates
  );

  const pJsonLds = product.hasDefaultVariant
    ? getProductJsonLdForDefaultVariant(product, urls)
    : getProductJsonLdForVariants(product, urls);

  return [...breadcrumbs, ...pJsonLds].map((ld) => ({ 'script:ld+json': ld }));
};

const productMetaFunction = (
  args: MetaFunctionArgs,
  seoTemplates: SeoTemplates
) => {
  const { data, matches } = args;
  const { dehydratedState, urls } = data!;

  if (!dehydratedState) {
    return matches.flatMap((match) => match.meta);
  }

  let parentMeta = sanitizeParentMeta(matches).filter(
    (tag) => tag.rel !== 'canonical'
  );
  // Remove parent Breadcrumb as its already getting added by getBreadcrumbJsonLd
  parentMeta = removeParentBreadcrumb(parentMeta);
  const { product, collection } = getMetadata(
    dehydratedState as DehydratedState
  );
  if (!product) {
    return matches.flatMap((match) => match.meta);
  }
  const ogTags = getOgTags(product!, seoTemplates, urls);
  const jsonLdTags = getJsonLdTags({ product, collection, seoTemplates, urls });
  return [...ogTags, ...parentMeta, ...jsonLdTags];
};

export { productMetaFunction };
