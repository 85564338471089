import type { SearchQuerySource } from '@tectonic/types';

const flattenSource = (source: SearchQuerySource) => {
  const fSource = {
    collection: source.collection,
    ...(source.query as object),
  } as any;

  // TODO: Get rid of it. We should be using toUrlSearchParams everywhere
  // so that we don't have to do this jing-bang. Presently, some widgets
  // pass this as is as payload.
  if (fSource.filterBy) {
    fSource.filterBy = JSON.stringify(fSource.filterBy);
  }
  return fSource;
};

export { flattenSource };
