import { useStyleConfig } from '@tectonic/elemason-components';
import { useActionDispatch } from '../../hooks';
import { ImageTick } from './ImageTick';
import { TextTick } from './TextTick';

import type { FC } from 'react';
import type { TickProps } from './Tick.types';

const Tick: FC<TickProps> = ({ config, tick, onClick }) => {
  const dispatch = useActionDispatch();
  const { style, className } = useStyleConfig({
    ...config?.image,
    ...tick.config?.image,
  });

  const handleOnClick = () => {
    onClick?.(tick);
    tick.actions?.onClick.forEach((action) => dispatch(action, tick));
  };

  if (tick.asset) {
    return (
      <ImageTick
        tick={tick}
        style={style}
        className={className}
        config={config?.image}
        onClick={handleOnClick}
      />
    );
  }

  return <TextTick tick={tick} config={config?.text} onClick={handleOnClick} />;
};

Tick.displayName = 'ElemasonTickerTick';

export { Tick };
