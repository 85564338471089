import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { type FC } from 'react';
import { Button } from '../../components';
import { useToast } from '../../core';
import { useActionDispatch, useHaloScript } from '../../hooks';
import { queryKeys } from '../../queryKeys';

import type { ElemasonRemoveFromCartWidget } from '@tectonic/types';

type RemoveFromCartWidgetProps = {
  widget: ElemasonRemoveFromCartWidget;
};

const RemoveFromCartWidget: FC<RemoveFromCartWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const actionDispatch = useActionDispatch();
  const cartLine = useHaloScript(data?.cartLine);

  const dispatchCartLineEvent = (
    eventName: AnalyticsCartEventNames,
    d?: Record<string, any>
  ) => {
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: eventName,
        data: d,
      },
    });
  };

  const deleteMutation = useMutation({
    mutationFn: () => {
      dispatchCartLineEvent(AnalyticsCartEventNames.PRODUCT_REMOVE_REQUEST, {
        quantity: cartLine!.quantity,
        entities: {
          product: cartLine!.productVariant.product,
          productVariant: cartLine!.productVariant,
        },
      });
      return remixApi.deleteCartLines([cartLine!]);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      dispatchCartLineEvent(AnalyticsCartEventNames.PRODUCT_REMOVE_SUCCESS, {
        entities: {
          cart: updatedCart,
          product: cartLine!.productVariant.product,
          productVariant: cartLine!.productVariant,
        },
        quantity: cartLine!.quantity,
      });
      showToast({ title: 'Product removed from cart' });
      actions?.onSuccess?.forEach((action) =>
        actionDispatch(action, undefined)
      );
    },

    onError: (error) => {
      const title = getErrorMessage(
        error,
        {},
        'Failed to remove product from cart'
      );

      dispatchCartLineEvent(AnalyticsCartEventNames.PRODUCT_REMOVE_ERROR, {
        error,
        entities: {
          product: cartLine!.productVariant.product,
          productVariant: cartLine!.productVariant,
        },
      });
      showToast({ title, duration: 5000 });
    },
  });

  const onDelete = () => {
    deleteMutation.mutate();
  };

  if (!cartLine) return null;

  return (
    <Button
      data={data}
      onClick={onDelete}
      config={config?.button}
      size={config?.button?.size}
      variant={config?.button?.variant}
      disabled={deleteMutation.isPending}
      modifier={config?.button?.modifier}
      isLoading={deleteMutation.isPending}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

export { RemoveFromCartWidget };
