import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import {
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import {
  ElemasonWidgetActionType,
  type CartAddLinePayload,
  type ElemasonLooksWidgetConfig,
  type ElemasonLooksWidgetData,
  type LookTypesense,
  type Product,
  type ProductVariant,
} from '@tectonic/types';
import clsx from 'clsx';
import { useCallback, useState, type ComponentProps, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { useToast } from '../../core/ElemasonEntry/Toast';
import { useActionDispatch } from '../../hooks';
import { queryKeys } from '../../queryKeys';
import { isOutOfStock } from '../../utils';
import { Button } from '../Button';
import { Look } from './Look';

interface LooksProps extends ComponentProps<'div'> {
  looks?: LookTypesense[];
  onEndReached?: () => void;
  data?: ElemasonLooksWidgetData;
  config?: ElemasonLooksWidgetConfig;
}

const Looks: FC<LooksProps> = ({ looks, data, config, onEndReached }) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();
  const { style, className } = useStyleConfig(
    config?.carousel?.dimension ?? {}
  );
  const [buttonStyle, buttonClasses] = useStyleConfigV2(
    config?.shopButton?.container ?? {}
  );

  const [selectedVariants, setSelectedVariants] = useState<
    Record<string, ProductVariant>
  >({});

  const actionDispatch = useActionDispatch();

  const [products, setProducts] = useState<Record<string, Product>>({});

  const mutation = useMutation({
    mutationFn: (lines: CartAddLinePayload[]) => {
      Object.entries(selectedVariants).forEach(([productId, selectedVariant]) =>
        actionDispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_REQUEST,
            data: {
              quantity: 1,
              entities: {
                productVariant: selectedVariant,
                product: products[productId],
              },
            },
          },
        })
      );
      return remixApi.addToCart(lines);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart) => {
      Object.entries(selectedVariants).forEach(([productId, selectedVariant]) =>
        actionDispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_SUCCESS,
            data: {
              quantity: 1,
              entities: {
                productVariant: selectedVariant,
                product: products[productId],
                cart: updatedCart,
              },
            },
          },
        })
      );
      showToast({ title: 'Product added to cart' });
    },
    onError: (error) => {
      const title = getErrorMessage(error, {}, 'Failed to add product to cart');

      Object.entries(selectedVariants).forEach(([productId, selectedVariant]) =>
        actionDispatch({
          type: ElemasonWidgetActionType.ANALYTICS,
          payload: {
            event: AnalyticsCartEventNames.PRODUCT_ADD_ERROR,
            data: {
              quantity: 1,
              entities: {
                productVariant: selectedVariant,
                product: products[productId],
              },
              error,
            },
          },
        })
      );
      showToast({ title });
    },
  });

  const onAddToCart = () => {
    const lines = Object.values(selectedVariants)
      .filter((variant) => !isOutOfStock(variant.stockStatus))
      .map((variant) => ({ quantity: 1, variantId: variant.id }));

    mutation.mutate(lines);
  };

  const look = looks?.[0];

  const handleInViewChange = useCallback(
    (inView: boolean) => inView && onEndReached?.(),
    [onEndReached]
  );

  const [inViewRef] = useInView({
    delay: 100,
    threshold: 0.7,
    onChange: handleInViewChange,
  });

  if (!look) return null;

  return (
    <div>
      <Look
        look={look}
        config={config}
        mediaStyle={style}
        setProducts={setProducts}
        mediaClassName={className}
        selectedVariants={selectedVariants}
        productFragment={data?.productFragment}
        setSelectedVariants={setSelectedVariants}
      />
      <div style={buttonStyle} className={clsx('flex flex-col', buttonClasses)}>
        <Button
          onClick={onAddToCart}
          data={data?.shopButton}
          config={config?.shopButton}
          isLoading={mutation.isPending}
        />
      </div>
      <div ref={inViewRef} />
    </div>
  );
};

export { Looks };
