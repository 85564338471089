import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';
import { Icon } from '../../Icon';

import type { FC } from 'react';
import type { SearchQueryProps } from './SearchSuggestions.types';

const DEFAULT_TEMPLATE = '{name}';

const SearchQuery: FC<SearchQueryProps> = ({
  config,
  data,
  onQueryClick,
  query,
}) => {
  const { container, startIcon, text } = config ?? {};

  const containerStyleConfig = useStyleConfig(container ?? {});

  const onClick = () => {
    onQueryClick(query);
  };

  return (
    <Clickable
      onClick={onClick}
      style={containerStyleConfig.style}
      className={clsx(
        'flex items-center gap-2',
        containerStyleConfig.className
      )}
    >
      {data.startIcon ? (
        <Icon config={startIcon} symbol={data.startIcon} />
      ) : null}
      <Text
        config={text}
        placeholders={{ name: query.name }}
        data={data.text ?? DEFAULT_TEMPLATE}
      />
    </Clickable>
  );
};

SearchQuery.displayName = 'SearchQuery';

export { SearchQuery };
