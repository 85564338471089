import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { CheckboxIndicator, CheckboxRoot } from '@tectonic/uikit';
import clsx from 'clsx';
import { useId } from 'react';
import { Icon } from '../Icon';

import type { FC } from 'react';
import type { CheckboxProps } from './Checkbox.types';

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  data,
  className,
  config,
  style,
}) => {
  const { container, icon, label } = config ?? {};
  const id = useId();

  const containerStyleConfig = useStyleConfig(container ?? {});

  return (
    <div
      style={{ ...containerStyleConfig.style, ...style }}
      className={clsx(
        'flex items-center',
        containerStyleConfig.className,
        className
      )}
    >
      <CheckboxRoot
        checked={checked}
        id={id}
        className="group shrink-0"
        onCheckedChange={onChange}
      >
        <Icon
          config={icon?.unchecked}
          symbol={data.icon.unchecked}
          className="group-radix-state-checked:hidden"
        />

        <CheckboxIndicator>
          <Icon config={icon?.checked} symbol={data.icon.checked} />
        </CheckboxIndicator>
      </CheckboxRoot>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={id}
        className={clsx('cursor-pointer', 'flex grow items-center')}
      >
        <Text config={label?.text} data={data.label.text} />
      </label>
    </div>
  );
};

Checkbox.displayName = 'Checkbox';

export { Checkbox };
