import { useAnalyticsStore } from '@tectonic/analytics';
import { type ElemasonWidgetAction, type HaloScript } from '@tectonic/types';
import { isHaloScript } from '@tectonic/utils';
import {
  useElemasonAnalyticsContext,
  useElemasonBlockContext,
  useElemasonCellContext,
  useElemasonContext,
  useElemasonPageContext,
} from '../contexts';
import { useElemasonActionContext } from '../contexts/ElemasonActionContext';
import { useElemasonFragmentContext } from '../contexts/ElemasonFragmentContext';
import { evaluateWithContext } from './useHaloScript';

import type { ElemasonAnalyticsContextType } from '../contexts';

type ActionDispatchInputType =
  | ElemasonWidgetAction
  | HaloScript<ElemasonWidgetAction>;

const useActionDispatch = () => {
  const cell = useElemasonCellContext();
  const block = useElemasonBlockContext();
  const actions = useElemasonActionContext();
  const { dispatch } = useElemasonPageContext();
  const { data, scope } = useElemasonPageContext();
  const fragmentContext = useElemasonFragmentContext();
  const analyticsContext = useElemasonAnalyticsContext();
  const { routeParams, searchParams, pathname } = useElemasonContext();
  const { previousPage, currentPage } = useAnalyticsStore((state) => ({
    previousPage: state.previousPage,
    currentPage: state.currentPage,
  }));

  return (actionScript: ActionDispatchInputType, actionContext: any = {}) => {
    try {
      const action = isHaloScript(actionScript)
        ? evaluateWithContext(
            cell,
            block,
            {
              ...routeParams,
              ...searchParams,
              pathname,
              __tt__internal_params: { routeParams, searchParams },
            },
            data,
            scope,
            fragmentContext,
            actionContext,
            undefined,
            actionScript,
            dispatch,
            actions
          )
        : actionScript;

      const enrichedAnalyticsContext = {
        ...analyticsContext,
        previousPage,
        currentPage,
      } as ElemasonAnalyticsContextType;

      if (action?.type) {
        // dispatch action only when we have a type.
        dispatch(action, enrichedAnalyticsContext);
      }
    } catch (error) {
      // TODO: @Sarat check this once.
      console.warn('Unable to parse halo-action', error);
    }
  };
};

export { useActionDispatch };

export type { ActionDispatchInputType as ActionDispatchActionInputType };
