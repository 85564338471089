import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { omit } from 'lodash-es';
import { ContentShimmer, Shimmer } from '../../Shimmer';

import type { ElemasonProductPromotionListWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ProductPromotionCardProps {
  config?: ElemasonProductPromotionListWidget['config'];
}

const ProductPromotionSkeletonCard: FC<ProductPromotionCardProps> = ({
  config,
}) => {
  const { style, className } = useStyleConfig(config?.card ?? {});

  return (
    <Shimmer className={clsx('flex flex-col')}>
      <div
        style={{ ...omit(style, 'backgroundColor', 'backgroundImage') }}
        className={clsx('flex h-full w-full flex-col', className)}
      >
        <ContentShimmer className="h-full w-full" />
        <ContentShimmer className="h-full w-full" />
        <ContentShimmer className="h-full w-full" />
        <ContentShimmer className="h-full w-full" />
      </div>
    </Shimmer>
  );
};

ProductPromotionSkeletonCard.displayName = 'ElemasonProductPromotionCard';

export { ProductPromotionSkeletonCard };
