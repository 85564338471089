import { memo, useMemo, type ComponentProps, type FC } from 'react';
import useImageSource from '../../hooks/useImageSource';

import type { Asset, ImageConfig } from '@tectonic/types';

interface ImageAssetProps extends Omit<ComponentProps<'img'>, 'alt' | 'src'> {
  asset?: Asset;
  config?: ImageConfig;
}

const generateImageSrcSet = (
  cdnUrl: string,
  breakpoints: number[],
  coverage: number = 1
) => {
  const sources = breakpoints.map((breakpoint) => {
    const url = new URL(cdnUrl);
    url.searchParams.append(
      'width',
      Math.round(breakpoint * coverage).toString()
    );
    return `${url.toString()} ${Math.round(breakpoint)}w`;
  });

  return sources.join(', ');
};

const ImageAsset: FC<ImageAssetProps> = ({
  asset,
  config,
  style,
  className,
  ...otherProps
}) => {
  const sources = useImageSource(asset, config?.assetScale);

  const isCdnAsset = useMemo(() => {
    if (!asset) {
      return false;
    }

    // TODO: @sarat - Remove the hardcoded check for vaaree CDN
    return (
      (asset.cdnUrl && asset.cdnName === 'SHOPIFY') ||
      asset.originalUrl?.startsWith('https://vaaree.com/cdn/shop')
    );
  }, []);

  if (!asset) return null;

  const derivedStyles = {
    width: config?.width ?? style?.width,
    height: config?.height ?? style?.height,
    aspectRatio:
      config?.aspectRatio ||
      (style?.aspectRatio as number) ||
      (asset.dimensions
        ? asset.dimensions.width / asset.dimensions.height
        : undefined),
  };

  if (isCdnAsset) {
    return (
      <div style={derivedStyles}>
        <img
          alt={asset.altText}
          className={className}
          loading={config?.loading ?? 'lazy'}
          style={{ ...style, ...derivedStyles, objectFit: config?.resizeMode }}
          srcSet={generateImageSrcSet(
            asset.cdnUrl || asset.originalUrl,
            [
              24, 32, 48, 64, 96, 144, 192, 213, 256, 320, 341, 384, 455, 480,
              512, 533, 576, 640, 683, 768, 768, 800, 1024, 1025, 1200,
            ],
            config?.coverage
          )}
          sizes="100vw"
          {...otherProps}
        />
      </div>
    );
  }

  return (
    <picture style={derivedStyles}>
      <source
        media="(max-width: 300px)"
        srcSet={asset ? `${sources.x1} 1x` : undefined}
      />

      <source
        media="(min-width: 500px)"
        srcSet={asset ? `${sources.x2} 2x` : undefined}
      />

      <source
        media="(min-width: 700px)"
        srcSet={asset ? `${sources.x3} 3x` : undefined}
      />

      <source
        media="(min-width: 1000px)"
        srcSet={asset ? `${sources.x3} 4x` : undefined}
      />

      <img
        src={sources.x3}
        alt={asset.altText}
        className={className}
        loading={config?.loading ?? 'lazy'}
        style={{ ...style, ...derivedStyles, objectFit: config?.resizeMode }}
        {...otherProps}
      />
    </picture>
  );
};

const ImageAssetMemo = memo(ImageAsset);

export { ImageAssetMemo as ImageAsset };
