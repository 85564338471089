import clsx from 'clsx';
import { ImageAsset } from '../Asset';

import type { Product } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface ProductGalleryProps
  extends Omit<ComponentProps<typeof ImageAsset>, 'onClick' | 'asset'> {
  product: Product;
  onProductClick?: (product: Product) => void;
}

const ProductGallery: FC<ProductGalleryProps> = ({
  product,
  className,
  onProductClick,
  ...props
}) => {
  const [asset] = product.assetMap.gallery;
  const handleClick = () => onProductClick?.(product);

  return (
    <ImageAsset
      asset={asset}
      onClick={handleClick}
      className={clsx(className, 'object-cover')}
      {...props}
    />
  );
};

export { ProductGallery };
