import { Logger } from '@tectonic/logger';
import { sendAffiliateEvent } from '../affiliate';
import { getClevertapInfo } from '../clevertap';
import { getCriteoInfo } from '../criteo';
import {
  getClientId as gtagGetClientId,
  getSessionId as gtagGetSessionId,
} from '../gtag';
import {
  getBrowserFingerPrint,
  getHeimdallTrackingInfo,
  getOrCreateHermesClientTracking,
  getShopifyIds,
  setHermesDeviceId,
  setHermesSessionId,
} from '../hermes';
import {
  getFBC,
  getFBP,
  initializePixel,
  resetPixel as pixelReset,
} from '../meta';
import {
  identifyUser,
  initializeMixpanel,
  getDistinctId as mpGetDistinctId,
  getProperties as mpGetProperties,
  getProperty as mpGetProperty,
  resetUser as mpReset,
  setUserAlias as mpSetUserAlias,
  timeEvent as mpTimeEvent,
  track as mpTrack,
  trackPageView as mpTrackPageView,
  removeSuperProperty,
  setPeopleProperties,
  setSuperProperties,
} from '../mixpanel';
import { getPTC, initializePinterest, resetPinterest } from '../pinterest';
import { getSCC, initializeSnap, resetSnap } from '../snap';
import { withTimeout } from './utils';

import type { AnalyticsEventNames } from '../constants';
import type {
  AnalyticsConfig,
  AnalyticsEvenOptions,
  AnalyticsEventPayload,
} from '../types';

// default timeout: 3s.
const DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT = 3000;

const getPixelIds = () => {
  try {
    const fbc = getFBC();
    const fbp = getFBP();
    return { fbc, fbp };
  } catch (error) {
    Logger.error(`[getPixelIds]`, error);
  }
  return {};
};

const getSnapIds = () => {
  try {
    const scc = getSCC();
    return { scc };
  } catch (error) {
    Logger.error(`[getSnapIds]`, error);
  }
  return {};
};

const getPinterestIds = () => {
  try {
    const ptc = getPTC();
    return { ptc };
  } catch (error) {
    Logger.error(`[getPinterestIds]`, error);
  }
  return {};
};

const getGtagIds = async () => {
  const [gaClientId, gaSessionId] = await Promise.all([
    gtagGetClientId(),
    gtagGetSessionId(),
  ]);
  return { gaClientId, gaSessionId };
};

const getDeviceId = (): { mixpanel: string } => ({
  mixpanel: mpGetProperty('$device_id'),
});

const toEnrichedEventPayload = async (
  payload: AnalyticsEventPayload,
  duration = DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT
): Promise<AnalyticsEventPayload> => {
  const hermesClientTrackingIds = getOrCreateHermesClientTracking();
  const heimdallTrackingIds = getHeimdallTrackingInfo();
  const shopifyIds = getShopifyIds();

  const pixelIds = getPixelIds();
  const snapIds = getSnapIds();
  const pinterestIds = getPinterestIds();
  const clevertapInfo = getClevertapInfo();
  const criteoInfo = getCriteoInfo();
  let gtagIds: AnalyticsEventPayload = {};
  try {
    gtagIds = await withTimeout<AnalyticsEventPayload>(getGtagIds(), {
      message: 'getGtagIds timeout',
      duration,
    });
  } catch (error) {
    // TODO: Use `Logger`. Build fails if we use logger.
    Logger.error(`[getGtagIds]`, error);
  }

  let clientFp: AnalyticsEventPayload = {};
  try {
    clientFp = await withTimeout<AnalyticsEventPayload>(
      getBrowserFingerPrint(),
      {
        message: 'getBrowserFingerPrint timeout',
        duration,
      }
    );
  } catch (error) {
    Logger.error(`[getBrowserFingerPrintErr]`, error);
  }

  try {
    setHermesDeviceId(getDeviceId().mixpanel);
  } catch (error) {
    Logger.error(`[setHermesDeviceIdErr]`, error);
  }

  return {
    ...criteoInfo,
    ...clevertapInfo,
    ...pixelIds,
    ...snapIds,
    ...pinterestIds,
    ...hermesClientTrackingIds,
    ...shopifyIds,
    ...gtagIds,
    ...clientFp,
    ...payload,
    ...heimdallTrackingIds,
  };
};

const setGlobalProperties = (props: AnalyticsEventPayload) => {
  setSuperProperties(props);
};

const initializeAnalytics = (
  config: AnalyticsConfig,
  ttTester: boolean,
  ttClientSessionId?: string,
  ttClientSessionInfo?: Record<string, unknown>
) => {
  // We initialize pixel before mixpanel so that fbc and fbp can be made available
  // to mixpanel.
  getOrCreateHermesClientTracking();
  initializePixel();
  initializeSnap();
  initializePinterest();
  setHermesSessionId(ttClientSessionId);
  initializeMixpanel(config.mixpanel);
  setGlobalProperties({ ttEventSource: 'WEBSITE', ttTester, ttClientSessionId, ttClientSessionInfo });
};

const trackPageView = async (props: AnalyticsEventPayload) => {
  const payload = await toEnrichedEventPayload(props);
  mpTrackPageView({ ...payload, ...props });
};

const timeEvent = (event: AnalyticsEventNames) => {
  mpTimeEvent(event);
};

const getProperties = async (duration = DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT) => {
  const otherProps = await toEnrichedEventPayload({}, duration);
  return { ...otherProps, ...mpGetProperties() };
};

// TODO only for vaaree we want to send events to a client side gtm to be sent to criteo
const trackAffiliateEvent = async (
  event: string,
  payload: Record<string, any>
) => {
  const affiliatePayload = { ...payload, ...(await getProperties()) }
  sendAffiliateEvent(event, affiliatePayload)
}

const track = async (
  event: string,
  props: AnalyticsEventPayload,
  options?: AnalyticsEvenOptions
) => {
  const payload = await toEnrichedEventPayload(props);
  await trackAffiliateEvent(event, payload);
  mpTrack(event, payload, options);
};

const getDistinctId = (): { mixpanel: string } => ({
  mixpanel: mpGetDistinctId(),
});

const setUser = (userId: string) => {
  identifyUser(userId);
};

// const isInDebugMode = () => isDebugModeEnabled();

const setUserProperties = (props: AnalyticsEventPayload) => {
  setPeopleProperties(props);
};

const resetUser = () => {
  mpReset();
  pixelReset();
  resetSnap();
  resetPinterest();

  // Unset facebook click id.
  removeSuperProperty('fbc');
};

const setUserAlias = (alias: string, original: string) => {
  mpSetUserAlias(alias, original);
};

export {
  DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT,
  getDeviceId,
  getDistinctId,
  getProperties,
  initializeAnalytics,
  resetUser,
  setGlobalProperties,
  setUser,
  setUserAlias,
  setUserProperties,
  timeEvent,
  track,
  trackPageView
};
