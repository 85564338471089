import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { Clickable } from '@tectonic/uikit';
import clsx from 'clsx';

import type { FC } from 'react';
import type { FacetProps } from './FacetSection.types';

const Facet: FC<FacetProps> = ({ config, facet, isActive, onClick }) => {
  const {
    default: defaultConfig,
    selected: selectedConfig,
    indicator: indicatorConfig,
  } = config ?? {};

  const fConfig = isActive ? selectedConfig : defaultConfig;
  const containerProps = useStyleConfig(fConfig?.container ?? {});
  const indicatorProps = useStyleConfig(indicatorConfig ?? {});

  // TODO: update this.
  const isApplied = false;

  const handleClick = () => {
    onClick(facet.name);
  };

  return (
    <Clickable
      onClick={handleClick}
      style={containerProps.style}
      className={clsx('flex items-center gap-2', containerProps.className)}
    >
      <Text config={fConfig?.text} data={facet.displayName} />
      {isApplied ? <div {...indicatorProps} /> : null}
    </Clickable>
  );
};

Facet.displayName = 'ElemasonSearchFilterFacet';

export { Facet };
