import { Text } from '@tectonic/elemason-components';

import type { FC } from 'react';
import type { TextTickProps } from './Tick.types';

const TextTick: FC<TextTickProps> = ({
  tick,
  onClick,
  style,
  className,
  config,
}) => (
  <Text
    style={style}
    config={config}
    data={tick.text}
    onClick={onClick}
    className={className}
  />
);

export { TextTick };
