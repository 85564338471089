import { useStyleConfig } from '@tectonic/elemason-components';
import { isVisible } from '../../../utils';
import { SearchQueries } from './SearchQueries';
import { SearchSuggestionsHeader } from './SearchSuggestionsHeader';
import { SearchSuggestionsSkeleton } from './SearchSuggestionsSkeleton';

import type { FC } from 'react';
import type { SearchSuggestionsProps } from './SearchSuggestions.types';

const SearchSuggestions: FC<SearchSuggestionsProps> = ({
  data,
  isLoading,
  queries,
  config,
  onQueryClick,
}) => {
  const { header, container } = config ?? {};

  const isHeaderVisible = isVisible(header);

  const containerStyleConfig = useStyleConfig(container ?? {});

  if (isLoading) {
    return <SearchSuggestionsSkeleton config={config} />;
  }

  return (
    <div {...containerStyleConfig}>
      {isHeaderVisible ? (
        <SearchSuggestionsHeader config={header} data={data.header!} />
      ) : null}
      <SearchQueries
        config={config}
        queries={queries}
        data={data.query}
        onQueryClick={onQueryClick}
      />
    </div>
  );
};

SearchSuggestions.displayName = 'ElemasonSearchSuggestions';

export { SearchSuggestions };
