import { Text } from '@tectonic/elemason-components';
import { useThemeContext } from '@tectonic/uikit';
import clsx from 'clsx';
import { memo, type FC } from 'react';
import { useActionDispatch } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonTextWidgetProps } from '@tectonic/types';
import type { Color } from '@tectonic/uikit';

const TextWidget: FC<ElemasonTextWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const { colors } = useThemeContext();
  const dispatch = useActionDispatch();
  const placeholders = useHaloScript<Record<string, string>>(
    data?.placeholders
  );
  const text = useHaloScript(
    typeof data?.text !== 'string' ? data?.text : undefined
  );

  const onClick = () => {
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return (
    <Text
      suppressHydrationWarning
      data={typeof data?.text !== 'string' ? text : data.text}
      className={clsx(actions?.onClick?.length && 'cursor-pointer')}
      onClick={onClick}
      config={{
        ...config?.text,
        color: colors[config?.text?.color as Color] ?? config?.text?.color,
      }}
      placeholders={placeholders}
    />
  );
};

const MemoizedTextWidget = memo(TextWidget);

export { MemoizedTextWidget as TextWidget };
