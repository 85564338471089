import { useHydrated } from '@tectonic/utils';
import { memo, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useCountdown } from '../../hooks/useCountdown';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { CountdownProvider } from './CountdownContext';

import type { ElemasonCountdownWidget } from '@tectonic/types';

interface ElemasonCountdownWidgetProps {
  widget: ElemasonCountdownWidget;
}

const CountdownWidget: FC<ElemasonCountdownWidgetProps> = ({
  widget: { data },
}) => {
  const fragment = usePageFragment(data?.fragment);
  let { days, hours, minutes, seconds } = useCountdown(data?.countdown);
  const ended = days === 0 && hours === 0 && minutes === 0 && seconds === 0;
  const showDays = data?.showDays ?? true;

  if (!showDays) {
    hours += days * 24;
    days = 0;
  }

  const paddedDays = days.toString().padStart(2, '0');
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  const fragmentValue = useFragmentValue(fragment);

  const isHydrated = useHydrated();

  if (ended || !fragment) {
    return null;
  }

  if (!isHydrated) {
    return null;
  }

  return (
    <CountdownProvider
      value={{
        ended,
        days: paddedDays,
        hours: paddedHours,
        minutes: paddedMinutes,
        seconds: paddedSeconds,
      }}
    >
      <ElemasonFragmentProvider value={fragmentValue()}>
        {fragment?.cells.map((cell, index) => (
          <Cell key={cell.id ?? index} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    </CountdownProvider>
  );
};

const MemoizedCountdownWidget = memo(CountdownWidget);

export { MemoizedCountdownWidget as CountdownWidget };
