import { useMemo } from 'react';

import type { ProductVariant } from '@tectonic/types';

const useDefaultVariant = (variants: ProductVariant[]) =>
  useMemo(
    () => variants?.find((v) => v.isCurrentlySelected) ?? variants[0],
    [variants]
  );

export { useDefaultVariant };
