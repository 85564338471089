import { memo, type FC } from 'react';
import { useHaloScript } from '../../hooks';
import { ReviewCore } from './ReviewCore';

import type { ElemasonReviewsWidget } from '@tectonic/types';

interface ReviewsWidgetProps {
  widget: ElemasonReviewsWidget;
}

const ReviewsWidget: FC<ReviewsWidgetProps> = ({ widget }) => {
  const sortBy = useHaloScript(widget.data?.sortBy);

  return <ReviewCore widget={widget} sortBy={sortBy} key={sortBy} />;
};

const MemoizedReviewsWidget = memo(ReviewsWidget);

export { MemoizedReviewsWidget as ReviewsWidget };
