import { useQuery } from "@tanstack/react-query";
import { remixApi } from "@tectonic/api-client";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { usePageFragment } from "../../../hooks";
import { useHaloScript } from "../../../hooks/useHaloScript";

import type { ElemasonSearchAutoCompleteWidget, SearchAutoCompleteResponse } from "@tectonic/types";

const useSearchAutoComplete = (widget: ElemasonSearchAutoCompleteWidget) => {
  const searchText = useHaloScript(widget.data?.searchText);
  const loaderFragment = usePageFragment(widget.data?.loaderFragment);
  const fragment = usePageFragment(widget.data?.autoCompleteCardFragment);
  const noResultsFragment = usePageFragment(widget.data?.noResultsFragment);

  const [search] = useDebounce(searchText, 300)

  const { data: response, isLoading, isError, refetch, isFetching } =
    useQuery<SearchAutoCompleteResponse | null>({
      queryKey: ['search-history'],
      queryFn: async () => !search ? null : remixApi.getSearchAutoComplete(search ?? ''),
    });

  useEffect(() => {
    refetch()
  }, [search])

  return {
    isError,
    response,
    fragment,
    searchText,
    loaderFragment,
    noResultsFragment,
    isLoading: isLoading || isFetching,
  }
}

export { useSearchAutoComplete };
