import { Logger } from '@tectonic/logger';
import { isEmpty, isNil } from 'lodash-es';
import { getCookie, setCookie } from '../helpers';
import { getRandomString } from './utils';

enum HermesIds {
  CLIENT_DEVICE_ID = '__tt_hermes_client_device_id',
  CLIENT_SESSION_ID = '__tt_hermes_client_session_id',
  CLIENT_INSTANCE_ID = '__tt_hermes_client_instance_id',
  DEVICE_ID = '__tt_hermes_device_id',
  SESSION_ID = '__tt_hermes_session_id',
}

const SESSION_ID_TTL = 1 / (24 * 2);
const DEVICE_ID_TTL = 180;

const setHermesDeviceId = (deviceId: string) => {
  if (isNil(deviceId) || isEmpty(deviceId)) {
    return
  }
  if (!getCookie(HermesIds.DEVICE_ID)) {
    setCookie(
      HermesIds.DEVICE_ID,
      deviceId,
      DEVICE_ID_TTL
    );
  }
};

const setHermesSessionId = (sessionId?: string) => {
  if (isNil(sessionId) || isEmpty(sessionId)) {
    return
  }
  setCookie(
    HermesIds.SESSION_ID,
    sessionId,
    SESSION_ID_TTL
  );
};

const getOrCreateHermesClientTrackingCustom = () => {

  try {
    const ttHermesClientDeviceId =
      getCookie(HermesIds.CLIENT_DEVICE_ID) ?? getRandomString();

    setCookie(
      HermesIds.CLIENT_DEVICE_ID,
      ttHermesClientDeviceId,
      DEVICE_ID_TTL
    );

    const ttHermesClientSessionId: string =
      getCookie(HermesIds.CLIENT_SESSION_ID) ?? getRandomString();

    setCookie(
      HermesIds.CLIENT_SESSION_ID,
      ttHermesClientSessionId,
      SESSION_ID_TTL
    );

    const ttHermesClientInstanceId =
      getCookie(HermesIds.CLIENT_INSTANCE_ID) ?? getRandomString();

    setCookie(HermesIds.CLIENT_INSTANCE_ID, ttHermesClientInstanceId);

    return {
      ttHermesClientDeviceId,
      ttHermesClientSessionId,
      ttHermesClientInstanceId,
    };
  } catch (error) {
    Logger.error(`[getOrCreateHermesClientTracking]`, error);
  }

  return {}
}

const getOrCreateHermesClientTracking = () =>
  getOrCreateHermesClientTrackingCustom();

export {
  getOrCreateHermesClientTracking,
  setHermesDeviceId,
  setHermesSessionId
};
