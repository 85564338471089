import { ElemasonFragmentProvider } from '../../../contexts';
import { Cell } from '../../../core';
import { useProductAddReview } from './hooks';

import type { FC } from 'react';
import type { ProductAddReviewWidgetProps } from './ProductAddReview.types';

const ProductAddReviewWidget: FC<ProductAddReviewWidgetProps> = ({
  widget,
}) => {
  const { fragment, fragmentData } = useProductAddReview(widget);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

ProductAddReviewWidget.displayName = 'ProductAddReviewWidget';

export { ProductAddReviewWidget };
