import { Button, Input, InputField } from '@tectonic/uikit';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { SelectMenu } from '../Select';

import type { FC } from 'react';
import type { PhoneNumberInputProps } from './PhoneNumberInput.types';

// TODO: This is a temporary component. We'll create a dedicated phoneNumber
// input component.
const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  value,
  className,
  style,
  countries,
  countryCode,
  onChange,
  ...inputProps
}) => {
  const { country, phone } = value ?? {};

  return (
    <div
      style={style}
      className={clsx(
        'flex',
        'rounded border border-neutral-400',
        'px-3',
        className
      )}
    >
      {!countryCode?.data ? (
        <Button
          className={clsx(
            'pointer-events-none shrink-0',
            '!rounded-none border-0 border-e border-neutral-400'
          )}
          disabled
          size="sm"
          startIcon=""
          type="button"
          variant="ghost"
          modifier="black"
          label={country?.stdCodes?.[0]}
          endIcon="outline-chevron-down"
        />
      ) : (
        <SelectMenu
          disabled={isEmpty(countries)}
          data={countryCode.data}
          config={countryCode.config}
          value={{ name: country?.name, displayName: country?.stdCodes?.[0] }}
          onChange={(selectedCountry) =>
            onChange({
              country: countries.find(
                (c) => c.stdCodes[0] && c.name === selectedCountry.name
              )!,
              phone,
            })
          }
          options={countries.map((c) => ({
            name: c.name,
            displayName: c.name,
          }))}
        />
      )}
      <Input className={clsx('grow border-none')}>
        <InputField
          value={phone}
          type="tel"
          pattern="[0-9]+"
          onChange={(event) => onChange({ country, phone: event.target.value })}
          {...inputProps}
        />
      </Input>
    </div>
  );
};

PhoneNumberInput.displayName = 'PhoneNumberInput';

export { PhoneNumberInput };
