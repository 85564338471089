import { Button } from '../Button';
import { useCarouselContext } from './CarouselContext';

import type { FC } from 'react';
import type { CarouselScrollNextProps } from './Carousel.types';

const ScrollNext: FC<CarouselScrollNextProps> = ({
  isVisible,
  containerStyle,
  containerClassName,
  ...restProps
}) => {
  const { scrollNext } = useCarouselContext();

  const onClick = () => scrollNext();

  if (!isVisible) return null;

  return (
    <div style={containerStyle} className={containerClassName}>
      <Button type="button" onClick={onClick} {...restProps} />
    </div>
  );
};

ScrollNext.displayName = 'CarouselScrollNext';

export default ScrollNext;
