import { withTryCatch } from './utils';

const SessionStorage = {
  setItem: withTryCatch((key: string, value: string) =>
    globalThis.sessionStorage.setItem(key, value)
  ) as typeof globalThis.sessionStorage.setItem,
  getItem: withTryCatch((key: string) =>
    globalThis.sessionStorage.getItem(key)
  ) as typeof globalThis.sessionStorage.getItem,
  removeItem: withTryCatch((key: string) =>
    globalThis.sessionStorage.removeItem(key)
  ) as typeof globalThis.sessionStorage.removeItem,
  clear: withTryCatch(() =>
    globalThis.sessionStorage.clear()
  ) as typeof globalThis.sessionStorage.clear,
};

export { SessionStorage };
