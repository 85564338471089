import { toTailwindUtility } from '@tectonic/utils';
import { compact } from 'lodash-es';

import type { StyleConfig, TextConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useTextConfig = (config: TextConfig & StyleConfig) => {
  const {
    variant,
    fontSize,
    fontFamily,
    fontWeight,
    fontStyle,
    textAlign,
    textDecoration,
    numberOfLines,
    textShadow,
    textTransform,
    marginEnd,
    textWrap,
    lineHeight,
  } = config;

  const textStyles: CSSProperties = {};

  const textClasses = [
    toTailwindUtility('text', fontSize),
    variant,
    toTailwindUtility('leading', lineHeight),
    toTailwindUtility('text', textWrap),
    toTailwindUtility('font', fontFamily),
    toTailwindUtility('font', fontWeight),
    toTailwindUtility('line-clamp', numberOfLines),
  ];

  textStyles.fontStyle = fontStyle;
  textStyles.textAlign = textAlign;
  if(marginEnd !== undefined)
    textStyles.marginRight = marginEnd;
  textStyles.textShadow = textShadow;
  textStyles.textTransform = textTransform;
  textStyles.textDecoration = textDecoration;

  return [textStyles, compact(textClasses)] as [CSSProperties, string[]];
};

export { useTextConfig };
