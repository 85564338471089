import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Icon } from '../../../Icon';

import type { ProductSizeVariantsDropdownConfig } from '@tectonic/types';
import type { FC } from 'react';

interface SizeDropDownProps {
  sizes: string[];
  selectedSize?: string;
  config: ProductSizeVariantsDropdownConfig;
}

const SizeDropDown: FC<SizeDropDownProps> = ({
  sizes,
  config,
  selectedSize,
}) => {
  const { style, className } = useStyleConfig(config.container ?? {});

  return (
    <div style={style} className={clsx('flex flex-row', className)}>
      <Text data={selectedSize} config={config.text} />
      {sizes.length > 0 && (
        <Icon symbol={config.icon} config={config.iconConfig} />
      )}
    </div>
  );
};

export { SizeDropDown };
