import { Text, useStyleConfig } from '@tectonic/elemason-components';
import {
  type ElemasonLooksWidgetHeaderConfig,
  type ElemasonLooksWidgetHeaderData,
} from '@tectonic/types';
import { isVisible } from '../../utils';

import type { FC } from 'react';

type LooksHeaderProps = {
  data?: ElemasonLooksWidgetHeaderData;
  config?: ElemasonLooksWidgetHeaderConfig;
};

const isHeaderVisible = (
  config?: ElemasonLooksWidgetHeaderConfig,
  data?: ElemasonLooksWidgetHeaderData
) => {
  const { title, subtitle } = data ?? {};
  // Header is displayed only when it has something to show.
  return isVisible(config ?? {}) && (Boolean(title) || Boolean(subtitle));
};

const LooksHeader: FC<LooksHeaderProps> = ({ data, config }) => {
  const { title, subtitle } = data ?? {};

  const showTitle = isVisible(config?.title ?? {});
  const showHeader = isHeaderVisible(config, data);
  const showSubtitle = isVisible(config?.subtitle ?? {});

  const containerProps = useStyleConfig(config?.container ?? {});

  if (!showHeader) {
    return null;
  }

  return (
    <div {...containerProps}>
      <div className="flex flex-row items-center">
        <div className="grow">
          {showTitle && <Text data={title} config={config?.title} />}
        </div>
      </div>
      {showSubtitle && subtitle && (
        <Text data={subtitle} config={config?.subtitle} />
      )}
    </div>
  );
};

LooksHeader.displayName = 'ElemasonLooksHeader';

export { LooksHeader };
