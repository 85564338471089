import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { ElemasonWidgetType } from '@tectonic/types';
import { Looks } from '../../components';
import { ElemasonFragmentProvider } from '../../contexts/ElemasonFragmentContext';
import { Cell } from '../../core/Cell';
import { usePageFragment } from '../../hooks';
import { useElemasonWidgetConfig } from '../../hooks/useElemasonConfig';
import { useFragmentValue } from '../../hooks/useFragmentValue';
import { queryKeys } from '../../queryKeys';
import { flattenSource } from '../../utils/flattenSource';
import { LooksHeader } from './LooksHeader';

import type { ElemasonLooksWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonLooksWidgetProps {
  widget: ElemasonLooksWidget;
}

const LooksWidget: FC<ElemasonLooksWidgetProps> = ({ widget }) => {
  const { config: looksConfig, data } = widget;
  const { query } = data ?? {};
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const config = useElemasonWidgetConfig(ElemasonWidgetType.Looks, looksConfig);

  const params = {
    ...flattenSource({ query, collection: 'looks' }),
    page: 1,
    perPage: 10,
  };

  const { data: looks, isLoading } = useQuery({
    queryKey: queryKeys.looks(params),
    queryFn: async () => remixApi.searchCollection(params ?? {}, 'looks'),
  });

  if (isLoading || !looks?.hits || looks.hits.length === 0) {
    return null;
  }

  if (data?.fragment) {
    return (
      <ElemasonFragmentProvider value={fragmentValue({ looks })}>
        {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    );
  }

  return (
    <>
      <LooksHeader data={data?.header} config={config?.header} />
      <Looks
        data={data}
        config={config}
        looks={looks.hits.map((h) => h.document)}
      />
    </>
  );
};

export { LooksWidget };
