import { isNil } from 'lodash-es';

import type { DimensionConfig } from '@tectonic/types';
import type { CSSProperties } from 'react';

const useDimensionConfig = (config: DimensionConfig): CSSProperties => {
  const {
    flex,
    flexShrink,
    width,
    height,
    aspectRatio,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
    gridColumn,
  } = config;

  const commonStyles: CSSProperties = {};

  if (!isNil(flex)) {
    commonStyles.flex = flex;
  }

  if (!isNil(flexShrink)) {
    commonStyles.flexShrink = flexShrink;
  }

  if (!isNil(maxHeight)) {
    commonStyles.maxHeight = maxHeight;
  }

  if (!isNil(minHeight)) {
    commonStyles.minHeight = minHeight;
  }

  if (!isNil(maxWidth)) {
    commonStyles.maxWidth = maxWidth;
  }

  if (!isNil(minWidth)) {
    commonStyles.minWidth = minWidth;
  }

  if (!isNil(aspectRatio)) {
    commonStyles.aspectRatio = aspectRatio;
  }

  if (!isNil(gridColumn)) {
    commonStyles.gridColumn = gridColumn;
  }

  if (!isNil(height) && !isNil(width)) {
    return {
      height,
      width,
      ...commonStyles,
    };
  }

  if (!isNil(height)) {
    if (typeof height === 'number') {
      return {
        height,
        width: aspectRatio ? height * aspectRatio : 'auto',
        ...commonStyles,
      };
    }

    return {
      height,
      ...commonStyles,
    };
  }

  if (!isNil(width)) {
    if (typeof width === 'number') {
      return {
        width,
        height: aspectRatio ? width / aspectRatio : 'auto',
        ...commonStyles,
      };
    }

    return {
      width,
      ...commonStyles,
    };
  }

  return commonStyles;
};

export { useDimensionConfig };
