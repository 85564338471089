enum AnalyticsCartEventNames {
  CART_VIEW = 'Cart View',
  // Cart related events requires some data from the cart object to be passed
  // as payload therefore we have added product add to cart in  cart events.
  PRODUCT_ADD_REQUEST = 'Cart Product Add Request',
  PRODUCT_ADD_SUCCESS = 'Cart Product Add Success',
  PRODUCT_ADD_ERROR = 'Cart Product Add Error',

  PRODUCT_REMOVE_REQUEST = 'Cart Product Remove Request',
  PRODUCT_REMOVE_SUCCESS = 'Cart Product Remove Success',
  PRODUCT_REMOVE_ERROR = 'Cart Product Remove Error',

  PRODUCT_MOVE_TO_WISHLIST_REQUEST = 'Cart Product Move To Wishlist Request',
  PRODUCT_MOVE_TO_WISHLIST_SUCCESS = 'Cart Product Move To Wishlist Success',
  PRODUCT_MOVE_TO_WISHLIST_ERROR = 'Cart Product Move To Wishlist Error',

  PROMOTION_ADD_REQUEST = 'Cart Promotion Add Request',
  PROMOTION_ADD_SUCCESS = 'Cart Promotion Add Success',
  PROMOTION_ADD_ERROR = 'Cart Promotion Add Error',

  PROMOTION_REMOVE_REQUEST = 'Cart Promotion Remove Request',
  PROMOTION_REMOVE_SUCCESS = 'Cart Promotion Remove Success',
  PROMOTION_REMOVE_ERROR = 'Cart Promotion Remove Error',

  PROMOTIONS_VIEW = 'Cart Promotions View',

  // TODO: Move checkout steps to checkout when we build checkout in-house.
  CHECKOUT_BEGIN = 'Checkout Begin',
  CHECKOUT_BEGIN_BUTTON_CLICK = 'Checkout Begin Button Click',
  CHECKOUT_INITIALIZED = 'Checkout Initialized',
}

export { AnalyticsCartEventNames };
