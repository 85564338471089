import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { forwardRef, memo } from 'react';

import type { ForwardRefRenderFunction } from 'react';
import type { CarouselSlideProps } from './Carousel.types';

const Slide: ForwardRefRenderFunction<HTMLDivElement, CarouselSlideProps> = (
  { className, children, asChild, isActive, ...restProps },
  ref
) => {
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      ref={ref}
      data-carousel-slide-active={isActive}
      className={clsx('shrink-0', className)}
      {...restProps}
    >
      {children}
    </Comp>
  );
};

Slide.displayName = 'CarouselSlide';

export default memo(forwardRef(Slide));
