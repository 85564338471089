import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from '@tectonic/uikit';
import { useEffect, type FC } from 'react';
import { Loader } from '../../../components';
import { ElemasonDrawerProvider } from '../../../contexts';
import { useElemasonPageContext } from '../../../contexts/ElemasonPageContext';
import { queryKeys } from '../../../queryKeys';
import { DrawerPage } from './DrawerPage';
import { useDrawers } from './store';

import type { Direction } from '@tectonic/types';

interface ElemasonDrawerProps {
  slug: string;
  data: unknown;
  exit?: Direction;
  entry?: Direction;
  exiting?: boolean;
  isActive?: boolean;
  animationType?: string;
}

const Drawer: FC<ElemasonDrawerProps> = ({
  slug,
  exit,
  data,
  entry,
  exiting,
  isActive,
  animationType,
}) => {
  const { close } = useDrawers();
  const { setScope } = useElemasonPageContext();

  const {
    data: page,
    isError,
    isLoading,
  } = useQuery({
    queryKey: queryKeys.drawer(slug),
    queryFn: () => remixApi.getPage(slug),
  });

  useEffect(() => {
    if (isError) close(slug);
  }, [isError]);

  useEffect(() => {
    // @ts-ignore
    setScope((localState) => ({
      ...localState,
      drawers: {
        ...(localState.drawers ?? {}),
        [slug]: {
          slug,
          data,
          pageData: page?.data,
        },
      },
    }));
    return () => {
      // @ts-ignore
      setScope((localState) => ({
        ...localState,
        drawers: {
          ...(localState.drawers ?? {}),
          [slug]: undefined,
        },
      }));
    };
  }, [data, page]);

  useEffect(() => {
    // Radix ui blocks modals opened by a third party.
    // https://github.com/radix-ui/primitives/issues/2122
    // ugly workaround to fix it
    const timer = setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 0);
    return () => {
      clearTimeout(timer);
      document.body.style.pointerEvents = 'auto';
    };
  }, []);

  return (
    <DialogRoot open modal onOpenChange={(open) => !open && close(slug)}>
      <DialogPortal>
        <DialogOverlay className="pointer-events-auto fixed inset-0 z-20 bg-black/70" />
        <DialogContent onInteractOutside={() => isActive && close(slug)}>
          {isLoading && (
            <Loader className="-transform-x-1/2 -transform-y-1/2 fixed left-1/2 top-1/2 z-20 text-white" />
          )}
          <ElemasonDrawerProvider
            value={{
              slug,
              data,
              close: () => close(slug),
            }}
          >
            {page && (
              <DrawerPage
                animationType={animationType}
                slug={slug}
                exit={exit}
                entry={entry}
                page={page.page}
                exiting={exiting}
              />
            )}
          </ElemasonDrawerProvider>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
};

export { Drawer };
