import { Logger } from '@tectonic/logger';
import { getCookie } from "../helpers";

enum CrieoIds {
  USER_ID = 'crto_mapped_user_id',
  USER_OPT = 'crto_is_user_optout',
}

const getCriteoInfo = () => {
  let criteoId = null;
  let criteoOptStatus = null;
  try {
    criteoId = getCookie(CrieoIds.USER_ID)
    criteoOptStatus = getCookie(CrieoIds.USER_OPT)
  } catch (error) {
    Logger.error(`[setHermesClevertapIdErr]`, error);
  }

  return { criteoId, criteoOptStatus }
}


export { getCriteoInfo };
