import { createContext } from '@tectonic/utils';

interface ElemasonBlockContextType {
  index: number;
}

const errorMessage =
  'useElemasonBlockContext must be inside a ElemasonBlockProvider with a value';
const name = 'ElemasonBlockContext';

const [ElemasonBlockProvider, useElemasonBlockContext] =
  createContext<ElemasonBlockContextType>({
    errorMessage,
    name,
    strict: false,
  });

export { ElemasonBlockProvider, useElemasonBlockContext };
