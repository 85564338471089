// Array for unit abbreviations
const ABBREVIATION_UNITS = ['', 'K', 'M', 'B', 'T'];

// TODO: Refactor this as needed when different locales are required.
// `en-IN` is used for now.
const toCurrency = (amount: number, code: string = 'INR') => {
  const options: Intl.NumberFormatOptions = {
    currency: code,
    style: 'currency',
    // React Native doesn't support only `maximumFractionDigits: 0`. We get invalid range
    // error when it is passed as option for `toLocaleString`. Therefore, we have
    // to pass both min and max values.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  };

  let result = '';
  try {
    result = amount.toLocaleString('en-IN', options);
  } catch (error) {
    // Fallback to symbol display if narrowSymbol is not supported
    options.currencyDisplay = 'symbol';
    result = amount.toLocaleString('en-IN', options);
  }

  return result.replaceAll(/\s/g, '');
};

// Using paling Intl is not working on iOS. It is not compacting number with plain
// `toLocaleString`:
// value.toLocaleString(locale, { notation: 'compact' });
// To keep things consistent, we are using custom implementation to humanize/compact
// numbers.
const humanize = (value: number): string => {
  if (value < 1) {
    return value.toLocaleString('en-US');
  }
  // Determine the index of the abbreviation
  const abbrIndex = Math.floor(Math.log10(value) / 3);
  const abbrUnit = ABBREVIATION_UNITS[abbrIndex];
  const num = value / 1000 ** abbrIndex;
  return (
    num.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }) + abbrUnit
  );
};

export { humanize, toCurrency };
