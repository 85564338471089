import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';

import type { Order, OrderLite, StyleConfig } from '@tectonic/types';
import type { FC } from 'react';

interface OrderHeaderProps {
  order: OrderLite | Order;
  config?: StyleConfig;
}

const OrderHeader: FC<OrderHeaderProps> = ({ order, config }) => {
  const { style: orderHeaderStyle, className: orderHeaderClassName } =
    useStyleConfig(config ?? {});

  const header = `Order: ${order.name}`;
  const dateObj = new Date(order.processedAt);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = `Placed on ${dateObj.toLocaleDateString(
    'en-US',
    // TODO: check issue here
    options
  )}`;

  return (
    <div
      className={clsx('flex flex-col', orderHeaderClassName)}
      style={orderHeaderStyle}
    >
      <div className={clsx('flex flex-col')} style={{}}>
        <Text
          data={header}
          config={{
            variant: 'body3',
            fontWeight: 'semibold',
            color: 'neutral-950',
          }}
        />
      </div>
      <div className={clsx('flex flex-col')} style={{}}>
        <Text
          data={formattedDate}
          config={{
            variant: 'body4',
            fontWeight: 'medium',
            color: 'neutral-500',
          }}
        />
      </div>
    </div>
  );
};

export { OrderHeader };
