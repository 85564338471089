/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Scope } from './scope';

import type {
  ApplyExpression,
  ArrayExpression,
  AssignmentExpression,
  BinaryExpression,
  ConditionalExpression,
  Context,
  Expression,
  LambdaExpression,
  LoopExpression,
  MemberExpression,
  ObjectExpression,
  Script,
  UnaryExpression,
} from './types';

function interpretUnaryExpression(expression: UnaryExpression, scope: Scope) {
  const argument = interpretExpression(expression.argument, scope);

  switch (expression.operator) {
    case '!':
      return !argument;
    default:
      throw new Error(`Unsupported operator: ${expression.operator}`);
  }
}

function interpretBinaryExpression(expression: BinaryExpression, scope: Scope) {
  const left = interpretExpression(expression.left, scope);
  const right = interpretExpression(expression.right, scope);

  switch (expression.operator) {
    case '+':
      return left + right;
    case '-':
      return left - right;
    case '*':
      return left * right;
    case '/':
      return left / right;
    case '%':
      return left % right;
    case '<':
      return left < right;
    case '>':
      return left > right;
    case '<=':
      return left <= right;
    case '>=':
      return left >= right;
    case '==':
      return left === right;
    case '!=':
      return left !== right;
    case '&&':
      return left && right;
    case '||':
      return left || right;
    case '??':
      return left ?? right;
    default:
      throw new Error(`Unsupported operator: ${expression.operator}`);
  }
}

function interpretArrayExpression(expression: ArrayExpression, scope: Scope) {
  return expression.items.map((item) => interpretExpression(item, scope));
}

function interpretObjectExpression(expression: ObjectExpression, scope: Scope) {
  return Object.fromEntries(
    expression.entries.map(({ key, value }) => [
      key,
      interpretExpression(value, scope),
    ])
  );
}

function interpretMemberExpression(
  expression: MemberExpression,
  scope: Scope
): any {
  const objectValue = interpretExpression(expression.object, scope);
  const propertyValue = interpretExpression(expression.property, scope);

  return objectValue?.[propertyValue];
}

// TODO: fix nested assignment
// looks like a[b][c], a[b[c]] are not differentiable
function interpretAssignmentExpression(
  expression: AssignmentExpression,
  scope: Scope
): any {
  const value = interpretExpression(expression.right, scope);

  if (expression.left.type === 'Identifier') {
    const name =
      typeof expression.left.name === 'string'
        ? expression.left.name
        : interpretExpression(expression.left.name, scope);
    scope.getScopeWithOwnProperty(name).set(name, value);

    return value;
  }

  const objectValue = interpretExpression(expression.left.object, scope);

  if (expression.left.property.type === 'MemberExpression') {
    return setNestedValue(objectValue, expression.left.property, value, scope);
  }

  const propertyName = interpretExpression(expression.left.property, scope);

  objectValue[propertyName] = value;

  return value;
}

function interpretLambdaExpression(expression: LambdaExpression, scope: Scope) {
  const { body, arguments: args } = expression;
  return (...fArgs: any[]) => {
    const fnScope = args.reduce((acc, arg, i) => {
      acc.set(arg, fArgs[i]);
      return acc;
    }, scope);

    return interpretBody(body, fnScope);
  };
}

function interpretApplyExpression(expression: ApplyExpression, scope: Scope) {
  const { function: fn, arguments: args, thisArg } = expression;
  const blockScope = new Scope({}, scope);
  let fnExpression = interpretExpression(fn, blockScope);

  const fnArgs = args.map((arg: any) => interpretExpression(arg, blockScope));
  while (typeof fnExpression !== 'function')
    fnExpression = interpretExpression(fnExpression, blockScope);

  if (thisArg) {
    const finalThisArg = interpretExpression(thisArg, scope);
    return fnExpression.apply(finalThisArg, fnArgs);
  }

  return fnExpression(...fnArgs);
}

function interpretConditionalExpression(
  expression: ConditionalExpression,
  scope: Scope
) {
  const { test, consequent, alternate } = expression;
  const blockScope = new Scope({}, scope);

  if (interpretExpression(test, blockScope)) {
    return interpretBody(consequent, blockScope);
  }
  return alternate ? interpretBody(alternate, blockScope) : undefined;
}

function interpretLoopExpression(expression: LoopExpression, scope: Scope) {
  let resp;
  const { init, test, update, body } = expression;
  const blockScope = new Scope({}, scope);

  interpretBody(init, blockScope);

  while (interpretExpression(test, blockScope)) {
    resp = interpretBody(body, blockScope);
    interpretBody(update, blockScope);
  }

  return resp;
}

function interpretExpression(expression: Expression, scope: Scope): any {
  switch (expression.type) {
    case 'Literal':
      return expression.value;
    case 'Identifier': {
      return scope.get(
        typeof expression.name === 'string'
          ? expression.name
          : interpretExpression(expression.name, scope)
      );
    }
    case 'MemberExpression':
      return interpretMemberExpression(expression, scope);
    case 'UnaryExpression':
      return interpretUnaryExpression(expression, scope);
    case 'BinaryExpression':
      return interpretBinaryExpression(expression, scope);
    case 'ArrayExpression':
      return interpretArrayExpression(expression, scope);
    case 'ObjectExpression':
      return interpretObjectExpression(expression, scope);
    case 'AssignmentExpression':
      return interpretAssignmentExpression(expression, scope);
    case 'LambdaExpression':
      return interpretLambdaExpression(expression, scope);
    case 'ApplyExpression':
      return interpretApplyExpression(expression, scope);
    case 'ConditionalExpression':
      return interpretConditionalExpression(expression, scope);
    case 'LoopExpression':
      return interpretLoopExpression(expression, scope);
    default:
      return interpretExpression(expression, scope);
  }
}

const interpretBody = (body: Expression[], scope: Scope) => {
  const resp = body.map((expression) => interpretExpression(expression, scope));
  return resp[resp.length - 1];
};

const interpret = (script: Script, context: Context): any => {
  const scope = new Scope(context);
  return interpretBody(script.body, scope);
};

const getNestedValue = (
  objectValue: any,
  propertyName: MemberExpression,
  scope: Scope
): any => {
  const object = objectValue?.[interpretExpression(propertyName.object, scope)];

  if (
    typeof propertyName.property === 'object' &&
    propertyName.property.type === 'MemberExpression'
  ) {
    return getNestedValue(object, propertyName.property, scope);
  }

  const property = interpretExpression(propertyName.property, scope);

  return object?.[property];
};

function setNestedValue(
  objectValue: any,
  propertyName: MemberExpression,
  value: any,
  context: any
): void {
  const object =
    objectValue?.[interpretExpression(propertyName.object, context)];

  if (
    typeof propertyName.property === 'object' &&
    propertyName.property.type === 'MemberExpression'
  ) {
    setNestedValue(object, propertyName.property, value, context);
    return;
  }

  const property = interpretExpression(propertyName.property, context);

  if (object) object[property] = value;
}

export { interpret };
