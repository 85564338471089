import { tv } from 'tailwind-variants';

const inputTv = tv({
  slots: {
    base: 'group flex items-center gap-x-2',
    field: 'grow w-0',
    slot: '',
    label: '',
    icon: 'text-neutral-300',
  },
  variants: {
    variant: {
      outline: {
        base: 'border border-neutral-300 focus-within:border-black',
        icon: 'group-focus-within:text-black',
      },
    },

    size: {
      lg: 'h-14 px-3.5',
      md: 'h-12 px-3',
      sm: 'h-10 px-2.5',
      xs: 'h-8 px-2',
    },

    rounded: {
      md: 'rounded',
      none: 'rounded-none',
      full: 'rounded-full',
    },

    disabled: {
      true: {
        base: 'opacity-70 pointer-events-none',
      },
    },
  },
  defaultVariants: {
    variant: 'outline',
    size: 'md',
    rounded: 'md',
  },
});

export { inputTv };
