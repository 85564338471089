import { trackImpressionEvent } from '@tectonic/analytics';
import { isEmpty, isNil } from 'lodash-es';
import { enrichAnalyticsPayloadWithWidgetData } from '../../utils';

import type { AnalyticsEventPayload, AnalyticsStorePage, ElemasonAnalyticsContextType } from '@tectonic/analytics';
import type { ImpressionLedgerEntry, Nil } from '@tectonic/types';

const IMPRESSION_CUTOFF_TIME = 500; // in ms

interface ImpressionLedgerPageInfo {
  previousPage?: Nil<AnalyticsStorePage>,
  currentPage?: Nil<AnalyticsStorePage>
}

const hasImpressedEnough = (entry: ImpressionLedgerEntry) => {
  const { inViewAt } = entry;
  if (isNil(inViewAt)) {
    return false;
  }
  const diff = Date.now() - inViewAt;
  return diff >= IMPRESSION_CUTOFF_TIME;
};

class ImpressionLedger {
  private readonly ledger = new Map<string, ImpressionLedgerEntry>();

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly eventName: string,
    private readonly source: ElemasonAnalyticsContextType,
    private readonly pageInfo?: ImpressionLedgerPageInfo,
  ) { }

  // Track existing impression for an entry if needed.
  private trackExistingImpression(id: string, inView: boolean) {
    const prevEntry = this.ledger.get(id);
    if (!prevEntry) {
      // no previous impression to track.
      return;
    }

    if (inView || prevEntry.isTracked) {
      // item is still in view port or may already tracked.
      return;
    }

    if (!hasImpressedEnough(prevEntry)) {
      // looks like cutoff time is not met.
      return;
    }

    trackImpressionEvent(
      this.eventName,
      this.generateImpressionEventPayload(prevEntry),
      prevEntry.entity
    );

    // mark impression as tracked to avoid noise.
    this.ledger.set(id, { ...prevEntry, isTracked: true });
  }

  public setEntry(id: string, entry: ImpressionLedgerEntry) {
    this.trackExistingImpression(id, entry.inView);

    const prevEntry = this.ledger.get(id);
    if (!prevEntry) {
      const inViewAt = entry.inView ? Date.now() : null;
      this.ledger.set(id, { ...entry, inViewAt });
      return this;
    }

    if (prevEntry.isTracked) {
      // already tracked. Don't do anything.
      return this;
    }

    if (entry.inView && prevEntry.inView) {
      // item is already in view no need to do anything.
      return this;
    }

    // If item has entered in the view port, update inViewAt as needed.
    const hasEntered = entry.inView && !prevEntry.inView;
    const inViewAt = hasEntered ? Date.now() : prevEntry.inViewAt;
    this.ledger.set(id, { ...prevEntry, ...entry, inViewAt });
    return this;
  }

  // Flushes impressions by sending them to mixpanel.
  public flush() {
    const items: ImpressionLedgerEntry[] = [];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    for (const [_, entry] of this.ledger) {
      if (entry.isTracked) {
        continue;
      }
      if (!hasImpressedEnough(entry)) {
        continue;
      }
      items.push(entry);
    }

    if (isEmpty(items)) {
      return;
    }

    items.forEach((item) => {
      trackImpressionEvent(
        this.eventName,
        this.generateImpressionEventPayload(item),
        item.entity
      );
    });
    this.ledger.clear();
  }

  private generateImpressionEventPayload(ledgerEntry: ImpressionLedgerEntry) {
    return {
      ...enrichAnalyticsPayloadWithWidgetData<AnalyticsEventPayload>(
        {
          items: [
            {
              page: ledgerEntry.page,
              index: ledgerEntry.index,
              ...ledgerEntry.payload,
            },
          ],
        },
        this.source.widget
      ),
      pageSlug: this.pageInfo?.currentPage?.pageSlug,
      pageVersion: this.pageInfo?.currentPage?.pageVersion,
      pageId: this.pageInfo?.currentPage?.pageId,
      previousPageVersion: this.pageInfo?.previousPage?.pageVersion,
      previousPageSlug: this.pageInfo?.previousPage?.pageSlug,
      previousPageId: this.pageInfo?.previousPage?.pageId,
    }
  }
}

export { ImpressionLedger };
