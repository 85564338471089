import { useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { SearchQuery } from './SearchQuery';

import type { FC } from 'react';
import type { SearchQueriesProps } from './SearchHistory.types';

const SearchQueries: FC<SearchQueriesProps> = ({
  config,
  data,
  onClear,
  onQueryClick,
  queries,
}) => {
  const { queryList } = config ?? {};
  const [containerStyle, containerClassName] = useStyleConfigV2(
    queryList?.container ?? {}
  );
  return (
    <div
      className={clsx(
        'flex',
        queryList?.container?.direction === 'horizontal'
          ? 'flex-row'
          : 'flex-col',
        containerClassName
      )}
      style={containerStyle}
    >
      {queries.map((query) => (
        <SearchQuery
          key={query.id}
          data={data}
          onClear={onClear}
          onQueryClick={onQueryClick}
          query={query}
          config={config?.query}
        />
      ))}
    </div>
  );
};

SearchQueries.displayName = 'SearchQueries';

export { SearchQueries };
