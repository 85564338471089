import parser from 'ua-parser-js';

// It's obviously not perfect
// e.g. if someone is on a tall phone (> 768px) and they're in horizontal mode,
// then we'll still show the "mobile" UI even though the screen width is > 768px.
// But it seems like this will work in ~95% of use cases.
// https://github.com/faisalman/ua-parser-js/issues/182
const isDesktop = (userAgent: string | null) => {
  const ua = parser(userAgent ?? '');
  return (
    ua.device.type === undefined ||
    !['wearable', 'mobile'].includes(ua.device.type)
  );
};

export { isDesktop };
