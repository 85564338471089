import { inputFieldTv2 } from './variant2';

import type { FC } from 'react';
import type { InputFieldProps2 } from './InputField2.types';

const InputField2: FC<InputFieldProps2> = ({
  size,
  className,
  ...inputProps
}) => {
  const inputClass = inputFieldTv2({ size, className });

  return <input type="text" className={inputClass} {...inputProps} />;
};

// TODO: Add support for ref when needed.

InputField2.displayName = 'UiKitInputField';

export { InputField2 };
