import clsx from 'clsx';

import type { OverflowConfig } from '@tectonic/types';

const useOverflowConfig = (config: OverflowConfig) => {
  const overflowClasses = clsx(
    config.overflow && `overflow-${config.overflow}`,
    config.overflowX && `overflow-x-${config.overflowX}`,
    config.overflowY && `overflow-y-${config.overflowY}`
  );

  return overflowClasses;
};

export { useOverflowConfig };
