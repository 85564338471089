import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { isEmpty } from 'lodash-es';
import { ProductFBT } from '../../components';
import { useHaloScript, usePageFragment } from '../../hooks';
import { useAccessorValue } from '../../hooks/useAccessorValue';
import { queryKeys } from '../../queryKeys';
import { isProductOOS } from '../../utils';

import type { ElemasonProductFBTWidget, Product } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonProductFBTWidgetProps {
  widget: ElemasonProductFBTWidget;
}

const ProductFBTWidget: FC<ElemasonProductFBTWidgetProps> = ({ widget }) => {
  const { config, data, actions: wActions } = widget;
  const { slug: slugAccessor } = data ?? {};
  const ctaText = useHaloScript(data?.ctaText);
  const limit = useHaloScript(data?.limit) ?? 10;
  const slug = useAccessorValue(slugAccessor ?? {});
  const fragment = usePageFragment(data?.productFragment);

  const {
    data: fbtProducts,
    isError,
    isLoading,
    isFetching,
  } = useQuery({
    enabled: !!slug,
    queryKey: queryKeys.frequentlyBoughtTogether(slug ?? ''),
    queryFn: async () =>
      // eslint-disable-next-line max-len
      remixApi.getBoughtTogetherProductsForProduct(
        slug!,
        new URLSearchParams({ page: '1', perPage: `${limit}` })
      ),
  });

  if (!slug || !fragment) {
    return null;
  }

  if (isEmpty(fbtProducts?.hits)) {
    return null;
  }

  const rootProduct = fbtProducts?.root;

  const totalProducts: Product[] = (isEmpty(rootProduct) ? [] : [rootProduct])
    .concat(fbtProducts?.hits ?? [])
    .filter((product) => !isProductOOS(product.variants ?? []));

  if (isEmpty(totalProducts) || totalProducts.length === 1) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <ProductFBT
        key={rootProduct?.id}
        config={config}
        fragment={fragment}
        atcFragment={data?.atcFragment!}
        products={totalProducts}
        selectIcon={data?.selectCta.selectedIcon}
        unselectIcon={data?.selectCta.unselectedIcon}
        highlightText="Product on this page"
        rootProduct={rootProduct}
        ctaText={ctaText}
        isLoading={isLoading || isFetching}
        isError={isError}
        highlightProduct
        wActions={wActions}
      />
    </div>
  );
};

export { ProductFBTWidget };
