import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Icon } from '../../Icon';

import type { FC } from 'react';
import type { SearchTrendsHeaderProps } from './SearchTrends.types';

const SearchTrendsHeader: FC<SearchTrendsHeaderProps> = ({ config, data }) => {
  const { container, title, icon } = config ?? {};

  const [style, className] = useStyleConfigV2(container ?? {});

  return (
    <div style={style} className={clsx('flex', className)}>
      {data.icon && <Icon config={icon} symbol={data.icon.symbol} />}
      <Text config={title} data={data.title} />
    </div>
  );
};

SearchTrendsHeader.displayName = 'ElemasonSearchTrendsHeader';

export { SearchTrendsHeader };
