import { resetUser } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Button } from '../../../components';

import type { FC } from 'react';
import type { AuthLogoutWidgetProps } from './AuthLogout.types';

const AuthLogoutWidget: FC<AuthLogoutWidgetProps> = ({ widget }) => {
  const wData = widget.data!;
  const { config } = widget;
  const containerStyleConfig = useStyleConfig(config?.container ?? {});

  const onSubmit = () => {
    resetUser();
    return true;
  };

  return (
    <form
      action="/api/users/sign-out"
      style={containerStyleConfig.style}
      method="post"
      className={clsx('flex flex-col', containerStyleConfig.className)}
      onSubmit={onSubmit}
    >
      <Button type="submit" config={config?.button} data={wData.button} />
    </form>
  );
};

AuthLogoutWidget.displayName = 'AuthLogoutWidget';

export { AuthLogoutWidget };
