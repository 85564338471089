import { isEmpty } from 'lodash-es';
import { memo, type FC } from 'react';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { usePageFragment } from '../../../hooks';
import { useProductSearch } from '../../../hooks/network';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { GenericCarousel } from '../../containers/GenericCarousel';

import type { ProductCarouselWidgetProps } from './ProductCarousel.types';

const ProductCarouselWidget: FC<ProductCarouselWidgetProps> = ({ widget }) => {
  const { id: widgetId, data: wData, config: wConfig } = widget;

  const dataSource = useHaloScript(wData?.source);

  const lFragment = usePageFragment(wData?.loader);
  const fragmentValue = useFragmentValue(lFragment);

  const { hits, isError, isLoading } = useProductSearch(
    widgetId,
    dataSource,
    wData?.maxProductToDisplay
  );

  if (isError) {
    return null;
  }

  if (isLoading && lFragment) {
    return (
      <ElemasonFragmentProvider value={fragmentValue()}>
        {lFragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    );
  }

  if (isEmpty(hits)) {
    return null;
  }

  return (
    <GenericCarousel
      slides={hits!}
      fragment={wData?.slide}
      config={wConfig?.carousel}
    />
  );
};

ProductCarouselWidget.displayName = 'ProductCarouselWidget';

const MemoizedProductCarouselWidget = memo(ProductCarouselWidget);

export { MemoizedProductCarouselWidget as ProductCarouselWidget };
