import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useElemasonPageContext } from '../../contexts/ElemasonPageContext';

import type { ElemasonFragment, Nil } from '@tectonic/types';

const getFragments = (
  slugs: string[],
  fragments: ElemasonFragment[]
): ElemasonFragment[] => {
  const sSet = new Set(slugs);
  return fragments.filter((fragment) => sSet.has(fragment.slug));
};

const defaultFragments: ElemasonFragment[] = [];

const usePageFragments = (slugs?: Nil<string[]>): ElemasonFragment[] => {
  const { pageFragments } = useElemasonPageContext();

  return useMemo(() => {
    if (isEmpty(pageFragments)) {
      return defaultFragments;
    }

    // in case of empty slugs we return all fragments.
    if (isEmpty(slugs)) {
      return pageFragments ?? defaultFragments;
    }
    return getFragments(slugs!, pageFragments!);
  }, [slugs, pageFragments]);
};

export { usePageFragments };
