import { useMutation } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { useEffect, useMemo, useState, type FC } from 'react';
import { Button } from '../../components';
import { useActionDispatch, useSharedLocalState } from '../../hooks';
import { useHaloScript } from '../../hooks/useHaloScript';

import type { ElemasonEmailSubscriptionWidget } from '@tectonic/types';

interface EmailSubscriptionWidgetProps {
  widget: ElemasonEmailSubscriptionWidget;
}

const EmailSubscriptionWidget: FC<EmailSubscriptionWidgetProps> = ({
  widget: { data, config, actions },
}) => {
  const dispatch = useActionDispatch();
  const email = useHaloScript(data?.email);
  const [isError, setIsError] = useState(false);
  const stateKey = useHaloScript(data?.stateKey);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const subscribe = useMutation({
    mutationFn: () => remixApi.emailSubscription(email!),
    onSettled: () => {
      setIsLoading(false);
    },
    onError: (_error) => {
      setIsError(true);
      actions?.onError?.forEach((action) => dispatch(action));
    },
    onSuccess: () => {
      setIsSuccess(true);
      actions?.onSuccess?.forEach((action) => dispatch(action));
    },
  });

  const stateContent = useMemo(
    () => ({
      isError,
      isLoading,
      isSuccess,
    }),
    [isError, isLoading, isSuccess]
  );

  const { setSharedState } = useSharedLocalState(stateKey!);

  useEffect(() => {
    setSharedState(stateContent);
  }, [stateContent]);

  const onClick = () => {
    subscribe.mutate();
    actions?.onClick?.forEach((action) => dispatch(action));
  };

  return (
    <Button
      onClick={onClick}
      disabled={!email}
      isLoading={false}
      data={data?.button}
      config={config?.button}
      size={config?.button?.size}
      variant={config?.button?.variant}
      modifier={config?.button?.modifier}
      style={{
        color: config?.button?.text?.color,
        borderColor: config?.button?.borderColor,
        borderRadius: config?.button?.borderRadius,
        backgroundColor: config?.button?.backgroundColor,
      }}
    />
  );
};

export { EmailSubscriptionWidget };
