import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AnalyticsCartEventNames } from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { Text, useStyleConfig } from '@tectonic/elemason-components';
import { getErrorMessage } from '@tectonic/errors';
import { script } from '@tectonic/halo-script';
import {
  CartDiscountMode,
  ElemasonWidgetActionType,
  LocalStateKeys,
} from '@tectonic/types';
import { Input, InputField, InputSlot } from '@tectonic/uikit';
import { access, toCurrency } from '@tectonic/utils';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState, type FC } from 'react';
import { Button, Icon } from '../../../components';
import { ElemasonFragmentProvider } from '../../../contexts';
import { Cell } from '../../../core';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import {
  useActionDispatch,
  useFragmentValue,
  usePageFragment,
  useSharedLocalState,
} from '../../../hooks';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { queryKeys } from '../../../queryKeys';
import { CouponDialog } from './CouponDialog';

import type {
  Cart,
  DiscountCodesPayload,
  ElemasonCartCouponsWidget,
  Promotion,
} from '@tectonic/types';

const STATE_KEY = LocalStateKeys.CART;
const SCRIPT_ACCESSOR = script([access(['local', STATE_KEY])]);

interface ElemasonCartCouponsWidgetProps {
  widget: ElemasonCartCouponsWidget;
}

const CartCouponsWidget: FC<ElemasonCartCouponsWidgetProps> = ({ widget }) => {
  const { config, data, actions } = widget;
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [open, setIsOpen] = useState(false);
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const cart = useHaloScript<Cart>(SCRIPT_ACCESSOR);
  const [customDiscount, setCustomDiscount] = useState('');
  const stateKey = useHaloScript<string>(widget.data?.stateKey);
  const { sharedState, setSharedState } = useSharedLocalState(
    stateKey ?? 'cart-coupons'
  );

  const actionDispatch = useActionDispatch();

  const applyCouponMutation = useMutation({
    mutationFn: (params: DiscountCodesPayload) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_ADD_REQUEST,
          data: {
            promotionName: params.discountCodes[0],
          },
        },
      });
      return remixApi.overrideCartDiscounts(params);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart, inputPayload) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_ADD_SUCCESS,
          data: {
            entities: {
              cart: updatedCart,
            },
            promotionName: inputPayload.discountCodes[0],
          },
        },
      });
      actions?.applyCoupon?.onSuccess?.forEach((action) =>
        actionDispatch(action)
      );
    },

    onError: (error, inputPayload) => {
      const title = getErrorMessage(
        error,
        {},
        'There was an error applying coupon'
      );
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_ADD_ERROR,
          data: {
            promotionName: inputPayload.discountCodes[0],
            error,
          },
        },
      });
      showToast({ title });
      actions?.applyCoupon?.onError?.forEach((action) =>
        actionDispatch(action)
      );
    },
  });

  const removeCouponMutation = useMutation({
    mutationFn: (params: DiscountCodesPayload) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_REMOVE_REQUEST,
          data: {
            promotionName: params.discountCodes[0],
          },
        },
      });
      return remixApi.removeCartDiscounts();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cart() });
    },
    onSuccess: (updatedCart, inputPayload) => {
      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_REMOVE_SUCCESS,
          data: {
            entities: {
              cart: updatedCart,
            },
            promotionName: inputPayload.discountCodes[0],
          },
        },
      });
    },
    onError: (error, inputPayload) => {
      const title = getErrorMessage(
        error,
        {},
        'There was an error removing coupon'
      );

      actionDispatch({
        type: ElemasonWidgetActionType.ANALYTICS,
        payload: {
          event: AnalyticsCartEventNames.PROMOTION_REMOVE_ERROR,
          data: {
            error,
            promotionName: inputPayload.discountCodes[0],
          },
        },
      });
      showToast({ title });
    },
  });

  const {
    data: currentlyApplicableDiscounts,
    isLoading,
    isSuccess,
    error: caDiscountsError,
  } = useQuery({
    queryKey: queryKeys.cartDiscounts(CartDiscountMode.CURRENTLY_APPLICABLE),
    queryFn: () =>
      remixApi.getCartDiscounts(CartDiscountMode.CURRENTLY_APPLICABLE),
  });

  const {
    data: potentiallyApplicableDiscounts,
    isLoading: paLoading,
    isSuccess: paSuccess,
    error: paDiscountsError,
  } = useQuery({
    queryKey: queryKeys.cartDiscounts(CartDiscountMode.POTENTIALLY_APPLICABLE),
    queryFn: () =>
      remixApi.getCartDiscounts(CartDiscountMode.POTENTIALLY_APPLICABLE),
  });

  const { style, className } = useStyleConfig(config?.container ?? {});

  const onClickViewAll = () => {
    actionDispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: 'View Coupons',
      },
    });
    setIsOpen(true);
  };

  const applyCoupon = (code: string) => {
    applyCouponMutation.mutate({ discountCodes: [code] });
    setIsOpen(false);
  };

  const onApply = (discount: Promotion) => {
    applyCoupon(discount.codes[0]);
  };

  const onRemoveCode = (code?: string) => {
    if (isEmpty(code)) {
      return;
    }
    removeCouponMutation.mutate({ discountCodes: [code!] });
  };

  const appliedStateFragment = usePageFragment(data?.appliedStateFragment);
  const appliedStateFragmentValue = useFragmentValue(appliedStateFragment);
  const appliedStateFragmentData = appliedStateFragmentValue({
    cart,
    onRemoveCode,
  });

  const appliedStateFragmentUi = (
    <ElemasonFragmentProvider value={appliedStateFragmentData}>
      {appliedStateFragment?.cells.map((cell) => (
        <Cell key={cell.id} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  );

  const discountAllocation = cart?.discountAllocations?.find(
    (d) => d.code === cart?.discountCodes?.[0]?.code
  );

  const discountValue = discountAllocation
    ? toCurrency(
        discountAllocation.discountedAmount.amount,
        discountAllocation.discountedAmount.currencyCode
      )
    : '';

  const fragmentData = useMemo(
    () => ({
      cart,
      discountValue,
      applyCoupon,
      removeCoupon: onRemoveCode,
      currentlyApplicableDiscounts,
      potentiallyApplicableDiscounts,
      isLoading:
        isLoading ||
        paLoading ||
        removeCouponMutation.isPending ||
        applyCouponMutation.isPending,
    }),
    [
      cart,
      discountValue,
      applyCoupon,
      onRemoveCode,
      currentlyApplicableDiscounts,
      potentiallyApplicableDiscounts,
      isLoading,
      paLoading,
    ]
  );

  useEffect(() => {
    setSharedState({
      isLoading:
        isLoading ||
        paLoading ||
        removeCouponMutation.isPending ||
        applyCouponMutation.isPending,
    });
  }, [
    isLoading,
    paLoading,
    removeCouponMutation.isPending,
    applyCouponMutation.isPending,
  ]);

  if (!cart) {
    return null;
  }

  if (fragment) {
    return (
      <ElemasonFragmentProvider value={fragmentValue(fragmentData)}>
        {fragment.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
      </ElemasonFragmentProvider>
    );
  }

  return (
    <>
      {config?.forceModal ||
      isLoading ||
      paLoading ||
      (currentlyApplicableDiscounts?.found ?? 0) > 0 ||
      (potentiallyApplicableDiscounts?.found ?? 0) > 0 ||
      (cart.discountCodes && cart.discountCodes.length > 0) ? (
        <div style={style} className={clsx('flex flex-col', className)}>
          {cart.discountCodes?.length === 0 && (
            <Button
              data={data?.button}
              config={config?.button}
              onClick={onClickViewAll}
              isLoading={
                applyCouponMutation.isPending || isLoading || paLoading
              }
            />
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {cart.discountCodes && cart.discountCodes.length > 0 ? (
            appliedStateFragment ? (
              appliedStateFragmentUi
            ) : (
              // TODO: make more configurable
              <div
                style={{ backgroundColor: '#ECFDF5' }}
                className="flex w-full flex-col rounded-lg border border-green-200"
              >
                <div className="flex w-full px-2 py-3">
                  <Icon symbol={data?.icon!} config={config?.icon} />
                  <div className="ml-2 pb-1">
                    <Text
                      data={data?.couponText}
                      config={config?.couponText}
                      placeholders={{
                        coupon: (
                          cart.discountCodes?.[0]?.code ?? ''
                        ).toUpperCase(),
                      }}
                    />
                    <Text
                      data={data?.couponValue}
                      config={config?.couponValue}
                      placeholders={{ discountValue }}
                    />
                  </div>
                  <div className="ml-auto">
                    <Button
                      size="xs"
                      variant="outline"
                      modifier="danger"
                      className="rounded-md"
                      data={{ text: 'Remove' }}
                      isLoading={removeCouponMutation.isPending}
                      onClick={() =>
                        onRemoveCode(cart.discountCodes?.[0]?.code)
                      }
                    />
                  </div>
                </div>
                {(currentlyApplicableDiscounts?.found ?? 0) +
                  (potentiallyApplicableDiscounts?.found ?? 0) >
                  1 && (
                  <Button
                    className="justify-center"
                    config={{
                      variant: 'ghost',
                      modifier: 'black',
                      backgroundColor: 'white',
                    }}
                    onClick={onClickViewAll}
                    data={{
                      text: 'View All Coupons',
                      endIcon: 'outline-chevron-right',
                    }}
                  />
                )}
              </div>
            )
          ) : null}
        </div>
      ) : (
        <div>
          <Input className="rounded-md pl-2 pr-1">
            <InputField
              size="md"
              style={{ width: '100%' }}
              placeholder="Enter coupon code"
              onChange={(e) => setCustomDiscount(e.target.value)}
            />
            <InputSlot
              onClick={() => onApply({ codes: [customDiscount] } as Promotion)}
            >
              <Button
                data={{ text: 'Apply' }}
                config={{
                  size: 'sm',
                  borderRadius: 'md',
                  text: {
                    textTransform: 'uppercase',
                  },
                }}
              />
            </InputSlot>
          </Input>
        </div>
      )}
      {open && (
        <CouponDialog
          onApply={onApply}
          data={data?.dialog}
          config={config?.dialog}
          onClose={() => setIsOpen(false)}
          isSuccess={isSuccess && paSuccess}
          isLoading={isLoading || paLoading}
          isError={Boolean(caDiscountsError || paDiscountsError)}
          currentlyApplicableDiscounts={currentlyApplicableDiscounts?.hits!}
          potentiallyApplicableDiscounts={potentiallyApplicableDiscounts?.hits}
        />
      )}
    </>
  );
};

export { CartCouponsWidget };
