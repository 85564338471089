import { Text } from '@tectonic/elemason-components';
import { useCountdownContext } from './CountdownContext';

import type { ElemasonCountdownTextWidget } from '@tectonic/types';
import type { FC } from 'react';

interface ElemasonCountdownTextWidgetProps {
  widget: ElemasonCountdownTextWidget;
}

const CountdownTextWidget: FC<ElemasonCountdownTextWidgetProps> = ({
  widget: { config, data },
}) => {
  const { hours, minutes, seconds, days, ended } = useCountdownContext();

  return (
    <Text
      data={data!.text}
      config={config?.text}
      placeholders={{ hours, minutes, seconds, days, ended }}
    />
  );
};

export { CountdownTextWidget };
