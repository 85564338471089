import { AnalyticsProductEventNames } from '@tectonic/analytics';
import { useStyleConfig } from '@tectonic/elemason-components';
import { ElemasonWidgetType } from '@tectonic/types';
import clsx from 'clsx';
import { useCallback, type FC } from 'react';
import { ProductList as ProductListContent } from '../../../components';
import { useElemasonAnalyticsContext } from '../../../contexts';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { usePageFragment } from '../../../hooks';
import { useElemasonWidgetConfig } from '../../../hooks/useElemasonConfig';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { useImpressionLedger } from '../../../hooks/useImpressionLedger';
import { useProductMap } from '../../../utils/productList';
import { ProductListHeader } from '../../ProductList/ProductListHeader';
import { useProductSuggestions } from './hooks';

import type { ImpressionLedgerEntry, Product } from '@tectonic/types';
import type { SearchProductSuggestionWidgetProps } from './SearchProductSuggestion.types';

const SearchProductSuggestionWidget: FC<SearchProductSuggestionWidgetProps> = ({
  widget,
}) => {
  const {
    isLoading,
    products,
    shouldUseResult,
    count,
    isError,
    onSourceClick,
    onProductClick,
  } = useProductSuggestions(widget);

  const { config: productListConfig } = widget;

  const analyticsContext = useElemasonAnalyticsContext();

  const wData = widget.data!;

  const noResultsFragment = usePageFragment(wData?.noResultsFragment);
  const noResultsFragmentValue = useFragmentValue(noResultsFragment);

  const config = useElemasonWidgetConfig(
    ElemasonWidgetType.ProductList,
    productListConfig
  );

  const impressionLedger = useImpressionLedger(
    AnalyticsProductEventNames.PRODUCT_IMPRESSION,
    analyticsContext
  );
  const productMap = useProductMap(products ?? [], count ?? 0);

  const onProductInViewChange = useCallback(
    (viewProduct: Product, inView: boolean) => {
      const entry = {
        ...productMap.get(`${viewProduct.id}`),
        inView,
      };
      impressionLedger.setEntry(
        `${viewProduct.id}`,
        entry as ImpressionLedgerEntry
      );
    },
    [impressionLedger, productMap]
  );

  const onInViewChange = (viewProduct: Product, inView: boolean) => {
    onProductInViewChange(viewProduct, inView);
  };

  const { style, className } = useStyleConfig(config?.container ?? {});

  if (!isLoading && products.length === 0) {
    if (noResultsFragment)
      return (
        <ElemasonFragmentProvider value={noResultsFragmentValue()}>
          {noResultsFragment.cells.map((cell) => (
            <Cell key={cell.id} cell={cell} />
          ))}
        </ElemasonFragmentProvider>
      );

    return null;
  }

  return (
    <div style={style} className={clsx('flex w-full flex-col', className)}>
      <ProductListHeader
        count={count}
        isError={isError}
        data={shouldUseResult ? wData.result.header : wData.noResult.header}
        isLoading={isLoading && !products}
        config={config?.header}
      />
      <ProductListContent
        data={wData}
        count={count}
        config={config}
        isError={isError}
        products={products}
        isLoading={isLoading}
        onSourceClick={onSourceClick}
        onProductClick={onProductClick}
        onProductInViewChange={onInViewChange}
      />
    </div>
  );
};

SearchProductSuggestionWidget.displayName =
  'ElemasonSearchProductSuggestionWidget';

export { SearchProductSuggestionWidget };
