import { useStyleConfig } from '@tectonic/elemason-components';
import { isVisible } from '../../../utils';
import { SearchQueries } from './SearchQueries';
import { SearchTrendsHeader } from './SearchTrendsHeader';
import { SearchTrendsSkeleton } from './SearchTrendsSkeleton';

import type { FC } from 'react';
import type { SearchTrendsProps } from './SearchTrends.types';

const SearchTrends: FC<SearchTrendsProps> = ({
  data,
  isLoading,
  queries,
  config,
  onQueryClick,
}) => {
  const { header, container } = config ?? {};

  const isHeaderVisible = isVisible(header);

  const containerStyleConfig = useStyleConfig(container ?? {});

  if (isLoading) {
    return <SearchTrendsSkeleton config={config} />;
  }

  return (
    <div {...containerStyleConfig}>
      {isHeaderVisible ? (
        <SearchTrendsHeader config={header} data={data.header!} />
      ) : null}
      <SearchQueries
        config={config}
        queries={queries}
        data={data.query}
        onQueryClick={onQueryClick}
      />
    </div>
  );
};

SearchTrends.displayName = 'ElemasonSearchTrends';

export { SearchTrends };
