import { withTryCatch } from './utils';

const LocalStorage = {
  setItem: withTryCatch((key: string, value: unknown) =>
    globalThis.localStorage.setItem(key, JSON.stringify(value))
  ),
  getItem: withTryCatch((key: string) => {
    const value = globalThis.localStorage.getItem(key);
    if (value === null) {
      return value;
    }
    return JSON.parse(value);
  }) as typeof localStorage.getItem,
  removeItem: withTryCatch((key: string) =>
    globalThis.localStorage.removeItem(key)
  ) as typeof localStorage.removeItem,
  clear: withTryCatch(() =>
    globalThis.localStorage.clear()
  ) as typeof localStorage.clear,
};

export { LocalStorage };
