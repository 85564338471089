class Scope {
  private scope: Record<string, any>;

  private parent?: Scope;

  constructor(scope: Record<string, any>, parent?: Scope) {
    this.scope = scope;
    this.parent = parent;
  }

  public get(name: string): any {
    if (name in this.scope) {
      return this.scope[name];
    }
    if (this.parent) {
      return this.parent.get(name);
    }
    return undefined;
  }

  public set(name: string, value: any): void {
    this.scope[name] = value;
  }

  public getScopeWithOwnProperty(name: string): Scope {
    if (name in this.scope) {
      return this;
    }

    if (this.parent) {
      return this.parent.getScopeWithOwnProperty(name);
    }

    return this;
  }
}

export { Scope };
