// TODO: create a uikit component for accordion which handles these
import { useStyleConfig } from '@tectonic/elemason-components';
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from '@tectonic/uikit';
import { memo } from 'react';
import { Cell } from '../../core/Cell';
import { useAccessorValue } from '../../hooks/useAccessorValue';
import { useHaloScript } from '../../hooks/useHaloScript';

import type {
  ElemasonAccordionWidget,
  ElemasonAccoridonItem,
} from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface ElemasonAccordionWidgetProps {
  widget: ElemasonAccordionWidget;
}

interface AccordionWidgetItemProps
  extends Omit<ComponentProps<typeof AccordionItem>, 'value'> {
  item: ElemasonAccoridonItem;
  index: number;
}

const AccordionWidgetItem: FC<AccordionWidgetItemProps> = ({
  item,
  index,
  ...props
}) => {
  const toRender = useHaloScript(item.shouldRender) ?? true;

  return toRender ? (
    <AccordionItem value={`${index}`} {...props}>
      <AccordionHeader asChild>
        <AccordionTrigger className="group w-full text-start">
          <div>
            <Cell
              cell={item.trigger.open}
              className="group-radix-state-closed:hidden"
            />
            <Cell
              cell={item.trigger.close}
              className="group-radix-state-open:hidden"
            />
          </div>
        </AccordionTrigger>
      </AccordionHeader>
      <AccordionContent>
        <Cell cell={item.content} />
      </AccordionContent>
    </AccordionItem>
  ) : null;
};

const AccordionWidget: FC<ElemasonAccordionWidgetProps> = ({
  widget: { config, data },
}) => {
  const items = useHaloScript(data?.items);
  const multiple = useAccessorValue(config?.multiple ?? {});
  const collapsible = useAccessorValue(config?.collapsible ?? {});
  const { style, className } = useStyleConfig(config?.container ?? {});
  const { style: itemStyle, className: itemClassName } = useStyleConfig(
    config?.item ?? {}
  );

  return (
    <AccordionRoot
      style={style}
      className={className}
      // @ts-ignore for multiple the default value needs to be a array
      defaultValue={multiple ? [data?.defaultItemIdx] : data?.defaultItemIdx}
      type={multiple ? 'multiple' : 'single'}
      {...(multiple ? {} : { collapsible })}
    >
      {items?.map((item, index) => (
        <AccordionWidgetItem
          key={index}
          item={item}
          index={index}
          style={itemStyle}
          className={itemClassName}
        />
      ))}
    </AccordionRoot>
  );
};

const MemoizedAccordionWidget = memo(AccordionWidget);

export { MemoizedAccordionWidget as AccordionWidget };
