import { useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  Typography,
  useForm,
} from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Button } from '../../Button';
import { PhoneNumberInput } from '../../PhoneNumberInput';

import type { FormSubmitHandler } from '@tectonic/uikit';
import type { FC } from 'react';
import type {
  AuthPhoneNoOtpSignInFormProps,
  AuthPhoneNoOtpSignInFormValue,
} from './AuthPhoneNoOtpSignInForm.types';

const useDefaultValues = (
  data: AuthPhoneNoOtpSignInFormProps['data']
): Partial<AuthPhoneNoOtpSignInFormValue> =>
  useMemo(() => {
    const country = data.defaultCountry;
    return { phoneNumber: { country, phone: '' } };
  }, [data]);

const AuthPhoneNoOtpSignInForm: FC<AuthPhoneNoOtpSignInFormProps> = ({
  isLoading,
  data,
  onSubmit,
  config,
}) => {
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  const defaultValues = useDefaultValues(data);

  const { control, formState, setError } =
    useForm<AuthPhoneNoOtpSignInFormValue>({
      defaultValues,
      mode: 'onChange',
    });

  const { errors } = formState;

  const handleSubmit: FormSubmitHandler<AuthPhoneNoOtpSignInFormValue> = (
    formData
  ) => {
    const value = formData.data;
    if (isBlank(value.phoneNumber.phone)) {
      setError('phoneNumber', {
        type: 'custom',
        message: data.phoneInput.textField.required!,
      });
      return;
    }
    onSubmit(value);
  };

  return (
    <Form control={control} onSubmit={handleSubmit}>
      <div
        style={containerStyleConfig.style}
        className={clsx('flex flex-col', containerStyleConfig.className)}
      >
        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneNumberInput
                value={field.value}
                countries={[data.defaultCountry]}
                onChange={(value) => field.onChange(value)}
                placeholder={data.phoneInput.textField.placeholder}
              />
            )}
          />
          <FormErrorMessage
            name="phoneNumber"
            errors={errors}
            as={Typography}
            className="text-danger-600"
            variant="body3"
          />
        </div>
        <div className="flex">
          <Button
            isLoading={isLoading}
            className="grow"
            type="submit"
            data={data.submitCta.button}
            config={config?.submitCta?.button}
          />
        </div>
      </div>
    </Form>
  );
};

AuthPhoneNoOtpSignInForm.displayName = 'AuthPhoneNoOtpSignInForm';

export { AuthPhoneNoOtpSignInForm };
