import { Text, useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Icon } from '../Icon';
import { Swatch } from './Swatch';

import type {
  IconConfig,
  StyleConfig,
  SwatchConfig,
  TextConfig,
} from '@tectonic/types';
import type { IconSymbol } from '@tectonic/uikit';
import type { ComponentProps, FC } from 'react';

interface ColorDropdownProps extends Omit<ComponentProps<'div'>, 'color'> {
  colors: {
    name: string;
    code: string;
  }[];
  color: {
    name: string;
    code: string;
  };
  config: {
    icon: IconSymbol;
    text?: TextConfig;
    swatch?: SwatchConfig;
    iconConfig: IconConfig;
    container?: StyleConfig;
    textContainer?: StyleConfig;
  };
}

const ColorDropdown: FC<ColorDropdownProps> = ({
  color,
  colors,
  config,
  style,
  className,
}) => {
  const { style: iStyle, className: iClassName } = useStyleConfig(
    config?.container ?? {}
  );

  const { style: textContainerStyle, className: textContainerClassName } =
    useStyleConfig(config?.textContainer ?? {});

  return (
    <div style={style} className={className}>
      <div style={iStyle} className={clsx('flex', iClassName)}>
        <Swatch color={color} config={config.swatch} />
        <div
          style={textContainerStyle}
          className={clsx('flex grow', textContainerClassName)}
        >
          <Text data={color.name} config={config.text} />
          {colors.length > 0 && (
            <Icon symbol={config.icon} config={config.iconConfig} />
          )}
        </div>
      </div>
    </div>
  );
};

export { ColorDropdown };
