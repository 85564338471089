import clsx from 'clsx';
import { Children, cloneElement, isValidElement, useId } from 'react';
import { InputField } from './InputField';
import { InputIcon } from './InputIcon';
import { InputSlot } from './InputSlot';
import { inputTv } from './variant';

import type { FC, ReactElement } from 'react';
import type { IconProps } from '../Icon';
import type { InputProps, InputSlotProps } from './Input.types';

const inputIconSizes: Record<
  NonNullable<InputSlotProps['size']>,
  IconProps['size']
> = {
  lg: 'md',
  md: 'md',
  sm: 'sm',
  xs: 'sm',
};

const Input: FC<InputProps> = ({
  variant,
  size,
  style,
  rounded,
  disabled,
  children,
  className,
}) => {
  const { base, field, slot, icon } = inputTv({
    variant,
    size,
    rounded,
    disabled,
  });
  const htmlFor = useId();

  // TODO: Find a better way to style component based on different conditions.
  // in the interest of time, we are simply cloning children. We should have a
  // proper framework around it. We can look at various styling engines
  // and use them.
  const nChildren = Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return child;
    }
    if (child.type === InputField) {
      return cloneElement(child as ReactElement, {
        id: htmlFor,
        size,
        disabled,
        className: clsx(field(), child.props.className),
      });
    }
    if (child.type === InputSlot) {
      return cloneElement(child as ReactElement, {
        size,
        className: slot(),
        children: Children.map(child.props.children, (gChild) => {
          if (!isValidElement(gChild)) {
            return gChild;
          }
          if (gChild.type === InputIcon) {
            return cloneElement(gChild as ReactElement, {
              size: inputIconSizes[size ?? 'md'],
              className: `${icon()} ${gChild.props.className}`,
            });
          }
          return gChild;
        }),
      });
    }
    return child;
  });

  return (
    <label className={base({ className })} htmlFor={htmlFor} style={style}>
      {nChildren}
    </label>
  );
};

Input.displayName = 'Input';

export { Input };
